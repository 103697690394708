<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Privacy Act Statement</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <h5 class="text-center fw-regular text-secondary text-italic">The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track beneficiary enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1851 and 1860D-1 of the Social Security Act and 42 CFR §§ 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and exchange enrollment data from Medicare beneficiaries as specified in the System of Records Notice (SORN) “Medicare Advantage Prescription Drug (MARx)”, System No. 09-70-0588. Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.</h5>
    </div>
    <div class="py-3">
      <div class="dashed-box border shadow p-4">
        <h4 class="text-center fw-semibold">Please Read and Sign.</h4>
        <div>
          <p class="text-label">By completing this form, I agree to the following:</p>
          <div class="p-2">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="agree1">    
              <label class="custom-control-label fw-medium text-dblue" for="agree1">This is a Medicare Advantage plan. It has a contract with the federal government. This is not a Medicare Supplement plan.</label>    
            </div>
          </div>
          <div class="p-2">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="agree2">    
              <label class="custom-control-label fw-medium text-dblue" for="agree2">I must keep both Part A and Part B to stay in this Medicare Advantage Plan, <strong>[Medicare Advantage Plan Name]</strong>. I must keep paying my Part B premium if I have one, unless Medicaid or someone else pays for it.</label>    
            </div>
          </div>
        </div>
        <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="iAgree">    
              <label class="custom-control-label fw-semibold text-dblue" for="iAgree">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>
</div>