<div class="order-table-wrapper">
  <h5 class="text-center fWeight-600">{{ tableTitle }}</h5>
  <table class="table table-borderless table-sm table-hover table-striped modal-table mb-5 text-center border border-light">
    <thead>
      <tr>
        <th class="w-15">Drug Tier</th>
        <th>30 Day Supply</th>
        <th>60 Day Supply</th>
        <th>90 Day Supply</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let drugTier of drugsTier[0][0]; let i = index">
        <td>Tier {{ i+1 }}</td>
        <td [class.p-3]="drugTier[0] == 'Not Supported'">
          <ng-container *ngIf="drugTier[0] != 'Not Supported'">
            {{ drugTier[0].includes("%") ? 'Coinsurance' : drugTier[0] }}
          </ng-container>
          <ng-container *ngIf="drugTier[0] == 'Not Supported'">
            <span class="no-pharma">Not Offered <i class="fa fa-info global-info-icon-first ml-2"></i></span>
          </ng-container>
        </td>
        <td [class.p-3]="drugTier[1] == 'Not Supported'">
          <ng-container *ngIf="drugTier[1] != 'Not Supported'">
            {{ drugTier[1] }}
          </ng-container>
          <ng-container *ngIf="drugTier[1] == 'Not Supported'">
            <span class="no-pharma">Not Offered <i class="fa fa-info global-info-icon-first ml-2"></i></span>
          </ng-container>
        </td>
        <td [class.p-3]="drugTier[2] == 'Not Supported'">
          <ng-container *ngIf="drugTier[2] != 'Not Supported'">
            {{ drugTier[2].includes("%") ? 'Coinsurance' : drugTier[2] }}
          </ng-container>
          <ng-container *ngIf="drugTier[2] == 'Not Supported'">
            <span class="no-pharma">Not Offered <i class="fa fa-info global-info-icon-first ml-2"></i></span>
          </ng-container>
        </td>        
      </tr>
    </tbody>

    <!--<tr>
      <td>0</td>
      <td>1</td>
      <td>2</td>
      <td>3</td>
    </tr>
    <tr>
      <td>0</td>
      <td>1</td>
      <td>2</td>
      <td>3</td>
    </tr>
    <tr>
      <td>0</td>
      <td>1</td>
      <td>2</td>
      <td>3</td>
    </tr>-->
  </table>
</div>