<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b [showNumber]="true"></app-header-b>

  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-md-8">
        <div class="banner-text-wrapper">
          <h1 class="banner-main-title">
            <span>Need To Enroll In Medicare Part B?</span><br />
            A Clear and Guided Experience
          </h1>
          <p class="banner-sub-title">
            The "New To Medicare" process is not easy. Not for anyone.<br />
            The TapTap experience makes it a breeze.
          </p>
        </div>
      </div>
      <div class="col-md-4 px-0 banner-image-wrapper">
        <img
          src="assets/img/common/medicare/old-lady-meditating.png"
          class="banner-main-image mb-neg-50"
        />
      </div>
    </div>
  </div>
</div>

<div class="banner-pointer-wrapper">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>

<section class="container-fluid main-content-wrapper home-wrapper">
  <div class="row home-content">
    <div class="col-12 row">
      <div class="col-md-12 home-content-row-div mt-50">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-4 col-md-1 logo-list-img">
              <img src="assets/img/home/company-logo-display/anthem-logo.jpg" />
            </div>
            <div class="col-4 col-md-1 logo-list-img">
              <img
                src="assets/img/home/company-logo-display/aarp-medicare-plans-logo.jpg"
              />
            </div>
            <div class="col-4 col-md-1 logo-list-img">
              <img src="assets/img/home/company-logo-display/aetna-logo.jpg" />
            </div>
            <div class="col-4 col-md-1 logo-list-img">
              <img src="assets/img/home/company-logo-display/cigna-logo.jpg" />
            </div>
            <div class="col-4 col-md-1 logo-list-img">
              <img
                src="assets/img/home/company-logo-display/humana -logo.jpg"
              />
            </div>
            <div class="col-4 col-md-1 logo-list-img">
              <img
                src="assets/img/home/company-logo-display/mutual-of-omaha-logo.jpg"
              />
            </div>
            <div class="col-4 col-md-1 logo-list-img">
              <img
                src="assets/img/home/company-logo-display/well-care-logo.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row flex-column-reverse flex-md-row align-items-center">
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start text-center text-md-left pr-md-5"
            >
              <div class="home-text-box">
                <h2>Medicare Part B<br />Enrollment</h2>
                <mat-list>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>
                      The Clarity You Deserve In Regards To Your<br />Specific
                      Medicare Enrollment Process
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>The Steps, Conditions, And Timelines Involved</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>
                      A Clear & Organized Medicare Experience<br />Right From
                      The Start
                    </p>
                  </mat-list-item>
                </mat-list>
                <!-- <ul>
                  <li><i class="fa fa-check mr-2"></i>Watch Videos</li>
                  <li><i class="fa fa-check mr-2"></i>Access Our E-Cources, Articles and Infographics</li>
                  <li><i class="fa fa-check mr-2"></i>FInd Help with Our Reliable Guidebook</li>
                </ul> -->
                <a
                  class="btn btn-first"
                  href="https://brightprice.gigabook.com/book/all#panel_step_choose"
                  target="_blank"
                >
                  SCHEDULE A TIME WITH A COUNSELOR
                </a>
              </div>
            </div>
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center align-items-center justify-content-md-end mb-4 mb-md-0"
            >
              <img src="assets/img/common/medicare/woman-wearing-black.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row home-content-first-box-wrapper">
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0"
            >
              <img src="assets/img/common/medicare/woman-in-computer.png" />
            </div>
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5"
            >
              <div class="home-text-box">
                <h2>A Solutions Driven<br />Medicare Team</h2>
                <mat-list>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Easily Compare Plans and Prices</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Customize the Medicare Plan You Want</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Setup Automatic Savings Checks</p>
                  </mat-list-item>
                </mat-list>
                <a href="tel:18002155108" class="btn btn-first col-8">
                  CALL US NOW
                  <!--<i class="fas fa-angle-double-right ml-2"></i>-->
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row flex-column-reverse flex-md-row align-items-center">
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start text-center text-md-left pr-md-5"
            >
              <div class="home-text-box">
                <h2>
                  Learn and Understand<br />
                  Enjoyably
                </h2>
                <mat-list>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Watch Videos</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Access Our E-Courses, Articles and<br />Infographics</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>View Our Consumer-Friendly<br />Illustrated Guides</p>
                  </mat-list-item>
                </mat-list>
                <!-- <ul>
                  <li><i class="fa fa-check mr-2"></i>Watch Videos</li>
                  <li><i class="fa fa-check mr-2"></i>Access Our E-Cources, Articles and Infographics</li>
                  <li><i class="fa fa-check mr-2"></i>FInd Help with Our Reliable Guidebook</li>
                </ul> -->
                <div class="row justify-content-center">
                  <a
                    class="btn btn-third col-5 margin-2-btn"
                    routerLink="/insurance-marketplace"
                    target="_blank"
                  >
                    VIEW PLANS & PRICES
                  </a>
                  <a
                    class="btn btn-third col-5 margin-2-btn"
                    routerLink="/education"
                    target="_blank"
                  >
                    VIEW OUR<br />EDUCATIONAL GUIDES
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center align-items-center justify-content-md-end mb-4 mb-md-0"
            >
              <img src="assets/img/common/medicare/woman-man.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row home-content-second-box-wrapper">
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0"
            >
              <img src="assets/img/common/medicare/woman-in-carpet.png" />
            </div>
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5"
            >
              <div class="home-text-box">
                <h2>Be Perfectly Organized</h2>
                <mat-list>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>
                      All The Details Of Your Insurances Saved<br />And Secured
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Your Plan Info Easily Accessible At All Times</p>
                  </mat-list-item>
                </mat-list>
                <button
                  class="btn btn-first col-8"
                  (click)="onClickChat($event)"
                  id="send_msg_ntm"
                >
                  SEND US A MESSAGE
                  <!--<i class="fas fa-angle-double-right ml-2"></i>-->
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 row">
      <div class="col-md-12 mt-50">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div
              class="row col-12 justify-content-center text-center padding-50"
            >
              <h2>3 Enjoyable Options To Choose From</h2>
              <h5 class="normal mt-20">
                Click to Choose Any of The Options Below:
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div
        class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block"
        style="z-index: 1"
      >
        <i class="fa fa-arrow-down" style="color: #007bff"></i>
        <div class="banner-pointer-background inverse-colored-down-arrow"></div>
      </div>

      <div class="col-md-12 blue-bg neg-83-margin-top">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div
              class="row col-12 justify-content-center text-center padding-50 mt-50"
            >
              <a
                href="tel:18002155108"
                class="col-md-3 row justify-content-center card-option"
              >
                <div class="col-12 center">
                  <h5>Call Us Now</h5>
                </div>
                <div class="col-8 center mt-30">
                  <div class="thumbnail1"></div>
                </div>
                <div class="col-12">&nbsp;</div>
              </a>

              <a
                href="https://brightprice.gigabook.com/book/all#panel_step_choose"
                target="_blank"
                class="col-md-3 row justify-content-center card-option"
              >
                <div class="col-12 center">
                  <h5>Schedule a Time<br />For Later</h5>
                </div>
                <div class="col-8 center mt-30">
                  <div class="thumbnail2"></div>
                </div>
                <div class="col-12">&nbsp;</div>
              </a>

              <div
                (click)="onClickChat($event)"
                class="col-md-3 row justify-content-center card-option"
                id="send_msg_ntm"
              >
                <div class="col-12 center">
                  <h5>Send Us a Message</h5>
                </div>
                <div class="col-8 center mt-30">
                  <div class="thumbnail3"></div>
                </div>
                <div class="col-12">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 blue-bg" style="height: 200px; margin-top: -20px">
        <div class="container"></div>
      </div>

      <div class="col-md-12">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div class="row col-12 justify-content-center text-center">
              <div class="col-10 row justify-content-center lets-talk">
                <h3>Not Sure What You Need? Let's Talk.</h3>
                <p>
                  Reserve your own dedicated time with an in-house counselor to
                  receive clear<br />
                  explanations, a full consultation, or a brief "Coverage
                  Clarity" session to better<br />
                  understand your own current coverage.
                </p>
                <button
                  class="col-md-5 btn btn-first-plain padding-20 width-auto"
                  (click)="speakToCounselorEvent(speakToCounselorModal, 540)"
                >
                  Speak With a Concierge >>
                </button>
              </div>
              <div class="col-10 row justify-content-center">
                <img
                  src="assets/img/common/medicare/old-woman-with-a-kit-half.png"
                  class="lets-talk-old-woman"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="d-none">
  <div #videoContent>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <div
          class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true"
          style="height: 100%; position: relative; width: 100%"
        >
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            "
          >
            <img
              src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch"
              style="
                filter: blur(5px);
                height: 100%;
                object-fit: contain;
                width: 100%;
              "
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <span class="wistia_embed wistia_async_9rgfxy7pqm popover=true popoverContent=link" style="display:inline;position:relative"><a href="#">This is my popover!</a></span> -->

<div class="d-none {{ dblocking }}" #speakToCounselorModal>
  <app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>
