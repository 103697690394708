import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medigap-plan-g-v1',
  templateUrl: './medigap-plan-g-v1.component.html',
  styleUrls: ['./medigap-plan-g-v1.component.scss']
})
export class MedigapPlanGV1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  moveToContent(){
    $('html,body').animate({ scrollTop: $("#target_learn_more").offset().top - 60},'slow');
  }

}
