<form clrForm>
  <clr-input-container>
    <label>Title</label>
    <input clrInput type="text" name="title" />
  </clr-input-container>
  <clr-input-container>
    <label>Description</label>
    <input clrInput type="text" name="description" />
  </clr-input-container>

  <button class="btn btn-primary" type="submit" (click)="submit()">Submit</button>
  <br>
  <br>
  <br>
  <a href="mailto: abc@example.com">MailTo</a>
</form>
