import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dental-version1',
  templateUrl: './version1.component.html',
  styleUrls: ['./version1.component.scss']
})
export class Version1Component implements OnInit {

  @Input() results:any;

  @Input() controls:any;

  @Input() logComparedKeys: any;

  @Input() base_img_loc: any;

  @Output() onClickPlanDocsResources = new EventEmitter();

  @Output() onClickCompanyProfile = new EventEmitter();

  @Output() onClickConsumerReview = new EventEmitter();

  @Output() onClickAdditionalInsights = new EventEmitter();

  @Output() onClickApplyOnline = new EventEmitter();

  @Output() onClickApplyOnThePhone = new EventEmitter();

  @Output() onClickAddToCompareFunc = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('zz', changes)
  }

  companyLogoCheck(event) {
    $(event.target).parent().find('.no-img-plan-name').addClass('d-block').removeClass('d-none');
  }

  clickPlanDocsResources(event){
    this.onClickPlanDocsResources.emit(event);
  }

  companyProfile(result){
    this.onClickCompanyProfile.emit(result)
  }

  consumerReview(result){
    this.onClickConsumerReview.emit(result);
  }

  additionalInsights(result){
    this.onClickAdditionalInsights.emit(result)
  }

  applyOnline(result, index){
    this.onClickApplyOnline.emit({result,index});
  }

  applyOnThePhone(result){
    this.onClickApplyOnThePhone.emit(result);
  }

  addToCompareFunc(result, event, index, parent1, child){
    this.onClickAddToCompareFunc.emit({result, event, index, parent1, child});
  }

}
