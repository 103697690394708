import {Component, OnInit, Input} from '@angular/core';
import {APIService} from '../../services/api.service';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import * as FileSaver from "file-saver";
import { Router } from '@angular/router';
//import { bookmarked } from 'bookmarked';
const API_BASE_URL: string = environment.apiUrl;

const bookmarks = [
    {
      href: 'https://www.blackgirlscode.com/',
      name: 'Black Girls Code, BlackGirlsCode, Women of Color in Technology',
    },
    {
      href: 'https://www.freecodecamp.org/',
      name: 'Learn to Code — For Free — Coding Courses for Busy People',
    },
  ];



@Component({
    selector: 'app-save-my-quote',
    templateUrl: './save-my-quote.component.html',
    styleUrls: ['./save-my-quote.component.scss']
})
export class SaveMyQuoteComponent implements OnInit {
    @Input() currentURL: string;
    @Input() insuranceType: string;
    @Input() insuranceSubType: string;
    @Input() plan: string;
    @Input() selected_pricing_rate: string;

    @Input() insurance_type: string;
    @Input() hash: string;

    loading: boolean = false;

    isFormSubmit: boolean = false;

    full_name: any;
    

    // save_your_quote: any = {
    //     name: new FormControl('', [
    //         Validators.required,
    //     ]),
    //     last_name: new FormControl('', [
    //         Validators.required,
    //     ]),
    //     your_email: new FormControl('', [
    //         Validators.required,
    //         Validators.email,
    //     ]),
    //     phone: new FormControl('', []),
    // };

    saveMyQuoteForm = new FormGroup({
        first_name: new FormControl('', [
            Validators.required,
        ]),
        last_name: new FormControl('', [
            Validators.required,
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]),
        phone: new FormControl(''),
    });

    get first_name() { return this.saveMyQuoteForm.get('first_name'); }
    get last_name() { return this.saveMyQuoteForm.get('last_name'); }
    get email() { return this.saveMyQuoteForm.get('email'); }
    get phone() { return this.saveMyQuoteForm.get('phone'); }
    

    today = new Date();
    dateFormatted: any;

    constructor(private _api: APIService, private _route: Router) {
        console.log(this.hash);
        this.dateFormatted = (this.today.getMonth() + 1) + '-' + this.today.getDate() + '-' + this.today.getFullYear();
    }

    ngOnInit() {
    }

    getQuotationUrl() {
        return  API_BASE_URL + '/api/v1/quotation/save/view/' + this.hash;
    }

    // $lastName $firstName medigap $planLetter mm dd yyyy
    saveYourQuote() {
      
        
	// If you have something in the `href` of your trigger
	//return false;    
            
        
        //console.log('boooooooooook');

        //bookmarked(bookmarks);

        // this.full_name = this.first_name.value + " " + this.last_name.value;

        // this.isFormSubmit = true;

        // if (this.saveMyQuoteForm.valid) {

        //     this.loading = true;
        //     console.log(this.saveMyQuoteForm.controls);
        //     console.log(this.saveMyQuoteForm.controls.first_name.value);
        //     console.log(this.saveMyQuoteForm.controls.last_name.value);
        //     console.log(this.saveMyQuoteForm.controls.email.value);
        //     console.log(this.saveMyQuoteForm.controls.phone.value);

        //     this._route.navigate(['./registration/' + this.saveMyQuoteForm.controls.first_name.value + '/' + this.saveMyQuoteForm.controls.last_name.value + '/' + this.saveMyQuoteForm.controls.email.value + '/' + this.saveMyQuoteForm.controls.phone.value]);

        //     //this._router.navigate(['./insurance-marketplace/medicare-advantage/results/plan-' + this.plan + '/zipcode-' + this.controls.zipcode.value + '/planYear-' + this.planYear]);
            
        //     // this._api.downloadFromAPIEndpoint('/api/v1/quotation/save', {
        //     //     'name': this.first_name.value,
        //     //     'your_email': this.email.value,
        //     //     'phone': this.phone.value,
        //     //     'insurance_type': this.insurance_type,
        //     //     'insurance_sub_type': this.insuranceSubType,
        //     //     'url' : this.currentURL, 
        //     //     'hash' : this.hash, //  '7469b963310bbd02f003add75eb1cbca'
        //     //     'selected_pricing_rate': this.selected_pricing_rate,
        //     // }).subscribe(
        //     //     (data: any) => {
        //     //         this.loading = false;
        //     //         var blob = new Blob([data], {type: 'application/pdf'});
        //     //         FileSaver.saveAs(blob, this.full_name.replace(' ', '-') + '-' + this.insurance_type + '-' + this.plan.replace(' ', '-') + '-' + this.dateFormatted + '.pdf');
        //     //         Swal.fire('Quote successfully saved!');
        //     //         this.emptyFields();
        //     //         this.isFormSubmit = false;
        //     //     },
        //     //     (err) => {
        //     //         this.loading = false;
        //     //     }
        //     // );
        // }
    }

    emptyFields() {
        this.saveMyQuoteForm.setValue(
            {   
                first_name : '', 
                last_name: '',
                email:'',
                phone: ''
            }
        )
    }
}
