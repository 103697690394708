<ng-container *ngIf="!param_contract_id && !param_plan_id">
  <ng-container *ngFor=" let result of results; let i = index">
    <div class="container d-flex align-items-center result-item">
      <div class="mapd-result-version-5 type-1 vp-btn-inside" *ngIf="result.county === countySelected" [class.bennydash-result]="isBlueBgHeading">
        <ng-container [ngTemplateOutlet]="resultTable" [ngTemplateOutletContext]="{ $implicit: result, index: i}"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="param_contract_id && param_plan_id">
  <ng-container *ngFor=" let result of results; let i = index">
    <div class="container d-flex align-items-center result-item">
      <div class="mapd-result-version-5 type-1 vp-btn-inside" *ngIf="result.county === countySelected && result.contract_id === param_contract_id && result.plan_id === param_plan_id">
        <ng-container [ngTemplateOutlet]="resultTable" [ngTemplateOutletContext]="{ $implicit: result, index: i }"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #resultTable let-result let-i="index">
  <table class="mapd-result-table">
    <tr class="tr-result-heading" [class.bgBlue]="isBlueBgHeading">
      <th class="w-25">Company</th>
      <th class="w-63 column-triple-content">
        <div>
          <span class="column-visits">Visits</span>
          <span class="column-tests">Diagnostics</span>
          <span class="column-surgery">Surgery/Hospital</span>
        </div>
        <span class="column-vision w-150">Includes</span>
      </th>
      <th class="w-12">Costs</th>
    </tr>
    <tr>
      <td class="w-25 result-logo-wrapper">
        <span class="no-img-plan-name d-none">

          {{ result.organization_name }}</span>
          <img src="{{base_img_loc + replaceSpaceWith(result.organization_name) + '.jpg'}}"
            id="logo_{{result.organization_name}}_{{result.key}}_{{i}}" class="w-100"
            alt="{{ result.organization_name }}"
            onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';" #insuranceLogo
            (error)="companyLogoCheck($event)">

          <div class="medicare-advantage-custom-title-rating-wrapper">
            <h2 #h2ResultPlanName class="results-plan-name">

              {{ result.plan_name }}</h2>
              <input type="hidden" value="{{ result.overall_star_rating }}" #resultStarRating>
              <div class="d-flex flex-column align-items-center mt-3 med-ad-rating-main-wrapper" (click)="onClick_Rating(starRatingDetails)">
                <div class="med-ad-rating-wrapper" #starRating *ngIf="result.overall_star_rating > 0">
                  <i
                    class="fa {{result.overall_star_rating > 0 && result.overall_star_rating < 1  ? 'fa-star-half-o' : result.overall_star_rating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
                  <i
                    class="fa {{result.overall_star_rating > 1 && result.overall_star_rating < 2  ? 'fa-star-half-o' : result.overall_star_rating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
                  <i
                    class="fa {{result.overall_star_rating > 2 && result.overall_star_rating < 3  ? 'fa-star-half-o' : result.overall_star_rating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
                  <i
                    class="fa {{result.overall_star_rating > 3 && result.overall_star_rating < 4  ? 'fa-star-half-o' : result.overall_star_rating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
                  <i
                    class="fa {{result.overall_star_rating > 4 && result.overall_star_rating < 5  ? 'fa-star-half-o' : result.overall_star_rating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
                </div>
                <span class="star-rating-count" *ngIf="result.overall_star_rating > 0">(

                  {{ result.overall_star_rating }} )</span>
                  <span class="star-rating-count" *ngIf="result.overall_star_rating <= 0">Plan Is New</span>
              </div>
          </div>
      </td>
      <td class="w-63 column-triple-content data-plan-value">
        <div class="column-triple-content-inner">
          <div class="flex-auto">
            <div class="column-visits">
              <div class="value-wrapper-top">
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.doctors_office_visit.inNetwork[0] | lowercase).includes('copay') && (result.taptap_benefits?.doctors_office_visit.inNetwork[0] | lowercase).includes('coinsurance')"
                    class="main-value value-bold" *ngIf="result.taptap_benefits?.doctors_office_visit.inNetwork[0]"
                    [innerHTML]="result.taptap_benefits?.doctors_office_visit.inNetwork[0].replace('Primary Physician:','').replace('<br>', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.doctors_office_visit.inNetwork[0]">N/A</span>
                  <span class="sub-value hide-on-desktop">Primary</span>
                </div>
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.lab_diagnostic.inNetwork[2] | lowercase).includes('copay') && (result.taptap_benefits?.lab_diagnostic.inNetwork[2] | lowercase).includes('coinsurance')"
                    class="main-value value-bold" *ngIf="result.taptap_benefits?.lab_diagnostic.inNetwork[2]"
                    [innerHTML]="result.taptap_benefits.lab_diagnostic.inNetwork[2].replace('for Lab Services','').replace('.', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.lab_diagnostic.inNetwork[2]">N/A</span>
                  <span class="sub-value hide-on-desktop">Tests & Procedures</span>
                </div>
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.outpatient_surgery.inNetwork[0] | lowercase).includes('copay') && (result.taptap_benefits?.outpatient_surgery.inNetwork[0] | lowercase).includes('coinsurance')"
                    class="main-value value-bold" *ngIf="result.taptap_benefits?.outpatient_surgery.inNetwork[0]"
                    [innerHTML]="result.taptap_benefits.outpatient_surgery.inNetwork[0].replace('.', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.outpatient_surgery.inNetwork[0]">N/A</span>
                  <span class="sub-value hide-on-desktop">Surgical Procedure</span>
                </div>
              </div>
              <div class="value-wrapper-bottom">
                <span class="sub-value">Primary Doctor</span>
                <span class="sub-value">Tests & Procedures</span>
                <span class="sub-value">Surgical Procedure</span>
              </div>
            </div>
            <div class="column-tests">
              <div class="value-wrapper-top">
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.doctors_office_visit.inNetwork[1] | lowercase).includes('copay') && (result.taptap_benefits?.doctors_office_visit.inNetwork[1] | lowercase).includes('coinsurance')"
                    class="main-value value-bold" *ngIf="result.taptap_benefits?.doctors_office_visit.inNetwork[1]"
                    [innerHTML]="result.taptap_benefits.doctors_office_visit.inNetwork[1].replace('Specialist Physician:','').replace('<br>', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.doctors_office_visit.inNetwork[1]">N/A</span>
                  <span class="sub-value hide-on-desktop">Specialists</span>
                </div>
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.lab_diagnostic.inNetwork[1] | lowercase).includes('copay') && (result.taptap_benefits?.lab_diagnostic.inNetwork[1] | lowercase).includes('coinsurance')"
                    class="main-value value-bold" *ngIf="result.taptap_benefits?.lab_diagnostic.inNetwork[1]"
                    [innerHTML]="result.taptap_benefits.lab_diagnostic.inNetwork[1].replace('for Diagnostic Tests','').replace('.', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.lab_diagnostic.inNetwork[1]">N/A</span>
                  <span class="sub-value hide-on-desktop">Lab Services</span>
                </div>
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.inpatient_hospital_care.inNetwork[0] | lowercase).includes('copay') && (result.taptap_benefits?.inpatient_hospital_care.inNetwork[0] | lowercase).includes('coinsurance')"
                    class="main-value value-bold"
                    *ngIf="result.taptap_benefits?.inpatient_hospital_care.inNetwork[0]"
                    [innerHTML]="result.taptap_benefits.inpatient_hospital_care.inNetwork[0].replace('/.*?/+', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.inpatient_hospital_care.inNetwork[0]">N/A</span>
                  <span class="sub-value hide-on-desktop"
                    *ngIf="result.taptap_benefits?.inpatient_hospital_care.range[0]"
                    [innerHTML]="result.taptap_benefits?.inpatient_hospital_care.range[0]"></span>
                  <span class="sub-value hide-on-desktop"
                    *ngIf="!result.taptap_benefits?.inpatient_hospital_care.range[0]">N/A</span>
                </div>
              </div>
              <div class="value-wrapper-bottom">
                <span class="sub-value">Specialists</span>
                <span class="sub-value">Lab Services</span>
                <span class="sub-value" *ngIf="result.taptap_benefits?.inpatient_hospital_care.range[0]"
                  [innerHTML]="result.taptap_benefits?.inpatient_hospital_care.range[0]"></span>
                <span class="sub-value "
                  *ngIf="!result.taptap_benefits?.inpatient_hospital_care.range[0]">N/A</span>
              </div>
            </div>
            <div class="column-surgery">
              <div class="value-wrapper-top">
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.outpatient_rehabilitation.physical_therapy | lowercase).includes('copay') && (result.taptap_benefits?.outpatient_rehabilitation.physical_therapy | lowercase).includes('coinsurance')"
                    *ngIf="result.taptap_benefits?.outpatient_rehabilitation.physical_therapy"
                    class="main-value value-bold"
                    [innerHTML]="result.taptap_benefits?.outpatient_rehabilitation.physical_therapy.replace('- ', '-')"></span>
                  <span *ngIf="!result.taptap_benefits?.outpatient_rehabilitation.physical_therapy"
                    class="main-value value-bold">N/A</span>
                  <span class="sub-value hide-on-desktop">Therapy/Rehab</span>
                </div>
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.lab_diagnostic.inNetwork[0] | lowercase).includes('copay') && (result.taptap_benefits?.lab_diagnostic.inNetwork[0] | lowercase).includes('coinsurance')"
                    class="main-value value-bold" *ngIf="result.taptap_benefits?.lab_diagnostic.inNetwork[0]"
                    [innerHTML]="result.taptap_benefits.lab_diagnostic.inNetwork[0].replace('for Diagnostic Radiology Services','').replace('.', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.lab_diagnostic.inNetwork[0]">N/A</span>
                  <span class="sub-value hide-on-desktop">Diagnostic Radiology</span>
                </div>
                <div class="value-wrapper">
                  <span
                    [class.small-span]="(result.taptap_benefits?.inpatient_hospital_care.inNetwork[1] | lowercase).includes('copay') && (result.taptap_benefits?.inpatient_hospital_care.inNetwork[1] | lowercase).includes('coinsurance')"
                    class="main-value value-bold"
                    *ngIf="result.taptap_benefits?.inpatient_hospital_care.inNetwork[1]"
                    [innerHTML]="result.taptap_benefits.inpatient_hospital_care.inNetwork[1].replace('/.*?/+', '').replace('- ', '-')"></span>
                  <span class="main-value value-bold"
                    *ngIf="!result.taptap_benefits?.inpatient_hospital_care.inNetwork[1]">N/A</span>
                  <span class="sub-value hide-on-desktop"
                    *ngIf="result.taptap_benefits?.inpatient_hospital_care.range[1]"
                    [innerHTML]="result.taptap_benefits?.inpatient_hospital_care.range[1]"></span>
                  <span class="sub-value hide-on-desktop"
                    *ngIf="!result.taptap_benefits?.inpatient_hospital_care.range[1]">N/A</span>
                </div>
              </div>
              <div class="value-wrapper-bottom">
                <span class="sub-value">Therapy/Rehab</span>
                <span class="sub-value">Diagnostic Radiology</span>
                <span class="sub-value" *ngIf="result.taptap_benefits?.inpatient_hospital_care.range[1]"
                  [innerHTML]="result.taptap_benefits?.inpatient_hospital_care.range[1]"></span>
                <span class="sub-value" *ngIf="!result.taptap_benefits?.inpatient_hospital_care.range[1]">N/A</span>
              </div>
            </div>
          </div>
          <div class=" w-150 column-vision result-include-icons">
            <div *ngIf="result.taptap_benefits?.vision_services.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-vision.PNG"
                class="result-vision-icon" alt="Coverage For Eyeglasses" matTooltip="Coverage For Eyeglasses"
                matTooltipClass="bg-primary" matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.preventive_dental_services.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-hearing.png"
                class="result-hearing-icon" alt="Coverage For Hearing Aids" matTooltip="Coverage For Hearing Aids"
                matTooltipClass="bg-primary" matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.non_opioid_pain_management.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-acupuncture.svg"
                alt="Acupuncture Coverage" matTooltip="Acupuncture Coverage" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div *ngIf="false"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-chiropractic.svg"
                alt="Chiropractic Coverage" matTooltip="Chiropractic Coverage" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.preventive_dental_services.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-dental.svg"
                alt="Dental Coverage" matTooltip="Dental Coverage" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.defined_supplemental_benefits?.fitness?.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-fitness.svg"
                alt="Fitness/Gym Benefit" matTooltip="Fitness/Gym Benefit" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.meal_benefit.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-healthy-foods.svg"
                alt="healthy foods" matTooltip="Healthy Foods" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div
              *ngIf="result.taptap_benefits?.defined_supplemental_benefits?.home_support_services.is_include == 1">
              <img src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-home-support-services.svg"
                alt="home support services icon" matTooltip="Home Support Services" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.defined_supplemental_benefits?.massage_therapy.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-massage-therapy.svg"
                alt="massage therapy icon" matTooltip="Massage Therapy" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.over_the_counter_items.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-OTC.svg" alt="OTC icon"
                matTooltip="OTC" matTooltipClass="bg-primary" matTooltipPosition="above"></div>
            <div
              *ngIf="result.taptap_benefits?.defined_supplemental_benefits?.alternative_therapies.is_include == 1">
              <img src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-other-extra-benefits-1.png"
                alt="other extra benefits" matTooltip="Other Extra Benefits Icon" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div
              *ngIf="result.taptap_benefits?.defined_supplemental_benefits?.bathroom_safety_devices.is_include == 1">
              <img src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-other-extra-benefits-2.png"
                alt="other extra benefits" matTooltip="Other Extra Benefits Icon" matTooltipClass="bg-primary"
                matTooltipPosition="above"></div>
            <div *ngIf="result.taptap_benefits?.transportation.is_include == 1"><img
                src="./assets/img/medicare-advantage/mapd-result/mapd-includes-icon-transportation.png"
                class="includes-transportation" alt="transportation icon" matTooltip="Transportation"
                matTooltipClass="bg-primary" matTooltipPosition="above"></div>
          </div>
        </div>

        <!-- <div class="plan-documents-button-wrapper">                      
          <button id="#button1_{{result.key}}" class="more-button btn btn-third btn-xs position-relative mx-2" (click)="onclick_planDocsResources(result.key)">
            <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
            More Benefits & Documents
            <div class="d-none faint-line position-absolute" id="line1_{{result.key}}" [class.withDrugCost]="isViewDrugCost"></div>
          </button>
          <button id="#button2_{{result.key}}" class="more-button btn btn-third btn-xs position-relative mx-2" (click)="onclick_doctorDrugAnalysis(result.key)">
            <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
            Doctors & Drugs
            <div class="d-none faint-line position-absolute" id="line2_{{result.key}}" [class.withDrugCost]="isViewDrugCost"></div>
          </button>                      
        </div> -->

        <!-- <div class="plan-documents-button-wrapper">
          <a class="btn btn-first btn-xs mr-1" target="_blank" href="/insurance-marketplace/medicare-advantage/plan-profile/{{result.key}}/{{this.controls.zipcode.value}}/{{this.planYear}}" id="view_benefits_{{result.organization_name}}_{{result.key}}_{{i}}"> <i class="fa fa-search mr-2" aria-hidden="true"></i> View All Benefits 1</a> -->
        <!-- <button class="btn btn-first btn-xs mr-1" (click)="clickPlanDocsResources($event)"><i class="fa fa-file-text-o mr-2" aria-hidden="true"></i> View Plan Documents</button> -->
        <!-- <button id="#button1_{{result.key}}" class="btn btn-first btn-xs mr-1 position-relative" (click)="onclick_planDocsResources(result.key)">
            <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
            View Plan Documents 1
            <div class="d-none faint-line position-absolute" id="line1_{{result.key}}"></div>
          </button>
          <button id="#button2_{{result.key}}" class="btn btn-first btn-xs position-relative" (click)="onclick_doctorDrugAnalysis(result.key)">
            <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
            Doctors & Drugs Analysis 1
            <div class="d-none faint-line position-absolute" id="line2_{{result.key}}"></div>
          </button>                      
        </div> -->
      </td>
      <td class="w-12 costs-wrapper border-0">
        <span class="fColor-green uppercase fWeight-600 fSize-20">Premium</span>
        <span class="main-value fColor-black m-0 mb-3 mt-1">{{ getConvertedRate(result.month_rate) | currency }}</span>
          <!-- <span class="sub-value mb-3">Per Month</span> -->

          <mat-divider class="mat-divider-cost"></mat-divider>

          <span class="fColor-green uppercase fWeight-600" style="font-size: 13px;">Deductible</span>
          <span class="main-value fColor-black m-0 mb-2 mt-1" style="font-size:20px">

            {{ result.plan_deductible | currency }}</span>
            <!-- <span class="sub-value mb-3"> Per Year</span> -->

          <mat-divider class="mat-divider-cost mb-2"></mat-divider>

            <span class="fColor-green uppercase fWeight-600 text-center mt-3"
              style="font-size: 13px;line-height: 1em;">Out-of-Pocket Max</span>
            <span class="main-value fColor-black m-0 mb-3 mt-1" style="font-size:20px">

              {{ result.in_network_moop }}</span>
              <!-- <span class="max-out-pocket"></span> -->

              

              <ng-container *ngIf="isViewDrugCost">
                <mat-divider
                  style="margin: 10px 0 20px;border-top-width:2px;border-top-style:dashed;border-top-color: #969696;">
                </mat-divider>
                <ng-container *ngFor="let costs of groupDrugCost">
                  <ng-container *ngIf="costs.contract_id == result.contract_id && costs.plan_id == result.plan_id">
                    <span class="fColor-green uppercase fWeight-600 text-center"
                      style="font-size: 13px;line-height: 1em;">Drug Costs</span>
                    <span class="main-value fColor-black m-0 mb-3 mt-1" style="font-size:20px">

                      {{ costs.total_drug_cost | currency }}</span>
                      <a class="view-drugs-cost-details-btn mb-0" target="_blank"
                        href="/insurance-marketplace/medicare-advantage/plan-profile/drug/{{result.key}}/{{this.controls.zipcode.value}}/{{this.planYear}}/{{this.controls.zipcode.value}}"
                        id="view_benefits_{{result.organization_name}}_{{result.key}}_{{i}}">View Details Here</a>
                  </ng-container>
                </ng-container>
              </ng-container>
      </td>
    </tr>
    <tr>
      <div class="plan-document-buttons d-flex flex-column flex-md-row pt-3">
        <!-- <button class="btn btn-first btn-xs mr-1" (click)="clickPlanDocsResources($event)"><i class="fa fa-file-text-o mr-2" aria-hidden="true"></i> View Plan Documents</button> -->
        <div class="w-100 mx-md-2 mb-2 mb-md-0">
          <button id="#button1_{{result.key}}" class="more-button btn btn-third btn-xs position-relative"
            (click)="onclick_planDocsResources(result.key)">
            <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
            More Benefits & Documents
          </button>
          <div class="d-none justify-content-center mt-2" id="arrow1_{{result.key}}">
            <div class="arrow"></div>
          </div>
        </div>
        <div class="w-100 mx-md-2 mb-2 mb-md-0">
          <button id="#button2_{{result.key}}" class="more-button btn btn-third btn-xs position-relative"
            (click)="onclick_doctorDrugAnalysis(result.key)">
            <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
            Doctors & Drugs
          </button>
          <div class="d-none justify-content-center mt-2" id="arrow2_{{result.key}}">
            <div class="arrow"></div>
          </div>
        </div>
        <div class="w-100 mx-md-2 mb-2 mb-md-0">
          <button id="#button3_{{result.key}}" class="compare-button btn btn-third btn-xs position-relative"
            (click)="onclick_planActionButtons(result.key)">
            Save, Compare, or Enroll
            <!-- <img src="./assets/img/pointer2.png" class="pointer-finger floating position-absolute" alt=""> -->
          </button>
          <div class="d-none justify-content-center mt-2" id="arrow3_{{result.key}}">
            <div class="arrow"></div>
          </div>
        </div>
      </div>
    </tr>
  </table>

  <!-- PLAN ACTION BUTTONS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="plan_actions_id_{{result.key}}">
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100 enrollment-button">
        <!-- <button class="saveplan-button w-100 my-2 btn btn-small btn-second">Save This Plan</button>
        <button class="saveplan-button w-100 my-2 btn btn-small btn-second">Share This Plan</button>
        <div class="addcompare-button w-100 my-2 d-flex align-items-center justify-content-center">
          <input type="checkbox" class="compare-input" id="addToCompare_{{result.key}}"
            (change)="onClick_addToCompare(result,$event)" [checked]="logComparedKeys.includes(result.key)">
          <label class="compare-label mb-0" for="addToCompare_{{result.key}}">Add to Compare</label>
        </div>
        <a target="_blank"
          href="/insurance-marketplace/medicare-advantage/results/compare-plans?zipcode={{controls.zipcode.value}}&planyear={{planYear}}&plan={{controls.plan.value}}&compare={{part_d_id}}"
          class="viewComparePlansBtn btn btn-third-plain btn-xs d-none ff mt-2"
          id="viewComparePlansBtn_{{result.key}}"
          [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a> -->
          <button class="save d-flex align-items-center px-3 py-2 my-2" (click)="saveThisPlan()">
            <img class="small-icon" src="./assets/img/common/save.svg" alt="">
            <div class="d-flex justify-content-center flex-grow-1">
              <span>SAVE THIS PLAN</span>
            </div>
          </button>
          <app-share-plan [sharePlanId]=result.key></app-share-plan>
          <button class="add d-flex align-items-center my-2 add-to-compare-button">
            <div class="checkbox-wrapper d-flex align-items-center justify-content-center ml-4">
              <input type="checkbox" class="compare-input" id="addToCompare_{{result.key}}"
              (change)="onClick_addToCompare(result,$event)" [checked]="logComparedKeys.includes(result.key)">
            </div>
            <div class="d-flex justify-content-center flex-grow-1 add-to-compare-label-wrapper">
              <label for="addToCompare_{{result.key}}">ADD TO COMPARE</label>
            </div>
          </button>
          <a target="_blank"
          href="/insurance-marketplace/medicare-advantage/results/compare-plans?zipcode={{controls.zipcode.value}}&planyear={{planYear}}&plan={{controls.plan.value}}&compare={{part_d_id}}"
          class="viewComparePlansBtn btn btn-third-plain btn-sm d-none ff mt-2"
          id="viewComparePlansBtn_{{result.key}}"
          [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a>
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100 enrollment-button">
        <!-- <button class="apply-button w-100 my-2 btn btn-small btn-second"
          (click)="onClick_EnrollOnline(result,i)">Enroll Online</button>
        <button class="apply-button w-100 my-2 btn btn-small btn-second"
          (click)="onClick_EnrollOnThePhone(result)">Enroll On The Phone</button>
        <button class="apply-button w-100 my-2 btn btn-small btn-second">
          Add To Cart</button> -->

        <button class="online d-flex align-items-center px-3 py-2 my-2" (click)="onClick_EnrollOnline(result,i)">
          <img class="small-icon" src="./assets/img/common/online.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>ENROLL ONLINE</span>
          </div>
        </button>
        <button class="phone d-flex align-items-center px-3 py-2 my-2" (click)="onClick_EnrollOnThePhone(result)">
          <img class="small-icon" src="./assets/img/common/phone.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>ENROLL ON THE PHONE</span>
          </div>
        </button>
        <button class="cart d-flex align-items-center p-0" id="ci_cart_btn__{{result.key}}" [class.active]="logCartKeys.includes(result.key)">
          <label class="cart-btn-label" for="ci_add_to_cart__{{result.key}}">
            <img class="small-icon" src="./assets/img/common/cart.svg" alt="">
            <span class="flex-grow-1">{{ addToCartLabel }}</span>
          </label>
        </button>
        <input type="checkbox" class="d-none" id="ci_add_to_cart__{{ result.key }}" (change)="onClick_AddToCart(result,$event)" [checked]="logCartKeys.includes(result.key)">
        <a target="_blank"
            href="/cart"
            class="viewCartBtn btn btn-third-plain btn-sm d-none ff mt-2"
            id="view_cart_btn_{{result.key}}"
            [class.d-block]="logCartKeys.includes(result.key)">View Cart</a>
      </div>
    </div>
  </div>

  <!-- MORE BENEFITS & DOCUMENTS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="docs_resources_id_{{result.key}}">
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/plan-benefits.png" alt="">
        </div>
        <h4 class="title-header text-center text-dgray">Plan Benefits</h4>
        <div class="w-full d-flex flex-column justify-content-around">
          <a class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second font-14" target="_blank"
            href="/insurance-marketplace/medicare-advantage/plan-details/{{result.contract_id}}/{{result.plan_id}}/{{this.planYear}}/{{this.plan_val}}/{{this.zipCode}}"
            id="view_benefits_{{result.organization_name}}_{{result.key}}_{{i}}">TapTap Interactive Plan Page</a>
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second font-14" (click)="onClick_AdditionalPlanInsights()">Additional Plan Insights</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/guides-documents.png" alt="">
        </div>
        <h4 class="title-header text-center text-dgray">Guides & Documents</h4>
        <div class="w-full d-flex flex-column justify-content-around">
          <!-- <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second font-14">Evidence of Coverage</button>
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second font-14">Wellness and Fitness Guide</button> -->
          <a *ngIf="result.taptap_details?.sob_link" href="{{ result.taptap_details?.sob_link }}" target="_blank"
          class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second font-14">Summary of Benefits</a>
          <button *ngIf="!result.taptap_details?.sob_link"
          class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second font-14"
          (click)="onClick_SummaryOfBenefits(result, 'sob')">Summary of Benefits</button>
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second font-14"
            (click)="onClick_AdditionalDocuments(600, result)">Additional Plan Documents</button>
        </div>
      </div>
    </div>
  </div>

  <!-- DOCTORS & DRUGS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="doctors_drugs_id_{{result.key}}">
    <div class="col-12 col-lg-6 p-4">
      <div class="action-box p-4">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/doctors-providers-new.png" alt="">
        </div>
        <h4 class="title-header2 text-center">Doctors & Providers</h4>
        <div class="w-full d-flex flex-column justify-content-around">
          <button class="w-md-full mx-md-2 my-2 btn btn-small btn-second btn-ghost">
            View Top Providers Taking This Plan
          </button>
          <button class="w-md-full py-2 btn btn-small btn-first fluid mt-2" 
            (click)="addDoctors(result.key, result.contract_id, result.plan_id, result.segment_id)">
              Acceptance By My Doctors & Providers
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4">
      <div class="action-box p-4">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/common/icons/plan-display/drug_pharmacy.svg" alt="">
        </div>
        <h4 class="title-header2 text-center">Drugs & Pharmacies</h4>
        <div class="w-full d-flex flex-column justify-content-around">
          <button class="w-md-full mx-md-2 my-2 btn btn-small btn-second btn-ghost" 
            (click)="onClick_ViewThisPlansDrugCopaysPerTier(940, result)">
              View Basic Drug Coverage Per Tier
          </button>
          <button class="w-md-full py-2 btn btn-small btn-first fluid mt-2" 
            (click)="addDrugs({ marketplace_type: 'mapd', plan_type: plan_val ,state: result.state, county: result.county, zipcode: controls.zipcode.value, contract_id: result.contract_id, plan_id: result.plan_id, segment_id: result.segment_id, plan_year: planYear })">
              Costs With My Drugs & Pharmacies
          </button>
        </div>
      </div>
    </div>
    <!-- PROVIDER LIST >> ONLY DISPLAYED WHEN PROVIDERS/FACILITIES ARE ADDED WITHIN SPECIFIC RESULT ITEM -->
    <div class="col-12" *ngIf="showProvider">
      <div class="d-flex flex-column justify-content-center doctors-facilities mx-auto">
        <div class="doctor-box-wrapper w-100 border my-3 my-lg-0 mx-lg-3">
          <h5 class="title-header text-center mb-0 py-2">Your Doctors</h5>
          <table class="table table-borderless table-striped table-hover">
            <tr *ngFor="let p of providers">
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="doctor-box px-2 d-flex align-items-start">
                    <img class="mr-3" src="./assets/img/providers/doctor.svg" alt="">
                    <div class="d-flex flex-column">
                      <span class="doctor-name">{{p.other_prefix}} {{p.first_name}} {{p.middle_name}}
                        {{p.last_name}} {{p.suffix}} {{p.credential_text}}</span>
                      <span *ngIf="!p.locations || p.locations.length === 0">

                        {{ p.mailing_first_address }},

                        {{ p.mailing_city }}
                         
                        {{ p.mailing_state }}
                      </span>
                        <small *ngIf="p.locations && p.locations.length > 0"
                          class="provider-modal-group-list-item-info-location">

                          {{ p.locations[0].street ? p.locations[0].street : p.locations[0].address_1 }},

                          {{ p.locations[0].suburb_city }} {{ p.mailing_state }}  <!-- add state and county here -->
                        </small>
                        <button (click)="onClickViewMoreModal(p)" class="view-button btn btn-third btn-xs">View More</button>
                    </div>
                  </div>
                  <div class="network-wrapper">
                    <span
                      [class.in-network]="p[result.contract_id + '#' + result.plan_id + '#' + result.segment_id] === 'In-Network'"
                      [class.out-network]="p[result.contract_id + '#' + result.plan_id + '#' + result.segment_id] !== 'In-Network'"
                      class="px-2 py-1">
                      {{ (p[result.contract_id + '#' + result.plan_id + '#' + result.segment_id] === 'In-Network') ? p[result.contract_id + '#' + result.plan_id + '#' + result.segment_id] : 'Out-of-Network'  }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="providers.length === 0">
              <td class="row justify-content-center">You have not added any providers yet.</td>
            </tr>
          </table>
        </div>
        <div class="doctor-box-wrapper w-100 border my-3 my-lg-0 mx-lg-3">
          <h5 class="title-header text-center mb-0 py-2">Your Facilities</h5>
          <table class="table table-borderless table-striped table-hover">
            <tr *ngFor="let f of facilities">
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="doctor-box px-2 d-flex align-items-start">
                    <img class="mr-3" src="./assets/img/providers/hospital-icon.svg" alt="">
                    <div class="d-flex flex-column">
                      <span class="doctor-name">

                        {{ f.organization_name }}</span>
                        <span *ngIf="!f.locations || f.locations.length === 0">

                          {{ f.mailing_first_address }},

                          {{ f.mailing_city }}

                          {{ f.mailing_state }}</span>
                          <small *ngIf="f.locations && f.locations.length > 0"
                            class="provider-modal-group-list-item-info-location">

                            {{ f.locations[0].street ? f.locations[0].street : f.locations[0].address_1 }},

                            {{ f.locations[0].suburb_city }}
                      </small>
                    </div>
                  </div>
                  <div class="network-wrapper">
                    <span
                      [class.in-network]="f[result.contract_id + '#' + result.plan_id + '#' + result.segment_id] === 'In-Network'"
                      [class.out-network]="f[result.contract_id + '#' + result.plan_id + '#' + result.segment_id] !== 'In-Network'"
                      class="px-2 py-1">

                      {{ f[result.contract_id + '#' + result.plan_id + '#' + result.segment_id] }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="facilities.length === 0">
              <td class="row justify-content-center">You have not added any facilities yet.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- DRUGS LIST >> ONLY DISPLAYED WHEN DRUGS/PHARMACIES ARE ADDED WITHIN SPECIFIC RESULT ITEM -->
    <div class="col-12" *ngIf="showDrugs">
      <div class="d-flex flex-column justify-content-center doctors-facilities mx-auto" *ngIf="drugsList">
        <div class="doctor-box-wrapper w-100 border my-3 my-lg-0 mx-lg-3">
          <h5 class="title-header text-center mb-0 py-2">Your Drugs</h5>
          <table class="table table-borderless table-striped table-hover">
            <tr *ngFor="let drug of drugsList">
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="doctor-box px-2 d-flex align-items-center">
                    <img class="mr-3" src="./assets/img/my-drugs/orange-capsule.svg" alt="">
                    <div class="d-flex flex-column">
                      <span class="doctor-name">{{ drug.drug_name }}</span>
                      <span>{{ drug.details.dosage }} | {{ drug.times_taken }}/{{ drug.often_taken.replace('per- ','') }}</span>
                    </div>
                  </div>
                  <button class="view-button btn btn-third btn-xs flex-shrink-1"
                    (click)="getDrugMoreDetails(drug)">View More</button>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="doctor-box-wrapper w-100 border my-3 my-lg-0 mx-lg-3" *ngIf="pharmacyList">
          <h5 class="title-header text-center mb-0 py-2">Your Pharmacies</h5>
          <table class="table table-borderless table-striped table-hover">
            <tr *ngFor="let pharmacy of pharmacyList">
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="doctor-box px-2 d-flex align-items-center">
                    <img class="mr-3" src="./assets/img/my-drugs/pharmacy.svg" alt="">
                    <div class="d-flex flex-column">
                      <span class="doctor-name">{{ pharmacy.pharmacy_name }}</span>
                      <span>{{ pharmacy.pharmacy_address }}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- specific drug details modal-->
<div class="d-none">
  <div class="dp-card" #specificDrugDetailsModal>
    <h4 class="text-center fWeight-600 mt-3">Specific Drug Details</h4>
    <h6 class="fColor-base fWeight-600 text-center">Confirm Your Drug</h6>
    <div *ngIf="formCheck == 'tablet'">
      <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="d-block mt-0 mx-auto max-w-90">
    </div>
    <div *ngIf="formCheck == 'capsule'">
      <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="d-block mt-0 mx-auto max-w-90">
    </div>
    <div *ngIf="formCheck == 'injection'">
      <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="d-block mt-0 mx-auto max-w-90">
    </div>
    <div *ngIf="formCheck == 'meds'">
      <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="d-block mt-0 mx-auto max-w-90">
    </div>
    <app-loading *ngIf="isLoading" class="loading"></app-loading>
    
    <!-- default details -->
    <div class="row drug-details text-center text-md-left mt-4" *ngIf="drugViewMoreDetail">
      <div class="col-12">
        <div class="row">
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Drug Name:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ strip_drug_name }}</span>
        </div>
        <div class="row mt-3 align-items-center">
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Dosage:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ drugViewMoreDetail[0][0]['active_numerator_strength'] + ' ' + drugViewMoreDetail[0][0]['active_ingredient_unit'].slice(0, drugViewMoreDetail[0][0]['active_ingredient_unit'].indexOf('/')) }}</span>
        </div>
        
        <div class="row mt-3 align-items-center">
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Amount Taken:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ viewMore_TimesTaken + '/' + viewMore_OftenTaken.replace('per-','') }}</span>
        </div>
        <div class="row mt-3 align-items-center">
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Days Supply:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ viewMore_DaysSupply }}</span>
        </div>
        <div class="row mt-3"> 
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Package Form:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ (drugViewMoreDetail[0][0]['dosage_form_name']) ? drugViewMoreDetail[0][0]['dosage_form_name'].replace('TABLET','ORAL TABLET') : 'n/a'}}</span>
        </div>
        <div class="row mt-3"> 
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Dosage Form:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ (drugViewMoreDetail[0][0]['dosage_form']) ? drugViewMoreDetail[0][0]['dosage_form'].replace('TABLET','ORAL TABLET') : 'n/a'}}</span>
        </div>
      </div>
    </div>

    <!-- additional details -->
    <div class="row drug-details text-center text-md-left mt-5" *ngIf="drugViewMoreDetail && showMoreDrugDetails">
      <h5 class="col-12 fWeight-600 mb-2">Additional Details</h5>
      <div class="col-12">              
        <div class="row mt-3">
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Manufacturer:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ (drugViewMoreDetail[0][0]['labeler_name']) ? drugViewMoreDetail[0][0]['labeler_name'] : 'n/a' }}</span>
        </div>
        <div class="row mt-3"> 
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">US Drug Release Date:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ drugViewMoreDetail[0][0]['start_marketing_date'] | date: 'MM/dd/yyyy' }}</span>
        </div>
        <div class="row mt-3"> 
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Brand Name Version:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ (drugViewMoreDetail[0][0]['proprietary_name']) ? drugViewMoreDetail[0][0]['proprietary_name'] : 'n/a' }}</span>
        </div>
        <div class="row mt-3"> 
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Generic Name Version:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ (drugViewMoreDetail[0][0]['non_proprietary_name']) ? drugViewMoreDetail[0][0]['non_proprietary_name'] : 'n/a' }}</span>
        </div>
        <div class="row mt-3">
          <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Drug NDC:</span>
          <span class="col-12 col-md-8 fWeight-500">{{ (drugViewMoreDetail[0][0]['ndc']) ? drugViewMoreDetail[0][0]['ndc'] : 'n/a' }}</span>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button class="btn btn-second-fill btn-xs" (click)="onClick_MoreDrugDetails(showMoreDrugDetails)">{{ moreDrugDetails_text }}</button>
    </div>

  </div>
</div>

<div class="d-none">
  <div #starRatingDetails>
    <app-mapd-star-rating></app-mapd-star-rating>
  </div>
</div>