<div class="col-md-12 mt-50">
  <div class="container">
    <div class="row flex-md-row align-items-center">
      <div class="row col-12 justify-content-center text-center padding-50">
        <h2 *ngIf="!showBottomPadding">
          3 Enjoyable Options To Choose From
        </h2>
        <h5 class="normal mt-20" [class.adjust-text]="showBottomPadding">
          Click to Choose Any of The Options Below:
        </h5>
      </div>
    </div>
  </div>
</div>

<div class="blue-bg" [class.col-12]="!showBottomPadding" [class.container]="showBottomPadding">
  <div class="banner-pointer-wrapper-secondary mb-0">
    <i class="fa fa-arrow-down"></i>
    <div class="banner-pointer-background"></div>
  </div>
</div>
<div class="blue-bg neg-83-margin-top" [class.col-md-12]="!showBottomPadding" [class.container]="showBottomPadding" [class.adjust-padding]="showBottomPadding">
  <div class="container">
    <div class="row flex-md-row align-items-center">
      <div class="row col-12 justify-content-center text-center padding-50 mt-50">

        <div class="col-md-3 row justify-content-center card-option" (click)="speakToCounselorEvent(speakToCounselorModal)">
          <div class="col-12 center">
            <h5>Call Us Now</h5>
          </div>
          <div class="col-8 center mt-30">
            <div class="thumbnail1"></div>
          </div>
          <div class="col-12">
            &nbsp;
          </div>
        </div>

        <div class="col-md-3 row justify-content-center card-option">
          <div class="col-12 center">
            <h5>Schedule a Time<br>For Later</h5>
          </div>
          <div class="col-8 center mt-30">
            <div class="thumbnail2"></div>
          </div>
          <div class="col-12">
            &nbsp;
          </div>
        </div>

        <div class="col-md-3 row justify-content-center card-option" routerLink="/contact">
          <div class="col-12 center">
            <h5>Send Us a Message</h5>
          </div>
          <div class="col-8 center mt-30">
            <div class="thumbnail3"></div>
          </div>
          <div class="col-12">
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>