import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pharmacies-order-table',
  templateUrl: './pharmacies-order-table.component.html',
  styleUrls: ['./pharmacies-order-table.component.scss']
})
export class PharmaciesOrderTableComponent implements OnInit {

  @Input() tableTitle:any;
  @Input() drugsTier: Array<Object> = [];

  constructor() { }

  ngOnInit() {
    console.log("bbbbbbbbbbb");
    console.log(this.drugsTier);
  }

}
