<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Final Questions</h4>
    <div class="yellow-line"></div>
    <div>
      <div class="dashed-box border shadow p-4 my-3">
        <div class="mb-3">
          <h6 class="text-center fw-bold text-dblue">Will you have other prescription drug coverage in addition to this plan?</h6>
          <div class="d-flex justify-content-center">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="otherPrescription_1" name="otherPrescription" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="otherPrescription_1">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="otherPrescription_2" name="otherPrescription" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="otherPrescription_2">No</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 col-md-4 p-2">
            <label for="coverageName" class="text-label">Name of Other Coverage</label>
            <input type="text" class="form-control blurred" id="coverageName"/>
          </div>
          <div class="form-group col-12 col-md-4 p-2">
            <label for="coverageID" class="text-label">ID# for this Coverage</label>
            <input type="text" class="form-control blurred" id="coverageID"/>
          </div>
          <div class="form-group col-12 col-md-4 p-2">
            <label for="groupID" class="text-label">Group # for this Coverage</label>
            <input type="text" class="form-control blurred" id="groupID"/>
          </div>
        </div>
      </div>

      <div class="dashed-box border shadow p-4 my-3">
        <div class="mb-3">
          <h6 class="text-center fw-bold text-dblue">Are you a resident in a long-term care facility, such as a nursing home? </h6>
          <div class="d-flex justify-content-center">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="resident_1" name="resident" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="resident_1">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="resident_2" name="resident" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="resident_2">No</label>
            </div>
          </div>
        </div>
        <p><em>If “<strong>YES</strong>", please provide the following information:</em></p>
        <div class="row">
          <div class="form-group col-12 p-2">
            <label for="coverageName" class="text-label">What is the Name of the long-term care Institution?</label>
            <input type="text" class="form-control blurred" id="coverageName"/>
          </div>
          <div class="form-group col-12 p-2">
            <label for="coverageID" class="text-label">What is the Address of the long-term care Institution?</label>
            <input type="text" class="form-control blurred" id="coverageID"/>
          </div>
        </div>
      </div>

      <div class="dashed-box border shadow p-4 my-3">
        <div>
          <h6 class="text-center fw-bold text-dblue">Do you work?</h6>
          <div class="d-flex justify-content-center">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="work_1" name="work" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="work_1">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="work_2" name="work" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="work_2">No</label>
            </div>
          </div>
        </div>
      </div>

      <div class="dashed-box border shadow p-4 my-3">
        <div>
          <h6 class="text-center fw-bold text-dblue">Does your spouse work?</h6>
          <div class="d-flex justify-content-center">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="spouseWork_1" name="spouseWork" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="spouseWork_1">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="spouseWork_2" name="spouseWork" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="spouseWork_2">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>
</div>