import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-taptap-new-to-medicare',
  templateUrl: './taptap-new-to-medicare.component.html',
  styleUrls: ['./taptap-new-to-medicare.component.scss']
})
export class TaptapNewToMedicareComponent implements OnInit {
  fullPrimaryHeader: boolean = true;
  dblocking: string = 'd-none';
  private baseUrl = window.location.origin;

  constructor(private titleService:Title) {
    this.titleService.setTitle("New to Medicare");
  }

  ngOnInit() {}

  goToGuide() {
    window.open(`${this.baseUrl}/education/hub/guides`, '_blank');
  }

  scheduleTime() {
    window.open(
      'https://brightprice.gigabook.com/book/all#panel_step_choose',
      '_blank'
    );
  }

  onClickChat($event) {
    $event.defaultPrevented;
    let launcherFrame = $('.aio-launcher-frame');
    let button = launcherFrame.contents().find('#aio-launcher-container');
    button.trigger('click');
  }

  speakToCounselorEvent(modalViews: any, modalType) {
    this.dblocking = 'd-block';

    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: modalType,
      padding: '3em',
      onClose: () => {
        this.dblocking = 'd-none';
      }
    });
  }
}
