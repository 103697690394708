import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-map-v14',
  templateUrl: './google-map-v14.component.html',
  styleUrls: ['./google-map-v14.component.scss']
})
export class GoogleMapV14Component implements OnInit {

  constructor() {}

  ngOnInit(): void {}

  mapOptions: google.maps.MapOptions = {
    center: { lat: 39.94842, lng: -75.16505 },
    zoom : 15
  }

  marker = {
    position: { lat: 39.94842, lng: -75.16505 },
  }

}
