import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    if (!text || !search) {
      return text;
    }
    const regex = new RegExp(search, 'gi');
    return text.replace(regex, (match) => `<mark>${match}</mark>`) || text;
  }
}