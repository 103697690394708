import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
/*import {AuthService} from './auth/auth.service';*/
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/*import { User } from './users/user.model';*/



@Injectable()
export class ServerService {
    constructor(private http: HttpClient) {}
    //users;
    store(params: any[]) {
        return this.http.post('https://taptapdev-app.firebaseio.com/data.json', params);
    }

    getUsers() {
        //const token = this.authService.getToken();

        //return this.http.get('https://taptapdev-app.firebaseio.com/users.json');

        //return 1;

        /*return this.http.get('https://taptapdev-app.firebaseio.com/user.json?auth' + token)
            .map(
                (response: Response) => {
                    console.log(response);
                }
            );*/
    }

    /*getUsers2() {
        return this.http.get('https://taptapdev-app.firebaseio.com/users.json')
            .subscribe(
                (response: Response) => {
                    const users = response.json();
                    console.log('23');
                    console.log(users);
                    this.users = users;
                    return this.users;
                }
            )
    }*/

    getUsers3() {
        /*return this.http.get('https://taptapdev-app.firebaseio.com/users.json')
            .pipe(map(res => res.json()));*/
    }

    getUsers4() {
        /*return this.http.get('https://taptapdev-app.firebaseio.com/users.json')
            .subscribe(
                (response: Response) => {
                    const users: User[] = response.json();
                    //console.log(users);
                    //this.recipe.
                }
            );*/
    }
}

