<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper pb-5">
    <div class="row">
      <div class="col-12">
        <h1 class="medium banner-main-title text-center pb-0">
          Provider's Status With All Plans
        </h1>
      </div>
    </div>
  </div>
</div>
<!--END BANNER-->

<div class="provider-detail-network-status">
  <div class="container">
    <div class="d-flex justify-content-center p-3">
      <div class="provider-wrapper p-3 pr-4 d-flex align-items-center">
        <img class="doctor mr-3" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
        <div>
          <p class="provider-name">John Wayne Humphrey DC</p>
          <span class="provider-loc">329 STATE ROAD, GREAT BARRINGTON MA</span>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="plans-box">
        <div class="d-flex justify-content-between px-3 py-0">
          <div class="custom-dropdown dropdown">
            <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
              <!-- ICONS -->
              <i *ngIf="networkSelection == 1" class="icon-all fas fa-globe mr-2"></i>
              <img *ngIf="networkSelection == 2" class="icon-img mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
              <img *ngIf="networkSelection == 3" class="icon-img mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
              <img *ngIf="networkSelection == 4" class="icon-img mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
              <img *ngIf="networkSelection == 5" class="icon-img mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
              <img *ngIf="networkSelection == 6" class="icon-img mr-2" src="./assets/img/provider-network-status/location.png" alt="">
              <img *ngIf="networkSelection == 7" class="icon-img mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
              <!-- LABELS -->
              <h6 *ngIf="networkSelection == 1" class="status-label">All Network</h6>
              <h6 *ngIf="networkSelection == 2" class="status-label in-network">In-Network</h6>
              <h6 *ngIf="networkSelection == 3" class="status-label undetermined">Undetermined</h6>
              <h6 *ngIf="networkSelection == 4" class="status-label ppo-out">Takes This PPO Plan as Out-of-Network Provider</h6>
              <h6 *ngIf="networkSelection == 5" class="status-label out-network">Out-of-Network</h6>
              <h6 *ngIf="networkSelection == 6" class="status-label location">Not At This Location</h6>
              <h6 *ngIf="networkSelection == 7" class="status-label carrier-out">Take This Carrier’s Plan As An Out-of-Network Provider</h6>
            </button>
            <div class="dropdown-menu">
              <button class="plan-item dropdown-item" type="button" [class.active]="networkSelection == 1" (click)="setNetworkPlansData(1)">
                <i class="icon-all fas fa-globe mr-2"></i>
                <h6 class="status-label">All Network</h6>
              </button>
              <button class="plan-item dropdown-item" type="button" [class.active]="networkSelection == 2" (click)="setNetworkPlansData(2)">
                <img class="icon-img mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                <h6 class="status-label in-network">In-Network</h6>
              </button>
              <button class="plan-item dropdown-item" type="button" [class.active]="networkSelection == 3" (click)="setNetworkPlansData(3)">
                <img class="icon-img mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                <h6 class="status-label undetermined">Undetermined</h6>
              </button>
              <button class="plan-item dropdown-item" type="button" [class.active]="networkSelection == 4" (click)="setNetworkPlansData(4)">
                <img class="icon-img mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                <h6 class="status-label ppo-out">Takes This PPO Plan as Out-of-Network Provider</h6>
              </button>
              <button class="plan-item dropdown-item" type="button" [class.active]="networkSelection == 5" (click)="setNetworkPlansData(5)">
                <img class="icon-img mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                <h6 class="status-label out-network">Out-of-Network</h6>
              </button>
              <button class="plan-item dropdown-item" type="button" [class.active]="networkSelection == 6" (click)="setNetworkPlansData(6)">
                <img class="icon-img mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                <h6 class="status-label location">Not At This Location</h6>
              </button>
              <button class="plan-item dropdown-item" type="button" [class.active]="networkSelection == 7" (click)="setNetworkPlansData(7)">
                <img class="icon-img mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                <h6 class="status-label carrier-out">Take This Carrier’s Plan As An Out-of-Network Provider</h6>
              </button>
            </div>
          </div>
          <div class="search-plan-field">
            <i class="fas fa-search mr-2"></i>
            <input type="text" name="" id="" placeholder="Search plans">
          </div>
        </div>

        <div class="row p-3">
          <table class="plan-table table table-borderless table-striped table-hover bg-light">
            <thead>
              <tr>
                <th class="p-0 d-none d-md-block">
                  <div class="row p-0 bottom-border">
                    <div class="col-12 col-md-4 p-3 m-0">
                      <h5 class="table-heading text-center">Plan</h5>
                    </div>
                    <div class="col-12 col-md-8 px-4 py-3 m-0 left-border">
                      <h5 class="table-heading">Network Status</h5>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody> 
              <ng-container *ngFor="let plan of networkSelectionPlans | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <tr *ngIf="networkSelection == 1 || plan.network_status == networkSelection">
                  <td class="p-0">
                    <div class="row p-0">
                      <div class="col-12 col-md-4 px-3 py-4 m-0">
                        <div class="plan-box">
                          <img [src]="plan.logo_url" alt="" class="plan-image">
                          <h6 class="plan-name">{{ plan.name }}</h6>
                        </div>
                      </div>
                      <div class="col-12 col-md-8 px-4 py-3 m-0 left-border">
                        <div *ngIf="plan.network_status == 2" class="network-status d-flex align-items-center justify-content-center justify-content-md-start in-network">
                          <img class="icon-img" src="./assets/img/provider-network-status/in-network.png" alt="">
                          <h6 class="status-label in-network">In-Network</h6>
                        </div>
                        <div *ngIf="plan.network_status == 3" class="network-status d-flex align-items-center justify-content-center justify-content-md-start undetermined">
                          <img class="icon-img" src="./assets/img/provider-network-status/undetermined.png" alt="">
                          <h6 class="status-label undetermined">Undetermined</h6>
                        </div>
                        <div *ngIf="plan.network_status == 4" class="network-status d-flex align-items-center justify-content-center justify-content-md-start ppo-out">
                          <img class="icon-img" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                          <h6 class="status-label ppo-out">Takes This PPO Plan as Out-of-Network Provider</h6>
                        </div>
                        <div *ngIf="plan.network_status == 5" class="network-status d-flex align-items-center justify-content-center justify-content-md-start out-network">
                          <img class="icon-img" src="./assets/img/provider-network-status/out-network.png" alt="">
                          <h6 class="status-label out-network">Out-of-Network</h6>
                        </div>
                        <div *ngIf="plan.network_status == 6" class="network-status d-flex align-items-center justify-content-center justify-content-md-start location">
                          <img class="icon-img" src="./assets/img/provider-network-status/location.png" alt="">
                          <h6 class="status-label location">Not At This Location</h6>
                        </div>
                        <div *ngIf="plan.network_status == 7" class="network-status d-flex align-items-center justify-content-center justify-content-md-start carrier-out">
                          <img class="icon-img" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                          <h6 class="status-label carrier-out">Take This Carrier’s Plan As An Out-of-Network Provider</h6>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <div class="d-flex justify-content-center mt-3">
          <app-custom-pagination [currentPage]="currentPage" [itemsPerPage]="itemsPerPage" [pageButtons]="5" [totalItems]="networkSelectionPlans.length" (pageChanged)="onPageChange($event)"></app-custom-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>