import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs/index';
import { AuthService } from '../../services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2'; 
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-wilmington-delaware',
  templateUrl: './wilmington-delaware.component.html',
  styleUrls: ['./wilmington-delaware.component.scss']
})
export class WilmingtonDelawareComponent implements OnInit {

  fullPrimaryHeader:boolean = true;
  isLoggedInUser:any;
  users: Observable<any>;
  constructor(private db: AngularFirestore,public _auth: AuthService,private titleService:Title) { 
    this.titleService.setTitle("TapTap Delaware");
  }

  ngOnInit() {
    this.users = this.db.collection('users')
          .valueChanges();

    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if(this.isLoggedInUser){
        this.fullPrimaryHeader = false;
    }
  }

  speakToCounselorEvent(modalViews) {
    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
      padding: '3em'
    });  
  }

}
