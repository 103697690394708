import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {MedicareAdvantageService} from '../../../insurance-marketplace/medicare-advantage/medicare-advantage.service';

@Component({
  selector: 'app-mapd-additional-insights',
  templateUrl: './mapd-additional-insights.component.html',
  styleUrls: ['./mapd-additional-insights.component.scss']
})
export class MapdAdditionalInsightsComponent implements OnInit {
  subscription1: Subscription;
  view_default:boolean = true;
  view_carrierPlanDetails:boolean = false;
  view_planRelatedDatesTimelines:boolean = false;
  view_popularityEnrollment:boolean = false;
  view_taptapPlanCarrierInsights:boolean = false;
  allRatings_serviceSupportScore:number = 5;
  allRatings_ProviderFavorScore:number = 4;
  allRatings_overallConsumerScore:number = 3;
  allRatings_PriceStabilityScore:number = 2;

  constructor(private _medicareAdvantageService:MedicareAdvantageService) { }

  ngOnInit() {
    this.subscription1 = this._medicareAdvantageService.get_backToDefaultView_AdditionalPlanInsightsModal().subscribe(d => {
      this.backToDefaultView()
    });
  }

  ngOnDestroy(){
    this.subscription1.unsubscribe();
  }

  onClick_CarrierPlanDetails(event):void{
    event.preventDefault();
    this.resetAllView();
    this.view_carrierPlanDetails = true;
    this.increasePopupWidth();
  }

  onClick_PlanRelatedDatesTimelines(event):void{
    event.preventDefault();
    this.resetAllView();
    this.view_planRelatedDatesTimelines = true;
    this.increasePopupWidth();
  }

  onClick_popularityEnrollment(event):void{
    event.preventDefault();
    this.resetAllView();
    this.view_popularityEnrollment = true;
    this.increasePopupWidth();
  }

  onClick_TaptapPlanCarrierInsights(event):void{
    event.preventDefault();
    this.resetAllView();
    this.view_taptapPlanCarrierInsights = true;
    this.increasePopupWidth();
  }

  resetAllView():void{
    this.view_default = false;
    this.view_carrierPlanDetails = false;
    this.view_planRelatedDatesTimelines = false;
    this.view_popularityEnrollment = false;
    this.view_taptapPlanCarrierInsights = false;
  }

  backToDefaultView():void{
    this.view_default = true;
    this.view_carrierPlanDetails = false;
    this.view_planRelatedDatesTimelines = false;
    this.view_popularityEnrollment = false;
    this.view_taptapPlanCarrierInsights = false;
    this.reducePopupWidth();
  }

  increasePopupWidth():void{
    const popup = document.querySelector<HTMLElement>('.additional-plan-insights');
    popup.style.width = '900px';
  }

  reducePopupWidth():void{
    const popup = document.querySelector<HTMLElement>('.additional-plan-insights');
    popup.style.width = '650px';
  }

}
