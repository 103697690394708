<h4 class="text-center mb-4">Plan Related Dates & Timelines</h4>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-item nav-link active" id="nav-plans-first-year-tab" data-toggle="tab" href="#nav-plans-first-year" role="tab" aria-controls="nav-plans-first-year" aria-selected="true">Plan's First Year</a>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-plans-first-year" role="tabpanel" aria-labelledby="nav-plans-first-year-tab">
    <div class="row p-5">
      <p class="text-center col-12 fWeight-500">Data coming soon...</p>
    </div>
  </div>
</div>