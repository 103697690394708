<!--START BANNER-->
<div class="container-fluid header-banner-wrapper">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-12">
        <div class="banner-text-wrapper text-center">
          <h2 class="banner-main-title">
            Get Clarity About Medicare<br>            
          </h2>
          <h4 class="fWeight-500 fColor-white mb-5">Know How To Ensure a Great Experience</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="banner-pointer-wrapper">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>
<!--END BANNER-->

<section>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="fWeight-600">About The Webinar</h4>
        <p>We know that the Medicare experience is not the easiest (to say the least!).</p>
        <p>And getting the essential information and explanations that are relevant to you, in a clear and convenient way, can be challenging - even frustrating & overwhelming at times.</p>
        <p>For something as important as your healthcare experience in retirement, things should not be so hard and confusing.</p>
        <p>The webinar we offer at TapTap Medicare takes the confusion, and uncertainty, out of Medicare.</p>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end">
        <form id="webinar-form" [formGroup]="webinarForm" [class.form-validated] = "isFormSubmit">
          <div class="webinar-form-top">
            <h5>Save Your Spot For The Webinar.</h5>
            <p>
              <span>Date:</span>
              <span *ngIf="results">{{ results[0].date }}</span>
            </p>
            <p>
              <span>Time:</span>
              <span *ngIf="results">{{ results[0].time }}</span>
            </p>
            <p>
              <span>Duration:</span>
              <span *ngIf="results">{{ results[0].duration }}</span>
            </p>
          </div>
          <div class="webinar-form-bottom webinar-form-bottom d-flex flex-column align-items-center">
            <div class="form-group col-12 d-flex justify-content-center">
                 <a href="https://brightprice.gigabook.com/group/all" target="_blank" class="save-my-seat-btn btn btn-first btn-small">
                  Save My Seat
                 </a>
            </div>
            <p class="text-center fColor-white" style="font-size: 16px;">
              Even if you can't make it, sign up anyway! <br>
              We'll send you the recording
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section id="section_webinar-about-your-teacher" style="background: #f5f5f5;padding-top: 2rem;">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-9">
        <h4 class="fWeight-600">You'll Learn:</h4>
        <ul>
          <li>The Single Most Important Thing to Understand as a Medicare Beneficiary</li>
          <li>The "Lens" With Which to View All Medicare Information and Advertising</li>
          <li>The Bottom Line When it Comes to Medigap vs Medicare Avantage Coverage</li>
          <li>Understanding the Most Essential Decision You Make as A Medicare Beneficiary</li>
          <li>Why "All-inclusive Coverage Experience" Is Your Guiding Star</li>
        </ul>
      </div>
      <div class="col-12 col-md-3 text-right desktopOnly">
        <img src="./assets/img/retiree-dashboard/my-coverage-woman.png" alt="">
      </div>
    </div>
  </div>
</section>

<section id="section_webinar-about-your-teacher">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-3 text-center">
        <img src="./assets/img/common/jon-img.PNG" alt="" style="max-width: fit-content;">
        <h5 class="fWeight-600 fColor-base">Jonathan L. E. Salem</h5>
        <span style="font-size:16px;">Founder of TapTap Medicare</span>
      </div>
      <div class="col-12 col-md-9 pl-4">
        <h4 class="fWeight-600">About Your Teacher:</h4>

        <p>TapTap Medicare’s Webinar is lead by Jonathan Salem, the founder of TapTap Medicare. 
        </p>
        <p>The essential focus and mission of our Webinar is to provide you with the clarity, confidence, and courage to know you can understand and navigate any Medicare scenario that comes your way. 
        </p>
        <p>Jonathan’s approach to teaching about the key concepts to know as a Medicare consumer makes the Medicare system - as well as the healthcare system as a whole - clear, easily understandable, and logical.
        </p>
        <p>Although it seems like "chaos" to many, there is an order and logic to the Medicare system, and we help you see and truly understand this order, and all of the important concepts that impact your experience.
        </p>
      </div>
    </div>
  </div>
</section>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>
