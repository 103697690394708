import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {APIService} from '../../services/api.service';


@Component({
    selector: 'app-email-my-quote',
    templateUrl: './email-my-quote.component.html',
    styleUrls: ['./email-my-quote.component.scss']
})

export class EmailMyQuoteComponent implements OnInit {
    @Input() insurance_type: string;
    @Input() hash: string;
    @Input() selected_pricing_rate: string;

    quote: any = {
    name: new FormControl('', [
        Validators.required,
    ]),
    your_email: new FormControl('', [
        Validators.required,
        Validators.email,
    ]),
    phone: new FormControl('', []),
    to_email: new FormControl('', [
        Validators.required,
        Validators.email,
    ]),
    loading: false,
    msg: '',
    insurance_type: null
    };

    constructor(private _api: APIService) {
        this.quote.insurance_type = this.insurance_type;
    }

    loading: boolean = false;

    ngOnInit() {
    }


    emailYourQuote() {
        this.loading = true;
        this._api.callAPIEndpoint('/api/v1/quotation/email', {
            'name': this.quote.name.value,
            'to_email': this.quote.to_email.value,
            'your_email': this.quote.your_email.value,
            'insurance_type': this.insurance_type,
            'hash': this.hash,
            'selected_pricing_rate': this.selected_pricing_rate,
        }).subscribe(
            (data: any) => {
                console.log(data);
                this.quote.msg = data.msg;
                Swal.fire(data.msg);
                this.loading = false;
                this.emptyFields();
            },
            (err) => {
                this.quote.loading = false;

            }
        );
    }

    emptyFields() {
        this.quote.name.value = '';
        this.quote.to_email.value = '';
        this.quote.your_email.value = '';
        this.quote.phone.value = '';
    }
}
