import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-application-medigap-version1-screen2',
  templateUrl: './screen2.component.html',
  styleUrls: ['./screen2.component.scss']
})
export class Screen2Component implements OnInit {

  dateFieldType: string;
  dateFieldHeading: string;

  policyInsured: boolean = false;

  dateOfBirthFormat = null
  customerDateOfBirth = ""

  constructor() { }

  ngOnInit() {
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

  onReceiveDate(dob: any){
    const idate = new Date(dob.date);
    const fdate = this.formatDate(idate);

    this.dateOfBirthFormat = fdate;
    this.customerDateOfBirth = dob.date;

    // if(dob.type == 'partAEffectiveDate'){
    //   this.partAEffectiveDate = fdate;
    //   this.details.customer_info.part_a_effective_date = dob.date;
    // }

    // if(dob.type == 'partBEffectiveDate'){
    //   this.partBEffectiveDate = fdate;
    //   this.details.customer_info.part_b_effective_date = dob.date;
    // }

    Swal.close();
  }

  formatDate(date) {
    return (this.padTo2Digits(date.getMonth()+1)) + '-' + this.padTo2Digits(date.getDate()) + '-' + date.getFullYear();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

}
