import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { DrugsService } from 'src/app/services/drugs.service';
import { AuthService } from 'src/app/services/auth.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-your-drugs',
  templateUrl: './your-drugs.component.html',
  styleUrls: ['./your-drugs.component.scss']
})
export class YourDrugsComponent implements OnInit {
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  existingDrugs_list: any;
  existingPharmacies_list: any;
  existingEntries_drugs_list_item = [];
  isLoading: boolean;
  dList: any;
  isUserLoggedIn:boolean;
  paramsObject: any;
  editDrugList: any = false;
  editStep: any = 1;
  existingRxDrugListNotes:any;
  drugListInfo:any;
  drugListId:any;
  drugInsights:any;

  timesTaken:any;
  oftenTaken:any;

  strip_drug_name:any;
  rxcui: any;
  showMoreDrugDetails:boolean = false;
  moreDrugDetails_text:string = 'More Drug Details';
  currentMonthNumber: number;
  isClickMonthProceed: boolean = false;
  selectedMonth: any;
  isCoverageMonthSelected:boolean;
  viewMonthCoverage:boolean = false;
  drugCostTypeLetter:any;
  auth_token: any;

  registeredLoggedIn: any = false;
  registeredNotLoggedIn: any = false;
  notRegistered: any = false;

  saveSelection: any = false;
  saveSelectionStep: any = 1;

  newList: any = false;
  existingList: any = false;

  newListStep: any = 1;
  existingListStep: any = 1;

  registerForm:FormGroup;
  loginForm:FormGroup;

  showLoginForm:boolean = true;
  showSignupForm:boolean = false;

  drugsNotes:any = '';
  pharmaciesNotes:any = '';

  marketplaceType:any;
  paramMarketplaceType: any = 'mapd';

  constructor(private drugsService: DrugsService, private _router: Router, private route: ActivatedRoute, private _auth: AuthService, private _api: APIService, private formBuilder: FormBuilder) {
    this.auth_token = window.localStorage.getItem('auth_token');
    this.rxcui = this.route.snapshot.paramMap.get('rxcui');

    this.isUserLoggedIn = _auth.isAuthenticated(); // check if user logged in

    if (this.isUserLoggedIn){ // check if user is logged in
      this.checkIfMarketplaceDefaultListExist();      
    }else{
      console.log("storage:", localStorage.getItem('rx_add_to_drug_list'))
      this.existingDrugs_list = this.drugsService.fetchAddedDrugList(); // get localstorage drug list
      this.drugInsights = this.drugsService.get_DrugInsights(this.existingDrugs_list);
      this.existingPharmacies_list = this.drugsService.fetchAddedPharmacyList(); // get localstorage pharmacy list
    }

    this.getCurrentMonthNumber();

    this.drugsNotes = localStorage.getItem('rx_drugs_notes');
    this.pharmaciesNotes = localStorage.getItem('rx_pharmacies_notes');

  }

  async checkIfMarketplaceDefaultListExist(){
    const list = await this.drugsService.getDrugLists();
    let listInfo = list.data.map(v => ({...v, drugs: v.drugs.map(x =>({...x, details: JSON.parse(x.details)}))}))
    const listInfo_filter = listInfo.filter(list => list.name.toLowerCase() == this.drugsService.forbiddenDrugListName);
    if(!listInfo_filter.length){
      this.onAutoSaveDefaultDrugList();
    }else{
      this.getMarketplaceDrugList(); // get marketplace drug list data
    }
  }

  async getMarketplaceDrugList(){
    const list = await this.drugsService.getDrugLists();
    let listInfo = list.data.map(v => ({...v, drugs: v.drugs.map(x =>({...x, details: JSON.parse(x.details)}))}))
    const listInfo_filter = listInfo.filter(list => list.name.toLowerCase() == this.drugsService.forbiddenDrugListName);

    this.drugsService.fetch_drugList_data(listInfo_filter[0].id);
    this.drugListId = listInfo_filter[0].id;
    localStorage.setItem('rx_default_drug_list_id', this.drugListId);
  }

  onAutoSaveDefaultDrugList() {    
    try {      
      this._api.callAPIEndpointAuthenticated('/api/drugs/save', this.auth_token, {
        "drug_list_type": 'reserve',
        "monthCoverage": '',
        drugs: [],
        pharmacies: [],
        'notes' : ''
      }).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.getMarketplaceDrugList();
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (e) {
      console.log('error', e);
    }

  }

  ngOnInit() {
    this.isLoading = true;
    this.subscription1 = this.drugsService.retrieveList().subscribe(d => this.existingDrugs_list = d);
    this.subscription2 = this.drugsService.retrievePharmacyList().subscribe(d => this.existingPharmacies_list = d);
    this.subscription3 = this.drugsService.retrieveDrugListInfo().subscribe(d => this.drugListInfo = d);
    this.subscription4 = this.drugsService.retrieveDrugInsights().subscribe(d => this.drugInsights = d);
    // .toLocaleDateString('en-US');
 
    this.route.queryParamMap
      .subscribe((params) => {
          this.paramsObject = {...params };
        }
      );

      // check if param has marketplace_type
      if(this.paramsObject['params'].marketplace_type){
        this.paramMarketplaceType = this.paramsObject['params'].marketplace_type;

        if(this.paramMarketplaceType == 'pdp'){
          this.marketplaceType = 'medicare-drug-coverage';
        }else if(this.paramMarketplaceType == 'medigap'){
          this.marketplaceType = 'medigap';
        }else if(this.paramMarketplaceType == 'dental'){
          this.marketplaceType = 'dental';
        }else{
          this.marketplaceType = 'medicare-advantage';
        }
        
      }else{
        this.marketplaceType = 'medicare-advantage';
      }
      //end check if param has marketplace_type

      this.registerForm = this.formBuilder.group({
        fullname: ['', Validators.required],
        dateofbirth: ['', Validators.required],
        contact: ['', Validators.required]
      });

      this.loginForm = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required]
      });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

  saveNewList() {
    this.saveSelection = false;
    this.newList = true;
  }
  saveExistingList() {
    this.saveSelection = false;
    this.existingList = true;
  }

  showLoginModal(modalViews) {
    Swal.fire({
      html: modalViews,
      padding: 35,
      width: 500,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  onClick_FullRxProfile(modalViews){
    this.registeredLoggedIn = false;
    this.registeredNotLoggedIn = false;
    this.notRegistered = false;
    this.saveSelection = false;
    this.saveSelectionStep = 1;
    this.newList = false;
    this.existingList = false;
    this.newListStep = 1;
    this.existingListStep = 1;
    
    Swal.fire({
      html: modalViews,
      padding: '50px',
      showConfirmButton: false,
      showCloseButton: true,
      //width: 900
      width:550
    });
  }

  removeDrug(item, content) {
    if(this.isUserLoggedIn){ // check if user is logged in
      this.removeDrugDb(item, content); // remove drug from db
    }else{
      this.removeDrugLocalStorage(item, content); // remove drug from local storage
    }
    
  }

  removePharmacy(item, content) {
    
    if(this.isUserLoggedIn){ // check if user is logged in
      this.removePharmacyDb(item, content);
    }else{
      this.removePharmacyLocalStorage(item, content);
    }
    
  }


  removeDrugLocalStorage(item, content){
    Swal.fire({
      html: content,
      showCancelButton: true,
      confirmButtonColor: '#156aff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
        if (result.value) {
          this.isLoading = true;
          
          this.drugsService.removeDrugList(item);

          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
  }

  removePharmacyLocalStorage(item, content){
    Swal.fire({
      html: content,
      showCancelButton: true,
      confirmButtonColor: '#156aff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
        if (result.value) {
          this.isLoading = true;
          
          this.drugsService.removePharmacyList(item);

          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
  }

  async removePharmacyDb(item, content){

    Swal.fire({
      html: content,
      showCancelButton: true,
      confirmButtonColor: '#156aff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
        if (result.value) {
          this.isLoading = true;
          try {
           const status: any = await this.drugsService.benny_deletePharmacy(item.id);

            if(status.code == 1){
              this.drugsService.fetch_drugList_data(this.drugListId); 
              this.isLoading = false;  
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )    
            }
            
            this.isLoading = false;
          } catch (e) {
            console.log('error', e);
            this.isLoading = false;
          } finally {
            
          }
          
        }
      })

  }

  async removeDrugDb(item, content){

    Swal.fire({
      html: content,
      showCancelButton: true,
      confirmButtonColor: '#156aff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
        if (result.value) {
          this.isLoading = true;
          try {
           const status: any = await this.drugsService.benny_deleteDrug(item.id);

            if(status.code == 1){
              this.drugsService.fetch_drugList_data(this.drugListId); 
              this.isLoading = false;  
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )    
            }
            
            this.isLoading = false;
          } catch (e) {
            console.log('error', e);
            this.isLoading = false;
          } finally {
            
          }
          
        }
      })

  }

  viewYourList(modalViews) {
    Swal.fire({
      html: modalViews,
      customClass: {
        content: 'text-left custom-modal-content'
      },
      showConfirmButton: false,
      showCloseButton: true,
      width: 650,
      padding: 0
    });
  }

  addAnotherDrug() {
    Swal.close();    
    this._router.navigateByUrl('/insurance-marketplace/' + this.marketplaceType + '/drugs?marketplace_type=' + this.paramMarketplaceType + '&plan_type='+ this.paramsObject['params'].plan_type +'&state=' + this.paramsObject['params'].state + '&county=' + this.paramsObject['params'].county + '&zipcode=' + this.paramsObject['params'].zipcode + '&contract_id=' + this.paramsObject['params'].contract_id + '&plan_id=' + this.paramsObject['params'].plan_id + '&segment_id=' + this.paramsObject['params'].segment_id + '&plan_year=' + this.paramsObject['params'].plan_year);
  }

  addAnotherPharmacy() {
    Swal.close();
    this._router.navigateByUrl('/insurance-marketplace/' + this.marketplaceType + '/drugs/step4?marketplace_type=' + this.paramMarketplaceType +'&plan_type='+ this.paramsObject['params'].plan_type +'&state=' + this.paramsObject['params'].state + '&county=' + this.paramsObject['params'].county + '&zipcode=' + this.paramsObject['params'].zipcode + '&contract_id=' + this.paramsObject['params'].contract_id + '&plan_id=' + this.paramsObject['params'].plan_id + '&segment_id=' + this.paramsObject['params'].segment_id + '&plan_year=' + this.paramsObject['params'].plan_year);
  }

  showModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 500,
      onClose: () => {
        this.editDrugList = false;
        this.editStep = 0;
    }
    })
  }

  changeDrugModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 800,
      onClose: () => {
        this.editDrugList = false;
        this.editStep = 0;
    }
    })
  }

  onClick_shortcutOften(times,often){
    this.timesTaken = times;
    this.oftenTaken = often;
    $('.timesOftenBtn').removeClass('active');
    document.querySelector('#'+often+'-'+times).classList.add('active');
  }

  click_often_taken(event, type) {
    $('.btn-packaging-list-item').removeClass('active');
    $('#often_taken_' + type).click();
    $(event.target).addClass('active');
  }

  onClick_MoreDrugDetails(state){
    this.showMoreDrugDetails = !state;
    if(this.showMoreDrugDetails){
      this.moreDrugDetails_text = "Hide Additional Details";
    }else{     
      this.moreDrugDetails_text = "More Drug Details";
    }
  }

  onSaveRxDrugListNotes(){
    localStorage.setItem('rx_drug_list_notes', this.existingRxDrugListNotes);
    Swal.fire(
      'Success!',
      'Your notes has been added to your Drug List',
      'success',
    );
  }

  onClick_YourDrugListNotes(content){
    this.existingRxDrugListNotes = localStorage.getItem('rx_drug_list_notes');
    this.showModal(content);
  }

  editYourDrug(drug) {
    const drugName = (drug.drug_name) ? drug.drug_name : drug.details.drug_name;
    const drug_id = (drug.drug_id) ? drug.drug_id : '2820';
    
    Swal.close();    

    if(this.isUserLoggedIn){
      this._router.navigateByUrl('/insurance-marketplace/' + this.marketplaceType + '/drugs?marketplace_type=' + this.paramMarketplaceType + '&plan_type='+ this.paramsObject['params'].plan_type +'&state=' + this.paramsObject['params'].state + '&county=' + this.paramsObject['params'].county + '&zipcode=' + this.paramsObject['params'].zipcode + '&contract_id=' + this.paramsObject['params'].contract_id + '&plan_id=' + this.paramsObject['params'].plan_id + '&segment_id=' + this.paramsObject['params'].segment_id + '&plan_year=' + this.paramsObject['params'].plan_year + '&druglistid=' + this.drugListId + '&edit_drug=' + true + '&edit_drug_name=' + drugName + '&edit_drug_id=' + drug_id);
    }else{
      this._router.navigateByUrl('/insurance-marketplace/' + this.marketplaceType + '/drugs?marketplace_type='+ this.paramMarketplaceType +'&plan_type='+ this.paramsObject['params'].plan_type +'&state=' + this.paramsObject['params'].state + '&county=' + this.paramsObject['params'].county + '&zipcode=' + this.paramsObject['params'].zipcode + '&contract_id=' + this.paramsObject['params'].contract_id + '&plan_id=' + this.paramsObject['params'].plan_id + '&segment_id=' + this.paramsObject['params'].segment_id + '&plan_year=' + this.paramsObject['params'].plan_year + '&edit_drug=' + true + '&edit_drug_name=' + drugName + '&edit_drug_id=' + drug_id);
      //this._router.navigate(['/insurance-marketplace/medicare-advantage/drugs?plan_type='+ this.paramsObject['params'].plan_type +'&state=' + this.paramsObject['params'].state + '&county=' + this.paramsObject['params'].county + '&zipcode=' + this.paramsObject['params'].zipcode + '&contract_id=' + this.paramsObject['params'].contract_id + '&plan_id=' + this.paramsObject['params'].plan_id + '&segment_id=' + this.paramsObject['params'].segment_id + '&plan_year=' + this.paramsObject['params'].plan_year], { queryParams: {edit_drug:'true'}})
    }
    
  }

  getCurrentMonthNumber(){
    const d = new Date();
    this.currentMonthNumber = d.getMonth();
  }
  
  onClick_PharmacyResultProceed(modalViews) {
    
    Swal.fire({
      html: modalViews,
      padding: '50px 70px 40px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 600
    });
  }

  select_pharmacy(event, month) {
    $('.btn-often').removeClass('active');
    $('#days_supply' + month).click();
    $(event.target).addClass('active');
    this.selectedMonth = month;
    localStorage.setItem('rx_month_coverage', JSON.stringify(this.selectedMonth));
    this.isCoverageMonthSelected = true;
  }

  onClick_drugCostAnalysisType(type){
    this.drugCostTypeLetter = type;
    this.viewMonthCoverage = true;
  }

  onClick_Monthproceed(){
    this.isClickMonthProceed = true;
    if(this.selectedMonth){
      this.isCoverageMonthSelected = true;
      Swal.close(); 

      if(this.drugCostTypeLetter == "A"){
        if(this.paramMarketplaceType == 'mapd'){
          this._router.navigate(['/insurance-marketplace/' + this.marketplaceType + '/results/plan-' + this.paramsObject['params'].plan_type + '/zipcode-' + this.paramsObject['params'].zipcode +'/planYear-' + this.paramsObject['params'].plan_year], { queryParams : { drugs: 1, contract_id: this.paramsObject['params'].contract_id, plan_id: this.paramsObject['params'].plan_id } });
        }else{
          this._router.navigate(['/insurance-marketplace/' + this.marketplaceType + '/results/zipcode-' + this.paramsObject['params'].zipcode +'/planYear-' + this.paramsObject['params'].plan_year + '/plan-' + this.paramsObject['params'].plan_type], { queryParams : { drugs: 1, contract_id: this.paramsObject['params'].contract_id, plan_id: this.paramsObject['params'].plan_id } });
        }
      }
      if(this.drugCostTypeLetter == "B"){
        if( this.paramsObject['params'].plan_type == 5 && this.paramMarketplaceType == 'mapd') {
          this._router.navigate(
            [
              '/insurance-marketplace/' + this.marketplaceType + '/results/plan-' + this.paramsObject['params'].plan_type + 
              '/zipcode-' + this.paramsObject['params'].zipcode +
              '/planYear-' + this.paramsObject['params'].plan_year + 
              '/monthlyPremium-' + this.paramsObject['params'].monthlyPremium + 
              '/additionalBenefits-' + this.paramsObject['params'].additionalBenefits + 
              '/outOfPocketCost-' + this.paramsObject['params'].outOfPocketCost + 
              '/otherCategories-' + this.paramsObject['params'].otherCategories + 
              '/selectorType-' + this.paramsObject['params'].selectorType
            ], { queryParams : { drugs: 1 }});
        } else {
          if(this.paramMarketplaceType == 'mapd'){
            this._router.navigate(['/insurance-marketplace/' + this.marketplaceType + '/results/plan-' + this.paramsObject['params'].plan_type + '/zipcode-' + this.paramsObject['params'].zipcode +'/planYear-' + this.paramsObject['params'].plan_year], { queryParams : { drugs: 1 }});
          }else{
            this._router.navigate(['/insurance-marketplace/' + this.marketplaceType + '/results/zipcode-' + this.paramsObject['params'].zipcode +'/planYear-' + this.paramsObject['params'].plan_year + '/plan-' + this.paramsObject['params'].plan_type], { queryParams : { drugs: 1 }});
          }          
        }
      }        
    }else{
      this.isCoverageMonthSelected = false;
    }
  }

  onClick_ProceedToRxProile(){
    Swal.close();
    this._router.navigate(['/benny-dash/my-healthcare-info/my-drugs/drugs-list/' + this.drugListId]);
  }

  onClick_ShowSignUpForm(){
    this.showSignupForm = true;
    this.showLoginForm = false;
  }

  onClick_ShowLoginForm(){
    this.showSignupForm = false;
    this.showLoginForm = true;
  }

  async onClick_SaveDrugsPharmaciesNotes(){    
    this.isLoading = true;
    const data = {
      "drug_list_name": this.drugListInfo.drug_list_name,
      "drug_list_type": this.drugListInfo.drug_list_type,
      "is_active": this.drugListInfo.is_active,
      "drug_list_tags": this.drugListInfo.drug_list_tags,
      "notes": this.drugListInfo.drug_list_notes,
      "drug_notes": this.drugListInfo.drug_notes,
      "pharmacy_notes": this.drugListInfo.pharmacy_notes
    }     
    try{      
      const updateStatus: any = await this.drugsService.editSpecificDrugList(this.drugListId, data);
      if(updateStatus){    
          this.drugsService.fetch_drugList_data(this.drugListId);                     
            Swal.fire({
              html: '<h4 class="text-center">Data has been successfully saved!</h4>',
              width:'600px',
              customClass: {
                confirmButton: 'btn btn-first btn-medium'
              },
              showConfirmButton: true,
              showCloseButton: true
            });     
          this.isLoading = false;
      }  
    }catch(e){
      console.log('error', e);
    }finally{

    }
    
  }

  onSave_DrugsNotesToLocalStorage(){
    localStorage.setItem('rx_drugs_notes', this.drugsNotes);
    Swal.close();
  }

  onSave_PharmaciesNotesToLocalStorage(){
    localStorage.setItem('rx_pharmacies_notes', this.pharmaciesNotes);
    Swal.close();
  }


}
