import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MedicareAdvantageService} from '../../insurance-marketplace/medicare-advantage/medicare-advantage.service';
import {Data, Router} from "@angular/router";
import Swal from "sweetalert2";
import { SlideInOutAnimation } from './animations';

@Component({
  selector: 'app-mapd-custom-filter',
  templateUrl: './mapd-custom-filter.component.html',
  styleUrls: ['./mapd-custom-filter.component.scss'],
  animations: [SlideInOutAnimation]
})
export class MapdCustomFilterComponent implements OnInit {

  animationState:string = 'out';

    @Output() isBackToMainView = new EventEmitter();
    @Output() emit_ShowZipCode = new EventEmitter();
    @Output() emit_backToMainView_benny = new EventEmitter();

    @Input() isViewOnBenny:boolean = false;

    @Input() viewOnly:boolean = false; // to remove Proceed button and Info Modal on modal SElector page

    @Input() isShowZipCode: boolean;
    @Input() plan: number;

    @Output() isShowZipCodeChange = new EventEmitter();
    @Output() planChange = new EventEmitter();

    @Output() quoteFormHeading = new EventEmitter();

    @Output() onClickZipCodeBack = new EventEmitter();

    isPrioritizeSelected: boolean = false;
    mustMatchAllActive: boolean = true;
    mustMatchAnyActive: boolean = false;
    prioritizeActive: boolean = false;
    isShowCustomPlan: boolean = true;

    monthlyPremium: any = [];
    additionalBenefits: any = [];
    outOfPocketCost: any = [];
    otherCategories: any = [];
    selectorType: any = 'match-all';

    selectorContent: any;

    isHMO: boolean = false;
    isPPO: boolean = false;
    isAll: boolean = false;
    isAll50orLess: boolean = false;
    isMoreThanTwo: boolean = false;

    /* opIsChecked1: boolean  = false;
     opIsChecked2: boolean = false;
     opIsChecked3: boolean = false;
     opIsChecked4: boolean = false;
     opIsChecked5: boolean = false;
     opIsChecked6: boolean = false;
     opIsChecked7: boolean = false;*/

    opIsChecked: any = [];
    opChexboxes = [1, 2, 3, 4, 5, 6, 7];

    monthlyPremiumIsChecked: any = [];
    monthlyPremiumCheckboxes = [1, 2, 3, 4, 5, 6, 7];

    viewDefault:boolean = true;

    constructor(private _medicareService: MedicareAdvantageService, private _router: Router) {

    }

    backClick_marketplace(){
        this.isBackToMainView.emit(null);
        Swal.close();
        this._router.navigate(['./insurance-marketplace/medicare-advantage']);
    }

    backClick_benny(){
      this.emit_backToMainView_benny.emit(null);
    }

    standardPlanTypesChange(monthlyPremium, values: any){

    }

    ngOnInit() {}

    selectorProceed_benny() {
      this.emit_ShowZipCode.emit();
    }

    selectorProceed_marketplace() {
    }

    // <i class='fa fa-info global-info-icon-first mr-3'></i>
    modalInfoHeading: any = `<div class='d-flex justify-content-center align-items-center mb-5'>
                                
                                <h2 class='mb-0 h2-global-title' style='font-size: 1.3em;margin-bottom: 0;font-weight: 600;'>TapTap Medicare Explains:</h2>
                            </div>`;

    //selector

    showCriteriaModal(content, width){
        Swal.fire({
            html: content.innerHTML,
            width: width,
            customClass: {
                content: 'selector-modal-content text-center',
                confirmButton: 'btn btn-first'
            },
            padding: '3em',
            buttonsStyling: false
        })
    }
    selectorModalView(content, width: 600) {
        // if (content == 'select-criteria') {
        //     this.selectorContent = '<ul>';
        //     this.selectorContent += '<li>You may select as many features from each section as you’d like, however, the plans shown will need to meet ALL of the plan criteria you’ve selected.</li>';
        //     this.selectorContent += '<li>You may also choose to not select any features from a particular section.</li>';
        //     this.selectorContent += '<li>Plans that just meet one or some of your criteria, but not all, will not be shown.</li>';
        //     this.selectorContent += '<li>Additionally, some criteria are incompatible with others. For example, you cannot select the criteria “HMO Plans with $0 Premium” and “PPO with Premium of $20 of Less” since all plans are either an HMO or a PPO, but cannot be both.” </li>';
        //     this.selectorContent += '<li>Since you are customizing your plan with each selection, the more criteria that you select will result in less plans being displayed in your results, since the only plans shown must meet ALL of the criteria you’ve selected. </li>';
        //     this.selectorContent += '<li>Of course, you may always give us a call at 1(800)215-5108 to help you with your plan customization.</li>';

        //     this.selectorContent += '</ul>';
        // }
        // if (name == 'monthly-premiums') {
        //     this.selectorContent = '<p> This section gives you the ability to view only the plans that are under a specified monthly premium amount. This monthly premium is what is charged to you when you are enrolled in the plan. *If you receive “Extra Help” from the government then you may pay less than the stated amount. </p>';
        // }
        // if (name == 'additional-benefits') {
        //     this.selectorContent = '<p>This section allows you to view only the plans that give you some of the popular additional coverage benefits that Medicare Advantage plans can offer. “Plans with Comprehensive Dental Coverage” provide dental coverage beyond just the routine and preventive services that are more common. “Part B Premium Reduction” will show plans that have $0 premium as well as reduce the Medicare premium you are charged for Part B”</p>';
        // }
        // if (name == 'out-of-pockets-costs') {
        //     this.selectorContent = '<p>This section allows you to select which type of out-of-pocket costs you want to prioritize. You may want to use this section if you have a specific procedure or course of treatments coming up that you would like to minimize your costs for. Plans shown for low out-of-pocket for a specific type of service is based relative to the out-of-pocket for other available plans. </p>';
        // }
        // if (name == 'other-categories') {
        //     this.selectorContent = '<p>This section gives you the ability to further customize the plan options that would be most suitable for you. If you select any of these categories then your results will only show plans that meet ALL of your selections.</p>';
        // }
        Swal.fire({
            html: this.modalInfoHeading + content.innerHTML,
            width: width,
            customClass: {
                content: 'selector-modal-content text-center',
                confirmButton: 'btn btn-first'
            },
            padding: '2em',
            buttonsStyling: false
        })
        
    }

    onClickSelectorType(value) {
        this.selectorType = value;
    }


    mustMatchAll() {
        this.mustMatchAllActive = true;
        this.mustMatchAnyActive = false;
        this.prioritizeActive = false;
        this.isPrioritizeSelected = false;
        $('#matchAllID').click();
        this.resetCheckboxes();

    }

    mustMatchAny() {
        this.mustMatchAnyActive = true;
        this.mustMatchAllActive = false;
        this.prioritizeActive = false;
        this.isPrioritizeSelected = false;
        $('#matchAnyID').click();
        this.resetCheckboxes();
    }

    prioritize() {
        this.mustMatchAnyActive = false;
        this.mustMatchAllActive = false;
        this.prioritizeActive = true;
        this.isPrioritizeSelected = true;
        $('#prioritizeID').click();
    }

    resetCheckboxes() {
        this.monthlyPremiumIsChecked[1] = false;
        this.monthlyPremiumIsChecked[2] = false;
        this.monthlyPremiumIsChecked[3] = false;
        this.monthlyPremiumIsChecked[4] = false;
        this.monthlyPremiumIsChecked[5] = false;
        this.monthlyPremiumIsChecked[6] = false;
        this.monthlyPremiumIsChecked[7] = false;

        const mpCustomCheck1 = document.getElementById("mp-customCheck1") as HTMLInputElement;
        const mpCustomCheck2 = document.getElementById("mp-customCheck2") as HTMLInputElement;
        const mpCustomCheck3 = document.getElementById("mp-customCheck3") as HTMLInputElement;
        const mpCustomCheck4 = document.getElementById("mp-customCheck4") as HTMLInputElement;
        const mpCustomCheck5 = document.getElementById("mp-customCheck5") as HTMLInputElement;
        const mpCustomCheck6 = document.getElementById("mp-customCheck6") as HTMLInputElement;
        const mpCustomCheck7 = document.getElementById("mp-customCheck7") as HTMLInputElement;

        mpCustomCheck1.checked = false;
        mpCustomCheck2.checked = false;
        mpCustomCheck3.checked = false;
        mpCustomCheck4.checked = false;
        mpCustomCheck5.checked = false;
        mpCustomCheck6.checked = false;
        mpCustomCheck7.checked = false;


        this.opIsChecked[1] = false;
        this.opIsChecked[2] = false;
        this.opIsChecked[3] = false;
        this.opIsChecked[4] = false;
        this.opIsChecked[5] = false;
        this.opIsChecked[6] = false;
        this.opIsChecked[7] = false;

        const opCustomCheck1 = document.getElementById("op-customCheck1") as HTMLInputElement;
        const opCustomCheck2 = document.getElementById("op-customCheck2") as HTMLInputElement;
        const opCustomCheck3 = document.getElementById("op-customCheck3") as HTMLInputElement;
        const opCustomCheck4 = document.getElementById("op-customCheck4") as HTMLInputElement;
        const opCustomCheck5 = document.getElementById("op-customCheck5") as HTMLInputElement;
        const opCustomCheck6 = document.getElementById("op-customCheck6") as HTMLInputElement;
        const opCustomCheck7 = document.getElementById("op-customCheck7") as HTMLInputElement;

        opCustomCheck1.checked = false;
        opCustomCheck2.checked = false;
        opCustomCheck3.checked = false;
        opCustomCheck4.checked = false;
        opCustomCheck5.checked = false;
        opCustomCheck6.checked = false;
        opCustomCheck7.checked = false;


    }

    checkBoxAccordionToggle() {      
        this.animationState = this.animationState === 'out' ? 'in' : 'out';
        console.log(this.animationState);      
    }

    onClickPrevBtn(){
        $('.carousel-control-prev').click();
    }

    onClickNextBtn(){
        $('.carousel-control-next').click();
    }

}
