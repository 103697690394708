<h4 class="title">Medicare Info</h4>
<div class="row px-0 pt-2">
  <div class="col-12 col-sm-6 pr-2">
      <div class="form-group">
      <label for="">Part A Effective Date</label>
      <input type="text" class="form-control blurred" [(ngModel)]="details?.customer_info.part_a_effective_date" (click)="onClickDob('partAEffectiveDate','Select Effective Date for Part A')"/>
      <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="onCheckedDontHavePartA($event)" [checked]="details?.customer_info.has_no_part_a">
          <label class="custom-control-label" for="customCheck1">I Do Not Have Part A Yet</label>
      </div>
      </div>
  </div>
  <div class="col-12 col-sm-6 pl-0">
      <div class="form-group">
        <label for="">Part B Effective Date</label>
        <input type="text" class="form-control blurred" [(ngModel)]="details?.customer_info.part_b_effective_date" (click)="onClickDob('partBEffectiveDate','Select Effective Date for Part B')"/>
        <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="customCheck2" (change)="onCheckedDontHavePartB($event)" [checked]="details?.customer_info.has_no_part_b">
            <label class="custom-control-label" for="customCheck2">I Do Not Have Part B Yet</label>
        </div>
      </div>
  </div>
</div>
<div class="row px-0 pt-4">
  <div class="col-12 col-sm-6 pr-2">
      <div class="form-group">
      <label for="">Medicare Card Name</label>
      <input type="text" class="form-control blurred" autocomplete="off" [(ngModel)]="details?.customer_info.medicare_card_name"/>
      </div>
  </div>
  <div class="col-12 col-sm-6 pl-0">
      <div class="form-group">
      <label for="">Medicare Card Number</label>
      <div class="d-flex align-items-center">
          <input type="text" class="form-control blurred mr-2" maxlength="4" autocomplete="off" [(ngModel)]="medCardNumber1"/> -
          <input type="text" class="form-control blurred mx-2" maxlength="3" autocomplete="off" [(ngModel)]="medCardNumber2"/> -
          <input type="text" class="form-control blurred ml-2" maxlength="4" autocomplete="off" [(ngModel)]="medCardNumber3"/>
      </div>
      </div>
  </div>
</div>
<div class="row px-0 pt-4">
  <div class="col-12 px-0">
      <div class="form-group">
      <label for="">Emergency Contact</label>
      <div class="custom-control custom-radio">
          <input type="radio" id="customRadio1" value="1" [(ngModel)]="customRadio" name="customRadio" class="custom-control-input">
          <label class="custom-control-label" for="customRadio1">Use My Household Member as My Emergency Contact</label>
      </div>
      <div class="custom-control custom-radio">
          <input type="radio" id="customRadio2" value="2" [(ngModel)]="customRadio" name="customRadio" class="custom-control-input">
          <label class="custom-control-label" for="customRadio2">No, I Do Not Want To Add An Emergency Contact Right Now</label>
      </div>
      <div class="custom-control custom-radio">
          <input type="radio" id="customRadio3" value="3" [(ngModel)]="customRadio" name="customRadio" class="custom-control-input">
          <label class="custom-control-label" for="customRadio3">Yes, I Will Add A New Emergency Contact Here</label>
      </div>
      <ng-container *ngIf="customRadio">
          <button (click)="customRadio = null" class="btn btn-second btn-xs mt-2">Clear</button>
      </ng-container>
      </div>
  </div> 
</div>
<div *ngIf="customRadio" class="row relationship-box">
    <div class="col-12 col-sm-6 pl-0">
        <div class="form-group">
          <label for="">Name</label>
          <input type="text" class="form-control blurred"/>
        </div>
    </div>
    <div class="col-12 pl-0">
        <div class="form-group">
            <label for="">Relationshop</label>
            <div class="row">
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_1" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_1">Husband</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_2" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_2">Wife</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_3" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_3">Mother</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_4" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_4">Father</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_12" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_12">Brother</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_5" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_5">Sister</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_6" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_6">Significant Other</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_7" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_7">Friend</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_8" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_8">Caretaker</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_9" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_9">Financial Advisor</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_10" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_10">Co-Worker</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                    <input type="radio" id="r_11" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="r_11">Financial Planner</label>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-6 pr-0">
      <div class="form-group">
        <label for="">Cellphone Number</label>
        <input type="text" class="form-control blurred" />
      </div>
    </div>
</div>