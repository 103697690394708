<ng-container *ngIf="isDefault">
  <div class="overview-coverage-4-boxes-wrapper" [class.highlighted-new-dental-box-wrapper]="highlighted == 'new-dental'" [class.large]="size === 'large'">
    <div class="overview-coverage-4-inner" [class.highlighted-new-dental]="highlighted == 'new-dental'">
      <h6>Primary Coverage</h6>
      <p>Original Medicare</p>
      <img src="./assets/img/retiree-dashboard/medicare-card-hd.png" alt="">
      <b>Medicare Part A & Part B</b>
    </div>
    <div class="rd-oc-box-v1-plus" [class.d-none]="highlighted == 'new-dental'">
      <i class="fa fa-plus"></i>
    </div>
    <div class="overview-coverage-4-inner rd-highlighted" [class.highlighted-new-dental]="highlighted == 'new-dental'" [class.rd-highlighted]="highlighted == 'medigap'" [class.scale-up]="highlighted == 'medigap'">
      <h6>Secondary Coverage</h6>
      <p>Medicare Supplement</p>
      <img src="./assets/img/retiree-dashboard/coverage-box-secondary-hd.png" alt="">
      <b>Medigap Insurance</b>
    </div>
    <div class="rd-oc-box-v1-plus" [class.d-none]="highlighted == 'new-dental'">
      <i class="fa fa-plus"></i>
    </div>
    <div class="overview-coverage-4-inner" [class.highlighted-new-dental]="highlighted == 'new-dental'" [class.rd-highlighted]="highlighted == 'pdp'" [class.scale-up]="highlighted == 'pdp'">
      <h6>Drug Coverage</h6>
      <p>Standalone Part D Plan</p>
      <img src="./assets/img/retiree-dashboard/coverage-box-drug-hd.png" alt="">
      <b>Medicare Part D</b>
    </div>
    <div class="rd-oc-box-v1-plus" [class.d-none]="highlighted == 'new-dental'">
      <i class="fa fa-plus"></i>
    </div>
    <div class="overview-coverage-4-inner" [class.d-none]="highlighted == 'new-dental'" [class.rd-highlighted]="highlighted == 'dental'" [class.scale-up]="highlighted == 'dental'">
      <h6>Dental Coverage</h6>
      <p>Individual Dental Policy</p>
      <img src="./assets/img/retiree-dashboard/coverage-box-dental-hd.png" alt="">
      <b>Dental Insurance</b>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isMapd">
  <div class="mapd-coverage-box flex-column flex-sm-row">
			
    <div class="mapd-coverage-box-left">
    <p class="text-center">
      Original Medicare is <b>NOT</b>
      your Primary Coverage
    </p>
    <img src="./assets/img/retiree-dashboard/original-medicare-card.png" alt="">
    </div>									
    
    <div class="mapd-coverage-box-right">
    <p class="text-center"><b class="color-primary d-block">Medicare Advantage</b> is Your <b>Primary</b> Drug Coverage & Dental, Vision & Hearing</p>
    <img class="m-auto" src="./assets/img/retiree-dashboard/medicare-advantage-card.png" alt="">
    </div>

  </div>
</ng-container>

<ng-container *ngIf="isDental">
  <div class="mapd-coverage-box flex-column flex-sm-row dental">
    <div class="mapd-coverage-box-right">
      <p class="text-center"><b class="color-primary d-block">Medicare Advantage Plans</b> is Your <b>Primary</b></p>
      <img src="./assets/img/retiree-dashboard/medicare-advantage-card.png" alt="">
    </div>
    
    <div class="mapd-coverage-box-left">
    <p class="text-center">Individual Dental Policy</p>
      <img src="./assets/img/retiree-dashboard/original-medicare-card.png" alt="">
    </div>									
  </div>
</ng-container>

<ng-container *ngIf="isMapdPlusDrug">
  <div class="mapd-coverage-box flex-column flex-sm-row mapd-plus-drug-box">
			
    <div class="mapd-coverage-box-left">
      <p class="text-center">
        Original Medicare is <b>NOT</b>
        your Primary Coverage
      </p>
      <img src="./assets/img/retiree-dashboard/original-medicare-card.png" alt="">
    </div>									
    
    <div class="mapd-coverage-box-right">
      <p class="text-center"><b class="color-primary d-block">Medicare Advantage</b> is Your <b>Primary</b> Drug Coverage & Dental, Vision & Hearing</p>
      <img class="m-auto" src="./assets/img/retiree-dashboard/medicare-advantage-card.png" alt="">
    </div>

    <div>
      <span class="fColor-blue fWeight-600">Drug Coverage</span>
      <span>For Prescription Drugs</span>
      <img src="./assets/img/common/drug-coverage-prescription.svg" alt="Drug Coverage">      
    </div>

  </div>
</ng-container>


