import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
@Component({
  selector: 'app-drug-notes-form',
  templateUrl: './drug-notes-form.component.html',
  styleUrls: ['./drug-notes-form.component.scss']
})
export class DrugNotesFormComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  isLoading_Modal:boolean = false;
  @Output() drugNotesTags = new EventEmitter;
  @Input() notes:any = '';
  @Input() tagger:any = [];
  constructor() { }

  ngOnInit() {
  }

  onClick_SaveBtn(){ 
    const notes = this.notes;
    const tags = this.tagger;
    this.drugNotesTags.emit({notes,tags})
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tagger.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tagger): void {
    const index = this.tagger.indexOf(tagger);

    if (index >= 0) {
      this.tagger.splice(index, 1);
    }
  }

}
