<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">
						<span>Your Medicare Advantage Marketplace</span><br> 
						Made Simple and Clear.
					</h1>
					<p class="banner-sub-title">TapTap Medicare has made viewing Medicare plans easy, convenient, and transparent.</p>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">
				<img src="assets/img/common/old-woman-half-body-with-basket-v1.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>

<div class="banner-pointer-wrapper mb-0">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<section class="px-5 pt-5">
  <div class="container">
    <h2 class="text-center fColor-white">Medicare Advantage Coverage</h2>
  </div>
  <div class="container d-flex align-items-center justify-content-center">
    <div class="desktop-only"><img src="./assets/img/common/old-woman-holding-fork-leaves.png" alt="" style="max-width: 300px"></div>
    <div>
      <div class="d-flex border-radius bg-third p-3 align-items-center mb-2">
        <span class="bg-white p-3 border-radius fWeight-700 fColor-black mr-4 text-center option-box">Option 2</span>
        <h3 class="fColor-black">Medicare Advantage Coverage</h3>
      </div>

      <div class="option-2-content flex-column flex-sm-row d-flex justify-content-between text-center mb-5 align-items-center" style="background: #fff;">
        
        <div class="option-2-content-left d-flex align-items-center flex-column justify-content-start p-2">
          <p>
            Original Medicare is <b>NOT</b>
            your Primary Coverage
          </p>
          <img src="./assets/img/retiree-dashboard/original-medicare-card.png" alt="">
        </div>

        <img src="./assets/img/common/blue-arrow-right.PNG" alt="" style="max-width:45px" class="tablet-only">

        <div class="option-2-content-right d-flex align-items-center flex-column justify-content-start p-2">
          <p><b class="color-primary d-block">Medicare Advantage</b> is Your <b>Primary</b> Drug Coverage & Dental, Vision & Hearing</p>
          <img src="./assets/img/retiree-dashboard/medicare-advantage-card.png" alt="">
        </div>

      </div>
    </div>
  </div>
</section>

<section class="p-5">
  <div class="container max-width-880">
    <h5 class="fColor-base">The Big Picture</h5>
    <ul class="circle-blue">
      <li>You "Replace" Original Medicare as your primary</li>
      <li>You have a "Network" of Healthcare Providers: Plans Are HMOs or PPOs</li>
      <li>More Out-of-Pocket Risk For Major-Medical, But Capped For The Year</li>
      <li>Low or no preimums: usually $0, and almost always under $60/month</li>
      <li>Yur drug coverage is included: wrapped in with your other benefits</li>
      <li>Your dental and vision coverage is usually included</li>
    </ul>
  </div>
</section>

<section class="p-5" style="background:#f1f1f1">
  <div class="container max-width-880">
    <h5 class="fColor-base fWeight-500 text-center" style="line-height:1.6em">
      When you have a Medicare Advantage plan, you replace Medicare Part A
      and B with a privatized HMO or PPO plan that will serve as your new total 
      healhcare coverage that includes your drug coverage. These plans often
      include dental and vision coverage, and often additional benefits Like
      over-the-counter items, transportation, and fitness & wellness programs.
    </h5>
    <button class="btn btn-first btn-small mx-auto my-4" (click)="speakToCounselorEvent(speakToCounselorModal)">Speak With a Concierge Now</button>
  </div>
</section>

<section class="p-5">
  <div class="container max-width-880">
    <h4 class="fColor-black mb-4">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">Like</span> About This Coverage?</h4>
      <ul class="checklist">
        <li>Low or No Monthly Premiums, & Includes Your Drug Coverage</li>
        <li>Streamlined Approach With All Healthcare Services Covered Under One Plan</li>
        <li>Most Plans Include Dental & Vision & Additional Health & Wellenss Benefits</li>
        <li>Can Include Extra Benefits LIke Gym Memberships, Transportation, & OTC Items</li>
      </ul>

      <h4 class="fColor-black mb-4 mt-5">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">NOT</span> Like About This Coverage?</h4>
      <ul class="xlist">
        <li>More Potential Out-of-Pocket Costs</li>
        <li>Plans Involve a Network, & Potentially Referrals & Pre-Certifications</li>
        <li>Plans Change Annually So There is More Oversight Needed</li>
        <li>Possibly Less Convenience Due To Networks & OUt-of-Pocket Bills</li>
      </ul>
  </div>
</section>

<section style="background:#f1f1f1" class="p-5">
  <div class="container max-width-880 text-center">
    <h5 class="fColor-base text-center fWeight-500">Medicare Advantage plans are a type of privatized Medicare
      coverage option, which will always be set up as an HMO or PPO plan with an independent insurance company.
    </h5>
  </div>
</section>

<section class="bg-primary p-5">
  <div class="container max-width-880">
    <h4 class="fColor-white mb-5 text-center fWeight-600">Your Medicare Advantage Plan Becomes Your All-In-One Coverage For All The Following Services:
    </h4>
    <div class="all-in-one-services-wrapper">
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_doctors-office-visits.png" alt="Doctor's Office Visits">
        <span>Doctor's Office Visits</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_prescription-drugs.png" alt="Doctor's Office Visits">
        <span>Prescription Drugs</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_diagnostic-tests.png" alt="Doctor's Office Visits">
        <span>Diagnosic Tests</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_medical_equipment.png" alt="Doctor's Office Visits">
        <span>Medical Equipment</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_dental-vision-hearing.png" alt="Doctor's Office Visits">
        <span>Dental, Vision, Hearing <br> <small style="color:inherit">(If Included)</small></span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_hospitalizations.png" alt="Doctor's Office Visits">
        <span>Hospitalizations</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_outpatient-procedures.png" alt="Doctor's Office Visits">
        <span>Outpatient Procedures</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_skilled-nursing.png" alt="Doctor's Office Visits">
        <span>Skilled Nursing</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_surgeries.png" alt="Doctor's Office Visits">
        <span>Surgeries</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_mental-health-services.png" alt="Doctor's Office Visits">
        <span>Mental Health Services</span>
      </div>
    </div>

    <mat-divider class="mb-5" style="border-color: #ccc;border-top-style: dashed;margin: 2em 0;"></mat-divider>
    
    <h5 class="fColor-white mb-4 text-center">Some Advantage Plans May Offer the Following Extra Benefits:
    </h5>
    <div class="all-in-one-services-wrapper">
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_transportation-to-healthcare-appointments.png" alt="Doctor's Office Visits">
        <span>Transportation To Healthcare Appointments</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_monthly-allowance-for-over-the-counter-items.png" alt="Doctor's Office Visits">
        <span>Monthly Allowance For Over-the-Counter Items</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_gym-and-fitness-club-memberships.png" alt="Doctor's Office Visits">
        <span>Gym & Fitness Club Memberships</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_rewards-for-healthy-behaviour.png" alt="Doctor's Office Visits">
        <span>Rewards For Healthy Behaviour</span>
      </div>
      <div class="all-in-one-services-wrapper-item">
        <img src="./assets/img/landing-pages/medigap/all-in-one_reduction-of-your-part-b-premium.png" alt="Doctor's Office Visits">
        <span>Reduction of Your Part B Premium</span>
      </div>
    </div>
  </div>
</section>

<section style="background:#f1f1f1" class="p-5">
  <div class="container max-width-880" >
    <h5 class="text-center fColor-black mb-5 fWeight-600">The Key Features of The Medicare Advantage Coverage Experience Are:</h5>
    <ul class="checklist d-flex flex-wrap justify-content-between align-items-start flex-column flex-sm-row">
      <li>Low Monthly Premiums</li>
      <li>Referrals Sometimes Needed (Not with A PPO)</li>
      <li>All Medicare Healthcare Services Under One Plan</li>
      <li>Plan is Enrolled in on a Calendar-Year Basis</li>
      <li>Utilization of a Network That Keeps Your Costs Down</li>
      <li>Additional Benefits Often Included</li>
      <li>Out-of-Pocket Costs Are Limited Each Year</li>
    </ul>
  </div>
</section>

<section class="p-5">
  <div class="container d-flex max-width-880 flex-column flex-sm-row">
    <div class="d-flex-1 p-3">
      <h5 class="fColor-black mb-3">Am I Locked In To A Medicare Advantage Plan?</h5>
      <p>Generally, unless you are new to Medicare,
        or just turning 65, Medicare Advantage plans can only be changed during the 
        <b class="fWeight-600 fColor-black">Annual Enrollment Period</b> (AEP) from october 15th to December 7th each year.
      </p>

      <p>However, there my be exceptions to This
        using what are called <b class="fWeight-600 fColor-black">Special Enrollment Periods (SEP), which we can always check for you.</b>
      </p>
    </div>

    <div class="d-flex-1 p-3">
      <h5 class="fColor-black mb-3">What's The Difference Between an HMO and PPO?</h5>
      <p>HMOs (Health Maintenance Organizations) require you to use only healthcare providers in  
        the designated plan network, and may require referrals from a primarycar physician in order to
        see a specialist.
      </p>

      <p>PPOs (Preferred Provider Organizations) allow you to use healthcare providers
        outside of the plan's network, but you will typically have lower
        out of pocket costs using the plan's in-network
        "preferred" healthcare providers, PPO's do not
        require a referral to see a specialist.
      </p>
    </div>
  </div>
</section>

<section class="bg-primary px-5 pt-5 0">
  <div class="container d-flex justify-content-center">
    <div>
      <img src="./assets/img/common/5-cat-woman.png" alt="" class="desktop-only" style="max-width:400px;-webkit-transform: scaleX(-1);
      transform: scaleX(-1);">
    </div>
    <div>
      <h4 class="fColor-white text-center mb-4 fWeight-600">Helpful Criteria To Evaluate a Medicare Advantage Plan!</h4>
      <div class="helpful-criteria-wrapper">
        <div class="helpful-criteria">
          <span>Criteria 1</span>
          <span>Your Doctors and Providers Are In Network</span>
        </div>
        <div class="helpful-criteria">
          <span>Criteria 2</span>
          <span>It is Optimized for Your Drug Costs</span>
        </div>
        <div class="helpful-criteria">
          <span>Criteria 3</span>
          <span>Low-Out-of-Porcket for The Services You Need Most</span>
        </div>
        <div class="helpful-criteria">
          <span>Criteria 4</span>
          <span>Strong Medicare Star Ratings</span>
        </div>
        <div class="helpful-criteria">
          <span>Criteria 5</span>
          <span>Providers the "Extra Benefits" You Want Most</span>
        </div>
      </div>
      <button class="btn btn-first btn-small mx-auto mt-4 mb-4" (click)="speakToCounselorEvent(speakToCounselorModal)">Speak With a Concierge Now</button>

    </div>

  </div>
</section>
<div class="banner-pointer-wrapper mb-0">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>

<div class="d-none">
  <div #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>


