<div class="row den-ins-table-wrapper">
  <div *ngFor=" let containerResults of results; let i = index" class="full-width">
    <h4 class="fColor-base text-center mb-4" [class.text-light]="!isBlueBgHeading" *ngIf="containerResults[0].annual_max == 0">N/A Annual Maximum Benefit
    </h4>
    <h4 class="fColor-base text-center mb-4" [class.text-light]="!isBlueBgHeading" *ngIf="containerResults[0].annual_max != 0">

      {{ containerResults[0].annual_max }} Annual Maximum Benefit</h4>

      <div class="mapd-result-version-5 container type-1 vp-btn-inside"
        *ngFor="let result of containerResults; let x = index">
        <table class="mapd-result-table">
          <tr class="tr-result-heading" [class.bgBlue]="isBlueBgHeading">
            <th>Company</th>
            <th class="column-triple-content">
              <span class="column-visits">Preventive</span>
              <span class="column-tests">Basic</span>
              <span class="column-surgery">Comprehensive</span>
              <span class="column-vision">Includes</span>
            </th>
            <th>Costs</th>
          </tr>
          <tr>
            <td class="result-logo-wrapper">
              <span class="no-img-plan-name d-none">

                {{ result?.plan_name }}</span>
                <img src="{{ base_img_loc + result?.logo }}"
                  onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
                  alt="{{ result.plan_name }}" width="900px" class="result-logo"
                  (error)="companyLogoCheck($event)" id="logo_{{result.carrier_name}}_{{result.id}}_{{x}}">

                <div class="medicare-advantage-custom-title-rating-wrapper">
                  <h2 #h2ResultPlanName class="results-plan-name mb-0">

                    {{ result?.plan_name }}</h2>
                </div>
            </td>
            <td class="column-triple-content">
              <div class="column-triple-content-inner">
                <div class="column-visits">
                  <div class="value-wrapper">
                    <span class="main-value result-value d-block"
                      [innerHTML]="(result?.preventive_headline) ? result?.preventive_headline : 'N/A'"></span>
                    <small class="fColor-base fWeight-600 d-block text-center"
                      [innerHTML]="(result?.preventive_subline) ? result?.preventive_subline : 'N/A'"></small>
                  </div>
                </div>
                <div class="column-tests">
                  <div class="value-wrapper">
                    <span class="main-value result-value d-block"
                      [innerHTML]="(result?.basic_headline) ? result?.basic_headline : 'N/A'"></span>
                    <small class="fColor-base fWeight-600 d-block text-center"
                      [innerHTML]="(result?.basic_subline) ? result?.basic_subline : 'N/A'"></small>
                  </div>
                </div>
                <div class="column-surgery">
                  <div class="value-wrapper">
                    <span class="main-value result-value d-block"
                      [innerHTML]="(result?.comprehensive_headline) ? result?.comprehensive_headline : 'N/A'"></span>
                    <small class="fColor-base fWeight-600 d-block text-center"
                      [innerHTML]="(result?.comprehensive_subline) ? result?.comprehensive_subline : 'N/A'"></small>
                  </div>
                </div>
                <div class="column-vision result-include-icons">
                  <div *ngIf="result?.includes_vision == 1"><img
                      src="./assets/img/common/mapd-includes-icon-vision.PNG" class="result-vision-icon"
                      alt="vision icon" matTooltip="Vision" matTooltipClass="bg-primary"
                      matTooltipPosition="above"></div>
                  <div *ngIf="result?.includes_hearing == 1"><img
                      src="./assets/img/common/mapd-includes-icon-hearing.png" class="result-hearing-icon"
                      alt="hearing icon" matTooltip="Hearing" matTooltipClass="bg-primary"
                      matTooltipPosition="above"></div>
                  <div *ngIf="result?.ppo_plan == 1"><img src="./assets/img/common/icons/no-image-icon.png"
                      class="result-includes-no-icon" alt="PPO Access" matTooltip="PPO Access"
                      matTooltipClass="bg-primary" matTooltipPosition="above"></div>
                  <div *ngIf="result?.dental_implants == 1"><img src="./assets/img/common/icons/no-image-icon.png"
                      class="result-includes-no-icon" alt="Coverage For Dental Implants"
                      matTooltip="Coverage For Dental Implants" matTooltipClass="bg-primary"
                      matTooltipPosition="above"></div>
                  <div *ngIf="result?.reimburse_any_dentist == 1"><img
                      src="./assets/img/common/icons/no-image-icon.png" class="result-includes-no-icon"
                      alt="Reimburse For Any Dentist" matTooltip="Reimburse For Any Dentist"
                      matTooltipClass="bg-primary" matTooltipPosition="above"></div>
                </div>
              </div>              
            </td>
            <td class="costs-wrapper">
              <span class="fColor-blue uppercase fWeight-500" style="font-size:20px;">Premium</span>
              <span class="main-value fColor-black mt-0">${{(result.price) ? result.price : 'N/A' }}</span>
              <span class="sub-value mb-3">Per Month</span>

              <span class="fColor-blue uppercase fWeight-500" style="font-size:13px;">Annual Max</span>
              <span class="main-value fColor-black mt-0"
                style="font-size:20px;">{{(result?.annual_max) ? result?.annual_max : 'N/A' }}</span>
            </td>
          </tr>
          <tr class="border-b-none">
            <div class="plan-document-buttons d-flex flex-column flex-md-row pt-3">                      
              <div class="w-100 mx-md-2 mb-2 mb-md-0">
                <button id="#button1_{{result.plan_pricing_id}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_planDocsResources(result.plan_pricing_id)">
                  <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
                  More Benefits & Documents
                </button>
                <div class="d-none justify-content-center mt-2" id="arrow1_{{result.plan_pricing_id}}">
                  <div class="arrow"></div>
                </div>
              </div>
              <div class="w-100 mx-md-2 mb-2 mb-md-0">
                <button id="#button2_{{result.plan_pricing_id}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_doctorDrugAnalysis(result.plan_pricing_id)">
                  <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
                  More Plan Details
                </button>
                <div class="d-none justify-content-center mt-2" id="arrow2_{{result.plan_pricing_id}}">
                  <div class="arrow"></div>
                </div>
              </div>
              <div class="w-100 mx-md-2 mb-2 mb-md-0">
                <button id="#button3_{{result.plan_pricing_id}}" class="compare-button btn btn-third btn-xs position-relative" (click)="onclick_planActionButtons(result.plan_pricing_id)">
                  Save, Compare, or Enroll
                </button>
                <div class="d-none justify-content-center mt-2" id="arrow3_{{result.plan_pricing_id}}">
                  <div class="arrow"></div>
                </div>
              </div>
            </div>
          </tr>
        </table>

        <!-- MORE BENEFITS & DOCUMENTS -->
        <div class="d-none row doctors-drugs-actions2 mx-auto" id="docs_resources_id_{{result.plan_pricing_id}}">
          <div class="col-12 col-lg-6 p-4 mx-auto">
            <div class="p-4 bg-light border h-100">
              <div class="d-flex justify-content-center">
                <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="">
              </div>
              <h4 class="title-header text-center text-dgray">More Plan Insights & Details</h4>
              <div class="w-full d-flex flex-column justify-content-around">      
                <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="additionalInsights(additionalDocuments, result)">Additional Insights</button>    
                <a class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" target="_blank"
                  href="/insurance-marketplace/dental/plan-profile/{{result.id}}/{{this.controls.zipcode.value}}?age={{controls.age.value}}"
                  id="view_benefits_{{result.carrier_name}}_{{result.id}}_{{x}}">View All Benefits</a>
                <a href="javascript:void(0)" class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second">Policy Brochure</a>
              </div>
            </div>
          </div>
        </div>

        <!-- DOCTORS & DRUGS -->
        <div class="d-none row doctors-drugs-actions2 mx-auto" id="doctors_drugs_id_{{result.plan_pricing_id}}">
          <div class="col-12 col-lg-6 p-4 mx-auto">
            <div class="bg-light p-4">
              <div class="d-flex justify-content-center">
                  <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="">
              </div>
              <h4 class="title-header text-center">Company Profile & Reviews</h4>
              <div class="w-full d-flex flex-column justify-content-around">
                <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="companyProfile(result)">Company Profile</button>
                <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="consumerReview(result)">Consumer Reviews</button>                
              </div>        
            </div>
          </div>
        </div>

        <!-- PLAN ACTION BUTTONS -->
        <div class="d-none row doctors-drugs-actions2 mx-auto" id="plan_actions_id_{{result.plan_pricing_id}}">
          <div class="col-12 col-lg-6 p-4">
            <div class="p-4 bg-light border h-100 enrollment-button">
              <!-- <button class="saveplan-button w-100 my-2 btn btn-small btn-second">Save This Plan</button>
              <div class="addcompare-button w-100 my-2 d-flex align-items-center justify-content-center">
                <input type="checkbox" class="compare-input" id="addToCompare_{{result.plan_pricing_id}}" (change)="addToCompareFunc(result, $event, x, '.compared-plans-btn-wrapper', 'a.viewComparePlansBtn')" [checked]="logComparedKeys.includes(result.plan_pricing_id)">
                <label class="compare-label mb-0" for="addToCompare_{{result.plan_pricing_id}}">Add To Compare</label>
              </div>
              <a id="view_compare_btn_{{x}}" target="_blank" href="/insurance-marketplace/dental/results/compare-plans?compare={{dental_array_id}}&age={{controls.age.value}}"
                    class="viewComparePlansBtn btn btn-third-plain btn-small d-none ff"
                    [class.d-block]="logComparedKeys.includes(result.plan_pricing_id)">View Compare Plans</a> -->
              <button class="save d-flex align-items-center px-3 py-2 my-2" (click)="saveThisPlan()">
                <img class="small-icon" src="./assets/img/common/save.svg" alt="">
                <div class="d-flex justify-content-center flex-grow-1">
                  <span>SAVE THIS PLAN</span>
                </div>
              </button>
              <app-share-plan [sharePlanId]=result.key></app-share-plan>
              <button class="add d-flex align-items-center my-2 add-to-compare-button">
                <div class="checkbox-wrapper d-flex align-items-center justify-content-center ml-4">
                  <input type="checkbox" class="compare-input" id="addToCompare_{{result.plan_pricing_id}}" (change)="addToCompareFunc(result, $event, x, '.compared-plans-btn-wrapper', 'a.viewComparePlansBtn')" [checked]="logComparedKeys.includes(result.plan_pricing_id)">
                </div>
                <div class="d-flex justify-content-center flex-grow-1 add-to-compare-label-wrapper">
                  <label for="addToCompare_{{result.plan_pricing_id}}">ADD TO COMPARE</label>
                </div>
              </button>
              <a id="view_compare_btn_{{x}}" target="_blank" href="/insurance-marketplace/dental/results/compare-plans?compare={{dental_array_id}}&age={{controls.age.value}}&zipcode={{controls.zipcode.value}}"
              class="viewComparePlansBtn btn btn-third-plain btn-small d-none"
              [class.d-block]="logComparedKeys.includes(result.plan_pricing_id)">View Compare Plans</a>
          </div>
          </div>
          <div class="col-12 col-lg-6 p-4">
            <div class="p-4 bg-light border h-100 enrollment-button">
              <!-- <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnline(result, x)">Enroll Online</button>
              <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnThePhone(result)">Enroll On The Phone</button> -->
              <button class="online d-flex align-items-center px-3 py-2 my-2" (click)="applyOnline(result, x)">
                <img class="small-icon" src="./assets/img/common/online.svg" alt="">
                <div class="d-flex justify-content-center flex-grow-1">
                  <span>ENROLL ONLINE</span>
                </div>
              </button>
              <button class="phone d-flex align-items-center px-3 py-2 my-2" (click)="applyOnThePhone(result)">
                <img class="small-icon" src="./assets/img/common/phone.svg" alt="">
                <div class="d-flex justify-content-center flex-grow-1">
                  <span>ENROLL ON THE PHONE</span>
                </div>
              </button>
              <button class="cart d-flex align-items-center p-0" id="ci_cart_btn__{{result.plan_pricing_id}}" [class.active]="logCartKeys.includes(result.plan_pricing_id)">
                <label class="cart-btn-label" for="ci_add_to_cart__{{result.plan_pricing_id}}">
                  <img class="small-icon" src="./assets/img/common/cart.svg" alt="">
                  <span class="flex-grow-1">{{ addToCartLabel }}</span>
                </label>
              </button>
              <input type="checkbox" class="d-none" id="ci_add_to_cart__{{ result.plan_pricing_id }}" (change)="onClick_AddToCart(result,$event)" [checked]="logCartKeys.includes(result.plan_pricing_id)">
              <a target="_blank"
                  href="/cart"
                  class="viewCartBtn btn btn-third-plain btn-sm d-none ff mt-2"
                  id="view_cart_btn_{{result.plan_pricing_id}}"
                  [class.d-block]="logCartKeys.includes(result.plan_pricing_id)">View Cart</a>
            </div>
          </div>
        </div>
        
      </div>

      <!-----------------------------------------------------------------------
-----------------------OCT 13, 2021 - END VERSION 5- TYPE 2 - hide and show plan doc DESIGN------------------------
--------------------------------------------------------------------------->

  </div>
</div>