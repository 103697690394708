<div class="container">
  <div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitDate" class="text-label">Last Doctors Visit Date</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" [(ngModel)]="lastDoctorsVisitDate" id="lastDoctorsVisitDate" (click)="onClickDob(appDOB,'normal','Set Date')"/>
          <i class="fas fa-calendar"></i>
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitNameOfProvider" class="text-label">Last Doctors Visit Name of Provider</label>
        <input type="text" class="form-control blurred" id="lastDoctorsVisitNameOfProvider"/>
      </div>

      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitNameOfProvider" class="text-label">Last Doctors Visit Reason</label>
        <input type="text" class="form-control blurred" id="lastDoctorsVisitNameOfProvider"/>
      </div>

      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitNameOfProvider" class="text-label">Last Doctors Visit Results</label>
        <input type="text" class="form-control blurred" id="lastDoctorsVisitNameOfProvider"/>
      </div>

      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastInpatientDate" class="text-label">Last Inpatient Hospitalization or Emergency Room Visit Date</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" [(ngModel)]="lastInpatientDate" id="lastInpatientDate" (click)="onClickDob(appDOB,'normal','Set Date')"/>
          <i class="fas fa-calendar"></i>
        </div>
      </div>

      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitNameOfProvider" class="text-label">Last Inpatient Hospitalization Reason</label>
        <input type="text" class="form-control blurred" id="lastDoctorsVisitNameOfProvider"/>
      </div>
      
      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitNameOfProvider" class="text-label">Last Inpatient Hospitalization Results</label>
        <input type="text" class="form-control blurred" id="lastDoctorsVisitNameOfProvider"/>
      </div>

      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitNameOfProvider" class="text-label">Any Upcoming Procedures?</label>
        <input type="text" class="form-control blurred" id="lastDoctorsVisitNameOfProvider"/>
      </div>
      
      <div class="form-group col-12 col-md-6 p-2">
        <label for="lastDoctorsVisitNameOfProvider" class="text-label">Type of Upcoming Procedures</label>
        <input type="text" class="form-control blurred" id="lastDoctorsVisitNameOfProvider"/>
      </div>

      <div class="form-group col-12 col-md-6 p-2">
        <label for="dateUpcomingProcedure" class="text-label">Date of Upcoming Procedures</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" [(ngModel)]="dateUpcomingProcedure" id="dateUpcomingProcedure" (click)="onClickDob(appDOB,'normal','Set Date')"/>
          <i class="fas fa-calendar"></i>
        </div>
      </div>
      
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>