<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b [showNumber]="true"></app-header-b>

  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-md-8">
        <div class="banner-text-wrapper">
          <h1 class="banner-main-title">
            <span>New To Medicare?</span><br />
            A Clear and Guided Experience
          </h1>
          <p class="banner-sub-title">
            The "New To Medicare" process is not easy. Not for anyone.<br />
            The TapTap experience makes it a breeze.
          </p>
        </div>
      </div>
      <div class="col-md-4 px-0 banner-image-wrapper">
        <img
          src="assets/img/common/medicare/old-lady-meditating.png"
          class="banner-main-image mb-neg-50"
        />
      </div>
    </div>
  </div>
</div>

<div class="banner-pointer-wrapper">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>

<section class="container-fluid main-content-wrapper home-wrapper">
  <div class="row home-content">
    <div class="col-12 row home-content-first-box-wrapper">
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 row justify-content-center mt-50">
              <div class="col-8">
                <h4 class="center border-dashed-welcome">
                  The New To Medicare process is not easy. <br />
                  Not for anyone.<br />
                  In fact, it can be pretty daunting.
                </h4>
              </div>
            </div>
            <div class="col-12 row justify-content-center">
              <img src="assets/img/i-am-new-to-medicare/old-man-doubt.png" />
            </div>
            <div class="col-12 row justify-content-center">
              <div class="col-8">
                <h4 class="center border-dashed-welcome">
                  However, it can actually be made quite<br />
                  <span>Simple and Clear.</span>
                </h4>
              </div>
            </div>
            <div class="col-12 row justify-content-center">
              <img src="assets/img/i-am-new-to-medicare/old-man-idea.png" />
            </div>
            <div class="col-12 row justify-content-center mt-20">
              <button
                (click)="scheduleTime()"
                class="col-md-6 row btn btn-first-plain padding-20 width-auto"
              >
                <h4 class="col-12 fColor-white center">SCHEDULE A TIME</h4>
                <label
                  class="col-12 fColor-white center"
                  style="margin-top: 5px"
                  >TO SEE HOW TAPTAP MAKES IT EASY</label
                >
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 home-content-row-div mt-50 blue-bg">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div
              class="row col-12 justify-content-center text-center padding-50"
            >
              <h5 class="fColor-white normal">
                With the right approach, and guidance, you can understand it all
                <br />
                very simply, and make all the right choices.
              </h5>
              <h2 class="fColor-white mt-20">
                And Even Enjoy Doing So While You're At It.
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block"
      >
        <i class="fa fa-arrow-down"></i>
        <div class="banner-pointer-background"></div>
      </div>

      <div class="col-md-12 home-content-row-div mt-50">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div
              class="row col-12 justify-content-center text-center padding-50"
            >
              <h2>3 Enjoyable Options To Choose From</h2>
              <h5 class="normal mt-20">
                To Help You Organize and Streamline Your Insurance.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div
        class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block"
        style="z-index: 1"
      >
        <i class="fa fa-arrow-down font-blue"></i>
        <div class="banner-pointer-background inverse-colored-down-arrow"></div>
      </div>

      <div class="col-md-12 home-content-row-div blue-bg neg-83-margin-top">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div
              class="row col-12 justify-content-center text-center padding-50 mt-50"
            >
              <div class="col-md-3 row justify-content-center card-option">
                <div class="col-12 center">
                  <h6>
                    The Dedicated <br />
                    Counselor
                  </h6>
                </div>
                <div class="col-8 center mt-20">
                  <div class="thumbnail1"></div>
                </div>
                <div class="col-12 center mt-20">
                  <button
                    (click)="scheduleTime()"
                    _ngcontent-c6=""
                    class="btn btn-third"
                  >
                    RESERVE YOUR<br />TIME NOW
                  </button>
                </div>
              </div>

              <div class="col-md-3 row justify-content-center card-option">
                <div class="col-12 center">
                  <h6>The Self Learner<br />&nbsp;</h6>
                </div>
                <div class="col-8 center mt-20">
                  <div class="thumbnail2"></div>
                </div>
                <div class="col-12 center mt-20">
                  <button
                    (click)="goToGuide()"
                    _ngcontent-c6=""
                    class="btn btn-third"
                  >
                    REQUEST YOUR<br />CUSTOM GUIDES
                  </button>
                </div>
              </div>

              <div class="col-md-3 row justify-content-center card-option">
                <div class="col-12 center">
                  <h6>The TapTap<br />Recommendation Engine</h6>
                </div>
                <div class="col-8 center mt-20">
                  <div class="thumbnail3"></div>
                </div>
                <div class="col-12 center mt-20">
                  <button
                    (click)="onClickChat($event)"
                    _ngcontent-c6=""
                    class="btn btn-third"
                    id="send_msg_ntm"
                  >
                    START A<br />CHAT NOW
                  </button>
                </div>
              </div>
            </div>
            <div
              class="row col-12 justify-content-center text-center padding-50"
            >
              <h4 class="fColor-white normal">
                Or Call Us Now At <b style="color: #ffa624">1(800) 215-5108</b>
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-12 home-content-row-div blue-bg"
        style="height: 200px; margin-top: -20px"
      >
        <div class="container"></div>
      </div>

      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div class="row col-12 justify-content-center text-center">
              <div class="col-10 row justify-content-center lets-talk">
                <h3>Not Sure What You Need? Let's Talk.</h3>
                <p>
                  Reserve your own dedicated time with an in-house counselor to
                  receive clear<br />
                  explanations, a full consultation, or a brief "Coverage
                  Clarity" session to better<br />
                  understand your own current coverage.
                </p>
                <button
                  (click)="speakToCounselorEvent(speakToCounselorModal, 540)"
                  class="col-md-5 btn btn-first-plain padding-20 width-auto"
                >
                  Speak With a Concierge >>
                </button>
              </div>
              <div class="col-10 row justify-content-center">
                <img
                  src="assets/img/common/medicare/old-woman-with-a-kit-half.png"
                  class="lets-talk-old-woman"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="d-none">
  <div #videoContent>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <div
          class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true"
          style="height: 100%; position: relative; width: 100%"
        >
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            "
          >
            <img
              src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch"
              style="
                filter: blur(5px);
                height: 100%;
                object-fit: contain;
                width: 100%;
              "
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <span class="wistia_embed wistia_async_9rgfxy7pqm popover=true popoverContent=link" style="display:inline;position:relative"><a href="#">This is my popover!</a></span> -->

<div class="d-none {{ dblocking }}" #speakToCounselorModal>
  <app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>
