import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-application-medigap-version1-screen5',
  templateUrl: './screen5.component.html',
  styleUrls: ['./screen5.component.scss']
})
export class Screen5Component implements OnInit {

  addEmergencyContact: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
