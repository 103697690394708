<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b></app-header-b>

  <div class="container home-banner banner-wrapper">
    <div class="d-flex">
      <div class="home-banner-left">
        <div class="home-banner-text-wrapper">
          <h1 class="banner-main-title">
            <!-- <span>Give Yourself A</span><br>  -->
            The First State Deserves
            A Clear & Organized
            Medicare Experience
          </h1>
          <p class="banner-sub-title">
            Delaware Residents Deserve Clarity When It Comes To
            Healthcare, A Team That Takes Accountability, and A
            Personalized Guided Approach
          </p>
        </div>
      </div>
      <div class="home-banner-right banner-image-wrapper">
        <img src="assets/img/landing-pages/lp-delaware-banner-char.png">
      </div>
    </div>
  </div>
</div>

<div class="container city-based-3-button-wrapper mt-5">
  <button class="btn btn-first" routerLink="/about">Learn More About Taptap & Our Team</button>
  <button class="btn btn-first" routerLink="/insurance-marketplace">Start Viewing Medicare Plans & Prices</button>
  <button class="btn btn-first" (click)="speakToCounselorEvent(speakToCounselorModal)">Schedule Your Organization Session</button>
</div>

<app-insurance-logo></app-insurance-logo>
<mat-divider class="insuranceLogo-home-content-divider"></mat-divider>
<section class="container-fluid main-content-wrapper home-wrapper pb-0">
  <div class="row home-content">
    <div class="col-12 row home-content-first-box-wrapper">
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
              <img src="assets/img/home/new/home-medicare-solves-medicare-problems.png">
            </div> 
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5">
              <div class="home-text-box">
                <h2>A Solutions Driven Medicare Team</h2>
                <mat-list>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Easily Compare Plans and Prices</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Customize the Medicare Plan You Want</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Set Up Automatic Savings Checks</p>
                  </mat-list-item>
                </mat-list>
                <button class="btn btn-first" (click)="speakToCounselorEvent(speakToCounselorModal)">Call Us Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row flex-column-reverse flex-md-row align-items-center">
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start text-center text-md-left pr-md-5">
              <div class="home-text-box">
                <h2>Learn and Understand Enjoyably</h2>
                <mat-list>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Watch Videos</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Access Our E-Courses, Articles and Infographics</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>View Our Consumer-Friendly Illustrated Guides</p>
                  </mat-list-item>
                </mat-list>
                <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  <button class="btn btn-third mr-0 mr-sm-2" routerLink="/insurance-marketplace">Visit Plans & Prices</button>
                  <button class="btn btn-third" routerLink="/education">View Our Educational Guides</button>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center align-items-center justify-content-md-end mb-4 mb-md-0">
              <img src="assets/img/home/new/home-learn-and-understand-enjoyably.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row home-content-second-box-wrapper">
      <div class="col-md-12 home-content-row-div">
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
              <img src="assets/img/home/new/home-be-organized.png">
            </div>
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5">
              <div class="home-text-box">
                <h2>Be Perfectly Organized</h2>
                <mat-list>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>All The Details Of Your Insurances Saved And Secured</p>
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon mat-list-icon color="primary">check</mat-icon>
                    <p>Your Plan Info Easily Accessible At All Times</p>
                  </mat-list-item>
                </mat-list>
                <button class="btn btn-first" routerLink="/contact">Send Us A Message</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-enjoyable-options></app-enjoyable-options>
</section>
<div class="footer-top-spacer bg-primary"></div>
<app-ready-to-talk-landing></app-ready-to-talk-landing>
<!-- <app-ready-to-talk></app-ready-to-talk> -->

<div class="d-none">
  <div #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>