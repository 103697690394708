<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Attestation of Eligibility for an Enrollment Period</h4>
    <div class="yellow-line"></div>
    <div>
      <p class="text-label">Typically, you may enroll in a Medicare Advantage plan only during the annual enrollment period from October 15 through December 7 of each year. There are exceptions that may allow you to enroll in a Medicare Advantage plan outside of this period.</p>
      <div class="mt-3">
        <p class="text-label">Please read the following statements carefully and check the box next to the statements that apply to you.</p>
        <ul>
          <li>By checking any of the following boxes, you are certifying that, to the best of your knowledge, you are eligible for an Enrollment Period.</li>
          <li>If the plan insurance company later determines that this information is incorrect, you may be disenrolled from the plan.</li>
        </ul>
      </div>
      <div *ngIf="storedScenario" class="dashed-box border shadow p-4 my-4">
        <div class="row">
          <div class="col-12 p-2">
            <span class="text-label">Selected MAPD Enrollment Period On Application</span>
            <p class="selected-mapd">{{ storedScenario }}</p>
          </div>
          <div class="col-12 p-2">
            <span class="text-label">Applied-For MAPD Enrollment Period</span>
            <div class="green-field">
              <i class="fas fa-check-circle"></i>
              <p>{{ storedScenario }}</p>
            </div>
          </div>
          <div class="col-12 p-2">
            <span class="text-label">Applied-For MAPD Enrollment Period Details</span>
            <div class="green-field">
              <i class="fas fa-check-circle"></i>
              <p>I moved back to the U.S. after living outside the country.</p>
            </div>
          </div>
          <div class="col-12 p-2">
            <span class="text-label">Applied-For MAPD Enrollment Period Qualification Date</span>
            <div class="green-field">
              <i class="fas fa-check-circle"></i>
              <p>March 14, 2022</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4">
      <h5 class="text-dblue fw-semibold">
        The Following Scenario Applies To Me (Check Any That Apply):
      </h5>
      <div class="dashed-box p-3 my-3">
        <h6 class="text-secondary">Most Common</h6>
        <div class="px-3">
          <div class="d-flex flex-column">
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'a1')" type="radio" id="mostCommon_1" name="mostCommon" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="mostCommon_1">I Am “New to Medicare”</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'a2')" type="radio" id="mostCommon_2" name="mostCommon" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="mostCommon_2">I Have Been On Medicare But <strong>I Am Just Turning Age 65 Now</strong></label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'a3')" type="radio" id="mostCommon_3" name="mostCommon" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="mostCommon_3"><strong>I Moved</strong>/Changed Where I Live</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'a4')" type="radio" id="mostCommon_4" name="mostCommon" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="mostCommon_4"><strong>I Lost Other Coverage</strong> or Had An Involuntary Change In My Coverage</label>
            </div>
          </div>
        </div>
      </div>
      <div class="dashed-box p-3 my-3">
        <h6 class="text-secondary">Financial Circumstances</h6>
        <div class="px-3">
          <div class="d-flex flex-column">
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'b1')" type="radio" id="financialCircumstances_1" name="financialCircumstances" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="financialCircumstances_1">I Am Currently Enrolled In A <strong>Financial Assistance Program</strong> (Like Extra Help, Medicaid, or a State Drug Cost Assistance Program)</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'b2')" type="radio" id="financialCircumstances_2" name="financialCircumstances" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="financialCircumstances_2">I Recently <strong>Had A Change In My Eligibility</strong> For a Financial Assistance Program</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'b3')" type="radio" id="financialCircumstances_3" name="financialCircumstances" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="financialCircumstances_3">I Have Low Income & Want To <strong>Check If I Am Eligible</strong> For a Financial Assistance Program</label>
            </div>
          </div>
        </div>
        <button class="btn btn-small btn-third-plain text-underline mx-auto">Click Here To See The Full List Based On Your State</button>
      </div>
      <div class="dashed-box p-3 my-3">
        <h6 class="text-secondary">Other Circumstances</h6>
        <div class="px-3">
          <div class="d-flex flex-column">
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'c1')" type="radio" id="otherCircumstances_1" name="otherCircumstances" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="otherCircumstances_1">I'm In a Medicare Advantage Plan Now & <strong>Have Had Medicare For Less Than 3 Months</strong> & Want to Make a Change</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'c2')" type="radio" id="otherCircumstances_2" name="otherCircumstances" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="otherCircumstances_2">I Was <strong>Affected By a FEMA-Designated Emergency</strong> (or Major Disaster) That Caused Me To Be Unable To Make A Prior Eligible Enrollment Due To The Designated Emergency</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'c3')" type="radio" id="otherCircumstances_3" name="otherCircumstances" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="otherCircumstances_3">I'm Moving Into, Live In, Or Recently Moved Out Of A <strong>Long-Term Care Facility</strong> (Like A Nursing Home or Rehabilitation Hospital)</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'c4')" type="radio" id="otherCircumstances_4" name="otherCircumstances" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="otherCircumstances_4">I Recently Received <strong>Lawful Presence Status In The U.S.</strong></label>
            </div>
          </div>
        </div>
      </div>
      <div class="dashed-box p-3 my-3">
        <h6 class="text-secondary">Based On The Previous or Existing Coverage I've Had</h6>
        <div class="px-3">
          <div class="d-flex flex-column">
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'d1')" type="radio" id="basedPreviousCoverage_1" name="basedPreviousCoverage" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="basedPreviousCoverage_1">I'm In A Plan That's <strong>Had a Star Rating Of Less Than 3 Stars</strong> For The Last 3 Years. I Want To Join A Plan With A Star Rating Of 3 Stars Or Higher</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'d2')" type="radio" id="basedPreviousCoverage_2" name="basedPreviousCoverage" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="basedPreviousCoverage_2"><strong>I Was Automatically Enrolled</strong> In A Plan By Medicare (Or My State) & I Want To Choose A Different Plan</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'d3')" type="radio" id="basedPreviousCoverage_3" name="basedPreviousCoverage" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="basedPreviousCoverage_3"><strong>I No Longer Qualify For My Previous</strong> Chronic Illness Special Needs Plan Or Dual Eligible Special Needs Plan</label>
            </div>
          </div>
        </div>
      </div>
      <div class="dashed-box p-3 my-3">
        <h6 class="text-secondary">Time-of-Year or Calendar-Based Periods</h6>
        <div class="px-3">
          <div class="d-flex flex-column">
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'e1')" type="radio" id="timeOfYear_1" name="timeOfYear" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="timeOfYear_1">I Am <strong>Within October 15th - December 7th</strong> (The Annual Enrollment Period)</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'e2')" type="radio" id="timeOfYear_2" name="timeOfYear" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="timeOfYear_2">I Have A Medicare Advantage Plan Currently & Am <strong>Within January 1st - March 31st</strong> (The Medicare Advantage Open Enrollment Period)</label>
            </div>
          </div>
        </div>
      </div>
      <div class="dashed-box p-3 my-3">
        <h6 class="text-secondary">Characteristics of The Plan You Are Enrolling In</h6>
        <div class="px-3">
          <div class="d-flex flex-column">
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'f1')" type="radio" id="planCharacteristics_1" name="planCharacteristics" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="planCharacteristics_1">I Am Enrolling Into a <strong>5-Star Plan</strong></label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input (click)="selectScenario(selectScenarioModal,'f2')" type="radio" id="planCharacteristics_2" name="planCharacteristics" class="custom-control-input">
              <label class="custom-control-label text-dblue" for="planCharacteristics_2">I Am Enrolling Into a <strong>Chronic Illness Special Needs Plan</strong> That I Qualify For</label>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div>
      <div class="d-flex justify-content-center align-items-center">
        <button class="btn btn-third btn-xs mr-2">View More</button>
        <div class="d-flex align-items-center">
          <span class="mr-2">OR, Use our</span>
          <a href="" class="text-underline">Interactive Enrollment Period Identifier</a>
        </div>
      </div>
      <p class="text-center p-4"><em>If none of these statements applies to you or you’re not sure, please contact TapTap Medicare at 1(800) 215-5108 Monday-Friday, 9:00AM EST-6:30PM EST to determine your Enrollment Period Qualifiers that will allow you to enroll.</em></p>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>

  <div #selectScenarioModal>
    <h5 class="text-center fw-semibold text-dblue mb-2">Confirm If You Want To Use This Enrollment Period?</h5>
    <div class="yellow-modal-box">
      <span>{{ selectedScenario }}</span>
      <i class="fas fa-quote-right"></i>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <button class="btn btn-first btn-small gray-button mx-2" (click)="rejectScenario()">No</button>
      <button class="btn btn-first btn-small mx-2" (click)="scenarioSelection()">Yes</button>
    </div>
  </div>

  <div #a3Modal>
    <h5 class="text-center modal-heading"><strong>I Moved</strong>/Changed Where I Live</h5>
    <div class="my-3">
      <span class="text-label">When did you move?</span>
      <div class="row justify-content-center p-3">
        <div class="col-12 col-md-8">
          <div class="dob-field">
            <input type="text" class="form-control blurred" id="fname" placeholder="- Select Date -" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
            <i class="fas fa-calendar"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3">
      <span class="text-label">Which of the following circumstances best applies to you?</span>
      <div class="d-flex flex-column p-3">
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_1" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_1">I moved to a new address that isn’t in my plan’s service area.</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_2" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_2">I moved to a new address that’s still in my plan's service area, but I have new plan options in my new location.</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_3" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_3">I moved back to the U.S. after living outside the country.</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_4" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_4">I was released from jail.</label>
        </div>
      </div>
    </div>
    <button class="btn btn-first btn-small mx-auto">Confirm</button>
  </div>

  <div #a4Modal>
    <h5 class="text-center modal-heading"><strong>I Lost Other Coverage</strong> or Had An Involuntary Change In My Coverage</h5>
    <div class="my-3">
      <span class="text-label">When did you lost the other coverage?</span>
      <div class="row justify-content-center p-3">
        <div class="col-12 col-md-8">
          <div class="dob-field">
            <input type="text" class="form-control blurred" id="fname" placeholder="- Select Date -" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
            <i class="fas fa-calendar"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3">
      <span class="text-label">Which of the following circumstances best applies to you?</span>
      <div class="d-flex flex-column p-3">
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_1" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_1">I left coverage from my employer or union (including COBRA coverage)</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_2" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_2">I No Longer Qualify For My Previous Chronic Illness Special Needs Plan Or Dual Eligible Special Needs Plan (Or Within The Past 3 Months)</label>
        </div>
      </div>
    </div>
    <button class="btn btn-first btn-small mx-auto">Confirm</button>
  </div>

  <div #b1Modal>
    <h5 class="text-center modal-heading">I Am Currently Enrolled In A <strong>Financial Assistance Program</strong> (Like Extra Help, Medicaid, or a State Drug Cost Assistance Program - Click Here To See The Full List Based On Your State)</h5>
    <div class="my-3">
      <span class="text-label">Which of the following circumstances best applies to you?</span>
      <div class="d-flex flex-column p-3">
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_1" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_1">I have both Medicare and Medicaid (or my state helps pay for my Medicare premiums) or I get Extra Help, but I haven’t had a change.</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_2" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_2">I'm enrolled in a State Pharmaceutical Assistance Program (SPAP) </label>
        </div>
      </div>
    </div>
    <button class="btn btn-first btn-small mx-auto">Confirm</button>
  </div>

  <div #b2Modal>
    <h5 class="text-center modal-heading">I Recently <strong>Had A Change In My Eligibility</strong> For a Financial Assistance Program</h5>
    <div class="my-3">
      <span class="text-label">When did you have a change in your eligibility?</span>
      <div class="row justify-content-center p-3">
        <div class="col-12 col-md-8">
          <div class="dob-field">
            <input type="text" class="form-control blurred" id="fname" placeholder="- Select Date -" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
            <i class="fas fa-calendar"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3">
      <span class="text-label">Which of the following circumstances best applies to you?</span>
      <div class="d-flex flex-column p-3">
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_1" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_1">I had a change in the level of Extra Help, had a change in the level of Extra Help, or lost Extra Help.  </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_2" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_2">I recently had a change in my Medicaid (newly got Medicaid, had a change in level of Medicaid assistance, or lost Medicaid).</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline mb-2">
          <input type="radio" id="details_3" name="details" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-dblue" for="details_3">I’ve gained or lost State Pharmaceutical Assistance Program eligibility. </label>
        </div>
      </div>
    </div>
    <button class="btn btn-first btn-small mx-auto">Confirm</button>
  </div>

  <div #c3Modal>
    <h5 class="text-center modal-heading">I’m Moving Into, Live In, Or Recently Moved Out Of A <strong>Long-Term Care Facility</strong> (Like A Nursing Home or Rehabilitation Hospital)</h5>
    <div class="my-3">
      <span class="text-center text-label">When did you move out?</span>
      <div class="row justify-content-center p-3">
        <div class="col-12 col-md-8">
          <div class="dob-field">
            <input type="text" class="form-control blurred" id="fname" placeholder="- Select Date -" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
            <i class="fas fa-calendar"></i>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-first btn-small mx-auto">Confirm</button>
  </div>

  <div #d3Modal>
    <h5 class="text-center modal-heading"><strong>I No Longer Qualify For My Previous</strong> Chronic Illness Special Needs Plan Or Dual Eligible Special Needs Plan</h5>
    <div class="my-3">
      <span class="text-center text-label">When did it occur?</span>
      <div class="row justify-content-center p-3">
        <div class="col-12 col-md-8">
          <div class="dob-field">
            <input type="text" class="form-control blurred" id="fname" placeholder="- Select Date -" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
            <i class="fas fa-calendar"></i>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-first btn-small mx-auto">Confirm</button>
  </div>

</div>