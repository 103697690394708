<div *ngIf="!loading && results.length">
  <div class="result-item pdp-version-5 result-version-5 container columns3 vp-btn-inner" *ngFor="let result of results; let i = index">
    <table class="container result-section">
      <tr class="tr-result-heading" [class.bgBlue]="isBlueBgHeading">
        <th class="heading-company">Company</th>
        <th class="heading-benefits-costs">
          <span class="heading-ratings">Benefits</span>
          <span class="heading-ratings">Ratings</span>
          <span class="heading-pricing">Costs</span>
        </th>
        <th class="heading-action d-none">Actions</th>
      </tr>
      <tr>
        <td class="result-logo-wrapper" *ngIf="result.plan_name=='SilverScript SmartRx (PDP)' || result.plan_name=='SilverScript Choice (PDP)' || result.plan_name=='SilverScript Plus (PDP)'">
          <span class="no-img-plan-name d-none">{{ result.plan_name }}</span>
          <img src="{{base_img_loc + 'silverscript.jpg'}}"
               onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
               alt="{{ result.plan_name }}"
               class="result-logo"
               (error)="companyLogoCheck($event)"
               id="logo_{{result.organization_name}}_{{result.key}}_{{i}}">

            <h6 class="results-plan-name">{{ result.plan_name }}</h6>

            <div class="d-flex align-items-center justify-content-center star-rating">
              <app-star-rating rating="{{ result?.overall_star_rating }}"></app-star-rating>
            </div>
        </td>

        <td class="result-logo-wrapper"  *ngIf="result.plan_name!='SilverScript SmartRx (PDP)' && result.plan_name!='SilverScript Choice (PDP)' && result.plan_name!='SilverScript Plus (PDP)'">
            <span class="no-img-plan-name d-none">{{ result.plan_name }}</span>

            <img src="{{base_img_loc + replaceSpaceWith(result.organization_name) + '.jpg'}}"
                 onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
                 alt="{{ result.plan_name }}"
                 class="result-logo"
                 (error)="companyLogoCheck($event)"
                 id="logo_{{result.organization_name}}_{{result.key}}_{{i}}">

            <h6 class="results-plan-name">{{ result.plan_name }}</h6>

            <div class="d-flex align-items-center justify-content-center star-rating">
              <app-star-rating rating="{{result?.overall_star_rating}}"></app-star-rating>
            </div>
        </td>

        <td class="benefits-costs-wrapper">
          <div class="benefits-costs-inner">
            <div class="benefits-costs-wrapper-left d-flex flex-column">
              <button class="btn btn-first btn-small mb-2" (click)="onClick_ViewGeneralCopaysPerTier(result)">
                  View General Copays Per Tier
              </button>
              <button class="btn btn-first btn-small mb-2">
                  Enter your Drugs to view Specific Copays
              </button>
            </div>
            
            <div class="d-flex flex-column">
              <span class="fColor-blue uppercase fWeight-600 d-block text-center" style="font-size:20px;margin-top: 16px;">Deductible</span>
              <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em" style="font-size:20px;">{{getConvertedRate(result.annual_drug_deductible) | currency }}</span>
              <small class="fColor-base fWeight-600 d-block text-center">Per Year</small>                
              <div class="d-flex flex-column align-items-center mt-3">
                <div class="d-flex align-items-center">
                  <span class="fColor-faint-grey uppercase fWeight-600 d-block text-center mr-2" style="font-size:13px; line-height: 0.5cm;">Tiers Exempt</span>
                  <svg style="height: 18px; width: 18px; fill:#FFA710;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z"/></svg>
                </div>
                <span style="line-height: 0.5cm;">
                  <strong style="font-size:16px; line-height: 0.5cm;" [innerHTML]="(result?.taptap_details?.tiers_exempt_from_deductible) ? result?.taptap_details?.tiers_exempt_from_deductible : 'N/A'"></strong>
                </span>
              </div>
            </div>

            <div class="d-flex flex-column benefits-costs-wrapper-right pricing-content">
              <span class="fColor-blue uppercase fWeight-600 d-block text-center" style="font-size:20px;">Premium</span>
              <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em">{{getConvertedRate(result.month_rate) | currency }}</span>
              <small class="fColor-base fWeight-600 d-block text-center mb-3">Per Month</small>
            </div>
          </div>
          <div class="plan-documents-button-wrapper">
            <button class="more-button btn btn-third btn-xs mr-2" (click)="clickPlanDocsResources($event)"><i class="fa fa-file-text-o mr-2" style="color:#818181 !important" aria-hidden="true"></i>More Benefits & Documents</button>
            <button id="#button2_{{result.key}}" class="more-button btn btn-third btn-xs position-relative mx-2" (click)="onclick_doctorDrugAnalysis(result.key)">
              <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
              Specific Drugs & Pharmacies
              <div class="d-none faint-line position-absolute" id="line2_{{result.key}}"></div> <!-- [class.withDrugCost]="isViewDrugCost"-->
            </button> 
          </div>
        </td>

        <!-- <td class="d-none result-action-wrapper">
          <div class="action-content result-button-wrapper d-flex flex-column">
            <button class="btn btn-first btn-small d-flex mb-2"
              (click)="onClick_ApplyOnline(result, i)">
              Apply Online
            </button>
            <button class="btn btn-first btn-small d-flex"
              (click)="onClick_ApplyOnThePhone(result)">
              Apply on Phone
            </button>
            <button class="btn btn-first mt-2 saveThisPlanButton">Save This Plan</button>
          </div>
          <div class="result-action-compare-add">
            <div class="compared-plans-btn-wrapper custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" class="custom-control-input" id="addToCompare-{{i}}" (change)="addToCompareFunc(result,$event,x)" [checked]="logComparedKeys.includes(result.key)">
                <label class="custom-control-label" for="addToCompare-{{i}}">Add to Compare</label>
                <a target="_blank" href="/insurance-marketplace/medicare-drug-coverage/results/compare-plans?zipcode={{controls.zipcode.value}}&planyear={{planYear}}&plan={{controls.plan.value}}&compare={{part_d_id}}" class="viewComparePlansBtn btn btn-third-plain btn-small d-none" [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a>
            </div>
          </div>
        </td> -->
      </tr>
    </table>
    <div class="plan-documents d-none">
      <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="plan documents icon">
      <div class="plan-documents-table">
        <div class="plan-documents-table-heading"><h4>Plan Documents & Resources</h4></div>
        <div class="plan-documents-table-row">
          <button class="btn btn-third-plain btn-small" (click)="showDrugModal(mailOrderModal, result, 900)">
            Formulary
          </button>

          <mat-divider [vertical]="true"></mat-divider>

          <button class="btn btn-third-plain btn-small" (click)="onClickPharmacyNetwork(result)">
            Pharmacy Network
          </button>

          <mat-divider [vertical]="true"></mat-divider>

          <button class="btn btn-third-plain btn-small" (click)="onClick_AdditionalInsights(result)">
            Additional Insights
          </button>

          <mat-divider [vertical]="true"></mat-divider>

          <a href="/insurance-marketplace/medicare-drug-coverage/plan-profile/{{result.key}}/{{this.controls.zipcode.value}}?zipcode={{controls.zipcode.value}}&planyear={{planYear}}&plan={{controls.plan.value}}" target="_blank" class="btn btn-third-plain btn-small">View All Benefits</a>

        </div>
      </div>
    </div>
    <div class="flex-column flex-md-row d-none doctors-drugs-actions" id="doctors_drugs_id_{{result.key}}">
      <div class="w-100 border m-3 p-4">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/common/icons/plan-display/drugs_pharmacies.svg" alt="">
        </div>
        <h4 class="title-header text-center">Drugs & Pharmacies</h4>
        <div class="w-full d-flex justify-content-around">
          <button class="link-button w-50 mx-md-2 my-2 btn btn-small btn-second">View This Plan's Drug Copays Per Tier</button>
          <button class="link-button w-50 mx-md-2 my-2 btn btn-small btn-second">Add My Drugs To See My Specific Copays</button>
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block action-buttons-wrapper border mb-4">
      <div class="action-buttons">
        <div class="action-content result-button-wrapper d-flex flex-column">
          <button class="apply-button btn btn-first btn-xs mb-2"
            (click)="onClick_ApplyOnline(result, i)">
            Enroll Online
          </button>
          <button class="apply-button btn btn-first btn-xs mb-2"
            (click)="onClick_ApplyOnThePhone(result)">
            Enroll on the Phone
          </button>
          <button class="btn btn-first btn-xs saveThisPlanButton mb-2">Save This Plan</button>
        </div>
        <div class="result-action-compare-add">
            <div class="compared-plans-btn-wrapper custom-control custom-checkbox rounded-pill p-2 d-flex justify-content-center align-items-center">
              <div class="d-flex justify-content-center align-items-center">
                <input type="checkbox" class="custom-control-input" id="addToCompare-{{i}}" (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.key)">
                <label class="custom-control-label" for="addToCompare-{{i}}">Add To Compare</label>
              </div>
            </div>         
            <a id="viewComparePlansBtn_{{i}}" target="_blank" href="/insurance-marketplace/medicare-drug-coverage/results/compare-plans?zipcode={{controls.zipcode.value}}&planyear={{planYear}}&plan={{controls.plan.value}}&compare={{part_d_id}}" class="viewComparePlansBtn btn btn-third-plain btn-xs d-none mt-2" [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a>
          
        </div>
      </div>
    </div>
    
  </div>
</div>