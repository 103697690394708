<!-- <h4 class="uppercase text-center">More Insights</h4> -->
<!-- <div class="row">
  <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
    <div class="img-wrapper">
      <img src="./assets/img/common/icons/Asset 9xxxhdpi1.png" alt="">
    </div>
    <h6 class="uppercase fColor-blue mt-2">Enrollment Fee</h6>
    <div *ngFor="let fee of fees; let i = index">
      <p class="result"><span class="font-secondary color-primary"
                              style="font-size: 2em;font-weight:bold;">{{ fee.value | currency }}</span></p>
    </div>
    <div *ngIf="fees.length === 0">
      <span class="fColor-base fWeight-500 d-block">Information coming soon</span>
    </div>
  </div>
  <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
    <div class="img-wrapper">
      <img src="./assets/img/common/icons/Asset 8xxxhdpi1.png" alt="">
    </div>
    <h6 class="uppercase fColor-blue mt-2">Household Discount</h6>
    <div *ngFor="let discount of discounts; let i = index">
      <p class="result"><span class="font-secondary color-primary"
                              style="font-size: 2em;font-weight:bold;">{{ discount.value | percent }}</span></p>
    </div>
    <div *ngIf="discounts.length === 0">
      <span class="fColor-base fWeight-500 d-block">N/A</span>
    </div>
  </div>
  <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
    <div class="img-wrapper">
      <img src="./assets/img/common/icons/Asset 7xxxhdpi1.png" alt="">
    </div>
    <h6 class="uppercase fColor-blue mt-2">Price History</h6>
    <div *ngFor="let rate of rateHistory; let i = index">
      <p class="result">
        <span class="font-secondary color-primary" style="font-size: 1em;font-weight:bold;">{{ rate.date | date }} : {{ rate.rate_increase | percent }}
        </span>
      </p>
    </div>
    <div *ngIf="rateHistory.length === 0">
      <span class="fColor-base fWeight-500 d-block">N/A</span>
    </div>
  </div>
  <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
    <div class="img-wrapper">
      <img src="./assets/img/common/icons/Asset 6xxxhdpi1.png" alt="">
    </div>
    <h6 class="uppercase fColor-blue mt-2">Wellness Program</h6>
    <span class="fColor-base fWeight-500">{{ silverSneakers }}</span>
  </div>
</div> -->

  <h4 class="uppercase text-center">More Insights</h4>
  <div class="additional-documents-modal">
    <div>
        <img src="./assets/img/common/icons/Asset 9xxxhdpi1.png" alt="Enrollment Fee">
        <p>Enrollment Fee</p>
        <div *ngFor="let fee of fees; let i = index">
          <p class="result"><span class="font-secondary mi-span-value">{{ fee.value | currency }}</span></p>
        </div>
        <div *ngIf="fees.length === 0">
          <span class="font-secondary mi-span-value">Data Not Available</span>
        </div>
    </div>
    <div>
        <img src="./assets/img/common/icons/Asset 8xxxhdpi1.png" alt="Household Discount">
        <p>Household Discount</p>
        <div *ngFor="let discount of discounts; let i = index">
          <p class="result"><span class="font-secondary mi-span-value">{{ discount.value | percent }}</span></p>
        </div>
        <div *ngIf="discounts.length === 0">
          <span class="font-secondary mi-span-value">Data Not Available</span>
        </div>
    </div>
    <div>
        <img src="./assets/img/common/icons/Asset 7xxxhdpi1.png" alt="Price History">
        <p>Price History</p>
        <div *ngFor="let rate of rateHistory; let i = index">
          <p class="result">
            <span class="font-secondary mi-span-value">{{ rate.date | date }} : {{ rate.rate_increase | percent }}
            </span>
          </p>
        </div>
        <div *ngIf="rateHistory.length === 0">
          <span class="font-secondary mi-span-value">Data Not Available</span>
        </div>
    </div>
    <div>
      <img src="./assets/img/common/icons/Asset 6xxxhdpi1.png" alt="Wellness Program">
      <p>Wellness Program</p>
      <span class="font-secondary mi-span-value">{{ silverSneakers }}</span>
    </div>
  </div>
