import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MetadataService } from '../services/metadata.service';
import {Title} from "@angular/platform-browser";

declare var window: any;

@Component({
  selector: 'app-become-a-contributor',
  templateUrl: './become-a-contributor.component.html',
  styleUrls: ['./become-a-contributor.component.scss'],
  providers: [MetadataService]
})
export class BecomeAContributorComponent implements OnInit {
  private currentUrl = window.location.href;
  private baseUrl = window.location.origin;
  fullPrimaryHeader: boolean = true;
  isLoggedInUser: any;

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!');
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }

  constructor(
    public _auth: AuthService,
    private metadataService: MetadataService,
    private titleService:Title
  ) {
    this.titleService.setTitle("Become A Contributor - TapTap Medicare");
  }

  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if (this.isLoggedInUser) {
      this.fullPrimaryHeader = false;
    }

    this.injectSEO();
  }

  taptapRecaptcha = new FormControl(false);
  show_captcha_message: boolean = false;

  contributorForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    how_did_you_hear_about_us: new FormControl('', [Validators.required]),
    yourMessage: new FormControl('', [Validators.required]),
    terms_and_condition: new FormControl('', [Validators.requiredTrue])
    // tataptapRecaptcha: new FormControl('', [
    //     Validators.requiredTrue,
    // ]),
  });

  register() {}

  injectSEO(): void {
    this.metadataService.getMetaData('/become-a-contributor', (a) => {
      const { data } = a;
      const metatitle = document.getElementById('meta-title');
      const metadesc = document.getElementById('meta-desc');
      const keywords = document.getElementById('meta-keywords');
      const fbMetaTitle = document.getElementById('fb-meta-title');
      const fbMetaDescription = document.getElementById('fb-meta-description');
      const fbMetaUrl = document.getElementById('fb-meta-url');
      const fbMetaImage = document.getElementById('fb-meta-image');

      metatitle.setAttribute('content', data['meta-title']);
      metadesc.setAttribute('content', data['meta-desc']);
      keywords.setAttribute('content', data['meta-keywords']);
      fbMetaTitle.setAttribute('content', data['meta-title']);
      fbMetaDescription.setAttribute('content', data['meta-desc']);
      fbMetaUrl.setAttribute('content', this.currentUrl);
      fbMetaImage.setAttribute(
        'content',
        `${this.baseUrl}/assets/company-logo.jpg`
      );
      window.prerenderReady = true;
    });
  }
}
