<section class="contact-top">
	<div class="container px-3">
		<h4 class="form-input-heading text-capitalize" [class.mb-0]="!isMedicareServices">
			{{ isMedicareServices ? infoMessage : "We Will Be Happy to Help You "+infoMessage }}
		</h4>
		<h5 class="form-input-heading text-capitalize color-primary" *ngIf="!isMedicareServices">
			Just Submit Your Info Below and Our Team Will Start Working To Assist You Right Away!
		</h5>
		<div class="container">
			<div class="d-flex justify-content-center">
				<div class="w-100 mx-md-4">
					<form class="contact-form">
						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="firstName" class="text-capitalize">First name</label>
								<input type="text" class="form-control" id="firstName" [formControl]="controls.first_name">
							</div>
							<div class="form-group col-md-6">
								<label for="lastName" class="text-capitalize">Last name</label>
								<input type="text" class="form-control" id="lastName" [formControl]="controls.last_name">
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="email">Email</label>
								<input type="email" class="form-control" id="email" [formControl]="controls.email">
							</div>
							<div class="form-group col-md-6">
								<label for="phoneNumber">Phone</label>
								<input type="text" class="form-control" id="phoneNumber" [formControl]="controls.phone">
							</div>
						</div>
						<div class="form-group">
							<label for="exampleFormControlTextarea1">Message</label>
							<textarea class="form-control" id="exampleFormControlTextarea1" rows="5"
								[formControl]="controls.message"></textarea>
						</div>
						<div class="row justify-content-around justify-content-md-between align-items-center">
							<!-- new recaptcha template - stephen 11-13-2019 -->
							<recaptcha [formControl]="taptapRecaptcha" (scriptLoad)="onScriptLoad()"
							(scriptError)="onScriptError()"
							style="transform: scale(0.9);-moz-transform: scale(0.9);-webkit-transform: scale(0.9);"
							class="mt-2"></recaptcha>
							<!-- end new recaptcha template -->
							<button class="btn btn-first mt-2" (click)="onInquire()">Send Message Now <i class="fas fa-paper-plane ml-3"></i></button>
						</div>
					</form>	
					<div class="contact-info mt-5">
						<div class="row">
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Phone Numbers:</h6>
								<p class="mb-1">Toll Free Phone: <br><span class="fWeight-500">1 (800) 215-5108</span></p>
								<p class="mb-1">Member Line: <br><span class="fWeight-500">1 (844) 200-8433</span></p>
							</div>
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Email Address:</h6>
								<a href="mailto:help@TapTapMedicare.com" class="text-secondary font-weight-normal text-decoration-none">help@TapTapMedicare.com</a>
							</div>
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Office Address:</h6>
								<p class="mb-1">TapTap Medicare</p>
								<p class="mb-1">230 S. Broad St. 17th Floor</p>
								<p class="mb-1">Philadelphia, PA 19102</p>
							</div>
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Social Media:</h6>
								<div class="social-media-links d-flex align-items-center">
									<a class="social-button mx-1" href="https://facebook.com/taptapmedicareofficial" target="_blank"><i class="fa fa-facebook"></i></a>
									<a class="social-button mx-1" href="https://twitter.com/TapTapMedicare" target="_blank"><i class="fa fa-twitter"></i></a>
									<a class="social-button mx-1" href="https://www.pinterest.com/a245809541e5870293dd2e0684cf0d/overview/" target="_blank"><i class="fa fa-pinterest"></i></a>
									<a class="social-button mx-1" href="#"><i class="fa fa-play"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block contact-asset align-self-start" src="./assets/img/common/contact-us-woman-pink-dress.png" alt="Contact us image">
			</div>
		</div>	
	</div>
</section>