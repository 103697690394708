import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-attestation-of-eligibility-for-an-enrollment-period',
  templateUrl: './attestation-of-eligibility-for-an-enrollment-period.component.html',
  styleUrls: ['./attestation-of-eligibility-for-an-enrollment-period.component.scss']
})
export class AttestationOfEligibilityForAnEnrollmentPeriodComponent implements OnInit {
  viewMore1:boolean = true;
  viewMore2:boolean = false;
  viewMore3:boolean = false;

  @Input() details: any;

  constructor() { }

  ngOnInit() {
  }

  onShow_View1(){
    this.viewMore1 = true;
    this.viewMore2 = false;
    this.viewMore3 = false;
  }

  onShow_View2(){
    this.viewMore1 = false;
    this.viewMore2 = true;
    this.viewMore3 = false;
  }

  onShow_View3(){
    this.viewMore1 = false;
    this.viewMore2 = false;
    this.viewMore3 = true;
  }

}
