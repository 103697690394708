<div class="container-fluid header-banner-wrapper access-forms force-height-auto">
        <button *ngIf="dialogData" class="close close-icon" mat-button (click)="closeModal()">
            <mat-icon class="color_white">close</mat-icon>
        </button>
        <!-- <app-header-b></app-header-b> -->
        <div class="container px-0">
        <div class="row flex-column-reverse flex-md-row">
            <div class="col-12 col-md-6 p-0 form-character-wrapper">
            <div class="login-left-text">            
                <h5 class="mb-4">The Power of Your TapTap Beneficiary Dashboard:</h5>            
                <ul>
                    <li>Being a Medicare Beneficiary is Hard...</li>
                    <li>And There Are a Lot of Moving Pieces</li>
                    <li>I Need One Central Place to Organize Everything, and See Everything</li>
                    <li>A Place That Will Help Me Understand My Situation At All Times</li>
                    <li>And Guide Me Through My Options & Choices</li>
                    <li>Proactive, Clear, and Organized.</li>
                </ul>
                <img src="./assets/img/common/woman-pink-dress-cut-with-gradient.svg" alt="Sign Up Character"> 
                <h5 class="mt-5 mb-3">Key Sections Of Your Dashboard</h5>
                <ul>
                    <li>My Healthcare</li>
                    <li>My Financials</li>
                    <li>My Coverages</li>
                    <li>My Documents</li>
                </ul>            
            </div>   
            </div>
            <div class="col-12 col-md-6 box-wrapper">
                <div class="primary-box">
                    <div class="box-top p-4 p-md-5">
                        <h4 class="text-center fWeight-600 mb-4">Login To Your TapTap Account</h4>
                        <p *ngIf="!isValidCredentials && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
                            Invalid Credentials, Please try again!
                        </p>
                        <p *ngIf="email.invalid && email.errors['required'] && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
                            Email is required
                        </p>
                        <p *ngIf="password.invalid && password.errors['required'] && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
                            Password is required
                        </p>
                        <p *ngIf="taptapRecaptcha.invalid && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
                            Please verify you're are a human
                        </p>
                        <p *ngIf="isWrongLogin && isSignInUsingExternalParty && existingUserMessage === null" class="alert alert-danger">
                            Failed to login using {{(externalParty[0].toUpperCase() + externalParty.substring(1))}}
                        </p>
                        <p *ngIf="isWrongLogin && isSignInUsingExternalParty && existingUserMessage !== null" class="alert alert-danger">
                            {{existingUserMessage}}
                        </p>
                        <form [formGroup]="loginForm">
                            <div class="row mb-3">
                                <div class="col px-1">
                                    <label class="form-label" for="email">Email or Phone<sup>*</sup></label>
                                    <input type="email" class="form-field" name="email" id="email" formControlName="email" required />
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col px-1">
                                    <label class="form-label" for="password">Password<sup>*</sup></label>
                                    <input type="password" class="form-field" name="password" id="password" formControlName="password" required />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col px-1 d-flex align-items-center">
                                    <input class="mr-1" type="checkbox" name="" id="">
                                    <label class="mb-0">Remember Me</label>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-lg-row mb-3 align-items-center justify-content-between">
                                <button type="submit" class="btn btn-first btn-small btn-login-now" (click)="login()">
                                    Login
                                </button>
                            </div>
                        </form>
                        <div class="row justify-content-center" *ngIf="!dialogData">
                            <p class="text-center mb-0">Forgot your login credentials? <a routerLink="/forgot-password" class="color-primary fWeight-600">Click Here</a></p>
                        </div>
                        <div class="row my-3">
                            <div class="col">
                            <div class="or-separator">
                                <hr>
                                <span class="px-2">OR LOGIN WITH</span>
                            </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-center mb-3">
                            <button (click)="loginUsingExternalParty('facebook')" class="social-login-button facebook-button d-flex align-items-center px-3 py-2 mb-2">
                                <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                                <span>Login with Facebook</span>
                            </button>
                            <button (click)="loginUsingExternalParty('twitter')" class="social-login-button twitter-button d-flex align-items-center px-3 py-2 mb-2">
                                <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
                                <span>Login with Twitter</span>
                            </button>
                            <button (click)="loginUsingExternalParty('google')" class="social-login-button google-button d-flex align-items-center px-3 py-2">
                                <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
                                <span>Login with Google</span>
                            </button>
                        </div>
                        <div class="row">
                            <div class="col px-1">
                                <button class="setup-button py-2" *ngIf="!dialogData" routerLink="/registration">Don't Have An Account Yet? Set Up One Here</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>