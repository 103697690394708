import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Router} from '@angular/router';
import {TermService} from '../../life-insurance/term/term.service';
import { AuthService } from '../../services/auth.service';
import {Location} from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-second-variation',
  templateUrl: './second-variation.component.html',
  styleUrls: ['./second-variation.component.scss']
})
export class SecondVariationComponent implements OnInit {
  dblocking: string = 'd-none';
  fullPrimaryHeader:boolean = true;
  isLoggedInUser:any;
  proceedButtonClicked:boolean = false;
  selectedAmount: string;
  selectedOtherAmount: string = 'Other';
  isProceedClicked:boolean = false;

  controls: any = {
      death_benefit: new FormControl('', [
          Validators.required
      ]),
  };

  constructor(private router: Router, private termService: TermService,private _auth: AuthService, private _location: Location  ) {
  }

  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if(this.isLoggedInUser){
      this.fullPrimaryHeader = false;
    }
  }

  proceed() {
      this.proceedButtonClicked = true;
      console.log(this.controls.death_benefit.value);
      //this.termService.setValue(this.controls.death_benefit.value);
      this.termService.fullUnderwriting.deathBenefit =  this.controls.death_benefit.value;

      if( this.controls.death_benefit.valid ){
          if (this.controls.death_benefit.value < 25) {
              this.router.navigate(['./life-insurance/term/limited-health-questions'], {queryParams : {'referrer' : 'death-benefit-needed', 'amount': this.controls.death_benefit.value}});
          } else {
              this.router.navigate(['./life-insurance/term/full-underwriting'], {queryParams : { 'referrer' : 'death-benefit-needed', 'amount': this.controls.death_benefit.value}});
          }
          this.proceedButtonClicked = false;
      }
  }

  goTo_ViewInputQuote():void{
    this.isProceedClicked = true;
    if(this.selectedAmount){
      this.router.navigate(['./life-insurance/the-amount-of-coverage'], {queryParams : {'amount': this.selectedAmount}});
    }    
  }

  backClicked() {
      this._location.back();
  }

  // adjust select element dropdown
  focusSelectElement(event){
      console.log(event.target);
      event.target.size = 10;
      $(event.target).css('z-index','99999');
  }

  blurSelectElement(event){
      event.target.size = 1;
  }

  changeSelectElement(event){
      event.target.size = 1;
      $(event.target).blur();
      $(event.target).css('z-index','1');
  }
  // end adjust select element dropdown

  speakToCounselorEvent(modalViews: any) {
    this.dblocking = 'd-block';

    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 'auto',
      padding: '0',
      onClose: () => {
        this.dblocking = 'd-none';
      }
    });
  }

  onSelect_Amount(event):void{
    let amount = event;
    this.selectedAmount = amount;
    this.selectedOtherAmount = 'Other';
  }

  onSelectOtherAmount(event):void{
    let amount = event.value;
    this.selectedOtherAmount = '$' + amount;
    this.selectedAmount = amount;
  }

}
