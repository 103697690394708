import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ConsumerReviewService } from 'src/app/services/consumer-review.service';

declare var grecaptcha: any;

@Component({
  selector: 'app-submit-a-review',
  templateUrl: './submit-a-review.component.html',
  styleUrls: ['./submit-a-review.component.scss']
})
export class SubmitAReviewComponent implements OnInit {

  @Input() id: any;
  @Input() pdsCode: any;
  @Input() type: any;

  insurance_total_score:number = 4;
  customer_service_score:number = 0;
  consumer_clarity_score:number = 0;
  price_stability_score:number = 0;
  ease_of_use_score:number = 0;
  consumer_rating_score:number = 0;

  overall_score:number = 0;
  experience_score:number = 0;
  insuranceType:string;
  review_message:any;
  reviewer_fullname:any;
  reviewer_email: string;
  reviewer_phone: string = '';

  submitReviewButtonClicked:boolean = false;
  message_invalid:boolean = false;
  showDefaultView:boolean = true;
  showMessageView: boolean = false;
  showVerifyReview: boolean = false;
  showExperienceRating:boolean = false;
  showThankYou: boolean = false;
  showLoginSection:boolean = false;

  isLoggedIn:boolean = false;
  currentUser: any;
  baseUrl = window.location.origin;
  toLoginBp: boolean = false;
  toLoginBpMode: number;
  captchaRendered2: boolean;
  widget2: any;
  fileAttr: any;
  
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    captcha: new FormControl('', [Validators.requiredTrue])
  });

  messageFormControl = new FormControl('', [
    Validators.required
  ]);

  verifyFullNameFormControl= new FormControl('', [
    Validators.required
  ])

  verifyEmailFormControl= new FormControl('', [
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
  ])

  star_rating_valid: boolean = true;
  submitReviewFirstBtnClicked: boolean = false;
  submitReviewSecondBtnClicked: boolean = false;

  reviewDetails: any = {
    id: '',
    pdsCode: '',
    customer_service: 0,
    consumer_clarity: 0,
    price_stability: 0,
    ease_of_use:0,
    consumer_rating:0,
    overall_score: 0,
    taptap_exp_score: 0,
    review: '',
    email: '',
    phone: '',
    picture: null,
    reviewer_name: '',
    type: 0,
    carrierBrandId: null,
    planId: null,
    naic: null,
    stateId: null,
    planYear: null
  }

  constructor(private _authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private _consumerReviewService: ConsumerReviewService
    ) { }

  ngOnInit() {
    this.checkIfAuthenticated();

    if(this.type === '1') {
      this.insuranceType = 'Medigap';
    } else if (this.type === '2') {
      this.insuranceType = 'Dental';
    }
  }

  starClick(type, ratingNumber){
    if(type == 'service') {
      this.customer_service_score = ratingNumber;
    }

    if(type == 'clarity') {
      this.consumer_clarity_score = ratingNumber;
    }

    if(type == 'stability') {
      this.price_stability_score = ratingNumber;
    }

    if(type == 'ease_of_use') {
      this.ease_of_use_score = ratingNumber;
    }

    if(type == 'consumer_rating') {
      this.consumer_rating_score = ratingNumber;
    }

    if(type == 'overall') {
      this.overall_score = ratingNumber;
    }

    if(type == 'experience') {
      this.experience_score = ratingNumber;
    }
    
    this.star_rating_valid = true;
  }

  next(){
    if( this.customer_service_score > 0 ||
        this.consumer_clarity_score > 0 ||
        this.price_stability_score > 0 ||
        this.overall_score > 0 ) {
         this.showMessageView = true;
         this.star_rating_valid = true;
         this.showMessageView = true;
         this.showDefaultView = false;
    } else {
         this.star_rating_valid = false;
    }
  }

  back_second(){
    this.showMessageView = false;
    this.showDefaultView = true;
  }
  
  submit_review_first(){
    if(this.isLoggedIn){
      this.showThankYou = true;
    }else{
      this.showVerifyReview = true;
      this.showLoginSection = true
    }
  }

  submit_review_second(){
    this.showThankYou = true;    
    this.showVerifyReview = false;
  }

  async pleaseEmail(): Promise<any> {
    await this.saveReview();
  }
  
  yesLetsDoIt(){
    this.showExperienceRating = true;
  }

  resetter(){
    this.showDefaultView = true;
    this.showMessageView = false;
    this.showVerifyReview = false;
    this.showLoginSection = false;
    this.showThankYou = false;
    this.showExperienceRating = false;

    this.customer_service_score = 0;
    this.consumer_clarity_score = 0;
    this.price_stability_score = 0;
    this.ease_of_use_score = 0;
    this.consumer_rating_score = 0;
    this.overall_score = 0;
    this.experience_score = 0;
    this.review_message = '';

    this.submitReviewButtonClicked = false;
    this.message_invalid = false;

    this.messageFormControl.reset();
    this.verifyFullNameFormControl.reset();
    this.verifyEmailFormControl.reset();
  }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
      });

    } else {
      this.fileAttr = 'Choose File';
    }
  }
  
  async checkIfAuthenticated(): Promise<boolean> {
    if(this._authService.isAuthenticated()) {
      this.isLoggedIn = true;
      this.toLoginBp = false;

      const result = await this._authService.getUserDetails();
      if(result) {
        this.reviewer_fullname = `${result.first_name} ${result.last_name}`;
        this.reviewer_email = result.email;
        this.reviewer_phone = result.customer_info ? result.customer_info.phone_primary : '';
      }
    } else {
      this.reviewer_fullname = this.getSavedCookie('name');
      this.reviewer_email = this.getSavedCookie('email');
      this.reviewer_phone = this.getSavedCookie('phone');
    }
    
    if(this.reviewer_fullname && this.reviewer_email) {
      this.isLoggedIn = true;
      this.showLoginSection = false;
    } else {
      this.isLoggedIn = false;
    }

    return this.isLoggedIn
  }

  async submitReview(): Promise<any> {
    if( this.review_message ){
      this.submitReviewButtonClicked = true;
      if (!this.isLoggedIn) {
        this.showLoginSection = true;
        this.showVerifyReview = true;
      } else {
        this.showThankYou = true;
        this.showVerifyReview = false;
      }
    }else{
      this.message_invalid = true;
    }
  }

  getSavedCookie(type:string):string {
    let result = '';
    let cookies = decodeURIComponent(document.cookie);
    if (cookies) {
      let cookie = cookies.split(';');
      for (var a in cookie) {
        if (type == 'name' && cookie[a].split('=')[0].trim() == 'bp_reviewer_name' || cookie[a].split('=')[0].trim() == 'bp_user_name') {
          result = cookie[a].split('=')[1];
        }
          
        if (type == 'email' && cookie[a].split('=')[0].trim() == 'bp_reviewer_email' || cookie[a].split('=')[0].trim() == 'bp_user_email') {
          result = cookie[a].split('=')[1];
        }

        if (type == 'phone' && cookie[a].split('=')[0].trim() == 'bp_reviewer_phone') {
          result = cookie[a].split('=')[1];
        }
      }
    }

    return result;
  }
  
  async loginUsingExternalParty(externalParty: string, callback = null): Promise<any> {
    try {
      const result = await this._authService.signInUsingExternalParty(externalParty);
      if(result){
          if(result.exists) {
              alert(result.message);
          } else {
              const { email, password } = result;
              await this.signUserInOnServer(email, password, externalParty, result, callback);
          }
      }
    } catch(error) {
      if(error.code === 'auth/account-exists-with-different-credential') {
          const userData = await this._authService.checkUserExists(error.email);
          if(userData && userData.length > 0) {
              if(userData[0].external_party !== null) {
                  alert(`You are already registered to our system using ${ userData[0].external_party }, with the email of ${ error.email }. Kindly login using that account.`);
              }
          } else {
              alert(error.message);
          }
      }
    }
  }

  async signUserInOnServer(email: string, password: string, externalParty: string, result: any, callback = null) : Promise<any> {
    this._authService.signUserInOnServer({
      'email': email,
      'password': password,
    }).subscribe(
      (details: any) => {
        if (details.code === 1) {
          this._authService.setActiveUserUsingJSON(details.details, externalParty);
          this.currentUser = {
            id: externalParty == 'BP' ? details.details.user.id : result.userData.user.uid,
            name: externalParty == 'BP' ? `${details.details.user.first_name} ${details.details.user.last_name}` : result.userData.user.displayName,
            picture: externalParty == 'BP' ? '' : result.userData.user.photoURL,
            email: externalParty == 'BP' ? details.details.user.email : result.userData.user.email,
            accountType: externalParty === 'facebook' ? 'FB' : externalParty,
            address: ''
          };

          this.reviewer_fullname = this.currentUser.name;
          this.reviewer_email = this.currentUser.email;

          this.isLoggedIn = true;
          this.showLoginSection = false;

          this.saveToCookies(true);
          this.changeDetectorRef.detectChanges();
          
          if(externalParty == 'BP') {
            this.toLoginBp = false;
          }

          if (callback) callback();
        }
      },

      (response) => {
        if (response.status == 401) {
          alert('Incorrect username or password');
        }
      }
    );
  }

  showLoginBpModal(mode) {
    this.toLoginBp = true;
    this.toLoginBpMode = mode;
    this.renderReCaptch();
  }

  renderReCaptch() {
    if (!this.captchaRendered2) {
      this.widget2 = grecaptcha.render(
        document.getElementById('captcha-div2'),
        {
          sitekey: '6LfYbNYUAAAAADJgUKeiBbQRZf03bRx-rjQLA1FA',
          callback: (response) => {
            this.loginForm.controls['captcha'].setValue('true');
          }
        }
      );
      this.captchaRendered2 = true;
    } else {
      grecaptcha.reset(this.widget2);
    }
  }

  async loginToBp() {
    if (this.loginForm.valid) {
      await this.signUserInOnServer(this.loginForm.controls.email.value, this.loginForm.controls.password.value, 'BP', null);
    }
  }

  saveToCookies(isLoggedIn: boolean): void {
    let d = new Date();
    d.setDate(d.getDate() + 1);
    let expires = d.toUTCString();
    if(isLoggedIn) {
      document.cookie = `bp_user_id=${this.currentUser.id};expires=${expires};path=${this.baseUrl};`;
      document.cookie = `bp_user_name=${this.currentUser.name};expires=${expires};path=${this.baseUrl};`;
      document.cookie = `bp_user_picture=${this.currentUser.picture};expires=${expires};path=${this.baseUrl};`;
      document.cookie = `bp_user_email=${this.currentUser.email};expires=${expires};path=${this.baseUrl};`;
      document.cookie = `bp_user_accounttype=${this.currentUser.accountType};expires=${expires};path=${this.baseUrl};`;
    } else {
      document.cookie ='bp_reviewer_name=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
      document.cookie = `bp_reviewer_name=${this.reviewer_fullname};expires=${expires};path=${this.baseUrl};`;
      
      document.cookie ='bp_reviewer_email=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
      document.cookie = `bp_reviewer_email=${this.reviewer_email};expires=${expires};path=${this.baseUrl};`;
      
      document.cookie ='bp_reviewer_phone=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
      document.cookie = `bp_reviewer_phone=${this.reviewer_phone};expires=${expires};path=${this.baseUrl};`;
      
      this.isLoggedIn = true;
      this.showLoginSection = false;
    }

  }

  cancelReview(){
    this.resetter();
  }

  async submitComment(): Promise<any> {
    if(this.reviewer_fullname && this.reviewer_email && !this.isLoggedIn){
      this.saveToCookies(false);
      this.showThankYou = true;
      this.submitReviewButtonClicked = true;
    }
  }

  async saveReview(lastStep: boolean = false): Promise<any> {
    if(lastStep && this.experience_score < 1) {
      this.star_rating_valid = false;
    }
    else if(this.review_message && this.review_message !== '' && this.submitReviewButtonClicked)
    {
      this.reviewDetails.type = this.type;
      this.reviewDetails.pdsCode = this.pdsCode;
      this.reviewDetails.customer_service = this.customer_service_score;
      this.reviewDetails.consumer_clarity = this.consumer_clarity_score;
      this.reviewDetails.price_stability = this.price_stability_score;
      this.reviewDetails.ease_of_use = this.ease_of_use_score;
      this.reviewDetails.consumer_rating = this.consumer_rating_score;
      this.reviewDetails.overall_score = this.overall_score;
      this.reviewDetails.taptap_exp_score = this.experience_score;
      this.reviewDetails.review = this.review_message;
      this.reviewDetails.email = this.reviewer_email;
      this.reviewDetails.phone = this.reviewer_phone;
      this.reviewDetails.reviewer_name = this.reviewer_fullname;
      this.reviewDetails.id = this.id;

      try {
        const result = await this._consumerReviewService.saveCustomerReview(this.reviewDetails);
        if(result) {
          this.showThankYou = true;
          this.showVerifyReview = false;
          
          if(this.experience_score > 3) {
            window.open('https://taptap.st/review-us', '_blank');
          }
        }
      } catch(e) {
      } finally {
        Swal.close();
        this.resetter();
      }
    }
    else {
      Swal.close();
      this.resetter();
    }
  }

  closeModal(toSave = false) {
    if (toSave) {
      this.saveReview();
    } else {
      this.resetter();
      Swal.close();
    }
  }
  
}
