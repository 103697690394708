<div class="text-left">
  <div class="mb-3">
    <h5 class="fw-semibold text-dblue mb-0">What Is Your Relationship To The Policy Insured?</h5>
    <div class="yellow-line"></div>
    <div class="row mx-4">
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Spouse" name="relationship" class="custom-control-input" value="spouse">
          <label class="custom-control-label" for="relationship_Spouse">Spouse</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Sibling" name="relationship" class="custom-control-input" value="sibling">
          <label class="custom-control-label" for="relationship_Sibling">Sibling</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Parent" name="relationship" class="custom-control-input" value="parent">
          <label class="custom-control-label" for="relationship_Parent">Parent</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Child" name="relationship" class="custom-control-input" value="child">
          <label class="custom-control-label" for="relationship_Child">Child</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Adviser" name="relationship" class="custom-control-input" value="adviser">
          <label class="custom-control-label" for="relationship_Adviser">Adviser</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Caregiver" name="relationship" class="custom-control-input" value="caregiver">
          <label class="custom-control-label" for="relationship_Caregiver">Caregiver</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Friend" name="relationship" class="custom-control-input" value="friend">
          <label class="custom-control-label" for="relationship_Friend">Friend</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="relationship_Trustee" name="relationship" class="custom-control-input" value="trustee">
          <label class="custom-control-label" for="relationship_Trustee">Trustee</label>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <h5 class="fw-semibold text-dblue mb-0">Information About You</h5>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeFirstName" class="text-label">Authorized Representative First Name</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeFirstName"/>
      </div>
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeLastName" class="text-label">Authorized Representative Last Name</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeLastName"/>
      </div>
    </div>
  </div>
</div>