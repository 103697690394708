<div class="retiree-navigation-wrapper">
  <div class="retiree-navigation-wrapper-box">
    <div class="account-profile-wrapper">
      <div class="profile-img">
        <img alt="profile-image" src="/assets/img/common/person-icon.png">
      </div>
      <div class="profile-text">
        <span class="profile-text-name">John Doe</span>
        <span class="profile-text-account">Retiree Account</span>
      </div>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-general-info" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-general-info.png" alt="">
          <span>My General Info</span>
        </a>
      </li>
      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)"
        routerLinkActive="active">
        <a class="nav-link" routerLink="/retiree-dashboard/my-profiles/" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-profiles.png" alt="">
          <span>My Profiles</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)"
            (click)="scrollToView('my-medicare-beneficiary-profiles-id')">
            <a class="nav-link" routerLinkActive="active">
              <span>My Medicare Beneficiary Profiles</span>
            </a>
          </li>
          <li class="nav-item" (click)="scrollToView('my-plan-availability-id')">
            <a class="nav-link" routerLinkActive="active">
              <span>My Plan Availability</span>
            </a>
          </li>
          <li class="nav-item"><a class="nav-link" routerLinkActive="active"><span>My Enrollment Periods</span></a></li>
        </ul>
      </li>

      <li class="nav-item" 
          (mouseover)="navHoverOver($event)" 
          (mouseout)="navHoverOut($event)"
          (click)="scrollToView('my-healthcare-organizer-id')">
      <!--<li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">-->
        <a class="nav-link" routerLink="/retiree-dashboard/my-healthcare-organizer" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-healthcare-organizer.png" alt="">
          <span>My Healthcare Organizer</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item"><a class="nav-link" routerLinkActive="active"><span>My Providers</span></a></li>
          <li class="nav-item"><a class="nav-link" routerLinkActive="active"><span>My Drugs</span></a></li>
          <li class="nav-item"><a class="nav-link" routerLinkActive="active"><span>My Claims</span></a></li>
        </ul>
      </li>
      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-network-status" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-network-status.png" alt="">
          <span>My Network Status</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLink="/retiree-dashboard/my-network-status/providers-network-status"><span>My
                Providers Network Status</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLink="/retiree-dashboard/my-network-status/carriers-network-status"><span>My
                Carriers Network Status</span></a>
          </li>
        </ul>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-guides-and-education" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-guides-and-education.png" alt="">
          <span>My Guides & Education</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Core Guides</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Extra Guides</span></a></li>
        </ul>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-coverage-preferences" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-coverage-preferences.png" alt="">
          <span>My Coverage Preferences</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>General Coverage Type and
                Experience</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)"><a class="nav-link"
              routerLinkActive="active"><span>Medicare Advantage Plan
                Preferences</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>Medigap Preferences</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>Dental Insurance
                Preferences</span></a></li>
        </ul>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-marketplace-plans-and-prices" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-marketplace-plans-and-prices.png" alt="">
          <span>My Marketplace - Plans & Prices</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>Menu Page</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)" (click)="scrollToView('my-medicare-supplement-plans-id')">
            <a class="nav-link" routerLinkActive="active"><span>My Medicare Supplement Plans</span></a></li> 
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)" (click)="scrollToView('my-medicare-advantage-plans-id')">
            <a class="nav-link" routerLinkActive="active"><span>My Medicare Advantage Plans</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Dental Insurance Plans</span></a>
          </li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Life Insurance Marketplace</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Supporting Plans Marketplace</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Drug Costs Analysis</span></a>
          </li>
        </ul>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-financial-assistance-programs" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-financial-assistance-programs.png" alt="">
          <span>My Financial Assistance Programs</span>
        </a>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-wellness-and-fitness-opportunities"
          routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-wellness-and-fitness-opportunities.png" alt="">
          <span>My Wellness & Fitness Opportunities</span>
        </a>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-community" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-community.png" alt="">
          <span>My Community</span>
        </a>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-coverage-organizer" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-coverage-organizer.png" alt="">
          <span>My Coverage Organizer</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a routerLink="/retiree-dashboard/my-coverage-organizer/my-new-coverage-summary" class="nav-link"><span>My
                New Coverage Summary</span></a>
          </li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a routerLink="/retiree-dashboard/my-coverage-organizer/my-coverage-outline" class="nav-link"><span>My
                Coverage Outline</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a routerLink="/retiree-dashboard/my-coverage-organizer/my-total-coverage-explainer"
              class="nav-link"><span>My Coverage Explainer</span></a></li>
        </ul>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-recommendations" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-recommendations.png" alt="">
          <span>My Recommendations</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Coverage Type
                Recommendation</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Specific Plan
                Recommendation</span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Additional Coverages
                Recommendation </span></a></li>
          <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
            <a class="nav-link" routerLinkActive="active"><span>My Cost Savings
                Opportunities</span></a></li>
        </ul>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-documents" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-documents.png" alt="">
          <span>My Documents</span>
        </a>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-custom-reports" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-custom-reports.png" alt="">
          <span>My Custom Reports</span>
        </a>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/retiree-dashboard/my-household" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_my-household.png" alt="">
          <span>My Household</span>
        </a>
      </li>

      <li class="nav-item" (mouseover)="navHoverOver($event)" (mouseout)="navHoverOut($event)">
        <a class="nav-link" routerLink="/logout" routerLinkActive="active">
          <img src="/assets/img/retiree-dashboard/nav-icon/nav-icon_logout.png" alt="">
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </div>
</div>