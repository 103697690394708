<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper">
    <div class="row">
      <div class="col-12">
        <h1 class="banner-main-title text-center">
          Medicare Advantage Compared Plans
        </h1>
      </div>
    </div>
  </div>
</div>

<!--END BANNER-->

<div class="main-content-wrapper">

  <!-- white banner pointer -->
  <div class="banner-pointer-wrapper">
    <i class="fa fa-arrow-down"></i>
    <div class="banner-pointer-background"></div>
  </div>
  <!-- end white banner pointer -->

  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="pills-hmo-plans-tab" data-toggle="pill" href="#pills-hmo-plans" role="tab"
          aria-controls="pills-hmo-plans" aria-selected="true">HMO Plans</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="pills-hmo-pppo-plans-tab" data-toggle="pill" href="#pills-hmo-pppo-plans" role="tab"
          aria-controls="pills-hmo-pppo-plans" aria-selected="false">PPO Plans</a>
      </li>
      </ul>
      <button *ngIf="hasResult" class="btn btn-third-plain clear-plans-btn" (click)="clearComparePlans()">Clear Compared Plans <i class="fa fa-trash"></i> </button>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-hmo-plans" role="tabpanel" aria-labelledby="pills-hmo-plans-tab">
        <div class="pills-carousel" *ngIf="results">
          <ng-container *ngFor="let result of results; let i = index ">

            <div class="pills-carousel-item" *ngIf="result.plan_type==='Local HMO'">
              <div class="pills-carousel-item-img">
                <img src="{{ base_img_loc + replaceSpaceWith(result.organization_name) + '.jpg' }}" alt="">
              </div>
              <h6>

                {{ result.plan_name }}</h6>
                <input type="hidden" value="{{ result.overall_star_rating }}" #resultStarRating>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="pills-carousel-item-rating" #starRating *ngIf="result.overall_star_rating > 0">
                    <i class="fa {{result.overall_star_rating > 0 && result.overall_star_rating < 1  ? 'fa-star-half-o' : result.overall_star_rating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
                    <i class="fa {{result.overall_star_rating > 1 && result.overall_star_rating < 2  ? 'fa-star-half-o' : result.overall_star_rating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
                    <i class="fa {{result.overall_star_rating > 2 && result.overall_star_rating < 3  ? 'fa-star-half-o' : result.overall_star_rating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
                    <i class="fa {{result.overall_star_rating > 3 && result.overall_star_rating < 4  ? 'fa-star-half-o' : result.overall_star_rating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
                    <i class="fa {{result.overall_star_rating > 4 && result.overall_star_rating < 5  ? 'fa-star-half-o' : result.overall_star_rating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
                  </div>
                  <span class="star-rating-count" *ngIf="result.overall_star_rating > 0">{{ result.overall_star_rating }}</span>
                  <span class="star-rating-count" *ngIf="result.overall_star_rating <= 0">Plan Is New</span>
                </div>

                <mat-divider></mat-divider>

                <span class="pills-carousel-item-label">Monthly Premium</span>
                <span class="pills-carousel-item-value">{{getConvertedRate(result.month_rate) | currency }}</span>

                <span class="pills-carousel-item-label">Plan Deductible</span>
                <span class="pills-carousel-item-value">

                  {{ result.plan_deductible | currency }}</span>

                  <span class="pills-carousel-item-label">Maximum Out-of-Pocket Per Year:
                    <small>(Does Not Include Drug Costs)</small>
                  </span>
                  <span class="pills-carousel-item-value">

                    {{ (result.in_network_moop_sort/100) | currency }}</span>

                    <div class="pills-carousel-item-top-button">
                      <button class="btn btn-third-plain btn-small"><i class="fa fa-file"></i> Plan Details</button>
                      <button class="btn btn-third-plain btn-small"><i class="fa fa-file"></i> Save Plans</button>
                      <button class="btn btn-third-plain btn-small"><i class="fa fa-file"></i> Email Plan</button>
                    </div>

                    <button class="btn btn-first btn-small add-to-cart">Add to Cart</button>

            </div>

          </ng-container>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-hmo-pppo-plans" role="tabpanel" aria-labelledby="pills-hmo-pppo-plans-tab">
        <div class="pills-carousel" *ngIf="results">
          <ng-container *ngFor="let result of results; let i = index ">
              <div class="pills-carousel-item" *ngIf="result.plan_type==='Local PPO'">
                <div class="pills-carousel-item-img">
                  <img src="{{ base_img_loc + replaceSpaceWith(result.organization_name) + '.jpg' }}" alt="">
                </div>
                <h6>

                  {{ result.plan_name }}</h6>
                  <input type="hidden" value="{{ result.overall_star_rating }}" #resultStarRating>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="pills-carousel-item-rating" #starRating *ngIf="result.overall_star_rating > 0">
                      <i
                        class="fa {{result.overall_star_rating > 0 && result.overall_star_rating < 1  ? 'fa-star-half-o' : result.overall_star_rating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
                      <i
                        class="fa {{result.overall_star_rating > 1 && result.overall_star_rating < 2  ? 'fa-star-half-o' : result.overall_star_rating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
                      <i
                        class="fa {{result.overall_star_rating > 2 && result.overall_star_rating < 3  ? 'fa-star-half-o' : result.overall_star_rating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
                      <i
                        class="fa {{result.overall_star_rating > 3 && result.overall_star_rating < 4  ? 'fa-star-half-o' : result.overall_star_rating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
                      <i
                        class="fa {{result.overall_star_rating > 4 && result.overall_star_rating < 5  ? 'fa-star-half-o' : result.overall_star_rating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
                    </div>
                    <span class="star-rating-count" *ngIf="result.overall_star_rating > 0">{{ result.overall_star_rating }}</span>
                    <span class="star-rating-count" *ngIf="result.overall_star_rating <= 0">Plan Is New</span>
                  </div>

                  <mat-divider></mat-divider>

                  <span class="pills-carousel-item-label">Monthly Premium</span>
                  <span class="pills-carousel-item-value">{{getConvertedRate(result.month_rate) | currency }}</span>

                  <span class="pills-carousel-item-label">Plan Deductible</span>
                  <span class="pills-carousel-item-value">

                    {{ result.plan_deductible | currency }}</span>

                    <span class="pills-carousel-item-label">Maximum Out-of-Pocket Per Year:
                      <small>(Does Not Include Drug Costs)</small>
                    </span>
                    <span class="pills-carousel-item-value">

                      {{ (result.in_network_moop_sort/100) | currency }}</span>

                      <div class="pills-carousel-item-top-button">
                        <button class="btn btn-third-plain btn-small"><i class="fa fa-file"></i> Plan Details</button>
                        <button class="btn btn-third-plain btn-small"><i class="fa fa-file"></i> Save Plans</button>
                        <button class="btn btn-third-plain btn-small"><i class="fa fa-file"></i> Email Plan</button>
                      </div>

                      <button class="btn btn-first btn-small add-to-cart">Add to Cart</button>

              </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading && !hasResult">
  <!-- <app-result-not-found></app-result-not-found> -->
  <h6 style="color: #b4b4b4;font-weight: 500;margin-bottom: 4em;text-align: center;margin-top:3rem">Opps! No Record to Display!!!</h6>
</div>
<!-- END OLD DESIGN -->

<div class="footer-top-spacer"></div>
<app-ready-to-talk class="noprint"></app-ready-to-talk>
>