<h4 class="text-center mb-4">Carrier & Plan Details</h4>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-item nav-link active" id="nav-general-details-tab" data-toggle="tab" href="#nav-general-details" role="tab" aria-controls="nav-general-details" aria-selected="true">General Details</a>
    <a class="nav-item nav-link" id="nav-more-details-tab" data-toggle="tab" href="#nav-more-details" role="tab" aria-controls="nav-more-details" aria-selected="false">More Details</a>
  </div>
  </nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-general-details" role="tabpanel" aria-labelledby="nav-general-details-tab">
    <div class="row">
      <table class="col-12 table-bordered">
        <tr>
          <td class="td-label"><span>Insurance Company Name:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Plan Name:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Plan Type:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Prescription Drug Coverage (Part D) Included:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Eligibility Requirements:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Is this a Special Needs Plan?	</span></td>
          <td></td>
        </tr>
      </table>
      <table class="col-12 table-bordered mt-4">
        <tr>
          <td class="td-label"><span>Contract Number:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Plan ID:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Segment ID:</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Plan Year:</span></td>
          <td></td>
        </tr>
      </table>
      <table class="col-12 table-bordered mt-4">
        <tr>
          <td class="td-label"><span>General Plan Service Area: </span></td>
          <td></td>
        </tr>
        <tr>
          <td class="td-label"><span>Eligible To Residents In The Following Counties:</span></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="tab-pane fade" id="nav-more-details" role="tabpanel" aria-labelledby="nav-more-details-tab">
    <div class="row">
      <div id="carouselCarrierMoreDetails" class="col-12 carousel slide" data-ride="carousel" data-interval="false">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <h5 class="fColor-base">Organization Details</h5>
            <table class="col-12 table-bordered">
              <tr>
                <td class="td-label"><span>Organization Type:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Organization Legal Name:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Is this an Employer-Only plan?</span></td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="carousel-item">
            <h5 class="fColor-base">Access & Availability Details</h5>
            <table class="col-12 table-bordered">
              <tr>
                <td class="td-label"><span>General Plan Service Area:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Eligible To Residents In The Following Counties:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Does this Plan have a Continuation Area?</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Same cost sharing in the Continuation Area for the services included?</span></td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="carousel-item">
            <h5 class="fColor-base">SNP Details</h5>
            <table class="col-12 table-bordered">
              <tr>
                <td class="td-label"><span>Is this a Special Needs Plan?</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Special Needs Plan Type:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Special Needs Institutional Type:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>D-SNP Plan With $0 Cost Sharing?</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Does The State Cover All Medicare Premiums & Cost Sharing With This D-SNP Plan?</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Chronic or Disabling Conditions:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Chronic or Disabling Condition Other 1 Description:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Chronic or Disabling Condition Other 2 Description:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Chronic or Disabling Condition Other 3 Description:</span></td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="carousel-item">
            <h5 class="fColor-base">Contact Info</h5>
            <h6 class="text-center">Websites:</h6>
            <table class="col-12 table-bordered mb-4">
              <tr>
                <td class="td-label"><span>Insurance Company Website: </span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Drug Formulary Website:	</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Find Network Providers Website:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Find Participating Pharmacies Website:</span></td>
                <td></td>
              </tr>              
            </table>
            <h6 class="text-center">Phone Numbers:</h6>  
            <p class="fWeight-600 text-center">Customer Service Phone Numbers For Currently Enrolled Medicare Beneficiaries:</p>          
            <table class="col-12 table-bordered mb-4">
              <tr>
                <td class="td-label"><span>Medical Coverage Related Questions:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Drug Coverage Related Questions: 	</span></td>
                <td></td>
              </tr>             
            </table>
            <p class="fWeight-600 text-center">Phone Numbers For Prospective Beneficiaries:</p>          
            <table class="col-12 table-bordered mb-5">
              <tr>
                <td class="td-label"><span>Medical Coverage Related Questions:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Drug Coverage Related Questions: 	</span></td>
                <td></td>
              </tr>             
            </table>

            <h6 class="text-center">TTY Phone Numbers</h6>  
            <p class="fWeight-600 text-center">TTY For Currently Enrolled Medicare Beneficiaries:</p>          
            <table class="col-12 table-bordered mb-4">
              <tr>
                <td class="td-label"><span>Medical Coverage Related Questions:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Drug Coverage Related Questions: 	</span></td>
                <td></td>
              </tr>             
            </table>
            <p class="fWeight-600 text-center">TTY For Prospective Beneficiaries:</p>          
            <table class="col-12 table-bordered">
              <tr>
                <td class="td-label"><span>Medical Coverage Related Questions:</span></td>
                <td></td>
              </tr>
              <tr>
                <td class="td-label"><span>Drug Coverage Related Questions: 	</span></td>
                <td></td>
              </tr>             
            </table>
          </div>
        </div>
        <div class="cc-control-wrapper">
          <a class="cc-carousel-control mr-2" href="#carouselCarrierMoreDetails" role="button" data-slide="prev">
            <i class="fa fa-chevron-left"></i>
          </a>
          <a class="cc-carousel-control" href="#carouselCarrierMoreDetails" role="button" data-slide="next">
            <i class="fa fa-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>		
  </div>
</div>