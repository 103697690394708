import { Component, OnInit, HostBinding, HostListener, ViewChild, ElementRef  } from '@angular/core';

import { RouterOutlet, Router } from '@angular/router';
import { slideInAnimation } from './animations';

import {HeaderService} from './services/header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit {
  @ViewChild('marketplaceWidgetForm', {read: ElementRef}) private marketplaceWidgetFormContent: ElementRef;
  @ViewChild('bennyDashWidgetForm', {read: ElementRef}) private bennyDashWidgetFormContent: ElementRef;
  @HostBinding('@.disabled')
  public animationsDisabled = false;
  title = 'taptapapp';
  //private postsSub: Subscription;
  details: any;

  constructor(public header: HeaderService, private route: Router) {
    // this.route.events.subscribe((val) => {
    //   console.log('batopick', val);
    // })
  }

  ngOnInit() {
    /*this.details = this.dentalService.getPosts();
        console.log(this.details);*/

        $(document).ready(function() {

            var dChatBtn = $('#custom-widget-chat-btn-id');
            var dChatBtnAfter = $('#custom-widget-chat-btn-id-after');
            var dChatBtnSmall = $('#custom-widget-chat-btn-small-id');
            var dChatBtnSmallAfter = $('#custom-widget-chat-btn-small-id-after');

            var crmForm = $('.b24-widget-button-social-item.b24-widget-button-crmform');
            

            $('#custom-widget-chat-btn-close').click(function(e){
              if(e.target === e.currentTarget){
                $(dChatBtn).hide();
                $(dChatBtnSmall).show();
                $(dChatBtn).addClass('trigger_cow_btn');
                $(dChatBtnAfter).removeClass('trigger_cow_btn');
              }  
            })

            $('#custom-widget-chat-btn-close-after').click(function(e){
              if(e.target === e.currentTarget){                
                $(dChatBtnAfter).hide();
                $(dChatBtnSmallAfter).show();
                $(dChatBtn).removeClass('trigger_cow_btn');
                $(dChatBtnAfter).addClass('trigger_cow_btn');
              }  
            })

            $(dChatBtnSmall).click(function(){
              $(dChatBtn).show();
              //$(dChatBtnAfter).show();
              $(this).hide();
            })

            $(dChatBtnSmallAfter).click(function(){
              $(dChatBtnAfter).show();
              //$(dChatBtnAfter).show();
              $(this).hide();
            })
         
        });

        /***************************************************************
         *************START MARKETPLACE WIDGET BUTTON*******************
         **************************************************************/
        const marketplaceWidgetBtn = document.createElement("button");
        marketplaceWidgetBtn.className = "marketplace-widget-btn d-none";
        document.body.append(marketplaceWidgetBtn);

        
        const marketplaceWidgetForm = document.createElement('div');
        marketplaceWidgetForm.id = "marketplace-widget-form";

        marketplaceWidgetForm.innerHTML = this.marketplaceWidgetFormContent.nativeElement.innerHTML;
        document.body.append(marketplaceWidgetForm);
        

        const marketplaceWidgetFormOverlay = document.createElement('div');
        marketplaceWidgetFormOverlay.className = "marketplace-widget-overlay";
        document.body.append(marketplaceWidgetFormOverlay);
        

        $( ".marketplace-widget-btn" ).on( "click", function( event ) {
            $('.marketplace-widget-overlay').removeClass('d-none');
            $('#marketplace-widget-form').removeClass('d-none');
            $('.marketplace-widget-overlay').addClass('d-block');
            $('#marketplace-widget-form').addClass('d-block');
        });

         /***************************************************************
         ****************END MARKETPLACE WIDGET BUTTON*******************
         **************************************************************/ 
        
         /***************************************************************
         *************START BENNYDASH WIDGET BUTTON*******************
         **************************************************************/
        const bennyDashWidgetBtn = document.createElement("button");
        bennyDashWidgetBtn.className = "bennyDash-widget-btn d-none";
        document.body.append(bennyDashWidgetBtn);

        
        const bennyDashWidgetForm = document.createElement('div');
        bennyDashWidgetForm.id = "bennyDash-widget-form";

        bennyDashWidgetForm.innerHTML = this.bennyDashWidgetFormContent.nativeElement.innerHTML;
        document.body.append(bennyDashWidgetForm);
        

        const bennyDashWidgetFormOverlay = document.createElement('div');
        bennyDashWidgetFormOverlay.className = "bennyDash-widget-overlay";
        document.body.append(bennyDashWidgetFormOverlay);
        

        $( ".bennyDash-widget-btn" ).on( "click", function( event ) {
            $('.bennyDash-widget-overlay').removeClass('d-none');
            $('#bennyDash-widget-form').removeClass('d-none');
            $('.bennyDash-widget-overlay').addClass('d-block');
            $('#bennyDash-widget-form').addClass('d-block');
        });
         

         /***************************************************************
         ****************END BENNYDASH WIDGET BUTTON*******************
         **************************************************************/ 
       
  }

    onActivate(event) {
        window.scroll(0,0);
    }

    ngAfterViewInit(){
      $('.benny-dash-sidebar').addClass('gggggggggggggg');
    }


    /*onLogout() {
      this.authService.logout();
    }

    siginInGoogle() {
      this.authService.siginInGoogle();
    }*/

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


 // listen to browser back button clicked 
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    
    // go to top page when back button click
    setTimeout(function(){ window.scroll(0,0); }, 200);

  }

  

}
