import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-application-mapd-version2-screen7',
  templateUrl: './screen7.component.html',
  styleUrls: ['./screen7.component.scss']
})
export class Screen7Component implements OnInit {
  @ViewChild('a3Modal') a3Modal: ElementRef;
  @ViewChild('a4Modal') a4Modal: ElementRef;
  @ViewChild('b1Modal') b1Modal: ElementRef;
  @ViewChild('b2Modal') b2Modal: ElementRef;
  @ViewChild('c3Modal') c3Modal: ElementRef;
  @ViewChild('d3Modal') d3Modal: ElementRef;

  dateFieldType: string;
  dateFieldHeading: string;

  selectedScenario:any = null;
  storedScenario:any = null;
  storedScenarioLetter:any = null;
  addendumDetails:any = null;
  addendumDate:any = null;

  screnarioLetters: any = {
    a1:"I Am “New to Medicare”",
    a2:"I Have Been On Medicare But I Am Just Turning Age 65 Now",
    a3:"I Moved/Changed Where I Live",
    a4:"I Lost Other Coverage or Had An Involuntary Change In My Coverage",
    b1:"I Am Currently Enrolled In A Financial Assistance Program (Like Extra Help, Medicaid, or a State Drug Cost Assistance Program)",
    b2:"I Recently Had A Change In My Eligibility For a Financial Assistance Program",
    b3:"I Have Low Income & Want To Check If I Am Eligible For a Financial Assistance Program",
    c1:"I’m In a Medicare Advantage Plan Now & Have Had Medicare For Less Than 3 Months & Want to Make a Change",
    c2:"I Was Affected By a FEMA-Designated Emergency (or Major Disaster) That Caused Me To Be Unable To Make A Prior Eligible Enrollment Due To The Designated Emergency",
    c3:"I’m Moving Into, Live In, Or Recently Moved Out Of A Long-Term Care Facility (Like A Nursing Home or Rehabilitation Hospital)",
    c4:"I Recently Received Lawful Presence Status In The U.S.",
    d1:"I’m In A Plan That’s Had a Star Rating Of Less Than 3 Stars For The Last 3 Years. I Want To Join A Plan With A Star Rating Of 3 Stars Or Higher",
    d2:"I Was Automatically Enrolled In A Plan By Medicare (Or My State) & I Want To Choose A Different Plan",
    d3:"I No Longer Qualify For My Previous Chronic Illness Special Needs Plan Or Dual Eligible Special Needs Plan",
    e1:"I Am Within October 15th - December 7th (The Annual Enrollment Period)",
    e2:"I Have A Medicare Advantage Plan Currently & Am Within January 1st - March 31st (The Medicare Advantage Open Enrollment Period)",
    f1:"I Am Enrolling Into a 5-Star Plan",
    f2:"I Am Enrolling Into a Chronic Illness Special Needs Plan That I Qualify For"
  }

  constructor() { }

  ngOnInit() {
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

  selectScenario(content, letter) {
    // console.log(period)
    this.storedScenarioLetter = letter;
    this.selectedScenario = this.screnarioLetters[letter];
    Swal.fire({
      html: content,
      showCloseButton: true,
      showConfirmButton: false,
      buttonsStyling: false,
      //allowOutsideClick: false,
      width: 640,
      padding:'35px',
    })
  }

  scenarioSelection() {
    this.storedScenario = this.selectedScenario
    if(this.storedScenarioLetter == 'a3') {
      this.enrollmentPeriodModal(this.a3Modal.nativeElement)
    }
    else if(this.storedScenarioLetter == 'a4') {
      this.enrollmentPeriodModal(this.a4Modal.nativeElement)
    }
    else if(this.storedScenarioLetter == 'b1') {
      this.enrollmentPeriodModal(this.b1Modal.nativeElement)
    }
    else if(this.storedScenarioLetter == 'b2') {
      this.enrollmentPeriodModal(this.b2Modal.nativeElement)
    }
    else if(this.storedScenarioLetter == 'c3') {
      this.enrollmentPeriodModal(this.c3Modal.nativeElement)
    }
    else if(this.storedScenarioLetter == 'd3') {
      this.enrollmentPeriodModal(this.d3Modal.nativeElement)
    } else {
      Swal.close();
    }
  }

  enrollmentPeriodModal(content) {
    Swal.fire({
      html: content,
      showCloseButton: true,
      showConfirmButton: false,
      buttonsStyling: false,
      //allowOutsideClick: false,
      width: 640,
      padding:'35px',
    })
  }

  rejectScenario() {
    this.selectedScenario = '';
    this.storedScenarioLetter = '';
    Swal.close();
  }

}
