import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import { APIService } from '../../services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-plan-document',
  templateUrl: './request-plan-document.component.html',
  styleUrls: ['./request-plan-document.component.scss']
})
export class RequestPlanDocumentComponent implements OnInit {
  loading:boolean = false;
  isFormSubmit:boolean = false;
  sendViaValue:string = 'email';

  @Input() selectedInquiryType;
  @Input() selectedInquiryResult;
  @Input() controls;
  @Input() planYear;

  requestForm = new FormGroup({
    inquiryName: new FormControl('', [
      Validators.required,
    ]),
    inquiryEmail: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    inquiryPhone: new FormControl('', []),
    sendVia: new FormControl(this.sendViaValue, [])
  }); 

  get inquiryName() {
    return this.requestForm.get('inquiryName');
  }

  get inquiryEmail() {
    return this.requestForm.get('inquiryEmail');
  }

  constructor(private _api: APIService, private _router: Router) { }

  ngOnInit() {
  }

  onSubmitRequestDetails(){
    this.isFormSubmit = true;

    if( this.requestForm.controls.inquiryName.valid && 
        this.requestForm.controls.inquiryEmail.valid ){

        Swal.close();

        this._api.callAPIEndpoint('/api/v1/quotation/request/information', {
          'url': this._router.url,
          'type': this.selectedInquiryType,
          'result': this.selectedInquiryResult,
          'zipcode': this.controls.zipcode.value,
          'planYear': this.planYear,
          'name': this.requestForm.controls.inquiryName.value,
          'send_via': this.requestForm.controls.sendVia.value,
          'mobile_no':this.requestForm.controls.inquiryPhone.value,
          'email': this.requestForm.controls.inquiryEmail.value
        }).subscribe(
          (data: any) => {
            Swal.fire('Your request has been successfully sent');
            this.requestForm.reset();
            this.requestForm.patchValue({sendVia: this.sendViaValue});
            this.requestForm.patchValue({inquiryPhone: ''});
            this.isFormSubmit = false;
            this.loading = false;
          },
          (err) => {
            this.loading = false;
          }
        );
    }
  }
}
