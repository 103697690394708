<h4 class="title mb-5">Basic Info</h4>
<div class="row px-0">
    <div class="col-12 col-sm-4 pr-2">
        <div class="form-group">
            <label for="legalFirstName">Legal First Name</label>
            <input type="text" class="form-control blurred" id="legalFirstName" [(ngModel)]="firstName"/>
        </div>        
    </div>
    <div class="col-12 col-sm-4">
        <div class="form-group">
            <label for="legalMiddleName">Legal Middle Name</label>
            <input type="text" class="form-control blurred" id="legalMiddleName" [(ngModel)]="middleName"/>
        </div>
    </div>
    <div class="col-12 col-sm-4 pl-2">
        <div class="form-group">
            <label for="legalLastName">Legal Last Name</label>
            <input type="text" class="form-control blurred" id="legalLastName" [(ngModel)]="lastName"/>
        </div>
    </div>
</div>  
<div class="row px-0 mt-4">
  <div class="col-12 col-sm-6 pr-2">
    <div class="form-group">
        <label for="dateOfBirth">Date of Birth</label>
        <input type="text" class="form-control blurred" id="dateOfBirth" (click)="onClickDob('normal','What Month & Year Were You Born?')"/>
    </div>
  </div>
  <div class="col-12 col-sm-6 pl-2">
    <div class="form-group">
      <label>Gender</label>
      <div class="row px-0">
        <div class="col-12 col-md-6 pr-1">
            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn ml-0" (click)="onClick_gender('male')" [class.active]="details?.customer_info.gender == 'male'">Male<i class="fas fa-check ml-2"></i></button>
        </div>
        <div class="col-12 col-md-6 pl-1">
            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn ml-0" (click)="onClick_gender('female')" [class.active]="details?.customer_info.gender == 'female'">Female <i class="fas fa-check ml-2"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>