<section class="container insurance-logo-wrapper first-option d-none" [class.pt-0]="isNoPaddingTop">
  <div class="col-12 insurance-logo-heading mt-4 row justify-content-center">
    <!-- <h5>Access The Full Marketplace of Medicare Plans & Carriers</h5> -->
    <h4 class="col-12 text-center mb-2">Your Healthcare, Your Security, Your Support System, Your Medicare, In One Clear View.</h4>
    <h6 class="subtitle col-12 text-center fColor-base fWeight-600">All Medicare Plans, Carriers, & Supporting Coverages In One Clear View.</h6>
    <!-- <h5>Your Full Marketplace Of Medicare Carriers</h5> -->
    <!-- <p>TapTap Medicare utilizes the entire ecosystem of Medicare Insurance carriers and coverage types to<br> make sure
      you are always in the most suitable and cost efficient coverages.
    </p> -->
    <!-- <p style="font-size: 16px;font-weight: 500;max-width: 740px;line-height: 1em;">This is TapTap's Commitment To Provide Your & Guide You With The Most Suitable <br>& Cost Efficient 
      Coverages Available To Medicare Beneficiaries.
    </p> -->
  </div>
  <div class="col-12 company-logo-display mb-0">
    <img src="assets/img/home/company-logo-display/anthem-logo.jpg">
    <img src="assets/img/home/company-logo-display/aarp-medicare-plans-logo.jpg">
    <img src="assets/img/home/company-logo-display/aetna-logo.jpg">
    <img src="assets/img/home/company-logo-display/cigna-healthspring.png">
    <!-- <img src="assets/img/home/company-logo-display/cigna-logo.jpg"> -->
    <img src="assets/img/home/company-logo-display/humana -logo.jpg">
    <img src="assets/img/home/company-logo-display/mutual-of-omaha-logo.jpg">
    <img src="assets/img/home/company-logo-display/well-care-logo.jpg">
    <img src="assets/img/home/company-logo-display/lumico-logo.jpg">
    <img src="assets/img/home/company-logo-display/medico-logo.png">
    <img src="assets/img/home/company-logo-display/americo-logo.png">
    <img src="assets/img/home/company-logo-display/blue-cross-blue-shield-logo.png">
    <img src="assets/img/home/company-logo-display/harvard-pilgrim-logo.png">
    <img src="assets/img/home/company-logo-display/fallon-health-logo.png">
    <img src="assets/img/home/company-logo-display/clover-logo.jpg">
    <img src="assets/img/home/company-logo-display/upmc-logo.png" style="max-width: 100px;">
    <img src="assets/img/home/company-logo-display/geisinger-logo.jpg">
    <img src="assets/img/home/company-logo-display/medigapTransamerica-logo.jpg"
      style="max-width: 135px;margin-top: -17px;">
    <img src="assets/img/home/company-logo-display/bankers-fidelity-logo.png" >
  </div>
  <div class="col-12 company-logo-display mt-0" style="max-width:990px;">
    <img src="assets/img/home/company-logo-display/allwell-logo.png" class="all-well-logo">
    <img src="assets/img/home/company-logo-display/csi-logo.png" class="csi-logo" style="max-height:73px">
    <img src="assets/img/home/company-logo-display/gpm-life-logo.png" class="gpm-life-logo" style="max-height:60px">
    <img src="assets/img/home/company-logo-display/amerigroup.png" class="amerigroup-logo">
    <img src="assets/img/home/company-logo-display/independence.png" class="independence-logo">
  </div>
</section>

<section id="section-insurance-logo" [class.pt-0]="isNoPaddingTop" *ngIf="showLogo">
  <div class="container">
    <div class="col-12 insurance-logo-heading mt-4 row justify-content-center mb-0 mb-sm-2 text-center">
      <h3 class="col-12 text-center mt-2 mt-md-5 mb-4 fWeight-700">Your Healthcare, Your Security, Your Support System, Your Medicare, In One Clear View.</h3>
      <!-- <h4 class="subtitle fColor-black fWeight-600 mb-3">All Medicare Plans, Carriers, & Supporting Coverages In One Clear View.</h4> -->
      <!-- <h5 class="col-12 text-center fColor-grey fWeight-500">
        This is TapTap's Commitment To Provide You 
        With The Most Suitable <br>& Cost Efficient Coverages Available To
        Medicare Beneficiaries.</h5> -->
    </div>
    <div id="carouselExampleIndicators" class="carousel slide mt-2 mt-md-5" data-ride="carousel" data-interval="false" *ngIf="showLogo">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/anthem-logo.jpg"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/aarp-medicare-plans-logo.jpg"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/aetna-logo.jpg"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/cigna-healthspring.png"></div>
        </div>
        <div class="carousel-item">
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/humana -logo.jpg"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/mutual-of-omaha-logo.jpg"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/well-care-logo.jpg"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/lumico-logo.jpg"></div>
        </div>
        <div class="carousel-item">
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/medico-logo.png"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/americo-logo.png"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/blue-cross-blue-shield-logo.png"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/harvard-pilgrim-logo.png"></div>
        </div>
        <div class="carousel-item">
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/fallon-health-logo.png"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/clover-logo.jpg"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/upmc-logo.png" style="max-width: 100px;"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/geisinger-logo.jpg"></div>
        </div>
        <div class="carousel-item">
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/medigapTransamerica-logo.jpg" style="max-width: 135px;margin-top: -17px;"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/bankers-fidelity-logo.png" ></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/allwell-logo.png" class="all-well-logo"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/csi-logo.png" class="csi-logo" style="max-height:73px"></div>
        </div>
        <div class="carousel-item">
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/gpm-life-logo.png" class="gpm-life-logo" style="max-height:60px"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/amerigroup.png" class="amerigroup-logo"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/independence.png" class="independence-logo"></div>
          <div class="carousel-item-img"><img src="assets/img/home/company-logo-display/allwell-logo.png" class="all-well-logo"></div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</section>

<!-- <section class="insurance-logo-wrapper second-option">
  <div class="col-12 insurance-logo-heading">
    <h5>Your Full Marketplace Of Medicare Carriers</h5>
    <p>TapTap Medicare utilizes the entire ecosystem of Medicare Insurance carriers and coverage types to<br> make sure you are always in the most suitable and cost efficient coverages. 
    </p>
  </div>
  <div class="col-12 company-logo-display">
    <img src="assets/img/home/company-logo-display/anthem-logo.jpg">
    <img src="assets/img/home/company-logo-display/aarp-medicare-plans-logo.jpg">
    <img src="assets/img/home/company-logo-display/aetna-logo.jpg">
    <img src="assets/img/home/company-logo-display/cigna-logo.jpg">
    <img src="assets/img/home/company-logo-display/humana -logo.jpg">
    <img src="assets/img/home/company-logo-display/mutual-of-omaha-logo.jpg">						
    <img src="assets/img/home/company-logo-display/well-care-logo.jpg">
    <img src="assets/img/home/company-logo-display/lumico-logo.jpg">    
    <img src="assets/img/home/company-logo-display/medico-logo.png">
    <img src="assets/img/home/company-logo-display/americo-logo.png">
    <img src="assets/img/home/company-logo-display/blue-cross-blue-shield-logo.png">    
    <img src="assets/img/home/company-logo-display/harvard-pilgrim-logo.png">
    <img src="assets/img/home/company-logo-display/clover-logo.jpg">
    <img src="assets/img/home/company-logo-display/upmc-logo.jpg">   
    <img src="assets/img/home/company-logo-display/geisinger-logo.jpg">      
    <img src="assets/img/home/company-logo-display/medigapTransamerica-logo.jpg">   
    <img src="assets/img/home/company-logo-display/bankers-fidelity-logo.png"> 
    <img src="assets/img/home/company-logo-display/allwell-logo.png" class="all-well-logo"> 
    <img src="assets/img/home/company-logo-display/csi-logo.png" class="csi-logo"> 
    <img src="assets/img/home/company-logo-display/gpm-life-logo.png" class="gpm-life-logo">
  </div>
</section>

<section class="insurance-logo-wrapper third-option">
  <div class="row">
    <h4 class="col-12 insurance-logo-heading text-center">Your Full Marketplace Of Medicare Carriers</h4>
  </div>
  <div class="row company-logo-display">
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/anthem-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/aarp-medicare-plans-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/aetna-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/cigna-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/humana -logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/mutual-of-omaha-logo.jpg">	
    </div>	
    <div class="col-3">			
      <img src="assets/img/home/company-logo-display/well-care-logo.jpg">
    </div>	
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/lumico-logo.jpg"> 
    </div>   
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/medico-logo.png">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/americo-logo.png">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/blue-cross-blue-shield-logo.png"> 
    </div>   
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/harvard-pilgrim-logo.png">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/clover-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/upmc-logo.jpg">   
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/geisinger-logo.jpg">  
    </div>  
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/medigapTransamerica-logo.jpg">   
    </div>  
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/bankers-fidelity-logo.png"> 
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/allwell-logo.png" class="all-well-logo">
    </div> 
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/csi-logo.png" class="csi-logo"> 
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/gpm-life-logo.png" class="gpm-life-logo">
    </div>
  </div>
</section>

<section class="insurance-logo-wrapper fourth-option">
  <div class="row">
    <h4 class="col-12 insurance-logo-heading text-center">Your Full Marketplace Of Medicare Carriers</h4>
  </div>
  <div class="row company-logo-display">
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/anthem-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/aarp-medicare-plans-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/aetna-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/cigna-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/humana -logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/mutual-of-omaha-logo.jpg">	
    </div>	
    <div class="col-3">			
      <img src="assets/img/home/company-logo-display/well-care-logo.jpg">
    </div>	
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/lumico-logo.jpg"> 
    </div>   
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/medico-logo.png">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/americo-logo.png">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/blue-cross-blue-shield-logo.png"> 
    </div>   
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/harvard-pilgrim-logo.png">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/clover-logo.jpg">
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/upmc-logo.jpg">   
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/geisinger-logo.jpg">  
    </div>  
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/medigapTransamerica-logo.jpg">   
    </div>  
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/bankers-fidelity-logo.png"> 
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/allwell-logo.png" class="all-well-logo">
    </div> 
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/csi-logo.png" class="csi-logo"> 
    </div>
    <div class="col-3">
      <img src="assets/img/home/company-logo-display/gpm-life-logo.png" class="gpm-life-logo">
    </div>
  </div>
</section> -->