import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-taptap-new-to-medicare-part-b',
  templateUrl: './taptap-new-to-medicare-part-b.component.html',
  styleUrls: ['./taptap-new-to-medicare-part-b.component.scss']
})
export class TaptapNewToMedicarePartBComponent implements OnInit {
  fullPrimaryHeader: boolean = true;
  dblocking: string = 'd-none';

  constructor(private titleService:Title) {
    this.titleService.setTitle("TapTap Medicare Part B Clarity");
  }

  ngOnInit() {}

  speakToCounselorEvent(modalViews: any, modalType) {
    this.dblocking = 'd-block';

    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: modalType,
      padding: '3em',
      onClose: () => {
        this.dblocking = 'd-none';
      }
    });
  }

  onClickChat($event) {
    $event.defaultPrevented;
    let launcherFrame = $('.aio-launcher-frame');
    let button = launcherFrame.contents().find('#aio-launcher-container');
    button.trigger('click');
  }
}
