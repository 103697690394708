import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { APIService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-speak-with-a-counselor',
  templateUrl: './speak-with-a-counselor.component.html',
  styleUrls: ['./speak-with-a-counselor.component.scss']
})
export class SpeakWithACounselorComponent implements OnInit {
  showCalendly: boolean = false;

  scheduleStep: number = 1;
  selected: Date = new Date();

  fname: string;
  lname: string;
  email: string;
  phone: string;
  message: string;

  searchCategoryInput = null;
  
  categories = [
    {
      name: 'I Just Started Medicare',
      subCategories: [
        { 
          name: 'Help Me With My Drug Costs', 
          desc: 'Get assistance understanding and managing the costs of your prescribed medications under Medicare.' 
        },
        { 
          name: 'Help Me Get a Medigap Price Comparison', 
          desc: 'Assistance in understanding and comparing the prices of various Medigap plans to find the one that best suits your needs.' 
        },
        { 
          name: 'Help Me Improve My Out-of-Pocket Costs', 
          desc: 'Strategies and assistance to minimize the costs that you pay out-of-pocket under your Medicare plan.' 
        }
      ]
    },
    {
      name: 'Medicare Part A & B Enrollment',
      subCategories: [
        { 
          name: 'Help Me Fully Enroll in Medicare', 
          desc: 'Step-by-step guidance on how to enroll in Medicare Parts A and B.' 
        },
        { 
          name: 'Help Me Enroll in Medicare Part A', 
          desc: 'Detailed assistance with enrolling in Medicare Part A, which covers hospital services.' 
        },
        { 
          name: 'Help Me Enroll in Medicare Part B', 
          desc: 'Support for enrolling in Medicare Part B, which covers outpatient services.' 
        }
      ]
    },
    {
      name: 'Understanding Medigap vs Medicare Advantage',
      subCategories: [
        { 
          name: 'Help Me Understand the Big Picture', 
          desc: 'Detailed overview of the differences between Medigap and Medicare Advantage, and how each can impact your healthcare.' 
        },
        { 
          name: 'Help Me Understand What Decisions I Have to Make', 
          desc: 'Guidance on the key decisions you will need to make when choosing between Medigap and Medicare Advantage.' 
        },
        { 
          name: 'Help Me Understand What Questions I Should Ask', 
          desc: 'Suggestions for important questions to ask when comparing Medigap and Medicare Advantage plans.' 
        }
      ]
    },
    {
      name: 'Help Me Organize My Medicare',
      subCategories: [
        { 
          name: 'Help Me Understand My Coverage', 
          desc: 'Help deciphering the details of your Medicare coverage, including what services are covered and what costs you can expect.' 
        },
        { 
          name: 'Help Me Organize My Coverage', 
          desc: 'Strategies and tips for keeping track of your Medicare coverage, including paperwork and healthcare services.' 
        },
        { 
          name: 'Help Me Identify and Understand My Costs', 
          desc: 'Support in identifying all of the costs associated with your Medicare coverage, including premiums, copayments, and deductibles.' 
        }
      ]
    },
    {
      name: 'Reduce Costs & Save Money',
      subCategories: [
        { 
          name: 'Help Me With My Drug Costs', 
          desc: 'Help understanding and reducing the costs of your medications under your Medicare plan.' 
        },
        { 
          name: 'Help Me Get a Medigap Price Comparison', 
          desc: 'Support in comparing the prices of various Medigap plans to help you save money.' 
        },
        { 
          name: 'Help Me Improve My Out-of-Pocket Costs', 
          desc: 'Guidance on strategies to reduce the out-of-pocket costs associated with your Medicare coverage.' 
        }
      ]
    },
    {
      name: 'Custom Plan Suitability Comparison',
      subCategories: [
        { 
          name: 'Help Me Compare Medicare Advantage Plans', 
          desc: 'Get assistance in comparing the coverage, costs, and benefits of different Medicare Advantage plans.' 
        },
        { 
          name: 'Help Me Compare Medigap Plans', 
          desc: 'Support in comparing the coverage and costs of different Medigap plans to help you find the best fit.' 
        },
        { 
          name: 'Help Me See All Plans My Doctor Takes', 
          desc: 'Guidance in identifying which Medicare and Medigap plans are accepted by your healthcare providers.' 
        }
      ]
    },
    {
      name: 'Apply for Government Assistance Programs',
      subCategories: [
        { 
          name: 'Help Me With Medicare Savings Programs', 
          desc: 'Assistance in understanding and applying for government programs that can help you save money on Medicare costs.' 
        },
        { 
          name: 'Help Me Drug Cost Assistance Programs', 
          desc: 'Guidance on applying for government assistance programs that can help you afford your medications.' 
        },
        { 
          name: 'Help Me Reduce My Part B Premium', 
          desc: 'Strategies and assistance in reducing the premium you pay for Medicare Part B.' 
        }
      ]
    },
    {
      name: 'Full Personalized Reviews',
      subCategories: [
        { 
          name: 'Help Me Receive Clear Answers and Explanations on Any Part of Medicare', 
          desc: 'Get personalized support and clear answers to all your questions about Medicare.' 
        },
        { 
          name: 'Help Me Identify Your Exact Coverage Scenario and Its Competitiveness Relative to Other Plans and Prices', 
          desc: 'Personalized review of your current Medicare coverage and how it compares to other available options.' 
        },
        { 
          name: 'Help Me Receive a Full Custom Report That I Can Access and Share with Others', 
          desc: 'Receive a comprehensive report detailing all aspects of your Medicare coverage that you can access anytime and share with others.' 
        }
      ]
    }
  ]

  displayedCategories = this.categories;

  selectedTopics = [];
  selectedSchedule;
  selectedTime: string;
  selectedDay: any;
  selectedMonthAndDay: any;
  selectedPreference: any;

  completed: boolean = false;


  constructor(private _api: APIService) { }
  
  ngOnInit() {
  }
  
  // trigger when Speak to Counselor > Chat through our website is clicked
  onClickChat($event) {
    Swal.close();
    $event.defaultPrevented;
      //const url = 'https://taptapmedicare.bitrix24.com/online/taptap-chat';

      //window.open(url, "_blank");
      
    /*let launcherFrame = $('.aio-launcher-frame');
    let button = launcherFrame.contents().find('#aio-launcher-container');
    button.trigger('click');*/

     //let launcherFrame = $('#olark-wrapper');
      //let button = launcherFrame.contents().find('.olark-launch-button');
      //button.trigger('click');

     /* let launcherFrame = $('#beacon-container');

      let frame = launcherFrame.contents().find('iframe');
      let button = frame.contents().find('.kdwGQf');
      button.trigger('click');*/


      //  let launcherFrame = $('.b24-widget-button-wrapper');
      //  let button = launcherFrame.contents().find('.b24-widget-button-block');
      //  button.trigger('click');
       //button.addClass('mmmmmm');
      /*let launcherFrame = $('.b24-widget-button-wrapper');
      let chatWindow = launcherFrame.contents().find('.bx-livechat');
      console.log(chatWindow);*/
      //chatWindow.show();

      //$('.bx-livechat-wrapper').show();
      //b24-widget-button-wrapper b24-widget-button-position-bottom-right b24-widget-button-visible

      //bx-livechat-wrapper bx-livechat-show bx-livechat-position-bottom-right bx-livechat-logo-en bx-livechat-offline-state bx-livechat-custom-scroll bx-livechat-bright-header
       
      // let launcherFrame_first = $('.custom-widget-chat-btn:visible');
      let launcherFrame = $('.trigger_cow_btn');
      
      // let button = launcherFrame.contents().find('.b24-widget-button-block');
      launcherFrame.trigger('click');
      // launcherFrame_first.trigger('click');
      
  }

  scheduleACallClick(){
    var calendlyPopUp = document.getElementById('calendlyPopUp');
    calendlyPopUp.click();
  }

  showModal(modalViews: any, modalWidth: number = 850, modalContentClass:any="") {
    Swal.fire({
        html: modalViews,
        showConfirmButton: false,
        showCloseButton: true,
        width: modalWidth,
        customClass: {
            popup: modalContentClass,
        }
    })
  }

  telFormat (e:any, id: any) { // id => HTML TAG ID, e => event
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }


  /***
   * Search Categories from Input Field
   */
  setCategoriesOnSearch(): any {
    document.querySelectorAll('.collapseWrapper').forEach(element => {
      element.classList.add('show');
    });
    if (this.searchCategoryInput.length < 1) {
      this.displayedCategories = this.categories;
      document.querySelectorAll('.collapseWrapper').forEach(element => {
        element.classList.remove('show');
      });
    } else {
      const filterData = this.categories.filter((category) => {
        const matchingSubcategories = category.subCategories.filter(
          (subCategory) =>
            subCategory.name.toLowerCase().includes(this.searchCategoryInput.toLowerCase())
        );
        return matchingSubcategories.length > 0;
      });
      this.displayedCategories = filterData;
    }
  }

  /**
  * Adds a Topic
  * @param {object} selection
  */
  setSelectTopic(selection: object): void {
    this.selectedTopics.push(selection);
    if(this.completed == false) {
      this.scheduleStep = 3;
    } else {
      this.scheduleStep = 5;
    }
  }

  /**
  * Removes a Topic
  * @param {number} index
  */
  setRemoveTopic(index: number): void {
    let array = this.selectedTopics;
    array.splice(index,1);
    this.selectedTopics = array;
  }

  /**
  * Get Day of Schedule based on Mat-Calendar selection
  * @return {string}
  */
  getDayOfWeek(): string {
    const dateString = this.selected;
    const date = new Date(dateString);
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' });
    const formattedDate = `${dayOfWeek}`;
    return formattedDate;
  }

  /**
  * Get Month and Day of Schedule based on Mat-Calendar selection
  * @return {string}
  */
  getMonthAndDay(): string {
    const dateString = this.selected;
    const date = new Date(dateString);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const dayOfMonth = date.toLocaleString('en-US', { day: '2-digit' });
    const formattedDate = `${month} ${dayOfMonth}`;
    return formattedDate;
  }

  /**
  * Selects an Appointment Time
  * @param {string} time
  */
  setSelectSchedule(time: string): void {
    this.selectedTime = time;
    this.selectedDay = this.getDayOfWeek();
    this.selectedMonthAndDay = this.getMonthAndDay();
    if(this.completed == false) {
      this.scheduleStep = 4;
    } else {
      this.scheduleStep = 5;
    }
  }

  /**
  * Selects a Preference
  * @param {string} selection
  */
  setSelectPreference(selection: string) {
    this.selectedPreference = selection;
    this.completed = true;
    this.scheduleStep = 5;
  }

  /**
  * Submit the Form to Bitrix API
  * @param
  * @return
  */
  setSubmitSchedule() {
    this.scheduleStep = 6;
    const selectedDate = new Date(this.selected);
    this._api
      .callAPIEndpoint('/api/v1/inquiry', {
        first_name: this.fname,
        last_name: this.lname,
        email: this.email,
        phone: this.phone,
        source_id: 'Website | Schedule A Call',
        source_description: 'Message Box Entry',
        date: `${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()}`,
        time: this.selectedTime,
        message: this.message
      })
      .subscribe(
        (data: any) => {
          
        },
        (err) => {
          
        }
      );
  }
}

