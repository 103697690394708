import {Injectable} from '@angular/core';
import {AuthenticatedUser} from "./user.data";
import {APIService} from "./api.service";
import {ActivatedRoute, Router, NavigationStart} from "@angular/router";
import {Observable, map} from "rxjs";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { treeFeaturesFactory } from '@clr/angular/data/tree-view/tree-features.service';

const API_USER_LOGIN: string = "/api/v1/member-login";
const API_CURRENT_USER: string = "/api/user/current";
const API_REGISTER_LOGIN: string = "/api/v1/register";

@Injectable()
export class AuthService {

    activeUser: AuthenticatedUser;
    sessionDict:{} = {};
    errors: any;

    auth_token: any;
    fullPrimaryHeader:boolean = true;

    constructor(private _API: APIService, private _activatedRoute: ActivatedRoute, private _router:Router) {
        this._router.events.subscribe((event)=>{
            if (this.activeUser && event instanceof  NavigationStart)
            {
                //save the session dict.
                this.saveSessionDict(this.sessionDict);
            }
        });

        console.log('auth_service.ts = ' + this.activeUser);
    }

    setActiveUserUsingJSON(json: string|Object, externalParty: string = '') {
        this.activeUser = AuthenticatedUser.initFromJSON(json);
        //if valid, save the auth_token to local storage
        if (this.activeUser.auth_token)
        {
            console.log(this.activeUser);
            window.localStorage.setItem('auth_token', this.activeUser.auth_token);
            window.localStorage.setItem('auth_id', this.activeUser.id);
            window.localStorage.setItem('external_party', externalParty);

            this.loadSessionDictFromLocalStorage();

            window['currentSessionData'] = () => {
                return {
                    user   : this.activeUser,
                    session: this.sessionDict
                }
            }
           // console.log('window');
            //console.log(window['currentSessionData']());
        }
    }

    signUserInOnServer(data: {email: string, password: string}) {
        console.log('signUserInOnServer =' + data);
        return this._API.callAPIEndpoint(API_USER_LOGIN, data);
    }

    async signInUsingExternalParty(externalParty: string): Promise<any> {
        firebase.auth().useDeviceLanguage();

        let provider: any = this.getProvider(externalParty);
        provider.setCustomParameters({
          display: 'popup'
        });
        
        const result = await firebase.auth().signInWithPopup(provider);
        if (result) {
            var user = result.user;
            var profile : any = result.additionalUserInfo.profile;
            
            const userData = await this.checkUserExists(user.email);
            var accountExists: boolean = userData && userData.length > 0;
            if(accountExists) {
                if(userData[0].external_party === externalParty) {
                    return ({email: user.email, password: user.uid, userData: result})
                } else if(userData[0].external_party === null) {
                    return { exists: true, message: `You are already registered to our system with the email of ${ user.email }. Kindly login using your taptapmedicare acount.` };
                } else if(userData[0].external_party !== externalParty) {
                    return { exists: true, message: `You are already registered to our system using ${ userData[0].external_party }, with the email of ${ user.email }. Kindly login using that account.`};
                } 
            }

            if(!accountExists) {
                return await this.registerAccount(externalParty, result);
            }
        }
    }

    async registerAccount(externalParty: string, userData: any) : Promise<any> {
        var user = userData.user;
        var profile : any = userData.additionalUserInfo.profile;

        let email = profile.email;
        let lastName = null;
        let firstName = null;
        let mobilePhone = null;
        
        if(externalParty === 'google') {
            lastName = profile.family_name;
            firstName = profile.given_name;
        } else if(externalParty === 'twitter') {
            lastName = user.displayName;
            firstName = profile.name;
        } else {
            lastName = profile.last_name;
            firstName = profile.first_name;
        }

        mobilePhone = user.phoneNumber;
        
        const result = new Promise((resolve, reject) => {
            this._API.callAPIEndpoint(API_REGISTER_LOGIN, {
                first_name: firstName, last_name: lastName, email: email, mobile_phone: mobilePhone, 
                password: user.uid, c_password: user.uid, external_id: user.uid, external_party: externalParty
            }).subscribe(
                (result: any) => {
                    if (result.status === 1) {
                        resolve({email: email, password: user.uid, userData: userData});
                    } else {
                        reject(result);
                    }
                }
            )
        });

        return result;
    }

    checkUserExists(email: string): any {
        const result = new Promise((resolve, reject) => {
            this._API.callAPIEndpoint('/api/v1/check-user', {
                email
            }).subscribe(
                (result: any) => {
                    if (result) {
                        resolve(result);
                    } else {
                        reject(null);
                    }
                }
            )
        });

        return result;
    }
    
    /**
     * Checks with the server to get a user for a given session id.
     * @param auth_token
     * @returns {Observable<boolean>}
     */
    getLoggedInUserDetailsWithAuthToken(auth_token):any {
        return this._API.callAPIEndpoint(API_CURRENT_USER,{'auth_token':auth_token}, {method:'POST', showLoading:true})
            .pipe(map((data)=>{
                if (data.code==1)
                {
                    this.setActiveUserUsingJSON(data.details);
                    return true;
                }
                return false;
            }));
    }

    logUserOut(): boolean {
        // window.localStorage.removeItem(this.activeUser.auth_token);
        this.sessionDict = {};
        this.activeUser = null;
        window.localStorage.removeItem('auth_token');
        window.localStorage.removeItem('auth_id');

        if(window.localStorage.getItem('external_party')) {
            firebase.auth().signOut().then(() => {
                window.localStorage.removeItem('external_party');
                window.location.href = "/home"; 
                return true;
            }).catch((error) => {
                console.log(error);
            });
        }
        else {
            window.localStorage.removeItem('external_party');
            window.location.href = "/home"; 
            return true;
        }

        this.deleteUserLoggedInCookies();
    }

    deleteUserLoggedInCookies(): void {
        document.cookie = 'bp_user_id=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
        document.cookie = 'bp_user_name=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
        document.cookie = 'bp_user_picture=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
        document.cookie = 'bp_user_email=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
        document.cookie = 'bp_user_accounttype=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=0;';
    }
    

    loadSessionDictFromLocalStorage() {
        if (this.activeUser)
        {
            let unparsed =  window.localStorage.getItem(this.activeUser.auth_token);
            if (unparsed)
            {
                this.sessionDict = JSON.parse(unparsed);
            }
            console.log('session dict');
            console.log(this.sessionDict);
        }
    }

    saveSessionDict(sessionDict) {
        //save in local storage.
        if (!this.activeUser) {
            return;
        }
        window.localStorage.setItem(this.activeUser.auth_token, JSON.stringify(sessionDict));
    }

    checkIfUserLoggedIn(){
        this.auth_token = window.localStorage.getItem('auth_token');
        if (this.auth_token) {
            return true;
        }else{
            return false;
        }
    }

    isAuthenticated() {
        var user = window.localStorage.getItem('auth_token');
        console.log(user);
        return user != null;
    }

    getProvider(externalParty: string): any{
        let provider: any;

        if (externalParty.toLowerCase() === 'facebook') {
            provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('public_profile');
            provider.addScope('email');
        }
        else if (externalParty.toLowerCase() === 'twitter') {
            provider = new firebase.auth.TwitterAuthProvider();
        }
        else if (externalParty.toLowerCase() === 'google'){
            provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        }

        return provider;
    }

    getUserDetails(): Promise<any> {
        const result = new Promise((resolve, reject) => {
            var token = window.localStorage.getItem('auth_token');
            
            this._API.geUserInfo('/api/user/details', token, {}).subscribe(
                (data: any) => {
                    resolve(data.details);
                },
                (err) => {
                    console.log(err);
                    reject(err);
                }
            );
        });

        return result;
    }
}
