<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b></app-header-b>

  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-md-8">
        <div class="banner-text-wrapper">
          <h1 class="banner-main-title">
            <span>Your Medicare Marketplace</span><br />
            Made Simple and Clear.
          </h1>
          <p class="banner-sub-title">
            TapTap Medicare has made viewing Medicare plans easy, convenient,
            and transparent.
          </p>
        </div>
      </div>
      <div class="col-md-4 px-0 banner-image-wrapper">
        <img
          src="assets/img/common/old-woman-half-body-with-basket-v1.png"
          class="banner-main-image"
        />
      </div>
    </div>
  </div>
</div>

<div class="banner-pointer-wrapper" style="margin-bottom: 0px">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>

<app-insurance-logo></app-insurance-logo>

<section class="container-fluid main-content-wrapper home-wrapper">
  <div class="row home-content">
    <div class="col-12 row home-content-first-box-wrapper">
      <div class="col-md-12 home-content-row-div" id="government-standardized">
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-12 col-md-5 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
              <img src="assets/img/common/pink-lady-cut.png" class="mt-4"/>
            </div>
            <div
              class="row col-12 col-md-7 d-flex justify-content-center justify-content-md-end text-center text-md-left government-standardized-box-content">
              <div class="col-12 details-p row justify-content-center">
                <p>
                  Medicare Supplement Plans are
                  <span>"Government <br />
                    Standardized"</span>, which means they provide the:
                </p>
                <ul>
                  <li>same <span>coverage and benefits</span></li>
                  <li>same <span>doctors and healthcare providers</span></li>
                  <li>same <span>claims process</span></li>
                  <li>same <span>rules and guarantees</span></li>
                </ul>
                <p>
                  ...for a specific plan letter,
                  <span>regardless of the <br />
                    insurance carrier</span>you purchase it from.
                </p>
              </div>
              <div class="col-12 row justify-content-center mt-20">
                <button class="col-6 btn btn-first-plain padding-20 width-auto"
                  (click)="speakToCounselorEvent(speakToCounselorModal, 540)">
                  Speak With a Concierge Now
                </button>
              </div>
              <div class="col-12 row justify-content-center mt-20">
                <p class="col-7 font-blue" style="font-size: 14px; text-align: center">
                  Or Scroll Below To Learn More
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 home-content-row-div mt-50">
        <div class="container">
          <div class="row flex-column-reverse flex-md-row align-items-center">
            <div class="row col-12 col-md-9 d-flex justify-content-center text-center text-md-left pr-md-5">
              <div class="col-12 details-p row justify-content-center mt-50">
                <h4>Important Coverage Insight:</h4>
              </div>
              <div class="col-12 details-p row justify-content-center">
                <p>
                  This eliminates, or reduces (depending on the Plan Letter you
                  choose), your out-of-pocket costs for all of the following
                  healthcare services:
                </p>
              </div>
              <div class="col-12 row justify-content-center cover-list">
                <div class="col-12 details-p row">
                  <ul>
                    <li><img src="./assets/img/common/plan-n-landing-page/Diabetic Supplies@144x.png" alt="Doctor's Office Visits">Doctor's Office Visits</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/Preventive Care@144x.png" alt="Preventive Care">Preventive Care</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/Diagnostics@144x.png" alt="Diagnostic Tests">Diagnostic Tests</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/Physical Theraphy@144x.png" alt="Physical Therapy">Physical Therapy</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/Medical Equipment@144x.png" alt="Medical Equipment">Medical Equipment</li>
                  </ul>
                </div>
                <div class="col-12 details-p row">
                  <ul>
                    <li><img src="./assets/img/common/plan-n-landing-page/Hospitalizations@144x.png" alt="Hospitalizations">Hospitalizations</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/outpatient procedures@144x.png" alt="Outpatient Procedures">Outpatient Procedures</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/Skilled Nursing@144x.png" alt="Skilled Nursing">Skilled Nursing</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/Surgeries@144x.png" alt="Surgeries">Surgeries</li>
                    <li><img src="./assets/img/common/plan-n-landing-page/Mental Health@144x.png" alt="Mental Health">Mental Health</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3 img-tile-box d-flex justify-content-center align-items-center justify-content-md-end mb-4 mb-md-0">
              <img class="flip-horizontal" src="assets/img/common/medigap/guy-in-a-jacket-crop.png" />
            </div>
          </div>
        </div>
      </div>

      <!--<div class="col-md-12 home-content-row-div mt-50 height-auto padding-20">
        <div class="container">
          <div
            class="row col-12 col-md-12 d-flex justify-content-center text-center text-md-left pr-md-5"
          >
            <div
              class="col-12 row justify-content-center table-coverage-content"
            >
              <div class="col-md-3 col-sm-10 row justify-content-center">
                <div class="col-12 center">
                  <label class="col fColor-white blue-bg"
                    ><b>PRIMARY<br />COVERAGE</b></label
                  >
                </div>
                <div class="col-12 center padding-10">
                  <label class="col">Original Medicare</label>
                  <div class="col">
                    <img
                      src="assets/img/retiree-dashboard/original-medicare-card.png"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-10 row justify-content-center">
                <div class="col-12 center">
                  <label class="col fColor-white blue-bg"
                    ><b>SECONDARY<br />COVERAGE</b></label
                  >
                </div>
                <div class="col-12 center padding-10">
                  <label class="col-12 row justify-content-center"
                    >Medicare<br />Supplement</label
                  >
                  <div class="col-12 row justify-content-center mt-20">
                    <div class="col-1">
                      <img
                        style="margin-left: -40px"
                        src="assets/img/retiree-dashboard/overview-coverage_box-plus.png"
                      />
                    </div>
                    <div class="col-10 center">
                      <img
                        src="assets/img/retiree-dashboard/overview-coverage_box-secondary.png"
                      /><br />
                      <span>MEDIGAP</span>
                    </div>
                    <div class="col-1">
                      <img
                        style="margin-right: -40px"
                        src="assets/img/retiree-dashboard/overview-coverage_box-plus.png"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-10 row justify-content-center">
                <div class="col-12 center">
                  <label class="col fColor-white blue-bg"
                    ><b>DRUG<br />COVERAGE</b></label
                  >
                </div>
                <div class="col-12 center padding-10">
                  <label class="col-12 row justify-content-center"
                    >Standalone<br />Part D Plan</label
                  >
                  <div class="col-12 row justify-content-center mt-20">
                    <div class="col-1">&nbsp;</div>
                    <div class="col-10 center">
                      <img
                        src="assets/img/retiree-dashboard/overview-coverage_box-drug.png"
                      /><br />
                      <span>MEDICARE PART<br />D DRUG PLAN</span>
                    </div>
                    <div class="col-1">
                      <img
                        style="margin-right: -40px"
                        src="assets/img/retiree-dashboard/overview-coverage_box-plus.png"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-10 row justify-content-center">
                <div class="col-12 center">
                  <label class="col fColor-white blue-bg"
                    ><b>DENTAL<br />COVERAGE</b></label
                  >
                </div>
                <div class="col-12 row center padding-10">
                  <label class="col-12 center"
                    >Individual<br />Dental Policy</label
                  >
                  <div class="col-12 center mt-20">
                    <img
                      src="assets/img/retiree-dashboard/overview-coverage_box-dental.png"
                    /><br />
                    <span>DENTAL<br />INSURANCE</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->

      <div class="col-md-12 home-content-row-div mt-50">
        <div class="container">
          <div class="col-md-12 home-content-row-div mt-20">
            <div class="container">
              <div class="row flex-column-reverse flex-md-row align-items-center">
                <div class="col-12 col-md-3 row img-tile-box2 d-flex justify-content-center align-items-center justify-content-md-end mb-4 mb-md-0">
                  <img class="col flip-horizontal d-none d-md-block d-lg-block d-xl-block"
                    src="assets/img/common/medigap/guy-in-blue-jacket-crop.png" />
                </div>
                <div class="row col-11 col-md-9 d-flex justify-content-center text-center text-md-left">
                  <div class="board-box">
                    <div class="col-12 details-p-board row justify-content-center">
                      <h5 class="center mb-4">
                        What Is Your Total Coverage Package<br />When You Have a
                        Medigap Plan?
                      </h5>
                    </div>
                    <div class="col-10 mx-auto details-p-board row justify-content-center">
                      <p>
                        Original Medicare (Part A and B) only covers Major-Medical
                        healthcare services. Therefore, after accounting for your
                        prescription drug coverage and your dental, vision, and
                        hearing, most Medicare beneficiaries end up with 4 pieces
                        to their coverage.
                      </p>
                    </div>
                    <div class="col-10 mx-auto details-p-board row justify-content-center">
                      <ul class="col-10">
                        <li>
                          <b>Piece 1:</b> Original Medicare as Your Primary for
                          Major-Medical
                        </li>
                        <li>
                          <b>Piece 2:</b> A Medigap Plan as Your Secondary for
                          Major-Medical
                        </li>
                        <li>
                          <b>Piece 3:</b> A "Standalone" Part D Plan as Your Drug
                          Coverage
                        </li>
                        <li>
                          <b>Piece 4:</b> An Individual Dental, Vision, Hearing
                          Policy
                        </li>
                      </ul>
                    </div>
                    <div class="col-10 mx-auto details-p-board row justify-content-center">
                      <p>
                        On this page we illustrate all 4 pieces of this coverage
                        structure in one easy to view "Coverage Package"
                      </p>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 home-content-row-div blue-bg mt-50">
        <div class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block" style="z-index: 1">
          <i class="fa fa-arrow-down font-blue"></i>
          <div class="banner-pointer-background inverse-colored-down-arrow"></div>
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-12 col-md-5 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
              <img class="flip-horizontal" src="assets/img/common/5-cat-woman.png" />
            </div>
            <div
              class="row col-12 col-md-7 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5">
              <h4 class="col-12 fColor-white text-center fWeight-500">
                5 Key Criteria For Choosing a Medigap Carrier:
              </h4>
              <table class="col-12 table-criteria mt-20">
                <tr>
                  <td class="first-col">Criteria 1</td>
                  <td class="second-col">Current Price Competitiveness</td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 2</td>
                  <td class="second-col">Track Record of Price Stability</td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 3</td>
                  <td class="second-col">
                    Financial Ratings and Overall Reputation
                  </td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 4</td>
                  <td class="second-col">
                    Quality and Convenience of Customer Service
                  </td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 5</td>
                  <td class="second-col">
                    Inclusion of Wellness and Fitness Membership
                  </td>
                </tr>
              </table>

              <div class="col-12 row justify-content-center mt-20">
                <button class="col-6 btn btn-first-plain padding-20 width-auto"
                  (click)="speakToCounselorEvent(speakToCounselorModal, 540)">
                  Speak With a Concierge Now
                </button>
              </div>
              <div class="col-12 row justify-content-center mt-20">
                <p class="col-7 fColor-white" style="font-size: 14px; text-align: center">
                  Or Scroll Below To Start Viewing Plans and Prices Now
                </p>
              </div>
              <div class="col-12 row">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>

      <div class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block">
        <i class="fa fa-arrow-down"></i>
        <div class="banner-pointer-background"></div>
      </div>

      <div class="col-md-12 home-content-row-div height-auto">
        <div class="container">
          <div class="row flex-column justify-content-center align-items-center">
            <div class="col-8 home-text-box center">
              <h4 class="center">
                What Does Your Total Coverage Package<br />With A Medigap Look
                Like?
              </h4>
            </div>
            <img src="./assets/img/common/icons/total-coverage-package.png" alt="Total Coverage Package" class="total-coverage-package-img">
            
            <h6 class="col-12 col-md-4 btn-type black">
              Option 1 Coverage Package Example
            </h6>

            <h4 class="col-md-8 col-sm-10 row justify-content-center col-sm-10 fColor-white btn-type blue">
                Example Total Premiums & Out-of-Pocket
            </h4>

            <!-- mobile -->
            <div class="col-10 row justify-content-center d-block d-md-none d-lg-none d-xl-none">
              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Original Medicare</label>
                <div class="col-12 center">
                  <img src="assets/img/retiree-dashboard/original-medicare-card.png" />
                </div>
                <div class="col-12 row justify-content-center border-bottom">
                  <span class="col mt-20">Premium</span>
                </div>
                <div class="col-12 row justify-content-center border-bottom">
                  <span class="col mt-20">Out-of-Pocket</span>
                </div>
              </div>

              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Medicare Supplement</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img
                      src="assets/img/retiree-dashboard/overview-coverage_box-secondary.png"/>
                  </div>
                  <span class="col-10 mt-20">MEDIGAP</span>
                  <span class="col-10 mt-20">MAJOR MEDICAL</span>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-green col mt-20">$100 - $150/month</div>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-blue col mt-20">$0 - $198/year</div>
                </div>
              </div>

              <div
                class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Standalone Part D Plan</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-drug.png"/>
                  </div>
                  <span class="col-10 mt-20">MEDICARE PART D DRUG PLAN</span>
                  <span class="col-10 mt-20">PRESCRIPTION DRUGS</span>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-green col mt-20">$15 - $25/month</div>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-blue col mt-20">$0 - $3,000</div>
                </div>
              </div>

              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Individual Dental Policy</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img
                      src="assets/img/retiree-dashboard/overview-coverage_box-dental.png"
                    />
                  </div>
                  <span class="col-10 mt-20">DENTAL INSURANCE</span>
                  <span class="col-10 mt-20">DENTAL</span>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer"
                >
                  <div class="label-green col mt-20">$25 - $35/month</div>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer"
                >
                  <div class="label-blue col mt-20">$0 - $1000/year</div>
                </div>
              </div>
            </div>

            <app-insurance-coverage-box highlighted="medigap" size="large" class="col-12 col-md-10"></app-insurance-coverage-box>	

            <div class="col-12 col-md-10 row justify-content-center table-coverage-footer">
              <div class="col-12 col-md-3 row center border-bottom border-right light-green">
                <span class="col-10" class="fColor-base">Premium</span>
              </div>
              <div class="col-12 col-md-3 row center border-bottom border-right light-green">
                <div class="label-green col-12 center">
                  $100 - $150/month
                </div>
              </div>
              <div class="col-12 col-md-3 row center border-bottom border-right light-green">
                <div class="label-green col-12 center">
                  $15 - $25/month
                </div>
              </div>
              <div class="col-12 col-md-3 row center border-bottom light-green">
                <div class="label-green col-12 center">
                  $25 - $35/month
                </div>
              </div>

              <div class="col-12 col-md-3 row center border-right light-blue">
                <span class="col-10">Out-of-Pocket</span>
              </div>
              <div class="col-12 col-md-3 row center border-right light-blue">
                <div class="label-blue col-12 center">
                  $0 - $198/year
                </div>
              </div>
              <div class="col-12 col-md-3 row center border-right light-blue">
                <div class="label-blue col-12 center">$0 - $3,000</div>
              </div>
              <div class="col-12 col-md-3 row center light-blue">
                <div class="label-blue col-12 center">
                  $0 - $1000/year
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <!-- hoi -->
      <div class="col-md-12 home-content-row-div mt-50 height-auto padding-20 d-none">
        <div class="container">
          <div class="row col-12 col-md-12 d-flex justify-content-center text-center text-md-left pr-md-5">
            <div class="col-8 home-text-box center">
              <h4 class="center">
                What Does Your Total Coverage Package<br />With A Medigap Look
                Like?
              </h4>
            </div>
            <div class="col-10 row justify-content-center table-coverage-header mt-50">
              <button class="col-md-6 col-sm-10">
                Option 1 Coverage Package Example
              </button>
              <h5 class="col-md-8 col-sm-10 row justify-content-center col-sm-10 fColor-white">
                Example Total Premiums & Out-of-Pocket
              </h5>
            </div>

            <!-- mobile -->
            <div class="col-10 row justify-content-center d-block d-md-none d-lg-none d-xl-none">
              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Original Medicare</label>
                <div class="col-12 center">
                  <img src="assets/img/retiree-dashboard/original-medicare-card.png" />
                </div>
                <div class="col-12 row justify-content-center border-bottom">
                  <span class="col mt-20">Premium</span>
                </div>
                <div class="col-12 row justify-content-center border-bottom">
                  <span class="col mt-20">Out-of-Pocket</span>
                </div>
              </div>

              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Medicare Supplement</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-secondary.png" />
                  </div>
                  <span class="col-10 mt-20">MEDIGAP</span>
                  <span class="col-10 mt-20">MAJOR MEDICAL</span>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-green col mt-20">$100 - $150/month</div>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-blue col mt-20">$0 - $198/year</div>
                </div>
              </div>

              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Standalone Part D Plan</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-drug.png" />
                  </div>
                  <span class="col-10 mt-20">MEDICARE PART D DRUG PLAN</span>
                  <span class="col-10 mt-20">PRESCRIPTION DRUGS</span>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-green col mt-20">$15 - $25/month</div>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-blue col mt-20">$0 - $3,000</div>
                </div>
              </div>

              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Individual Dental Policy</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-dental.png" />
                  </div>
                  <span class="col-10 mt-20">DENTAL INSURANCE</span>
                  <span class="col-10 mt-20">DENTAL</span>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-green col mt-20">$25 - $35/month</div>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-blue col mt-20">$0 - $1000/year</div>
                </div>
              </div>
            </div>

            <!-- desktop -->
            <div class="col-10 row justify-content-center d-none d-md-block d-lg-block d-xl-block">
              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-3 center">Original<br />Medicare</label>
                <label class="col-3 center">Medicare<br />Supplement</label>
                <label class="col-3 center border-right">Standalone<br />Part D Plan</label>
                <label class="col-3 center">Individual<br />Dental Policy</label>
              </div>
              <div class="col-12 row justify-content-center table-coverage-content">
                <div class="col-3 center">
                  <img src="assets/img/retiree-dashboard/original-medicare-card.png" />
                </div>
                <div class="col-3 row center">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-secondary.png" />
                  </div>
                  <div class="col-2 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-plus.png" />
                  </div>
                  <span class="col-10 mt-20">MEDIGAP</span>
                </div>
                <div class="col-3 row center border-right">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-drug.png" />
                  </div>
                  <div class="col-2 center">
                    <img style="margin-right: -40px"
                      src="assets/img/retiree-dashboard/overview-coverage_box-plus.png" />
                  </div>
                  <span class="col-10 mt-20">MEDICARE PART<br />D DRUG PLAN</span>
                </div>
                <div class="col-3 row center">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-dental.png" />
                  </div>
                  <span class="col-10 mt-20">DENTAL<br />INSURANCE</span>
                </div>

                <div class="col-3 row center">&nbsp;</div>
                <div class="col-3 row center border-top">
                  <span class="col-10 mt-20">MAJOR MEDICAL</span>
                </div>
                <div class="col-3 row center border-top border-right">
                  <span class="col-10 mt-20">PRESCRIPTION DRUGS</span>
                </div>
                <div class="col-3 row center border-top">
                  <span class="col-10 mt-20">DENTAL</span>
                </div>
              </div>
              <div
                class="col-12 row justify-content-center table-coverage-footer border-left border-right border-bottom">
                <div class="col-3 row center border-bottom">
                  <span class="col-10 mt-20">Premium</span>
                </div>
                <div class="col-3 row center border-bottom">
                  <div class="label-green col-12 center mt-20">
                    $100 - $150/month
                  </div>
                </div>
                <div class="col-3 row center border-bottom border-right">
                  <div class="label-green col-12 center mt-20">
                    $15 - $25/month
                  </div>
                </div>
                <div class="col-3 row center border-bottom">
                  <div class="label-green col-12 center mt-20">
                    $25 - $35/month
                  </div>
                </div>

                <div class="col-3 row center">
                  <span class="col-10 mt-20">Out-of-Pocket</span>
                </div>
                <div class="col-3 row center">
                  <div class="label-blue col-12 center mt-20">
                    $0 - $198/year
                  </div>
                </div>
                <div class="col-3 row center border-right">
                  <div class="label-blue col-12 center mt-20">$0 - $3,000</div>
                </div>
                <div class="col-3 row center">
                  <div class="label-blue col-12 center mt-20">
                    $0 - $1000/year
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- hoi -->

    </div>
  </div>
</section>

<div class="d-none">
  <div #videoContent>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <div
          class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true"
          style="height: 100%; position: relative; width: 100%"
        >
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            "
          >
            <img
              src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch"
              style="
                filter: blur(5px);
                height: 100%;
                object-fit: contain;
                width: 100%;
              "
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <span class="wistia_embed wistia_async_9rgfxy7pqm popover=true popoverContent=link" style="display:inline;position:relative"><a href="#">This is my popover!</a></span> -->

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>

<div class="d-none {{ dblocking }}" #speakToCounselorModal>
  <app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>
