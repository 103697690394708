import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MedicareAdvantageService {

    private monthlyPremium: any[];
    private additionalBenefits: any[];
    private outOfPocketCost: any[];
    private otherCategories: any[];
    private planType: any[];
    private networks: any[];
    private selectorType: any;
    private additionalPlanInsightsModal = new Subject<any>();

    constructor() {
    }

    setMonthlyPremium(value) {
        this.monthlyPremium = value;
    }
    setAdditionalBenefits(value) {
        this.additionalBenefits = value;
    }
    setOutOfPocketCost(value) {
        this.outOfPocketCost = value;
    }
    setOtherCategories(value) {
        this.otherCategories = value;
    }

    setPlanType(value) {
        this.planType = value;
    }


    setSelectorType(value) {
        this.selectorType = value;
    }


    setNetworks(value) {
        this.networks = value;
    }


    getMonthlyPremium() {
        return this.monthlyPremium;
    }
    getAdditionalBenefits() {
        return this.additionalBenefits;
    }
    getOutOfPocketCost() {
        return this.outOfPocketCost;
    }
    getOtherCategories() {
        return this.otherCategories;
    }

    getSelectorType() {
        return this.selectorType;
    }

    getPlanType() {
        return this.planType;
    }

    getNetworks() {
        return this.networks;
    }

    set_backToDefaultView_AdditionalPlanInsightsModal(): void {
        this.additionalPlanInsightsModal.next(true);
    }

    get_backToDefaultView_AdditionalPlanInsightsModal(): Observable<string> {
        return this.additionalPlanInsightsModal.asObservable();
    }


}
