import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorized-representative-question',
  templateUrl: './authorized-representative-question.component.html',
  styleUrls: ['./authorized-representative-question.component.scss']
})
export class AuthorizedRepresentativeQuestionComponent implements OnInit {

  relationship:any;
  authorizedRepresentativeLastName:any;

  constructor() { }

  ngOnInit() {
  }

  onChange_relationship(){}

}
