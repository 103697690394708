<app-header *ngIf="!header.isInteractive && !header.isNoHeader"></app-header>
<div class="app-main-content" [ngClass]="{'beneficiary-content': header.isRetireeDashboard, 'no-min-height': header.isNoMinHeight}">
<div [@routeAnimations]="prepareRoute(outlet)" >
    <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
</div>
</div>
<app-footer *ngIf="!header.isInteractive" class="noprint"></app-footer>

<div class="d-none">
<app-marketplace-widget-form #marketplaceWidgetForm></app-marketplace-widget-form>
<app-bennydash-widget-form #bennyDashWidgetForm></app-bennydash-widget-form>
</div>