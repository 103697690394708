import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-application-medigap-version1-screen16',
  templateUrl: './screen16.component.html',
  styleUrls: ['./screen16.component.scss']
})
export class Screen16Component implements OnInit {
  applicantStatementAgreeDate:any = ""
  disenrollment = false
  others = false

  dateFieldType: string;
  dateFieldHeading: string;

  constructor() { }

  ngOnInit() {
  }

  getSelected(name:string):void {
    // const radioClassInput = $(`input[type='radio'][name='selectedValue']:checked`).val();
    if (name === 'disenrollment') {
      this.disenrollment = true;
      this.others = false;
    } else if (name === 'others') {
      this.disenrollment = false;
      this.others = true;
    } else {
      this.disenrollment = false;
      this.others = false;
    }
  }

  onClickDob(calendar, type, heading){  
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

  onReceiveDate(dob: any){
    const idate = new Date(dob.date);
    const fdate = this.formatDate(idate);

    this.applicantStatementAgreeDate = dob.date;

    Swal.close();
  }

  formatDate(date) {
    return (this.padTo2Digits(date.getMonth()+1)) + '-' + this.padTo2Digits(date.getDate()) + '-' + date.getFullYear();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

}
