<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Primary Care Provider Selection (Optional)</h4>
    <div class="yellow-line"></div>
    <div>
      <p class="text-label">Would You Like To Specify Your Preferred Primary Care Doctor With The Plan?</p>
      <div class="py-4">
        <h5 class="text-center fw-semibold text-dblue">What Is The Name of Your Primary Care Doctor?</h5>
        <div *ngIf="!cantFindDoctor" class="row justify-content-center">
          <div class="col-12 col-md-6">
            <div class="form-group mb-1 p-2">
              <input type="text" class="form-control blurred" id="doctorSearch" placeholder="Search for your doctor here..."/>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="cantFindDoctor" [(ngModel)]="cantFindDoctor">    
            <label class="custom-control-label fWeight-500 uppercase" for="cantFindDoctor">I couldn't find my Doctor's Name</label>    
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cantFindDoctor" class="row">
      <div class="form-group col-12 col-md-4 p-2">
        <label for="dfname" class="text-label">Doctor's First Name</label>
        <input type="text" class="form-control blurred" id="dfname"/>
      </div>
      <div class="form-group col-12 col-md-4 p-2">
        <label for="dmname" class="text-label">Doctor's Middle Name</label>
        <input type="text" class="form-control blurred" id="dmname"/>
      </div>
      <div class="form-group col-12 col-md-4 p-2">
        <label for="dlname" class="text-label">Doctor's Last Name</label>
        <input type="text" class="form-control blurred" id="dlname"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="dnpi" class="text-label">Primary Care Doctor NPI</label>
        <input type="text" class="form-control blurred" id="dnpi"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="dlocation" class="text-label">Primary Care Doctor Practice Location</label>
        <input type="text" class="form-control blurred" id="dlocation"/>
      </div>
    </div>
    <div class="p-3">
      <h5 class="text-center fw-semibold text-dblue">
        Are You a Current Patient?
      </h5>
      <div class="d-flex justify-content-center">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="currentPatient_1" name="currentPatient" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-primary" for="currentPatient_1">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="currentPatient_2" name="currentPatient" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-primary" for="currentPatient_2">No</label>
        </div>
      </div>
    </div>
  </div>
</div>