<app-loading *ngIf="isLoading" class="loading-fixed"></app-loading>
<div class="row flex-column text-center">

      <ng-container *ngIf="viewDefault">
        <div class="dp-card" *ngIf="!isEditDrug"> <!--EDIT DRUG - hide search bar on editing drug-->
          <!-- <h4 class="text-capitalize">My Drug Names</h4>        -->
          <h4 class="text-capitalize dblue">Enter The Name of Your Drug Below</h4>       
          <form class="d-flex justify-content-center align-items-center" id="step1-form" [formGroup]="step1Form" (ngSubmit)="onSubmit($event)">
              <div class="form-group pt-2 m-0">
                <!-- <label for="drugname">Enter The Name of Your Drug Below</label> -->
                <div class="d-flex justify-content-center align-items-center">
                  <mat-form-field class="search" >
                    <input
                      type="text"
                      matInput
                      formControlName="drugname"
                      #searchFieldDrugname
                      [matAutocomplete]="searchFieldDrugnameAuto"
                      name="drugname"
                      id="drugname"
                      class="form-control blurred px-2"
                      (input)="fullnameValueChanged(searchFieldDrugname.value)"
                      maxlength = "100"                    
                    >
                    <mat-autocomplete #searchFieldDrugnameAuto="matAutocomplete">
                      <ng-container *ngFor="let option of results">
                        <mat-option (onSelectionChange)="updateMySelection(option)" [value]="option.name ? option.name : option.suggest ? option.suggest : ''">
                          <span>
                            {{option.name ? option.name : option.suggest ? option.suggest : ''}}                        
                          </span>
                        </mat-option>
                        <mat-option (onSelectionChange)="updateMySelection(option)" [value]="option.generic" *ngIf="option.generic" class="option-generic">
                          <span> 
                            <span class="turn-down-right-arrow">↳</span> {{option.generic}}
                          </span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  <button class="btn btn-first btn-small m-0 mx-2 mb-2">Submit</button>
                </div>
              </div>
          </form>        
        </div>
        
      </ng-container>

      
      <!-- <div class="dp-card" *ngIf="viewCommonListDrugs">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <img src="./assets/img/my-drugs/6-tablet-blue-bg.JPG" class="max-h-120 mr-3" alt="View The Most Common Drugs"> 
            <h4 class="fWeight-600">{{ drugTableName }}</h4>
          </div>
          <table class="table table-striped text-left">
            <thead>
              <tr>
                <th class="fWeight-500 fColor-blue">Rank</th>
                <th class="fWeight-500 fColor-blue">Drug Name</th>
                <th class="fWeight-500 fColor-blue">Total Prescriptions (2019)</th>
                <th class="fWeight-500 fColor-blue">Total Patients (2019)</th>
                <th class="fWeight-500 fColor-blue">Annual Change</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let drug of tagResults">
                <td>1</td>
                <td><a role="button" class="underlined fColor-blue fWeight-600 cursor-pointer pointer" (click)="viewNextStep_TypesMedication()">{{ drug.name }}</a></td>
                <td>112,104,359</td>
                <td>24,493,971</td>
                <td>0</td>
              </tr>              
            </tbody>
          </table>
          <button class="btn btn-third btn-small mx-auto mt-3" (click)="onClick_backToPrevView(drugTable_prev_view)">Back</button>
      </div>

      <div class="dp-card" *ngIf="viewTypesOfMedications">
        <div class="row types-of-medications">
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Blood Presure','blood_pressure')">
                <img src="./assets/img/my-drugs/bp.JPG">
                <span>Blood Pressure <i class="fa fa-info global-info-icon-first"></i></span>                 
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Cholesterol','cholesterol')">
                <img src="./assets/img/my-drugs/heart.JPG">
                <span>Cholesterol <i class="fa fa-info global-info-icon-first"></i></span>  
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Diabetes','diabetes')">
                <img src="./assets/img/my-drugs/cells.JPG">
                <span>Diabetes <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Lung & Respiratory','lung_and_respiratory')">
                <img src="./assets/img/my-drugs/lung.JPG">
                <span>Lung & Respiratory <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Mental Health','mental_health')">
                <img src="./assets/img/my-drugs/brain.JPG">
                <span>Mental Health <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Kidney Health','kidney_health')">
                <img src="./assets/img/my-drugs/kidney.JPG">
                <span>Kidney Health <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
        </div>
        <button class="btn btn-third btn-small mx-auto" (click)="onClick_ViewDefault()">Back</button>
      </div> -->
</div>

<!-- <div class="d-none">
  <div class="text-center" #otherWaysToAddYourDrugs>
    <h4>Other Ways To Add Your Drugs</h4>
    <div class="row mt-2">
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/upload-import.svg" alt="">
          <p class="mb-0 font-weight-bold">Upload or Import Your List</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/take-picture.svg" alt="">
          <p class="mb-0 font-weight-bold">Take A Picture of My Drug Bottles & Send Them</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/call-phone.svg" alt="">
          <p class="mb-0 font-weight-bold">Call Me To Provide Them Over The Phone</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/connect.svg" alt="">
          <p class="mb-0 font-weight-bold">Connect My Drug Record</p>
        </button>
      </div>
    </div>
  </div>
</div> -->