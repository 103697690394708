

<div class="modal-popupWrapper modal-apply modal-applyOnThePhone">
  <div class="row">
    <div class="col-md-8 d-flex modal-RightWrapper">
        <div class="modal-applyBox">
            <h4 class="mb-3 text-center px-4">TapTap Will Help You Enroll In This Plan</h4>
            <h5 class="fColor-base text-center fWeight-500">
              Call Us Now at <strong class="fColor-black fWeight-600">1(800)215-5108</strong> <br>
              For Guided Assistance From a Counselor
            </h5>
            <h5 class="fColor-base text-center fWeight-500">
              Below Is a Summary of <br>
              Your Plan Selection
            </h5>

            <div class="boxApply mt-5">
                <img onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';" src="{{ logo }}"
                    class="modal-companyLogo">

                <h5 class="mt-4 text-center uppercase" *ngIf="insuranceType == 'Medicare Supplement Plan' && (plan == 'Core Plan' || plan == 'Plan 1' || plan == 'Plan 1A' || plan == 'All Plans')"><span [innerHTML]="insuranceType.replace('Plan', '')" class="span-insurance-type"></span> {{ plan }}</h5>
                <h5 class="mt-4 text-center uppercase" *ngIf="insuranceType == 'Medicare Supplement Plan' && !(plan == 'Core Plan' || plan == 'Plan 1' || plan == 'Plan 1A' || plan == 'All Plans')">{{ insuranceType }} {{ plan }}</h5>
                <h5 class="mt-4 text-center uppercase" *ngIf="insuranceType != 'Medicare Supplement Plan'">{{ plan }}</h5>

                <table class="mt-3">
                    <tr [class.text-center]="insuranceType != 'Medicare Supplement Plan'">
                        <td>{{ planPremiumLabel }}:</td>
                        <td class="fWeight-600 fColor-blue" *ngIf="planPremium">{{ planPremium }}</td>
                        <td class="fWeight-600 fColor-blue" *ngIf="!planPremium">$0.00</td>
                    </tr>
                    <tr *ngIf="insuranceType == 'Medicare Supplement Plan'">
                        <td>One-Time Enrollment Fee:</td>
                        <td class="fWeight-600 fColor-blue" *ngIf="oneTimeEnrollmentFee != '' &&  insuranceType != 'Medicare Advantage Plan'">{{ oneTimeEnrollmentFee }}</td>
                        <td class="fWeight-600 fColor-blue" *ngIf="oneTimeEnrollmentFee == '' || insuranceType == 'Medicare Advantage Plan'">$0.00</td>
                    </tr>
                    <tr *ngIf="insuranceType == 'Medicare Supplement Plan'">
                        <td>Household Discount Included:</td>
                        <td class="fWeight-600 fColor-blue" *ngIf="householdDiscount">{{ householdDiscount | currency }}</td>
                        <td class="fWeight-600 fColor-blue" *ngIf="!householdDiscount">$0.00</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-4 modal-LeftImgWrapper">
        <img src="assets/img/common/old-woman-with-basket.png" alt="" *ngIf="insuranceType != 'Medicare Supplement Plan' && insuranceType != 'Medicare Part D Drug Plan' && insuranceType.indexOf('Life Insurance') == -1 && insuranceType != 'Medicare Advantage Plan' && insuranceType != 'Dental'">
        <img src="assets/img/common/old-woman-with-basket-medigap.png" alt="" *ngIf="insuranceType == 'Medicare Supplement Plan'">
        <img src="assets/img/common/old-woman-with-basket-mpdp.png" alt="" *ngIf="insuranceType == 'Medicare Part D Drug Plan'">
        <img src="assets/img/common/old-woman-with-basket-life-insurance.png" alt="" *ngIf="insuranceType.indexOf('Life Insurance') > -1">
        <img src="assets/img/common/old-woman-with-basket-mapd.png" alt="" *ngIf="insuranceType == 'Medicare Advantage Plan'">
        <img src="assets/img/common/old-woman-with-basket-dental.png" alt="" *ngIf="insuranceType == 'Dental'">
    </div>
  </div>

  <div class="modal-actionWrapper d-flex justify-content-around flex-wrap align-items-center">
    <div class="text-center">
      <a href="tel:18002155108" class="btn btn-third d-flex flex-column justify-content-center align-items-center">
        <p style="font-weight: 600;font-size: 1rem;margin: 0;" class="fColor-orange">Call Us Now</p> 1(800) 215-5108
      </a>
    </div>
    <p class="fColor-black fWeight-700 my-3 mb-sm-0">OR</p>
    <button class="btn btn-first" (click)="onClickScheduleTime()">Schedule a Call</button>
  </div>

  <!-- <div class="d-flex">
    <div class="d-flex modal-RightWrapper">
      <div class="modal-applyBox">
        <h3>TapTap Will Help You Enroll In This Plan
          Below is a Summary for you to Review: </h3>

        <div class="boxApply">
          <img onerror="this.src='/assets/img/no-image-available.jpg';" src="{{ logo }}" class="modal-companyLogo">

          <h4 class="mt-3">

            {{ insuranceType }}

            {{ plan }}</h4>

            <table class="table-bordered table-rating-summary">
              <tr>
                <td>Plan Premium:</td>
                <td style="font-weight:bold;">{{ planPremium }}</td>
              </tr>
              <tr>
                <td>One-Time Enrollment Fee:</td>
                <td style="font-weight:bold;" *ngIf="insuranceType == 'Medicare Part D Drug Plan' || insuranceType == 'Medicare Advantage Plan' ">$0.00</td>
                <td style="font-weight:bold;" *ngIf="insuranceType != 'Medicare Part D Drug Plan' && oneTimeEnrollmentFee != '' && insuranceType != 'Medicare Advantage Plan'">{{ oneTimeEnrollmentFee }}</td>
                <td style="font-weight:bold;" *ngIf="insuranceType != 'Medicare Part D Drug Plan' && oneTimeEnrollmentFee == '' ">$0.00</td>
              </tr>
              <tr *ngIf="householdDiscount">
                <td>Household Discount Included:</td>
                <td style="font-weight:bold;">

                  {{ householdDiscount }}</td>
              </tr>
            </table>

        </div>

        <div class="modal-actionWrapper d-flex justify-content-around flex-wrap align-items-end">
          <div class="text-center">
            <button class="btn btn-third d-flex flex-column justify-content-center align-items-center">
              <p style="font-weight: 600;font-size: 1rem;margin: 0;">Call Us Now</p> 1(800) 215-5108
            </button>
          </div>
          <p>OR</p>
          <button class="btn btn-first" (click)="onClickScheduleTime()">Schedule a Call
          </button>
        </div>
      </div>
    </div>
    <div class="modal-LeftImgWrapper">
      <img src="assets/img/common/old-woman-with-basket.png" alt=""
        *ngIf="insuranceType != 'Medicare Supplement Plan' && 
               insuranceType != 'Medicare Part D Drug Plan' && 
               !(insuranceType.indexOf('Life Insurance') > -1) &&
               insuranceType != 'Medicare Advantage Plan' ">
      <img src="assets/img/common/old-woman-with-basket-medigap.png" alt=""
        *ngIf="insuranceType == 'Medicare Supplement Plan'">
      <img src="assets/img/common/old-woman-with-basket-mpdp.png" alt=""
        *ngIf="insuranceType == 'Medicare Part D Drug Plan'">
      <img src="assets/img/common/old-woman-with-basket-life-insurance.png" alt=""
        *ngIf="insuranceType.indexOf('Life Insurance') > -1">
      <img src="assets/img/common/old-woman-with-basket-mapd.png" alt=""
        *ngIf="insuranceType.indexOf('Medicare Advantage Plan') > -1">
    </div>
  </div> -->
</div>