<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Contact Info</h4>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="email" class="text-label">Email Address</label>
        <input type="text" class="form-control blurred" id="email"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="phone" class="text-label">Phone Number</label>
        <input type="text" class="form-control blurred" id="phone"/>
      </div>
      <div class="form-group d-flex justify-content-end col-12 col-md-12 p-2">
        <h6 class="mr-2">Is This The Policy Insured’s Phone?</h6>
        <div class="form-check mr-2">
          <input class="form-check-input" type="radio" name="insuredPhone" id="insuredPhone">
          <label class="form-check-label" for="insuredPhone">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="notInsured" id="notInsured" checked>
          <label class="form-check-label" for="notInsured">
            No
          </label>
        </div>
      </div>
      <div class="form-group col-12 col-md-9 p-2">
        <label for="address" class="text-label">Address</label>
        <input type="text" class="form-control blurred" id="address"/>
      </div>
      <div class="form-group col-12 col-md-3 p-2">
        <label for="aptUnit" class="text-label">Apt/Unit #</label>
        <input type="text" class="form-control blurred" id="aptUnit"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="city" class="text-label">City</label>
        <input type="text" class="form-control blurred" id="city"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="state" class="text-label">State</label>
        <input type="text" class="form-control blurred" id="state"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="zipCode" class="text-label">Zip Code</label>
        <input type="text" class="form-control blurred" id="zipCode"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="county" class="text-label">County</label>
        <input type="text" class="form-control blurred" id="county"/>
      </div>
    </div>
    <div class="p-2">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="thisIsMyMailing" [(ngModel)]="thisIsMyMailing">    
        <label class="custom-control-label fWeight-500 color-primary uppercase" for="thisIsMyMailing">This Is My Mailing Address As Well</label>    
      </div>
    </div>
  </div>
  <br>
  <div *ngIf="!thisIsMyMailing">
    <h4 class="fw-semibold text-dblue">My Mailing Address</h4>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="email2" class="text-label">Email Address</label>
        <input type="text" class="form-control blurred" id="email2"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="phone2" class="text-label">Phone Number</label>
        <input type="text" class="form-control blurred" id="phone2"/>
      </div>
      <div class="form-group d-flex justify-content-end col-12 col-md-12 p-2">
        <h6 class="mr-2">Is This The Policy Insured’s Phone?</h6>
        <div class="form-check mr-2">
          <input class="form-check-input" type="radio" name="mailingInsuredPhone" id="mailingInsuredPhone1">
          <label class="form-check-label" for="mailingInsuredPhone1">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="mailingNotInsuredPhone" id="mailingNotInsuredPhone1" checked>
          <label class="form-check-label" for="mailingNotInsuredPhone1">
            No
          </label>
        </div>
      </div>
      <div class="form-group col-12 col-md-9 p-2">
        <label for="address2" class="text-label">Address</label>
        <input type="text" class="form-control blurred" id="address2"/>
      </div>
      <div class="form-group col-12 col-md-3 p-2">
        <label for="aptUnit2" class="text-label">Apt/Unit #</label>
        <input type="text" class="form-control blurred" id="aptUnit2"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="city2" class="text-label">City</label>
        <input type="text" class="form-control blurred" id="city2"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="state2" class="text-label">State</label>
        <input type="text" class="form-control blurred" id="state2"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="zipCode2" class="text-label">Zip Code</label>
        <input type="text" class="form-control blurred" id="zipCode2"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="county2" class="text-label">County</label>
        <input type="text" class="form-control blurred" id="county2"/>
      </div>
    </div>
  </div>
</div>