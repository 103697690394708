<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Authorization Form For Disclosure</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <div class="dashed-box border shadow p-4">
        <h4 class="text-center fw-semibold">Please Read and Sign.</h4>
        <div>
          <p class="text-label">By completing this form, I agree to the following:</p>
          <div class="p-2">
            <div class="">
              <h6 class="d-flex justify-content-center text-center">Authorization Form For Disclosure Of A Consumer’s</h6>
              <h6 class="d-flex justify-content-center fw-semibold text-center">Protected Health Information For Marketing Purposes</h6>
              <span class="d-flex justify-content-center">("Authorization")</span>
            </div>
          </div>
          <div class="p-2">
            <ol>
              <li>
                I hereby authorize the use and disclosure of all my health information, including but not limited to my personal and medical information contained in the Company’s records (“Protected Health Information”) to <b>[Insurance Carrier NAIC Name]</b>, and it’s affiliates (“Company”) as described below.
              </li>
              <li>
                I authorize the Company to use the Protected Health Information contained in the Company’s records, including its underwriting and claim records, to help determine whether I might be interested in or can benefit from other non-health-related insurance products offered by the Company.
              </li>
              <li>
                I understand that the Company will disclose the Protected Health Information to its underwriting staff, new business staff, sales agents, or marketing management for the purpose of marketing non-health-related products to me.
              </li>
              <li>
                I understand that I may revoke this Authorization at any time, except to the extent that action has been taken by the Company in reliance on this Authorization, by sending a written revocation to the Company’s Privacy Steward at <b>[Insurance Carrier NAIC Address]</b>
              </li>
              <li>
                I understand that the Protected Health Information which the Company will use and disclose under this Authorization is not necessary for the Company to determine my eligibility for coverage under the policy and that the Company will not condition its approval and issuance of the policy on my providing this Authorization.
              </li>
              <li>
                I understand that if the person or entity that receives my Protected Health Information is not a health care provider or health plan covered by the federal privacy regulations, the information may be redisclosed by such person or entity and will likely no longer be protected by the federal privacy regulations. 
              </li>
              <li>
                I understand that a photocopy, facsimile copy, or other electronic copy of this Authorization is as effective and valid as the original. I also understand that I or my personal representative am entitled to receive a copy of this Authorization. This Authorization will expire twenty-four (24) months from the date it is signed.
              </li>
            </ol>
          </div>
        </div>
        <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" [(ngModel)]="agreedAuthorizationDate" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="iAgree13">    
              <label class="custom-control-label fw-semibold text-dblue" for="iAgree13">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>