<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-9">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">
						<span>TapTap Medicare Is All About Solutions.</span><br><br>
						Built To Serve Your Medicare Experience.
					</h1>
					<p class="banner-sub-title">TapTap Medicare is a part of the BrightPrice Group, an organization dedicated to improving financial clarity and cost efficiency for Americans, specifically those in or nearing retirement. 
					</p>
					<button routerLink="/contact" class="btn btn-first mt-2 mx-auto mx-md-0">Connect With Us</button>
				</div>
			</div>
			<div class="col-md-3 px-0 banner-image-wrapper">
				<img src="assets/img/about/Woman-in-Black-Dress-Cheersing.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<section class="main-content-wrapper about-wrapper">
	<div class="container">
		<!-- top part -->
		<div class="row">
			<div class="col-md-6 px-0 d-flex align-items-md-center justify-content-center about-img-wrapper">
				<img src="assets/img/about/insurance-planning.png" alt="about us image">
			</div>
			<div class="col-md-6 text-wrapper pr-md-0">
				<h2>
					Insurance Planning is Important
				</h2>
				<p>
					It can create or take away from real life peace 
					and prosperity for yourself and your family.
				</p>
				<p>
					We believe that the insurance experience for Medicare 
					beneficiaries has not been acceptable. It is riddled with 
					conflicts of interests, bombardments of advertisements, 
					and extreme overpaying on identical insurance coverages 
					– and this is all at a time when the budgets of older 
					Americans have been squeezed like never before.
				</p>
			</div>
		</div>
		<div class="row d-flex flex-column-reverse flex-md-row">
			<div class="col-md-6 text-wrapper pl-md-0">
				<h2>
					We Knew You
					Wanted Better
				</h2>
				<p>
					We wanted better for all of us. Older Americans 
					deserved better.
				</p>
				<p>
					Our team is truly and inspired by the services 
					and real life impact we bring to life for 
					consumers. Our company is for you. It’s 
					important. It's your insurance - clear, efficient,
					and organized.
				</p>
			</div>
			<div class="col-md-6 px-0 d-flex align-items-md-center justify-content-center about-img-wrapper">
				<img src="assets/img/about/we-knew-you-wanted-better.png" alt="about us Mission image">
			</div>
		</div>

		<!-- bottom part -->
		<div class="row">
			<div class="col-md-12 text-center my-5">
				<h2 class="fWeight-600 color-secondary">The Mission of TapTap Medicare is Simple:</h2>
				<h4>
					To Create a Better Medicare Experience <br>
					for Medicare Beneficiaries.
				</h4>
			</div>
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-6 px-0 d-flex align-items-md-center justify-content-center about-img-wrapper">
						<img src="assets/img/about/medicare-experience.png" alt="about us image">
					</div>
					<div class="col-md-6 text-wrapper pr-md-0">
						<p>
							To accomplish this, we started at the only  
							reasonable place: Understanding the current
							Medicare experience that beneﬁciaries deal
							with and are faced with.
						</p>
						<p>
							For most, the experience they’ve received has 
							been unacceptable. A lack of clarity, confusion, 
							rising costs, unanswered questions, no 
							proactive support and management, and a 
							general overload of information that is never 
							put into clear, concise, and consumer friendly 
							terms.
						</p>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="row d-flex flex-column-reverse flex-md-row">
					<div class="col-md-6 text-wrapper pl-md-0">
						<p class="fWeight-700">
							What's more, is that this stuff is important -
							this is your healthcare in retirement!
						</p>
						<p>
							For something as important as your healthcare 
							in retirement, you deserve a clear, organized, 
							cost efficient, and convenient service to ensure
							you have exactly the healthcare coverage 
							experience you desire.
						</p>
					</div>
					<div class="col-md-6 px-0 d-flex align-items-md-center justify-content-center about-img-wrapper">
						<img src="assets/img/about/healthcare-retirement.png" alt="about us Mission image">
					</div>
				</div>
			</div>

			<div class="col-md-12 text-center">
				<button class="btn btn-first mx-auto" (click)="watchVideo(videoContent)">Watch Video</button>
			</div>
		</div>	

	</div>
</section>
<app-new-to-medicare headingTitle="Need Some Clarity About Medicare?"></app-new-to-medicare>
<app-testimonials></app-testimonials>
<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>


<!---------------------------------------------------------
---------------------VIDEO MODAL CONTENT-------------------	
----------------------------------------------------------->
<div class="d-none">
	<div #videoContent>
		<video width="100%" height="auto" controls>
			<source src="./assets/videos/TaptapMedicare Aboutus Short.mp4" type="video/mp4">
			Your browser does not support the video tag.
		</video>
	</div>
</div>