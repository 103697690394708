<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Household Enrollment</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <div class="dashed-box p-3 mt-4">
        <h6 class="text-label fw-semibold">Are You Applying For This Medicare Supplement Plan With Anyone Else In Your Household?</h6>
        <div class="row pt-2">
          <div class="col-12">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="enrollmentStatus_1" name="enrollmentStatus" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_1">No, I Am Not Applying With Anyone Else</label>
            </div>
          </div>
          <div class="col-12">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="enrollmentStatus_2" name="enrollmentStatus" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_2">Yes, I Am Applying With My Spouse</label>
            </div>
          </div>
          <div class="col-12">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="enrollmentStatus_3" name="enrollmentStatus" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_3">Yes, I Am Applying With A Household Member Other Than My Spouse</label>
            </div>
          </div>
        </div>
      </div>

      <div class="dashed-box p-3 mt-4">
        <h6 class="text-label fw-semibold">What Is Your Household Member's Relationship To You?</h6>
        <div class="row pt-2">
          <div class="col-6">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="pRelationship_1" name="pRelationship" class="custom-control-input">
              <label class="custom-control-label" for="pRelationship_1">Spouse</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="pRelationship_2" name="pRelationship" class="custom-control-input">
              <label class="custom-control-label" for="pRelationship_2">Significant Other</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="pRelationship_3" name="pRelationship" class="custom-control-input">
              <label class="custom-control-label" for="pRelationship_3">Sibling</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="pRelationship_4" name="pRelationship" class="custom-control-input">
              <label class="custom-control-label" for="pRelationship_4">Parent</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="pRelationship_5" name="pRelationship" class="custom-control-input">
              <label class="custom-control-label" for="pRelationship_5">Child</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="pRelationship_6" name="pRelationship" class="custom-control-input">
              <label class="custom-control-label" for="pRelationship_6">Cousin</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="pRelationship_7" name="pRelationship" class="custom-control-input">
              <label class="custom-control-label" for="pRelationship_7">Friend</label>
            </div>
          </div>
        </div>
      </div>

      <div class="dashed-box p-3 mt-4">
        <h6 class="text-label fw-semibold">Would You Like To Apply For Both Of You On This Same Application?</h6>
        <div class="row pt-2">
          <div class="col-12">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="bothApp_1" name="enrollmentStatus" (click)="noteModal(applyBoth)" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-dblue" for="bothApp_1">Yes</label>
            </div>
          </div>
          <div class="col-12">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="bothApp_2" name="enrollmentStatus" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-dblue" for="bothApp_2">No, Send Them A Link To Complete Themselves</label>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>

  <div #applyBoth>
    <p class="text-center fw-semibold text-dblue mb-2">
      Okay, Great. For Your Convenience, The Entire First Set of Questions Asked Will Be 
      For You As The Primary Applicant, & Then We Will Proceed To Any Remaining Questions 
      For Your Household Member Applying With You Once You Have Completed Your Full Portion.
    </p>
    <div class="mt-3 d-flex justify-content-center">
      <button class="btn btn-first btn-small gray-button mx-2" (click)="closeModal()">Continue</button>
    </div>
  </div>

</div>