<div class="company-profile-wrapper">
    <div class="d-none d-sm-block ribbon-wrapper" *ngIf="planIdentifier === 'medigap' && profilePage === 1">
        <div class="top-ribbon">Top Carrier</div>
    </div>
    <div class="d-block d-sm-none ribbon-wrapper2">
        <div class="top-ribbon">Top Carrier</div>
    </div>
    
    <h4 class="uppercase text-center mb-2 mb-sm-4 pt-5 pt-sm-0" (click)="consoleLog()">{{ profilePage === 1 ? 'Company Profile' : 'Additional Company Insights' }}</h4>
    <div class="row justify-content-center" *ngIf="profilePage === 1">
        <div class="col-12 col-md-6">
            <div class="logo-wrapper">
                <p class="found-year ml-auto" *ngIf="yearFounded">Founded in <span class="bold">{{ yearFounded }}</span></p>
                <div class="d-flex justify-content-center">
                    <img *ngIf="marketplaceType != 'medigap'" 
                          class="carrier-img" 
                          src="{{'./assets/img/insurance-logo/'+ taptapDetails.naic +'.png'}}" 
                          alt="{{ taptapDetails.plan_name }}">
                    <img *ngIf="marketplaceType == 'medigap'" 
                          class="carrier-img" 
                          src="{{ image }}" 
                          alt="{{ carrierName }}">
                </div>
            </div>
        </div>
    </div>

    <div class="my-3" *ngIf="profilePage === 1">
        <h5 class="modal-head">Company Bio</h5>
        <div class="yellow-line"></div>
        <p class="bio" [innerHTML]="companyBio" *ngIf="companyBio"></p>
        <p class="bio" *ngIf="!companyBio">Information Coming soon</p>
        <!-- <p class="bio" *ngIf="taptapDetails.taptap_carrier_profile != '' || taptapDetails.taptap_carrier_profile != null">
            {{ taptapDetails.taptap_carrier_profile }}
        </p>
        <p class="bio" *ngIf="taptapDetails.taptap_carrier_profile == '' || taptapDetails.taptap_carrier_profile == null">
            Information coming soon
        </p> -->
        <button class="btn btn-first btn-small mx-auto mt-3" *ngIf="companyBrochure">
            View Company Brochure
        </button>
    </div>

    <div class="my-3" *ngIf="planIdentifier === 'medigap'">
        <div *ngIf="profilePage === 2">
            <h5 class="modal-head">Ratings</h5>
            <div class="yellow-line"></div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-3 p-2">
                    <div class="ratings-head-wrapper">
                        <h1 class="ratings-head2" *ngIf="amBestFinancialRating">{{ amBestFinancialRating }}</h1>
                        <h1 class="ratings-head2" *ngIf="!amBestFinancialRating">N/A</h1>
                    </div>
                    <p class="ratings-label">AM Best Financial Rating</p>
                </div>
                <div class="col-12 col-md-3 p-2">
                    <div class="ratings-head-wrapper">
                        <h1 class="ratings-head2" *ngIf="amBestOutlook">{{ amBestOutlook }}</h1>
                        <h1 class="ratings-head2" *ngIf="!amBestOutlook">N/A</h1>
                    </div>
                    <p class="ratings-label">AM Best Outlook</p>
                </div>
                <div class="col-12 col-md-3 p-2">
                    <div class="ratings-head-wrapper">
                        <h1 class="ratings-head2" *ngIf="spFinancialRating">{{ spFinancialRating }}</h1>
                        <h1 class="ratings-head2" *ngIf="!spFinancialRating">N/A</h1>
                    </div>
                    <p class="ratings-label">S&P's Financial rating</p>
                </div>
                <div class="col-12 col-md-3 p-2">
                    <div class="ratings-head-wrapper">
                        <h1 class="ratings-head2" *ngIf="spOutlook">{{ spOutlook }}</h1>
                        <h1 class="ratings-head2" *ngIf="!spOutlook">N/A</h1>
                    </div>
                    <p class="ratings-label">S&P's Outlook</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="planIdentifier === 'medigap' && profilePage === 2">
            <button class="btn btn-first btn-small bg-bblue mx-2" (click)="profilePage = 1">Back</button>
        </div>
        <div class="row justify-content-center" *ngIf="planIdentifier === 'medigap' && profilePage === 1">
            <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first" (click)="profilePage = 2">Additional Company Insights</button>
        </div>
    </div>

    <div class="my-3" *ngIf="planIdentifier !== 'medigap'">
        <h5 class="modal-head">Ratings</h5>
        <div class="yellow-line"></div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-3 p-2">
                <div class="ratings-head-wrapper">
                    <h1 class="ratings-head2" *ngIf="amBestFinancialRating">{{ amBestFinancialRating }}</h1>
                    <h1 class="ratings-head2" *ngIf="!amBestFinancialRating">N/A</h1>
                </div>
                <p class="ratings-label">AM Best Financial Rating</p>
            </div>
            <div class="col-12 col-md-3 p-2">
                <div class="ratings-head-wrapper">
                    <h1 class="ratings-head2" *ngIf="amBestOutlook">{{ amBestOutlook }}</h1>
                    <h1 class="ratings-head2" *ngIf="!amBestOutlook">N/A</h1>
                </div>
                <p class="ratings-label">AM Best Outlook</p>
            </div>
            <div class="col-12 col-md-3 p-2">
                <div class="ratings-head-wrapper">
                    <h1 class="ratings-head2" *ngIf="spFinancialRating">{{ spFinancialRating }}</h1>
                    <h1 class="ratings-head2" *ngIf="!spFinancialRating">N/A</h1>
                </div>
                <p class="ratings-label">S&P's Financial rating</p>
            </div>
            <div class="col-12 col-md-3 p-2">
                <div class="ratings-head-wrapper">
                    <h1 class="ratings-head2" *ngIf="spOutlook">{{ spOutlook }}</h1>
                    <h1 class="ratings-head2" *ngIf="!spOutlook">N/A</h1>
                </div>
                <p class="ratings-label">S&P's Outlook</p>
            </div>
        </div>
    </div>

</div>



<!-- <div class="company-profile">
    <div class="company-profile-top">
        <img src="./assets/img/common/icons/Asset 5xxxhdpi1.png" alt="Taptap Carrier Profile">
        <h6>Carrier Profile:</h6>
    </div>
    <div class="company-profile-bottom" *ngIf="taptapDetails.taptap_carrier_profile != '' || taptapDetails.taptap_carrier_profile != null">
        {{ taptapDetails.taptap_carrier_profile }}
    </div>
    <div class="company-profile-bottom" *ngIf="taptapDetails.taptap_carrier_profile == '' || taptapDetails.taptap_carrier_profile == null">
        Information coming soon
    </div>
</div>

<div class="company-profile">
    <div class="company-profile-top">
        <img src="./assets/img/common/icons/Asset 4xxxhdpi1.png" alt="Taptap Carrier Brochure">
        <h6>Carrier Brochure:</h6>
    </div>
    <div class="company-profile-bottom" *ngIf="taptapDetails.taptap_carrier_brochure != '' || taptapDetails.taptap_carrier_brochure != null">
        {{ taptapDetails.taptap_carrier_brochure }}
    </div>
    <div class="company-profile-bottom" *ngIf="taptapDetails.taptap_carrier_brochure == '' || taptapDetails.taptap_carrier_brochure == null">
        Information coming soon
    </div>
</div>

<div class="company-profile mb-0">
    <div class="company-profile-top">
        <img src="./assets/img/common/icons/Asset 3xxxhdpi1.png" alt="Taptap Review">
        <h6>TapTap Review:</h6>
    </div>
    <div class="company-profile-bottom" *ngIf="taptapDetails.taptap_review != '' || taptapDetails.taptap_review != null">
        {{ taptapDetails.taptap_review }}
    </div>
    <div class="company-profile-bottom" *ngIf="taptapDetails.taptap_review == '' || taptapDetails.taptap_review == null">
        Information coming soon
    </div>
</div> -->