import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-submit-a-review-new',
  templateUrl: './submit-a-review-new.component.html',
  styleUrls: ['./submit-a-review-new.component.scss']
})
export class SubmitAReviewNewComponent implements OnInit {
  @Input() pdsCode: any;
  @Input() type: any;

  insurance_total_score:number = 4; 
  customer_service_score:number = 0;
  consumer_clarity_score:number = 0;
  price_stability_score:number = 0; 
  overall_score:number = 0;
  experience_score:number = 0;

  insuranceType:string =  'Medigap';

  isLoggedIn:boolean = false;

  message_invalid:boolean = false;

  showDefaultView:boolean = true;
  showMessageView: boolean = false;
  showVerifyReview: boolean = false;
  showLoginSection:boolean = false;
  showThankYou: boolean = false;
  showExperienceRating:boolean = false;

  toLoginBp: boolean = false;
  toLoginBpMode: number;
  
  fileAttr: any;

  nextBtnClicked: boolean = false;

  star_rating_valid: boolean = true;

  submitReviewFirstBtnClicked: boolean = false;

  submitReviewSecondBtnClicked: boolean = false;

  messageFormControl = new FormControl('', [
      Validators.required
  ])

  verifyFullNameFormControl= new FormControl('', [
    Validators.required
  ])

  verifyEmailFormControl= new FormControl('', [
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
  ])

  constructor() { }

  ngOnInit() {
  }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    captcha: new FormControl('', [Validators.requiredTrue])
  });

  starClick(type, ratingNumber){

    if(type == 'service'){
      this.customer_service_score = ratingNumber;
    }

    if(type == 'clarity'){
      this.consumer_clarity_score = ratingNumber;
    }

    if(type == 'stability'){
      this.price_stability_score = ratingNumber;
    }

    if(type == 'overall'){
      this.overall_score = ratingNumber;
    }

    if(type == 'experience'){
      this.experience_score = ratingNumber;
    }

    this.star_rating_valid = true;
    
  }

  next(){

    this.nextBtnClicked = true;

    if(  this.customer_service_score > 0 ||
         this.consumer_clarity_score > 0 ||
         this.price_stability_score > 0 ||
         this.overall_score > 0 ){
            
            this.showMessageView = true;
            this.showDefaultView = false;
            this.nextBtnClicked = false;
            this.star_rating_valid = true;
    }else{
            this.star_rating_valid = false;
    }
  }

  back_second(){
    this.showMessageView = false;
    this.showDefaultView = true;
    this.submitReviewFirstBtnClicked = false;
  }

  submit_review_first(){
    this.submitReviewFirstBtnClicked = true;
    if( this.messageFormControl.valid ){
      if(this.isLoggedIn){
        this.showThankYou = true;
      }else{
        this.showVerifyReview = true;
        this.showLoginSection = true
      }
      this.submitReviewFirstBtnClicked = false;
    }
  }

  submit_review_second(){
    this.submitReviewSecondBtnClicked = true;

    if ( this.verifyFullNameFormControl.valid &&  this.verifyEmailFormControl.valid ){
      this.showThankYou = true;    
      this.showVerifyReview = false;
      this.submitReviewSecondBtnClicked = false;
    }

  }
  
  submit_experience_score(){
    Swal.close();
    this.resetter();
  }
  
  pleaseEmail(){
    Swal.close();
    this.resetter();
  }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
      });

    } else {
      this.fileAttr = 'Choose File';
    }
  }

  yesLetsDoIt(){
    this.showExperienceRating = true;
  }

  resetter(){
    this.showDefaultView = true;
    this.showMessageView = false;
    this.showVerifyReview = false;
    this.showLoginSection = false;
    this.showThankYou = false;
    this.showExperienceRating = false;

    this.customer_service_score = 0;
    this.consumer_clarity_score = 0;
    this.price_stability_score = 0; 
    this.overall_score = 0;
    this.experience_score = 0;
  }

}
