<div class="container-fluid header-banner-wrapper access-forms">
  <app-header-b></app-header-b>
  <div class="container px-0">
  <div class="row">
    <div class="col-md-5 p-0 form-character-wrapper">
      <h4 class="text-center fWeight-600 fColor-white mt-4">
        <span class="helloTapTapTeamSpan">Hello From The TapTap Team! </span>
          I can help you update your password. Ready to go?</h4>
      <img src="./assets/img/registration/woman-high-5-violet.png" alt="Sign Up Image" class="signup-img">
    </div>
    <div class="col-md-7 box-wrapper">
      <div class="primary-box">
        <div class="box-top">
            <p *ngIf="success" class="alert alert-success">
                Password successfully reset.
            </p>
            <p *ngIf="!isValid" class="alert alert-danger">
                The given data was invalid
            </p>

          <p *ngIf="email.invalid && email.errors['required'] && submitIsClicked" class="alert alert-danger">
              Email is required
          </p>
          <p *ngIf="password.invalid && password.errors['required'] && submitIsClicked" class="alert alert-danger">
              Password is required
          </p>
          <p *ngIf="c_password.invalid && c_password.errors['required'] && submitIsClicked" class="alert alert-danger">
              Confirm Password is required
          </p>
          <p *ngIf="taptapRecaptcha.invalid && submitIsClicked" class="alert alert-danger">
              Please verify you're a human
          </p>
          <h4 class="fColor-base fWeight-600">Reset your Password</h4>
          <form [formGroup]="updatePasswordForm">
              <div class="form-group">
                  <label for="email">Email<sup>*</sup></label>
                  <input type="email" class="form-control" name="email" id="email" formControlName="email" required />
              </div>
              <div class="form-group">
                  <label for="password">Password<sup>*</sup></label>
                  <input type="password" class="form-control" name="password" id="password" formControlName="password" required />
              </div>
              <div class="form-group">
                  <label for="password">Confirm Password<sup>*</sup></label>
                  <input type="password" class="form-control" name="c_password" id="c_password" formControlName="c_password" required />
              </div>
            
              <div class="d-flex justify-content-around align-items-center flex-wrap mb-3">
                  <re-captcha
                      [formControl]="getTapTapRecaptcha"
                      class="recaptcha-wrapper my-2"
                  ></re-captcha>
                  <button type="submit" class="btn btn-first"  (click)="update()">Update Now</button>
              </div>
          </form>
      </div>
        <div class="box-bottom d-flex justify-content-between align-items-center">
            <a routerLink="/login" class="capitalize"><h6 class="fWeight-600">Back to Login Form</h6></a>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
