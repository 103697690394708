import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Screen7Service {

  private medigapHealthConditionURLFile = "../../../../../../../assets/docs/MedigapHealthCondition.json";

  constructor(private http: HttpClient) { }

  getMedigapHealthConditionFileFromServer() {
    let data = new Promise((resolve, reject) => {
      this.http.get(this.medigapHealthConditionURLFile)
        .toPromise()
        .then(
          res => {
            resolve(res)
          }
        )
    })
    return data;
  }

}
