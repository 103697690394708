import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-beneficiary-dashboard-navigation',
  templateUrl: './beneficiary-dashboard-navigation.component.html',
  styleUrls: ['./beneficiary-dashboard-navigation.component.scss']
})
export class BeneficiaryDashboardNavigationComponent implements OnInit {
  setActiveNav:boolean = false;
  hoverState: boolean = false;
  setActiveNavGuides:boolean = false;

  constructor(public _auth: AuthService,private elRef: ElementRef) { }
  ngOnInit() {
    //  console.log(this.elRef.nativeElement.parentElement(''));
      //this.router.navigate(['login']);
  }

  scrollToView(viewId){
    document.getElementById(viewId).scrollIntoView();
  }

  navHoverOver(event){
    console.log(event);
    jQuery(event.target).parent().parent().addClass('li-active');
  }

  navHoverOut(event){
    jQuery(event.target).parent().parent().removeClass('li-active');
  }

  // navClick(type){
  //     if(type=='my-guides-education'){
  //       this.setActiveNavGuides = true;
  //     }
      
  //     this.setActiveNav = true;
  // }


  onLogout() {
      this._auth.logUserOut();
  }

  findParentActive(event){
    console.log('finding parents ......');
    let xx = event.target;
    $(xx).parents('.top-level').addClass('active'); 
    console.log(event.target);
  }

  
}
