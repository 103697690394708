import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderDetailType } from './provider-detail-type';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.scss']
})
export class ProviderDetailComponent implements OnInit {
  provider_detail:ProviderDetailType = {
    address: '',
    other_prefix: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    suffix: '',
    credential_text: '',
  }

  inNetwork = true;
  outNetwork = false;
  notAtThisLocation = false;
  undetermined = false;
  ppo = false;

  networkSelection = 1;
  networkSelectionPlans = [];

  currentPage = 1;
  itemsPerPage = 5;

  plans = [
    {
      name: "Allwell 1",
      network_status: 2,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 2",
      network_status: 2,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 3",
      network_status: 2,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 4",
      network_status: 2,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 5",
      network_status: 3,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 6",
      network_status: 3,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 7",
      network_status: 3,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 8",
      network_status: 4,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 9",
      network_status: 4,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 10",
      network_status: 5,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 11",
      network_status: 5,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 12",
      network_status: 5,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 13",
      network_status: 6,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 14",
      network_status: 6,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 15",
      network_status: 7,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 16",
      network_status: 7,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    },
    {
      name: "Allwell 17",
      network_status: 7,
      logo_url: "./assets/img/home/company-logo-display/allwell-logo.png"
    }
  ]

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUrlData();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.setNetworkPlansData(1);
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  /**
   * Assign url data to object.
   * @returns void
   */
  getUrlData():void {
    this.provider_detail.address = this.route.snapshot.paramMap.get("address");
    this.provider_detail.other_prefix = this.route.snapshot.paramMap.get("other_prefix") === 'null' ? '' : this.route.snapshot.paramMap.get("other_prefix");
    this.provider_detail.first_name = this.route.snapshot.paramMap.get("first_name") === 'null' ? '' : this.route.snapshot.paramMap.get("first_name");
    this.provider_detail.middle_name = this.route.snapshot.paramMap.get("middle_name") === 'null' ? '' : this.route.snapshot.paramMap.get("middle_name");
    this.provider_detail.last_name = this.route.snapshot.paramMap.get("last_name") === 'null' ? '' : this.route.snapshot.paramMap.get("last_name");
    this.provider_detail.suffix = this.route.snapshot.paramMap.get("suffix") === 'null' ? '' : this.route.snapshot.paramMap.get("suffix");
    this.provider_detail.credential_text = this.route.snapshot.paramMap.get("credential_text") === 'null' ? '' : this.route.snapshot.paramMap.get("credential_text");
  }

  /**
   * Tabs Functionality
   * @returns void
   */
  switchTab(contentId:string):void {
    switch(contentId) {
      case 'InNetwork':
        this.inNetwork = true;
        this.outNetwork = false;
        this.notAtThisLocation = false;
        this.undetermined = false;
        this.ppo = false;
        break;
      case 'OutNetwork':
        this.inNetwork = false;
        this.outNetwork = true;
        this.notAtThisLocation = false;
        this.undetermined = false;
        this.ppo = false;
        break;
      case 'NotAtThisLocation':
        this.inNetwork = false;
        this.outNetwork = false;
        this.notAtThisLocation = true;
        this.undetermined = false;
        this.ppo = false; 
        break;
      case 'Undetermined':
        this.inNetwork = false;
        this.outNetwork = false;
        this.notAtThisLocation = false;
        this.undetermined = true;
        this.ppo = false; 
        break;
      case 'PPO':
        this.inNetwork = false;
        this.outNetwork = false;
        this.notAtThisLocation = false;
        this.undetermined = false;
        this.ppo = true; 
        break;
    }
  }

  setNetworkPlansData(number) {
    this.networkSelection = number;
    this.networkSelectionPlans = [];
    if(this.networkSelection > 1) {
      for(let plan of this.plans) {
        if(plan.network_status == this.networkSelection) {
          this.networkSelectionPlans.push(plan);
        }
      }
    } else {
      for(let plan of this.plans) {
        this.networkSelectionPlans.push(plan);
      }
    }
  }

}
