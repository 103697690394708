<div class="container-fluid header-banner-wrapper home-banner-wrapper force-height-auto">
	<app-header-b></app-header-b>
  
	<div class="container home-banner banner-wrapper unset-absolute-position">
	  <div class="d-flex">
		<div class="home-banner-left">
		  <div class="home-banner-text-wrapper">
			<h2 class="banner-main-title-subtext text-center text-sm-left">A Clear & Organized</h2>
			<h1 class="banner-main-title">
			  Medicare Experience
			</h1>
			<mat-list class="mt-4">
			  <mat-list-item>
				<mat-icon mat-list-icon color="primary">check</mat-icon>
				<p class="text-capitalize">Easy Consumer-Friendly View of Plans</p>
			  </mat-list-item>
			  <mat-list-item>
				<mat-icon mat-list-icon color="primary">check</mat-icon>
				<p class="text-capitalize">Clear Explanations & Recommendations</p>
			  </mat-list-item>
			  <mat-list-item>
				<mat-icon mat-list-icon color="primary">check</mat-icon>
				<p class="text-capitalize">Guidance & Support All Year</p>
			  </mat-list-item>
			</mat-list>
			<div
			  class="home-banner-btn d-flex justify-content-around justify-content-md-start flex-column flex-md-row align-items-center">
			  <a routerLink="/benny-dash" class="btn btn-third text-uppercase">Go To My Benny HQ</a>
  
			  <button class="btn btn-first ml-2" routerLink="/insurance-marketplace">View Plans</button>
			</div>
  
		  </div>
		</div>
		<div class="home-banner-right banner-image-wrapper">
		  <!-- <img src="assets/img/home/new/blonde-old-woman-holding-a-phone.png"> -->
		  <img src="assets/img/common/medigap/plan-n-banner-character.png">
		</div>
	  </div>
	</div>
</div>

<!-- SIDE BY SIDE COMPARISON SECTION -->
<section class="side-by-side-comparison-section">
  <div class="between-double-background side-by-side-comparison-type">
    <div class="between-double-background-top"></div>
    <div class="between-double-background-bottom"></div>
    <div class="container side-by-side-comparison-type-boxes">
      <div class="type-box">
        <h4>Permanent Life <br> Insurance</h4>
        <div class="banner-pointer-wrapper">
          <div class="banner-pointer-background"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="side-by-side-comparison-box-wrapper">
    <div class="container">

      <div class="comparison-wrapper col-md-6">
        <!-- 1st box -->
        <div class="comparison-box">
          <!-- <div class="comparison-circle-img"> -->
          <img src="assets/img/life-insurance/help-me-decide/side-by-side-1.png">
          <!-- </div> -->
          <p>
            <b>Permanent Life Insurance</b> offers level premiums
            for the entire lifetime of the
            insured.
          </p>
        </div>
        <mat-divider class="mat-divider-dashed" [vertical]="true"></mat-divider>
        <!--  2nd box  -->
        <div class="comparison-box">
          <!-- <div class="comparison-circle-img"> -->
          <img src="assets/img/life-insurance/help-me-decide/side-by-side-2-6.png">
          <!-- </div> -->
          <p>
            Permanent Life Insurance can take the form of
            Whole Life or Universal Life coverage - both of
            which offer guaranteed lifetime coverage.
          </p>
        </div>
        <mat-divider class="mat-divider-dashed" [vertical]="true"></mat-divider>
        <!-- 3rd box -->
        <div class="comparison-box">
          <!-- <div class="comparison-circle-img"> -->
          <img src="assets/img/life-insurance/help-me-decide/side-by-side-3.png">
          <!-- </div> -->
          <p>
            Since Permanent coverage guarantees
            coverage for life, it is often used to protect
            against permanent disabilities.
          </p>
        </div>
        <mat-divider class="mat-divider-dashed" [vertical]="true"></mat-divider>
        <!-- 4th box -->
        <div class="comparison-box">
          <!-- <div class="comparison-circle-img"> -->
          <img src="assets/img/life-insurance/help-me-decide/side-by-side-4.png">
          <!-- </div> -->
          <p>
            Most Permanent Life Insurance build a cash
            value within the policy which can be borrowed
            from or which can be cashed in if the insured
            surrenders (cancels) the policy.
          </p>
        </div>
        <mat-divider class="mat-divider-dashed" [vertical]="true"></mat-divider>
        <!-- 5th box -->
        <div class="comparison-box">
          <!-- <div class="comparison-circle-img"> -->
          <img src="assets/img/life-insurance/help-me-decide/side-by-side-5.png">
          <!-- </div> -->
          <p>
            The cash value witin a Permanent Life
            insurance policy is often used to supplement
            retirement income.
          </p>
        </div>
        <mat-divider class="mat-divider-dashed" [vertical]="true"></mat-divider>
        <!-- 6th box -->
        <div class="comparison-box">
          <!-- <div class="comparison-circle-img"> -->
          <img src="assets/img/life-insurance/help-me-decide/side-by-side-6.png">
          <!-- </div> -->
          <p>
            Most Permanent policies allow a ‘paid-up’
            feature that can guarantee lifetime coverage
            without further payments.
          </p>
        </div>
        <mat-divider class="mat-divider-dashed" [vertical]="true"></mat-divider>
        <!-- 6th box -->
      </div>
    </div>
  </div>
  <div class="between-double-background side-by-side-comparison-box-wrapper-bottom">
    <div class="between-double-background-top"></div>
    <div class="between-double-background-bottom"></div>
    <div class="container">
      <div class="comparison-wrapper col-md-6">
        <div class="comparison-box">
          <!-- <div class="comparison-circle-img"> -->
          <img src="assets/img/life-insurance/help-me-decide/side-by-side-2-6.png">
          <!-- </div> -->
          <p>
            Permanent Life Insurance policies allow policy
            holders to suspend their premiums for a period
            determined by their existing cash value.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="col-md-12 home-content-row-div mt-50">
  <div class="container">
    <div class="row align-items-center">
      <div class="row col-12 col-md-9 d-flex justify-content-center text-center text-md-left pr-md-5">
        <div class="col-12 details-p row justify-content-center mt-50">
          <h4 class="mb-3">Summary of Level Benefit Whole Life Insurance</h4>
        </div>
        <div class="col-12 row justify-content-center cover-list cover-list-text-white">
          <div class="col-12 details-p row">
            <p><span>Description:</span> Whole Life insurance is the most simple type of permanent life insurance. Regardless of how old the insured lives to, the policy will pay out the death benefit tax-free upon the passing of the insured. These policies  have minimum guaranteed levels of cash value and surrender value, and a simpler approval process, but higher prices.</p>
            <p><span>Death Benefit Amounts Available:</span> $5,000 to $40,000</p>
            <p><span>Policy Features:</span> Guaranteed Cash Value, Surrender Value</p>
            <p><span>Approval Process: </span>Simple Yes/No Health Questions, Predictable and Rules-Based, Sometimes Involves a Phone Health Interview</p>
            <p><span>When Does This Coverage Type Make Sense?</span> When you need under $25,000 of coverage and want a quicker and simpler health approval process.</p>
          </div>
          <!-- <div class="col-12 details-p row">
            <ul>
              <li><img src="./assets/img/common/plan-n-landing-page/Diabetic Supplies@144x.png"
                  alt="Doctor's Office Visits">Doctor's Office Visits</li>
              <li><img src="./assets/img/common/plan-n-landing-page/Preventive Care@144x.png"
                  alt="Preventive Care">Preventive Care</li>
              <li><img src="./assets/img/common/plan-n-landing-page/Diagnostics@144x.png"
                  alt="Diagnostic Tests">Diagnostic Tests</li>
              <li><img src="./assets/img/common/plan-n-landing-page/Physical Theraphy@144x.png"
                  alt="Physical Therapy">Physical Therapy</li>
              <li><img src="./assets/img/common/plan-n-landing-page/Medical Equipment@144x.png"
                  alt="Medical Equipment">Medical Equipment</li>
            </ul>
          </div>
          <div class="col-12 details-p row">
            <ul>
              <li><img src="./assets/img/common/plan-n-landing-page/Hospitalizations@144x.png"
                  alt="Hospitalizations">Hospitalizations</li>
              <li><img src="./assets/img/common/plan-n-landing-page/outpatient procedures@144x.png"
                  alt="Outpatient Procedures">Outpatient Procedures</li>
              <li><img src="./assets/img/common/plan-n-landing-page/Skilled Nursing@144x.png"
                  alt="Skilled Nursing">Skilled Nursing</li>
              <li><img src="./assets/img/common/plan-n-landing-page/Surgeries@144x.png" alt="Surgeries">Surgeries</li>
              <li><img src="./assets/img/common/plan-n-landing-page/Mental Health@144x.png" alt="Mental Health">Mental
                Health</li>
            </ul>
          </div> -->
        </div>
        <div class="col-12 details-p row justify-content-center mt-0 mt-md-5">
          <h4 class="mb-3">Summary of Permanent Universal Life Insurance</h4>
        </div>
        <div class="col-12 row justify-content-center cover-list cover-list-text-white">
          <div class="col-12 details-p row">
            <p><span>Description:</span> Simplified Description: Permanent Universal Life is a more cost-efficient type of permanent life insurance that guarantees coverage at a fixed price for the entire life of the insured, but requires some additional underwriting/approval requirements, and guarantees less cash value and surrender value amounts.</p>
            <p><span>Death Benefit Amounts Available:</span> $25,000 to $1,000,000</p>
            <p><span>Additional Benefits:</span> Some Cash Value, Surrender Value, Optional Living Benefits</p>
            <p><span>Approval Process: </span>Health Questions, A Phone Interview, and Typically Health Records Requested from Your Physician</p>
          </div>          
        </div>
      </div>
      <div
        class="col-12 col-md-3 img-tile-box img-tile-box-guy d-flex justify-content-center align-items-center justify-content-md-end mb-md-0">
        <img class="flip-horizontal" src="./assets/img/guy-in-a-jacket-crop-full.png" />
      </div>
    </div>
  </div>
</div>

<!-- input quote section -->
<div class="container-fluid mt-8em">
    <div class="view-wrapper">

            <div class="input-quote-center-wrapper">
              
                <h4 class="form-input-heading mb-5 mx-auto mw-570">Select the Death Benefit Amount You Would Like</h4>
          
                <div class="amount-selection-wrapper">
          
          
                  <button class="amount-selection-btn" (click)="onSelect_Amount('5000')" [class.active]="selectedAmount == '5000'">$5,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn" (click)="onSelect_Amount('8000')" [class.active]="selectedAmount == '8000'">$8,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn" (click)="onSelect_Amount('10000')" [class.active]="selectedAmount == '10000'">$10,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn position-relative" (click)="onSelect_Amount('12000')" [class.active]="selectedAmount == '12000'">$12,000 <i class="fas fa-check ml-2"></i><div class="badge">Most <br> Common</div></button>
          
                  <button class="amount-selection-btn" (click)="onSelect_Amount('15000')" [class.active]="selectedAmount == '15000'">$15,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn" (click)="onSelect_Amount('18000')" [class.active]="selectedAmount == '18000'">$18,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn" (click)="onSelect_Amount('20000')" [class.active]="selectedAmount == '20000'">$20,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn" (click)="onSelect_Amount('22000')" [class.active]="selectedAmount == '22000'">$22,000 <i class="fas fa-check ml-2"></i></button>
          
                  <button class="amount-selection-btn" (click)="onSelect_Amount('25000')" [class.active]="selectedAmount == '25000'">$25,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn" (click)="onSelect_Amount('50000')" [class.active]="selectedAmount == '50000'">$50,000 <i class="fas fa-check ml-2"></i></button>
                  <button class="amount-selection-btn" (click)="onSelect_Amount('100000')" [class.active]="selectedAmount == '100000'">$100,000<i class="fas fa-check ml-2"></i></button>
                  
                  <button class="amount-selection-btn grayed position-relative" (click)="selectOther.open()" [class.active]="selectedOtherAmount != 'Other'">{{selectedOtherAmount}} <i class="fas fa-arrow-down" [class.ml-2]="selectedOtherAmount != 'Other'"></i>
                    <mat-form-field id="ci-other-amount">
                      <mat-select #selectOther (selectionChange)="onSelectOtherAmount($event)">
                        <mat-option value="105000">105,000</mat-option>
                        <mat-option value="110000">110,000</mat-option>
                        <mat-option value="115000">115,000</mat-option>
                        <mat-option value="120000">120,000</mat-option>
                        <mat-option value="125000">125,000</mat-option>
                        <mat-option value="130000">130,000</mat-option>
                        <mat-option value="135000">135,000</mat-option>
                        <mat-option value="140000">140,000</mat-option>
                        <mat-option value="145000">145,000</mat-option>
                        <mat-option value="150000">150,000</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <i class="fas fa-check ml-2"></i>
                  </button>     
                  <!-- ERROR MESSAGE -->
                  <div class="invalid-feedback d-block text-center mt-3" *ngIf="!selectedAmount && isProceedClicked">
                    Amount is required
                  </div>  
                  <!-- END ERROR MESSAGE -->        
          
                  <button class="amount-selection-btn help-me-determine" routerLink="/life-insurance/help-me-decide">Help Me Determine</button>
          
                </div>
          
                <div class="d-flex justify-content-center align-items-center flex-row mt-6em">                                           
                    <!-- <button class="btn btn-third btn-back mr-3 btn-back" routerLink="/life-insurance-v2"><span>Back</span></button> -->
                    <button class="btn btn-first" (click)="goTo_ViewInputQuote()">Proceed</button>
                </div>   
              
                <!-- <h4 class="form-input-heading">
                    Select the Death Benefit Amount You Would Like
                </h4>
                <form>
                    <div class="row justify-content-center">
                        <div class="form-group adjusted-select-form-group mb-2">
                            <label for="death_benefit">Amount</label>
                            <select id="death_benefit" class="custom-select form-control" [formControl]="controls.death_benefit" (focus)="focusSelectElement($event)" (blur)='blurSelectElement($event)' (change)='changeSelectElement($event)' [class.error]="controls.death_benefit.hasError('required') && proceedButtonClicked">
                                <option value="5">$5,000</option>
                                <option value="10">$10,000</option>
                                <option value="15">$15,000</option>
                                <option value="20">$20,000</option>
                                <option value="25">$25,000</option>
                                <option value="30">$30,000</option>
                                <option value="35">$35,000</option>
                                <option value="40">$40,000</option>
                                <option value="45">$45,000</option>
                                <option value="50">$50,000</option>
                                <option value="60">$60,000</option>
                                <option value="70">$70,000</option>
                                <option value="80">$80,000</option>
                                <option value="90">$90,000</option>
                                <option value="100" selected>$100,000</option>
                                <option value="125">$125,000</option>
                                <option value="150">$150,000</option>
                                <option value="175">$175,000</option>
                                <option value="200">$200,000</option>
                                <option value="225">$225,000</option>
                                <option value="250">$250,000</option>
                                <option value="300">$300,000</option>
                                <option value="400">$400,000</option>
                                <option value="450">$450,000</option>
                                <option value="500">$500,000</option>
                                <option value="600">$600,000</option>
                                <option value="700">$700,000</option>
                                <option value="800">$800,000</option>
                                <option value="900">$900,000</option>
                                <option value="1000">$1,000,000</option>
                                <option value="1250">$1,250,000</option>
                                <option value="1500">$1,500,000</option>
                                <option value="2000">$2,000,000</option>
                                <option value="3000">$3,000,000</option>
                                <option value="4000">$4,000,000</option>
                                <option value="5000">$5,000,000</option>
                                <option value="6000">$6,000,000</option>
                                <option value="7000">$7,000,000</option>
                                <option value="8000">$8,000,000</option>
                                <option value="9000">$9,000,000</option>
                                <option value="9500">$9,500,000</option>
                                <option value="10000">$10,000,000</option>
                            </select>                            
                        </div>  
                        
                        <div class="mb-0 form-group invalid-feedback d-block" *ngIf="controls.death_benefit.hasError('required') && proceedButtonClicked">
                            Amount is required
                        </div> 
                                              
                    </div>
                    <div class="row justify-content-center mt-4">
                        <div class="form-group d-flex justify-content-between flex-row">
                            <button class="btn btn-third btn-back mx-1 btn-back">
                                <span>Get Help</span>
                            </button>
                            <button class="btn btn-first" (click)="proceed()">Proceed</button>
                        </div>
                    </div>
                </form> -->
            </div>

    </div>
</div>

<div class="col-md-12 home-content-row-div blue-bg mt-50">
	<div class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block" style="z-index: 1">
	  <i class="fa fa-arrow-down font-blue"></i>
	  <div class="banner-pointer-background inverse-colored-down-arrow"></div>
	</div>
	<div class="container">
	  <div class="row align-items-center">
		<div
		  class="col-12 col-md-5 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
		  <img class="flip-horizontal" src="assets/img/common/5-cat-woman.png" />
		</div>
		<div
		  class="row col-12 col-md-7 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5">
		  <h4 class="col-12 fColor-white text-center fWeight-500">
        Permanent Life Insurance Consumers Should Always Compare the Following Between Carriers:
		  </h4>
		  <table class="col-12 table-criteria mt-20">
			<tr>
			  <td class="first-col">
          <img src="./assets/img/life-insurance/life-insurance-second-var-1.png" class="criteria-img">
        </td>
			  <td class="second-col">Type of Coverage</td>
			</tr>
			<tr>
			  <td class="first-col">
          <img src="./assets/img/life-insurance/life-insurance-second-var-2.png" class="criteria-img">
        </td>
			  <td class="second-col">Death Benefit Amount</td>
			</tr>
			<tr>
			  <td class="first-col">
          <img src="./assets/img/life-insurance/life-insurance-second-var-3.png" class="criteria-img">
        </td>
			  <td class="second-col">
          Quality & Reputation of the Insurance Company
			  </td>
			</tr>
			<tr>
			  <td class="first-col">
          <img src="./assets/img/life-insurance/life-insurance-second-var-4.png" class="criteria-img">
        </td>
			  <td class="second-col">
          Price Competitiveness in the Marketplace
			  </td>
			</tr>
		  </table>

		  <div class="col-12 row justify-content-center mt-20">
			<button class="col-6 btn btn-first-plain padding-20 width-auto"
			  (click)="speakToCounselorEvent(speakToCounselorModal)">
			  Speak With a Concierge NOW
			</button>
		  </div>
		  <!-- <div class="col-12 row justify-content-center mt-20">
			<p class="col-7 fColor-white" style="font-size: 14px; text-align: center">
			  Or Scroll Below To Start Viewing Plans and Prices Now
			</p>
		  </div> -->
		  <div class="col-12 row">&nbsp;</div>
		</div>
	  </div>
	</div>
</div>

<div class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<div class="d-none {{ dblocking }}" #speakToCounselorModal>
  <app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>
<app-testimonials></app-testimonials>
<div class="footer-top-spacer mt-5"></div>
<app-ready-to-talk></app-ready-to-talk>
