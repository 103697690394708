import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss']
})
export class DobComponent implements OnInit {

  dobCalendarStep:any = '1';
  selectMonth:any = 10;
  selectYear:any = 1965;
  startAt:any = new Date(this.selectYear+'/'+this.selectMonth+'/1');
  isDobCalendarDateSelected:boolean = false;
  selectedDate:any;
  cDateFormattedValue:any;

  @Input() isShowDaySelection:boolean = true;

  @Output() dobValue: EventEmitter<any> = new EventEmitter();
  @Input() heading;
  @Input() fieldType;
  
  constructor() { }

  ngOnInit() {
  }

  nextStep() {
      this.startAt = new Date(this.selectYear+'/'+this.selectMonth+'/1')
      this.dobCalendarStep = 2;
  }

  getAgeDate(event){
      this.isDobCalendarDateSelected = true;
      this.selectedDate = event;        
      const cDateSelected = new Date(event);        
      this.cDateFormattedValue = cDateSelected.toLocaleDateString('fr-CA');  
  }

  calculateAge(withDaySelection = true){
    const noDayDate = new Date(this.selectYear+'/'+this.selectMonth+'/1');
    this.cDateFormattedValue = (withDaySelection) ? this.cDateFormattedValue : noDayDate.toLocaleDateString('fr-CA');
    
    const data = {
      'date':this.cDateFormattedValue,
      'type':this.fieldType,
    }
    
    this.dobValue.emit(data);
    //Swal.close();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dobCalendarStep = '1';    
    this.selectMonth = 1;
    this.selectYear = 2023;//2022

    // if(changes.fieldType.currentValue == 'normal'){
    //   this.selectYear = 1965;
    // }else{
    //   this.selectYear = 2022;
    // }
  }

}
