import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MetadataService } from '../services/metadata.service';
import {Title} from "@angular/platform-browser";
declare var window: any;

@Component({
  selector: 'app-medicare-organizer',
  templateUrl: './medicare-organizer.component.html',
  styleUrls: ['./medicare-organizer.component.scss'],
  providers: [MetadataService]
})
export class MedicareOrganizerComponent implements OnInit {
  fullPrimaryHeader: boolean = true;
  isLoggedInUser: any;

  private currentUrl = window.location.href;
  private baseUrl = window.location.origin;
  isShow: boolean = true;
  infoMessage: string;

  constructor(
    private _auth: AuthService,
    private metadataService: MetadataService,
    private titleService:Title
  ) {
    this.titleService.setTitle("Your Clear & Organized Medicare Organizer - TapTap Medicare");
  }

  showForm(info) {
    this.infoMessage = info;
    this.isShow = false;
    window.scrollTo(0, 0);
  }

  back() {
    this.isShow = true;
    window.scrollTo(0, 0);
  }

  injectSEO(): void {
    this.metadataService.getMetaData('/organizer', (a) => {
      const { data } = a;
      const metatitle = document.getElementById('meta-title');
      const metadesc = document.getElementById('meta-desc');
      const keywords = document.getElementById('meta-keywords');
      const fbMetaTitle = document.getElementById('fb-meta-title');
      const fbMetaDescription = document.getElementById('fb-meta-description');
      const fbMetaUrl = document.getElementById('fb-meta-url');
      const fbMetaImage = document.getElementById('fb-meta-image');

      metatitle.setAttribute('content', data['meta-title']);
      metadesc.setAttribute('content', data['meta-desc']);
      keywords.setAttribute('content', data['meta-keywords']);
      fbMetaTitle.setAttribute('content', data['meta-title']);
      fbMetaDescription.setAttribute('content', data['meta-desc']);
      fbMetaUrl.setAttribute('content', this.currentUrl);
      fbMetaImage.setAttribute(
        'content',
        `${this.baseUrl}/assets/company-logo.jpg`
      );
      window.prerenderReady = true;
    });
  }

  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if (this.isLoggedInUser) {
      this.fullPrimaryHeader = false;
    }
    this.injectSEO();
  }
}
