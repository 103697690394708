import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ready-to-talk-landing',
  templateUrl: './ready-to-talk-landing.component.html',
  styleUrls: ['./ready-to-talk-landing.component.scss']
})
export class ReadyToTalkLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  speakToCounselorEvent(modalViews) {
    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
      padding: '3em'
    });
  }

}
