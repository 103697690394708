<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">MIB, Inc., Pre-Notice</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <div class="dashed-box border shadow p-4">
        <h4 class="text-center fw-semibold">Please Read and Sign.</h4>
        <div>
          <p class="text-label">By completing this form, I agree to the following:</p>
          <div class="p-2">
            <div class="">
              <h6 class="d-flex justify-content-center text-center">MIB, Inc., Pre-Notice</h6>
              <h6 class="d-flex justify-content-center fw-semibold text-center">[Insurance Carrier NAIC Name]</h6>
              <h6 class="d-flex justify-content-center fw-semibold text-center">Address: [Insurance Carrier NAIC Address]</h6>
              <h6 class="d-flex justify-content-center fw-semibold text-center">Phone Number: [Insurance Carrier NAIC Phone Number 000-000-0000]</h6>
            </div>
          </div>
          <div class="p-2">
            <p>
              Information regarding your insurability will be treated as confidential. American Retirement Life Insurance  Company or its reinsurers may, however, make a brief report thereon to MIB, Inc., formerly known as Medical Information Bureau, a not-for-profit membership organization of insurance companies, which operates an information exchange on behalf of its Members. If you apply to another MIB member company for life or health  insurance coverage, or a claim for benefits is submitted to such a company, MIB, upon request, will supply such company with the information in its file.
            </p>
            <p>
              Upon receipt of a request from you, MIB will arrange disclosure of any information it may have in your file. Please contact MIB at 866-692-6901. If you question the accuracy of information in MIB's file, you may contact MIB  and seek a correction in accordance with the procedures set forth in the federal Fair Credit Reporting Act. The address of MIB's information office is 50 Braintree Hill Park, Suite 400, Braintree, MA 02184-8734.
            </p>
            <p>
              <b>[Insurance Carrier NAIC Name]</b>, or its reinsurers, may also release information in its file to other  insurance companies to whom you may apply for life or health insurance, or to whom a claim for benefits may be submitted. Information for consumers about MIB may be obtained on its website at www.mib.com.
            </p>
          </div>
        </div>
        <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" [(ngModel)]="preNoticeAgreedDate" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="iAgree12">    
              <label class="custom-control-label fw-semibold text-dblue" for="iAgree12">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>