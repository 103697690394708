import { Component, OnInit, Input, ElementRef } from '@angular/core';
import Swal from "sweetalert2";

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  @Input() image: any;
  @Input() amBestFinancialRating: any;
  @Input() amBestOutlook: any;
  @Input() spOutlook: any;
  @Input() spFinancialRating: any;
  @Input() companyBio:any;
  @Input() carrierName: any;
  @Input() yearFounded: any;
  @Input() companyBrochure: any;
  @Input() marketplaceType:any = 'medigap';

  @Input() taptapDetails: any;
  @Input() overallRating: any;
  @Input() priceStabilityRating: any;
  @Input() numberOfPolicyholders: any;
  @Input() carrierReview: any;
  @Input() counselorButton:any;
  @Input() consumerRating:any;

  @Input() planIdentifier:string; // medigap, mapd, etc.

  profilePage = 1;

  
  dblocking = 'd-none';

  constructor() { 
    console.log(this.counselorButton);
    console.log(this.planIdentifier)
  }

  ngOnInit() {
    
  }

  consoleLog() {
    console.log(this.taptapDetails)
  }

  speakToCounselorEvent(modalViews: any, modalType) {
    this.dblocking = 'd-block';
    Swal.fire({
    html: modalViews,
    showConfirmButton: false,
    showCloseButton: true,
    width: modalType,
    padding: '3em',
    customClass: {
      popup: 'speak-to-counselor-popup-box',
      closeButton: 'speak-to-counselor-popup-box-close-btn'
    },
    onClose: () => {
        this.dblocking = 'd-none';
    }
  });  
}

}
