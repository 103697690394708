<div class="container">
  <div class="mb-4">
    <div class="dashed-box p-3">
      <h6 class="text-label fw-semibold">Would You Like To Add An Emergency Contact On The Policy?</h6>
      <div class="row pt-2">
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="addEmergencyContact_1" name="addEmergencyContact" class="custom-control-input" [value]="true" [(ngModel)]="addEmergencyContact">
            <label class="custom-control-label fw-semibold text-dblue" for="addEmergencyContact_1">YES</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="addEmergencyContact_2" name="addEmergencyContact" class="custom-control-input" [value]="false" [(ngModel)]="addEmergencyContact">
            <label class="custom-control-label fw-semibold text-dblue" for="addEmergencyContact_2">NO</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="addEmergencyContact">
    <h4 class="fw-semibold text-dblue">Emergency Contact Info</h4>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-md-6">
        <div class="p-2">
          <label for="efname" class="text-label">Emergency Contact First Name</label>
          <input type="text" class="form-control blurred" id="efname"/>
        </div>
        <div class="p-2">
          <label for="emname" class="text-label">Emergency Contact Middle Name</label>
          <input type="text" class="form-control blurred" id="emname"/>
        </div>
        <div class="p-2">
          <label for="elname" class="text-label">Emergency Contact Last Name</label>
          <input type="text" class="form-control blurred" id="elname"/>
        </div>
        <div class="p-2">
          <label for="eEmail" class="text-label">Emergency Contact Email</label>
          <input type="text" class="form-control blurred" id="eEmail"/>
        </div>
        <div class="p-2">
          <label for="ePhone" class="text-label">Emergency Contact Phone</label>
          <input type="text" class="form-control blurred" id="ePhone"/>
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <div class="dashed-box border p-3 m-2">
          <h6 class="text-label fw-semibold">Policy Insured’s Relationship To You</h6>
          <div class="row pt-2">
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_1" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_1">Husband</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_2" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_2">Wife</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_3" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_3">Mother</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_4" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_4">Father</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_5" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_5">Brother</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_6" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_6">Sister</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_7" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_7">Significant Other</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_8" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_8">Friend</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_9" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_9">Caretaker</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_10" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_10">Co-Worker</label>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3">
          <h5 class="text-center fw-semibold text-dblue">
            Send Invite To Emergency Contact?
          </h5>
          <div class="d-flex justify-content-center">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="inviteEmergencyContact_1" name="inviteEmergencyContact" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="inviteEmergencyContact_1">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="inviteEmergencyContact_2" name="inviteEmergencyContact" class="custom-control-input">
              <label class="custom-control-label fw-semibold text-primary" for="inviteEmergencyContact_2">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>