import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs/index';
import Swal from 'sweetalert2'; 
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-third-variation',
  templateUrl: './third-variation.component.html',
  styleUrls: ['./third-variation.component.scss']
})
export class ThirdVariationComponent implements OnInit {

  @Input('isDefault') isDefault: boolean = true;
  @Input() isLandingPage: boolean = false;
  @Input() spacerColor: string = 'transparent';
  
  fullPrimaryHeader:boolean = true;
  isLoggedInUser:any;
  showLogo = false;

  users: Observable<any>;
  constructor(private db: AngularFirestore,public _auth: AuthService) { 
    
  }

  ngOnInit() {
    this.users = this.db.collection('users')
          .valueChanges();

    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if(this.isLoggedInUser){
        this.fullPrimaryHeader = false;
    }
  }

  speaktoCounselor(modalViews){
    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 400,
      padding: '2rem',
      customClass: {
        popup: 'speak-to-counselor-popup-box',
        closeButton: 'speak-to-counselor-popup-box-close-btn'
      },
    });
  }

}
