<app-loading *ngIf="showLoading"></app-loading>

<h4 class="fColor-base fWeight-600">Create An Account</h4>
<form [formGroup]="registerForm" [class.form-validated]="isFormSubmit">
  <div class="d-none" [class.d-block]="viewDefault">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="first_name">First Name<sup>*</sup></label>
        <input type="text" class="form-control" name="first_name" formControlName="first_name" id="first_name"
          value="{{ passed_firstname }}">
        <ng-container *ngIf="(first_name.invalid && first_name.touched) || first_name.dirty || isFormSubmit">
          <small *ngIf="first_name.invalid && first_name.errors.required" class="text-danger">
            First Name is required
          </small>
        </ng-container>
      </div>
      <div class="form-group col-md-6">
        <label for="last_name">Last Name<sup>*</sup></label>
        <input type="text" class="form-control" name="last_name" formControlName="last_name" id="last_name"
          value="{{ passed_lastname }}">
        <ng-container *ngIf="(last_name.invalid && last_name.touched) || last_name.dirty || isFormSubmit">
          <small *ngIf="last_name.invalid && last_name.errors.required" class="text-danger">
            Last Name is required
          </small>
        </ng-container>
      </div>
    </div>
    <div class="form-group">
      <label for="mobile_phone">Mobile Phone<sup>*</sup></label>
      <input type="number" class="form-control" name="mobile_phone" id="mobile_phone" formControlName="mobile_phone"
        value="{{ passed_mobile }}">
      <ng-container *ngIf="(mobile_phone.invalid && mobile_phone.touched) || mobile_phone.dirty || isFormSubmit">
        <small *ngIf="mobile_phone.invalid && mobile_phone.errors.required" class="text-danger">
          Mobile Phone is required
        </small>
      </ng-container>
    </div>
    <div class="d-flex flex-column flex-wrap flex-sm-row justify-content-between mb-4">
      <!-- new recaptcha  - stephen(11-13-2019)-->
      <div class="d-flex flex-column recaptcha-inner-wrapper">
        <recaptcha [formControl]="taptapRecaptcha" (scriptLoad)="onScriptLoad()" (scriptError)="onScriptError()"
          class="recaptcha-wrapper"
          style="transform:scale(0.80);-webkit-transform:scale(0.80);transform-origin:0 0;-webkit-transform-origin:0 0;">
        </recaptcha>
        <ng-container *ngIf="taptapRecaptcha.invalid && isFormSubmit">
          <small class="text-danger">
            Please Verify your a human
          </small>
        </ng-container>
      </div>

      <!-- end new recaptcha  - stephen(11-13-2019)-->
      <button type="submit" class="btn btn-first proceed-btn" (click)="clickProceed()">Proceed</button>
    </div>
    <div class="row">
      <button (click)="onShowLoginForm()" role="button" *ngIf="!dialogData" id="alreadyHaveAccountBtn" class="mb-5 btn">Already have an account?
        Login Here</button>
    </div>
  </div>
  <div class="d-none" [class.d-block]="!viewDefault">
    <div class="form-group">
      <label for="email">Email<sup>*</sup></label>
      <input type="email" class="form-control" name="email" id="email" formControlName="email"
        value="{{ passed_email }}" [class.error-border]="errors && errors.email">
      <ng-container *ngIf="(email.invalid && email.touched) || email.dirty || isFormSubmit">
        <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
        <small *ngIf="email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
        <small *ngIf="errors && errors.email" class="text-danger">

          {{ errors.email[0] }}</small>
      </ng-container>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="password">Password<sup>*</sup></label>
        <input type="password" class="form-control" name="password" id="password" formControlName="password">
        <ng-container *ngIf="(password.invalid && password.touched) || password.dirty || isFormSubmit">
          <small *ngIf="password.invalid && password.errors.required" class="text-danger">
            Password is required
          </small>
        </ng-container>
      </div>
      <div class="form-group col-md-6">
        <label for="c_password">Confirm Password<sup>*</sup></label>
        <input type="password" class="form-control" name="c_password" id="c_password" formControlName="c_password"
          [class.error-border]="errors && errors.c_password">
        <ng-container *ngIf="(c_password.invalid && c_password.touched) || c_password.dirty || isFormSubmit">
          <small *ngIf="c_password.invalid && c_password.errors.required" class="text-danger">
            Confirm Password is required
          </small>
          <small *ngIf="errors && errors.c_password" class="text-danger">

            {{ errors.c_password[0] }}
                            </small>
        </ng-container>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center flex-wrap pb-5">
      <!-- <div #recaptcha style="width:200px"></div> -->
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="terms_and_condition">
        <label class="custom-control-label" for="customCheck1">I agree with service <a class="capitalize">Terms &
            Conditions!</a></label>
      </div>
      <div class="d-flex justify-content-center">
        <!-- <button role="button" class="btn btn-third" (click)="viewDefault = !viewDefault">Back</button> -->
        <button type="submit" class="btn btn-first btn-signup-now" (click)="register()">Signup Now</button>
      </div>
    </div>
  </div>
</form>