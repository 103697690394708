<div class="estimate-drugs">
  <h4 class="modal-title-heading">Add Your Drugs</h4>

  <div class="estimate-first-view" *ngIf="isShowView">

    <!-----------------------------------------------------------
      ------------------------INPUT FIELDS ------------------------
      ------------------------------------------------------------>
    <div class="form-row justify-content-between align-items-end mb-5" *ngFor="let drug of drugsArray; let i = index">

      <div class="form-group m-0" style="flex:auto;width:auto;max-width: 100%;padding:5px;">
        <label for="yourDoctorsName" style="font-weight:600">Drug Name:</label>
        <input type="text" [(ngModel)]="drug.name" value="" class="form-control" id="yourDoctorsName" autofocus>
      </div>
      <div class="form-group m-0" style="flex:auto;width:auto;max-width: 100%;padding:5px;">
        <label for="dosage" style="font-weight:600">Dosage:</label>
        <input type="text" [(ngModel)]="drug.dosage" value="" class="form-control" id="dosage" autofocus>
      </div>
      <div class="form-group m-0" style="flex:auto;width:auto;max-width: 100%;padding:5px;">
        <label for="frequency" style="font-weight:600">Frequency:</label>
        <input type="text" value="" class="form-control" id="frequency" autofocus>
      </div>
    </div>


    <!-----------------------------------------------------------
      ----------------ADD ANOTHER DRUG BUTTON ---------------------
      ------------------------------------------------------------>

    <div class="row mt-5 justify-content-center">
      <div class="d-flex flex-column justify-content-center">
        <button class="btn btn-third mb-3 btn-small" (click)="addFieldValue()">
          Add Another Drug
          <i class="fas fa-plus ml-2"></i>
        </button>
      </div>
    </div>


    <!-----------------------------------------------------------
      ----------------SELECT FROM COMMON MEDICATIONS---------------
      ------------------------------------------------------------>

    <div class="sfcm">
      <div class="sfcm-item">
        <h6 class="sfcm-item-title">Blood Pressure</h6>
        <img src="./assets/img/insurance-marketplace/new/icon-medicare-part-d-plans.png" alt="" class="sfcm-item-img">
      </div>
      <div class="sfcm-item">
        <h6 class="sfcm-item-title">Cholesterol</h6>
        <img src="./assets/img/insurance-marketplace/new/icon-medicare-part-d-plans.png" alt="" class="sfcm-item-img">
      </div>
      <div class="sfcm-item">
        <h6 class="sfcm-item-title">Diabetes</h6>
        <img src="./assets/img/insurance-marketplace/new/icon-medicare-part-d-plans.png" alt="" class="sfcm-item-img">
      </div>
      <div class="sfcm-item">
        <h6 class="sfcm-item-title">Lung & Respiratory</h6>
        <img src="./assets/img/insurance-marketplace/new/icon-medicare-part-d-plans.png" alt="" class="sfcm-item-img">
      </div>
      <div class="sfcm-item">
        <h6 class="sfcm-item-title">Mental Health</h6>
        <img src="./assets/img/insurance-marketplace/new/icon-medicare-part-d-plans.png" alt="" class="sfcm-item-img">
      </div>
      <div class="sfcm-item">
        <h6 class="sfcm-item-title">Kidney Health</h6>
        <img src="./assets/img/insurance-marketplace/new/icon-medicare-part-d-plans.png" alt="" class="sfcm-item-img">
      </div>
    </div>


    <!-----------------------------------------------------------
      ----------------------PROCEED BUTTON-------------------------
      ------------------------------------------------------------>

    <div class="row mt-5 justify-content-center">
      <div class="d-flex flex-column justify-content-center">
        <button class="btn btn-first btn-small" (click)="isShowView = false">
          Proceed
        </button>
      </div>
    </div>

  </div>
</div>



   <!-----------------------------------------------------------
      ----------------------------2ND VIEW-------------------------
      ------------------------------------------------------------>
  
    <div class="estimate-second-view" *ngIf="!isShowView">

      <h5 class="text-center mb-4 fWeight-600">Enter Your Info Below And We Will Generate Your Custom Drug Costs Analysis Directly For You.</h5>
      <h5 class="text-center mb-4 fWeight-600">Click Below to Proceed To Your Available Plans</h5>
      <table class="table table-bordered">
        <tr>
          <td class="bg-primary" style="width:35%;vertical-align: middle;">
            <h6 class="fColor-white fWeight-600 mb-0">Your Name</h6>
          </td>
          <td><input type="text" class="form-control" [(ngModel)]="inquiryName" name="your-name"></td>
        </tr>
        <tr>
          <td class="bg-primary" style="width:35%;vertical-align: middle;">
            <h6 class="fColor-white fWeight-600 mb-0">Your Email</h6>
          </td>
          <td><input type="email" class="form-control" [(ngModel)]="inquiryEmail" name="your-email"></td>
        </tr>
        <tr>
          <td class="bg-primary" style="width:35%;vertical-align: middle;">
            <h6 class="fColor-white fWeight-600 mb-0">Your Mobile Phone</h6>
          </td>
          <td><input type="text" class="form-control" [(ngModel)]="inquiryPhone" name="your-phone"></td>
        </tr>
      </table>

      <div class="d-flex align-items-center justify-content-center">
        <button type="submit" class="btn btn-third btn-back mx-1" (click)="isShowView = true">
            <span>Back</span>
          </button>
          <button (click)="onSubmitRequestDetails()" class="btn btn-first mx-3 btn-small">Submit
            Request</button>
      </div>
      
    </div>
    <!-- <form>
      <div class="form-group">
        <label for="household-income">What is your approximate total monthly household?</label>
        <input type="text" class="form-control" id="household-income">
      </div>
      <div class="form-group">
        <label for="married">Are you currently married?</label>
        <select class="custom-select form-control">
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>

      <div class="form-group d-flex justify-content-between">
        <button type="submit" class="btn btn-first btn-circle btn-back mx-1" (click)="isShowView = true">
          <span>Back</span>
        </button>
        <button type="submit" class="btn btn-first mx-1 btn-small" (click)="onSearch($event)">Proceed
        </button>
      </div>
    </form> -->
  