<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Privacy Act Statement</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <p class="fw-medium">
        Before making an enrollment decision, it is important that you fully understand our benefits and rules. If you have any questions, you can call and speak to a Customer Service Representative at the number listed on the back cover of this book.
      </p>
    </div>
    <div class="py-3">
      <h5 class="fw-semibold">By Submitting This Enrollment Application, I Agree To The Following:</h5>
      <div class="dashed-box border shadow p-5">
        <h4 class="text-center fw-semibold text-dblue">Check Each Box Below To Submit This Form</h4>
        <div>
          <div class="my-3">
            <h6 class="text-secondary fw-semibold">Understanding the Benefits</h6>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="benefit1" [(ngModel)]="benefit1">    
                <label class="custom-control-label fw-medium text-dblue" for="benefit1">Review the full list of benefits found in the Evidence of Coverage (EOC), especially for those services that you routinely see a doctor.</label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="benefit2" [(ngModel)]="benefit2">    
                <label class="custom-control-label fw-medium text-dblue" for="benefit2">Review the Provider Directory (or ask your doctor) to make sure the doctors you see now are in the network. If they are not listed, it means you will likely have to select a new doctor.</label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="benefit3" [(ngModel)]="benefit3">    
                <label class="custom-control-label fw-medium text-dblue" for="benefit3">Review the Pharmacy Directory to make sure the pharmacy you use for any prescription medicines is in the network. If the pharmacy is not listed, you will likely have to select a new pharmacy for your prescriptions.</label>    
              </div>
            </div>
          </div>
          <div class="my-3">
            <h6 class="text-secondary fw-semibold">Understanding Important Rules</h6>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="rule1" [(ngModel)]="rule1">    
                <label class="custom-control-label fw-medium text-dblue" for="rule1">In addition to your monthly plan premium, you must continue to pay your Medicare Part B premium unless your Part B premium is paid for you by Medicaid or another third party. This premium is normally taken out of your Social Security check each month.</label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="rule2" [(ngModel)]="rule2">    
                <label class="custom-control-label fw-medium text-dblue" for="rule2">Benefits may change on January 1 of each year.</label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="rule3" [(ngModel)]="rule3">    
                <label class="custom-control-label fw-medium text-dblue" for="rule3">Except in emergency or urgent situations, we do not cover services by Out-Of-Network providers (doctors who are not listed in the provider directory).</label>    
              </div>
            </div>
          </div>
          <div class="my-3">
            <h6 class="text-secondary fw-semibold">Understanding TapTap Medicare & It’s Services</h6>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="understand1" [(ngModel)]="understand1">    
                <label class="custom-control-label fw-medium text-dblue" for="understand1">TapTap Medicare is privately owned and operated as a non-government resource for those looking for guidance and clarity regarding Medicare and related insurance planning topics. If you need to visit the Federal Government’s official Medicare website, you can access it here: <span class="yellow">www.medicare.gov</span></label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="understand2" [(ngModel)]="understand2">    
                <label class="custom-control-label fw-medium text-dblue" for="understand2">This website and all of the content provided is for education and illustration purposes & to provide convenience to Medicare beneficiaries only. We recommend you speak with a licensed insurance agent before applying for coverage through our site.</label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="understand3" [(ngModel)]="understand3">    
                <label class="custom-control-label fw-medium text-dblue" for="understand3">These pages do not constitute your application of record for this health insurance plan. </label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="understand4" [(ngModel)]="understand4">    
                <label class="custom-control-label fw-medium text-dblue" for="understand4">After you complete these pages your application of record will be created and made available to you by mail or download. </label>    
              </div>
            </div>
            <div class="p-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="understand5" [(ngModel)]="understand5">    
                <label class="custom-control-label fw-medium text-dblue" for="understand5">We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area. Please contact Medicare.gov or 1-800-MEDICARE to get information on all of your options.</label>    
              </div>
            </div>
          </div>
        </div>
        <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="agreeToAll" [(ngModel)]="agreeToAll" (change)="checkAgree(agreeToAll ? true : false)">    
              <label class="custom-control-label fw-semibold text-dblue" for="agreeToAll">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="agreeToAll" class="d-flex justify-content-center">
    <button class="btn btn-first" (click)="openSuccessModal(enrollmentSuccessModal)">Submit Enrollment</button>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>
  <div #enrollmentSuccessModal>
    <div class="d-flex">
      <div *ngIf="!isLoggedIn" class="py-4 pl-4 align-self-center">
        <h3 class="text-center fw-semibold text-dblue mb-2">Congratulations!</h3>
        <h5 class="text-center fw-medium text-dblue">We Received Your Online Enrollment Application</h5>
        <p class="text-center fw-medium">Create Your Benny HQ To Check Your Application Status And Review Your Plan Details</p>
        <div>
          <p class="text-center"><em>If You Are A New Member, Create An Account</em></p>
          <button class="btn btn-first mx-auto no-shadow" routerLink="/registration">Create My Account Now</button>
          <div class="px-5 py-2">
            <div class="or-separator">
              <hr>
              <span class="px-2">OR</span>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-first btn-small mx-4 no-shadow w-100 bg-blue" routerLink="/login">Login</button>
            <button class="btn btn-third btn-small mx-4 no-shadow w-100 blue-white-btn" (click)="backClicked()">Back To Results</button>
          </div>
        </div>
      </div>
      <div *ngIf="isLoggedIn" class="py-4 pl-4 align-self-center">
        <h3 class="text-center fw-semibold text-dblue mb-2">Congratulations!</h3>
        <h5 class="text-center fw-medium text-dblue">We Received Your Online Enrollment Application</h5>
        <p class="text-center fw-medium">Save Your Application To Your Benny HQ To Check Your Application Status And Review Your Plan Details</p>
        <div>
          <p class="text-center"><em>Save Your Application Info To Your BennyHQ</em></p>
          <button class="btn btn-first mx-auto no-shadow">SAVE TO MY BENNYHQ</button>
          <div class="px-5 py-2">
            <div class="or-separator">
              <hr>
              <span class="px-2">OR</span>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-third btn-small mx-4 no-shadow blue-white-btn" (click)="backClicked()">GO BACK TO MY RESULTS</button>
          </div>
        </div>
      </div>
      <img class="d-none d-md-block pt-4 align-self-end modal-asset" src="./assets/img/half-brown.png" alt="">
    </div>
  </div>
</div>