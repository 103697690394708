<div *ngIf="modalStep == 1">
  <h4 class="uppercase text-center text-dblue" *ngIf="hasHeading">View All Ratings</h4>
  <div class="row" *ngIf="!hasHeading" [class.for-result]="forResult">
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5" [class.mb-3]="forResult">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/12.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Service & Support Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_serviceSupportScore != '' && allRatings_serviceSupportScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 0 && allRatings_serviceSupportScore < 1  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 1 && allRatings_serviceSupportScore < 2  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 2 && allRatings_serviceSupportScore < 3  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 3 && allRatings_serviceSupportScore < 4  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 4 && allRatings_serviceSupportScore < 5  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="allRatings_serviceSupportScore">(</ng-container>
          {{ allRatings_serviceSupportScore != '' ? allRatings_serviceSupportScore : 'Information coming soon'  }}
          <ng-container *ngIf="allRatings_serviceSupportScore">)</ng-container>
        </span>
      </div>
    </div>
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/11.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Price Stability Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_PriceStabilityScore != '' && allRatings_PriceStabilityScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 0 && allRatings_PriceStabilityScore < 1  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 1 && allRatings_PriceStabilityScore < 2  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 2 && allRatings_PriceStabilityScore < 3  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 3 && allRatings_PriceStabilityScore < 4  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 4 && allRatings_PriceStabilityScore < 5  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="allRatings_PriceStabilityScore">(</ng-container>        
          {{ allRatings_PriceStabilityScore != '' ? allRatings_PriceStabilityScore : 'Information coming soon'  }}
          <ng-container *ngIf="allRatings_PriceStabilityScore">)</ng-container>
        </span>
      </div>
    </div>
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5" [class.mb-3]="forResult">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/13.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Overall Consumer Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_overallConsumerScore != '' && allRatings_overallConsumerScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 0 && allRatings_overallConsumerScore < 1  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 1 && allRatings_overallConsumerScore < 2  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 2 && allRatings_overallConsumerScore < 3  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 3 && allRatings_overallConsumerScore < 4  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 4 && allRatings_overallConsumerScore < 5  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="allRatings_overallConsumerScore">(</ng-container>
            {{ allRatings_overallConsumerScore != '' ? allRatings_overallConsumerScore : 'Information coming soon'  }}
            <ng-container *ngIf="allRatings_overallConsumerScore">)</ng-container>
        </span>
      </div>
    </div>
    <!-- <div class="col-12 col-md-6 text-center mt-0 mt-sm-5" *ngIf="providerScore">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/11.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Provider Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="providerScore != '' && providerScore != 'Information coming soon'">
          <i class="fa fColor-orange {{providerScore > 0 && providerScore < 1  ? 'fa-star-half-o' : providerScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{providerScore > 1 && providerScore < 2  ? 'fa-star-half-o' : providerScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{providerScore > 2 && providerScore < 3  ? 'fa-star-half-o' : providerScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{providerScore > 3 && providerScore < 4  ? 'fa-star-half-o' : providerScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{providerScore > 4 && providerScore < 5  ? 'fa-star-half-o' : providerScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="providerScore">(</ng-container>        
          {{ providerScore != '' ? providerScore : 'Information coming soon'  }}
          <ng-container *ngIf="providerScore">)</ng-container>
        </span>
      </div>
    </div> -->
  </div>
  
  <div class="row" *ngIf="!hasHeading" [class.for-result]="forResult">
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5" *ngIf="hasHeading">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/doctor-line.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Provider Favor Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="overallRating != '' && overallRating != 'Information coming soon'">
          <i class="fa fColor-orange {{overallRating > 0 && overallRating < 1  ? 'fa-star-half-o' : overallRating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{overallRating > 1 && overallRating < 2  ? 'fa-star-half-o' : overallRating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{overallRating > 2 && overallRating < 3  ? 'fa-star-half-o' : overallRating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{overallRating > 3 && overallRating < 4  ? 'fa-star-half-o' : overallRating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{overallRating > 4 && overallRating < 5  ? 'fa-star-half-o' : overallRating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="overallRating">(</ng-container>
          {{ overallRating != '' ? overallRating : 'Information coming soon'  }}
          <ng-container *ngIf="overallRating">)</ng-container>
        </span>
      </div>
    </div>
  </div>
  
  <div class="row" *ngIf="hasHeading" [class.for-result]="forResult">
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5" [class.mb-3]="forResult">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/12.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Service & Support Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_serviceSupportScore != '' && allRatings_serviceSupportScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 0 && allRatings_serviceSupportScore < 1  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 1 && allRatings_serviceSupportScore < 2  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 2 && allRatings_serviceSupportScore < 3  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 3 && allRatings_serviceSupportScore < 4  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_serviceSupportScore > 4 && allRatings_serviceSupportScore < 5  ? 'fa-star-half-o' : allRatings_serviceSupportScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="allRatings_serviceSupportScore">(</ng-container>
          {{ allRatings_serviceSupportScore != '' ? allRatings_serviceSupportScore : 'Information coming soon'  }}
          <ng-container *ngIf="allRatings_serviceSupportScore">)</ng-container>
        </span>
      </div>
    </div>
  
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5" *ngIf="hasHeading">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/doctor-line.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Provider Favor Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_ProviderFavorScore != '' && allRatings_ProviderFavorScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_ProviderFavorScore > 0 && allRatings_ProviderFavorScore < 1  ? 'fa-star-half-o' : allRatings_ProviderFavorScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_ProviderFavorScore > 1 && allRatings_ProviderFavorScore < 2  ? 'fa-star-half-o' : allRatings_ProviderFavorScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_ProviderFavorScore > 2 && allRatings_ProviderFavorScore < 3  ? 'fa-star-half-o' : allRatings_ProviderFavorScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_ProviderFavorScore > 3 && allRatings_ProviderFavorScore < 4  ? 'fa-star-half-o' : allRatings_ProviderFavorScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_ProviderFavorScore > 4 && allRatings_ProviderFavorScore < 5  ? 'fa-star-half-o' : allRatings_ProviderFavorScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="allRatings_ProviderFavorScore">(</ng-container>
          {{ allRatings_ProviderFavorScore != '' ? allRatings_ProviderFavorScore : 'Information coming soon'  }}
          <ng-container *ngIf="allRatings_ProviderFavorScore">)</ng-container>
        </span>
      </div>
    </div>
  
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5" [class.mb-3]="forResult">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/13.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Overall Consumer Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_overallConsumerScore != '' && allRatings_overallConsumerScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 0 && allRatings_overallConsumerScore < 1  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 1 && allRatings_overallConsumerScore < 2  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 2 && allRatings_overallConsumerScore < 3  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 3 && allRatings_overallConsumerScore < 4  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_overallConsumerScore > 4 && allRatings_overallConsumerScore < 5  ? 'fa-star-half-o' : allRatings_overallConsumerScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="allRatings_overallConsumerScore">(</ng-container>
            {{ allRatings_overallConsumerScore != '' ? allRatings_overallConsumerScore : 'Information coming soon'  }}
            <ng-container *ngIf="allRatings_overallConsumerScore">)</ng-container>
        </span>
      </div>
    </div>
  
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
      <div class="img-wrapper">
        <img src="./assets/img/common/icons/new-view-all-ratings/11.png" alt="">
      </div>
      <h6 class="uppercase fColor-blue mt-2">Price Stability Score</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_PriceStabilityScore != '' && allRatings_PriceStabilityScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 0 && allRatings_PriceStabilityScore < 1  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 1 && allRatings_PriceStabilityScore < 2  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 2 && allRatings_PriceStabilityScore < 3  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 3 && allRatings_PriceStabilityScore < 4  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 4 && allRatings_PriceStabilityScore < 5  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span class="fWeight-600 fColor-orange mb-1">
          <ng-container *ngIf="allRatings_PriceStabilityScore">(</ng-container>        
          {{ allRatings_PriceStabilityScore != '' ? allRatings_PriceStabilityScore : 'Information coming soon'  }}
          <ng-container *ngIf="allRatings_PriceStabilityScore">)</ng-container>
        </span>
      </div>
    </div>
    
  </div>
</div>

<div *ngIf="modalStep == 2">
  <h4 class="uppercase text-center text-dblue">Plan Profile</h4>
  <div class="row justify-content-center">
    <div class="col-12 py-3">
      <h1 class="big-text text-center">{{ (planProfile_wellnessFitnessProgramInclusion) ? planProfile_wellnessFitnessProgramInclusion : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">Wellness/Fitness Program Inclusion</h6>
      <button class="btn btn-first btn-xs bg-blue mx-auto">View Fitness Program</button>
    </div>
    <div class="col-12 col-md-6 py-3">
      <h1 class="big-text text-center">{{ (planProfile_householdDiscountConditions) ? planProfile_householdDiscountConditions : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">Household Discount Conditions</h6>
    </div>
    <div class="col-12 col-md-6 py-3">
      <h1 class="big-text text-center">{{ (planProfile_enrollmentDiscountConditions) ? planProfile_enrollmentDiscountConditions : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">Enrollment Discount Conditions</h6>
    </div>
    <div class="col-12 col-md-6 py-3">
      <h1 class="big-text text-center">${{ (planProfile_onetimeCarrierEnrollmentFee) ? planProfile_onetimeCarrierEnrollmentFee : '0' }}</h1>
      <h6 class="uppercase mt-2 text-center">One-time Carrier Enrollment Fee</h6>
    </div>
    <div class="col-12 col-md-6 py-3">
      <h1 class="big-text text-center">${{ (planProfile_onetimeTaptapEnrollmentFee) ? planProfile_onetimeTaptapEnrollmentFee : '0' }}</h1>
      <h6 class="uppercase mt-2 text-center">One-time TapTap Enrollment Fee</h6>
    </div>
    <div class="col-12">
      <button class="btn btn-first btn-small mx-auto bg-dblue">Plan Brochure</button>
    </div>
  </div>
</div>

<div *ngIf="modalStep == 3">
  <h4 class="uppercase text-center text-dblue">Price Record</h4>
  <div class="row justify-content-center">
    <div class="col-12 py-3">
      <div class="d-flex justify-content-center">
        <img class="img-pricestab" src="./assets/img/price-stab.png" alt="">
      </div>
      <h6 class="uppercase mt-2 text-center">Price Stability Rating</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="star-rating-section mr-2" *ngIf="allRatings_PriceStabilityScore != '' && allRatings_PriceStabilityScore != 'Information coming soon'">
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 0 && allRatings_PriceStabilityScore < 1  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 1 && allRatings_PriceStabilityScore < 2  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 2 && allRatings_PriceStabilityScore < 3  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 3 && allRatings_PriceStabilityScore < 4  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
          <i class="fa fColor-orange {{allRatings_PriceStabilityScore > 4 && allRatings_PriceStabilityScore < 5  ? 'fa-star-half-o' : allRatings_PriceStabilityScore >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
        </div>
        <span *ngIf="allRatings_PriceStabilityScore" class="fWeight-600 mb-1">
          (     
          {{ allRatings_PriceStabilityScore != '' ? allRatings_PriceStabilityScore : 'Information coming soon'  }}
          )
        </span>
      </div>
    </div>
    <div class="col-12 col-md-4 py-3">
      <h1 class="big-text text-center">{{ (priceRecord_2018RateIncrease) ? priceRecord_2018RateIncrease : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">2018 Rate Increase</h6>
    </div>
    <div class="col-12 col-md-4 py-3">
      <h1 class="big-text text-center">{{ (priceRecord_2019RateIncrease) ? priceRecord_2019RateIncrease : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">2019 Rate Increase</h6>
    </div>
    <div class="col-12 col-md-4 py-3">
      <h1 class="big-text text-center">{{ (priceRecord_2020RateIncrease) ? priceRecord_2020RateIncrease : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">2020 Rate Increase</h6>
    </div>
    <div class="col-12 col-md-4 py-3">
      <h1 class="big-text text-center">{{ (priceRecord_2021RateIncrease) ? priceRecord_2021RateIncrease : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">2021 Rate Increase</h6>
    </div>
    <div class="col-12 col-md-4 py-3">
      <h1 class="big-text text-center">{{ (priceRecord_2022RateIncrease) ? priceRecord_2022RateIncrease : '0' }}%</h1>
      <h6 class="uppercase mt-2 text-center">2022 Rate Increase</h6>
    </div>
  </div>
</div>

<div *ngIf="hasHeading" class="mt-4">
  <a target="_blank" class="btn btn-first btn-small mx-auto max-w-fit" routerLink="/insurance-marketplace/medigap/single-plan">TapTap Interactive Plan Page</a>
  <div class="d-flex justify-content-center mt-3">
    <button *ngIf="modalStep > 1" class="btn btn-first btn-small bg-bblue mx-2" (click)="modalStep = modalStep - 1">Back</button>
    <button *ngIf="modalStep < 3" class="btn btn-first btn-small bg-blue mx-2" (click)="modalStep = modalStep + 1">Next</button>
  </div>
</div>