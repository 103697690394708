import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs/index';
import Swal from 'sweetalert2'; 
import { AuthService } from '../services/auth.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isOpen = true;
  fullPrimaryHeader:boolean = true;
  isLoggedInUser:any;

  showBanner:boolean = true;

  toggle() {
    this.isOpen = !this.isOpen;
  }

  users: Observable<any>;
  constructor(private db: AngularFirestore,public _auth: AuthService, private titleService:Title) { 
    this.titleService.setTitle("Clear & Organized Medicare Experience  - TapTap Medicare");
  }

  ngOnInit() {
    this.users = this.db.collection('users')
          .valueChanges();

    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if(this.isLoggedInUser){
        this.fullPrimaryHeader = false;
    }
  }

  watchMore(content){
    Swal.fire({
      html:content,
      // html: '<video width="100%" controls>' +
      //     '<source src="../assets/videos/Medicare Supplement Instant Quote Final Cut.mp4">' +
      //   '</video>',
      showConfirmButton: false,
      customClass: 'watchMoreVideoWrapper',
      width: 900
    })
  }

}
