<h4 class="title mb-5">Attestation of Eligibility for an Enrollment Period</h4>
<p>Typically, you may enroll in a Medicare Advantage plan only during the annual enrollment period from
  October 15 through December 7 of each year. There are exceptions that may allow you to enroll in a Medicare Advantage plan outside of this period.
  </p>
  <p>Please read the following statements carefully and check the box next to the statements that apply to you. </p>
  <ul>
    <li>
      By checking any of the following boxes, you are certifying that, to the best of your knowledge, you are eligible for an Enrollment Period.
    </li>
    <li>
      If the plan insurance company later determines that this information is incorrect, you may be disenrolled from the plan.
    </li>
  </ul>
  <h5>The Following Scenario Applies To Me (Check Any That Apply):</h5>

  <ng-container *ngIf="viewMore1">
    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">Most Common</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="mostCommon_1">
          <label class="custom-control-label" for="mostCommon_1">I Am “New to Medicare”</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="mostCommon_2">
          <label class="custom-control-label" for="mostCommon_2">I Have Been On Medicare But <strong>I Am Just Turning Age 65 Now</strong></label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="mostCommon_3">
          <label class="custom-control-label" for="mostCommon_3"><strong>I Moved</strong>/Changed Where I Live</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="mostCommon_4">
          <label class="custom-control-label" for="mostCommon_4"><strong>I Lost Other Coverage</strong> or Had An Involuntary Change In My Coverage</label>
        </div>
      </div>
      
    </div>

    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">Financial Circumstances</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="mostCommon_1">
          <label class="custom-control-label" for="mostCommon_1">I Am Currently Enrolled In A <strong>Financial Assistance Program</strong> (Like Extra Help, Medicaid, or a State Drug Cost Assistance Program)</label>
        </div>
        <a href="#">Click Here To See The Full List Based On Your State</a>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="mostCommon_2">
          <label class="custom-control-label" for="mostCommon_2">I Recently <strong>Had A Change In My Eligibility</strong> For a Financial Assistance Program</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="mostCommon_3">
          <label class="custom-control-label" for="mostCommon_3">I Have Low Income & Want To <strong>Check If I Am Eligible</strong> For a Financial Assistance Program</label>
        </div>
      </div>    
    </div>

    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">Other Circumstances</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="otherCircumstances_1">
          <label class="custom-control-label" for="otherCircumstances_1">I’m In a Medicare Advantage Plan Now & <strong>Have Had Medicare For Less Than 3 Months &</strong> Want to Make a Change</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="otherCircumstances_2">
          <label class="custom-control-label" for="otherCircumstances_2">I Was <strong>Affected By a FEMA-Designated Emergency</strong> (or Major Disaster) That Caused Me To Be Unable To Make A Prior Eligible Enrollment Due To The Designated Emergency</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="otherCircumstances_3">
          <label class="custom-control-label" for="otherCircumstances_3">I’m Moving Into, Live In, Or Recently Moved Out Of A <strong>Long-Term Care Facility</strong> (Like A Nursing Home or Rehabilitation Hospital)</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="otherCircumstances_4">
          <label class="custom-control-label" for="otherCircumstances_4">I Recently Received <strong>Lawful Presence Status In The U.S</strong></label>
        </div>
      </div>
      
    </div>

    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">Based On The Previous or Existing Coverage I’ve Had</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="basedOnPreviousCoverage_1">
          <label class="custom-control-label" for="basedOnPreviousCoverage_1">I’m In A Plan That’s <strong>Had a Star Rating Of Less Than 3 Stars</strong> For The Last 3 Years. I Want To Join A Plan With A Star Rating Of 3 Stars Or Higher</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="basedOnPreviousCoverage_2">
          <label class="custom-control-label" for="basedOnPreviousCoverage_2"><strong>I Was Automatically Enrolled</strong> In A Plan By Medicare (Or My State) & I Want To Choose A Different Plan</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="basedOnPreviousCoverage_3">
          <label class="custom-control-label" for="basedOnPreviousCoverage_3"><strong>I No Longer Qualify For My Previous</strong> Chronic Illness Special Needs Plan Or Dual Eligible Special Needs Plan</label>
        </div>
      </div>    
    </div>

    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">Time-of-Year or Calendar-Based Periods</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="timeOfYearCalendar_1">
          <label class="custom-control-label" for="timeOfYearCalendar_1">I Am <strong>Within October 15th - December 7th</strong> (The Annual Enrollment Period)</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="timeOfYearCalendar_2">
          <label class="custom-control-label" for="timeOfYearCalendar_2">I Have A Medicare Advantage Plan Currently & Am <strong>Within January 1st - March 31st</strong> (The Medicare Advantage Open Enrollment Period)</label>
        </div>
      </div>  
    </div>

    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">Characteristics of The Plan You Are Enrolling In</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="characteristicsPlanEnrolling_1">
          <label class="custom-control-label" for="characteristicsPlanEnrolling_1">I Am Enrolling Into a <strong>5-Star Plan</strong></label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="characteristicsPlanEnrolling_2">
          <label class="custom-control-label" for="characteristicsPlanEnrolling_2">I Am Enrolling Into a <strong>Chronic Illness Special Needs Plan</strong> That I Qualify For</label>
        </div>
      </div>  
    </div>

    <div class="d-flex justify-content-center align-items-center my-4">
      <button (click)="onShow_View2()" class="btn btn-second btn-small">View More</button>  
      <span class="fWeight-400 text-center mx-2">| Or, Use Our</span> 
      <span class="fColor-blue fWeight-600">Interactive Enrollment Period Identifier</span>
    </div>

    <p class="text-center">If none of these statements applies to you or you’re not sure, please contact TapTap Medicare at 1(800) 215-5108 Monday-Friday, 9:00AM EST-6:30PM EST to determine your Enrollment Period Qualifiers that will allow you to enroll. </p>
  </ng-container>

  <ng-container *ngIf="viewMore2">
    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">You Lose Or Have A Change In Your Current Coverage</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="youLoseOrHaveAChange_1">
          <label class="custom-control-label" for="youLoseOrHaveAChange_1">I Involuntarily Lost Other Drug Coverage That’s As Good As Medicare Drug Coverage (Creditable Coverage), or My Other Coverage Changed & Is No Longer Creditable.</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="youLoseOrHaveAChange_2">
          <label class="custom-control-label" for="youLoseOrHaveAChange_2">I Dropped My Coverage in a Programs of All-Inclusive Care for the Elderly plan.</label>
        </div>
      </div>  
    </div>
    <div class="row bordered p-3">
      <div class="col-12">
        <h6 class="fWeight-600 fColor-black">Special Situations</h6>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="specialSituation_1">
          <label class="custom-control-label" for="specialSituation_1">I Joined a Plan, or Chose Not To Join a Plan, Due To An Error By a Federal Employee</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="specialSituation_2">
          <label class="custom-control-label" for="specialSituation_2">I Wasn't Properly Told That My Other Private Drug Coverage Wasn't As Good As Medicare Drug Coverage (Creditable Coverage).</label>
        </div>
      </div>  
      <div class="col-12 col-sm-6">      
        <div class="custom-control custom-checkbox mt-2 mr-5">
          <input type="checkbox" class="custom-control-input" id="specialSituation_3">
          <label class="custom-control-label" for="specialSituation_3">I Wasn't Properly Told That I Was Losing Private Drug Coverage That Was As Good As Medicare Drug Coverage (Creditable Coverage).</label>
        </div>
      </div>
    </div>
    <button (click)="onShow_View3()" class="btn btn-second btn-small">View More</button>
  </ng-container>

  <ng-container *ngIf="viewMore3">
    <div class="row bordered p-3">
      <div class="col-12">    
        <p class="fWeight-600 fColor-black">Will you have other prescription drug coverage (like VA, TRICARE) in addition to this Medicare Advantage Plan, [Medicare Advantage Plan Name]?</p>  
        <div class="d-flex">
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">Yes</label>
          </div>
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">No</label>
          </div>
        </div>        
      </div>
      <div class="col-12">
        <div class="row px-0">
          <div class="col-12 col-sm-4 pr-2">
              <div class="form-group">
                  <label for="legalFirstName">Name of other coverage</label>
                  <input type="text" class="form-control blurred" id="legalFirstName"/>
              </div>        
          </div>
          <div class="col-12 col-sm-4">
              <div class="form-group">
                  <label for="legalMiddleName">ID # for this coverage</label>
                  <input type="text" class="form-control blurred" id="legalMiddleName"/>
              </div>
          </div>
          <div class="col-12 col-sm-4 pl-2">
              <div class="form-group">
                  <label for="legalLastName">Group # for this coverage:</label>
                  <input type="text" class="form-control blurred" id="legalLastName"/>
              </div>
          </div>
      </div> 
      </div>
    </div>
    <div class="row bordered p-3">
      <div class="col-12">    
        <p class="fWeight-600 fColor-black">Are you a resident in a long-term care facility, such as a nursing home?</p>  
        <div class="d-flex">
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">Yes</label>
          </div>
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">No</label>
          </div>
        </div>        
      </div>
      <div class="col-12">
        <div class="row px-0">
          <div class="col-12">
            <p>If “yes,” please provide the following information:</p>
          </div>
          <div class="col-12 pr-2">
              <div class="form-group">
                  <label for="legalFirstName">Name of Institution:</label>
                  <input type="text" class="form-control blurred" id="legalFirstName"/>
              </div>        
          </div>
          <div class="col-12">            
              <div class="form-group">
                  <label for="legalMiddleName">Address & Phone Number of Institution (number and street):</label>
                  <textarea name="" id="" class="form-control blurred" cols="30" rows="10"></textarea>
              </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="row bordered p-3">
      <div class="col-12">    
        <p class="fWeight-600 fColor-black">Do you work?</p>  
        <div class="d-flex">
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">Yes</label>
          </div>
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">No</label>
          </div>
        </div>        
      </div>
      <div class="col-12">    
        <p class="fWeight-600 fColor-black">Does your spouse work?</p>  
        <div class="d-flex">
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">Yes</label>
          </div>
          <div class="custom-control custom-checkbox mt-2 mr-5">
            <input type="checkbox" class="custom-control-input" id="mostCommon_1">
            <label class="custom-control-label" for="mostCommon_1">No</label>
          </div>
        </div>        
      </div>
    </div>
    <div class="row bordered p-3 mb-5">
      <div class="col-12">    
        <p class="fWeight-600 fColor-black">Please check any of the boxes below if you would prefer us to send you information in a language other than English or in an accessible format:</p>  
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 d-flex">
            <div class="custom-control custom-checkbox mt-2 mr-5">
              <input type="checkbox" class="custom-control-input" id="mostCommon_1">
              <label class="custom-control-label" for="mostCommon_1">Other language (please specify)</label>
            </div>
            <input type="text" class="form-control"/>
          </div>
          <div class="col-12">
            <div class="custom-control custom-checkbox mt-2 mr-5">
              <input type="checkbox" class="custom-control-input" id="mostCommon_1">
              <label class="custom-control-label" for="mostCommon_1">Braille</label>
            </div>
          </div>
          <div class="col-12">
            <div class="custom-control custom-checkbox mt-2 mr-5">
              <input type="checkbox" class="custom-control-input" id="mostCommon_1">
              <label class="custom-control-label" for="mostCommon_1">Audio tape</label>
            </div>
          </div>
          <div class="col-12">
            <div class="custom-control custom-checkbox mt-2 mr-5">
              <input type="checkbox" class="custom-control-input" id="mostCommon_1">
              <label class="custom-control-label" for="mostCommon_1">Large print</label>
            </div>
          </div>        
        </div>             
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12">
        <p>Please contact this Medicare Advantage Plan Insurance Carrier, [Medicare Advantage Carrier Brand Name] if you need information in an accessible format or language other than what is listed above.</p>
        <h6 class="mt-5">Privacy Act Statement</h6>
        <p>The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track
          beneficiary enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1851 and 1860D-1 of the Social Security Act and 42 CFR §§ 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and exchange enrollment data from Medicare beneficiaries as specified in the System of Records Notice (SORN) “Medicare Advantage Prescription Drug (MARx)”, System No. 09-70-0588. Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.
          </p>
      </div>
    </div>
  </ng-container>