<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain banner-result">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper pb-0">
    <div class="row">
      <div class="col-12">
        <h1 class="banner-main-title text-center">
          My RX Preferences
        </h1>
      </div>
    </div>
  </div>
</div>
<img class="d-none d-lg-block banner-image" src="/assets/img/beneficiary/my-providers/bg-providers.svg" alt="">
<div class="main-content-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-9 mapd-drugs-left">
        <div class="dp-card">
          <h4 class="text-center fWeight-600 mt-3">Specific Drug Details</h4>
          <h6 class="fColor-base fWeight-600 text-center">Confirm Your Drug</h6>
          <div *ngIf="formCheck == 'tablet'">
            <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <div *ngIf="formCheck == 'capsule'">
            <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <div *ngIf="formCheck == 'injection'">
            <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <div *ngIf="formCheck == 'meds'">
            <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <!-- <img *ngIf="results[0][0]['dosage_form'] == 'TABLET'||'TABLET, FILM COATED'" src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90"> -->
          <!-- <img *ngIf="results[0][0]['dosage_form'] == 'CAPSULE'||'CAPSULE, FILM COATED'" src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90"> -->
          <app-loading *ngIf="isLoading" class="loading-fixed"></app-loading>
          
          <!-- default details -->
          <div class="row drug-details text-center text-md-left mt-4" *ngIf="results.length">
            <div class="col-12">
              <div class="row">
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Drug Name:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ strip_drug_name }}</span>
              </div>
              <div class="row mt-3 align-items-center">
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Dosage:</span>
                <ng-container *ngIf="!showEditFields">  
                    <span class="col-12 col-md-8 fWeight-500">{{ results[0][0]['active_numerator_strength'] + ' ' + results[0][0]['active_ingredient_unit'].slice(0, results[0][0]['active_ingredient_unit'].indexOf('/')) }}</span>
                </ng-container>
                <ng-container *ngIf="showEditFields">
                  <mat-form-field class="col-md-8 pl-2">
                    <mat-select (selectionChange)="onChange_Dosage($event)" [(value)]="edit_dosageValue">
                      <mat-option value="{{ drug.rxcui }}" *ngFor="let drug of branded">{{ drug.drug_info.dosage }}</mat-option>   
                      <mat-option value="{{ drug.rxcui }}" *ngFor="let drug of generic">{{ drug.drug_info.dosage }}</mat-option>                   
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="row mt-3 align-items-center">
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Amount Taken:</span>
                <ng-container *ngIf="!showEditFields">
                  <span class="col-12 col-md-8 fWeight-500">{{ paramsObject['params'].times_taken + '/' + paramsObject['params'].often_taken.replace('per-','') }}</span>
                </ng-container>
                <ng-container *ngIf="showEditFields">
                  <mat-form-field class="col-md-4 pl-2 pr-2">
                    <mat-select (selectionChange)="onChange_TimesTaken($event)" [(value)]="edit_timesTakenValue" >
                      <mat-option value="1">1</mat-option>
                      <mat-option value="2">2</mat-option>
                      <mat-option value="3">3</mat-option>
                      <mat-option value="4">4</mat-option>
                      <mat-option value="5">5</mat-option>
                      <mat-option value="6">6</mat-option>
                      <mat-option value="7">7</mat-option>
                      <mat-option value="8">8</mat-option>
                      <mat-option value="9">9</mat-option>
                      <mat-option value="10">10</mat-option>

                      <mat-option value="11">11</mat-option>
                      <mat-option value="12">12</mat-option>
                      <mat-option value="13">13</mat-option>
                      <mat-option value="14">14</mat-option>
                      <mat-option value="15">15</mat-option>
                      <mat-option value="16">16</mat-option>
                      <mat-option value="17">17</mat-option>
                      <mat-option value="18">18</mat-option>
                      <mat-option value="19">19</mat-option>
                      <mat-option value="20">20</mat-option>

                      <mat-option value="21">21</mat-option>
                      <mat-option value="22">22</mat-option>
                      <mat-option value="23">23</mat-option>
                      <mat-option value="24">24</mat-option>
                      <mat-option value="25">25</mat-option>
                      <mat-option value="26">26</mat-option>
                      <mat-option value="27">27</mat-option>
                      <mat-option value="28">28</mat-option>
                      <mat-option value="29">29</mat-option>
                      <mat-option value="30">30</mat-option>

                      <mat-option value="45">45</mat-option>
                      <mat-option value="60">60</mat-option>
                      <mat-option value="90">90</mat-option>
                      <mat-option value="120">120</mat-option>
                      <mat-option value="150">150</mat-option>
                      <mat-option value="180">180</mat-option>
                      <mat-option value="210">210</mat-option>
                      <mat-option value="240">240</mat-option>
                      <mat-option value="360">360</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-md-4 pl-2">
                    <mat-select (selectionChange)="onChange_OftenTaken($event)" [(value)]="edit_oftenTakenValue" >
                      <mat-option value="per-day">Per Day</mat-option>
                      <mat-option value="per-meal">Per Meal</mat-option>
                      <mat-option value="per-week">Per Week</mat-option>
                      <mat-option value="per-month">Per Month</mat-option>                      
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="row mt-3 align-items-center">
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Days Supply:</span>
                <ng-container *ngIf="!showEditFields">
                  <span class="col-12 col-md-8 fWeight-500">{{ paramsObject['params'].days_supply }}</span>
                </ng-container>
                <ng-container *ngIf="showEditFields">
                  <mat-form-field class="col-md-8 pl-2">
                    <mat-select (selectionChange)="onChange_DaysSupply($event)" [(value)]="edit_daysSupplyValue">
                      <mat-option value="30">30</mat-option>
                      <mat-option value="60">60</mat-option>
                      <mat-option value="90">90</mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="row mt-3 align-items-center" *ngIf="drug_refill">
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Refill:</span>
                <ng-container *ngIf="!showEditFields">
                  <span class="col-12 col-md-8 fWeight-500">{{ drug_refill }}</span>
                </ng-container>
                <ng-container *ngIf="showEditFields">
                  <mat-form-field class="col-md-8 pl-2">
                    <mat-select (selectionChange)="onChange_Refill($event)" [(value)]="edit_refillValue">
                      <mat-option value="1">Once Per Year</mat-option>
                      <mat-option value="2">Twice Per Year</mat-option>
                      <mat-option value="3">3-4 Times Per Year</mat-option>
                      <mat-option value="4">5-6 Times Per year</mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Package Form:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ (results[0][0]['dosage_form_name']) ? results[0][0]['dosage_form_name'].replace('TABLET','ORAL TABLET') : 'n/a'}}</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Dosage Form:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ (results[0][0]['dosage_form']) ? results[0][0]['dosage_form'].replace('TABLET','ORAL TABLET') : 'n/a'}}</span>
              </div>
            </div>
          </div>

          <!-- additional details -->
          <div class="row drug-details text-center text-md-left mt-5" *ngIf="results.length && showMoreDrugDetails">
            <h5 class="col-12 fWeight-600 mb-2">Additional Details</h5>
            <div class="col-12">              
              <div class="row mt-3">
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Manufacturer:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ (results[0][0]['labeler_name']) ? results[0][0]['labeler_name'] : 'n/a' }}</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">US Drug Release Date:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ results[0][0]['start_marketing_date'] | date: 'MM/dd/yyyy' }}</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Brand Name Version:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ (results[0][0]['proprietary_name']) ? results[0][0]['proprietary_name'] : 'n/a' }}</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Generic Name Version:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ (results[0][0]['non_proprietary_name']) ? results[0][0]['non_proprietary_name'] : 'n/a' }}</span>
              </div>
              <div class="row mt-3">
                <span class="fWeight-600 col-12 col-md-4 fWeight-600 fColor-black">Drug NDC:</span>
                <span class="col-12 col-md-8 fWeight-500">{{ (results[0][0]['ndc']) ? results[0][0]['ndc'] : 'n/a' }}</span>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <!-- <button class="btn btn-second btn-small mr-3" (click)="onClick_ChangeDrug()" *ngIf="!this.isEditDrug">Change Drug</button> -->
            <button class="btn btn-second btn-xs mr-3" (click)="onClick_editBtn()" *ngIf="!showEditFields">Edit</button>
            <button class="btn btn-second btn-xs mr-3" (click)="onClick_edit_SaveBtn()" *ngIf="showEditFields">Save</button>
            <button class="btn btn-second-fill btn-xs" (click)="onClick_MoreDrugDetails(showMoreDrugDetails)">{{ moreDrugDetails_text }}</button>
            <button class="btn btn-third btn-xs ml-3" (click)="onClick_addNote(addNoteModal)">Add Note</button>
          </div>

          <!-- <button class="btn btn-first btn-small mx-auto mt-5 ping" (click)="onClick_AddDrugToList(drugAddedToYourListModal)">{{ (isEditDrug) ? 'Edit your List' : 'Add to your List'  }}</button> -->
          <button class="pulse-button ping mt-5 mb-3" (click)="onClick_AddDrugToList(drugAddedToYourListModal)">
            {{ (isEditDrug) ? 'Edit your List' : 'Add to your List'  }}
          </button>

        </div>
      </div>
      <div class="col-12 col-md-3 mapd-drugs-right">
        <app-common-your-drug-list></app-common-your-drug-list>
        <!-- <button class="btn btn-first btn-small mx-auto mt-3" (click)="onClick_DoneAddingDrugs(drugAddedToYourListModal)">Done Adding My Drugs</button> -->
      </div>
    </div>
  </div>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk class="noprint"></app-ready-to-talk>


<div class="d-none">
  <div class="text-center" #drugAddedToYourListModal>
    <img src="./assets/img/my-drugs/yay-drugs.svg" class="regular-icon" alt="Hands Clap">
    <h2>YAY!</h2>
    <h5>Your Drug Has Been Added To Your List!</h5>
    <p class="fWeight-600">What would you like to do next?</p>
    <div class="drug-profile-info row" *ngIf="results.length">
      <div class="col-10 mx-auto">
        <div class="cta-btn-wrapper">
          <button class="btn btn-third cta-btn no-hover-bg" (click)="addAnotherDrug()"><img src="./assets/img/my-drugs/drug-add.svg">Add Another Drug</button>
          <button class="btn btn-third cta-btn no-hover-bg" (click)="addPharmacy()"><img src="./assets/img/my-drugs/drug-pharmacy.svg">Add Pharmacy</button>
          <!-- <button class="btn btn-third cta-btn no-hover-bg" (click)="onClick_YourDrugListNotes(yourDrugListNotesModal)"><img src="./assets/img/my-drugs/your-notes.svg">Add A Note To Your Drug</button> -->
          <!-- <button class="btn btn-third cta-btn no-hover-bg" (click)="showModal(yourDrugListInsightsModal)"><img src="./assets/img/my-drugs/your-insights.svg">Your Drug List Insights</button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" #yourDrugListInsightsModal>
    <img src="./assets/img/my-drugs/your-insights.png" class="regular-icon mb-3" alt="Hands Clap">
    <h5>Your Drug List Insights</h5>
    <p class="fWeight-600">Please see below:</p>
    <div class="text-left p-3">
      <ul>
        <li>Your Take 6 Total Prescription Drugs</li>
        <li>3 Prescription Drugs Are For Blood Pressure</li>
        <li>4 Prescription Drugs Are Pill Form</li>
        <li>2 Drugs Are Solutions</li>
        <li>5 Are Generic Drugs</li>
        <li>1 Is a Brand Name Drug</li>
      </ul>
    </div>
  </div>
  <div class="notes-modal text-center" #yourDrugListNotesModal>
    <img src="./assets/img/my-drugs/your-notes.png" class="regular-icon mb-3" alt="Hands Clap">
    <h5>Your Drug List Notes</h5>
    <p class="fWeight-600">Please enter your notes below:</p>
    <div class="text-left p-3">
      <textarea class="notes-field" name="existingRxDrugListNotes" [(ngModel)]="existingRxDrugListNotes" id="" placeholder="Enter your notes here...">{{existingRxDrugListNotes}}</textarea>
    </div>
    <button class="btn btn-first btn-small mx-auto" (click)="onSaveRxDrugListNotes()">Save</button>
  </div>
  <div #addNoteModal>
    <app-drug-notes-form (drugNotesTags)="onClick_setNotes($event)" [tagger]="single_tags"></app-drug-notes-form>
  </div>
</div>