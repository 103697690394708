import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medigap-version1',
  templateUrl: './version1.component.html',
  styleUrls: ['./version1.component.scss']
})
export class Version1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
