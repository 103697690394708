<div class="notes-modal text-center" >
  <app-loading *ngIf="isLoading_Modal"></app-loading>
  <img src="./assets/img/my-drugs/your-notes.png" class="regular-icon mb-3" alt="Hands Clap">
  <h5>Your Notes for your List</h5>
  <p class="fWeight-600">Please enter your notes below:</p>
  <div class="text-left p-3">
    <textarea class="notes-field" name="existingRxDrugListNotes" id="" placeholder="Enter your notes here..." [(ngModel)]="notes">{{ notes }}</textarea>
  </div>
  <div class="m-3">
    <p class="text-left"><strong>Tags:</strong></p>
    <!-- <input class="w-100 px-3 py-2" type="text" placeholder="Enter tags here..." [(ngModel)]="tags"> -->

    <mat-form-field class="tag-chip-list">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let tag of tagger" [selectable]="selectable"
                 [removable]="removable" (removed)="remove(tag)">
          {{tag}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
    </mat-form-field>

  </div>
  <button class="btn btn-first btn-small mx-auto" (click)="onClick_SaveBtn()">Save</button>
</div>