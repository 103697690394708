import {Component, OnInit} from '@angular/core';
import {DrugsService} from '../../../services/drugs.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import Swal from 'sweetalert2';
import {Subscription, forkJoin} from 'rxjs';
import confetti from 'canvas-confetti'

@Component({
  selector: 'app-common-step3',
  templateUrl: './common-step3.component.html',
  styleUrls: ['./common-step3.component.scss']
})
export class CommonStep3Component implements OnInit {

  rxcui: any;
  promise1: any;
  promise2: any;
  results: any[] = [];
  isLoading: boolean = false;
  existingEntries_drugs_list: any;
  paramsObject: any;
  showMoreDrugDetails:boolean = false;
  moreDrugDetails_text:string = 'More Drug Details';
  subscription1: Subscription;
  existingRxDrugListNotes:any;

  existingEntries_drugs_list_item = [];

  formCheck:any = [];

  strip_drug_name:any;

  isEditDrug:boolean = false;  //EDIT DRUG - initialize isEditDrug variable on editing drug
  
  isUserLoggedIn:boolean;

  drugListID: any;

  drug_refill:any;

  edit_dosageValue:any;
  edit_amountTakenValue:any;
  edit_daysSupplyValue:any;
  edit_timesTakenValue:any;
  edit_oftenTakenValue:any;
  edit_refillValue:any;
  showEditFields:boolean = false;

  branded: any[] = [];
  generic: any[] = [];
  generic_grouped: any = [];
  branded_grouped: any = [];
  step1Results: any;
  single_notes: any = "";
  single_tags:any = [];
  marketplaceType:any;
  paramMarketplaceType: any = 'mapd';

  constructor(public drugService: DrugsService, private _router: Router, private route: ActivatedRoute, private _auth: AuthService) {
    

    this.isUserLoggedIn = _auth.isAuthenticated(); // check if user logged in

    const existingDrugs = localStorage.getItem('rx_add_to_drug_list');
    if (existingDrugs) {
      this.existingEntries_drugs_list = JSON.parse(existingDrugs);
    }
    
    //EDIT DRUG - get edit_drug parameter from url on editing drug
    this.route.queryParamMap // listen to query param change
      .subscribe((params) => {
          this.rxcui = this.route.snapshot.paramMap.get('rxcui');
          this.paramsObject = {...params };
          this.isEditDrug = this.paramsObject['params'].edit_drug; 
          this.step3();     
          this.showEditFields = false;
        
          // check if param has marketplace_type
          if(this.paramsObject['params'].marketplace_type){
            this.paramMarketplaceType = this.paramsObject['params'].marketplace_type;

            if(this.paramMarketplaceType == 'pdp'){
              this.marketplaceType = 'medicare-drug-coverage';
            }else if(this.paramMarketplaceType == 'medigap'){
              this.marketplaceType = 'medigap';
            }else if(this.paramMarketplaceType == 'dental'){
              this.marketplaceType = 'dental';
            }else{
              this.marketplaceType = 'medicare-advantage';
            }
            
          }else{
            this.marketplaceType = 'medicare-advantage';
          }
          //end check if param has marketplace_type

        }
    );
    
    this.route.params.subscribe(params => { // listen to param change
        this.rxcui = params['rxcui'];
        this.step3();     
        this.showEditFields = false;
    });

    this.getMarketplaceDrugListID();

    if(this.paramsObject['params'].drug_refill){
      switch(this.paramsObject['params'].drug_refill) {
        case '1':
          this.drug_refill = 'Once Per Year';
          break;
        case '2':
          this.drug_refill = 'Twice Per Year';
          break;
        case '3':
          this.drug_refill = '3-4 Times Per Year';
          break;
        default:
          this.drug_refill = '5-6 Times Per year';
      }
    }

  }

  ngOnInit() {
    this.subscription1 = this.drugService.retrieveList().subscribe(d => this.existingEntries_drugs_list = d);
  }

  async getMarketplaceDrugListID(){
    const list = await this.drugService.getDrugLists();    
    const listInfo_filter = list.data.filter(list => list.name.toLowerCase() == this.drugService.forbiddenDrugListName);
    this.drugListID = listInfo_filter[0].id;
    
  }

  async step3() {
    this.isLoading = true;
    try {
      this.promise1 = await this.drugService.step3r1(this.rxcui);
      this.promise2 = await this.drugService.step3r2(this.rxcui);

      Promise.all([this.promise1, this.promise2]).then((values) => {
        this.results = values;
        this.checkDosageForm();
        console.log('step3r1, step3r2',this.results);
        //this.strip_drug_name = this.results[1].idGroup.name.toLowerCase().replace('oral','').replace('tablet','').replace('injection','').replace('capsule','');
        this.strip_drug_name = this.drugService.getDrugName(this.results[1].idGroup.drug_info );

      });
      this.isLoading = false;

    } catch (e) {
      console.log('error', e);
      this.isLoading = false;
    } finally {
    }
  }

  addPharmacy() {
    Swal.close();
    this._router.navigate(['../../step4'], {queryParamsHandling: 'preserve', relativeTo: this.route});
  }

  checkDosageForm() {
    const dosageName = this.results[1].idGroup.name.toLowerCase();
    const dosageString = "capsule";
    const dosageString2 = "tablet";
    const dosageString3 = "injection";
    if(dosageName.includes(dosageString)) {
      this.formCheck = "capsule";
    }
    else if(dosageName.includes(dosageString2)) {
      this.formCheck = "tablet";
    }
    else if(dosageName.includes(dosageString3)) {
      this.formCheck = "injection";
    }
    else {
      this.formCheck = "meds";
    }
  }

  async onClick_AddDrugToList(content) {
    this.isLoading = true;

    this.existingRxDrugListNotes = localStorage.getItem('rx_drug_list_notes');
    if (this.existingRxDrugListNotes == null || this.existingRxDrugListNotes == "") {
      localStorage.setItem('rx_drug_list_notes', "");
    }

    this.promise1 = await this.drugService.step3r1(this.rxcui);
    this.promise2 = await this.drugService.step3r2(this.rxcui);
      Promise.all([this.promise1, this.promise2]).then((values) => {
        this.results = values;
        this.checkDosageForm();
      });
     
    this.results[1].idGroup.drug_info['isbranded'] = this.paramsObject['params'].is_branded;

    const drugInfo = { // payload for saving invidual drug to local storage
      'drug_id' : this.paramsObject['params'].drug_id,
      //'drug_name' : this.results[0][0].proprietary_name.toLowerCase(),
      'drug_name': this.paramsObject['params'].selected_drug_name, 
      'rxcui' : this.rxcui,
      'often_taken': this.paramsObject['params'].often_taken,
      'times_taken': this.paramsObject['params'].times_taken,      
      'quantity': this.paramsObject['params'].days_supply,
      'tags' : this.single_tags.toString(),
      'notes' : this.single_notes,
      'details' : this.results[1].idGroup.drug_info,
      'search_drug_name' : this.paramsObject['params'].drug_name 
    };


    const drugInfoDB = { // payload for saving invidual drug to DB
      "rx_list_id": this.drugListID,
      "drug_id": this.paramsObject['params'].drug_id,
      "drug_name": this.paramsObject['params'].selected_drug_name,
      "notes": this.single_notes,
      "often_taken": this.paramsObject['params'].often_taken,
      "times_taken": this.paramsObject['params'].times_taken,
      "quantity": this.paramsObject['params'].days_supply,
      "rxcui": this.rxcui,
      "tags": this.single_tags.toString(),
      "details": this.results[1].idGroup.drug_info
    }

    if(this.isUserLoggedIn){ // check if user is logged in
      this.saveToDbHandler(drugInfoDB);
    }else{
      this.saveToLocalStorageHandler(drugInfo); // save to local storage only
    }
    
    this.isLoading = false;
    
    this.onShow_DrugAddedToListModal(content);
    this.deployConfetti();

  }

  async saveToDbHandler(drugInfoDB){
    const status = await this.drugService.saveIndividualDrug(drugInfoDB);
    if(status.code == 1){
      this.drugService.fetch_drugList_data(this.drugListID); 
      this.isLoading = false;  
    }
  }

  // function to save drug detail to Browser Local Storage
  saveToLocalStorageHandler(drugInfo){
    let existingEntries = JSON.parse(localStorage.getItem('rx_add_to_drug_list'));
    if (existingEntries == null) {
      existingEntries = [];
    }

    if (existingEntries.filter(e => e.rxcui === drugInfo.rxcui).length > 0) {
      this.drugService.removeDrugListByRxcui(drugInfo);
    }
  
    existingEntries = JSON.parse(localStorage.getItem('rx_add_to_drug_list'));
    if (existingEntries == null) {
      existingEntries = [];
    }
  
    existingEntries.push(drugInfo);
    this.drugService.addList(drugInfo);
    localStorage.setItem('rx_add_to_drug_list', JSON.stringify(existingEntries));
    this.drugService.fetchAddedDrugList(); // pull drugs list from local storage
  }

  addAnotherDrug() {
    Swal.close();    
    this._router.navigateByUrl('/insurance-marketplace/' + this.marketplaceType +'/drugs?marketplace_type=' + this.paramMarketplaceType + '&plan_type='+ this.paramsObject['params'].plan_type +'&state=' + this.paramsObject['params'].state + '&county=' + this.paramsObject['params'].county + '&zipcode=' + this.paramsObject['params'].zipcode + '&contract_id=' + this.paramsObject['params'].contract_id + '&plan_id=' + this.paramsObject['params'].plan_id + '&segment_id=' + this.paramsObject['params'].segment_id + '&plan_year=' + this.paramsObject['params'].plan_year);
  }

  onClick_ChangeDrug() {
    this.addAnotherDrug();    
  }

  onClick_MoreDrugDetails(state){
    this.showMoreDrugDetails = !state;
    if(this.showMoreDrugDetails){
      this.moreDrugDetails_text = "Hide Additional Details";
    }else{     
      this.moreDrugDetails_text = "More Drug Details";
    }
  }

  onShow_DrugAddedToListModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true
    })
  }

  showModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 500
    })
  }

  changeDrugModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 800
    })
  }

  deployConfetti() {
    confetti({
      particleCount: 200,
      spread: 120,
      origin: { y: 0.5 },
      zIndex: 1000
    });
  }

  onClick_DoneAddingDrugs(content){
    this.onShow_DrugAddedToListModal(content);
  }

  onSaveRxDrugListNotes(){
    localStorage.setItem('rx_drug_list_notes', this.existingRxDrugListNotes);
    Swal.fire(
      'Success!',
      'Your notes has been added to your Drug List',
      'success',
    );
  }

  onClick_YourDrugListNotes(content){
    this.existingRxDrugListNotes = localStorage.getItem('rx_drug_list_notes');
    this.showModal(content);
  }

  onChange_Dosage(event){
    console.log('onChange_Dosage',event.value);
    this.edit_dosageValue = event.value;
  }

  onChange_DaysSupply(event){
    console.log('onChange_daysSupply',event.value);
    this.edit_daysSupplyValue = event.value;
  }

  onChange_TimesTaken(event){
    console.log('onChangeDosage_timesTaken',event.value);
    this.edit_timesTakenValue = event.value;
  }

  onChange_OftenTaken(event){
    console.log('onChangeDosage_oftenTaken',event.value);
    this.edit_oftenTakenValue = event.value;
  }

  onChange_Refill(event){
    console.log('onChange_Refill',event.value);
    this.edit_refillValue = event.value;
  }

  async onEditSubmit() {
    this.isLoading = true;
    this.branded = [];
    this.generic = [];
    this.branded_grouped = [];
    this.generic_grouped = [];
    
    try {    
      //let step1Results;

      this.step1Results = await this.drugService.step1(this.strip_drug_name, 1);

      if(this.step1Results){
        this.branded = this.step1Results.branded;
        this.generic = this.step1Results.generic;
      }

      this.branded = this.branded.filter(item => item.drug_info.brand_name.toLowerCase() == this.strip_drug_name.toLowerCase());
      this.generic = this.generic.filter(item => item.drug_info.drug_name.toLowerCase() == this.strip_drug_name.toLowerCase());
      
      //set edit field default value to current selected drug
      this.edit_dosageValue = this.rxcui;
      this.edit_daysSupplyValue = this.paramsObject['params'].days_supply;
      this.edit_timesTakenValue = this.paramsObject['params'].times_taken;
      this.edit_oftenTakenValue = this.paramsObject['params'].often_taken;
      this.edit_refillValue = this.paramsObject['params'].drug_refill;

      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
    }

  }

  onClick_editBtn(){
    this.showEditFields = true;
    this.onEditSubmit();
  }

  onClick_edit_SaveBtn(){
    const editedDrugInfo = {
      times_taken: this.edit_timesTakenValue,
      often_taken: this.edit_oftenTakenValue ,
      days_supply: this.edit_daysSupplyValue,
      drug_refill: this.edit_refillValue
    }
    this._router.navigate(['../../step3', this.edit_dosageValue], { queryParams : editedDrugInfo, queryParamsHandling: 'merge', relativeTo: this.route });
  }

  onClick_addNote(modalContent){
    Swal.fire({
      html:modalContent,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 500
    }) 
  }

  onClick_setNotes(event){ 
    console.log('libayan',event ); 
    this.single_notes = event.notes;
    this.single_tags = event.tags;
    Swal.close();
  }
  
}
