import { Component, OnInit, ElementRef, ViewChild, Optional, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { APIService } from '../services/api.service';
import { MetadataService } from '../services/metadata.service';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {Title} from "@angular/platform-browser";
import Swal from 'sweetalert2';
declare var window: any;

export interface DialogData {
  email: string;
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [MetadataService]
})
export class RegistrationComponent implements OnInit {
  private currentUrl = window.location.href;
  private baseUrl = window.location.origin;
  errors: any;
  success: boolean = false;
  isFormSubmit: boolean = false;

  viewDefault: boolean = true;

  dateFieldType: any;
  dateFieldHeading: any;
  isShowDaySelection:boolean = true;
  dateOfBirth: any;

  passwordMatch: boolean = true;

  activeContactField: any = 'email';

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!');
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }

  registerForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")], ),
    mobile_phone: new FormControl('', [Validators.required, Validators.minLength(14)]),
    password: new FormControl('', [Validators.required]),
    c_password: new FormControl('', [Validators.required]),
    terms_and_condition: new FormControl('', [Validators.requiredTrue]),
    taptapRecaptcha: new FormControl(false)
  });

  get getTapTapRecaptcha(): FormControl {
    return this.registerForm.get('taptapRecaptcha') as FormControl;
  }

  get first_name() {
    return this.registerForm.get('first_name');
  }
  get last_name() {
    return this.registerForm.get('last_name');
  }
  get dob() {
    return this.registerForm.get('dob');
  }
  get gender() {
    return this.registerForm.get('gender');
  }
  get email() {
    return this.registerForm.get('email');
  }
  get mobile_phone() {
    return this.registerForm.get('mobile_phone');
  }
  get password() {
    return this.registerForm.get('password');
  }
  get c_password() {
    return this.registerForm.get('c_password');
  }
  get terms_and_condition() {
    return this.registerForm.get('terms_and_condition');
  }
  get taptapRecaptcha() {
    return this.registerForm.get('taptapRecaptcha');
  }

  private sub: any;

  public passed_firstname: any;
  public passed_lastname: any;
  public passed_email: any;
  public passed_mobile: any;

  constructor(
    private _api: APIService,
    private _router: Router,
    private metadataService: MetadataService,
    private route: ActivatedRoute,
    private titleService:Title,
    @Optional() public dialogRef: MatDialogRef<RegistrationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
  ) {

      this.sub = this.route.params.subscribe(params => {
        this.titleService.setTitle("Create Your Medicare Account  - TapTap Medicare");
        console.log(params);
          if (params['passed_firstname'] !== undefined && params['passed_firstname'] !== '' && params['passed_firstname'] !== null ) {
              this.passed_firstname = params["passed_firstname"];
          }

          if (params['passed_lastname'] !== undefined && params['passed_lastname'] !== '' && params['passed_lastname'] !== null ) {
              this.passed_lastname = params["passed_lastname"];
          }

          if (params['passed_email'] !== undefined && params['passed_email'] !== '' && params['passed_email'] !== null ) {
              this.passed_email = params["passed_email"];
          }

          if (params['passed_mobile'] !== undefined && params['passed_mobile'] !== '' && params['passed_mobile'] !== null ) {
              this.passed_mobile = params["passed_mobile"];
          }
          
      });

      if (dialogData && dialogData.email !== undefined && dialogData.email !== null && dialogData.email) {
        this.registerForm.controls['email'].setValue(dialogData.email);
        this.registerForm.controls['first_name'].setValue(dialogData.first_name);
        this.registerForm.controls['last_name'].setValue(dialogData.last_name);
        this.passed_firstname = dialogData.first_name;
        this.passed_lastname = dialogData.last_name;
        this.passed_email = dialogData.email;
      }
    
  }

  ngOnInit() {
    // this.addRecaptchaScript();
    this.injectSEO();
  }

  hack(val) {
    return Array.from(val);
  }

  closeModal() {
    if (this.dialogRef) {
        this.dialogRef.close(false);
    }
  }

  register() {
    this.success = false;
    this.errors = [];
    this.isFormSubmit = true;
    if (this.registerForm.valid) {
      if (this.registerForm.controls.password.value !== this.registerForm.controls.c_password.value) {
        this.passwordMatch = false;
      } else {
        this._api.callAPIEndpoint('/api/v1/register', {
          first_name: this.registerForm.controls.first_name.value,
          last_name: this.registerForm.controls.last_name.value,
          email: this.registerForm.controls.email.value,
          mobile_phone: this.registerForm.controls.mobile_phone.value,
          password: this.registerForm.controls.password.value,
          c_password: this.registerForm.controls.c_password.value
        })
        .subscribe(
          (response: any) => {
            if (response) {
  
              //this._router.navigate(['./login']);
              //console.log(response);
              //console.log(response['status']);
              if (response['status'] === 0) {
                this.errors = response['data']['errors'];
                console.log(this.errors);
              } else {
                const email = this.registerForm.controls.email.value;
                const password = this.registerForm.controls.password.value;
  
                //onAutoSaveDefaultDrugList - to be continue
  
                this.success = true;
                this.registerForm.reset();
                this.isFormSubmit = false;
                this.passwordMatch = true;
                if (this.dialogRef) {
                  const data = {
                    email,
                    password
                  }
                  this.dialogRef.close(data);
                }
              }
            } else {
              console.log('no results found');
            }
          },
          (err) => {
            console.log(err);
            //console.log('no results found');
          }
        );
      }
    }
  }

  // onAutoSaveDefaultDrugList() {    
  //   try {      
  //     this._api.callAPIEndpointAuthenticated('/api/drugs/save', this.auth_token, {
  //       "drug_list_type": 'reserve',
  //       "monthCoverage": '',
  //       drugs: [],
  //       pharmacies: [],
  //       'notes' : ''
  //     }).subscribe(
  //       (result: any) => {
  //         this.isLoading = false;
  //         console.log('new drug list saved');
  //         this.getMarketplaceDrugList();
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  //   } catch (e) {
  //     console.log('error', e);
  //   }

  // }

  injectSEO(): void {
    this.metadataService.getMetaData('/registration', (a) => {
      const { data } = a;
      const metatitle = document.getElementById('meta-title');
      const metadesc = document.getElementById('meta-desc');
      const keywords = document.getElementById('meta-keywords');
      const fbMetaTitle = document.getElementById('fb-meta-title');
      const fbMetaDescription = document.getElementById('fb-meta-description');
      const fbMetaUrl = document.getElementById('fb-meta-url');
      const fbMetaImage = document.getElementById('fb-meta-image');

      metatitle.setAttribute('content', data['meta-title']);
      metadesc.setAttribute('content', data['meta-desc']);
      keywords.setAttribute('content', data['meta-keywords']);
      fbMetaTitle.setAttribute('content', data['meta-title']);
      fbMetaDescription.setAttribute('content', data['meta-desc']);
      fbMetaUrl.setAttribute('content', this.currentUrl);
      fbMetaImage.setAttribute(
        'content',
        `${this.baseUrl}/assets/company-logo.jpg`
      );
      window.prerenderReady = true;
    });
  }

  clickProceed(){

    this.success = false;
    this.errors = [];
    this.isFormSubmit = true;
    
    if( this.registerForm.controls.first_name.valid && 
        this.registerForm.controls.last_name.valid &&
        this.registerForm.controls.mobile_phone.valid && 
        this.registerForm.controls.taptapRecaptcha.valid ){

        this.viewDefault = false;
        this.isFormSubmit = false;
    }
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return (this.padTo2Digits(date.getMonth()+1)) + '-' + this.padTo2Digits(date.getDate()) + '-' + date.getFullYear();
  }

  onReceiveDate(dob: any){
    const idate = new Date(dob.date);
    const fdate = this.formatDate(idate);
    this.dateOfBirth = fdate;
    this.registerForm.controls.dob.setValue(fdate);
    console.log('DOB: ',this.dateOfBirth);
    Swal.close();
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;

    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        width: 300,
        padding:'20px',
    })
  }

  selectGender(gender) {
    this.registerForm.controls.gender.setValue(gender);
  }

  telFormat (e:any, id: any) { // id => HTML TAG ID, e => event
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }

  switchField(fieldType) {
    this.activeContactField = fieldType;
  }
}
