import { Component, OnInit, Input, EventEmitter, ElementRef, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-mapd-plan-customizer-dual-eligible',
  templateUrl: './mapd-plan-customizer-dual-eligible.component.html',
  styleUrls: ['./mapd-plan-customizer-dual-eligible.component.scss']
})
export class MapdPlanCustomizerDualEligibleComponent implements OnInit {
  @Input() viewOnly: boolean = false; // to remove Proceed button and Info Modal on modal SElector page

  // @Input() isShowZipCode: boolean;
  isShowZipCode: boolean = false;
  
  @Input() plan: number;

  @Output() isShowZipCodeChange = new EventEmitter();
  @Output() planChange = new EventEmitter();

  @Output() quoteFormHeading = new EventEmitter();

  @Output() onClickZipCodeBack = new EventEmitter();


  isPrioritizeSelected: boolean = false;
  mustMatchAllActive: boolean = true;
  mustMatchAnyActive: boolean = false;
  prioritizeActive: boolean = false;
  isShowCustomPlan: boolean = true;

  monthlyPremium: any = [];
  additionalBenefits: any = [];
  outOfPocketCost: any = [];
  otherCategories: any = [];
  selectorType: any = 'match-all';

  selectorContent: any;

  isHMO: boolean = false;
  isPPO: boolean = false;
  isAll: boolean = false;
  isAll50orLess: boolean = false;
  isMoreThanTwo: boolean = false;

  opIsChecked: any = [];
  opChexboxes = [1, 2, 3, 4, 5, 6, 7];

  monthlyPremiumIsChecked: any = [];
  monthlyPremiumCheckboxes = [1, 2, 3, 4, 5, 6, 7];

  isShowCustomPlan_dual: boolean = false;
  isShowMainPage: boolean = true;
  isShowCustomPlanZ: boolean = false;
  fullPrimaryHeader: boolean = true;
  isLoggedInUser: any;

  modalInfoHeading: any = `<div class='d-flex justify-content-center align-items-center mb-5'>
                                
  <h2 class='mb-0 h2-global-title' style='font-size: 1.3em;margin-bottom: 0;font-weight: 600;'>TapTap Medicare Explains:</h2>
</div>`;

  constructor(private _auth: AuthService) { }



  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if (this.isLoggedInUser) {
      this.fullPrimaryHeader = false;
    }
  }

  mustMatchAll() {
    this.mustMatchAllActive = true;
    this.mustMatchAnyActive = false;
    this.prioritizeActive = false;
    this.isPrioritizeSelected = false;
    $('#matchAllID').click();

  }

  mustMatchAny() {
    this.mustMatchAnyActive = true;
    this.mustMatchAllActive = false;
    this.prioritizeActive = false;
    this.isPrioritizeSelected = false;
    $('#matchAnyID').click();
  }

  prioritize() {
    this.mustMatchAnyActive = false;
    this.mustMatchAllActive = false;
    this.prioritizeActive = true;
    this.isPrioritizeSelected = true;
    $('#prioritizeID').click();
  }

  showCriteriaModal(content, width) {
    Swal.fire({
      html: content.innerHTML,
      width: width,
      customClass: {
        content: 'selector-modal-content text-center',
        confirmButton: 'btn btn-first'
      },
      padding: '2em',
      buttonsStyling: false
    })
  }

  selectorModalView(content, width: 600) {
    Swal.fire({
        html: this.modalInfoHeading + content.innerHTML,
        width: width,
        customClass: {
            content: 'selector-modal-content text-center',
            confirmButton: 'btn btn-first'
        },
        padding: '2em',
        buttonsStyling: false
    })
    
  }

  selectorProceed(){
    this.isShowZipCode = true;
    window.scrollTo(0,0);
  }

  onClickZipCodeBackParent() {
    this.isShowZipCode = false;
  }

  onClickCustomPlanBack (){
    this.isShowZipCode = false;
    window.scrollTo(0,0);
  }

  onClickZipCodeBackButton() {
    this.onClickZipCodeBack.emit();
  }

  

}
