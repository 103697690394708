import {Injectable} from '@angular/core';
import { APIService } from './api.service';
import * as _ from 'lodash';

@Injectable()

export class ConsumerReviewService {
    constructor(private _api: APIService) {}

    saveCustomerReview(data): Promise<any> {
       
        const result = new Promise((resolve, reject) => {
            this._api.callAPIEndpoint(`/api/v1/consumer-reviews/save`, data, {
                method: 'POST',
                showLoading: true
            }).subscribe((d) => {
                console.log('customer review saved');
                resolve(d);
            }, (err) => {
                console.log('error', {
                    detail: err
                });
                reject(err);
            });
        });

        return result;
    }

    getCustomerReviews(data): Promise<any> {
        const result = new Promise((resolve, reject) => {
            this._api.callAPIEndpoint(`/api/v1/get-consumer-reviews`, data, {
                method: 'GET',
                showLoading: true
            }).subscribe((d) => {
                resolve(d);
            }, (err) =>{
                console.log('error fetching reviews', {
                    detail: err
                });
                reject(err);
            });
        });
        return result;
    }
}