<div style="border-radius: 20px; overflow: hidden;">
  <div class="row">
    <div class="col d-flex flex-column justify-content-between bg-primary">
      <div>
        <div class="d-flex justify-content-end p-3">
          <img style="width: 150px; height:auto;" src="/assets/img/common/logo-white.png" alt="">
        </div>
        <div class="text-center p-3">
          <h2 class="text-light">Request This Plan Document</h2>
        </div>
      </div>
      <div class="d-flex justify-content-center pt-3 px-3">
        <img src="/assets/img/common/pink-lady.svg" alt="">
      </div>
    </div>
    <div class="col bg-light">
      <div class="px-4 pt-4 text-center">
        <h5 class="fColor-base fWeight-600">
          <strong class="fColor-blue">The Taptap Team</strong> is in the Process of Getting this particular Plan Document Connect
        </h5>
        <h5 style="color: #003DA8;">
          We will send this Plan's Documents to you within 24 hours
        </h5>
      </div>
      <div class="p-4">
        <form [formGroup]="requestForm">
          <div class="form-group mb-3">
            <label style="font-size: 18px;" class="font-weight-bold" for="your_name">Your Name</label>
            <input type="text" class="form-control v2" name="your-name" formControlName="inquiryName">
            <ng-container *ngIf="(inquiryName.invalid && inquiryName.touched) || inquiryName.dirty || isFormSubmit">
              <small *ngIf="inquiryName.invalid && inquiryName.errors.required" class="text-danger">
                  Name is required
              </small>
            </ng-container>
          </div>
          <div class="form-group mb-3">
            <label style="font-size: 18px;" class="font-weight-bold" for="your_email">Your Email</label>
            <input type="email" class="form-control v2" name="your-email" formControlName="inquiryEmail">
            <ng-container *ngIf="(inquiryEmail.invalid && inquiryEmail.touched) || inquiryEmail.dirty || isFormSubmit">
              <small *ngIf="inquiryEmail.invalid && inquiryEmail.errors.required" class="text-danger">
                  Email is required
              </small>
              <small *ngIf="inquiryEmail.invalid && inquiryEmail.errors.email" class="text-danger">
                Email is invalid
              </small>
            </ng-container>
          </div>
          <div class="form-group mb-3">
            <label style="font-size: 18px;" class="font-weight-bold" for="your_phone">Your Mobile Phone</label>
            <input type="text" class="form-control v2" name="your-phone" formControlName="inquiryPhone">
          </div>

          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="sendVia" id="via_email" value="email"  [checked]="true">
              <label style="font-size: 18px;" class="form-check-label" for="via_email">
                Email It To Me
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="via_text"  formControlName="sendVia" value="text" [checked]="false">
              <label style="font-size: 18px;" class="form-check-label" for="via_text">
                Text It To Me
              </label>
            </div>
            <div class="form-check disabled">
              <input class="form-check-input" type="radio" id="via_both" formControlName="sendVia" value="email and text" [checked]="false">
              <label style="font-size: 18px;" class="form-check-label" for="via_both">
                Both Email & Text
              </label>
            </div>
          </div>
          <button role="button" (click)="onSubmitRequestDetails()" class="btn btn-first mx-auto mt-4 btn-small">
            Submit Request
          </button>
        </form>
      </div>
    </div>

  </div>
</div>