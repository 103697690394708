<div class="container-fluid header-banner-wrapper access-forms force-height-auto" *ngIf="loadingHash">
  <button *ngIf="dialogData" class="close close-icon" mat-button (click)="closeModal()">
      <mat-icon class="color_white">close</mat-icon>
  </button>
  <div class="container px-0">
    <div class="row flex-column-reverse flex-md-row">
        <div class="col-12 col-md-6 p-0 form-character-wrapper">
        <div class="login-left-text">            
            <h5 class="mb-4">The Power of Your TapTap Beneficiary Dashboard:</h5>            
            <ul>
                <li>Being a Medicare Beneficiary is Hard...</li>
                <li>And There Are a Lot of Moving Pieces</li>
                <li>I Need One Central Place to Organize Everything, and See Everything</li>
                <li>A Place That Will Help Me Understand My Situation At All Times</li>
                <li>And Guide Me Through My Options & Choices</li>
                <li>Proactive, Clear, and Organized.</li>
            </ul>
            <img src="./assets/img/common/woman-pink-dress-cut-with-gradient.svg" alt="Sign Up Character"> 
            <h5 class="mt-5 mb-3">Key Sections Of Your Dashboard</h5>
            <ul>
                <li>My Healthcare</li>
                <li>My Financials</li>
                <li>My Coverages</li>
                <li>My Documents</li>
            </ul>            
        </div>   
        </div>
        <ng-container *ngIf="isValidHash">
          <div class="col-12 col-md-6 box-wrapper">
            <div class="primary-box">
                <div class="box-top p-4 p-md-5">
                  <ng-container *ngIf="type == 2; else defaultVerification">
                    <h4 class="text-center fWeight-600 mb-4">Your Email has been Successfully Verified. Change your password now!</h4>
                    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                      <div class="row mb-1">
                          <div class="col-md-12 px-1 mb-2">
                              <label class="form-label">Password<sup>*</sup></label>
                              <input type="password" class="form-field" name="passcode" formControlName="password" required />
                          </div>
                          <div class="col-md-12 px-1">
                            <label class="form-label">Confirm Password<sup>*</sup></label>
                            <input type="password" class="form-field" name="passcode" formControlName="confirm_password" required />
                        </div>
                      </div>
                        <div class="d-flex flex-column flex-lg-row mt-3 mb-3 align-items-center justify-content-between">
                            <button type="submit" class="btn btn-first btn-small btn-login-now">
                                Submit
                            </button>
                        </div>
                    </form>
                  </ng-container>
                  <ng-template #defaultVerification>
                    <h4 class="text-center fWeight-600 mb-4">Your Email has been Successfully Verified!</h4>
                    <div class="row justify-content-center">
                      <p><a routerLink="/login" class="color-primary fWeight-600">Login Here</a></p>
                  </div>
                  </ng-template>
                </div>
              </div>
          </div>
          
        </ng-container>
        <ng-container *ngIf="!isValidHash">
          <div class="col-12 col-md-6 box-wrapper">
            <div class="primary-box">
                <div class="box-top p-4 p-md-5">
                  <h4 class="text-center fWeight-600 mb-4">Sorry Your Token is Either Invalid or Expired.</h4>
                </div>
              </div>
          </div>
        </ng-container>
    </div>
  </div>
</div>
