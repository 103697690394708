<div class="text-left">
  <div class="mb-3">
    <h5 class="fw-semibold text-dblue mb-0">What Is The Best Phone Number To Reach You On?</h5>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeCity" class="text-label">Phone</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeCity"/>
      </div>
    </div>
    <div class="p-1">
      <span class="text-dblue fw-medium">Is this your cellphone?</span>
      <div class="p-1">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="isPhoneNumber_1" name="isPhoneNumber" class="custom-control-input" value="yes" >
          <label class="custom-control-label fw-semibold text-primary" for="isPhoneNumber_1">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="isPhoneNumber_2" name="isPhoneNumber" class="custom-control-input" value="no">
          <label class="custom-control-label fw-semibold text-primary" for="isPhoneNumber_2">No</label>
        </div>
      </div>
    </div>
  </div>
</div>