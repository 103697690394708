import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-application-mapd-version2-screen6',
  templateUrl: './screen6.component.html',
  styleUrls: ['./screen6.component.scss']
})
export class Screen6Component implements OnInit {

  paymentOption:any = 0;

  dateFieldType: string;
  dateFieldHeading: string;

  constructor() { }

  ngOnInit() {
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

}
