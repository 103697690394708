<app-loading *ngIf="isLoading" class="loading-fixed"></app-loading>

<ng-container *ngIf="isTypeView">

  <ng-container *ngIf="isView_1">
    <h4 class="text-center mb-2">Your Full RX Profile</h4>
    <img src="./assets/img/my-drugs/safety-kit-supply.JPG" alt="Your Full RX Profile" class="safety-kit-img">
    <form [formGroup]="rxProfileForm"> <!--(ngSubmit)="onSubmit()"-->
      <div class="form-row choose-pharmacy-search">
        <div class="form-group col-12 col-md-6">
          <label for="fullname">Full Name</label>
          <input type="text" id="fullname" class="form-control blurred" formControlName="fullname">
        </div>    
        <div class="form-group col-12 col-md-6">
          <label for="dateofbirth">Date of Birth</label>
          <input type="date" id="dateofbirth" class="form-control blurred" formControlName="dateofbirth">
        </div>  
        <div class="form-group col-12 col-md-6">
          <label for="zipcode">Zip Code</label>
          <input type="text" id="zipcode" class="form-control blurred" formControlName="zipcode">
        </div>    
        <div class="form-group col-12 col-md-6">
          <label for="state">State</label>
          <select class="form-control blurred"  id="state" formControlName="state">
            <option value="AL" selected="selected">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>                
        </div>     
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-first btn-small">Save Changes</button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="isView_2">
    <div class="row p-0 align-items-center">
      <div class="col-3">
        <img src="./assets/img/common/old-woman-cooking.png" alt="My Enrollment Profile" class="fluid">
      </div>
      <div class="col-9 pl-2">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h4 class="fWeight-600">My Enrollment Profile is</h4>
          <button class="btn btn-second fColor-orange my-enrollment-box-cp">EP32</button>
        </div>
        <p><b>This means</b> I am new to Medicare as a result of
          turning age 65, are now evaluating My Medicare
          Insurance Coverage Options, and are note
          currently evaluating active employment group
          coverage or employer retiree coverage.</p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isView_3">
    <div class="welcome-box flex-column">
      <h4 class="text-center fColor-base fWeight-600">As a Medicare Beneficiary</h4>
      <h5 class="text-center mb-5">I have 2 Key Helpful & Important Classifications:</h5>

      <div class="row">
        <div class="col-12 mb-5">
          <div class="row align-items-center">
            <div class="col-7 pr-3">
              <h4>My Coverage Profile</h4>
              <p>
                <strong>Why is Coverage Profile Helpful for me?</strong>
                A new to Medicare as a result of turning age 65,
                are now evaluating your Medicare insurance
                coverage options. Then new to Medicare as
                result of turning age 65, are now evaluating
              </p>              
            </div>
            <div class="col-5">
              <img src="./assets/img/common/8-old-seniors-cropped.svg" alt="">
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-4">              
              <img src="./assets/img/common/6-old-person.svg" alt="">
            </div>
            <div class="col-8 pl-3">
              <h4>My Enrollment Profile</h4>
              <p>
              <strong>What Does an Enrollment Profile Let Me Do?</strong>
                      As a result of turning age 65, are now evaluating your
                      Medicare insurance coverage options. Then new to
                      Medicare as a result of turning age 65, are now
                      evaluating
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isView_4">
    <h4 class="text-center mb-2">Assistance Profile</h4>
  </ng-container>

  <ng-container *ngIf="isView_5">
    <h4 class="text-center mb-2">Existing Drug Coverage</h4>
    <div class="row">
      <div class="col-12 col-md-6 p-3">
        <div class="dp-card h-100 d-flex flex-column justify-content-between">
          <div>
            <img src="./assets/img/my-drugs/6-tablet-blue-bg.JPG" class="max-h-100 mx-auto d-block mb-3" alt="View The Most Common Drugs">
            <h3 class="text-center fWeight-700 mb-4">Your Drug List:</h3>
            <div class="drug-list-type-stats" *ngIf="isUserLoggedIn">
              <div>
                <span class="stats-label">List Type</span>
                <span class="stats">Prime</span>
              </div>
              <div>
                <span class="stats-label">Status</span>
                <span class="stats">Active</span>
              </div>
            </div>
            <div class="doctor-your-drug-list" *ngIf="existingDrugs_list">
              <div class="your-drug-detail" *ngFor="let existingDrugs of existingDrugs_list; let i = index">
                <img src="./assets/img/my-drugs/orange-white-capsule.JPG" alt="Capsule Icon">
                <div class="d-flex flex-column">                  
                  <strong class="your-drug-detail-name text-capitalize">{{ existingDrugs.drug_name }}</strong>
                  
                  <ng-container *ngIf="existingDrugs.details.package.toLowerCase().includes('tablet'); else temp_capsule">
                    <small class="your-drug-detail-name-dosage">Oral Tablet</small> 
                  </ng-container>

                  <ng-template #temp_capsule>
                    <ng-container *ngIf="existingDrugs.details.package.toLowerCase().includes('capsule'); else temp_injection">
                      <small class="your-drug-detail-name-dosage">Capsule</small> 
                    </ng-container>
                  </ng-template>
                  
                  <ng-template #temp_injection>
                    <ng-container *ngIf="existingDrugs.details.package.toLowerCase().includes('injection'); else temp_meds">
                      <small class="your-drug-detail-name-dosage">Injection</small> 
                    </ng-container>
                  </ng-template>

                  <ng-template #temp_meds>
                    <small class="your-drug-detail-name-dosage">{{ existingDrugs.details.package }}</small> 
                  </ng-template>

                  <small class="your-drug-detail-name-dosage">{{ existingDrugs.details.dosage }}</small> 
                  <!-- <small class="your-drug-detail-name-dosage">{{ existingDrugs.details.dosage }} | {{ existingDrugs.times_taken }}/{{ existingDrugs.often_taken.replace('per-','') }}</small> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="btn btn-third cta-btn no-hover-bg mx-auto mt-4 fluid" (click)="onClick_ChangeDrug()"><img src="./assets/img/my-drugs/6-tablet-blue-bg-on-green.JPG">Add Another Drug</button> -->
        </div>
      </div>
      <div class="col-12 col-md-6 p-3">
        <div class="dp-card h-100 d-flex flex-column justify-content-between">
          <div>
            <img src="./assets/img/my-drugs/add-pharmacy.JPG" class="max-h-100 mx-auto d-block mb-3">
            <h3 class="text-center fWeight-700 mb-4">Your Pharmacy List:</h3>
            <div class="doctor-your-drug-list" *ngIf="existingPharmacies_list">
              <div class="your-drug-detail" *ngFor="let existingPharmacy of existingPharmacies_list; let i = index">
                <img src="./assets/img/my-drugs/blue-kit-white-cross.JPG" alt="Cross Kit Icon">
                <div class="d-flex flex-column">
                  <strong class="your-drug-detail-name">{{ existingPharmacy.pharmacy_name }}</strong>
                  <!-- <small class="your-drug-detail-name-dosage d-none">{{ existingPharmacy.pharmacy_address }}</small> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="btn btn-third cta-btn no-hover-bg mx-auto mt-4 fluid" (click)="addPharmacy()"><img src="./assets/img/my-drugs/add-pharmacy.JPG">Add Pharmacy</button> -->
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isView_6">
    <h4 class="text-center mb-2">Medicare Part D Drug Coverage Preferences</h4>
    <h6 class="fColor-base fWeight-600 text-center">Illustration of This Coverage Setup</h6>
    <app-insurance-coverage-box [isMapdPlusDrug]="true"></app-insurance-coverage-box>
    
    <div class="settings-box" *ngIf="customer_id">
        <div class="row bg-white">
            <div class="box-12 row margin-top-20">
              <div class="box-6">
                <h6>Low Monthly Premium</h6>
              </div>
              <div class="box-6 row">
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_monthly_premiums_1" class="circle-1"
                    name="part_d_low_monthly_premiums" (click)="sendUpdateRequest(57,1);" [checked]="isChecked(57,1)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_monthly_premiums_2" class="circle-2"
                    name="part_d_low_monthly_premiums" (click)="sendUpdateRequest(57,2);" [checked]="isChecked(57,2)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_monthly_premiums_3" class="circle-3"
                    name="part_d_low_monthly_premiums" (click)="sendUpdateRequest(57,3);" [checked]="isChecked(57,3)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_monthly_premiums_4" class="circle-4"
                    name="part_d_low_monthly_premiums" (click)="sendUpdateRequest(57,4);" [checked]="isChecked(57,4)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_monthly_premiums_5" class="circle-5"
                    name="part_d_low_monthly_premiums" (click)="sendUpdateRequest(57,5);" [checked]="isChecked(57,5)">
                </div>
              </div>
            </div>

            <div class="box-12 row margin-top-20">
              <div class="box-6">
                <h6>Low Deductible</h6>
              </div>
              <div class="box-6 row">
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_deductible_1" class="circle-1" name="part_d_low_deductible"
                    (click)="sendUpdateRequest(58,1);" [checked]="isChecked(58,1)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_deductible_2" class="circle-2" name="part_d_low_deductible"
                    (click)="sendUpdateRequest(58,2);" [checked]="isChecked(58,2)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_deductible_3" class="circle-3" name="part_d_low_deductible"
                    (click)="sendUpdateRequest(58,3);" [checked]="isChecked(58,3)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_deductible_4" class="circle-4" name="part_d_low_deductible"
                    (click)="sendUpdateRequest(58,4);" [checked]="isChecked(58,4)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_low_deductible_5" class="circle-5" name="part_d_low_deductible"
                    (click)="sendUpdateRequest(58,5);" [checked]="isChecked(58,5)">
                </div>
              </div>
            </div>

            <div class="box-12 row margin-top-20">
              <div class="box-6">
                <h6>My Desired Pharmacy</h6>
              </div>
              <div class="box-6 row">
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_my_desired_pharmacy_1" class="circle-1"
                    name="part_d_my_desired_pharmacy" (click)="sendUpdateRequest(59,1);" [checked]="isChecked(59,1)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_my_desired_pharmacy_2" class="circle-2"
                    name="part_d_my_desired_pharmacy" (click)="sendUpdateRequest(59,2);" [checked]="isChecked(59,2)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_my_desired_pharmacy_3" class="circle-3"
                    name="part_d_my_desired_pharmacy" (click)="sendUpdateRequest(59,3);" [checked]="isChecked(59,3)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_my_desired_pharmacy_4" class="circle-4"
                    name="part_d_my_desired_pharmacy" (click)="sendUpdateRequest(59,4);" [checked]="isChecked(59,4)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_my_desired_pharmacy_5" class="circle-5"
                    name="part_d_my_desired_pharmacy" (click)="sendUpdateRequest(59,5);" [checked]="isChecked(59,5)">
                </div>
              </div>
            </div>

            <div class="box-12 row margin-top-20">
              <div class="box-6">
                <h6>Mail Order Costs</h6>
              </div>
              <div class="box-6 row">
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_mail_order_costs_1" class="circle-1" name="part_d_mail_order_costs"
                    (click)="sendUpdateRequest(60,1);" [checked]="isChecked(60,1)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_mail_order_costs_2" class="circle-2" name="part_d_mail_order_costs"
                    (click)="sendUpdateRequest(60,2);" [checked]="isChecked(60,2)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_mail_order_costs_3" class="circle-3" name="part_d_mail_order_costs"
                    (click)="sendUpdateRequest(60,3);" [checked]="isChecked(60,3)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_mail_order_costs_4" class="circle-4" name="part_d_mail_order_costs"
                    (click)="sendUpdateRequest(60,4);" [checked]="isChecked(60,4)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_mail_order_costs_5" class="circle-5" name="part_d_mail_order_costs"
                    (click)="sendUpdateRequest(60,5);" [checked]="isChecked(60,5)">
                </div>
              </div>
            </div>

            <div class="box-12 row margin-top-20">
              <div class="box-6">
                <h6>Consistent Costs</h6>
              </div>
              <div class="box-6 row">
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_consistent_costs_1" class="circle-1" name="part_d_consistent_costs"
                    (click)="sendUpdateRequest(61,1);" [checked]="isChecked(61,1)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_consistent_costs_2" class="circle-2" name="part_d_consistent_costs"
                    (click)="sendUpdateRequest(61,2);" [checked]="isChecked(61,2)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_consistent_costs_3" class="circle-3" name="part_d_consistent_costs"
                    (click)="sendUpdateRequest(61,3);" [checked]="isChecked(61,3)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_consistent_costs_4" class="circle-4" name="part_d_consistent_costs"
                    (click)="sendUpdateRequest(61,4);" [checked]="isChecked(61,4)">
                </div>
                <div class="box-2 row justify-content-center">
                  <input type="radio" id="part_d_consistent_costs_5" class="circle-5" name="part_d_consistent_costs"
                    (click)="sendUpdateRequest(61,5);" [checked]="isChecked(61,5)">
                </div>
              </div>
            </div>
        </div>
    </div>
    
  </ng-container>

  <div class="navigator">
      <button class="btn btn-second-fill btn-xs" (click)="onClick_prev()">Back</button>
      <div class="navigator-items">
        <span class="span-view active" id="view_1">1</span>
        <span class="span-view" id="view_2">2</span>
        <span class="span-view" id="view_3">3</span>
        <span class="span-view" id="view_4">4</span>
        <span class="span-view" id="view_5">5</span>
        <span class="span-view" id="view_6">6</span>
      </div>
      <button class="btn btn-second-fill btn-xs" (click)="onClick_next()">Next</button>
  </div>

</ng-container>


<ng-container *ngIf="!isTypeView">
 
    <h4 class="text-center mb-2">Your Full RX Profile</h4>
    <img src="./assets/img/my-drugs/safety-kit-supply.JPG" alt="Your Full RX Profile" class="safety-kit-img">
    <form [formGroup]="rxProfileForm">
      <div class="form-row choose-pharmacy-search">
        <div class="form-group col-12 col-md-6">
          <label for="fullname">Full Name</label>
          <input type="text" id="fullname" class="form-control blurred" formControlName="fullname">
        </div>    
        <div class="form-group col-12 col-md-6">
          <label for="dateofbirth">Date of Birth</label>
          <input type="date" id="dateofbirth" class="form-control blurred" formControlName="dateofbirth">
        </div>  
        <div class="form-group col-12 col-md-6">
          <label for="zipcode">Zip Code</label>
          <input type="text" id="zipcode" class="form-control blurred" formControlName="zipcode">
        </div>    
        <div class="form-group col-12 col-md-6">
          <label for="email">Email</label>
          <input type="email" id="email" class="form-control blurred">
        </div>
      </div>
      <div class="d-flex justify-content-center mb-5">
        <div class="custom-control custom-checkbox mr-sm-2">
          <input type="checkbox" class="custom-control-input" id="acceptTermsAndConditions">
          <label class="custom-control-label" for="acceptTermsAndConditions">I've read and accept the <a href="/terms-of-services" target="_blank">Terms & Conditions</a></label>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-first btn-small">Save Changes</button>
      </div>
    </form>
  
</ng-container>