import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
 
import { APIService } from '../services/api.service';
import { MetadataService } from '../services/metadata.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

declare var window: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [MetadataService]
})
export class ContactComponent implements OnInit {
  private currentUrl = window.location.href;
  private baseUrl = window.location.origin;
  latitude: number;
  longitude: number;
  zoom: number;

  auth_token: any;
  fullPrimaryHeader: boolean = true;

  taptapRecaptcha = new FormControl(false);

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!');
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }

  public router: Router;

  controls: any = {
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required])
  };

  constructor(
    private _api: APIService,
    private _router: Router,
    private metadataService: MetadataService,
    private titleService:Title
  ) {
    this.titleService.setTitle("Get In Touch With Us - TapTap Medicare");
  }

  injectSEO(): void {
    this.metadataService.getMetaData('/contact', (a) => {
      const { data } = a;
      const metatitle = document.getElementById('meta-title');
      const metadesc = document.getElementById('meta-desc');
      const keywords = document.getElementById('meta-keywords');
      const fbMetaTitle = document.getElementById('fb-meta-title');
      const fbMetaDescription = document.getElementById('fb-meta-description');
      const fbMetaUrl = document.getElementById('fb-meta-url');
      const fbMetaImage = document.getElementById('fb-meta-image');

      metatitle.setAttribute('content', data['meta-title']);
      metadesc.setAttribute('content', data['meta-desc']);
      keywords.setAttribute('content', data['meta-keywords']);
      fbMetaTitle.setAttribute('content', data['meta-title']);
      fbMetaDescription.setAttribute('content', data['meta-desc']);
      fbMetaUrl.setAttribute('content', this.currentUrl);
      fbMetaImage.setAttribute(
        'content',
        `${this.baseUrl}/assets/company-logo.jpg`
      );
      window.prerenderReady = true;
    });
  }

  ngOnInit() {
    // this.addRecaptchaScript();
    this.injectSEO();
    this.setCurrentLocation();

    this.auth_token = window.localStorage.getItem('auth_token');
    if (this.auth_token) {
      this.fullPrimaryHeader = false;
    }
  }

  private setCurrentLocation() {
    this.latitude = 39.94842;
    this.longitude = -75.16505;
    this.zoom = 15;
  }

  onInquire() {
    this._api
      .callAPIEndpoint('/api/v1/inquiry', {
        first_name: this.controls.first_name.value,
        last_name: this.controls.last_name.value,
        email: this.controls.email.value,
        phone: this.controls.phone.value,
        source_id: 'Website | Contact Us',
        source_description: 'Message Box Entry',
        message: this.controls.message.value
      })
      .subscribe(
        (data: any) => {
          this.emptyForm();
          this.alertMessage();
        },
        (err) => {
          this.emptyForm();
        }
      );
  }

  emptyForm() {
    this.controls.first_name.reset();
    this.controls.last_name.reset();
    this.controls.email.reset();
    this.controls.phone.reset();
    this.controls.what_can_we_help_for_you.reset();
    this.controls.how_did_you_hear_about_us.reset();
    this.controls.message.reset();
  }

  alertMessage() {
    Swal.fire({
      html: '<h1>Inquiry Sent!</h1>',
      customClass: {
        //content: 'text-left'
      },
      showConfirmButton: true,
      showCloseButton: true
    });
  }
}
