
<div class="custom-pagination d-flex align-items-center">
  <button class="previous" (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1" [class.disabled]="currentPage === 1">
    <i class="fas fa-angle-left mr-2"></i> Previous
  </button>
  <button class="page-number" *ngFor="let pageNumber of pages" [class.current]="currentPage === pageNumber" (click)="onPageChange(pageNumber)">
    {{ pageNumber }}
  </button>
  <button class="next" (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages" [class.disabled]="currentPage === totalPages">
    Next <i class="fas fa-angle-right ml-2"></i>
  </button>
</div>