<app-loading *ngIf="isLoading" class="loading-fixed"></app-loading>
<div class="row flex-column text-center">

      <ng-container *ngIf="viewDefault">
        <div class="dp-card" *ngIf="!isEditDrug"> <!--EDIT DRUG - hide search bar on editing drug-->
          <!-- <h4 class="text-capitalize">My Drug Names</h4>        -->
          <h4 class="text-capitalize dblue">Enter The Name of Your Drug Below</h4>       
          <form class="d-flex justify-content-center align-items-center" id="step1-form" [formGroup]="step1Form" (ngSubmit)="onSubmit($event)">
              <div class="form-group pt-2 m-0">
                <!-- <label for="drugname">Enter The Name of Your Drug Below</label> -->
                <div class="d-flex justify-content-center align-items-center">
                  <mat-form-field class="search" >
                    <input
                      type="text"
                      matInput
                      formControlName="drugname"
                      #searchFieldDrugname
                      [matAutocomplete]="searchFieldDrugnameAuto"
                      name="drugname"
                      id="drugname"
                      class="form-control blurred px-2"
                      (input)="fullnameValueChanged(searchFieldDrugname.value)"
                      maxlength = "100"                    
                    >
                    <mat-autocomplete #searchFieldDrugnameAuto="matAutocomplete">
                      <ng-container *ngFor="let option of results">
                        <mat-option (onSelectionChange)="updateMySelection(option)" [value]="option.name ? option.name : option.suggest ? option.suggest : ''">
                          <span>
                            {{option.name ? option.name : option.suggest ? option.suggest : ''}}                        
                          </span>
                        </mat-option>
                        <mat-option (onSelectionChange)="updateMySelection(option)" [value]="option.generic" *ngIf="option.generic" class="option-generic">
                          <span> 
                            <span class="turn-down-right-arrow">↳</span> {{option.generic}}
                          </span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  <button class="btn btn-first btn-small m-0 mx-2 mb-2">Submit</button>
                </div>
              </div>
          </form>        
        </div>
        <div *ngIf="!view_drug_list" class="d-flex justify-content-center or-element">
          <span>OR</span>
        </div>
        <div *ngIf="view_drug_list" class="mb-3"></div>

        <!-- <ng-container *ngIf="view_drug_list && branded.length">
          <div class="drug-selection-card mt-4">
            <div class="d-flex align-items-center justify-content-center py-3">
              <img src="./assets/img/my-drugs/brand-name.svg" alt="Tablet Icon" class="mt-0 mr-3 max-w-90">
              <h4 class="text-capitalize">Brand Name Versions</h4>
            </div>
            <table class="drug-table table table-striped table-borderless text-left">
              <thead>
                <tr>
                  <th scope="col" class="fWeight-600 pl-4">Name</th>
                  <th scope="col" class="fWeight-600">Dosage</th>
                  <th scope="col" class="fWeight-600">Type</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drug of branded">
                  <td class="fColor-blue fWeight-600 pl-4">{{ drug.drug_info.brand_name }}</td>
                  <td>{{ drug.drug_info.dosage }}</td>
                  <td>{{ drug.drug_info.package }}</td>
                  <td class="text-right">
                    <a class="select-button btn btn-second btn-xs mr-3 px-4 text-primary max-w-85" (click)="viewNextStep(drug.rxcui)">Select</a>
                  </td>
                </tr>
              </tbody>
            </table>          
          </div>
        </ng-container> -->

        <!-- <ng-container *ngIf="view_drug_list && generic.length">
          <div class="drug-selection-card mt-4">
            <div class="d-flex align-items-center justify-content-center py-3">
              <img src="./assets/img/my-drugs/generic-name.svg" alt="Tablet Icon" class="mt-0 mr-3 max-w-90">
              <h4 class="text-capitalize">Generic Versions</h4>
            </div>
            <table class="drug-table table table-striped table-borderless text-left">
              <thead>
                <tr>
                  <th scope="col" class="fWeight-600 pl-4">Name</th>
                  <th scope="col" class="fWeight-600">Dosage</th>
                  <th scope="col" class="fWeight-600">Type</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drug of generic">
                  <td class="fColor-blue fWeight-600 pl-4">{{ drug.drug_info.drug_name }}</td>
                  <td>{{ drug.drug_info.dosage }}</td>
                  <td>{{ drug.drug_info.package }}</td>
                  <td class="text-right">
                    <a class="select-button btn btn-second btn-xs mr-3 px-4 text-primary max-w-85" (click)="viewNextStep(drug.rxcui)">Select</a>
                  </td>
                </tr>
              </tbody>
            </table> 
          </div>
        </ng-container> -->


        <ng-container *ngIf="view_drug_list && branded_grouped.length">
          <div class="drug-selection-card with-table-separator mt-4">
            <div class="d-flex align-items-center justify-content-center py-3">
              <img src="./assets/img/my-drugs/generic-name.svg" alt="Tablet Icon" class="mt-0 mr-3 max-w-90">
              <h4 class="text-capitalize dblue">Branded Versions</h4>
            </div>
            <!-- new -->
            <ng-container *ngFor="let group of branded_grouped; let i = index">
              <h5 class="py-2">{{ group.type }}</h5>
              <table class="drug-table table table-striped table-borderless text-left">
                <thead *ngIf="i == 0">
                  <tr>
                    <th scope="col" class="col-name fWeight-600 pl-4">Name</th>
                    <th scope="col" class="col-dosage fWeight-600">Dosage</th>
                    <th scope="col" class="col-type fWeight-600 text-center">Type</th>
                    <!-- <th scope="col" class="col-icon">&nbsp;</th> -->
                    <th scope="col" class="col-action">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let drug of group.drugs">
                    <td class="fColor-blue fWeight-600 pl-4 col-name">{{ drug.drug_info.brand_name }}</td>
                    <td class="col-dosage">{{ drug.drug_info.dosage }}</td>
                    <td class="col-type">
                      <div class="d-flex align-items-center">
                        <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('tablet'); else temp_capsule">
                          <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                        </ng-container>
  
                        <ng-template #temp_capsule>
                          <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('capsule'); else temp_injection">
                            <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                          </ng-container>
                        </ng-template>
                        
                        <ng-template #temp_injection>
                          <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('injection'); else temp_meds">
                            <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                          </ng-container>
                        </ng-template>
  
                        <ng-template #temp_meds>
                          <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                        </ng-template>     
                        <span>{{ drug.drug_info.package }}</span>
                      </div>
                    </td>
                    <!-- <td class="col-icon">

                      <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('tablet'); else temp_capsule">
                        <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                      </ng-container>

                      <ng-template #temp_capsule>
                        <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('capsule'); else temp_injection">
                          <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                        </ng-container>
                      </ng-template>
                      
                      <ng-template #temp_injection>
                        <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('injection'); else temp_meds">
                          <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                        </ng-container>
                      </ng-template>

                      <ng-template #temp_meds>
                        <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                      </ng-template>                      
                      
                    </td> -->
                    <td class="text-right col-action">
                      <a class="mx-auto select-button btn btn-second btn-xs mr-3 px-4 text-primary max-w-85" (click)="viewNextStep(drug, 1)">Select</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </ng-container>
        
        <ng-container *ngIf="view_drug_list && generic_grouped.length">
          <div class="drug-selection-card mt-4 with-table-separator">
            <div class="d-flex align-items-center justify-content-center py-3">
              <img src="./assets/img/my-drugs/generic-name.svg" alt="Tablet Icon" class="mt-0 mr-3 max-w-90">
              <h4 class="text-capitalize dblue">Generic Versions</h4>
            </div>
            <!-- new -->
            <ng-container *ngFor="let group of generic_grouped; let i = index">
              <h5 class="py-2">{{ group.type }}</h5>
              <table class="drug-table table table-striped table-borderless text-left">
                <thead *ngIf="i == 0">
                  <tr>
                    <th scope="col" class="col-name fWeight-600 pl-4">Name</th>
                    <th scope="col" class="col-dosage fWeight-600">Dosage</th>
                    <th scope="col" class="col-type fWeight-600 text-center">Type</th>
                    <!-- <th scope="col" class="col-icon">&nbsp;</th> -->
                    <th scope="col" class="col-action">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let drug of group.drugs">
                    <td class="fColor-blue fWeight-600 pl-4 col-name">{{ drug.drug_info.drug_name }}</td>
                    <td class="col-dosage">{{ drug.drug_info.dosage }}</td>
                    <td class="col-type">
                      <div class="d-flex align-items-center">
                        <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('tablet'); else temp_capsule">
                          <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                        </ng-container>
  
                        <ng-template #temp_capsule>
                          <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('capsule'); else temp_injection">
                            <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                          </ng-container>
                        </ng-template>
                        
                        <ng-template #temp_injection>
                          <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('injection'); else temp_meds">
                            <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                          </ng-container>
                        </ng-template>
  
                        <ng-template #temp_meds>
                          <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="col-icon mt-0 max-w-90 mr-3">
                        </ng-template>
                        <span>{{ drug.drug_info.package }}</span>
                      </div>
                    </td>
                    <!-- <td class="col-icon">

                      <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('tablet'); else temp_capsule">
                        <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                      </ng-container>

                      <ng-template #temp_capsule>
                        <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('capsule'); else temp_injection">
                          <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                        </ng-container>
                      </ng-template>
                      
                      <ng-template #temp_injection>
                        <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('injection'); else temp_meds">
                          <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                        </ng-container>
                      </ng-template>

                      <ng-template #temp_meds>
                        <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                      </ng-template>                      
                      
                    </td> -->
                    <td class="text-right col-action">
                      <a class="select-button btn btn-second btn-xs mr-3 px-4 text-primary max-w-85 mx-auto" (click)="viewNextStep(drug, 0)">Select</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </ng-container>
            
        <div *ngIf="!view_drug_list && !branded.length" class="dp-card pt-4">
          <h4 class="text-capitalize dblue">Search Your Drugs By Category</h4>
          <div class="row">
            <div class="col-12 col-md-6 p-0 p-md-4">
              <a role="button" class="btn-select-from-a-list" (click)="onClick_ViewTheMostCommonDrugs('common_drug')">
                <h5 class="text-center">Most Common Drugs</h5>
                <img src="./assets/img/my-drugs/6-tablet-blue-bg.JPG" class="max-h-150" alt="View The Most Common Drugs"> 
                <h6 class="fColor-base fWeight-600 mt-2">View The Most Commonly Taken Drugs</h6>
              </a>
            </div>
            <div class="col-12 col-md-6 p-0 p-md-4">
              <a role="button" class="btn-select-from-a-list" (click)="onClick_ViewTypesOfMedications()">
                <h5 class="text-center">Drug Types</h5>
                <img src="./assets/img/my-drugs/blue-rx-prescription.JPG" class="max-h-150" alt="Search By The Type of Drug or The Reason It's Taken">
                <h6 class="fColor-base fWeight-600 mt-2">Search By The Type of Drug or The Reason It's Taken</h6>
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center my-2">
            <button (click)="otherWaysToAddModal(otherWaysToAddYourDrugs)" class="btn btn-small btn-first">
              Other Ways To Add Your Drugs
            </button>
          </div>
        </div>
      </ng-container>

      
      <div class="dp-card" *ngIf="viewCommonListDrugs">
          <div class="d-flex justify-content-center align-items-center mb-3">
            <img src="./assets/img/my-drugs/6-tablet-blue-bg.JPG" class="max-h-120 mr-3" alt="View The Most Common Drugs"> 
            <h4 class="fWeight-600">{{ drugTableName }}</h4>
          </div>
          <table class="table table-striped text-left">
            <thead>
              <tr>
                <th class="fWeight-500 fColor-blue">Rank</th>
                <th class="fWeight-500 fColor-blue">Drug Name</th>
                <th class="fWeight-500 fColor-blue">Total Prescriptions (2019)</th>
                <th class="fWeight-500 fColor-blue">Total Patients (2019)</th>
                <th class="fWeight-500 fColor-blue">Annual Change</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let drug of tagResults">
                <td>1</td>
                <td><a role="button" class="underlined fColor-blue fWeight-600 cursor-pointer pointer" (click)="viewNextStep_TypesMedication()">{{ drug.name }}</a></td>
                <td>112,104,359</td>
                <td>24,493,971</td>
                <td>0</td>
              </tr>              
            </tbody>
          </table>
          <button class="btn btn-third btn-small mx-auto mt-3" (click)="onClick_backToPrevView(drugTable_prev_view)">Back</button>
      </div>

      <div class="dp-card" *ngIf="viewTypesOfMedications">
        <div class="row types-of-medications">
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Blood Presure','blood_pressure')">
                <img src="./assets/img/my-drugs/bp.JPG">
                <span>Blood Pressure <i class="fa fa-info global-info-icon-first"></i></span>                 
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Cholesterol','cholesterol')">
                <img src="./assets/img/my-drugs/heart.JPG">
                <span>Cholesterol <i class="fa fa-info global-info-icon-first"></i></span>  
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Diabetes','diabetes')">
                <img src="./assets/img/my-drugs/cells.JPG">
                <span>Diabetes <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Lung & Respiratory','lung_and_respiratory')">
                <img src="./assets/img/my-drugs/lung.JPG">
                <span>Lung & Respiratory <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Mental Health','mental_health')">
                <img src="./assets/img/my-drugs/brain.JPG">
                <span>Mental Health <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
            <div class="col-12 col-sm-6 p-3">
              <a role="button" class="types-of-medications-btn cursor-pointer" (click)="onClick_types_of_medications('Drugs for Kidney Health','kidney_health')">
                <img src="./assets/img/my-drugs/kidney.JPG">
                <span>Kidney Health <i class="fa fa-info global-info-icon-first"></i></span>
              </a>
            </div>
        </div>
        <button class="btn btn-third btn-small mx-auto" (click)="onClick_ViewDefault()">Back</button>
      </div>
</div>

<div class="d-none">
  <div class="text-center" #otherWaysToAddYourDrugs>
    <h4>Other Ways To Add Your Drugs</h4>
    <div class="row mt-2">
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/upload-import.svg" alt="">
          <p class="mb-0 font-weight-bold">Upload or Import Your List</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/take-picture.svg" alt="">
          <p class="mb-0 font-weight-bold">Take A Picture of My Drug Bottles & Send Them</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/call-phone.svg" alt="">
          <p class="mb-0 font-weight-bold">Call Me To Provide Them Over The Phone</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="other-buttons btn btn-plain border p-4 w-100">
          <img class="mb-2" src="./assets/img/my-drugs/connect.svg" alt="">
          <p class="mb-0 font-weight-bold">Connect My Drug Record</p>
        </button>
      </div>
    </div>
  </div>
</div>