import {Injectable} from '@angular/core';

@Injectable()

/*
* This file serves as a common shared file for utility functions
*/

export class UtilityService{

    constructor(){

    }

    createReadableTimeDateStamp(dt: string): string{
        let d = new Date(parseInt(dt));
        let month = this.translateMonth(d.getMonth()),
            day = d.getDay(),
            year = d.getFullYear(),
            hour = d.getHours(),
            minute = d.getMinutes();

        return month + ' ' + day + ', ' + year + ' ' + hour + ':' + minute;
    }

    evulateBoolean(bool: boolean): string{
        if(bool){
            return 'Yes';
        }else{
            return 'No';
        }
    }

    translateMonth(month):string{
        let monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return monthArr[month];
    }
}
