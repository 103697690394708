import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorized-representative-question-slide1',
  templateUrl: './slide1.component.html',
  styleUrls: ['./slide1.component.scss']
})
export class Slide1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
