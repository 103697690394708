import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorized-representative-question-slide2',
  templateUrl: './slide2.component.html',
  styleUrls: ['./slide2.component.scss']
})
export class Slide2Component implements OnInit {

  isHomeAddress:any = '';

  constructor() { }

  ngOnInit() {
  }

  onChange_isHomeAddress(value){
    this.isHomeAddress = value;
  }

}
