import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import {DrugsService} from '../../../services/drugs.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {AuthService} from '../../../services/auth.service';
import {Sortable} from 'sortablejs';


@Component({
  selector: 'app-common-step4',
  templateUrl: './common-step4.component.html',
  styleUrls: ['./common-step4.component.scss']
})
export class CommonStep4Component implements OnInit {
  @ViewChild('sortPharmacy') sortPharmacy: ElementRef;

  existPharmacyNpi:any;
  pharmacyForm: FormGroup;
  rxcui: any;
  results: any[] = [];
  copyResults: any[] = [];
  isLoading:boolean = false;
  pharmacies: any = [];
  subscription: Subscription;
  existingPharmacies_list: any;
  dblocking = 'd-none';
  months: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  selectedMonth: number;
  pharmacyCheckLabel:string = "Select Pharmacy";
  searchBy: string = 'pharmacy_name';
  isFormSubmitted:boolean = false;
  paramsObject:any;
  paramZipcode:any;
  showPharmacyBtnState:boolean = false;
  selectedAddPharmacy: any = null;
  isUserLoggedIn:boolean;
  drugListID: any;
  showPharmacyNoteForm:boolean = false;
  single_tags:any = [];
  single_notes:any = '';

  constructor(private _router: Router, private route: ActivatedRoute, public drugService: DrugsService, private formBuilder: FormBuilder,  private _auth: AuthService) {

    this.isUserLoggedIn = _auth.isAuthenticated(); // check if user logged in

    this.existingPharmacies_list = this.drugService.fetchAddedPharmacyList();

    this.route.queryParamMap
    .subscribe((params) => {
        this.paramsObject = {...params };
        this.paramZipcode = this.paramsObject['params'].zipcode;
    });
    
    this.assignPharmacyNpi();

    this.getMarketplaceDrugListID();

  }

  ngOnInit() {

    this.subscription = this.drugService.retrievePharmacyList().subscribe((d)=>{
      this.existingPharmacies_list = d
      this.assignPharmacyNpi();
    });

    this.rxcui = this.route.snapshot.paramMap.get('rxcui');

    this.pharmacyForm = this.formBuilder.group({
      zipcode: [this.paramZipcode, Validators.required],
      pharmacy_name: ['', Validators.required],
      radius: ['2', Validators.required],
      search_by_type: ['', Validators.required],
      pharmacy_street: ['', Validators.required]      
    });

    this.autoSearchPharmacy(); //call function to autosearch pharmacy
    this.sortablePharma();
  }

  async getMarketplaceDrugListID(){
    const list = await this.drugService.getDrugLists();    
    const listInfo_filter = list.data.filter(list => list.name.toLowerCase() == this.drugService.forbiddenDrugListName);
    this.drugListID = listInfo_filter[0].id;
    //this.drugService.fetch_drugList_data(listInfo_filter[0].id);
  }

  assignPharmacyNpi(){    
    this.existPharmacyNpi = this.existingPharmacies_list.map(a => a.npi);
  }

  // function to autosearch pharmacy
  async autoSearchPharmacy(){
    this.isLoading = true;
    try {
      //this.isLoading[type] = true;
      const results: any = await this.drugService.step4(this.pharmacyForm.value);

      if (results) {
        this.assignPharmacyNpi();
        this.results = results.results.slice(0,10);
        //cloned results array to concatinate all address values into new Property "complete_address"
        this.copyResults = this.results.map(v => ({...v, addresses: v.addresses.map(x =>({...x, complete_address: x.line_one + ' ' + x.line_two + ' ' + x.city + ' ' + x.state})), other_business_name: this.drugService.removeSWordFromPharmacyName(v.other_business_name), business_name: this.drugService.removeSWordFromPharmacyName(v.business_name), telephone_number: this.drugService.formatPhoneNumber(v.telephone_number), fax_number: this.drugService.formatPhoneNumber(v.fax_number)}))
        this.results = this.copyResults;        
        //this.view_selectAmount = true;
      }
      this.isLoading = false;
    } catch (e) {
      console.log('error', e);
      this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
    }
  }

  async onSubmit() {
    
    //reset search by type and pharmacy Street value after submit
    this.pharmacyForm.controls['pharmacy_street'].reset();
    this.pharmacyForm.controls['search_by_type'].reset('pharmacy_name');
    this.searchBy = "pharmacy_name";

    this.isLoading = true;
    try {
      //this.isLoading[type] = true;
      const results: any = await this.drugService.step4(this.pharmacyForm.value);
      console.log('result xxxxx = ', results);

      if (results) {
        this.assignPharmacyNpi();
        this.results = results.results;
        //cloned results array to concatinate all address values into new Property "complete_address"
        this.copyResults = this.results.map(v => ({...v, addresses: v.addresses.map(x =>({...x, complete_address: x.line_one + ' ' + x.line_two + ' ' + x.city + ' ' + x.state})), other_business_name: this.drugService.removeSWordFromPharmacyName(v.other_business_name), business_name: this.drugService.removeSWordFromPharmacyName(v.business_name)}))
        this.results = this.copyResults;
        this.isFormSubmitted = true;
        //this.view_selectAmount = true;
      }
      this.isLoading = false;
    } catch (e) {
      console.log('error', e);
      this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
    }
  }
  
  // pharmacySelected(pharmacy, event) {
  //   console.log(this.existingPharmacies_list)
  //   document.querySelector('#pharmacy_label_id_'+pharmacy.npi).classList.toggle('active');
  //   // console.log('pharmacySelected',pharmacy);
    
  //   let practiceAddress = pharmacy.addresses.filter( (item) => item.type === 'PRACTICE' );
  //   let address = '';
  //   if( practiceAddress.length ) {
  //     address = practiceAddress[0].line_one + ' ' + practiceAddress[0].line_two + '' + practiceAddress[0].city + '' + practiceAddress[0].state + ' ' + practiceAddress[0].zip_5 +' ('+ practiceAddress[0].type +')'; 
  //   }

  //   const pharmacyInfo = {
  //     'npi' : pharmacy.npi,
  //     'pharmacy_name' : pharmacy.business_name,
  //     'tags' : this.single_tags,
  //     'notes' : this.single_notes,
  //     //'business_name' : pharmacy.business_name,
  //     'telephone_number' : pharmacy.telephone_number,
  //     'pharmacy_address': address
  //   };

  //   // const pharmacyInfo = {
  //   //   'npi' : pharmacy.npi,
  //   //   'business_name' : pharmacy.business_name,
  //   //   'telephone_number' : pharmacy.telephone_number
  //   // };

  //   this.existingPharmacies_list = this.drugService.fetchAddedPharmacyList();

  //   if (event.currentTarget.checked) {
  //     this.pharmacyCheckLabel = 'Added to List';
      
  //     this.drugService.addPharmacyList(pharmacyInfo);
  //   } else {
  //     this.pharmacyCheckLabel = "Select Pharmacy";
  //     this.drugService.removePharmacyList(pharmacyInfo);
  //   }
  // }

  addSelectedPharmacy(content, pharmacy) {
    
    this.selectedAddPharmacy = pharmacy;
    console.log(this.selectedAddPharmacy);
    Swal.fire({
      html: content,
      padding: 0,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
      onClose: ()=>{
        this.selectedAddPharmacy = null;
      }
    });
  }

  confirmAddPharmacy(pharmacy, content) {
    document.querySelector('#pharmacy_label_id_'+pharmacy.npi).classList.toggle('active');
    let practiceAddress = pharmacy.addresses.filter( (item) => item.type === 'PRACTICE' );
    let address = '';
    if( practiceAddress.length ) {
      // address = practiceAddress[0].line_one + ' ' + practiceAddress[0].line_two + '' + practiceAddress[0].city + '' + practiceAddress[0].state + ' ' + practiceAddress[0].zip_5 +' ('+ practiceAddress[0].type +')'; 
      address = practiceAddress[0].line_one + ' ' + practiceAddress[0].line_two + '' + practiceAddress[0].city; 
    }
    const pharmacyInfo = {
      'npi' : pharmacy.npi,
      'pharmacy_name' : pharmacy.business_name,
      'tags' : this.single_tags.toString(),
      'notes' : this.single_notes,
      //'business_name' : pharmacy.business_name,
      'telephone_number' : pharmacy.telephone_number,
      'pharmacy_address': address
    };

    const pharmacyInfoDb = {
      'rx_list_id' : this.drugListID,
      'npi' : pharmacy.npi,
      'pharmacy_name' : pharmacy.business_name,
      'tags' : this.single_tags.toString(),
      'notes' : this.single_notes,
      //'business_name' : pharmacy.business_name,
      'telephone_number' : pharmacy.telephone_number,
      'pharmacy_address': address
    };

    if(this.isUserLoggedIn){
      this.addPharmacyToDb(pharmacyInfoDb);
    }else{
      this.drugService.addPharmacyList(pharmacyInfo);
      this.single_notes = '';
      this.single_tags = [];
    }

    // Swal.fire(
    //   'Pharmacy successfully added to your list!',
    //   null,
    //   'success',
    // );

    Swal.fire({
      html: content,
      padding: 40,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
    });
  }

  closeModal() {
    Swal.close();
  }

  showNotesModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 500,
    })
  }

  async addPharmacyToDb(pharmacy){

    const status = await this.drugService.saveIndividualPharmacy(pharmacy);

    if(status.code == 1){
      this.drugService.fetch_drugList_data(this.drugListID); 
      this.isLoading = false;  
      this.single_notes = '';
      this.single_tags = [];
    }
  }

  // selectPharmacy() {
  //   Swal.close();
  //   this._router.navigate(['../../step8', this.rxcui], { queryParams : {}, queryParamsHandling: 'merge', relativeTo: this.route});
  // }
  // isLeapYear() {

  // }

  monthChanged(value) {
    localStorage.setItem('rx_month_coverage', JSON.stringify(this.selectedMonth));
    console.log('selected month', this.selectedMonth);
    console.log(value);
  }

  onClick_MoreInfo(npi){

    const showPharmaContent = document.querySelector('#pharmacy_result_additional_info_'+npi);
    const showPharmaBtn = document.querySelector('#pharmacy_result_additional_info_btn_'+npi);
    
    this.showPharmacyBtnState = !this.showPharmacyBtnState;

    if(this.showPharmacyBtnState){
      showPharmaBtn.textContent = 'Less Info';
    }else{
      showPharmaBtn.textContent = 'More Info';      
    }

    showPharmaContent.classList.toggle('d-block');
    showPharmaBtn.classList.toggle('is-hide');
    
  }
  

  onClick_DoneAddingDrugsPharma(){
    this._router.navigate(['../../step9', this.rxcui], { queryParams : {}, queryParamsHandling: 'merge', relativeTo: this.route});
    //this._router.navigate(['../../step8', this.rxcui], { queryParams : {}, queryParamsHandling: 'merge', relativeTo: this.route});
  }

  onSearchPharmacy(searchKey) {
    let copy = this.copyResults;
    if( searchKey !== '' ) {
      if( this.searchBy === 'pharmacy_name' ) {
        copy = copy.filter(item => item.business_name.indexOf(searchKey.toUpperCase()) !== -1 )
      } else if( this.searchBy === 'street_address' ) {
        copy = copy.filter(item => typeof item.addresses[0] !== 'undefined' && (item.addresses[0].complete_address.indexOf(searchKey.toUpperCase()) !== -1 ) )
      }
    }
    this.results = copy ? copy : [];
  }

  onSelectedChange(searchBy) {
    this.searchBy = searchBy;  
    this.pharmacyForm.controls['pharmacy_street'].reset();
    this.results = this.copyResults;
  }

  onClick_setNotes(event){  
    this.single_notes = event.notes;
    this.single_tags = event.tags;
    this.showPharmacyNoteForm = false;
  }

  showModal(content){
    Swal.fire({
      html:content,
      padding:0,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
    })
  }

  showModalTimeout(content, pharmacy) {
    let practiceAddress = pharmacy.addresses.filter( (item) => item.type === 'PRACTICE' );
    let address = '';
    if( practiceAddress.length ) {
      //address = practiceAddress[0].line_one + ' ' + practiceAddress[0].line_two + '' + practiceAddress[0].city + '' + practiceAddress[0].state + ' ' + practiceAddress[0].zip_5 +' ('+ practiceAddress[0].type +')'; 
      address = practiceAddress[0].line_one + ' ' + practiceAddress[0].line_two + '' + practiceAddress[0].city; 
    }
    const pharmacyInfo = {
      'npi' : pharmacy.npi,
      'pharmacy_name' : pharmacy.business_name,
      'tags' : this.single_tags.toString(),
      'notes' : this.single_notes,
      //'business_name' : pharmacy.business_name,
      'telephone_number' : pharmacy.telephone_number,
      'pharmacy_address': address
    };

    const pharmacyInfoDb = {
      'rx_list_id' : this.drugListID,
      'npi' : pharmacy.npi,
      'pharmacy_name' : pharmacy.business_name,
      'tags' : this.single_tags.toString(),
      'notes' : this.single_notes,
      //'business_name' : pharmacy.business_name,
      'telephone_number' : pharmacy.telephone_number,
      'pharmacy_address': address
    };

    if(this.isUserLoggedIn){
      this.addPharmacyToDb(pharmacyInfoDb);
    }else{
      this.drugService.addPharmacyList(pharmacyInfo);
      this.single_notes = '';
      this.single_tags = [];
    }


    Swal.fire({
      html:content,
      padding:'40px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
    })

    window.scrollTo({top: 0, behavior: 'smooth'});
    const myTimeout = setTimeout(myStopFunction, 7000);
    function myStopFunction() {
      Swal.close();
      clearTimeout(myTimeout);
    }
  }

  sortablePharma() {
    var el = document.getElementById('sortable-items');
    new Sortable(el, {
      animation: 150,
      ghostClass: 'dragging'
  });
  }
}
