<div class="primary-box">
  <div class="box-top">
    <h4 class="text-center fWeight-600 mb-4">Login To Your TapTap Account</h4>
    <p *ngIf="!isValidCredentials && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
        Invalid Credentials, Please try again!
    </p>
    <p *ngIf="email.invalid && email.errors['required'] && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
        Email is required
    </p>
    <p *ngIf="password.invalid && password.errors['required'] && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
        Password is required
    </p>
    <p *ngIf="taptapRecaptcha.invalid && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
        Please verify you're are a human
    </p>
    <p *ngIf="isWrongLogin && isSignInUsingExternalParty && existingUserMessage === null" class="alert alert-danger">
        Failed to login using {{(externalParty[0].toUpperCase() + externalParty.substring(1))}}
    </p>
    <p *ngIf="isWrongLogin && isSignInUsingExternalParty && existingUserMessage !== null" class="alert alert-danger">
        {{existingUserMessage}}
    </p>
    <form [formGroup]="loginForm">
        <div class="row mb-3">
            <div class="col px-1">
                <label class="form-label" for="email">Email or Phone<sup>*</sup></label>
                <input type="email" class="form-field" name="email" id="email" formControlName="email" required />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col px-1">
                <label class="form-label" for="password">Password<sup>*</sup></label>
                <input type="password" class="form-field" name="password" id="password" formControlName="password" required />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col px-1 d-flex align-items-center">
                <input class="mr-1" type="checkbox" name="" id="">
                <label class="mb-0">Remember Me</label>
            </div>
        </div>
        <div class="d-flex flex-column flex-lg-row mb-3 align-items-center justify-content-between">
            <div class="d-flex flex-column recaptcha-inner-wrapper">
                <recaptcha
                    [formControl]="taptapRecaptcha"
                    (scriptLoad)="onScriptLoad()"
                    (scriptError)="onScriptError()"
                    class="recaptcha-wrapper"
                    style="transform:scale(0.80);-webkit-transform:scale(0.80);transform-origin:0 0;-webkit-transform-origin:0 0;">
                </recaptcha>
            </div>
            <button type="submit" class="btn btn-first btn-small btn-login-now" (click)="login()">
                Login
            </button>
        </div>
        <app-loading *ngIf="showLoading"></app-loading>
    </form>
    <div class="row justify-content-center" *ngIf="!dialogData">
        <p class="text-center mb-0">Forgot your login credentials? <a routerLink="/forgot-password" class="color-primary fWeight-600">Click Here</a></p>
    </div>
    <div class="row my-3">
        <div class="col">
        <div class="or-separator">
            <hr>
            <span class="px-2">OR LOGIN WITH</span>
        </div>
        </div>
    </div>
    <div class="d-flex flex-column align-items-center mb-3">
        <button (click)="loginUsingExternalParty('facebook')" class="social-login-button facebook-button d-flex align-items-center px-3 py-2 mb-2">
            <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
            <span>Login with Facebook</span>
        </button>
        <button (click)="loginUsingExternalParty('twitter')" class="social-login-button twitter-button d-flex align-items-center px-3 py-2 mb-2">
            <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
            <span>Login with Twitter</span>
        </button>
        <button (click)="loginUsingExternalParty('google')" class="social-login-button google-button d-flex align-items-center px-3 py-2">
            <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
            <span>Login with Google</span>
        </button>
    </div>
    <div class="row">
        <div class="col px-1">
            <button class="setup-button py-2" *ngIf="!dialogData" routerLink="/registration">Don't Have An Account Yet? Set Up One Here</button>
        </div>
    </div>
</div>
  <!-- <div class="box-top">
    <p *ngIf="!isValidCredentials && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
      Invalid Credentials, Please try again!
    </p>
    <p *ngIf="email.invalid && email.errors['required'] && submitIsClicked && !isSignInUsingExternalParty"
      class="alert alert-danger">
      Email is required
    </p>
    <p *ngIf="password.invalid && password.errors['required'] && submitIsClicked && !isSignInUsingExternalParty"
      class="alert alert-danger">
      Password is required
    </p>
    <p *ngIf="taptapRecaptcha.invalid && submitIsClicked && !isSignInUsingExternalParty" class="alert alert-danger">
      Please verify you're are a human
    </p>
    <p *ngIf="isWrongLogin && isSignInUsingExternalParty && existingUserMessage === null"
      class="alert alert-danger">
      Failed to login using {{(externalParty[0].toUpperCase() + externalParty.substring(1))}}
    </p>
    <p *ngIf="isWrongLogin && isSignInUsingExternalParty && existingUserMessage !== null"
      class="alert alert-danger">
      {{existingUserMessage}}
    </p>

    <h4 class="fColor-base fWeight-600">Login To Your TapTap Account</h4>
    <form [formGroup]="loginForm">
      <div class="form-group">
        <label for="email">Email<sup>*</sup></label>
        <input type="email" class="form-control" name="email" id="email" formControlName="email" required />
      </div>
      <div class="form-group">
        <label for="password">Password<sup>*</sup></label>
        <input type="password" class="form-control" name="password" id="password" formControlName="password"
          required />
      </div>

      <div class="d-flex flex-column flex-wrap flex-sm-row justify-content-between mb-3">
        <div class="d-flex flex-column recaptcha-inner-wrapper">
          <recaptcha [formControl]="taptapRecaptcha" (scriptLoad)="onScriptLoad()" (scriptError)="onScriptError()"
            class="recaptcha-wrapper"
            style="transform:scale(0.80);-webkit-transform:scale(0.80);transform-origin:0 0;-webkit-transform-origin:0 0;">
          </recaptcha>
        </div>
        <button type="submit" class="btn btn-first btn-login-now" (click)="login()">
          Login Now
        </button>
      </div>
      <div class="form-group" *ngIf="!dialogData">
        <p>Forgot your login credentials? <a routerLink="/forgot-password" class="color-primary fWeight-600">Click
            Here</a></p>
      </div>

      <div class="row justify-content-center login-separator">
        <label> OR </label>
      </div>

      <div class="col-12 d-flex justify-content-center">
        <div class="verifyReview_icon fb" title="Login to Facebook" (click)="loginUsingExternalParty('facebook')">
        </div>
        <div class="verifyReview_icon twitter" title="Login to Twitter"
          (click)="loginUsingExternalParty('twitter')"></div>
        <div class="verifyReview_icon google" title="Login to Google" (click)="loginUsingExternalParty('google')">
        </div>
      </div>
      <app-loading *ngIf="showLoading"></app-loading>
    </form>
  </div>
  <div class="box-bottom d-flex justify-content-between align-items-center">
    <button id="dontHaveAccountBtn" class="btn" *ngIf="!dialogData" (click)="onClick_ShowSignUpForm()">Don't have an account? Register Here</button>
  </div> -->
</div>

<div class="d-none">
  <div #modal_success_added_to_drug_list>
    <h4 class="text-center">Your Drugs and Pharmacies has been successfully added to your Default Marketplace Drug List on Benny Dash</h4>
    <button class="btn btn-first btn-xs mx-auto mt-4" (click)="onReloadPage_AfterLogin()">Ok</button>
  </div>
  <!-- <div #modal_WithList>
    <div class="d-flex justify-content-center align-items-center flex-column">
      <button class="btn btn-first btn-small mb-3 w-100" (click)="onClick_DoneAddingDrugsPharma_Update()">Overwrite your Existing Default List?</button>
      <button class="btn btn-first btn-small w-100">Save to a new list?</button>
    </div>
  </div> -->
</div>

