<div class="container-fluid header-banner-wrapper home-banner-wrapper force-height-auto">
	<app-header-b></app-header-b>
  
	<div class="container home-banner banner-wrapper unset-absolute-position">
	  <div class="d-flex">
		<div class="home-banner-left">
		  <div class="home-banner-text-wrapper">
			<h2 class="banner-main-title-subtext text-center text-sm-left">A Clear & Organized</h2>
			<h1 class="banner-main-title">
			  Medicare Experience
			</h1>
			<mat-list class="mt-4">
			  <mat-list-item>
				<mat-icon mat-list-icon color="primary">check</mat-icon>
				<p class="text-capitalize">Easy Consumer-Friendly View of Plans</p>
			  </mat-list-item>
			  <mat-list-item>
				<mat-icon mat-list-icon color="primary">check</mat-icon>
				<p class="text-capitalize">Clear Explanations & Recommendations</p>
			  </mat-list-item>
			  <mat-list-item>
				<mat-icon mat-list-icon color="primary">check</mat-icon>
				<p class="text-capitalize">Guidance & Support All Year</p>
			  </mat-list-item>
			</mat-list>
			<div
			  class="home-banner-btn d-flex justify-content-around justify-content-md-start flex-column flex-md-row align-items-center">
			  <a routerLink="/benny-dash" class="btn btn-third text-uppercase">Go To My Benny HQ</a>
  
			  <button class="btn btn-first ml-2" routerLink="/insurance-marketplace">View Plans</button>
			</div>
  
		  </div>
		</div>
		<div class="home-banner-right banner-image-wrapper">
		  <!-- <img src="assets/img/home/new/blonde-old-woman-holding-a-phone.png"> -->
		  <img src="assets/img/common/medigap/plan-n-banner-character.png">
		</div>
	  </div>
	</div>
</div>
  
<app-insurance-logo [showLogo]="showLogo"></app-insurance-logo>

<section class="third-variation-section mt-5 mb-5">
	<div class="third-variation-container">
		<div class="container">
			<h1 class="color-primary text-capitalize text-center pt-5 mb-5">2 Ways To Start Searching</h1>
			<div class="row">
				<div class="col-md-6 col-sm-12 third-variation-clickable">
					<a class="tv-amount-coverage-content" routerLink="/life-insurance/the-amount-of-coverage">
						<img src="assets/img/third-variation/1-third-variation.png" alt="">
						<h3 class="color-primary text-capitalize">The Amount of Coverage</h3>
					</a>
				</div>
				<div class="col-md-6 col-sm-12 third-variation-clickable">
					<a class="tv-cost-coverage-content" routerLink="/life-insurance/the-cost-of-coverage">
						<img src="assets/img/third-variation/2-third-variation.png" alt="">
						<h3 class="color-primary text-capitalize">The Cost of Coverage</h3>
					</a>
				</div>
			</div>
		</div>
	</div>
</section>

<app-enjoyable-options [showBottomPadding]="true"></app-enjoyable-options>

<app-ready-to-talk [isLandingPage]="true" [thirdVariation]="true" spacerColor="#f2f2f2"></app-ready-to-talk>

<div class="d-none">
  <div #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>
