<div *ngIf="shareStep == 'main'">
  <div class="share-how-modal p-4">
      <img class="logo mx-auto mb-3" src="./assets/img/common/taptap-logo-colored.svg" alt="">
      <h5 class="share-header text-center mb-3">
          How Do You Want To Share This Plan?
      </h5>
      <!-- <button (click)="onShare(shareContent)" class="share-specific">
          <img src="./assets/img/new-benny-icons/share.png" alt="">
          <h6>Share With A Specific Individual</h6>
      </button> -->
      <div class="share-specific">
        <img src="./assets/img/new-benny-icons/share.png" alt="">
        <h6>Share With A Specific Individual</h6>
      </div>
      <div class="or-separator">
          <hr>
          <span class="px-2">OR</span>
      </div>
      <div class="d-flex justify-content-center">
          <button class="share-btn d-flex flex-column align-items-center mx-2">
              <div class="share-facebook">
                  <svg class="" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
              </div>
              <p class="btn-label text-center mt-1">Facebook</p>
          </button>
          <button class="share-btn d-flex flex-column align-items-center mx-2" (click)="shareStep = 'email'">
              <div class="share-email">
                  <i class="fas fa-envelope"></i>
              </div>
              <p class="btn-label text-center mt-1">Email</p>
          </button>
          <button class="share-btn d-flex flex-column align-items-center mx-2">
              <div class="share-sms">
                  <i class="fas fa-sms"></i>
              </div>
              <p class="btn-label text-center mt-1">SMS</p>
          </button>
      </div>
  </div>
</div>
<div *ngIf="shareStep == 'email'">
  <div class="share-email-modal p-4">
      <img class="logo mx-auto mb-3" src="./assets/img/common/taptap-logo-colored.svg" alt="">
      <h5 class="share-header text-center mb-3">Who Do You Want To Share This Plan With?</h5>
      <p class="share-tip text-center px-4">We will send the email directly to the recipient within 24 hours.</p>
      <div class="share-form px-5">
          <div class="field-wrapper">
              <label for="">Email Address</label>
              <input type="text" name="" id="" placeholder="Enter email address...">
          </div>
          <div class="field-wrapper">
              <label for="">Full Name</label>
              <input type="text" name="" id="" placeholder="Enter full name...">
          </div>
          <div class="field-wrapper">
              <label for="">Phone Number</label>
              <input type="text" name="" id="" placeholder="Enter phone number...">
          </div>
          <div class="d-flex align-items-center justify-content-center my-3">
            <button class="btn btn-third btn-small mx-2" (click)="shareStep = 'main'">Back</button>
            <button class="btn btn-first btn-small mx-2" (click)="onShare(shareContent)">Submit</button>
          </div>
      </div>
      <img class="lady-asset" src="./assets/img/common/share-plan/contact-woman-cropped.png" alt="">
  </div>
</div>

<div class="d-none">
    <div #shareContent>
        <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex;">
            <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
            <span class="swal2-success-line-tip"></span>
            <span class="swal2-success-line-long"></span>
            <div class="swal2-success-ring"></div>
            <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
            <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
        </div>
        <h4 class="text-center">
            Hooray! Share success!
        </h4>
        <p class="text-center">
            Your message will be sent withing 24 hours.
        </p>
    </div>
</div>