<div class="row resultFilterModal">
  <div class="col-12 px-4 pt-4 filter-modal-header">
    <div class="d-flex align-items-end">
      <div class="d-none d-md-block">
        <img src="./assets/img/filter-modal-team.png" alt="">
      </div>
      <div class="w-100 d-flex flex-column align-items-center">
        <img src="./assets/img/filter-modal-icon.png" alt="">
        <h4 class="text-center text-light pb-3">
          Select Which Types Of Plans To Display
        </h4>              
        <button *ngIf="is_resultFilterDefault_view" class="btn btn-third btn-small p-4 border-width-5px mb-3" (click)="showSpecificCarriers()">Select Specific Carriers to Display</button>
      </div>
    </div>
  </div>
    <div class="col-12 px-sm-4 pt-3 resultFilterModal-content" *ngIf="is_resultFilterDefault_view">
        <h6>Plan Types</h6>
        <ul>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('2')" class="custom-control-input" id="filterResult_PPO" (change)="planTypeChangeCheckbox('2', $event)">
              <label class="custom-control-label" for="filterResult_PPO">
                <img src="./assets/img/medicare-advantage/new/fully-customize-plan.png" alt="">
                PPO
              </label>
            </div>
            <div class="icons" *ngIf="isChecked('2')">              
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('2')">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
            <li>
              <div class="custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" [checked]="isChecked('1')" class="custom-control-input" id="filterResult_HMO" (change)="planTypeChangeCheckbox('1', $event)">
                <label class="custom-control-label" for="filterResult_HMO">
                  <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                  HMO
                </label>
              </div>
              <div class="icons" *ngIf="isChecked('1')">                
                <i class="fas fa-eye"></i>
              </div>
              <div class="icons" *ngIf="!isChecked('1')">
                <i class="fas fa-eye-slash"></i>
              </div>
            </li>
            <li>
              <div class="custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" [checked]="isChecked('7')" class="custom-control-input" id="filterResult_HMOPOS" (change)="planTypeChangeCheckbox('7', $event)">
                <label class="custom-control-label" for="filterResult_HMOPOS">
                  <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                  HMO-POS
                </label>
              </div>
              <div class="icons" *ngIf="isChecked('7')">                
                <i class="fas fa-eye"></i>
              </div>
              <div class="icons" *ngIf="!isChecked('7')">
                <i class="fas fa-eye-slash"></i>
              </div>
            </li>
        </ul>
        <mat-divider style="border-top-width: 2px;border-top-style: dashed;margin: 1.5rem 0;border-color: #9d9d9d;"></mat-divider>
        <h6>Sort Plans By</h6>
        <ul>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('4')" class="custom-control-input" id="filterResult_lowest_monthly_premiums" (change)="planTypeChangeCheckbox('4', $event)">
              <label class="custom-control-label" for="filterResult_lowest_monthly_premiums">
                <img src="./assets/img/medicare-advantage/new/see-all-plans-with-no-deductible.png" alt="">
                Lowest Monthly Premiums
              </label>
            </div>
            <div class="icons" *ngIf="isChecked('4')">              
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('4')">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('5')" class="custom-control-input" id="filterResult_lowest_moop" (change)="planTypeChangeCheckbox('5', $event)">
              <label class="custom-control-label" for="filterResult_lowest_moop">
                <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                Lowest Max Out-of-Pocket
              </label>
            </div>
            <div class="icons" *ngIf="isChecked('5')">              
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('5')">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('6')" class="custom-control-input" id="filterResult_lowest_drug_costs_with_plan" (change)="planTypeChangeCheckbox('6', $event)">
              <label class="custom-control-label" for="filterResult_lowest_drug_costs_with_plan">
                <img src="./assets/img/medicare-advantage/new/see-all-plans-with-dental-and-vision.png" alt="">
                Lowest Drug Costs With Plan
              </label>
            </div>
            <div class="icons" *ngIf="isChecked('6')">              
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('6')">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
          <li>
              <div class="custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" [checked]="isChecked('8')" class="custom-control-input" id="filterResult_most_providers_in_network_with_plan" (change)="planTypeChangeCheckbox('8', $event)">
                <label class="custom-control-label" for="filterResult_most_providers_in_network_with_plan">
                  <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                  Most Providers In Network With Plan
                </label>
              </div>
              <div class="icons" *ngIf="isChecked('8')">                
                <i class="fas fa-eye"></i>
              </div>
              <div class="icons" *ngIf="!isChecked('8')">
                <i class="fas fa-eye-slash"></i>
              </div>
          </li>
        </ul>
        <mat-divider class="mat-divider-dotted"></mat-divider>
        <!-- <h6>Plan Features</h6>
        <ul>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('4')" class="custom-control-input" id="filterResult_no_deductible" (change)="planTypeChangeCheckbox('4', $event)">
              <label class="custom-control-label" for="filterResult_no_deductible">No Deductible</label>
            </div>

            <div class="icons" *ngIf="isChecked('4')">
              <img src="./assets/img/medicare-advantage/new/see-all-plans-with-no-deductible.png" alt="">
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('4')">
              <img src="./assets/img/medicare-advantage/new/see-all-plans-with-no-deductible.png" alt="">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('5')" class="custom-control-input" id="filterResult_lowest_moop" (change)="planTypeChangeCheckbox('5', $event)">
              <label class="custom-control-label" for="filterResult_lowest_moop">Lowest MOOP</label>
            </div>

            <div class="icons" *ngIf="isChecked('5')">
              <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('5')">
              <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('6')" class="custom-control-input" id="filterResult_best_dental" (change)="planTypeChangeCheckbox('6', $event)">
              <label class="custom-control-label" for="filterResult_best_dental">Best Dental</label>
            </div>

            <div class="icons" *ngIf="isChecked('6')">
              <img src="./assets/img/medicare-advantage/new/see-all-plans-with-dental-and-vision.png" alt="">
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('6')">
              <img src="./assets/img/medicare-advantage/new/see-all-plans-with-dental-and-vision.png" alt="">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
          <li>
              <div class="custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" [checked]="isChecked('8')" class="custom-control-input" id="filterResult_0_premium_plans" (change)="planTypeChangeCheckbox('8', $event)">
                <label class="custom-control-label" for="filterResult_0_premium_plans">$0 Premium Plans</label>
              </div>
              <div class="icons" *ngIf="isChecked('8')">
                <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                <i class="fas fa-eye"></i>
              </div>
              <div class="icons" *ngIf="!isChecked('8')">
                <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                <i class="fas fa-eye-slash"></i>
              </div>
          </li>
        </ul> -->
        <!-- <mat-divider style="border-top-width: 2px;border-top-style: dashed;margin: 1.5rem 0;border-color: #9d9d9d;"></mat-divider> -->
        <h6>My Healthcare Details</h6>
        <ul>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('9')" class="custom-control-input" id="filterResult_see_my_drug_costs" (change)="planTypeChangeCheckbox('9', $event)">
              <label class="custom-control-label" for="filterResult_see_my_drug_costs">
                <img src="./assets/img/medicare-advantage/new/see-all-plans-with-no-deductible.png" alt="">
                See My Drug Costs With Each Plan
              </label>
            </div>
            <div class="icons" *ngIf="isChecked('9')">              
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('9')">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" [checked]="isChecked('10')" class="custom-control-input" id="filterResult_see_which_plans" (change)="planTypeChangeCheckbox('10', $event)">
              <label class="custom-control-label" for="filterResult_see_which_plans">
                <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                See Which Plans My Doctors Accept
              </label>
            </div>
            <div class="icons" *ngIf="isChecked('10')">              
              <i class="fas fa-eye"></i>
            </div>
            <div class="icons" *ngIf="!isChecked('10')">
              <i class="fas fa-eye-slash"></i>
            </div>
          </li>
        </ul>
        <div class="d-flex align-items-center justify-content-center flex-column mb-4 mt-4">
          <button class="btn btn-first btn-xs mt-2 max-width-150"  (click)="filterSubmit()"> Filter Plans</button>
        </div>
    </div>
    <div class="col-12 px-sm-4 pt-3 resultFilterModal-content" *ngIf="!is_resultFilterDefault_view">
        
        <div class="option-2">
          <h6>Plan Types</h6>
          <ul>
            <li>
              <div class="custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" [checked]="isChecked('2')" class="custom-control-input" id="filterResult_all_in_network" (change)="planTypeChangeCheckbox('2', $event)">
                <label class="custom-control-label" for="filterResult_all_in_network">
                  <img src="./assets/img/medicare-advantage/new/fully-customize-plan.png" alt="">
                  All In-Network
                </label>
              </div>
              <div class="icons" *ngIf="isChecked('02')">                
                <i class="fas fa-eye"></i>
              </div>
              <div class="icons" *ngIf="!isChecked('02')">
                <i class="fas fa-eye-slash"></i>
              </div>
            </li>
              <li>
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" [checked]="isChecked('1')" class="custom-control-input" id="filterResult_the_majority_in_network" (change)="planTypeChangeCheckbox('1', $event)">
                  <label class="custom-control-label" for="filterResult_the_majority_in_network">
                    <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                    The Majority In-Network
                  </label>
                </div>
                <div class="icons" *ngIf="isChecked('01')">                  
                  <i class="fas fa-eye"></i>
                </div>
                <div class="icons" *ngIf="!isChecked('01')">
                  <i class="fas fa-eye-slash"></i>
                </div>
              </li>
              <li>
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" [checked]="isChecked('05')" class="custom-control-input" id="filterResult_any_in_network" (change)="planTypeChangeCheckbox('7', $event)">
                  <label class="custom-control-label" for="filterResult_any_in_network">
                    <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                    Any In-Network
                  </label>
                </div>
                <div class="icons" *ngIf="isChecked('07')">                  
                  <i class="fas fa-eye"></i>
                </div>
                <div class="icons" *ngIf="!isChecked('07')">
                  <i class="fas fa-eye-slash"></i>
                </div>
              </li>
              <li>
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" [checked]="isChecked('05')" class="custom-control-input" id="filterResult_my_prioritized_providers" (change)="planTypeChangeCheckbox('7', $event)">
                  <label class="custom-control-label" for="filterResult_my_prioritized_providers">
                    <img src="./assets/img/medicare-advantage/new/see-all-plans.png" alt="">
                    My Prioritized Providers In-Network
                  </label>
                </div>
                <div class="icons" *ngIf="isChecked('05')">                 
                  <i class="fas fa-eye"></i>
                </div>
                <div class="icons" *ngIf="!isChecked('05')">
                  <i class="fas fa-eye-slash"></i>
                </div>
              </li>
          </ul>
        </div>

        <mat-divider class="mat-divider-dotted my-5"></mat-divider>

        <div class="option-1">
          <h5>Select Just The Carriers You'd Like Displayed:</h5>

          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected="true">All In Network <i class="fa fa-info global-info-icon-first ml-2"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-mainly-tab" data-toggle="pill" href="#pills-mainly" role="tab" aria-controls="pills-mainly" aria-selected="false">Mainly In Network <i class="fa fa-info global-info-icon-first ml-2"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-all-carriers-tab" data-toggle="pill" (click)="displayAllCarriers()" role="tab" aria-controls="pills-mainly" aria-selected="false">All Carriers <i class="fa fa-info global-info-icon-first ml-2"></i></a>
            </li>
          </ul>

          <div class="tab-content" id="pills-tabContent" *ngIf="!loading">
            <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
              <ul>
                  <li *ngFor="let network of allNetworksCollected; let i = index">
                    <div class="custom-control custom-checkbox mr-sm-2">
                      <input type="checkbox" [checked]="isAllInNetworkChecked(network)" class="custom-control-input" id="n_all_in_network_{{i}}" (change)="allInNetworkChange(network, $event)">
                      <label class="custom-control-label" for="n_all_in_network_{{i}}">{{ network }}</label>
                    </div>
                    <div class="icons">
                      <img src="{{base_img_loc + replaceSpaceWith(network) + '.jpg'}}" alt="">
                      <i class="fas fa-eye-slash" *ngIf="!isAllInNetworkChecked(network)"></i>
                      <i class="fas fa-eye" *ngIf="isAllInNetworkChecked(network)"></i>
                    </div>
                  </li>
              </ul>
            </div>
            <div class="tab-pane fade" id="pills-mainly" role="tabpanel" aria-labelledby="pills-mainly-tab">
              <ul>
                <li *ngFor="let network of allNetworksCollected; let i = index">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked(network)" class="custom-control-input" id="n_m_{{i}}" (change)="mainlyInNetworkChange(network, $event)">
                    <label class="custom-control-label" for="n_m_{{i}}">{{ network }}</label>
                  </div>
                  <div class="icons">
                    <img src="{{base_img_loc + replaceSpaceWith(network) + '.jpg'}}" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked(network)"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked(network)"></i>
                  </div>
                </li>
            </ul>

              <!-- <ul>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('Aetna Medicare')" class="custom-control-input" id="n_m_aetna" (change)="mainlyInNetworkChange('Aetna Medicare', $event)">
                    <label class="custom-control-label" for="n_m_aetna">Aetna Medicare</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/aetna-medicare.jpg" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('Aetna Medicare')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('Aetna Medicare')"></i>
                  </div>
                </li>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('Anthem')" class="custom-control-input" id="n_m_anthem" (change)="mainlyInNetworkChange('Anthem', $event)">
                    <label class="custom-control-label" for="n_m_anthem">Anthem</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/anthem-blue-cross-and-blue-shield.jpg" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('Anthem')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('Anthem')"></i>
                  </div>
                </li>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('Cigna')" class="custom-control-input" id="n_m_cigna" (change)="mainlyInNetworkChange('Cigna', $event)">
                    <label class="custom-control-label" for="n_m_cigna">Cigna</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/cigna.jpg" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('Cigna')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('Cigna')"></i>
                  </div>
                </li>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('Clover Health')" class="custom-control-input" id="n_m_clover" (change)="mainlyInNetworkChange('Clover Health', $event)">
                    <label class="custom-control-label" for="n_m_clover">Clover Health</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/clover-health.jpg" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('Clover Health')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('Clover Health')"></i>
                  </div>
                </li>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('Geisinger')" class="custom-control-input" id="n_m_geisinger" (change)="mainlyInNetworkChange('Geisinger', $event)">
                    <label class="custom-control-label" for="n_m_geisinger">Geisinger</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/geisinger.png" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('Geisinger')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('Geisinger')"></i>
                  </div>
                </li>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('Humana')" class="custom-control-input" id="n_m_humana" (change)="mainlyInNetworkChange('Humana', $event)">
                    <label class="custom-control-label" for="n_m_humana">Humana</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/humana.jpg" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('Humana')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('Humana')"></i>
                  </div>
                </li>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('UnitedHealthcare')" class="custom-control-input" id="n_m_uhc" (change)="mainlyInNetworkChange('UnitedHealthcare', $event)">
                    <label class="custom-control-label" for="n_m_uhc">UnitedHealthcare</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/unitedhealthcare-erickson-advantage.jpg" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('UnitedHealthcare')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('UnitedHealthcare')"></i>
                  </div>
                </li>
                <li>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" [checked]="isMainlyInNetworkChecked('WellCare')" class="custom-control-input" id="n_m_wellCare" (change)="mainlyInNetworkChange('WellCare', $event)">
                    <label class="custom-control-label" for="n_m_wellCare">WellCare</label>
                  </div>
                  <div class="icons">
                    <img src="./assets/img/medicare-advantage/wellcare.jpg" alt="">
                    <i class="fas fa-eye-slash" *ngIf="!isMainlyInNetworkChecked('WellCare')"></i>
                    <i class="fas fa-eye" *ngIf="isMainlyInNetworkChecked('WellCare')"></i>
                  </div>
                </li>
              </ul> -->
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <button class="btn btn-third btn-xs mb-5 mt-5 mr-2" (click)="hideSpecificCarriers()">Back</button>
          <button class="btn btn-first btn-xs mb-5 mt-5 max-width-150" (click)="filterSubmit()">Filter Plans</button>
        </div>
    </div>
</div>