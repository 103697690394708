<h1 class="text-center fSize-32">Medicare Advantage Plan [ {{ planName }} ] | Application Info</h1>
<h4 class="text-center fWeight-600 fColor-grey mb-4 fSize-18">We Have Designed Your Application Info Experience For Your Clarity and
  Convenience. Begin By Reviewing and Inputting The Info Below:</h4>

<div id="my-base-info-wrapper">
  <mat-horizontal-stepper labelPosition="bottom" (selectionChange)="selectionChange($event)"
    [selectedIndex]="myBaseInfoStepperSelectedIndex" #stepper>

    <ng-template matStepperIcon="edit">
      <mat-icon style="color:#fff">done</mat-icon>
    </ng-template>

    <!-- Basic Info -->
    <mat-step>
      <ng-template matStepLabel>Basic Info</ng-template>

      <app-basic-info [details]="details" (emit_onClickCalendar)="onClickDob($event)" (emit_onClick_gender)="onClick_gender($event)" [firstName]="firstName" [middleName]="middleName" [lastName]="lastName"></app-basic-info>

      <div class="row px-0 px-md-4 mt-5">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Contact Info -->
    <mat-step>
      <ng-template matStepLabel>Contact Info</ng-template>

      <app-contact-info (emit_onClick_maritalStatus)="onClick_maritalStatus($event)"
        (emit_onCheckedMailingAddress)="onCheckedMailingAddress($event)" [details]="details"
        [isSpouseActive]="isSpouseActive" (emit_onClickCalendar)="onClickDob($event)"></app-contact-info>

      <!-- <h4 class="title">Contact Info</h4>
                <p class="subtitle mb-5">We keep your information private, safe, and secure.</p>
                <div class="row px-0">
                    <div class="col-12 col-sm-6 pl-0">
                    <div class="form-group">
                        <label for="">Date of Birth</label>
                        <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.date_of_birth" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
                    </div>
                    </div>
                    <div class="col-12 col-sm-6 px-0">
                    <div class="row px-0">
                        <div class="col-12 col-sm-6 pr-0">
                        <div class="form-group">
                            <label for="">City/Town</label>
                            <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.city"/>
                        </div>
                        </div>
                        <div class="col-12 col-sm-3 pr-0">
                        <div class="form-group">
                            <label for="">State</label>
                            <select class="form-control blurred" name="" id="">
                            <option value="NegOr" [selected]="details.customer_info.state == 'NegOr'">{{details.customer_info.state}}</option>
                            </select>
                        </div>
                        </div>
                        <div class="col-12 col-sm-3 pr-0">
                        <div class="form-group">
                            <label for="">Country</label>
                            <select class="form-control blurred" name="" id="">
                            <option value="Philippines" [selected]="details.customer_info.county == 'Philippines'">{{details.customer_info.county}}</option>
                            
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="separator dashed my-4"></div>    
                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-first btn-small" (click)="clickSaveCurrentCustomerInfo()">Save Changes</button>
                </div> -->

      <div class="row px-0 px-md-2 mt-5">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Medicare Info -->
    <mat-step>
      <ng-template matStepLabel>Medicare Info</ng-template>

      <app-medicare-info [details]="details" (emit_onClickCalendar)="onClickDob($event)" [medCardNumber1]="medCardNumber1" [medCardNumber2]="medCardNumber2" [medCardNumber3]="medCardNumber3"></app-medicare-info>

      <!-- <div class="settings-box mt-2">
                <h4 class="title">Medicare Info</h4>
                <p class="subtitle mb-5">We keep your information private, safe, and secure.</p>
                <div class="row px-0">
                    <div class="col-12 col-sm-6 pl-0">
                    <div class="form-group">
                        <div class="row">
                        <label class="col-12 mb-3">My Marital Status</label>
                        </div>
                        <div class="row">
                        <div class="col-12 col-md-6">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('married_living_together')" [class.active]="details.customer_info.marital_status == 'married_living_together'">Married - Living Together <i class="fas fa-check ml-2"></i></button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('divorced')" [class.active]="details.customer_info.marital_status == 'divorced'">Divorced <i class="fas fa-check ml-2"></i></button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('married_not_living_together')" [class.active]="details.customer_info.marital_status == 'married_not_living_together'">Married - Not Living Together <i class="fas fa-check ml-2"></i></button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('widowed')" [class.active]="details.customer_info.marital_status == 'widowed'">Widowed <i class="fas fa-check ml-2"></i></button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('single')" [class.active]="details.customer_info.marital_status == 'single'">Single <i class="fas fa-check ml-2"></i></button>
                        </div>
                        <div class="col-12 col-md-6">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('separated')" [class.active]="details.customer_info.marital_status == 'separated'">Separated <i class="fas fa-check ml-2"></i></button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>  
                <div *ngIf="isSpouseActive" class="separator dashed my-4"></div>    
    
                <div class="row" *ngIf="isSpouseActive">
                    <h5 class="col-12 mb-3 fColor-black fWeight-600">Tell Us About Your Spouse</h5>
                    <div class="row col-12">
                    <div class="col-12 col-sm-6 pl-0">
                        <div class="form-group">
                        <label for="">Spouse's Name</label>
                        <input type="text" class="form-control blurred"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 pr-0">
                        <div class="form-group">
                        <label for="">Spouse's Date of Birth</label>
                        <input type="text" class="form-control blurred" [(ngModel)]="memberDateofBirth" (click)="onClickDob(appDOB,'normal','Enter Spouse\'s Date of Birth')"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 pl-0">
                        <div class="form-group">
                        <label for="">Spouse's Gender</label>
                        <div class="row px-0">
                            <div class="col-12 col-md-6 pl-0">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn ml-0" (click)="onClick_spouseGender('M')" [class.active]="spouseGender == 'M'">Male<i class="fas fa-check ml-2"></i></button>
                            </div>
                            <div class="col-12 col-md-6 pl-0">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn ml-0" (click)="onClick_spouseGender('F')" [class.active]="spouseGender == 'F'">Female <i class="fas fa-check ml-2"></i></button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>  
    
                    <div *ngIf="spouseGender" class="row col-12">
                    <div class="form-group">
                        <label>Is she/he on Medicare?</label>
                        <div class="d-flex">
                        <button class="btn btn-first btn-small btn-plain btn-circle mb-3 mr-2 marketplace-input-quote-btn flex-none" (click)="onClick_spouseOnMedicare(1)" [class.active]="currentSpouseOnMedicare == 1">Yes <i class="fas fa-check ml-2"></i></button>
                        <button class="btn btn-first btn-small btn-plain btn-circle mb-3 mr-2 marketplace-input-quote-btn flex-none" (click)="onClick_spouseOnMedicare(2)" [class.active]="currentSpouseOnMedicare == 2">No <i class="fas fa-check ml-2"></i></button>
                        <button class="btn btn-first btn-small btn-plain mb-3 marketplace-input-quote-btn flex-none" (click)="onClick_spouseOnMedicare(3)" [class.active]="currentSpouseOnMedicare == 3">[He/She] Will Be Soon <i class="fas fa-check ml-2"></i></button>
                        </div>
                    </div>
                    </div>
    
                    <div *ngIf="currentSpouseOnMedicare" class="row col-12">
                    <p class="fWeight-500">
                        Would you like to add your household member's full set of info here or would you like to send them and
                        invite to their email to set up their own separate but connected account?
                    </p>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                        <button (click)="showOtherHousehold = true" class="btn btn-small btn-second bg-primary fColor-white fluid">Add Household Members Info Here</button>
                        </div>
                        <div class="col-12 col-sm-6">
                        <button class="btn btn-small btn-second fluid">Send An Invite For Them To Setup Their Own Account</button>
                        </div>            
                    </div>
                    </div>
                </div>
    
                <div *ngIf="showOtherHousehold" class="separator dashed my-4"></div>   
                
                <div *ngIf="showOtherHousehold" class="row">
                    <h5 class="col-12 mb-3 fColor-black fWeight-600">Any Other Household Members?</h5>
                    <div class="row col-12">
                    <div class="col-12 col-sm-6 pl-0">
                        <div class="form-group">
                        <label for="">Household Member’s Name</label>
                        <input type="text" class="form-control blurred"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 pr-0">
                        <div class="form-group">
                        <label for="">Household Member’s Date of Birth</label>
                        <input type="text" class="form-control blurred" [(ngModel)]="memberDateofBirth2" (click)="onClickDob(appDOB,'normal','Enter Date of Birth')"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 pl-0">
                        <div class="form-group">
                        <label for="">Household Member’s Gender</label>
                        <div class="row px-0">
                            <div class="col-12 col-md-6 pl-0">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn ml-0" (click)="onClick_householdGender('M')" [class.active]="householdGender == 'M'">Male<i class="fas fa-check ml-2"></i></button>
                            </div>
                            <div class="col-12 col-md-6 pl-0">
                            <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn ml-0" (click)="onClick_householdGender('F')" [class.active]="householdGender == 'F'">Female <i class="fas fa-check ml-2"></i></button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>  
    
                    <div class="row col-12">
                    <div class="form-group">
                        <label>Is Your Household Member On Medicare?</label>
                        <div class="d-flex">
                        <button class="btn btn-first btn-small btn-plain btn-circle mb-3 mr-2 marketplace-input-quote-btn flex-none" (click)="onClick_memberOnMedicare(1)" [class.active]="currentMemberOnMedicare == 1">Yes <i class="fas fa-check ml-2"></i></button>
                        <button class="btn btn-first btn-small btn-plain btn-circle mb-3 mr-2 marketplace-input-quote-btn flex-none" (click)="onClick_memberOnMedicare(2)" [class.active]="currentMemberOnMedicare == 2">No <i class="fas fa-check ml-2"></i></button>
                        <button class="btn btn-first btn-small btn-plain mb-3 marketplace-input-quote-btn flex-none" (click)="onClick_memberOnMedicare(3)" [class.active]="currentMemberOnMedicare == 3">[He/She] Will Be Soon <i class="fas fa-check ml-2"></i></button>
                        </div>
                    </div>
                    </div>
    
                    <div class="row col-12">
                    <p class="fWeight-500">
                        Would you like to add your household member's full set of info here or would you like to send them and
                        invite to their email to set up their own separate but connected account?
                    </p>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                        <button class="btn btn-small btn-second bg-primary fColor-white fluid">Add Household Members Info Here</button>
                        </div>
                        <div class="col-12 col-sm-6">
                        <button class="btn btn-small btn-second fluid">Send An Invite For Them To Setup Their Own Account</button>
                        </div>            
                    </div>
                    </div>
                </div>
    
                <div class="separator dashed my-4"></div> 
    
                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-first btn-small" (click)="clickSaveCurrentCustomerInfo()">Save Changes</button>
                </div>
                </div> -->
      <div class="row px-0 px-md-4 mt-5">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Coverage Info -->
    <mat-step>
      <ng-template matStepLabel>Coverage Info</ng-template>
      <app-coverage-info [details]="details"></app-coverage-info>
      <!-- <div class="settings-box mt-2">
                <h4 class="title">Coverage Info</h4>
                <p class="subtitle mb-5">Hatch your very own hero by uploading a photo and selecting its gender. Easy!</p>
                <div class="row px-0">
                    <div class="col-12 col-sm-6 px-0 mx-auto text-center">
                    <div class="form-group">  
                        <h5 class="fColor-base fWeight-600 text-center mb-5">Upload a Picture & Approve</h5>         
                        <div class="avatar-list-item-img uploaded-hero-img">
                        <img src="" class="d-none"/>
                        </div>
                        <button class="btn btn-second bg-primary fColor-white btn-small mx-auto">Browse</button>
                    </div>
                    </div>
                    <div class="col-12">
                    <p class="separator-text"><span></span>Or<span></span></p>
                    </div>
                    <div class="col-12">
                    <h5 class="fColor-base fWeight-600 text-center mb-5">Select an Avatar</h5>
                    </div>
                    <div class="col-12">
                    <div class="row avatar-list">
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                        <div class="avatar-list-item col-12 col-md-2">
                        <div class="avatar-list-item-img">
                            <img src="" alt="" class="d-none">
                        </div>              
                        <button class="btn btn-second btn-small">Select</button>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="separator dashed my-4"></div>    
                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-first btn-small" (click)="clickSaveCurrentCustomerInfo()">Save Changes</button>
                </div>
                </div> -->
      <div class="row px-0 px-md-2 mt-0">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Policy Info -->
    <mat-step>
      <ng-template matStepLabel>Policy Info</ng-template>
      <app-policy-info [details]="details" [planName]="planName"></app-policy-info>
      <!-- <div class="settings-box mt-2">
        <h4 class="title">Policy Info</h4>
        <p class="subtitle mb-5">We keep your information private, safe, and secure.</p>
        <div class="d-flex justify-content-center mb-4">
          <div class="medicare-card-section mx-auto">
            <span class="label">Medicare Card Number</span>
            <div class="medicare-card">
              <div class="blue py-3">
                <h5 class="text-center text-light">Medicare Health Insurance</h5>
              </div>

              <div class="row py-2">
                <div class="col-12">
                  <span class="med-card-label">Name/Nombre</span>
                  <span class="med-card-value">

                    {{ details.customer_info.medicare_card_name }}</span>
                </div>

                <div class="col-12 pt-4 pb-3">
                  <span class="med-card-label">Medicare Number/Numero de Medicare</span>
                  <span class="med-card-value">

                    {{ details.customer_info.medicare_card_number }}</span>
                </div>

                <div class="col-12">
                  <table class="med-card-table">
                    <tr>
                      <th><span class="med-card-label">Entitled to/Con derecho a</span></th>
                      <th><span class="med-card-label">Coverage starts/Cobertura empleza</span></th>
                    </tr>
                    <tr *ngIf="details.customer_info.has_no_part_a == 0">
                      <td><span class="med-card-value">Hospital (Part A)</span></td>
                      <td><span class="med-card-value">

                          {{ details.customer_info.part_a_effective_date }}</span></td>
                    </tr>
                    <tr *ngIf="details.customer_info.has_no_part_b == 0">
                      <td><span class="med-card-value">Medical (Part B)</span></td>
                      <td><span class="med-card-value">

                          {{ details.customer_info.part_b_effective_date }}</span></td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="red"></div>
            </div>
          </div>
        </div>
        <div class="row px-0 pt-4">
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Medicare Card Name</label>
              <input type="text" class="form-control blurred" autocomplete="off"
                [(ngModel)]="details.customer_info.medicare_card_name" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Medicare Card Number</label>
              <div class="d-flex align-items-center">
                <input type="text" class="form-control blurred mr-2" maxlength="4" autocomplete="off"
                  [(ngModel)]="medCardNumber1" /> -
                <input type="text" class="form-control blurred mx-2" maxlength="3" autocomplete="off"
                  [(ngModel)]="medCardNumber2" /> -
                <input type="text" class="form-control blurred ml-2" maxlength="4" autocomplete="off"
                  [(ngModel)]="medCardNumber3" />
              </div>
            </div>
          </div>
        </div>
        <div class="row px-0 pt-2">
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Part A Effective Date</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.part_a_effective_date"
                (click)="onClickDob(appDOB,'partAEffectiveDate','Select Effective Date for Part A')" />
              <div class="custom-control custom-checkbox mt-2">
                <input type="checkbox" class="custom-control-input" id="customCheck1"
                  (change)="onCheckedDontHavePartA($event)" [checked]="details.customer_info.has_no_part_a">
                <label class="custom-control-label" for="customCheck1">I Do Not Have Part A Yet</label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Part B Effective Date</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.part_b_effective_date"
                (click)="onClickDob(appDOB,'partBEffectiveDate','Select Effective Date for Part B')" />
              <div class="custom-control custom-checkbox mt-2">
                <input type="checkbox" class="custom-control-input" id="customCheck2"
                  (change)="onCheckedDontHavePartB($event)" [checked]="details.customer_info.has_no_part_b">
                <label class="custom-control-label" for="customCheck2">I Do Not Have Part B Yet</label>
              </div>
            </div>
          </div>
        </div>
        <div class="separator dashed my-4"></div>
        <div class="d-flex align-items-center justify-content-end">
          <button class="btn btn-first btn-small" (click)="clickSaveCurrentCustomerInfo()">Save Changes</button>
        </div>
      </div> -->
      <div class="row px-0 px-md-2 mt-0">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Paying Your Plan Premium -->
    <mat-step>
      <ng-template matStepLabel>Premium Info</ng-template>
      <app-paying-your-plan-premium [details]="details" [planName]="planName" [planPremium]="planPremium"></app-paying-your-plan-premium>
      <!-- <div class="settings-box mt-2">
        <h4 class="title">Paying Your Plan Premium</h4>
        <p class="subtitle mb-5">We keep your information private, safe, and secure.</p>
        <div class="row px-0">
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Cellphone Number</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.phone_primary" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">Alternative Phone Number</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.phone_alternate" />
            </div>
          </div>
        </div>
        <div class="row px-0">
          <div class="col-12 px-0">
            <div class="form-group">
              <label for="">Emergency Contact</label>
              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio1" value="1" [(ngModel)]="customRadio" name="customRadio"
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadio1">Use My Household Member as My Emergency
                  Contact</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio2" value="2" [(ngModel)]="customRadio" name="customRadio"
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadio2">No, I Do Not Want To Add An Emergency Contact
                  Right Now</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio3" value="3" [(ngModel)]="customRadio" name="customRadio"
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadio3">Yes, I Will Add A New Emergency Contact
                  Here</label>
              </div>
              <ng-container *ngIf="customRadio">
                <button (click)="customRadio = null" class="btn btn-second btn-xs mt-2">Clear</button>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="customRadio" class="row relationship-box">
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Name</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">Cellphone Number</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Email Address</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 pl-0">
            <div class="form-group">
              <label for="">Relationshop</label>
              <div class="row">
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_1" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_1">Husband</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_2" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_2">Wife</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_3" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_3">Mother</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_4" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_4">Father</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_12" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_12">Brother</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_5" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_5">Sister</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_6" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_6">Significant Other</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_7" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_7">Friend</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_8" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_8">Caretaker</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_9" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_9">Financial Advisor</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_10" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_10">Co-Worker</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_11" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_11">Financial Planner</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator dashed my-4"></div>
        <div class="d-flex align-items-center justify-content-end">
          <button class="btn btn-first btn-small" (click)="clickSaveCurrentCustomerInfo()">Save Changes</button>
        </div>
      </div> -->
      <div class="row px-0 px-md-2 mt-0">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Primary Care Provider Selection (Optional) -->
    <mat-step>
      <ng-template matStepLabel>App Info</ng-template>
      <app-primary-care-provider-selection [details]="details"></app-primary-care-provider-selection>
      <!-- <div class="settings-box mt-2">
        <h4 class="title">My Medicare Details</h4>
        <p class="subtitle mb-5">We keep your information private, safe, and secure.</p>
        <div class="row px-0">
          <div class="col-12 col-sm-8 pl-0">
            <div class="form-group">
              <label for="">Address</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.address">
            </div>
          </div>
          <div class="col-12 col-sm-4 pr-0">
            <div class="form-group">
              <label for="">Apt/Unit #</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">City</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.city" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">State</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.state" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Zip Code</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.zipcode" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">County</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.county" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="custom-control custom-checkbox mt-2">
              <input type="checkbox" (change)="onCheckedMailingAddress($event)"
                [checked]="details.customer_info.is_mailing_address" class="custom-control-input"
                id="mailing_address_as_well">
              <label class="custom-control-label fWeight-600" for="mailing_address_as_well">This is My Mailing Address
                As Well</label>
            </div>
          </div>
        </div>

        <div *ngIf="!mailingAddress" class="separator dashed my-4"></div>
        <div *ngIf="!mailingAddress" class="row px-0">
          <h5 class="col-12 mb-3 fColor-black fWeight-600 px-0">My Mailing Address</h5>
          <div class="col-12 col-sm-8 pl-0">
            <div class="form-group">
              <label for="">Address</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-4 pr-0">
            <div class="form-group">
              <label for="">Apt/Unit #</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">City</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">State</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Zip Code</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">County</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
        </div>


        <div class="separator dashed my-4"></div>
        <div class="d-flex align-items-center justify-content-end">
          <button class="btn btn-first btn-small" (click)="clickSaveCurrentCustomerInfo()">Save Changes</button>
        </div>
      </div> -->
      <div class="row px-0 px-md-2 mt-0">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Attestation of Eligibility for an Enrollment Period -->
    <mat-step>
      <ng-template matStepLabel>App Info</ng-template>
      <app-attestation-of-eligibility-for-an-enrollment-period [details]="details"></app-attestation-of-eligibility-for-an-enrollment-period>
      <!-- <div class="settings-box mt-2">
        <h4 class="title">Attestation of Eligibility for an Enrollment Period</h4>
        <p class="subtitle mb-5">We keep your information private, safe, and secure.</p>
        <div class="row px-0">
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Cellphone Number</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.phone_primary" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">Alternative Phone Number</label>
              <input type="text" class="form-control blurred" [(ngModel)]="details.customer_info.phone_alternate" />
            </div>
          </div>
        </div>
        <div class="row px-0">
          <div class="col-12 px-0">
            <div class="form-group">
              <label for="">Emergency Contact</label>
              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio1" value="1" [(ngModel)]="customRadio" name="customRadio"
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadio1">Use My Household Member as My Emergency
                  Contact</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio2" value="2" [(ngModel)]="customRadio" name="customRadio"
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadio2">No, I Do Not Want To Add An Emergency Contact
                  Right Now</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio3" value="3" [(ngModel)]="customRadio" name="customRadio"
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadio3">Yes, I Will Add A New Emergency Contact
                  Here</label>
              </div>
              <ng-container *ngIf="customRadio">
                <button (click)="customRadio = null" class="btn btn-second btn-xs mt-2">Clear</button>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="customRadio" class="row relationship-box">
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Name</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pr-0">
            <div class="form-group">
              <label for="">Cellphone Number</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-0">
            <div class="form-group">
              <label for="">Email Address</label>
              <input type="text" class="form-control blurred" />
            </div>
          </div>
          <div class="col-12 pl-0">
            <div class="form-group">
              <label for="">Relationshop</label>
              <div class="row">
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_1" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_1">Husband</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_2" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_2">Wife</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_3" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_3">Mother</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_4" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_4">Father</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_12" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_12">Brother</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_5" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_5">Sister</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_6" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_6">Significant Other</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_7" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_7">Friend</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_8" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_8">Caretaker</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_9" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_9">Financial Advisor</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_10" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_10">Co-Worker</label>
                </div>
                <div class="custom-control custom-radio col-12 col-md-6">
                  <input type="radio" id="r_11" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="r_11">Financial Planner</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator dashed my-4"></div>
        <div class="d-flex align-items-center justify-content-end">
          <button class="btn btn-first btn-small" (click)="clickSaveCurrentCustomerInfo()">Save Changes</button>
        </div>
      </div> -->
      <div class="row px-0 px-md-2 mt-0">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Please Read and Sign -->
    <mat-step>
      <ng-template matStepLabel>Please Read and Sign</ng-template>

      <app-please-read-and-sign [details]="details"></app-please-read-and-sign>

      <div class="row px-0 px-md-2 mt-0">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" matStepperNext>
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Finish | Preferences</ng-template>

      <app-finish-preferences [details]="details" [planName]="planName"></app-finish-preferences>

      <div class="row px-0 px-md-2 mt-0">
        <div class="form-group col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third mr-2" matStepperPrevious>
            Back
          </button>
          <button class="btn btn-first" (click)="submitCustomerInfo()">
            Submit Enrollment
          </button>
        </div>
      </div>
    </mat-step>



  </mat-horizontal-stepper>

</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>
