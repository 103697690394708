import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-additional-insights',
  templateUrl: './additional-insights.component.html',
  styleUrls: ['./additional-insights.component.scss']
})

export class AdditionalInsightsComponent implements OnInit {
  @Input() image:any;
  @Input() consumerRating:any;
  @Input() overallRating:any;
  @Input() priceStabilityRating:any;
  @Input() fees: any[]; 
  @Input() discounts: any[];
  @Input() rateHistory: any[];
  @Input() silverSneakers: any;
  
  constructor() {
  }

  ngOnInit() {
  }

  formatDate(date) {
      return new Date(date).getFullYear();
  }

}
