<div class="container-fluid header-banner-wrapper">
	<app-header-b [hasNoMarginTop]="true" [hasNoMarginBottom]="true"></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">
						{{ main_title }}
					</h1>
					<p class="banner-sub-title">{{ sub_title }}</p>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">
				<img src="{{ banner_image }}" class="banner-main-image">
			</div>
		</div>
	</div>
</div>