<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Paying Your Plan Premium</h4>
    <div class="yellow-line"></div>
    <div>
      <div class="py-4">
        <h4 class="text-center fw-semibold mb-0">Keystone 65 Preferred RX (HMO)</h4>
        <h2 class="text-center text-success fw-semibold mb-0">$231.00</h2>
        <p class="text-center fw-semibold">PLAN PREMIUM</p>
      </div>
      <div>
        <h5 class="fw-semibold text-dblue">Preference For Paying Your Plan Premium</h5>
        <ul>
          <li>You Have The Option Of Paying Your Monthly Plan Premium In A Few Different Ways Based On Your Preferences. </li>
          <li>The Default Option, If You Don’t Select A Specific Payment Option, Will Be To Get A Monthly Coupon Book In The Mail (Even If Your Plan Premium Is $0.00). </li>
          <li>The Monthly Coupon Book Is The “Paper Bill” Option That Allows You To Send Any Required Plan Premium In Through The Mail.</li>
          <li>You Also Have The Option To Pay Your Premium By Automatic Deduction From Your Social Security Or Railroad Retirement Board (RRB) Benefit Check Each Month. </li>
          <li>You Can Also Request To Have An Electronic Funds Transfer (EFT) Each Month From A Personal Bank Account.</li>
        </ul>
      </div>
      <div class="py-4">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <h5 class="text-center fw-semibold text-dblue">
              The Effective Date of a Medicare Advantage Plan will always take place on the 1st of the Month. Confirm Your Requested Effective Date Here:
            </h5>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="form-group col-12 col-md-6">
            <label for="partA" class="text-label">Effective Date</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" id="fname" (click)="onClickDob(appDOB,'normal','Select Effective Date')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4">
      <h5 class="fw-semibold text-secondary">
        If you don’t select a payment option, you will get a bill each month (even if your Plan Premium is $0.00).
      </h5>
      <div class="row">
        <div class="col-12 p-2">
          <div class="radio-box shadow-sm">
            <input type="radio" id="paymentOption_1" name="paymentOption" [value]="1" [(ngModel)]="paymentOption">
            <label class="fw-semibold text-dblue mb-0" for="paymentOption_1">Monthly Coupon Book/Paper Bill</label>
          </div>
        </div>
        <div class="col-12 p-2">
          <div class="radio-box shadow-sm">
            <input type="radio" id="paymentOption_2" name="paymentOption" [value]="2" [(ngModel)]="paymentOption">
            <label class="fw-semibold text-dblue mb-0" for="paymentOption_2">Automatic deduction from your monthly Social Security or Railroad Retirement Board (RRB) benefit check.</label>
          </div>
          <div *ngIf="paymentOption == 2" class="row justify-content-center p-4">
            <div class="col-12 col-md-6">
              <div class="d-flex align-items-center mb-2">
                <i class="fas fa-caret-right mr-2"></i>
                <h6 class="fw-semibold text-secondary mb-0">Which of the following type of benefit check do you receive?</h6>
              </div>
              <div class="d-flex justify-content-center">
                <div class="d-flex flex-column">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="benefitCheck_1" name="benefitCheck" class="custom-control-input">
                    <label class="custom-control-label fw-semibold text-dblue" for="benefitCheck_1">Social Security Retirement</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="benefitCheck_2" name="benefitCheck" class="custom-control-input">
                    <label class="custom-control-label fw-semibold text-dblue" for="benefitCheck_2">Social Security Disability</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="benefitCheck_3" name="benefitCheck" class="custom-control-input">
                    <label class="custom-control-label fw-semibold text-dblue" for="benefitCheck_3">Railroad Retirement Board (RRB)</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-2">
          <div class="radio-box shadow-sm">
            <input type="radio" id="paymentOption_3" name="paymentOption" [value]="3" [(ngModel)]="paymentOption">
            <label class="fw-semibold text-dblue mb-0" for="paymentOption_3">Electronic Funds Transfer (Auto Draft From A Bank Account)</label>
          </div>
        </div>
      </div>
      
    </div>
    <div *ngIf="paymentOption == 3" class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="accountName" class="text-label">Name on The Account</label>
        <input type="text" class="form-control blurred" id="accountName"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="accountAddress" class="text-label">Address Associated With Account</label>
        <input type="text" class="form-control blurred" id="accountAddress"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="bankName" class="text-label">Bank Name or Financial Institution Name</label>
        <input type="text" class="form-control blurred" id="bankName"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="fname" class="text-label">What type of account is this?</label>
        <div class="d-flex align-items-center">
          <div class="custom-control custom-radio custom-control-inline mx-2">
            <input type="radio" id="accountType_1" name="accountType" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="accountType_1">Savings</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline mx-2">
            <input type="radio" id="accountType_2" name="accountType" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="accountType_2">Checking</label>
          </div>
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="accountRouting" class="text-label">Routing Number</label>
        <input type="text" class="form-control blurred" id="accountRouting"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="accountBank" class="text-label">Bank Account Number</label>
        <input type="text" class="form-control blurred" id="accountBank"/>
      </div>
    </div>
    <div class="p-3">
      <h5 class="text-center fw-semibold text-dblue">
        Would You Like To Add Your Payments Profile?
      </h5>
      <div class="d-flex justify-content-center">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="addProfile_1" name="addProfile" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-primary" for="addProfile_1">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="addProfile_2" name="addProfile" class="custom-control-input">
          <label class="custom-control-label fw-semibold text-primary" for="addProfile_2">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>
</div>