import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {MedicareAdvantageService} from '../../insurance-marketplace/medicare-advantage/medicare-advantage.service';

@Component({
  selector: 'app-mapd-filter-result-modal',
  templateUrl: './mapd-filter-result-modal.component.html',
  styleUrls: ['./mapd-filter-result-modal.component.scss']
})
export class MapdFilterResultModalComponent implements OnInit {

  @Input() allNetworksCollected;

  isViewDrugCost:boolean = false;
  monthlyPremium: any;
  additionalBenefits: any;
  outOfPocketCost: any;
  otherCategories: any;
  selectorType: any;
  planType: any;
  planTypeFromCheckbox: any = [];

  is_resultFilterDefault_view: boolean = true;
  allInNetworkFromCheckbox: any;
  mainlyInNetworkFromCheckbox: any;
  base_img_loc = '../assets/img/medicare-advantage/';
  is_filterBox_active: boolean = false;

  constructor(private _router: Router,  private _medicareService: MedicareAdvantageService) { }

  ngOnInit() {
  }

  showSpecificCarriers() {
    this.is_resultFilterDefault_view = false;
  }

  hideSpecificCarriers() {
    this.is_resultFilterDefault_view = true;
  }

  isMainlyInNetworkChecked(type) {
    if (this.mainlyInNetworkFromCheckbox !== undefined && this.mainlyInNetworkFromCheckbox !== '' && this.mainlyInNetworkFromCheckbox !== null) {
      return this.mainlyInNetworkFromCheckbox.includes(type);
    } else {
      return false;
    }


  }

  isAllInNetworkChecked(type) {
    if (this.allInNetworkFromCheckbox !== undefined && this.allInNetworkFromCheckbox !== '' && this.allInNetworkFromCheckbox !== null) {
      return this.allInNetworkFromCheckbox.includes(type);
    } else {
      return false;
    }
  }

  isChecked(type) {
    if (this.planTypeFromCheckbox !== undefined && this.planTypeFromCheckbox !== '' && this.planTypeFromCheckbox !== null) {
      return this.planTypeFromCheckbox.includes(type);
    } else {
      return false;
    }
  }

  replaceSpaceWith(string, char = '-') {
    return string.replace(/ /g, char).replace(/,/g, '').replace(/'/g, '').replace(/\./g, '').toLowerCase();
  }

  displayAllCarriers() {
    Swal.close();
    this.allInNetworkFromCheckbox = [];
    this.mainlyInNetworkFromCheckbox = [];
    // this._router.navigate(['./insurance-marketplace/medicare-advantage/results/plan-5' + '/zipcode-' + this.controls.zipcode.value + '/planYear-' + this.planYear + '/monthlyPremium-' + this.finalMonthlyPremium() + '/additionalBenefits-' + this.finalAdditionalBenefits() + '/outOfPocketCost-' + this.finalOutOfPocketCost() + '/otherCategories-' + this.finalOtherCategories() + '/planType-' + this.planTypeFromCheckbox.join('-') + '/selectorType-match-all' + '/all-in-networks-' + this.allInNetworkFromCheckbox.join('-') + '/mainly-in-networks-' + this.mainlyInNetworkFromCheckbox.join('-') + '/drugs-' + this.isViewDrugCost]);
    this.is_filterBox_active = false;
  }

  finalMonthlyPremium() {
    if (this.monthlyPremium !== '' && this.monthlyPremium !== undefined) {
      return this.monthlyPremium.split('-');
    } else if (this._medicareService.getMonthlyPremium() !== null) {
      return this._medicareService.getMonthlyPremium();
    } else {
      return null;
    }
  }

  finalAdditionalBenefits() {
    if (this.additionalBenefits !== '' && this.additionalBenefits !== undefined) {
      return this.additionalBenefits.split('-');
    } else if (this._medicareService.getAdditionalBenefits() !== null) {
      return this._medicareService.getAdditionalBenefits();
    } else {
      return null;
    }
  }

  finalOutOfPocketCost() {
    if (this.outOfPocketCost !== '' && this.outOfPocketCost !== undefined) {
      return this.outOfPocketCost.split('-');
    } else if (this._medicareService.getOutOfPocketCost() !== null) {
      return this._medicareService.getOutOfPocketCost();
    } else {
      return null;
    }
  }

  finalOtherCategories() {
    if (this.otherCategories !== '' && this.otherCategories !== undefined) {
      return this.otherCategories.split('-');
    } else if (this._medicareService.getOtherCategories() !== null) {
      return this._medicareService.getOtherCategories();
    } else {
      return null;
    }
  }

  planTypeChangeCheckbox(filterKey, event) {

    let rc = event.target;

    if (event.currentTarget.checked) {
      $(rc).parents('li').find('i').removeClass('fa-eye-slash').addClass('fa-eye');
      this.planTypeFromCheckbox.push(filterKey);
    } else {
      $(rc).parents('li').find('i').removeClass('fa-eye').addClass('fa-eye-slash');
      this.planTypeFromCheckbox.indexOf(filterKey) !== -1 && this.planTypeFromCheckbox.splice(this.planTypeFromCheckbox.indexOf(filterKey), 1);
    }
    
  }

  filterSubmit() {
    Swal.close();
    //this._router.navigate(['./insurance-marketplace/medicare-advantage/results/plan-5' + '/zipcode-' + this.controls.zipcode.value + '/planYear-' + this.planYear + '/monthlyPremium-' + this.finalMonthlyPremium() + '/additionalBenefits-' + this.finalAdditionalBenefits() + '/outOfPocketCost-' + this.finalOutOfPocketCost() + '/otherCategories-' + this.finalOtherCategories() + '/planType-' + this.planTypeFromCheckbox.join('-') + '/selectorType-match-all' + '/all-in-networks-' + this.allInNetworkFromCheckbox.join('-') + '/mainly-in-networks-' + this.mainlyInNetworkFromCheckbox.join('-') +'/drugs-' + this.isViewDrugCost]);
    //this._router.navigate(['./insurance-marketplace/medicare-advantage/results/plan-' + this.controls.plan.value + '/zipcode-' + this.controls.zipcode.value + '/planYear-' + this.planYear]);
    this.planTypeFromCheckbox = [];
    this.allInNetworkFromCheckbox = [];
    this.mainlyInNetworkFromCheckbox = [];
    this.is_filterBox_active = false;
  }

}
