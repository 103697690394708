<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Medicare Info</h4>
    <div class="yellow-line"></div>
    <div class="d-flex flex-column align-items-center">
      <img class="medicare-card-example" src="./assets/img/medicare-card-example.png" alt="">
      <div class="security-box my-4">
        <i class="fas fa-lock"></i>
        <span>Your Information is protected with us for your safety and privacy with standard (SSL) encryption.</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="email" class="text-label">Medicare Card Number</label>
        <div class="medicare-card-form">
          <input type="text" name="" id="">
          <span>-</span>
          <input type="text" name="" id="">
          <span>-</span>
          <input type="text" name="" id="">
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="phone" class="text-label">Full Medicare Name</label>
        <input type="text" class="form-control blurred" id="phone"/>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="exactName">    
          <label class="custom-control-label fWeight-500 text-dblue uppercase" for="exactName">This Is The Exact Name On Your/The Policy Insured’s Medicare Card</label>    
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="medicaidNumber" class="text-label">Medicaid Number</label>
        <input type="text" class="form-control blurred" id="medicaidNumber"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="ssn" class="text-label">SSN</label>
        <input type="text" class="form-control blurred" id="ssn"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="partA" class="text-label">Medicare Part A Effective Date</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" id="partA" (click)="onClickDob(appDOB,'normal','Medicare Part A Effective Date')"/>
          <i class="fas fa-calendar"></i>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="noPartA">    
          <label class="custom-control-label fWeight-500 text-dblue uppercase" for="noPartA">I Do Not Have Part A Yet</label>    
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="partB" class="text-label">Medicare Part b Effective Date</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" id="partB" (click)="onClickDob(appDOB,'normal','Medicare Part B Effective Date')"/>
          <i class="fas fa-calendar"></i>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="noPartB">    
          <label class="custom-control-label fWeight-500 text-dblue uppercase" for="noPartB">I Do Not Have Part B Yet</label>    
        </div>
      </div>
    </div>
    <div class="dashed-box p-3 mt-4">
      <h6 class="text-label fw-semibold">Which Best Describes Your/The Policy Insured’s Medicare-Enrollment Status?</h6>
      <div class="row pt-2">
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_1" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_1">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_2" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_2">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_3" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_3">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_4" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_4">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>
</div>