import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-insurance-coverage-box',
  templateUrl: './insurance-coverage-box.component.html',
  styleUrls: ['./insurance-coverage-box.component.scss']
})
export class InsuranceCoverageBoxComponent implements OnInit {

  @Input() highlighted;
  @Input() size;
  @Input() isDefault:boolean = true;
  @Input() isMapd: boolean = false;
  @Input() isDental: boolean = false;
  @Input() isMapdPlusDrug: boolean = false;
  @Input() newDental: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
