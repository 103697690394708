import { Component, OnInit } from '@angular/core';
import { Screen7Service } from './screen7.service'; 

@Component({
  selector: 'app-online-application-medigap-version1-screen7',
  templateUrl: './screen7.component.html',
  styleUrls: ['./screen7.component.scss']
})
export class Screen7Component implements OnInit {

  healthData:any = []

  constructor(public medigapService: Screen7Service) { }

  ngOnInit() {
    this.getHealthConditions();
  }

  async getHealthConditions() {
    const data:any = await this.medigapService.getMedigapHealthConditionFileFromServer();
    this.healthData = data

  }

}
