import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-policy-info',
  templateUrl: './policy-info.component.html',
  styleUrls: ['./policy-info.component.scss']
})
export class PolicyInfoComponent implements OnInit {

  @Input() details: any;
  @Input() planName: any;
  
  constructor() { }

  ngOnInit() {
  }

}
