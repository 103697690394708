<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper">
    <div class="row">
      <div class="col-12">
        <h1 class="medium banner-main-title text-center d-none">
          (404) PAGE NOT FOUND 
        </h1>
      </div>
    </div>
  </div>
</div>
<div class="main-content-wrapper bg-primary">
  <div class="container d-flex">
    <div class="text">
      <p class="p-quote">
        "Some beautiful paths can't be discovered without getting lost."
        <span class="fColor-gold">Erol Ozan</span>
      </p>
      <h2 class="fColor-gold text-center text-sm-left">So you must be an adventurer,
        because you’ve reach an invalid page :)
      </h2>

      <h4 class="choose-from-the-ff">Choose from the following</h4>
      <ul>
        <li>
          <a routerLink="/insurance-marketplace">View Plans & Prices</a>
          <a routerLink="/insurance-marketplace"><img src="./assets/img/common/old-woman-half-body-with-basket-v1.png" alt=""></a>
        </li>
        <li>
          <a routerLink="/education">Learn & Enjoy Education</a>
          <a routerLink="/education"><img src="./assets/img/common/seniors-with-computer.png" alt=""></a>
        </li>
        <li>
          <a routerLink="/contact">Contact us</a>
          <a routerLink="/contact"><img src="./assets/img/common/woman-standing-holding-box-with-bag.png" alt=""></a>         
        </li>
        <li>
          <a routerLink="/login">Login or Sign Up</a>
          <a routerLink="/login"><img src="./assets/img/common/contact-us-woman-pink-dress.png" alt=""></a> 
        </li>
        <li>
          <a routerLink="/medicare-services">See All Medicare Services</a>
          <a routerLink="/medicare-services"><img src="./assets/img/common/old-man-and-woman-carying-bags.png" alt=""></a>
        </li>
      </ul>
    </div>
    <div class="img">
      <img src="./assets/img/common/woman-red-holding-drink.png" alt="Page Not Found" class="desktopOnly">
    </div>
  </div>
</div>
<!--END BANNER-->

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>