import {Component, OnInit} from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import {APIService} from '../services/api.service';
import {Router} from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    submitIsClicked: boolean = false;
    isValid: boolean = true;
    success: boolean = false;

    onScriptLoad() {
        console.log('Google reCAPTCHA loaded and is ready for use!')
    }

    onScriptError() {
        console.log('Something went long when loading the Google reCAPTCHA')
    }

    private baseUrl = window.location.origin;

    form: any;

    forgotPasswordForm = new FormGroup({
        email: new FormControl('', [
            Validators.required,
        ]),
        taptapRecaptcha: new FormControl(false)
    });

    get email() {
        return this.forgotPasswordForm.get('email');
    }

    get taptapRecaptcha() {
        return this.forgotPasswordForm.get('taptapRecaptcha');
    }

    currentUser: any;

    constructor(private _api: APIService, private _router: Router, private _location: Location, private titleService:Title) 
    {
        this.titleService.setTitle("Forgot Password");     
    }

    ngOnInit() {
    }

    // saveToCookies():void{
    //     let d = new Date();
    //     d.setTime(d.getTime() + (24*60*60*1000));
    //     let expires = d.toUTCString();
    //     document.cookie = `bp_user_id=${this.currentUser.id};expires=${expires};path=${this.baseUrl};`;
    //     document.cookie = `bp_user_name=${this.currentUser.name};expires=${expires};path=${this.baseUrl};`
    //     document.cookie = `bp_user_picture=${this.currentUser.picture};expires=${expires};path=${this.baseUrl};`;
    //     document.cookie = `bp_user_email=${this.currentUser.email};expires=${expires};path=${this.baseUrl};`;
    //     document.cookie = `bp_user_accounttype=${this.currentUser.accountType};expires=${expires};path=${this.baseUrl};`;
    // }

    proceed() {
        this.submitIsClicked = true;
        this.isValid = true;
        this.success = false;
        if (this.forgotPasswordForm.valid) {
            this._api.callAPIEndpoint('/api/v1/password/email', {
                'email': this.forgotPasswordForm.controls.email.value
            })
                .subscribe(
                    (result: any) => {
                        if (result) {
                            console.log(result);
                            this.success = true;
                            this.forgotPasswordForm.reset();
                            this.submitIsClicked = false;
                        }
                    },
                    (err) => {
                        this.isValid = false;
                        console.log(err);
                    }
                );
        }


        /*console.log(this.controls.email.status);
        console.log(this.controls.password.status);*/

        /*this._api.callAPIEndpoint('/api/v1/login', {
            'email': this.controls.email.value,
            'password': this.controls.password.value,
        })
            .subscribe(
                (data: any) => {
                    if (data) {
                        this._router.navigate(['./']);
                        console.log(data);
                    } else {
                        console.log('no results found');
                    }
                },
                (err) => {
                    console.log('no results found');
                }
            );*/
    }

    goBackToLastPage(): void{
       this._location.back();
    }

}
