<!-- input quote section -->
<section>

  <section class="med-ad-selector-wrapper version-2" *ngIf="isShowCustomPlan">
    <div class="container med-ad-selector-container" [class.onBennyDash]="isViewOnBenny">
        <div class="row justify-content-center med-ad-selector-heading">
            <h2 class="text-center">Your Medicare Advantage Plan Customizer</h2>
            <h5 class="fWeight-500 fColor-base">Select The Criteria That You Would Like To Search Plans.
                <i *ngIf="!viewOnly" class="fa fa-info global-info-icon-first ml-2" (click)="showCriteriaModal(selectCriteria,900)"></i>
            </h5>
        </div>
        
        <div class="row selector-criteria-buttons">
                <div class="selector-criteria-button-inside">
                    <button class="btn btn-first small mr-2" [ngClass]="{'active':mustMatchAllActive}" (click)="mustMatchAll()">Must Match All
                        <i class="fa fa-check"></i>
                    </button>
                    <button class="btn btn-first small mr-2" [ngClass]="{'active':mustMatchAnyActive}" (click)="mustMatchAny()">Must Match Any
                        <i class="fa fa-check"></i>
                    </button>
                    <button class="btn btn-first small" [ngClass]="{'active':prioritizeActive}" (click)="prioritize()">Prioritize
                        <i class="fa fa-check"></i>
                    </button>
                </div>
        </div>
        
        <!-- <div class="row">            
            <div class="col-12 col-md-6 d-none">
                <input type="radio" id="matchAllID" name="selector-type" class="form-control" value="match-all" checked (click)="onClickSelectorType('match-all')"> Must Match all
                <input type="radio" id="matchAnyID" name="selector-type" class="form-control" value="match-any" (click)="onClickSelectorType('match-any')"> Must Match match-any
                <input type="radio" id="prioritizeID" name="selector-type" class="form-control" value="prioritize" (click)="onClickSelectorType('prioritize')"> Prioritize
            </div>
        </div>   -->
        <div class="col-12 mapd-custom-filter-wrapper">
            <!-- carousel view -->
            <div id="filterCarousel" class="carousel slide" data-interval="false">
                <ol class="carousel-indicators">
                  <li data-target="#filterCarousel" data-slide-to="0" class="active"></li>
                  <li data-target="#filterCarousel" data-slide-to="1"></li>
                  <li data-target="#filterCarousel" data-slide-to="2"></li>
                  <li data-target="#filterCarousel" data-slide-to="3"></li>
                  <li data-target="#filterCarousel" data-slide-to="4"></li>
                  <li data-target="#filterCarousel" data-slide-to="5"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-5">
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                    <img src="./assets/img/common/icons/mapd-plan-selector/Document Check.svg">
                                    <h3>Standard Plan Types 
                                        <i class="fa fa-info global-info-icon-first ml-2"
                                            (click)="selectorModalView(monthlyPremiums)"
                                            *ngIf="!viewOnly" ></i></h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" 
                                                (change)="standardPlanTypesChange(1, $event)"
                                                value="1" class="custom-control-input" id="spt-customCheck1">
                                            <label class="custom-control-label" for="spt-customCheck1">All Plan Types</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="standardPlanTypesChange(2, $event)"
                                                value="2" class="custom-control-input" id="spt-customCheck2">
                                            <label class="custom-control-label" for="spt-customCheck2">HMO Plans</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="standardPlanTypesChange(3, $event)"
                                                value="3" class="custom-control-input" id="spt-customCheck3">
                                            <label class="custom-control-label" for="spt-customCheck3">PPO Plans</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="standardPlanTypesChange(4, $event)"
                                                value="4" class="custom-control-input" id="spt-customCheck4">
                                            <label class="custom-control-label" for="spt-customCheck4">HMO-POS Plans</label>
                                        </div>
                                        <div class="checkbox-accordion">
                                            <label class="checkbox-accordion-button" (click)="checkBoxAccordionToggle()" >
                                                <!-- <i [@slideInOut_arrow]="animationState" class="fa fa-chevron-down"></i>   -->
                                                <i class="fa fa-chevron-down"></i>
                                                Other Plan Types
                                            </label>
                                            <div [@slideInOut]="animationState" class="checkbox-accordion-content">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox"
                                                        (change)="standardPlanTypesChange(5, $event)"
                                                        value="5" class="custom-control-input" id="spt-customCheck5">
                                                    <label class="custom-control-label" for="spt-customCheck5">Regional PPO Plans</label>
                                                </div>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox"
                                                        (change)="standardPlanTypesChange(6, $event)"
                                                        value="6" class="custom-control-input" id="spt-customCheck6">
                                                    <label class="custom-control-label" for="spt-customCheck6">Plan Without Drug Coverage</label>
                                                </div>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox"
                                                        (change)="standardPlanTypesChange(7, $event)"
                                                        value="7" class="custom-control-input" id="spt-customCheck7">
                                                    <label class="custom-control-label" for="spt-customCheck9">Dual Eligible (DSNP) Special Needs Plans</label>
                                                </div>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox"
                                                        (change)="standardPlanTypesChange(8, $event)"
                                                        value="8" class="custom-control-input" id="spt-customCheck8">
                                                    <label class="custom-control-label" for="spt-customCheck8">Chronic Conditions (CSNP) Special Needs Plans</label>
                                                </div>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox"
                                                        (change)="standardPlanTypesChange(9, $event)"
                                                        value="9" class="custom-control-input" id="spt-customCheck9">
                                                    <label class="custom-control-label" for="spt-customCheck9">Institutional (ISNP) Special Needs Plans</label>
                                                </div>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox"
                                                        (change)="standardPlanTypesChange(10, $event)"
                                                        value="10" class="custom-control-input" id="spt-customCheck10">
                                                    <label class="custom-control-label" for="spt-customCheck10">Retirement/Senior Community Plans</label>
                                                </div>
                                            </div>                                
                                        </div>                             
                                    </div>                        
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-5">  
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                    <img src="./assets/img/common/icons/mapd-plan-selector/Calendar.svg">
                                    <h3>Monthly Premiums <i
                                            class="fa fa-info global-info-icon-first ml-2"
                                            (click)="selectorModalView(monthlyPremiums)"
                                            *ngIf="!viewOnly" ></i>
                                    </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                                (change)="monthlyPremiumsChange(1, $event)"
                                                value="1" class="custom-control-input" id="mp-customCheck1">
                                        <label class="custom-control-label" for="mp-customCheck1">Monthly Premium of $0</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                                (change)="monthlyPremiumsChange(2, $event)"
                                                value="2" class="custom-control-input" id="mp-customCheck2">
                                        <label class="custom-control-label" for="mp-customCheck2">Monthly Premium of $20 or less</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                                (change)="monthlyPremiumsChange(3, $event)"
                                                value="3" class="custom-control-input" id="mp-customCheck3">
                                        <label class="custom-control-label" for="mp-customCheck3">Monthly Premium of $50 or less</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                                (change)="monthlyPremiumsChange(4, $event)"
                                                value="4" class="custom-control-input" id="mp-customCheck4">
                                        <label class="custom-control-label" for="mp-customCheck4">$0 Premium With Part B Premium Reduction</label>
                                    </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-5">   
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/core-cost.png">
                                        <h3>Core Costs <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="coreCostsChange(1, $event)"
                                                value="1" class="custom-control-input" id="cc-customCheck1">
                                            <label class="custom-control-label" for="cc-customCheck1">Plans with No Deductible</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="coreCostsChange(2, $event)"
                                                value="2" class="custom-control-input" id="cc-customCheck2">
                                            <label class="custom-control-label" for="cc-customCheck2">Total Max Out-of-Pocket Under $3,000</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="coreCostsChange(3, $event)"
                                                value="3" class="custom-control-input" id="cc-customCheck3">
                                            <label class="custom-control-label" for="cc-customCheck3">Total Max Out-of-Pocket Under $4,500</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="coreCostsChange(4, $event)"
                                                value="4" class="custom-control-input" id="cc-customCheck4">
                                            <label class="custom-control-label" for="cc-customCheck4">Total Max Out-of-Pocket Under $6,000</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-5">    
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Ratings.svg">
                                        <h3>Medicare Star Ratings <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="medicareStarRatingsChange(1, $event)"
                                                value="1" class="custom-control-input" id="msr-customCheck1">
                                            <label class="custom-control-label" for="msr-customCheck1">Plans Rated 3 Stars or Above</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="medicareStarRatingsChange(2, $event)"
                                                value="2" class="custom-control-input" id="msr-customCheck2">
                                            <label class="custom-control-label" for="msr-customCheck2">Plans Rated 4 Stars or Above</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="medicareStarRatingsChange(3, $event)"
                                                value="3" class="custom-control-input" id="msr-customCheck3">
                                            <label class="custom-control-label" for="msr-customCheck3">5 Star Rated Plans</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-5">  
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Capsule.svg">
                                        <h3>Drug Coverage Features <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="drugCoverageFeaturesChange(1, $event)"
                                                value="1" class="custom-control-input" id="dcf-customCheck1">
                                            <label class="custom-control-label" for="dcf-customCheck1">Just Plans With No Deductible</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="drugCoverageFeaturesChange(2, $event)"
                                                value="2" class="custom-control-input" id="dcf-customCheck2">
                                            <label class="custom-control-label" for="dcf-customCheck2">Just Plans With Enhanced Benefits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="drugCoverageFeaturesChange(3, $event)"
                                                value="3" class="custom-control-input" id="dcf-customCheck3">
                                            <label class="custom-control-label" for="dcf-customCheck3">Just Plans With Additional Gap Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="drugCoverageFeaturesChange(4, $event)"
                                                value="4" class="custom-control-input" id="dcf-customCheck4">
                                            <label class="custom-control-label" for="dcf-customCheck4">Just Plans With insulin Savings Benefits</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-5">
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/TeethEye.svg">
                                        <h3>Dental and Vision <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="dentalAndVisionChange(1, $event)"
                                                value="1" class="custom-control-input" id="dv-customCheck1">
                                            <label class="custom-control-label" for="dv-customCheck1">Plans with Dental</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="dentalAndVisionChange(2, $event)"
                                                value="2" class="custom-control-input" id="dv-customCheck2">
                                            <label class="custom-control-label" for="dv-customCheck2">Plans with Dental and Vision</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="dentalAndVisionChange(3, $event)"
                                                value="3" class="custom-control-input" id="dv-customCheck3">
                                            <label class="custom-control-label" for="dv-customCheck3">Plans with Comprehensive Dental Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="dentalAndVisionChange(4, $event)"
                                                value="4" class="custom-control-input" id="dv-customCheck4">
                                            <label class="custom-control-label" for="dv-customCheck4">Plans with Hearing Aid Coverage</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                      <div class="row">
                        <div class="col-12 col-md-6 mb-5">
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Doctor Walking.svg">
                                        <h3>Copays Per Visit <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="copaysPerVisitChange(1, $event)"
                                                value="1" class="custom-control-input" id="cpv-customCheck1">
                                            <label class="custom-control-label" for="cpv-customCheck1">Low Copays For Primary Care Doctors Visits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="copaysPerVisitChange(2, $event)"
                                                value="2" class="custom-control-input" id="cpv-customCheck2">
                                            <label class="custom-control-label" for="cpv-customCheck2">Low Copays For Specialist Doctors Visits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="copaysPerVisitChange(3, $event)"
                                                value="3" class="custom-control-input" id="cpv-customCheck3">
                                            <label class="custom-control-label" for="cpv-customCheck3">Low Copays For Physical Therapy Visits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="copaysPerVisitChange(4, $event)"
                                                value="4" class="custom-control-input" id="cpv-customCheck4">
                                            <label class="custom-control-label" for="cpv-customCheck4">Low Copays For Mental Therapy Visits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="copaysPerVisitChange(5, $event)"
                                                value="5" class="custom-control-input" id="cpv-customCheck5">
                                            <label class="custom-control-label" for="cpv-customCheck5">Low Copays For Urgent Care Visits</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-5">
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Patient.svg">
                                        <h3>Major-Medical Services Costs <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="majorMedicalServicesCostsChange(1, $event)"
                                                value="1" class="custom-control-input" id="mmsc-customCheck1">
                                            <label class="custom-control-label" for="mmsc-customCheck1">Plans with Low Outpatient Procedure Costs</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="majorMedicalServicesCostsChange(2, $event)"
                                                value="2" class="custom-control-input" id="mmsc-customCheck2">
                                            <label class="custom-control-label" for="mmsc-customCheck2">Plans with Low Inpatient Hospitalization Costs</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="majorMedicalServicesCostsChange(3, $event)"
                                                value="3" class="custom-control-input" id="mmsc-customCheck3">
                                            <label class="custom-control-label" for="mmsc-customCheck3">Plans with Low Testing & Laboratory Costs</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="majorMedicalServicesCostsChange(4, $event)"
                                                value="4" class="custom-control-input" id="mmsc-customCheck4">
                                            <label class="custom-control-label" for="mmsc-customCheck4">Plans with Low Diagnostic Radiology Costs</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="majorMedicalServicesCostsChange(5, $event)"
                                                value="5" class="custom-control-input" id="mmsc-customCheck5">
                                            <label class="custom-control-label" for="mmsc-customCheck5">Plans with Low Therapeutic Radiology Costs</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-5">   
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Location.svg">
                                        <h3>Additional Coverage Areas <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="additionalCoverageAreasChange(1, $event)"
                                                value="1" class="custom-control-input" id="aca-customCheck1">
                                            <label class="custom-control-label" for="aca-customCheck1">Plans with Strong Acupuncture Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="additionalCoverageAreasChange(2, $event)"
                                                value="2" class="custom-control-input" id="aca-customCheck2">
                                            <label class="custom-control-label" for="aca-customCheck2">Plans with Strong Chiropractic Care Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="additionalCoverageAreasChange(3, $event)"
                                                value="3" class="custom-control-input" id="aca-customCheck3">
                                            <label class="custom-control-label" for="aca-customCheck3">Plans with Strong Foot Care (Podiatry) Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="additionalCoverageAreasChange(4, $event)"
                                                value="4" class="custom-control-input" id="aca-customCheck4">
                                            <label class="custom-control-label" for="aca-customCheck4">Plans with Strong Skilled Nursing Coverage</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-5">    
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Muscle.svg">
                                        <h3>Specific Benefits Strength <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(1, $event)"
                                                value="1" class="custom-control-input" id="sbs-customCheck1">
                                            <label class="custom-control-label" for="sbs-customCheck1">Plans with Strong Medical Equipment Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(2, $event)"
                                                value="2" class="custom-control-input" id="sbs-customCheck2">
                                            <label class="custom-control-label" for="sbs-customCheck2">Plans with Strong Diabetic Supplies Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(3, $event)"
                                                value="3" class="custom-control-input" id="sbs-customCheck3">
                                            <label class="custom-control-label" for="sbs-customCheck3">Plans with Strong Hearing Aid Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(4, $event)"
                                                value="4" class="custom-control-input" id="sbs-customCheck4">
                                            <label class="custom-control-label" for="sbs-customCheck4">Plans with Strong Telehealth Services</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-5"> 
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Patient Documents.svg">
                                        <h3>Includes <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="includesChange(1, $event)"
                                                value="1" class="custom-control-input" id="includes-customCheck1">
                                            <label class="custom-control-label" for="includes-customCheck1">Plans with Transportation Benefits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="includesChange(2, $event)"
                                                value="2" class="custom-control-input" id="includes-customCheck2">
                                            <label class="custom-control-label" for="includes-customCheck2">Plans with Over-the-Counter Items Benefits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="includesChange(3, $event)"
                                                value="3" class="custom-control-input" id="includes-customCheck3">
                                            <label class="custom-control-label" for="includes-customCheck3">Plans with Healthy Foods Benefits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="includesChange(4, $event)"
                                                value="4" class="custom-control-input" id="includes-customCheck4">
                                            <label class="custom-control-label" for="includes-customCheck4">Plans with Free Gym/Fitness Memberships</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="includesChange(5, $event)"
                                                value="5" class="custom-control-input" id="includes-customCheck5">
                                            <label class="custom-control-label" for="includes-customCheck5">Plans with Additional Wellness Benefits</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="includesChange(5, $event)"
                                                value="5" class="custom-control-input" id="includes-customCheck5">
                                            <label class="custom-control-label" for="includes-customCheck5">Plans with Preventive Health Rewards</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-5">   
                            <div class="med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center">
                                <div class="md-ad-selector-selection-inside">
                                    <div class="md-ad-selector-selection-inside-heading">
                                        <img src="./assets/img/common/icons/mapd-plan-selector/Add.svg">
                                        <h3>Additional Features <i
                                                class="fa fa-info global-info-icon-first ml-2"
                                                (click)="selectorModalView(monthlyPremiums)"
                                                *ngIf="!viewOnly" ></i>
                                        </h3>
                                    </div>
                                    <div class="md-ad-selector-selection-checkbox-wrapper">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(1, $event)"
                                                value="1" class="custom-control-input" id="af-customCheck1">
                                            <label class="custom-control-label" for="af-customCheck1">Plans with Home Support Services</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(2, $event)"
                                                value="2" class="custom-control-input" id="af-customCheck2">
                                            <label class="custom-control-label" for="af-customCheck2">Plans with Coverage of Wigs For Chemotherapy</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(3, $event)"
                                                value="3" class="custom-control-input" id="af-customCheck3">
                                            <label class="custom-control-label" for="af-customCheck3">Plans with Supervised Exercise Therapy (SET) Coverage</label>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                (change)="specificBenefitsStrengthChange(4, $event)"
                                                value="4" class="custom-control-input" id="af-customCheck4">
                                            <label class="custom-control-label" for="af-customCheck4">Plans with Smoking Cessation Programs</label>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <a class="d-none carousel-control-prev" href="#filterCarousel" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="d-none carousel-control-next" href="#filterCarousel" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
            </div>
            <!-- end carousel view -->
            <!-- <div class="row" [class.d-none]="!viewDefault">
            </div> 
            <div class="row" [class.d-none]="viewDefault">
            </div> -->
            <button class="nav-filter-button" id="nav-prev-btn" (click)="onClickPrevBtn()"><i class="fa fa-angle-left" aria-hidden="true"></i> Prev Page</button>
            <button class="nav-filter-button" id="nav-next-btn" (click)="onClickNextBtn()"><i class="fa fa-angle-right" aria-hidden="true"></i> Next Page</button>            
            <!-- <button class="nav-filter-button" id="nav-prev-btn" (click)="viewDefault=true"><i class="fa fa-angle-left" aria-hidden="true"></i> Prev Page</button>
            <button class="nav-filter-button" id="nav-next-btn" (click)="viewDefault=false"><i class="fa fa-angle-right" aria-hidden="true"></i> Next Page</button>             -->
        </div>
        <!-- <div class="mapd-custom-filter-indicator">
            <span class="indicator item-1 mr-2" [class.active]="viewDefault"></span>
            <span class="indicator item-2" [class.active]="!viewDefault"></span>
        </div> -->
        <div class="row mb-5">
            <div class="col-12 text-center d-flex justify-content-center">   
                            
                <ng-container *ngIf="isViewOnBenny">
                  <button (click)="backClick_benny()" class="btn btn-third mr-2">benny Back</button> 
                  <button *ngIf="!viewOnly" (click)="selectorProceed_benny()" class="btn btn-first">benny Proceed</button>
                </ng-container>
                <ng-container *ngIf="!isViewOnBenny">
                  <button (click)="backClick_marketplace()" class="btn btn-third mr-2">Back</button> 
                  <button *ngIf="!viewOnly" (click)="selectorProceed_marketplace()" class="btn btn-first">Proceed</button>
                </ng-container>
            </div>
        </div>
    </div>
  </section>

</section>
<!-- end input quote section -->

<div #preferenceRatingModal class="d-none">
  <div class="preference-rating-wrapper">
      <h5>Your Preference Rating</h5>
      <div class="preference-rating">
          <div class="preference-rating-inside">
              <span class="top-label">Lowest <br> Importance</span>
              <input type="radio" id="pr1" name="pr-level" class="form-control" value="1">
              <span class="bottom-label">1</span>
          </div>
          <div class="preference-rating-inside">
              <span class="top-label visibility-hidden">hidden</span>
              <input type="radio" id="pr2" name="pr-level" class="form-control" value="2">
              <span class="bottom-label">2</span> 
          </div>
          <div class="preference-rating-inside">
              <span class="top-label">Average <br>Importance</span> 
              <input type="radio" id="pr3" name="pr-level" class="form-control" value="3">
              <span class="bottom-label">3</span>
          </div>
          <div class="preference-rating-inside">
              <span class="top-label visibility-hidden">hidden</span>
              <input type="radio" id="pr4" name="pr-level" class="form-control" value="4">
              <span class="bottom-label">4</span>
          </div>
          <div class="preference-rating-inside">
              <span class="top-label"> Most <br>Importance</span>
              <input type="radio" id="pr5" name="pr-level" class="form-control" value="5" checked>
              <span class="bottom-label">5</span>
          </div>
      </div>
  </div>
</div>

<!-- Modals -->
<div class="d-none">
  <div #selectCriteria>
      <div class="custom-selector-modal-content criteria-modal">
              <h3 class="text-center" style="font-size:35px;">TapTap Medicare Explains:</h3>
              <!-- <mat-divider class="mt-4 mb-5"></mat-divider> -->
              <h5 class="text-center">You have 3 methods to customize your selection of plans</h5>
              <h6 class="uppercase mt-5">Must Match All:</h6> 
              <!-- <img src="./assets/img/common/girl-counselor-pointing-pen.png" alt="" style="float:right;max-width: 300px;margin-left:1.5em;background: #fff;"> -->
              <ul>
                  <li>This method allows you to make sure you are only seeing plans that include your “Must Haves”. With this method you can select as many features from each section as you’d like, however, the plans shown will need to meet ALL of the plan criteria you’ve selected. Plans that just meet one or some of your criteria, but not all, will not be shown.</li>
                  <ul>
                      <li>Additionally, some criteria are incompatible with others. For example, you cannot select the criteria “HMO Plans with $0 Premium” and “PPO with Premium of $20 of Less” since all plans are either an HMO or a PPO, but cannot be both.”</li>
                      <li>Since you are customizing your plan with each selection, the more criteria that you select will result in less plans being displayed in your results, since the only plans shown must meet ALL of the criteria you’ve selected.</li>
                  </ul>
              </ul>
              
              <!-- <mat-divider class="mt-5 mb-5"></mat-divider> -->
              
              <h6 class="mt-5 uppercase">Must Match Any:</h6> 
              <ul>
                  <li>This method allows you to see a broader selection of plans that include at least some of your desired plan preferences. With this method you can identify all the plan features from each section that  you’d like and TapTap will display all the plans that meet ANY of the criteria you’ve selected.</li>
              </ul>
              
              <!-- <mat-divider class="mt-5 mb-5"></mat-divider> -->

              <h6 class="mt-5 uppercase">Prioritize:</h6>
              <ul>
                  <li>This method allows you to “Rank” or “Prioritize” the plan features in the order of their important to you. With this method you to see a broader selection of plans that will be displayed according to how you have ranked your preferences.</li>
                  <li>Once select a feature by clicking the check box next to any individual feature, then you will see a little popup box that allows you to identify your "Preference Rating" for that particular feature</li>
                  <li>5 is the Highest (Most Important) Preference Rating, and 1 is the Lowest (Least Important) Preference Rating</li>
                  <li>Of course, you may always give us a call at 1(800)215-5108 to help you with your plan customization.</li>
              </ul>
              
              <!-- <mat-divider class="mt-5 mb-5"></mat-divider> -->
              
          
      </div>
  </div>
  <div #monthlyPremiums>
      <ul>
          <li class="mt-2">This section gives you to customize your plans by their monthly premium amount.</li>
          <li class="mt-2">This monthly premium is what is charged to you when you are enrolled in the plan.</li>
          <li class="mt-2">Note: If you receive “Extra Help” from the government then you may pay less than the stated amount.</li>
      </ul>
  </div>
  <div #outOfPocketCost>
      <ul>
          <li>This section gives you to customize your plans by their out-of-pocket costs, for specific healthcare services, as well as for the plan as a whole.</li>
          <li>You may want to use this section if you have a specific procedure or course of treatments coming up that you would like to minimize your costs for.</li>
          <li>Plans shown for low out-of-pocket for a specific type of service is based relative to the out-of-pocket for other available plans.</li>
      </ul>
  </div>
  <div #extraBenefits>
      <ul>
          <li>This section gives you to customize your plans by some of the popular additional coverage benefits that Medicare Advantage plans can offer.</li>
          <li>“Plans with Comprehensive Dental Coverage” provide dental coverage beyond just the routine and preventive services that are more common.</li>
      </ul>
  </div>
  <div #additionalFeatures>
      <ul>
          <li>This section gives you the ability to further customize the plan options that would be most suitable for you. </li>
          <li>For example, “Part B Premium Reduction” will show plans that have $0 premium as well as reduce the Medicare premium you are charged for Part B”.</li>
      </ul>
  </div>
</div>

<div class="d-none" #formHeadingHTML>
  Great! <br><br> Last step and your custom plan selection will be right up! 
</div>
