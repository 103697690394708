import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as $ from 'jquery';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
//import { SignupComponent } from './auth/signup/signup.component';
//import { SigninComponent } from './auth/signin/signin.component';
//import { AuthService } from './auth/auth.service';
import { AuthService } from './services/auth.service';
/*import { UserComponent } from './user/user.component';
import { AuthGuardService } from './auth/auth-guard.service';*/
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { FirestoreModule } from '@angular/fire/firestore';
import { ServerService } from './server.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConsumerReviewService } from './services/consumer-review.service';
import { environment } from '../environments/environment';
import { ArticlesComponent } from './articles/articles.component';
/*import { AdminComponent } from './admin/admin.component';*/
/*import { SupportComponent } from './support/support.component';*/
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateTicketComponent } from './create-ticket/create-ticket.component';
import { APIService } from './services/api.service';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
 
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderService } from './services/header.service';
import { FooterService } from './services/footer.service';
import { RegistrationComponent } from './registration/registration.component';
import { MedicareServicesComponent } from './medicare-services/medicare-services.component';
import { IAmNewToMedicareComponent } from './i-am-new-to-medicare/i-am-new-to-medicare.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { TestimonialsPageComponent } from './testimonials/testimonials.component';
import { PartnershipOpportunitiesComponent } from './partnership-opportunities/partnership-opportunities.component';
import { BecomeAContributorComponent } from './become-a-contributor/become-a-contributor.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { LogoutComponent } from './logout/logout.component';
// import { PartBEnrollmentCenterComponent } from './part-b-enrollment-center/part-b-enrollment-center.component';
import { MedicareOrganizerComponent } from './medicare-organizer/medicare-organizer.component';
import { TaptapMedicareMedigapPricesComponent } from './taptap-medicare-medigap-prices/taptap-medicare-medigap-prices.component';
import { TaptapMedicareMedigapPricesTwoComponent } from './taptap-medicare-medigap-prices-two/taptap-medicare-medigap-prices-two.component';
import { TaptapNewToMedicareComponent } from './taptap-new-to-medicare/taptap-new-to-medicare.component';
import { TaptapNewToMedicareTurningSixtyFiveComponent } from './taptap-new-to-medicare-turning-sixty-five/taptap-new-to-medicare-turning-sixty-five.component';
import { TaptapNewToMedicarePartBComponent } from './taptap-new-to-medicare-part-b/taptap-new-to-medicare-part-b.component';
import { TaptapMedicarePartBEnrollmentComponent } from './taptap-medicare-part-b-enrollment/taptap-medicare-part-b-enrollment.component';
import { MarketplaceWidgetFormComponent } from './common/marketplace-widget-form/marketplace-widget-form.component';
import { BennydashWidgetFormComponent } from './common/bennydash-widget-form/bennydash-widget-form.component';

/*************************************
 **********LANDING PAGES**************
 *************************************/
import { StLouisMissouriComponent } from './city-based/st-louis-missouri/st-louis-missouri.component';
import { BostonComponent } from './city-based/boston/boston.component';
import { HartfordConnecticutComponent } from './city-based/hartford-connecticut/hartford-connecticut.component';
import { ManhattanComponent } from './city-based/manhattan/manhattan.component';
import { MiamiComponent } from './city-based/miami/miami.component';
import { PhiladelphiaComponent } from './city-based/philadelphia/philadelphia.component';
import { PhoenixArizonaComponent } from './city-based/phoenix-arizona/phoenix-arizona.component';
import { TexasComponent } from './city-based/texas/texas.component';
import { WilmingtonDelawareComponent } from './city-based/wilmington-delaware/wilmington-delaware.component';
import { DenverComponent } from './city-based/denver/denver.component';
import { MedigapLandingPageComponent } from './medigap-landing-page/medigap-landing-page.component';
import { MedigapLandingPageTwoComponent } from './medigap-landing-page-two/medigap-landing-page-two.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

/*********************************************
 **********MAPD CUSTOMIZER PAGES**************
 ********************************************/

import { MapdPlanCustomizerDualEligibleComponent } from './mapd-customizer/mapd-plan-customizer-dual-eligible/mapd-plan-customizer-dual-eligible.component';
import { MapdPlanCustomizerHealthFitnessComponent } from './mapd-customizer/mapd-plan-customizer-health-fitness/mapd-plan-customizer-health-fitness.component';
import { MapdPlanCustomizerVeteransComponent } from './mapd-customizer/mapd-plan-customizer-veterans/mapd-plan-customizer-veterans.component';
import { ComparePlansComponent } from './compare-plans/compare-plans.component';

import { WebinarComponent } from './webinar/webinar.component';

import { WebinarV2Component } from './webinar-v2/webinar-v2.component';

import { AepComponent } from './aep/aep.component';

import { AepModule } from './aep/aep.module';

import { ContentAComponent } from './aep/contents/content-a/content-a.component';

import { ContentBComponent } from './aep/contents/content-b/content-b.component';

import { WhatAreSomeOtherMedicareEnrollmentPeriodsIShouldBeAwareOfComponent } from './aep/contents//what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of/what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of.component';

import { MedigapPlanGV2Component } from './medigap-plan-g-v2/medigap-plan-g-v2.component';
import { MedigapPlanGV1Component } from './medigap-plan-g-v1/medigap-plan-g-v1.component';
import { MedigapPlanNV2Component } from './medigap-plan-n-v2/medigap-plan-n-v2.component';
import { MedigapPlanNV1Component } from './medigap-plan-n-v1/medigap-plan-n-v1.component';

import { RetirementLivingComponent } from './retirement-living/retirement-living.component';
import { MedicareAdvantageBaseComponent } from './medicare-advantage-base/medicare-advantage-base.component';
import { PartDDrugCoverageComponent } from './part-d-drug-coverage/part-d-drug-coverage.component';
import { IndividualDentalInsuranceComponent } from './individual-dental-insurance/individual-dental-insurance.component';

import { HighlightPipe } from './highlight.pipe';

//import { CartComponent } from './cart/cart.component';

 import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/database';
import {HttpErrorInterceptor} from './http-error.interceptor';
import { DemoComponent } from './demo/demo.component';
import { EmailVerificatonComponent } from './email-verificaton/email-verificaton.component';
firebase.initializeApp(environment.firebaseConfig);

const APP_ID = 'taptapapp';

/* TODO Move this key to a private function within auth module, route authentication state to browser side versus server*/

/*********************************************************************************************************************/
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    /*UserComponent,
    SignupComponent,
    SigninComponent,
    AdminComponent,
  SupportComponent,*/
    LoginComponent,
    DashboardComponent,
    ArticlesComponent,
    CreateTicketComponent,
    ContactComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    RegistrationComponent,
    MedicareServicesComponent,
    IAmNewToMedicareComponent,
    TermsOfServicesComponent,
    TestimonialsPageComponent,
    PartnershipOpportunitiesComponent,
    BecomeAContributorComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    LogoutComponent,
    // PartBEnrollmentCenterComponent,
    MedicareOrganizerComponent,
    TaptapMedicareMedigapPricesComponent,
    TaptapMedicareMedigapPricesTwoComponent,
    TaptapNewToMedicareComponent,
    TaptapNewToMedicareTurningSixtyFiveComponent,
    TaptapNewToMedicarePartBComponent,
    TaptapMedicarePartBEnrollmentComponent,

    //city-based
    StLouisMissouriComponent,
    BostonComponent,
    HartfordConnecticutComponent,
    ManhattanComponent,
    MiamiComponent,
    PhiladelphiaComponent,
    PhoenixArizonaComponent,
    TexasComponent,
    WilmingtonDelawareComponent,
    MedigapLandingPageComponent,
    MedigapLandingPageTwoComponent,
    DenverComponent,
    
    PageNotFoundComponent,
    PrivacyPolicyComponent,

    //MAPD CUSTOMIZER
    MapdPlanCustomizerDualEligibleComponent,
    MapdPlanCustomizerHealthFitnessComponent,
    MapdPlanCustomizerVeteransComponent,

    ComparePlansComponent,
    WebinarComponent,
    WebinarV2Component,
    MarketplaceWidgetFormComponent,
    BennydashWidgetFormComponent,
    AepComponent,
    ContentAComponent,
    ContentBComponent,
    WhatAreSomeOtherMedicareEnrollmentPeriodsIShouldBeAwareOfComponent,
    MedigapPlanGV2Component,
    MedigapPlanGV1Component,
    MedigapPlanNV2Component,
    MedigapPlanNV1Component,
    RetirementLivingComponent,
    PartDDrugCoverageComponent,
    IndividualDentalInsuranceComponent,
    MedicareAdvantageBaseComponent,
    DemoComponent,
    EmailVerificatonComponent,
    HighlightPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: APP_ID }),
    BrowserModule,
    HttpClientModule,
    ClarityModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    FirestoreModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    SharedModule,
    AepModule

  ],
  providers: [AuthService, ServerService, APIService, HeaderService, FooterService, ConsumerReviewService],
  bootstrap: [AppComponent]
})
export class AppModule { }
