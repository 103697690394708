import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {AuthService} from '../../../../../../services/auth.service';
import {Location} from '@angular/common';
import confetti from 'canvas-confetti';

@Component({
  selector: 'app-online-application-mapd-version2-screen11',
  templateUrl: './screen11.component.html',
  styleUrls: ['./screen11.component.scss']
})
export class Screen11Component implements OnInit {

  isLoggedIn:boolean = false;

  paymentOption:any = 0;

  dateFieldType: string;
  dateFieldHeading: string;

  benefit1: boolean = false;
  benefit2: boolean = false;
  benefit3: boolean = false;
  rule1: boolean = false;
  rule2: boolean = false;
  rule3: boolean = false;
  understand1: boolean = false;
  understand2: boolean = false;
  understand3: boolean = false;
  understand4: boolean = false;
  understand5: boolean = false;

  agreeToAll: boolean = false;

  constructor(public authLogin: AuthService, private _location: Location) { }

  ngOnInit() {
    if(this.authLogin.isAuthenticated()){ // check if user is logged in
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;
    }
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

  checkAgree(event) {
    if(event == true) {
      this.benefit1 = true;
      this.benefit2 = true;
      this.benefit3 = true;
      this.rule1 = true;
      this.rule2 = true;
      this.rule3 = true;
      this.understand1 = true;
      this.understand2 = true;
      this.understand3 = true;
      this.understand4 = true;
      this.understand5 = true;
    } else {
      this.benefit1 = false;
      this.benefit2 = false;
      this.benefit3 = false;
      this.rule1 = false;
      this.rule2 = false;
      this.rule3 = false;
      this.understand1 = false;
      this.understand2 = false;
      this.understand3 = false;
      this.understand4 = false;
      this.understand5 = false;
    }
  }

  deployConfetti() {
    confetti({
      particleCount: 200,
      spread: 120,
      origin: { y: 0.5 },
      zIndex: 1000
    });
  }

  openSuccessModal(content) {
    Swal.fire({
      html: content,
      showCloseButton: false,
      showConfirmButton: false,
      buttonsStyling: false,
      allowOutsideClick: false,
      width: 800,
      padding:'0px',
    })
    this.deployConfetti();
  }

  backClicked() {
    Swal.close();
    this._location.back();
  }
  

}
