<!--START BANNER-->
<div class="container-fluid header-banner-wrapper">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-12">
        <div class="banner-text-wrapper text-center">
          <h2 class="banner-main-title">
            Get Clarity About Medicare<br>            
          </h2>
          <h4 class="fWeight-500 fColor-white mb-5">Know How To Ensure a Great Experience</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="banner-pointer-wrapper">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>
<!--END BANNER-->

<section>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="fWeight-600">I'm On Medicare... Now What?</h4>
        <p>The Medicare experience is not the easiest (to say the least!).</p>
        <p>This webinar is all about giving you clarity on 
           your Medicare options. 
        </p>
        <p>What is the difference
           between Medicare Advantage plans and
          Medicare Supplement plans? How much do 
          they cost? We'll cover all the questions you may have
          and much more - at absolutely no
          cost. 
        </p>
        <p>We are here to provide you with clear explanations
          related to Medicare and your options.
        </p>
        <p> If you have specific questions you'd like 
          answered during the webinar, sign up now and 
          let us know!
        </p>
      </div>
      <div class="col-12 col-md-6 d-flex flex-column justify-content-start align-items-center">
        <button class="btn btn-first btn-small" id="signUpToWebinar" (click)="signUpToWebinar()">Sign Up To The Webinar</button>
        <img src="./assets/img/common/old-man-doubt-blurred.png" alt="" style="max-width: 450px;margin-top: 2em;">
      </div>
    </div>
  </div>
</section>

<section id="section_webinar_save_your_seat" style="padding-top: 2rem;">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-9 pr-4">
        <h4 id="saveYourSeatHeading">Save Your Seat!</h4>
        <!-- Calendly inline widget begin -->
        <!--<div class="calendly-inline-widget" data-url="https://calendly.com/taptap/webinar-i-ve-got-medicare-now-what" style="min-width:320px;height:430px;"></div>-->
        <!-- Calendly inline widget end -->

        <!-- Calendly inline widget begin -->
        <div class="calendly-inline-widget" data-url="https://calendly.com/taptap/webinar-i-ve-got-medicare-now-what?primary_color=156aff" style="min-width:320px;height:630px;"></div>
        <!-- <div class="calendly-inline-widget" data-url="https://calendly.com/taptap" style="min-width:320px;height:600px;"></div> -->
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
        <!-- Calendly inline widget end -->

      </div>
      <div class="col-12 col-md-3 text-right desktopOnly">
        <img src="./assets/img/common/webinar-save-your-seat.png" alt="Save your Seat">
        <p class="nowOver10Year">Now Over 10 year Serving<br>Medicare Beneficiaries</p>
      </div>
    </div>
  </div>
</section>

<section id="section_webinar-about-your-teacher">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4">
        <h4 class="fWeight-600">About Your Teacher:</h4>
      </div>
      <div class="col-12 col-md-8">&nbsp;</div>
      <div class="col-12 col-md-4 text-center">
        <img src="./assets/img/common/jon-img.PNG" alt="" style="max-width: fit-content;">
        <h5 class="fWeight-600 fColor-base">Jonathan L. E. Salem</h5>
        <span style="font-size:16px;">Founder of TapTap Medicare</span>
      </div>
      <div class="col-12 col-md-8 pl-4">
        
        <p>For something as important as your healthcare experience in retirement, things should not be so hard and confusing.</p>
        
        <p>This webinar from TapTap Medicare takes the confusion and uncertainty out of Medicare.</p>

        <p>Our approach to teaching about the key concepts to know as a Medicare
          consumer makes the entire Medicare system - as well as the healthcare system as a whole - clear, easily understandable, and logical.
        </p>
        <p>Although it seems like "chaos" to many, there is an order and logic to the Medicare system, and we help you see and truly understand this.
        </p>
      </div>
    </div>
  </div>
</section>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>
