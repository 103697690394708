<!-- DISPLAY RESULT SECTION -->
<div class="result-version-5 container mb-3" [class.bg-white]="containerBgWhite" *ngFor="let result of results; let i = index">
  <table class="container result-section">
      <tr class="tr-result-heading" [class.bgBlue]="isBlueBgHeading">
          <th class="heading-company">Company</th>
          <th class="heading-company-info">Plan Info</th>
          <th class="heading-ratings">Ratings</th>
          <th class="heading-pricing">
              <div class="costSelectorDropdown" id="costSelectorDropdown_{{ i }}"  *ngIf="result.payment_method_available_and_pricing.length"> <!-- [ngClass]="{ 'active': costToggleState }"-->
                  <ng-container *ngFor="let paymentMethods of result.payment_method_available_and_pricing">
                    <button (click)="on_costSelectorDropdown(paymentMethods.calculated_rate, paymentMethods.multiplier_display_name, i)">{{ paymentMethods.multiplier_display_name }}</button>
                  </ng-container>
              </div>
              Costs
              <i class="fa fa-chevron-down" (click)="onClickCostToggleState(i)" *ngIf="result.payment_method_available_and_pricing.length"></i>       <!--(click)="costToggleState = !costToggleState"-->                     
          </th>
          <th class="heading-action d-none">Actions</th>
      </tr>
      <tr [ngClass]="{'planTypeStartLetterTr': result?.plan_start }">
          <td class="text-center planTypeStartLetter" *ngIf="result?.plan_start">
              <h4 *ngIf="result?.plan_start == 'MA_CORE'">Core Plan</h4>
              <h4 *ngIf="result?.plan_start == 'MA_SUPP1'">Plan 1</h4>
              <h4 *ngIf="result?.plan_start == 'MA_SUPP1A'">Plan 1A</h4>
              <h4 *ngIf="result?.plan_start == 'All-MA'">All Plans</h4>
          </td>
          <td class="result-logo-wrapper">
              <span class="no-img-plan-name d-none">{{ result.plan.plan_name }} <br> <span class="fColor-black fSize-15 fWeight-600">Medigap Plan {{ planLetter }}</span></span><!--{{ result.carrier_brand.carrier_name }} ( {{ result.plan.plan_name }} )-->
              <img class="result-logo" id="logo_{{result?.naic}}_{{i}}"
                   onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
                   src="{{ result?.carrier_brand?.logo }}"
                   alt="{{ result?.carrier_brand?.carrier_name }}"><!-- (error)="companyLogoCheck($event)" -->
              <!-- <h6 class="results-plan-name text-center">{{ result?.carrier_brand?.carrier_name }} <br> 
                <span class="fColor-black fSize-15 fWeight-600">Medigap Plan {{ planLetter }}</span>
              </h6> -->
              <h6 class="results-plan-name text-center">{{ result?.plan?.plan_name }} <br> 
                <span *ngIf="planLetter != 'All Plans' && planLetter != 'Core Plan'" class="fColor-black fSize-15 fWeight-600">{{ (planLetter.includes('Plan ') ? planLetter : 'Plan ' + planLetter ) }}</span>
                <span *ngIf="planLetter == 'Core Plan'" class="fColor-black fSize-15 fWeight-600">Core Plan</span>
                <span *ngIf="planLetter == 'All Plans'" class="fColor-black fSize-15 fWeight-600">All Plans</span>
              </h6>
          </td>
          <td class="result-company-info-wrapper">
            <!-- <app-star-rating-v2-medigap [result]="result"></app-star-rating-v2-medigap> -->
            <app-view-all-ratings
                image="{{ result?.carrier_brand?.logo }}"                
                [hasHeading]="false"
                [forResult]="true"
                [result]="result"
                [providerScore]="result.section14.data?.price_record?.provider_score"
                [customerServiceRating]="result.section14.data?.price_record?.customer_service_score"
                [overallScore]="result.section14.data?.price_record?.overall_score"
                [consumerScore]="result.section14.data?.price_record?.consumer_score">
            </app-view-all-ratings>
          </td>

          <td class="result-ratings-wrapper align-items-center justify-content-center">
              <span class="fWeight-600 color-primary uppercase">Financial Rating</span>
              <span class="fColor-black rating-content capitalize d-block onMobileTabletResultValue" [innerHTML]="(result?.section10?.data?.company_profile) ? result?.section10?.data?.company_profile?.am_best_financial_rating : 'N/A'"></span>
          </td>

          <td class="result-pricing-wrapper align-items-center justify-content-start border-0">
              <ng-container *ngIf="result.payment_method_available_and_pricing.length">
                <div class="pricing-content onMobileTabletResultValue">
                  <span class="fColor-blue uppercase fWeight-600 d-block text-center">Premium:</span>                    
                  <span id="cost_rate_selected_id_{{i}}" class="price-value d-block fColor-black text-right text-md-center lHeight-1em d-none"></span>
                  <span id="cost_rate_default_id_{{i}}" class="price-value d-block fColor-black text-right text-md-center lHeight-1em d-block" >
                    ${{ result_cost_label_array[i]?.cost_price | number : '1.2-2' }}
                    <!-- <ng-container *ngFor="let pm of result.payment_method_available_and_pricing">
                      <ng-container *ngIf="pm.multiplier_display_name.includes('Monthly EFT Auto Draft From Bank Account') && costPreValue; else costMonthlyLastDefaultRate">
                        {{ pm.calculated_rate.toLocaleString("en-US") }}
                      </ng-container>    
                      <ng-template #costMonthlyLastDefaultRate>   
                        <ng-container *ngIf="pm.multiplier_display_name.includes('Monthly')">
                          {{ pm.calculated_rate.toLocaleString("en-US") }}
                        </ng-container>    
                      </ng-template> 
                    </ng-container>                                         -->
                  </span>

                  <!-- <ng-container *ngIf="costRateType"> -->
                    <span id="cost_label_selected_id_{{i}}" class="cost-type-label d-none">
                        <!-- <ng-container *ngIf="costRateType.includes('Semi Annual'); else quarterlyCost">
                          <span class="cost-type-label-default">Semi Annual</span>
                          <span class="cost-type-label-hovered">{{ costRateType }}</span>
                        </ng-container>
                        
                        <ng-template #quarterlyCost>
                          <ng-container *ngIf="costRateType.includes('Quarterly'); else annualCost">
                            <span class="cost-type-label-default">Quarterly</span>
                            <span class="cost-type-label-hovered">{{ costRateType }}</span>
                          </ng-container>
                        </ng-template>

                        <ng-template #annualCost>
                          <ng-container *ngIf="costRateType.includes('Annual '); else monthlyCost">
                            <span class="cost-type-label-default">Annual</span>
                            <span class="cost-type-label-hovered">{{ costRateType }}</span>
                          </ng-container>
                        </ng-template>
                        
                        <ng-template #monthlyCost>
                          <ng-container *ngIf="costRateType.includes('Monthly'); else defaultCost">
                            <span class="cost-type-label-default">Monthly</span>
                            <span class="cost-type-label-hovered">{{ costRateType }}</span>
                          </ng-container>
                        </ng-template>

                        <ng-template #defaultCost>
                          {{ costRateType }}
                        </ng-template>                     -->
                    </span>

                  <!-- </ng-container> -->

                  <!-- <ng-container *ngIf="!costRateType"> -->
                    <!-- ${{ (costRate) ? costRate : result.payment_method_available_and_pricing[0].calculated_rate.toLocaleString("en-US") }}    -->
                    <span id="cost_label_default_id_{{i}}" class="cost-type-label d-block">
                      <!-- {{ result.payment_method_available_and_pricing[0].multiplier_display_name }} -->
                      <!-- {{ result_cost_label_array[i]?.cost_name }} -->
                      <ng-container *ngIf="result_cost_label_array[i]?.cost_name.includes('Monthly')">
                        Monthly
                      </ng-container>
                      <ng-container *ngIf="result_cost_label_array[i]?.cost_name.includes('Annual')">
                        Annual
                      </ng-container>
                      <ng-container *ngIf="result_cost_label_array[i]?.cost_name.includes('Quarterly')">
                        Quarterly
                      </ng-container>
                      <!-- <ng-container *ngFor="let pm of result.payment_method_available_and_pricing">
                        <ng-container *ngIf="pm.multiplier_display_name.includes('Monthly EFT Auto Draft From Bank Account'); else costMonthlyLastDefaultDisplayName">
                          {{ pm.multiplier_display_name }}
                        </ng-container>    
                        <ng-template #costMonthlyLastDefaultDisplayName>   
                          <ng-container *ngIf="pm.multiplier_display_name.includes('Monthly')">
                            {{ pm.multiplier_display_name }}
                          </ng-container>    
                        </ng-template>       
                      </ng-container>     -->

                    </span>
                  <!-- </ng-container> -->
                  
                  <!-- <span class="cost-type-label">{{ (costRateType) ? costRateType : result.payment_method_available_and_pricing[0].multiplier_display_name }}</span> -->
                </div>

                <!-- <div class="pricing-content onMobileTabletResultValue" *ngIf="!result.household && !istaptap">
                    <span class="fColor-blue uppercase fWeight-600 d-block text-center">Premium:</span>
                    <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em">
                      ${{ (costRate) ? costRate : result.payment_method_available_and_pricing[0].calculated_rate.toLocaleString("en-US") }} 
                      <span class="cost-type-label">{{ (costRateType) ? costRateType : result.payment_method_available_and_pricing[0].multiplier_display_name }}</span>
                    </span>                   
                </div> -->
              </ng-container>

              <ng-container *ngIf="!result.payment_method_available_and_pricing.length">
                <h5 class="fColor-black text-center">N/A</h5>
              </ng-container>
          </td>

          <td class="result-action-wrapper d-none">
              <div class="action-content result-button-td result-button-wrapper">
                  <button class="btn btn-first mb-2 btn-xs applyActionBtn">
                      Apply Online
                  </button>
                  <button class="btn btn-first btn-xs applyActionBtn_noImage mb-2 d-none" >
                      Apply Online
                  </button>
                  <button class="btn btn-first mb-2 btn-xs applyActionBtn">
                      Apply on Phone
                  </button>
                  <button class="btn btn-first btn-xs applyActionBtn_noImage mb-2 d-none" >
                      Apply on Phone
                  </button>
                  <button class="btn btn-first mt-2 saveThisPlanButton">Save This Plan</button>
              </div>

              <div class="result-action-compare-add">
                  <!-- <div class="custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" class="custom-control-input" id="saveThisPlan_{{i}}">
                  <label class="custom-control-label" for="saveThisPlan_{{i}}">Save Selected Plan</label>
                  </div> -->
                  <div class="compared-plans-btn-wrapper custom-control custom-checkbox mr-sm-2">
                  <!-- <input type="checkbox" class="custom-control-input" id="addToCompare_{{i}}" (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.id)"> -->
                  <label class="custom-control-label" for="addToCompare_{{i}}">Add To Compare</label>
                  <!-- <a target="_blank" href="/insurance-marketplace/medigap/results/compare-plans?zipcode={{controls.zipcode.value}}&age={{controls.age.value}}&tobacco={{controls.tobacco.value}}&household={{controls.household.value}}&plan={{controls.plan.value}}&gender={{this.controls.gender.value}}&compare={{part_d_id}}" class="viewComparePlansBtn btn btn-third-plain btn-small d-none" [class.d-block]="logComparedKeys.includes(result.id)">View Compare Plans</a> -->
                  </div>
              </div>
          </td>
      </tr>
      <tr>
        <div class="plan-document-buttons d-flex flex-column flex-md-row pt-3">                      
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button1_{{result.id}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_planDocsResources(result.id)">
              <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
              More Company Details
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow1_{{result.id}}">
              <div class="arrow"></div>
            </div>
          </div>
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button2_{{result.id}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_doctorDrugAnalysis(result.id)">
              <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
              More Plan Details
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow2_{{result.id}}">
              <div class="arrow"></div>
            </div>
          </div>
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button3_{{result.id}}" class="compare-button btn btn-third btn-xs position-relative" (click)="onclick_planActionButtons(result.id)">
              Save, Compare, or Enroll
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow3_{{result.id}}">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
      </tr>
  </table>

  <!-- MORE BENEFITS & DOCUMENTS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="docs_resources_id_{{result.id}}">
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="p-4 bg-light border h-100">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/company-details-no-icon.png" alt="">
        </div>
        <h4 class="title-header text-center text-dgray">Company Details</h4>
        <div class="w-full d-flex flex-column justify-content-around">          
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="companyProfile(result)">Company Profile</button>
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second">Additional Company Insights</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="p-4 bg-light yellow-border h-100">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/retiree-dashboard/8-old-seniors-cropped.png" alt="">
        </div>
        <h4 class="title-header text-center text-dgray">Consumer Community</h4>
        <div class="w-full d-flex flex-column justify-content-around">          
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first" (click)="consumerReview(result)">Consumer Reviews About This Company</button>     
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first">Discuss This Company With Others</button>
        </div>
      </div>
    </div>
  </div>

  <!-- DOCTORS & DRUGS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="doctors_drugs_id_{{result.id}}">
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="bg-light border p-4 h-100">
        <div class="d-flex justify-content-center">
            <img src="./assets/img/plan-benefits.png" alt="">
        </div>
        <h4 class="title-header text-center">Plan Benefits</h4>
        <div class="w-full d-flex flex-column justify-content-around">
          <!-- <a class="d-none link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" target="_blank" href="#">TapTap Interactive Plan Guide</a> -->
          <a class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" href="/insurance-marketplace/medigap/single-plan/{{ result.id }}/{{ result.pricing.plan_pricing_id }}/{{ controls.plan.value }}/{{ controls.zipcode.value }}/{{ controls.age.value }}/{{ controls.gender.value }}/{{ controls.tobacco.value }}/{{ controls.household.value }}">TapTap Interactive Plan Page</a>
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="additionalInsights(result)">Additional Plan Insights</button>          
        </div>        
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="bg-light yellow-border p-4 h-100">
        <div class="d-flex justify-content-center">
            <img src="./assets/img/retiree-dashboard/8-old-seniors-cropped.png" alt="">
        </div>
        <h4 class="title-header text-center">Consumer Community</h4>
        <div class="w-full d-flex flex-column justify-content-around">          
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first">Consumer Reviews About This Plan</button>   
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first" (click)="onClick_DiscussThisPlanWithOthers(discussThisPlanWithOthersModal)">Discuss This Plan With Others</button>
        </div>        
      </div>
    </div>
  </div>

  <!-- PLAN ACTION BUTTONS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="plan_actions_id_{{result.id}}">
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100 enrollment-button">
        <!-- <button class="saveplan-button w-100 my-2 btn btn-small btn-second">Save This Plan</button>
        <div class="addcompare-button w-100 my-2 d-flex align-items-center justify-content-center">
          <input type="checkbox" class="compare-input" id="addToCompare_{{result.id}}" (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.id)">
          <label class="compare-label mb-0" for="addToCompare_{{result.id}}">Add To Compare</label>
        </div>
        <a target="_blank" id="view_compare_btn_{{i}}" href="/insurance-marketplace/medigap/results/compare-plans?zipcode={{controls.zipcode.value}}&age={{controls.age.value}}&tobacco={{controls.tobacco.value}}&household={{controls.household.value}}&plan={{controls.plan.value}}&gender={{this.controls.gender.value}}&compare={{part_d_id}}" class="viewComparePlansBtn btn btn-third-plain btn-small d-none" [class.d-block]="logComparedKeys.includes(result.id)">View Compare Plans</a> -->
        
        <button class="save d-flex align-items-center px-3 py-2 my-2" (click)="saveThisPlan()">
          <img class="small-icon" src="./assets/img/common/save.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>SAVE THIS PLAN</span>
          </div>
        </button>
        <app-share-plan [sharePlanId]=result.id></app-share-plan>
        <button class="add d-flex align-items-center my-2 add-to-compare-button">
          <div class="checkbox-wrapper d-flex align-items-center justify-content-center ml-4">
            <input type="checkbox" class="compare-input" id="addToCompare_{{result.id}}"
            (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.id)">
          </div>
          <div class="d-flex justify-content-center flex-grow-1 add-to-compare-label-wrapper">
            <label for="addToCompare_{{result.id}}">ADD TO COMPARE</label>
          </div>
        </button>
        <a target="_blank"
        href="/insurance-marketplace/medigap-v2/compare-plans?zipcode={{controls.zipcode.value}}&age={{controls.age.value}}&tobacco={{controls.tobacco.value}}&household={{controls.household.value}}&plan={{controls.plan.value}}&gender={{this.controls.gender.value}}&compare={{part_d_id}}"
        class="viewComparePlansBtn btn btn-third-plain btn-sm d-none ff mt-2"
        id="view_compare_btn_{{i}}"
        [class.d-block]="logComparedKeys.includes(result.id)">View Compare Plans</a>
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100 enrollment-button">
        <!-- <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnline(applyOnlineModal,result,i)">Enroll Online</button>
        <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnline(result, i, base_img_loc + result.naic + '.png')">Enroll Online</button>
        <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnThePhone(result, base_img_loc + result.naic + '.png')">Enroll On The Phone</button> -->
        <button class="online d-flex align-items-center px-3 py-2 my-2" (click)="applyOnline(result, i, base_img_loc + result.naic + '.png')">
          <img class="small-icon" src="./assets/img/common/online.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>ENROLL ONLINE</span>
          </div>
        </button>
        <button class="phone d-flex align-items-center px-3 py-2 my-2" (click)="applyOnThePhone(result, base_img_loc + result.naic + '.png')">
          <img class="small-icon" src="./assets/img/common/phone.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>ENROLL ON THE PHONE</span>
          </div>
        </button>
        <button class="cart d-flex align-items-center p-0" id="ci_cart_btn__{{result.id}}" [class.active]="logCartKeys.includes(result.id)">
          <label class="cart-btn-label" for="ci_add_to_cart__{{result.id}}">
            <img class="small-icon" src="./assets/img/common/cart.svg" alt="">
            <span class="flex-grow-1">{{ addToCartLabel }}</span>
          </label>
        </button>
        <input type="checkbox" class="d-none" id="ci_add_to_cart__{{ result.id }}" (change)="onClick_AddToCart(result,$event)" [checked]="logCartKeys.includes(result.id)">
        <a target="_blank"
            href="/cart"
            class="viewCartBtn btn btn-third-plain btn-sm d-none ff mt-2"
            id="view_cart_btn_{{result.id}}"
            [class.d-block]="logCartKeys.includes(result.id)">View Cart</a>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #discussThisPlanWithOthersModal>

    <h4 class="uppercase text-center">Discuss This Plan</h4>

    <a class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second-fill mt-3px" target="_blank" routerLink="/insurance-marketplace/medigap/single-plan/{{ selectedMapId }}/{{ selectedPricingId }}/{{ controls.plan.value }}/{{ controls.zipcode.value }}/{{ controls.age.value }}/{{ controls.gender.value }}/{{ controls.tobacco.value }}/{{ controls.household.value }}">TapTap Interactive Plan Guide</a>
  
  </div>
</div>