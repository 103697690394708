import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-insurance-logo',
  templateUrl: './insurance-logo.component.html',
  styleUrls: ['./insurance-logo.component.scss']
})
export class InsuranceLogoComponent implements OnInit {

  @Input() isNoPaddingTop: boolean = false;
  @Input() showLogo = true;

  constructor() { }

  ngOnInit() {
  }

}
