<h4 class="header bg-primary">Important Information</h4>
<ng-container *ngIf="view1">
  <div class="bordered-wrapper">

    <h5 class="uppercase fColor-black fWeight-600 mb-4 text-center">Health Plan Disclaimers</h5>

    <h6 class="fWeight-600">Important Notices:</h6>

    <ul>
      <li>We will be collecting information from you on the following pages to complete your health insurance application. </li>
      <li>These pages do not constitute your application of record for this health insurance plan. </li>
      <li>After you complete these pages your application of record will be created and made available to you by mail or download.</li>
      <li>The answers to the questions you will be asked on subsequent screens will be electronically transferred to the application for insurance.</li>
    </ul>

    <p>If you need help, please call 1(800) 215-5108 Monday-Friday, 9:00AM EST-6:30PM EST for Consumer Service & Support Team Members and Licensed Insurance Counselors who can assist with helping you to find information on Medicare insurance plans.</p>

    <h5 class="uppercase fColor-black fWeight-600 mb-4 text-center mt-5">Medicare Required Disclaimers</h5>
    
    <h6 class="fWeight-600">Notices and Disclaimers for Medicare Advantage Coverage:</h6>

    <ul>
      <li>Do Not Cancel Any Health Insurance Coverage You Currently Have Or Decline COBRA Benefits Until You Receive An Approval Letter and Insurance Policy (Also Known As An Insurance Contract Or Certificate) From The Insurance Company You Selected.</li>
      <li>Make Sure You Understand and Agree With The Terms Of The Insurance Policy.</li>
      <li>Plans are insured or covered by a Medicare Advantage organization with a Medicare contract and/or a Medicare-approved Part D sponsor.  Enrollment in the plan depends on the plan’s contract renewal with Medicare.</li>
      <li>We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area.  Please contact Medicare.gov or 1-800-MEDICARE to get information on all of your options.</li>
    </ul>

    <div class="d-flex justify-content-center">
      <div class="custom-control custom-checkbox mr-4">
          <input type="checkbox" class="custom-control-input" id="iUnderstand" (change)="onChange_IUnderstand($event)">    
          <label class="custom-control-label uppercase fWeight-500 color-primary" for="iUnderstand">I Understand</label>    
      </div>
    </div>

  </div>

  <div class="d-flex align-items-center justify-content-center mt-3 mb-4">
    <button class="btn btn-third" [class.mr-2]="isIUnderstand" (click)="showDefaultView()">Back</button>    
    <button class="btn btn-first" (click)="showSecondView()" *ngIf="isIUnderstand">Next</button>
  </div>

</ng-container>

<ng-container *ngIf="view2">
  <div class="bordered-wrapper">
    <h5 class="uppercase fColor-black fWeight-600 mb-4 text-center">By Completing This Enrollment Application, <br> I Agree To The Following</h5>
    <h6 class="fWeight-600">Important Notices:</h6>
    <ul>
      <li>
        I must keep both Hospital (Part A) and Medical (Part B) to stay in this Medicare Advantage Plan, [Medicare Advantage Plan Name].
      </li>
      <li>
        By joining this Medicare Advantage Plan or Medicare Prescription Drug Plan, I acknowledge that this Medicare Advantage Plan, [Medicare Advantage Plan Name] will share my information with Medicare, who may use it to track my enrollment, to make payments, and for other purposes allowed by Federal law that authorize the collection of this information (see Privacy Act Statement).
      </li>
      <li>
        Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.
      </li>
      <li>
        The information on this enrollment form is correct to the best of my knowledge. I understand that if I intentionally provide false information on this form, I will be disenrolled from the plan.
      </li>
      <li>
        I understand that people with Medicare are generally not covered under Medicare while out of the country, except for limited coverage near the U.S. border.
      </li>
      <li>
        I understand that when this Medicare Advantage Plan, [Medicare Advantage Plan Name] coverage begins, I must get all of my medical and prescription drug benefits from this Medicare Advantage Plan, [Medicare Advantage Plan Name]. Benefits and services provided by this Medicare Advantage Plan, [Medicare Advantage Plan Name] and contained in this Medicare Advantage Plan’s ([Medicare Advantage Plan Name]) “Evidence of Coverage'' document (also known as a member contract or subscriber agreement) will be covered. Neither Medicare nor this Medicare Advantage Plan, [Medicare Advantage Plan Name] will pay for benefits or services that are not covered.
      </li>
      <li>
        I understand that my signature (or the signature of the person legally authorized to act on my behalf) on this application means that I have read and understand the contents of this application. If signed by an authorized representative (as described above), this signature certifies that:
        <ul>
          <li>This person is authorized under State law to complete this enrollment and</li>
          <li>Documentation of this authority is available upon request by Medicare.</li>
        </ul>
      </li>
    </ul>
    <div class="row mt-5 mb-4">
      <div class="col-12 col-sm-2 mx-auto">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="iUnderstand_2" (change)="onChange_IUnderstandTwo($event)">    
          <label class="custom-control-label fWeight-500 color-primary uppercase" for="iUnderstand_2">I Agree</label>    
        </div>
      </div>
    </div> 
    <div class="row mb-4" *ngIf="isIUnderstand2">
        <div class="form-group col-12 col-sm-6 p-1">
          <label for="firstName" class="fWeight-500">First Name:</label>
          <input type="text" class="form-control blurred" id="firstName" [(ngModel)]="firstName"/>
        </div>
        <div class="form-group col-12 col-sm-6 p-1">
          <label for="middleName" class="fWeight-500">Middle Name:</label>
          <input type="text" class="form-control blurred" id="middleName" [(ngModel)]="middleName"/>
        </div>
        <div class="form-group col-12 col-sm-6 p-1">
          <label for="lastName" class="fWeight-500">Last Name:</label>
          <input type="text" class="form-control blurred" id="lastName" [(ngModel)]="lastName"/>
        </div>
        <div class="form-group col-12 col-sm-6 p-1">
          <label for="selectDate" class="fWeight-500">Select Date</label>
          <input type="text" class="form-control blurred" id="selectDate" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')" [(ngModel)]="importantInformationSelectedDate"/>
        </div>       
    </div>
  </div>

  <ng-container *ngIf="isIUnderstand2">
    <div class="text-center mb-2">
      <h5 class="fw-semibold text-dblue mx-4 mb-2">Are You Completing This Application As An Authorized Representative?</h5>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="isAuthorizedRepresentative_1" name="isAuthorizedRepresentative" class="custom-control-input" value="yes" [(ngModel)]="isAuthorizedRepresentative" (change)="onChange_isAuthorizedRepresentative($event.target.value)">
        <label class="custom-control-label fw-semibold text-primary" for="isAuthorizedRepresentative_1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="isAuthorizedRepresentative_2" name="isAuthorizedRepresentative" class="custom-control-input" value="no" [(ngModel)]="isAuthorizedRepresentative" (change)="onChange_isAuthorizedRepresentative($event.target.value)" checked="true">
        <label class="custom-control-label fw-semibold text-primary" for="isAuthorizedRepresentative_2">No</label>
      </div>
    </div>

    <div *ngIf="isShowAllMailSentToAuthorizedRepresentative" class="text-center">
      <app-authorized-representative-question></app-authorized-representative-question>
      <!-- <p>Would You Like All Mail To Be Sent To The Authorized Representative?</p>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="isAllMailSentToAuthorizedRepresentative_1" name="isAllMailSentToAuthorizedRepresentative" class="custom-control-input" value="yes" [(ngModel)]="isAllMailSentToAuthorizedRepresentative" (change)="onChange_isAllMailSentToAuthorizedRepresentative($event.target.value)">
        <label class="custom-control-label" for="isAllMailSentToAuthorizedRepresentative_1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="isAllMailSentToAuthorizedRepresentative_2" name="isAllMailSentToAuthorizedRepresentative" class="custom-control-input" value="no" [(ngModel)]="isAllMailSentToAuthorizedRepresentative" (change)="onChange_isAllMailSentToAuthorizedRepresentative($event.target.value)" checked="true">
        <label class="custom-control-label" for="isAllMailSentToAuthorizedRepresentative_2">No</label>
      </div> -->
    </div>

  </ng-container>

  <div class="d-flex align-items-center justify-content-center my-4">
    <button class="btn btn-third" [class.mr-2]="isIUnderstand2" (click)="showFirstView()">Back</button>
    <button class="btn btn-first" (click)="showAcknowledgement()" *ngIf="isIUnderstand2">Next</button>
  </div>
</ng-container>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>