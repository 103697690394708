import { APIService } from './../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-provider-network-status',
  templateUrl: './provider-network-status.component.html',
  styleUrls: ['./provider-network-status.component.scss']
})
export class ProviderNetworkStatusComponent implements OnInit {
  @Input() providers;
  @Input() facilities;

  displayedProviders: any;

  selection: string = 'doctors';

  contractId: string;
  planId: string;
  segmentId: string;
  planYear: string;
  result: any = null;
  base_img_loc = '../assets/img/medicare-advantage/';

  sumUpLocations:string = '';

  currentPage = 1;
  itemsPerPage = 5;

  constructor(private route: ActivatedRoute, private _api: APIService) {
    this.route.params.subscribe(params => {
      this.contractId = params['contractId'];
      this.planId = params['planId'];
      this.segmentId = params['segmentId'];
      this.planYear = params['planYear'];
    });
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  ngOnInit() {
    const plan = JSON.parse(localStorage.getItem('selectedPlanForProviderSearch'));
    
    if (plan && 
        plan.contract_id === this.contractId && 
        plan.plan_id === this.planId && 
        +plan.plan_year === +this.planYear) 
    {
      this.result = plan;
    } else {
      this.getSelectedPlan();
    }

    console.log('xxxxxt',this.facilities);
    
  }

  getSelectedPlan() {
    this._api.callAPIEndpointAuthenticatedGetRequest(`/api/v1/medicare-advantage/plan-details-db/${this.contractId}/${this.planId}/${this.planYear}`, {

    }).subscribe((d) => {
      if (d.code === 1) {
        if (typeof d.result.json_details === 'string') {
          d.result.json_details = JSON.parse(d.result.json_details);
        }

        this.result = d.result;
        localStorage.setItem('selectedPlanForProviderSearch', JSON.stringify(this.result));
      }
    });
  }

  replaceSpaceWith(string, char = '-') {
    return string.replace(/ /g, char).replace(/,/g, '').replace(/\./g, '').toLowerCase();
  }

  changeSelection(selection) {
    this.selection = selection;
  }

  openModal(content) {
    Swal.fire({
      html: content,
      padding: '0px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 900
    });
  }

  openLargeModal(content) {
    // this.getLocations();
    Swal.fire({
      html: content,
      padding: '0px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 1200
    });
  }

  closeModal() {
    Swal.close();
  }

  getLocations():void {
    let temp = []
    const data = this.providers.locations;
    for (let i = 0; i < data.length; i++) {
      temp.push(`${data[i].street ? data[i].street : data[i].address_1} ${data[i].suburb_city}`)
    }
    this.sumUpLocations = temp.toString()
  }



}
