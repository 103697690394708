<div class="container-fluid header-banner-wrapper home-banner-wrapper force-height-auto">
  <app-header-b></app-header-b>

  <div class="container home-banner banner-wrapper unset-absolute-position">
    <div class="d-flex">
      <div class="home-banner-left">
        <div class="home-banner-text-wrapper">
          <h2 class="banner-main-title-subtext text-center text-sm-left">A Clear & Organized</h2>
          <h1 class="banner-main-title">
            Medicare Experience
          </h1>
          <mat-list class="mt-4">
            <mat-list-item>
              <mat-icon mat-list-icon color="primary">check</mat-icon>
              <p class="text-capitalize">Easy Consumer-Friendly View of Plans</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="primary">check</mat-icon>
              <p class="text-capitalize">Clear Explanations & Recommendations</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="primary">check</mat-icon>
              <p class="text-capitalize">Guidance & Support All Year</p>
            </mat-list-item>
          </mat-list>
          <div
            class="home-banner-btn d-flex justify-content-around justify-content-md-start flex-column flex-md-row align-items-center">
            <a routerLink="/benny-dash" class="btn btn-third text-uppercase">Go To My Benny HQ</a>

            <button class="btn btn-first ml-2" routerLink="/insurance-marketplace">View Plans</button>
          </div>          
        </div>
      </div>
      <div class="home-banner-right banner-image-wrapper">
        <img src="assets/img/common/medigap/plan-n-banner-character.png">
      </div>
    </div>
  </div>
</div>

<app-insurance-logo [showLogo]="showLogo"></app-insurance-logo>

<section class="with-this-short-section">
  <div class="with-this-short-section-bg-bottom"></div>
  <div class="container">
    <div class="with-this-short-section-box col-md-6 mt-5">
      <div class="term col-md-12">
        <h4 class="with-this-short-section-box-h4">Permanent Life <br> Insurance</h4>
        <img src="assets/img/life-insurance/help-me-decide/with-this-short-permanent.png">
        <h5 class="with-this-short-title">
          Permanent Life insurance refers to all
          life insurance that does not have a
          temporary or limited length of the
          coverage
        </h5>
        <div class="with-this-short-desc">
          <p>
            Unlike Term Life Insurance, which only lasts for a
            predetermined amount of years.
          </p>
          <p>
            you live, no matter how long you live - so that
            regardless of how old the insured lives to be, the
            policy will payout the tax-free death beneﬁt to the
            beneﬁciaries when the insured passes away.
          </p>
          <p>
            This type of policy will normally have a cash value and
            a surrender value.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="advantages-and-disadvantages-section mt-5">
  <div class="container">
    <div class="advantages-desc col-md-6 mx-auto">
      <div class="advantage-and-disadvantage-bullets col-md-12">
        <div class="bullets-right">
          <div class="type-box">
            <h4>Permanent Life <br> Insurance</h4>
            <div class="banner-pointer-wrapper">
              <div class="banner-pointer-background"></div>
            </div>
          </div>
          <ul>
            <li>
              <img src="assets/img/life-insurance/help-me-decide/bullet-check.png">
              <p>
                Policies generally guarantee. Level
                premium payments for life.
              </p>
            </li>
            <li>
              <img src="assets/img/life-insurance/help-me-decide/bullet-check.png">
              <p>
                Policies allow you to accumulate cash
                value overtime.
              </p>
            </li>
            <li>
              <img src="assets/img/life-insurance/help-me-decide/bullet-check.png">
              <p>
                Cash value insurance can provide
                life-long protection. Because it doesn’t
                need to be renewed , you no longer have
                to worry that declining health will affect
                your insurability (unless you decide to
                buy more insurance).
              </p>
            </li>
            <li>
              <img src="assets/img/life-insurance/help-me-decide/bullet-check.png">
              <p>
                You may borrow against your cash value
                funds at any time, for any reason,
                although doing so may reduce the death
                beneﬁt available to your survivors if the
                loan is not repaid before your death.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container-fluid main-content-wrapper home-wrapper">
  <div class="row home-content">
    <div class="col-12 row home-content-first-box-wrapper">
      <div class="col-md-12 home-content-row-div" id="government-standardized">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-md-5 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
              <img src="assets/img/common/pink-lady-cut.png" class="mt-4" />
            </div>
            <div class="row col-12 col-md-7 d-flex justify-content-center justify-content-md-end text-center text-md-left government-standardized-box-content">
              <div class="col-12 details-p row justify-content-center">
                <p>
                  These policies provide the same <span>basic features regardless </span>of the insurance carrier you purchase it from:
                </p>
                <ul>
                  <li><span>Your Death Benefit is Fully In Force from Day 1</span></li>
                  <li><span>Death Benefit Stays the Same For As Long As You Live</span> (It is not a “Term” policy)</li>
                  <li><span>Premium is Fixed and Locked in For Life</span></li>
                  <li><span>Internal Cash Value That Grows Over Time</span></li>
                  <li><span>Surrender Value That Grows of Over Time</span></li>
                </ul>
              </div>
              <div class="col-12 row justify-content-center mt-20">
                <button class="col-6 btn btn-first-plain padding-20 width-auto"
                  (click)="speakToCounselorEvent(speakToCounselorModal)">
                  Speak With a Concierge NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</section>

<div class="d-none {{ dblocking }}" #speakToCounselorModal>
	<app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>
<app-testimonials></app-testimonials>
<div class="footer-top-spacer mt-5"></div>
<app-ready-to-talk></app-ready-to-talk>
