<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Finish | Preference</h4>
    <div class="yellow-line"></div>
    <div>
      <div class="py-4">
        <h5 class="text-center fw-semibold">My Preferred Language Preferences</h5>
        <div class="row justify-content-center">
          <div class="col-12 col-md-6">
            <div class="form-group mb-1 p-2">
              <select type="text" class="form-control blurred" id="language">
                <option value="English" selected>English</option>
                <option value="Spanish">Spanish</option>
                <option value="Mexican">Mexican</option>
              </select>
            </div>
          </div>
        </div>
        <p class="text-center"><em>Please select your preferred language</em></p>
      </div>
      <div class="py-3">
        <h5 class="fw-semibold">My Preferred Delivery Method Preferences</h5>
        <div class="row">
          <div class="col-12 p-2">
            <div class="radio-box shadow-sm">
              <input type="radio" id="deliveryMethod_1" name="deliveryMethod">
              <label class="fw-semibold text-dblue mb-0" for="deliveryMethod_1">Braille</label>
            </div>
          </div>
          <div class="col-12 p-2">
            <div class="radio-box shadow-sm">
              <input type="radio" id="deliveryMethod_2" name="deliveryMethod">
              <label class="fw-semibold text-dblue mb-0" for="deliveryMethod_2">Audio tape</label>
            </div>
          </div>
          <div class="col-12 p-2">
            <div class="radio-box shadow-sm">
              <input type="radio" id="deliveryMethod_3" name="deliveryMethod">
              <label class="fw-semibold text-dblue mb-0" for="deliveryMethod_3">Large print</label>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3">
        <h5 class="fw-semibold">My Preferred Application Status Communication Preferences</h5>
        <div class="row">
          <div class="col-12 p-2">
            <div class="radio-box shadow-sm">
              <input type="radio" id="communcation_1" name="communcation">
              <label class="fw-semibold text-dblue mb-0" for="communcation_1">Mail</label>
            </div>
          </div>
          <div class="col-12 p-2">
            <div class="radio-box shadow-sm">
              <input type="radio" id="communcation_2" name="communcation">
              <label class="fw-semibold text-dblue mb-0" for="communcation_2">Email</label>
            </div>
          </div>
          <div class="col-12 p-2">
            <div class="radio-box shadow-sm">
              <input type="radio" id="communcation_3" name="communcation">
              <label class="fw-semibold text-dblue mb-0" for="communcation_3">Fax</label>
            </div>
          </div>
          <div class="col-12 p-2">
            <div class="radio-box shadow-sm">
              <input type="radio" id="communcation_4" name="communcation">
              <label class="fw-semibold text-dblue mb-0" for="communcation_4">Sms</label>
            </div>
          </div>
        </div>
      </div>
      <p class="px-5 text-center">Please contact this Medicare Advantage Plan Insurance Carrier, <strong>[Medicare Advantage Carrier Brand Name]</strong> if you need information in an accessible format or language other than what is listed above.</p>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading"></app-dob>
  </div>
</div>