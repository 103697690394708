<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue py-3">Replacement Notice: Notice to Applicant</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <div class="dashed-box border shadow p-4">
        <h4 class="text-center fw-semibold">Please Read and Sign.</h4>
        <div>
          <p class="text-label">By completing this form, I agree to the following:</p>
          <div class="p-2">
            <div class="">
              <h6 class="d-flex justify-content-center text-center">Notice to Applicant Regarding Replacement of Medicare Supplement Insurance or Medicare Advantage</h6>
            </div>
          </div>
          <div class="py-2">
            <p>
              Save this notice! It may be important to you in the future.
            </p>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="dynamicEmail">    
              <label class="custom-control-label fw-medium text-dblue" for="dynamicEmail">Click Here To Email This To Me</label>    
            </div>
            <p>
              According to information you have furnished, you intend to terminate existing Medicare Supplement or Medicare Advantage insurance and replace it with a policy/certificate to be issued by <b>[Insurance Carrier NAIC Name]</b>.
            </p>
            <p>
              Your new policy/certificate will provide 30 days within which you may decide - without cost - whether you desire to keep the policy/certificate.
            </p>
            <p>
              You should review this new coverage carefully. Compare it with all accident and sickness coverage you now have.
            </p>
            <p>
              If after due consideration, you find that purchase of this Medicare Supplement coverage is a wise decision, you should terminate your present Medicare Supplement or Medicare Advantage coverage.
            </p>
            <p>
              You should evaluate the need for other accident and sickness coverage you have that may duplicate this policy.
            </p>
          </div>
        </div>
        <!-- <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" [(ngModel)]="importantStatementsAgreeDate" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div> -->
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="iAgree15">    
              <label class="custom-control-label fw-semibold text-dblue" for="iAgree15">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
