import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-please-read-and-sign',
  templateUrl: './please-read-and-sign.component.html',
  styleUrls: ['./please-read-and-sign.component.scss']
})
export class PleaseReadAndSignComponent implements OnInit {
  
  @Input() details: any;

  isShowLongTermResident:boolean = false;

  isShowHaveOtherPrescriptionDrug:boolean = false;

  doYouWork:string = 'no';

  doesYourSpouseWork:string = 'no';

  haveOtherPrescriptionDrug:string = 'no';

  isLongTermResident:string = 'no';

  constructor() { }

  ngOnInit() {
  }

  onChange_doYouWork(value){}

  onChange_doesYourSpouseWork(){}

  onChange_haveOtherPrescriptionDrug(value){
    if(value === 'yes'){
      this.isShowHaveOtherPrescriptionDrug = true;
    }else{
      this.isShowHaveOtherPrescriptionDrug = false;
    }
  }

  onChange_isLongTermResident(value){
    if(value === 'yes'){
      this.isShowLongTermResident = true;
    }else{
      this.isShowLongTermResident = false;
    }
  }
}
