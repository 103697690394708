<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain banner-result">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper pb-0">
    <div class="row">
      <div class="col-12">
        <h1 class="banner-main-title text-center">
          My RX Preferences
        </h1>
      </div>
    </div>
  </div>
</div>
<img class="d-none d-lg-block banner-image" src="/assets/img/beneficiary/my-providers/bg-providers.svg" alt="">

<app-loading *ngIf="isLoading" class="loading-fixed"></app-loading>

<div class="main-content-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-9 mapd-drugs-left text-left">
        <div class="dp-card d-none" [class.d-block]="existingPharmacies_list.length > 1">
          <h4 class="text-center">Your Pharmacy List & Preferences</h4>
          <p class="text-center text-capitalize font-weight-bold mb-3">Slide Up or Down To Rank of which pharmacy is your prioritiy</p>
          <ul class="list-group sortable-list" id="sortable-items">
            <li class="sort-item list-group-item px-2 mb-0" *ngFor="let existingPharmacy of existingPharmacies_list; let i = index">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="img-wrapper d-flex justify-content-center flex-shrink-0">
                    <img src="./assets/img/pharmacy-logo-temp.png" alt="">
                  </div>
                  <div class="d-flex flex-column px-2">
                    <span class="pharmacy-name">{{ existingPharmacy.pharmacy_name }}</span>
                    <span>{{ existingPharmacy.pharmacy_address }}</span>
                  </div>
                </div>
                <svg class="arrow-icon mx-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
                </svg>
              </div>
            </li>
          </ul>
        </div>
        <div class="dp-card" [class.mt-4]="existingPharmacies_list.length > 1">
          <h4 class="text-center">Add a Pharmacy</h4>
          <p class="text-center">Drug costs vary based on the pharmacy you use. Choosing pharmacies lets us show you your estimated
            drug costs, helping you pick the lowest cost plan. You don't have to choose the pharmacies you currently use.
          </p>
          <form [formGroup]="pharmacyForm" (ngSubmit)="onSubmit()">
            <div class="form-row choose-pharmacy-search">
              <div class="form-group col-12 col-md-6 d-flex flex-column">
                <label for="zipcode">Your Complete Address or Zip Code</label>
                <input type="text" id="zipcode" class="form-control blurred" formControlName="zipcode">
              </div>
              <div class="form-group col-12 col-md-6 filter-by-section d-flex flex-column">
                <label for="filter_by col-sm-2">Choose A Distance To Search Within:</label>
                <select class="form-control form-control-lg blurred" id="radius" formControlName="radius">
                  <option value="1">Distance: 1 mile</option>
                  <option value="2">Distance: 2 mile</option>
                  <option value="3">Distance: 3 mile</option>
                  <option value="4">Distance: 4 mile</option>
                  <option value="5">Distance: 5 mile</option>
                  <option value="6">Distance: 6 mile</option>
                  <option value="7">Distance: 7 mile</option>
                  <option value="8">Distance: 8 mile</option>
                  <option value="9">Distance: 9 mile</option>
                  <option value="10" selected>Distance: 10 mile</option>
                  <option value="25">Distance: 25 mile</option>
                  <option value="50">Distance: 50 mile</option>
                  <option value="75">Distance: 75 mile</option>
                </select> 
                <small class="font-italic"><strong>Note:</strong> You do not have to choose the pharmacy you currently use.</small>              
              </div>
              <div class="filter-by-section-box row w-100" *ngIf="isFormSubmitted">
                <div class="form-group col-12 col-md-6 pr-1 filter-by-section">
                  <label for="search_by col-sm-2">Search by:</label>
                  <select class="form-control" (change)="onSelectedChange($event.target.value)" formControlName="search_by_type">
                      <option value="pharmacy_name" selected>Pharmacy Name</option>
                      <option value="street_address">Street Address</option>
                  </select>                
                </div>
                <!-- <div class="form-group col-12 col-md-6">
                  <label for="pharmacy_name">Name of Pharmacy (Optional)</label>
                  <input type="text" id="pharmacy_name" (keyup)="onSearchPharmacyBusinessName($event.target.value)" class="form-control blurred" formControlName="pharmacy_name">
                </div>   -->
                <div class="form-group col-12 col-md-6 pl-1">
                  <label for="pharmacy_street">Enter Search Key</label>
                  <input type="text" id="pharmacy_street" (keyup)="onSearchPharmacy($event.target.value)" class="form-control" formControlName="pharmacy_street">
                </div>    
              </div>      
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-first btn-small">Find Pharmacy</button>
            </div>
          </form>
        </div>
        
        <app-loading *ngIf="isLoading" class="loading-fixed"></app-loading>

        <div class="results-card dp-card mt-4" *ngIf="results.length">
          <div class="p-3">
            <h4 class="text-center">Choose up to 5 Pharmacies</h4>
            <h6 class="text-center fColor-base fWeight-600">There are {{results.length}} Different Pharmacies That Fit Your Criteria</h6>
          </div>
          <table class="w-100 table-borderless table-hover table-striped">
            <tbody>
              <tr class="choose-pharmacy-results" *ngFor="let result of results">
                <td class="choose-pharmacy-results-item">
                  <img src="./assets/img/my-drugs/blank-logo.png" class="pharmacy-logo">
                  <div class="item-content">
                    <ng-container *ngIf="result.other_business_name; else obn">
                      <h6 class="name">{{ result.other_business_name | titlecase }}</h6>
                    </ng-container>
                    <ng-template #obn>
                      <h6 class="name">{{ result.business_name | titlecase }}</h6>
                    </ng-template>                  
                    <div *ngFor="let address of result.addresses">
                      <span class="address" *ngIf="address.type === 'PRACTICE'">
                        <!--{{ address.line_one | titlecase }}, {{ address.line_two | titlecase }} {{ address.city | titlecase }} {{ address.state | titlecase }} {{ address.zip_5 | titlecase }}--> <!--<small>({{ address.type | titlecase }})</small>-->
                        {{ address.line_one | titlecase }}, {{ address.line_two | titlecase }} {{ address.city | titlecase }}  <!--<small>({{ address.type | titlecase }})</small>-->
                      </span>
                    </div> 
                    <div id="pharmacy_result_additional_info_{{result.npi}}" class="pharmacy_result_additional_info">
                      <span class="fWeight-600 d-block">Phone: <span class="font-secondary ml-2">{{ result.telephone_number }}</span></span>
                      <span class="fWeight-600 d-block">Fax: <span class="font-secondary ml-2">{{ result.fax_number }}</span></span>
                      <span class="fWeight-600 d-block">NPI: <span class="font-secondary ml-2">{{ result.npi }}</span></span>
                      <div *ngFor="let address of result.addresses">
                        <span class="fWeight-600 d-block" *ngIf="address.type === 'PRACTICE'">LAT: <span class="font-secondary ml-2">"{{ address.lat }}"</span></span>
                        <span class="fWeight-600 d-block" *ngIf="address.type === 'PRACTICE'">LNG: <span class="font-secondary ml-2">"{{ address.lng }}"</span></span>
                      </div>
                      <span class="fWeight-600 d-block">Practice Fax: <span class="font-secondary ml-2">{{ (result.practice_fax_number) ? result.practice_fax_number: 'None' }}</span></span>
                    </div>
                    <a role="button" id="pharmacy_result_additional_info_btn_{{result.npi}}" class="btn btn-second-fill btn-xxs max-w-fit mt-2" (click)="onClick_MoreInfo(result.npi)">More Info</a>
                  </div>
                  <div class="item-action">
                    <!-- <input type="checkbox" (change)="pharmacySelected(result, $event)" value="{{ result.npi }}" class="custom-control-input d-none" id="pharmacy-{{result.npi}}" [checked]="existPharmacyNpi.includes(result.npi)">
                    <label id="pharmacy_label_id_{{result.npi}}" class="custom-control-label checkbox-right" for="pharmacy-{{result.npi}}" [class.active]="existPharmacyNpi.includes(result.npi)" >
                      <i class="fa fa-plus state-plus"></i>
                      <span class="fWeight-600 state-plus" style="color:#156aff;">Select Pharmacy</span>
                      <i class="fa fa-check state-check"></i>
                      <span class="fWeight-600 state-check" style="color:green;">Added to List</span>
                    </label> -->
                    <!-- <input type="checkbox" (click)="addSelectedPharmacy(addConfirmationModal, result)" value="{{ result.npi }}" class="custom-control-input d-none" id="pharmacy-{{result.npi}}" [checked]="existPharmacyNpi.includes(result.npi)">
                    <label id="pharmacy_label_id_{{result.npi}}" class="custom-control-label checkbox-right" for="pharmacy-{{result.npi}}" [class.active]="existPharmacyNpi.includes(result.npi)" [class.disabled]="existPharmacyNpi.includes(result.npi)">
                      <i class="fa fa-plus state-plus"></i>
                      <span class="fWeight-600 state-plus" style="color:#156aff;">Select Pharmacy</span>
                      <i class="fa fa-check state-check"></i>
                      <span class="fWeight-600 state-check" style="color:green;">Added to List</span>
                    </label> -->

                    <input type="checkbox" (click)="addSelectedPharmacy(addConfirmationModal, result)" value="{{ result.npi }}" class="custom-control-input d-none" id="pharmacy-{{result.npi}}" [disabled]="existPharmacyNpi.includes(result.npi)" [checked]="existPharmacyNpi.includes(result.npi)">
                    <label id="pharmacy_label_id_{{result.npi}}" class="custom-control-label checkbox-right" for="pharmacy-{{result.npi}}" [class.active]="existPharmacyNpi.includes(result.npi)" [class.disabled]="existPharmacyNpi.includes(result.npi)">
                      <i class="fa fa-plus state-plus"></i>
                      <span class="fWeight-600 state-plus" style="color:#156aff;">Select Pharmacy</span>
                      <i class="fa fa-check state-check"></i>
                      <span class="fWeight-600 state-check" style="color:green;">Added to List</span>
                    </label>

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <button class="btn btn-first btn-small mx-auto mt-4" (click)="onClick_PharmacyResultProceed(monthCoverageEffective)">Proceed</button> -->
        </div>
        <div class="results-card dp-card mt-4" *ngIf="!results.length && isFormSubmitted">
          <h5 class="fColor-base text-center">No Results Found</h5>
        </div>

      </div>
      <div class="col-12 col-md-3 mapd-drugs-right">
        <app-common-your-drug-list></app-common-your-drug-list>
        <!-- <button class="btn btn-first btn-small mx-auto mt-3" (click)="onClick_DoneAddingDrugsPharma()">Done Adding My Drugs & Pharmacies</button> -->
      </div>

    </div>
  </div>
</div>



<div class="d-none">
  <div #addConfirmationModal>
    <!-- <div *ngIf="selectedAddPharmacy" [class.d-none]="showPharmacyNoteForm">
      <h4 class="text-center mb-4">Add This Pharmacy To Your List?</h4>
      <div class="d-flex justify-content-center align-items-center">
        <img src="./assets/img/my-drugs/blank-logo.png" class="pharmacy-logo mr-2">
        <div>
          <ng-container *ngIf="selectedAddPharmacy.other_business_name; else obn">
            <h4 class="pharmacy-name">{{ selectedAddPharmacy.other_business_name | titlecase }}</h4>
          </ng-container>
          <ng-template #obn>
            <h4 class="pharmacy-name">{{ selectedAddPharmacy.business_name | titlecase }}</h4>
          </ng-template>
          <span *ngIf="selectedAddPharmacy.npi" class="text-capitalize mr-2"><strong>NPI:</strong> {{ selectedAddPharmacy.npi }}</span>
          <span *ngIf="selectedAddPharmacy.telephone_number" class="text-capitalize mr-2"><strong>Phone:</strong> {{ selectedAddPharmacy.telephone_number }}</span>
          <span *ngIf="selectedAddPharmacy.fax_number" class="text-capitalize mr-2"><strong>Fax:</strong> {{ selectedAddPharmacy.fax_number }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button class="btn btn-first btn-small" (click)="showModal(pharmacyPreference)">Add To My List</button>
        <button class="btn btn-third btn-small ml-2" (click)="showPharmacyNoteForm = true">Add Note</button>
      </div>
    </div> -->
    <div class="p-4" [class.d-none]="!showPharmacyNoteForm">
      <app-drug-notes-form (drugNotesTags)="onClick_setNotes($event)" [notes]="single_notes" [tagger]="single_tags"></app-drug-notes-form>
    </div>


    <div class="row split-design" [class.d-none]="showPharmacyNoteForm" *ngIf="selectedAddPharmacy">
      <div class="col-12">
        <div class="modal-heading d-flex flex-column align-items-center p-4">
          <img class="header-image" src="./assets/img/my-drugs/pharmacy.svg" alt="">
          <p class="question text-center px-4 mb-0 mt-3 text-capitalize">Add This Pharmacy To Your List?</p>
        </div>
      </div>
      <div class="w-100 p-4">
        <div class="d-flex justify-content-center align-items-center">
          <img src="./assets/img/my-drugs/blank-logo.png" class="pharmacy-logo mr-2">
          <div>
            <ng-container *ngIf="selectedAddPharmacy.other_business_name; else obn">
              <h4 class="pharmacy-name">{{ selectedAddPharmacy.other_business_name | titlecase }}</h4>
            </ng-container>
            <ng-template #obn>
              <h4 class="pharmacy-name">{{ selectedAddPharmacy.business_name | titlecase }}</h4>
            </ng-template>
            <span *ngIf="selectedAddPharmacy.npi" class="text-capitalize mr-2"><strong>NPI:</strong> {{ selectedAddPharmacy.npi }}</span>
            <span *ngIf="selectedAddPharmacy.telephone_number" class="text-capitalize mr-2"><strong>Phone:</strong> {{ selectedAddPharmacy.telephone_number }}</span>
            <span *ngIf="selectedAddPharmacy.fax_number" class="text-capitalize mr-2"><strong>Fax:</strong> {{ selectedAddPharmacy.fax_number }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <button class="btn btn-first btn-small" (click)="showModal(pharmacyPreference)">Add To My List</button>
          <button class="btn btn-third btn-small ml-2" (click)="showPharmacyNoteForm = true">Add Note</button>
        </div>
      </div>
    </div>
  </div>

  <div #successDialogue>
    <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex;">
      <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
      <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
      <div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
      <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
    </div>
    <div>
      <h4 class="text-center mb-2">Great! We Will Factor This Into Your Cost Analysis & Ideal Pathway Analysis!</h4>
      <p class="font-weight-bold text-center text-capitalize">Your Pharmacy has been added to your list! You Can Update your Pharmacy Preferences
        Any Time!</p>
    </div>
  </div>

  <div #pharmacySuccessModal>
    <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex;">
      <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
      <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
      <div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
      <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
    </div>
    <div>
      <h4 class="text-center mb-2">Pharmacy Successfully Added To Your List</h4>
      <div class="row">
        <div class="col-12 col-md-6 p-2">
          <button (click)="closeModal()" class="py-3 modal-buttons w-100 btn border d-flex flex-column justify-content-center align-items-center">
            <img src="./assets/img/my-drugs/add-pharmacy.svg" alt="">
            <span class="font-weight-bold">Add More Pharmacy</span>
          </button>
        </div>
        <div class="col-12 col-md-6 p-2">
          <button (click)="showNotesModal(pharmacyNotesModal)" class="py-3 modal-buttons w-100 btn border d-flex flex-column justify-content-center align-items-center">
            <img class="logo px-4" src="./assets/img/my-drugs/company-logo.png" alt="">
            <span class="font-weight-bold">Add Notes To Your Pharmacy</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div #pharmacyNotesModal class="notes-modal text-center" >
    <img src="./assets/img/my-drugs/your-notes.png" class="regular-icon mb-3" alt="Hands Clap">
    <h5>Your Pharmacy Notes</h5>
    <p class="fWeight-600">Please enter your notes below:</p>
    <div class="text-left p-3">
      <textarea class="notes-field" name="existingRxDrugListNotes" id="" placeholder="Enter your notes here..."></textarea>
    </div>
    <button class="btn btn-first btn-small mx-auto">Save</button>
  </div>

  <div #pharmacyPreference>
    <div class="split-design">
      <div class="modal-heading d-flex flex-column align-items-center p-4">
        <img class="header-image" src="./assets/img/pharm-pref.png" alt="">
        <p class="question text-center px-4 mb-0 mt-3 text-capitalize">How Would You Describe Your Preference of This Pharmacy?</p>
      </div>
      <div class="d-flex flex-column p-4">
        <button class="pref-button my-2 px-3 py-2" (click)="showModal(willingLowerCosts)">
          I am willing to go to whichever local pharmacies(within a certain distance) will get me the lowest costs
        </button>
        <button class="pref-button my-2 px-3 py-2" (click)="showModal(savingsAmount)">
          This is my preferred local pharmacy but I would consider others if they lower my costs by a large enough amount
        </button>
        <button class="pref-button my-2 px-3 py-2" (click)="showModal(mailOrderPreference)">
          This is the only local pharmacy I am willing to go
        </button>
      </div>
    </div>
  </div>

  <div #willingLowerCosts>
    <div class="split-design">
      <div class="modal-heading d-flex flex-column align-items-center p-4">
        <img class="header-image" src="./assets/img/multi-pharm.png" alt="">
        <p class="question text-center px-4 mb-0 mt-3 text-capitalize">Would You Be Willing To Use Multiple Pharmacies To Get Even Lower Costs?</p>
      </div>
      <div class="d-flex flex-column p-4">
        <button (click)="showModalTimeout(successDialogue, selectedAddPharmacy)" class="pref-button my-2 px-3 py-2">
          No, I’d Like To Get All of My Drugs From One Place
        </button>
        <button (click)="showModal(pharmacyDistance)" class="pref-button my-2 px-3 py-2">
          Yes, I Am Willing To Go To Multiple Local Pharmacies If It Gets Me Better Costs
        </button>
      </div>
    </div>
  </div>

  <div #pharmacyDistance>
    <div class="split-design">
      <div class="modal-heading d-flex flex-column align-items-center p-4">
        <img class="header-image" src="./assets/img/new-benny-assets/3.png" alt="">
        <p class="question text-center px-4 mb-0 mt-3 text-capitalize">What Amount of Savings Would It Take For You To Prefer To Use Mail Order For Some of Your Drugs?</p>
      </div>
      <div class="p-4">
        <span class="text-center font-weight-bold">
          Select an Amount
        </span>
        <select class="form-control form-control-lg blurred my-2">
          <option value="1" selected>Within 1 Mile</option>
          <option value="2">Within 2 Miles</option>
          <option value="3">Within 3 Miles</option>
          <option value="4">Within 5 Miles</option>
          <option value="5">Within 10 Miles</option>
          <option value="6">Within 15 Miles</option>
          <option value="7">Within 20 Miles</option>
        </select>
        <button class="btn btn-first btn-small mt-3 mx-auto" (click)="showModalTimeout(successDialogue, selectedAddPharmacy)">
          Proceed
        </button>
      </div>
    </div>
  </div>

  <div #savingsAmount>
    <div class="split-design">
      <div class="modal-heading d-flex flex-column align-items-center p-4">
        <img class="header-image" src="./assets/img/new-benny-assets/3.png" alt="">
        <p class="question text-center px-4 mb-0 mt-3 text-capitalize">What Amount of Savings Would It Take For You To Prefer To Use Mail Order For Some of Your Drugs?</p>
      </div>
      <div class="p-4">
        <span class="text-center font-weight-bold">
          Select an Amount
        </span>
        <select class="form-control form-control-lg blurred my-2">
          <option value="1" selected>If I Will Save Over $50 For The Year</option>
          <option value="2">If I Will Save Over Over $100 For The Year</option>
          <option value="3">If I Will Save Over Over $200 For The Year</option>
          <option value="4">If I Will Save Over Over $300 For The Year</option>
          <option value="5">If I Will Save Over Over $400 For The Year</option>
          <option value="6">If I Will Save Over Over $500 For The Year</option>
          <option value="7">If I Will Save Over Over $750 For The Year</option>
          <option value="8">If I Will Save Over Over $1,000 For The Year</option>
          <option value="9">If I Will Save Over Over $1,500 For The Year</option>
          <option value="10">If I Will Save Over Over $2,000 For The Year</option>
          <option value="11">If I Will Save Over Over $3,000 For The Year</option>
        </select>
        <button class="btn btn-first btn-small mt-3 mx-auto" (click)="showModal(willingLowerCosts)">
          Proceed
        </button>
      </div>
    </div>
  </div>

  <div #mailOrderPreference>
    <div class="split-design">
      <div class="modal-heading d-flex flex-column align-items-center p-4">
        <img class="header-image" src="./assets/img/pharm-pref.png" alt="">
        <p class="question text-center px-4 mb-0 mt-3 text-capitalize">What Is Your Preference On Using Mail Order For Some of Your Drugs?</p>
      </div>
      <div class="p-4">
        <div class="d-flex flex-column">
          <button class="pref-button my-2 px-3 py-2" (click)="showModalTimeout(successDialogue, selectedAddPharmacy)">
            I Do Not Want To Use Mail Order At All, Even If It Saves Me Money
          </button>
          <button class="pref-button my-2 px-3 py-2" (click)="showModal(savingsAmount2)">
            I Am Willing To Use Mail Order On All of My Drugs If It Saves Me Enough Money
          </button>
          <button class="pref-button my-2 px-3 py-2" (click)="showModal(savingsAmount2)">
            I Am Willing To Use Mail Order On Some of My Drugs If It Saves Me Enough Money
          </button>
        </div>
      </div>
    </div>
  </div>

  <div #savingsAmount2>
    <div class="split-design">
      <div class="modal-heading d-flex flex-column align-items-center p-4">
        <img class="header-image" src="./assets/img/new-benny-assets/3.png" alt="">
        <p class="question text-center px-4 mb-0 mt-3 text-capitalize">What Amount of Savings Would It Take For You To Prefer To Use Mail Order For Some of Your Drugs?</p>
      </div>
      <div class="p-4">
        <span class="text-center font-weight-bold">
          Select an Amount
        </span>
        <select class="form-control form-control-lg blurred my-2">
          <option value="1" selected>If I Will Save Over $50 For The Year</option>
          <option value="2">If I Will Save Over Over $100 For The Year</option>
          <option value="3">If I Will Save Over Over $200 For The Year</option>
          <option value="4">If I Will Save Over Over $300 For The Year</option>
          <option value="5">If I Will Save Over Over $400 For The Year</option>
          <option value="6">If I Will Save Over Over $500 For The Year</option>
          <option value="7">If I Will Save Over Over $750 For The Year</option>
          <option value="8">If I Will Save Over Over $1,000 For The Year</option>
          <option value="9">If I Will Save Over Over $1,500 For The Year</option>
          <option value="10">If I Will Save Over Over $2,000 For The Year</option>
          <option value="11">If I Will Save Over Over $3,000 For The Year</option>
        </select>
        <button class="btn btn-first btn-small mt-3 mx-auto" (click)="showModalTimeout(successDialogue, selectedAddPharmacy)">
          Proceed
        </button>
      </div>
    </div>
  </div>

  <!-- <div #sortPharmacy>
    <ul id="sortable-items" *ngIf="existingPharmacies_list">
      <li class="sort-item" *ngFor="let existingPharmacy of existingPharmacies_list | slice:0:5; let i = index">
        <img src="./assets/img/pharmacy-logo/walmart.JPG" alt="">
      </li>
    </ul>
  </div> -->
</div>


<div class="footer-top-spacer"></div>
<app-ready-to-talk class="noprint"></app-ready-to-talk>