import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-taptap-medicare-medigap-prices-two',
  templateUrl: './taptap-medicare-medigap-prices-two.component.html',
  styleUrls: ['./taptap-medicare-medigap-prices-two.component.scss']
})
export class TaptapMedicareMedigapPricesTwoComponent implements OnInit {
  fullPrimaryHeader: boolean = true;
  dblocking: string = 'd-none';

  constructor() {}

  ngOnInit() {}

  speakToCounselorEvent(modalViews: any, modalType) {
    this.dblocking = 'd-block';

    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: modalType,
      padding: '3em',
      onClose: () => {
        this.dblocking = 'd-none';
      }
    });
  }
}
