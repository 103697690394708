import { Component, OnInit, Input } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-enjoyable-options',
  templateUrl: './enjoyable-options.component.html',
  styleUrls: ['./enjoyable-options.component.scss']
})
export class EnjoyableOptionsComponent implements OnInit {

  @Input() showBottomPadding = false;

  constructor() { }

  ngOnInit() {
  }

  speakToCounselorEvent(modalViews) {
    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
      padding: '3em'
    });  
  }
}
