import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aep',
  templateUrl: './aep.component.html',
  styleUrls: ['./aep.component.scss']
})
export class AepComponent implements OnInit {
  isSidebarActive:boolean = false;

  constructor() { }

  ngOnInit() {
  }

  sidebarMenuToggle(){
    this.isSidebarActive = !this.isSidebarActive;
  }
}
