<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-md-8">
        <div class="banner-text-wrapper">
          <h1 class="banner-main-title">
            Get The Best Medigap <span class="fColor-orange fWeight-inherit fSize-inherit">Plan G</span><br />
            Rate with TapTap Medicare
          </h1>
          <ul class="checklist">
            <li>Access To All Medigap Plan N Carriers And Prices</li>
            <li>Save Money On Your Insurance</li>
            <li>Find Lower Plan N Rates With The Exact Same Coverage</li>
            <li>Easy-To-Understand Answers & Explanations</li>
            <li>Guidance & Support Throughout The Year</li>
          </ul>
          <div class="d-flex align-items-center flex-column flex-sm-row">
            <button class="btn btn-first mr-2" (click)="moveToContent()">Learn More</button>
            <button class="btn btn-third" routerLink="/insurance-marketplace/medigap">Compare Prices</button>
          </div>
        </div>
      </div>
      <div class="col-md-4 px-0 banner-image-wrapper">
        <img src="assets/img/common/plan-n-landing-page/plan-n-banner-character.png" class="banner-main-image" />
      </div>
    </div>
  </div>
</div>

<div class="banner-pointer-wrapper" style="margin-bottom: 0px;">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>

<section id="target_learn_more">
  <div class="container">
    <h2 class="text-center">What Is Plan G?</h2>
    <div>
      <h3>Overview</h3>
      <p>When you have Medicare Supplement coverage this Means You are keeping Medicare as your
        primary and enrolling in a Medicare Supplement (aka Medigap plan) to eliminate your out-of-
        pocket costs for “Major Medical” services.</p>
      
      <p><strong class="color-primary">Your prescription drug coverage</strong> is through a “Standalone” Medicare Part D Drug Plan, and your
        dental, vision, and hearing coverage is through a separate additional policy. These are not
        covered by your Medigap plan</p>
    </div>

    <app-insurance-coverage-box highlighted="medigap" size="large" class="col-12 col-md-10"></app-insurance-coverage-box>	

    <p>Medicare Supplement Plans are <strong class="color-primary">"Government Standardized"</strong> , which means that for a particular Plan
      Letter – like <strong class="color-primary">Plan F, Plan G, or Plan N</strong> – every carrier must provide the:</p>
    
    <ul class="d-block">
      <li class="fWeight-600">same coverage and benefits</li>
      <li class="fWeight-600">same doctors and healthcare providers</li>
      <li class="fWeight-600">same claims process</li>
      <li class="fWeight-600">same rules and guarantees</li>
    </ul>

    <p class="text-center"><strong>With Medigap Plan G,</strong> the only amount you pay out of pocket each year for major medical
      healthcare services is your once per year Part B Deductible of $203
    </p>   

    <div class="cover-list">
        <div class="col-12 details-p row">
          <ul>
            <li><img src="./assets/img/common/plan-n-landing-page/Diabetic Supplies@144x.png" alt="Doctor's Office Visits">Doctor's Office Visits</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Preventive Care@144x.png" alt="Preventive Care">Preventive Care</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Diagnostics@144x.png" alt="Diagnostic Tests">Diagnostic Tests</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Physical Theraphy@144x.png" alt="Physical Therapy">Physical Therapy</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Medical Equipment@144x.png" alt="Medical Equipment">Medical Equipment</li>
          </ul>
        </div>
        <div class="col-12 details-p row">
          <ul>
            <li><img src="./assets/img/common/plan-n-landing-page/Hospitalizations@144x.png" alt="Hospitalizations">Hospitalizations</li>
            <li><img src="./assets/img/common/plan-n-landing-page/outpatient procedures@144x.png" alt="Outpatient Procedures">Outpatient Procedures</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Skilled Nursing@144x.png" alt="Skilled Nursing">Skilled Nursing</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Surgeries@144x.png" alt="Surgeries">Surgeries</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Mental Health@144x.png" alt="Mental Health">Mental Health</li>
          </ul>
        </div>
    </div>

    <p>Part B Excess Charges are a relatively uncommon occurrence in which a healthcare provider can
        charge up to 15% above the Medicare-approved amount for a service, and is only possible if the
        healthcare provider doesn't take Medicare assignment.
    </p>

    <a href="tel:18002155108" class="btn btn-first mx-auto max-width-300">Call us For Clarity</a>
  </div>
</section>

<section class="bg-grey" style="background:#f2f2f2;">
  <div class="container">
    <p>To understand <strong>what Medicare Supplement Plan G does,</strong> here is a simplified look at
      what you are responsible for <strong>if you just have Original Medicare.</strong> Plan G covers
      everything except the highlighted Part B Deductible of $203.</p>

    <mat-divider class="my-5"></mat-divider>

    <div class="row part-a-b-services">
      <div class="col-12 col-md-6">
        <span class="part-a-b-services-box-heading">FOR PART A (INPATIENT) SERVICES:</span>
        <div class="part-a-b-services-box-wrapper">
          <div class="part-a-b-services-box">
            <h4>Inpatient Hospitalization</h4>
            <ul class="checklist">
              <li>Your first $1,484 of charges (This is your Part A Deductible)</li>
              <li>$371/day during days 60–151 (This is your Part A Copay)</li> 
              <li>$742/day during day 91–151 (This is your Part A Reserve Copay)</li>
              <li>100% of costs after day 151</li>
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Skilled Nursing</h4>
            <ul class="checklist">
              <li>$0 during days 1–20</li>
              <li>$185.50/day during day 21–100</li> 
              <li>100% of costs after day 100</li>
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Hospice Care</h4>
            <ul class="checklist">
              <li>100% of inpatient hospice costs</li>
            </ul>
          </div>
        </div>
        
      </div>
      <div class="col-12 col-md-6">
        <span class="part-a-b-services-box-heading">FOR PART B (OUTPATIENT) SERVICES:</span>
        <div class="part-a-b-services-box-wrapper">
          <div class="part-a-b-services-box">
            <h4>Outpatient Services</h4>
            <ul class="checklist">
              <li class="highlighted">Your first $203 of charges. (This is your Part B Deductible)</li>
              <li>20% of all charges. (This is your Part B Coinsurance)</li> 
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Tests and Lab Services</h4>
            <ul class="checklist">
              <li>$0 for Any Medically Necessary Diagnostic Laboratory Tests</li>
              <li>20% of Charges for Outpatient MRI, X-Rays, etc.</li> 
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Blood</h4>
            <ul class="checklist">
              <li>100% of Your First Three Pints</li>
              <li>0% of Additional Amount</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>


<section>
  <div class="view-wrapper d-flex justify-content-around align-items-center" >
          <div class="view-left"></div>
          <div class="view-center">
            <h4 class="form-input-heading" style="line-height: 44px">
              <span>Medigap Prices Made Easy.</span>
              <span>The Full Marketplace</span>
              <span>No Tricks. No Gimmicks</span>
            </h4>
            <form>
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <!-- zip code -->
                  <div class="form-group 1st-level" *ngIf="isShow">
                    <label for="zipCode">Zip Code</label>
                    <input
                      type="text"
                      class="form-control"
                      id="zipCode"
                      [formControl]="controls.zipcode"
                    />
                  </div>
                  <!-- end zip code -->

                  <!-- plan letter -->
                  <div class="form-group 2nd-level" *ngIf="!isShow">
                    <label for="planLetter">
                      Plan Letter
                      <i
                        class="fa fa-question global-info-icon-first"
                      ></i>
                    </label>
                    <div
                      class="d-flex justify-content-between planLetterButtonWrapper"
                    >
                      <!-- not massachusets -->
                      <button
                        (click)="planLetterFunc('F')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterF }"
                        *ngIf="!isMassachusetts"
                      >
                        Plan F
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="planLetterFunc('G')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterG }"
                        *ngIf="!isMassachusetts"
                      >
                        Plan G
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="planLetterFunc('N')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterN }"
                        *ngIf="!isMassachusetts"
                      >
                        Plan N
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="planLetterFunc('All')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterAP }"
                        *ngIf="!isMassachusetts"
                      >
                        All Plans
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <!-- end not massachusets -->

                      <!-- massachusets option -->
                      <button
                        (click)="planLetterFunc('MA_CORE')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterCorePlan }"
                        *ngIf="isMassachusetts"
                      >
                        Core Plan
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="planLetterFunc('MA_SUPP1')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterPlan1 }"
                        *ngIf="isMassachusetts"
                      >
                        Plan 1
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="planLetterFunc('MA_SUPP2')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterPlan1A }"
                        *ngIf="isMassachusetts"
                      >
                        Plan 1A
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="planLetterFunc('All-MA')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: planLetterMAAP }"
                        *ngIf="isMassachusetts"
                      >
                        All Plans
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <!-- end massachusets option -->
                    </div>
                    <div class="form-group-info d-none">
                      <select
                        [(ngModel)]="planLetterSelected"
                        id="planLetter"
                        class="custom-select form-control"
                        [formControl]="controls.plan"
                      >
                        <option value="F" selected *ngIf="!isMassachusetts">
                          F
                        </option>
                        <option value="N" *ngIf="!isMassachusetts">N</option>
                        <option value="G" *ngIf="!isMassachusetts">G</option>
                        <option value="All" *ngIf="!isMassachusetts">
                          All Plans
                        </option>
                        <option
                          value="MA_CORE"
                          selected
                          *ngIf="isMassachusetts"
                        >
                          Core Plan
                        </option>
                        <option value="MA_SUPP1" *ngIf="isMassachusetts">
                          Plan 1
                        </option>
                        <option value="MA_SUPP2" *ngIf="isMassachusetts">
                          Plan 1A
                        </option>
                        <option value="All-MA" *ngIf="isMassachusetts">
                          All Plans
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- end plan letter -->
                </div>

                <div class="col-12 d-flex justify-content-center">
                  <!-- age -->
                  <div class="form-group 1st-level" *ngIf="isShow">
                    <label for="age">Your Age</label>
                    <div
                      class="d-flex justify-content-between ageButtonWrapper"
                    >
                      <button
                        (click)="ageFunc('65')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: age65 }"
                      >
                        65
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="ageFunc('66')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: age66 }"
                      >
                        66
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="ageFunc('67')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: age67 }"
                      >
                        67
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="ageFunc('68')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: age68 }"
                      >
                        68
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="ageFunc('69')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: age69 }"
                      >
                        69
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="ageFunc('all')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: ageAll }"
                        style="position: relative"
                      >
                        {{ allAgeSelectedText }}
                        <i class="fas fa-check ml-2"></i>
                        <div style="position: absolute; width: 100%">
                          <mat-form-field>
                            <mat-select
                              (selectionChange)="selectedAgeFunc($event)"
                            >
                              <mat-option value="64"
                                >64 - Disabled or ESRD</mat-option
                              >
                              <mat-option value="70">70</mat-option>
                              <mat-option value="71">71</mat-option>
                              <mat-option value="72">72</mat-option>
                              <mat-option value="73">73</mat-option>
                              <mat-option value="74">74</mat-option>
                              <mat-option value="75">75</mat-option>
                              <mat-option value="76">76</mat-option>
                              <mat-option value="77">77</mat-option>
                              <mat-option value="78">78</mat-option>
                              <mat-option value="79">79</mat-option>
                              <mat-option value="80">80</mat-option>
                              <mat-option value="81">81</mat-option>
                              <mat-option value="82">82</mat-option>
                              <mat-option value="83">83</mat-option>
                              <mat-option value="84">84</mat-option>
                              <mat-option value="85">85</mat-option>
                              <mat-option value="85">86</mat-option>
                              <mat-option value="85">87</mat-option>
                              <mat-option value="85">88</mat-option>
                              <mat-option value="85">89</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </button>
                    </div>
                    <!-- old age dropdown -->
                    <select
                      [(ngModel)]="allAgeSelectedValue"
                      *ngIf="isAllAgesSelected"
                      id="age"
                      class="custom-select form-control d-none"
                      [formControl]="controls.age"
                    >
                      <option value="64">64 - Disabled or ESRD</option>
                      <option selected value="65">65</option>
                      <option value="66">66</option>
                      <option value="67">67</option>
                      <option value="68">68</option>
                      <option value="69">69</option>
                      <option value="70">70</option>
                      <option value="71">71</option>
                      <option value="72">72</option>
                      <option value="73">73</option>
                      <option value="74">74</option>
                      <option value="75">75</option>
                      <option value="76">76</option>
                      <option value="77">77</option>
                      <option value="78">78</option>
                      <option value="79">79</option>
                      <option value="80">80</option>
                      <option value="81">81</option>
                      <option value="82">82</option>
                      <option value="83">83</option>
                      <option value="84">84</option>
                      <option value="85">85</option>
                      <option value="85">86</option>
                      <option value="85">87</option>
                      <option value="85">88</option>
                      <option value="85">89</option>
                    </select>

                    <select
                      [(ngModel)]="ageSelected"
                      *ngIf="!isAllAgesSelected"
                      id="age"
                      class="custom-select form-control d-none"
                      [formControl]="controls.age"
                    >
                      <option value="64">Under Age 64</option>
                      <option value="64">64 - Disabled or ESRD</option>
                      <option selected value="65">65</option>
                      <option value="66">66</option>
                      <option value="67">67</option>
                      <option value="68">68</option>
                      <option value="69">69</option>
                      <option value="70">70</option>
                      <option value="71">71</option>
                      <option value="72">72</option>
                      <option value="73">73</option>
                      <option value="74">74</option>
                      <option value="75">75</option>
                      <option value="76">76</option>
                      <option value="77">77</option>
                      <option value="78">78</option>
                      <option value="79">79</option>
                      <option value="80">80</option>
                      <option value="81">81</option>
                      <option value="82">82</option>
                      <option value="83">83</option>
                      <option value="84">84</option>
                      <option value="85">85</option>
                      <option value="85">86</option>
                      <option value="85">87</option>
                      <option value="85">88</option>
                      <option value="85">89</option>
                    </select>
                    <!-- end old age dropdown -->
                  </div>
                  <!-- end age -->

                  <!-- tobacco use -->
                  <div class="form-group 2nd-level" *ngIf="!isShow">
                    <div *ngIf="isMassachusetts">
                      <label for="apply_household_discount">
                        Are You Within 6 Months of:
                        <i
                          class="fa fa-question global-info-icon-first d-none"
                        ></i>
                      </label>
                      <div
                        class="d-flex flex-column justify-content-between household-button-wrapper"
                      >
                        <div class="form-row">
                          <button
                            (click)="withinSixMonthsOfFunc(1)"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: partBEffectiveDate }"
                          >
                            Part B Effective Date
                            <i class="fas fa-check ml-2"></i>
                          </button>
                        </div>
                        <div class="form-row">
                          <button
                            (click)="withinSixMonthsOfFunc(2)"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: sixtyFifthBirthdayMonth }"
                          >
                            65th Birthday Month
                            <i class="fas fa-check ml-2"></i>
                          </button>
                        </div>
                        <div class="form-row">
                          <button
                            (click)="withinSixMonthsOfFunc(3)"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: losingEmployerCoverage }"
                          >
                            Losing Employer Coverage
                            <i class="fas fa-check ml-2"></i>
                          </button>
                        </div>
                        <div class="form-row">
                          <button
                            (click)="withinSixMonthsOfFunc(4)"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: movingToMassachusetts }"
                          >
                            Moving To Massachusetts
                            <i class="fas fa-check ml-2"></i>
                          </button>
                        </div>
                        <div class="form-row">
                          <button
                            (click)="withinSixMonthsOfFunc(5)"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{
                              active: movingAndLosingAccessToHmoPlan
                            }"
                          >
                            Moving & Losing Access to HMO Plan
                            <i class="fas fa-check ml-2"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!isMassachusetts">
                      <label for="tobacco_use">Tobacco Use</label>
                      <div class="d-flex justify-content-between">
                        <button
                          (click)="tobaccoUseFunc(1)"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: tobaccoUseYes }"
                        >
                          Yes
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="tobaccoUseFunc(0)"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: tobaccoUseNo }"
                        >
                          No
                          <i class="fas fa-check ml-2"></i>
                        </button>
                      </div>
                      <!-- old tobacco dropdown -->
                      <select
                        [(ngModel)]="tobaccoUseSelected"
                        id="tobacco_use"
                        class="custom-select form-control d-none"
                        [formControl]="controls.tobacco"
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                      <!-- end old tobacco dropdown -->
                    </div>
                  </div>
                  <!-- end tobacco use -->
                </div>

                <div class="col-12 d-flex justify-content-center">
                  <!-- gender -->
                  <div class="form-group 2nd-level" *ngIf="isShow">
                    <label for="gender">Gender</label>
                    <div class="d-flex justify-content-between">
                      <button
                        (click)="genderFunc('f')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: genderFemale }"
                      >
                        Female
                        <i class="fas fa-check ml-2"></i>
                      </button>
                      <button
                        (click)="genderFunc('m')"
                        class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                        [ngClass]="{ active: genderMale }"
                      >
                        Male
                        <i class="fas fa-check ml-2"></i>
                      </button>
                    </div>

                    <!-- old gender dropdown -->
                    <select
                      [(ngModel)]="genderSelected"
                      id="gender"
                      class="custom-select form-control d-none"
                      [formControl]="controls.gender"
                    >
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                    <!-- end old gender dropdown -->
                  </div>
                  <!-- end gender -->

                  <!-- apply household discount -->
                  <div class="form-group 1st-level" *ngIf="!isShow">
                    <label for="apply_household_discount">
                      Apply Household Discount
                      <i
                        class="fa fa-question global-info-icon-first d-none"
                      ></i>
                    </label>
                    <div
                      class="d-flex flex-column justify-content-between household-button-wrapper"
                    >
                      <div class="form-row">
                        <button
                          (click)="householdFunc('nhm')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: noHouseholdMembers }"
                        >
                          No, No Household Members
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <i
                          class="fa fa-question global-info-icon-first"
                        ></i>
                      </div>
                      <div class="form-row">
                        <button
                          (click)="householdFunc('awhm')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: applyingWithHouseholdMember }"
                        >
                          Yes, Applying With Household Member
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <i
                          class="fa fa-question global-info-icon-first"
                        ></i>
                      </div>
                      <div class="form-row" *ngIf="!isMassachusetts">
                        <button
                          (click)="householdFunc('mnat')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: marriedNotApplyingTogether }"
                        >
                          Married, But Not Applying Together
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <i
                          class="fa fa-question global-info-icon-first"
                        ></i>
                      </div>
                    </div>
                    <select
                      [(ngModel)]="householdSelected"
                      id="apply_household_discount"
                      class="custom-select form-control d-none"
                      [formControl]="controls.household"
                    >
                      <option value="0">No</option>
                      <option value="1" *ngIf="!isThisIsMeActive_Top">
                        Yes, Applying Together
                      </option>
                      <option value="0" *ngIf="!isThisIsMeActive_Bottom">
                        Married, Not Applying Together
                      </option>
                    </select>
                  </div>
                  <!-- end apply household discount -->
                </div>
              </div>

              <div class="row mt-3 justify-content-center">
                <div
                  class="form-group d-flex justify-content-between"
                  *ngIf="!isShow"
                >
                  <button
                    type="submit"
                    class="btn btn-third btn-back mx-1"
                    id="medigap_back_btn"
                    (click)="hideInputs()"
                  >
                    <span>Back</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-first mx-1 getMyQuoteBtn"
                    (click)="onSearch()"
                  >
                    See My Prices
                  </button>
                </div>
                <div
                  class="form-group d-flex justify-content-center"
                  *ngIf="isShow"
                >
                  <button
                    type="submit"
                    class="btn btn-first"
                    (click)="showInputs()"
                  >
                    Proceed
                    <!--<i class="fas fa-angle-double-right ml-2"></i>-->
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="view-right">
            <!-- <img
              src="./assets/img/common/old-woman-with-basket-medigap.png"
              alt="senior citizen holding a basket"
            /> -->
          </div>
  </div>
</section>

<div class="d-none">
  <div #videoContent>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <div
          class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true"
          style="height: 100%; position: relative; width: 100%"
        >
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            "
          >
            <img
              src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch"
              style="
                filter: blur(5px);
                height: 100%;
                object-fit: contain;
                width: 100%;
              "
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ready-to-talk [isLandingPage]="true"></app-ready-to-talk>

<div class="d-none">
  <div #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>
