import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-share-plan',
  templateUrl: './share-plan.component.html',
  styleUrls: ['./share-plan.component.scss']
})
export class SharePlanComponent implements OnInit {

  @Input()
  sharePlanId:string;

  constructor() { }

  ngOnInit() {
  }

  /***
   * User Login
   * 
   * @returns {void}
   */
   sharePlanLoginModal():void {
    const loginElement = `<div id="sharePlan_${this.sharePlanId}">
    <div class="container">
      <div class="row">
        <div class="share-plan-content d-block w-100">
          <img src="../../../assets/img/common/taptap-logo-colored.svg" alt="">
          <div class="login-sso-icons">
            <span class="mt-4 color-primary fWeight-500">Login with</span>
            <div class="icon-group">
              <svg xmlns="http://www.w3.org/2000/svg" width="45" viewBox="0 0 45 45">
                <g id="facebook-icon" transform="translate(247 -14743)">
                  <circle id="facebook-icon-2" data-name="facebook-icon" cx="22.5" cy="22.5" r="22.5" transform="translate(-247 14743)" fill="#29487d"/>
                  <path id="icons8-facebook-f" d="M20.191,10.934H15.773V8.381c0-1.317.105-2.147,1.959-2.147h2.341V2.176A32.084,32.084,0,0,0,16.641,2c-3.4,0-5.882,2.115-5.882,6v2.937H7v5.1h3.76V27.525h5.013V16.035h3.842Z" transform="translate(-238.095 14750.512)" fill="#fff"/>
                </g>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="45" viewBox="0 0 45 45">
                <g id="twitter-icon" transform="translate(182 -14743)">
                  <circle id="Ellipse_725" data-name="Ellipse 725" cx="22.5" cy="22.5" r="22.5" transform="translate(-182 14743)" fill="#55acee"/>
                  <path id="icons8-twitter" d="M27,7.4a8.794,8.794,0,0,1-2.946.994A6.264,6.264,0,0,0,26.308,4.9,9.215,9.215,0,0,1,23.05,6.43a4.735,4.735,0,0,0-3.743-1.994c-2.833,0-5.129,2.827-5.129,6.311a7.794,7.794,0,0,0,.133,1.438A13.885,13.885,0,0,1,3.739,5.59a7.44,7.44,0,0,0-.693,3.175,6.79,6.79,0,0,0,2.281,5.252A4.415,4.415,0,0,1,3,13.227v.079c0,3.059,1.767,5.61,4.114,6.188A4.221,4.221,0,0,1,4.8,19.6c.653,2.507,2.547,4.333,4.791,4.384a9.146,9.146,0,0,1-6.37,2.7A8.4,8.4,0,0,1,2,26.6a12.631,12.631,0,0,0,7.862,2.835c9.434,0,14.592-9.617,14.592-17.958q0-.41-.014-.816A12.079,12.079,0,0,0,27,7.4Z" transform="translate(-173.999 14748.564)" fill="#fff"/>
                </g>
              </svg>                      
              <svg id="google-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29.407" viewBox="0 0 29.407 30.091">
                <defs>
                  <clipPath id="clip-path">
                    <path id="Path_3121" data-name="Path 3121" d="M-339.734,297.61h-14.02v5.813h8.07c-.752,3.693-3.9,5.813-8.07,5.813a8.873,8.873,0,0,1-8.891-8.891,8.873,8.873,0,0,1,8.891-8.891,8.7,8.7,0,0,1,5.54,1.983l4.377-4.377a14.977,14.977,0,0,0-9.916-3.761A15,15,0,0,0-368.8,300.346a15,15,0,0,0,15.046,15.046c7.523,0,14.362-5.471,14.362-15.046A12.48,12.48,0,0,0-339.734,297.61Z" transform="translate(368.8 -285.3)"/>
                  </clipPath>
                </defs>
                <g id="Group_1802" data-name="Group 1802" transform="translate(0 0)" clip-path="url(#clip-path)">
                  <path id="Path_3120" data-name="Path 3120" d="M-370.8,312.081V294.3l11.626,8.891Z" transform="translate(369.432 -288.145)" fill="#fbbc05"/>
                </g>
                <g id="Group_1803" data-name="Group 1803" transform="translate(0 0)" clip-path="url(#clip-path)">
                  <path id="Path_3122" data-name="Path 3122" d="M-370.8,290.823l11.626,8.891,4.787-4.172,16.413-2.667V283.3H-370.8Z" transform="translate(369.432 -284.668)" fill="#ea4335"/>
                </g>
                <g id="Group_1805" data-name="Group 1805" transform="translate(0 0)">
                  <g id="Group_1804" data-name="Group 1804" clip-path="url(#clip-path)">
                    <path id="Path_3124" data-name="Path 3124" d="M-370.8,308.6l20.517-15.73,5.4.684,6.907-10.258v32.827H-370.8Z" transform="translate(369.432 -284.668)" fill="#34a853"/>
                  </g>
                </g>
                <g id="Group_1807" data-name="Group 1807" transform="translate(0 0)">
                  <g id="Group_1806" data-name="Group 1806" clip-path="url(#clip-path)">
                    <path id="Path_3126" data-name="Path 3126" d="M-333.864,319.6l-21.2-16.413-2.736-2.052,23.936-6.839Z" transform="translate(365.323 -288.145)" fill="#4285f4"/>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="row my-3">
            <div class="col">
              <div class="or-separator">
                <hr>
                <span class="px-2">OR</span>
              </div>
            </div>
          </div>
          <h6 class="mt-2 mb-2 color-primary fWeight-500 text-center">Leave your Name and Email Address</h6>
          <div class="share-plan-login-input-group">
            <input type="text" placeholder="Name" class="d-flex w-100">
            <input type="email" placeholder="Email address" class="d-flex w-100">
          </div>
          <button class="share-plan-login-submit-btn d-flex mx-auto">Submit</button>
          <div class="share-plan-login-footer">
            <span>
              <a href="" class="color-primary">Login</a>
              as Agent
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>`
    
    Swal.fire({
      html: loginElement,
      title: '<h1 class="color-primary">How Do You Want To Share This Plan?</h1>',
      padding: 50,
      customClass: 'swal-shareplan',
      showConfirmButton: false,
      showCloseButton: true
    });
    
    $(`#sharePlan_${this.sharePlanId} .share-plan-login-submit-btn`).on('click', () => {
      Swal.close();
      this.reviewCarrier();
    })
  }
  
  /**
   * Start of user modal review
   * 
   * @returns {void}
   */
  reviewCarrier():void {
    const carrierElement = `<div id='sharePlanCarrier_${this.sharePlanId}'>
    <div class="container">
      <div class="row">
        <div class="carrier-first-page d-flex w-100">
          <div class="carrier-img-content">
            <img src="../../../assets/img/common/share-plan/contact-us-woman.png" alt="">
          </div>
          <div class="carrier-main-content d-block mx-auto">
            <h6 class="text-uppercase color-primary text-center">Review this carrier</h6>
            <h4 class="text-uppercase text-center mt-4 fWeight-500">Logo Coming Soon</h4>
            <div class="top-align-rating text-center mt-3 mb-3">
              <i class="fa fColor-orange fa-star"></i>
              <i class="fa fColor-orange fa-star"></i>
              <i class="fa fColor-orange fa-star"></i>
              <i class="fa fColor-orange fa-star"></i>
              <i class="fa fColor-orange fa-star-o"></i>
              <span class="fColor-orange fWeight-500">( 4 )</span>
            </div>
            <div class="carrier-rating-breakdown">
              <div class="group-rating">
                <span class="color-primary text-uppercase fWeight-500">Customer Service Score:</span>
                <div class="star-group">
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <span class="fColor-orange fWeight-500">( 0 )</span>
                </div>
              </div>
            </div>
            <div class="carrier-rating-breakdown">
              <div class="group-rating">
                <span class="color-primary text-uppercase fWeight-500">Customer Clarity Score:</span>
                <div class="star-group">
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <span class="fColor-orange fWeight-500">( 0 )</span>
                </div>
              </div>
            </div>
            <div class="carrier-rating-breakdown">
              <div class="group-rating">
                <span class="color-primary text-uppercase fWeight-500">Price Stability Score:</span>
                <div class="star-group">
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <span class="fColor-orange fWeight-500">( 0 )</span>
                </div>
              </div>
            </div>
            <div class="carrier-rating-breakdown">
              <div class="group-rating">
                <span class="color-primary text-uppercase fWeight-500">Overall Score:</span>
                <div class="star-group">
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <i class="fa fColor-orange fa-star-o"></i>
                  <span class="fColor-orange fWeight-500">( 0 )</span>
                </div>
              </div>
            </div>
    
            <div class="carrier-btn-group mt-5">
              <button class="btn btn-first-plain">
                NEXT
              </button>
            </div>
          </div>
        </div>
  
        <div class="carrier-second-page d-none w-100">
          <div class="carrier-img-content">
            <img src="../../../assets/img/common/share-plan/contact-woman-cropped.png" alt="">
          </div>
          <div class="carrier-main-content">
            <h6 class="text-uppercase color-primary text-center">Request this plan document</h6>
            <div class="top-note d-flex mx-auto">
              <span class="text-center">
                <strong>The TapTap Team</strong>
                Is In The Process of Getting This Particular Plan Document Connected.
              </span>
            </div>
            
            <div class="second-page-content mt-4 d-flex">
              <div class="second-page-left-note">
                <h5 class="fWeight-500 color-primary">
                  We Will Send This Plan's Documents Directly To You Within 24 Hours:
                </h5>
              </div>
              <div class="second-page-right-form">
                <h6 class="color-primary text-center">Enter Your Details Here:</h6>
                <div class="page-input-group">
                  <label for="fullName_${this.sharePlanId}" class="fWeight-500">Full Name</label>
                  <input id="fullName_${this.sharePlanId}" name="fullName${this.sharePlanId}" placeholder="Please enter.." />
                </div>
                <div class="page-input-group">
                  <label for="fullPhone_${this.sharePlanId}" class="fWeight-500">Full Phone</label>
                  <input id="fullPhone_${this.sharePlanId}" name="fullPhone${this.sharePlanId}" placeholder="Please enter.." />
                </div>
                <div class="page-input-group">
                  <label for="email_${this.sharePlanId}" class="fWeight-500">Email To</label>
                  <input id="email_${this.sharePlanId}" name="email${this.sharePlanId}" placeholder="Please enter.." />
                </div>
                <div class="carrier-btn-group mt-5">
                  <button class="btn btn-first-plain text-uppercase d-flex mx-auto">
                    Submit Request
                  </button>
                </div>
              </div>
            </div>

            <span class="fWeight-500 d-flex mx-auto text-center footer-note">
              All emails include an unsubscribe link. You may opt-out at any time.
            </span>
          </div>
        </div>

        <div class="carrier-third-page d-none w-100">
          <div class="carrier-img-content">
            <img src="../../../assets/img/common/share-plan/contact-us-woman.png" alt="">
          </div>
          <div class="carrier-main-content d-block mx-auto">
            <h6 class="text-uppercase color-primary text-center">Review this carrier</h6>
            <div class="third-page-question d-flex mx-auto">
              <h5 class="text-center mt-4 fWeight-500">What Insights or Advice Would You Give To Others About This Carrier? <b>*</b> </h5>
            </div>
            <div class="third-page-textarea-container">
              <div class="form-group">
                <textarea class="form-control" id="message_${this.sharePlanId}" rows="7"></textarea>
              </div>
            </div>
            <div class="third-page-button-container">
              <div class="carrier-btn-group mt-5 d-flex">
                <button class="btn btn-third text-uppercase d-flex mr-1">
                  Back
                </button>
                <button class="btn btn-first-plain text-uppercase d-flex ml-1">
                  Submit Review
                </button>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>`

    Swal.fire({
      html: carrierElement,
      title: '<h1 class="color-primary">Why Do You Want To Share This Plan?</h1>',
      padding: 50,
      customClass: 'swal-shareplan-carrier',
      showConfirmButton: false,
      showCloseButton: true
    });

    const firstPageNextButton = $(`#sharePlanCarrier_${this.sharePlanId} .carrier-first-page .carrier-btn-group button`);
    const secondPageNextButton = $(`#sharePlanCarrier_${this.sharePlanId} .carrier-second-page .carrier-btn-group button`);
    const thirdPageBackButton = $(`#sharePlanCarrier_${this.sharePlanId} .carrier-third-page .carrier-btn-group .btn-third`);
    const thirdPageNextButton = $(`#sharePlanCarrier_${this.sharePlanId} .carrier-third-page .carrier-btn-group .btn-first-plain`);

    const firstPageDiv = $(`#sharePlanCarrier_${this.sharePlanId} .carrier-first-page`);
    const secondPageDiv = $(`#sharePlanCarrier_${this.sharePlanId} .carrier-second-page`);
    const thirdPageDiv = $(`#sharePlanCarrier_${this.sharePlanId} .carrier-third-page`);

    const secondPageTitleValue = 'Who Do You Want To Share This Plan With?';
    const thirdPageTitleValue = 'Addition Notes To Share This Plan With?';

    firstPageNextButton.on('click', () => {
      firstPageDiv[0].style.setProperty('display','none','important');
      secondPageDiv[0].style.setProperty('display','flex','important');
      $(`.swal-shareplan-carrier .swal2-title`).text(secondPageTitleValue)
    });

    secondPageNextButton.on('click', () => {
      secondPageDiv[0].style.setProperty('display','none','important');
      thirdPageDiv[0].style.setProperty('display','flex','important');
      $(`.swal-shareplan-carrier .swal2-title`).text(thirdPageTitleValue)
    });

    thirdPageBackButton.on('click', () => {
      secondPageDiv[0].style.setProperty('display','flex','important');
      thirdPageDiv[0].style.setProperty('display','none','important');
      $(`.swal-shareplan-carrier .swal2-title`).text(secondPageTitleValue)
    });

    thirdPageNextButton.on('click', () => {
      Swal.close();

      const mockData = {}; // store gathered data
      this.submitReviewCarrier(mockData);
    })
  }

  /***
   * Submit User Review
   * 
   * @param {object} userFormAndReviews
   * @type {void}
   */
  submitReviewCarrier(userFormAndReviews: object):void {
    // Apply Asynchronous post request here
    // userFormAndReviews - { }

    this.overallReviewExperienceModal();
  }

  /***
   * Show Overall Experience Modal 
   * 
   * @type {void}
   */
  overallReviewExperienceModal():void {
    const overallReviewElement = `
      <div class="overall-review-experience-container">
        <div class="container">
          <div class="row">
            <img src="../../../assets/img/common/taptap-logo-colored.svg" alt="">
            <div class="d-flex mx-auto mt-4">
              <h3 class="color-primary fWeight-500 text-center">Thank You!</h3>
            </div>
            <div class="d-flex mx-auto mt-4">
              <h5 class="text-center color-primary">Do You Have A Moment To Review TapTap Medicare?</h5>
            </div>
            <div class="d-flex mx-auto mt-4">
              <button class="overall-review-main-button fWeight-500">Yes, Let's Do It!</button>
            </div>
            <div class="d-flex mx-auto mt-3">
              <button class="overall-review-alt-button">No, Please Email Me A Link To Complete Later!</button>
            </div>
          </div>
        </div>
      </div>
    `

    Swal.fire({
      html: overallReviewElement,
      padding: 50,
      customClass: 'swal-overall-experience-review',
      showConfirmButton: false,
      showCloseButton: true
    });

    const AgreeButtonElement = $(".overall-review-main-button");
    const DeclineButtonElement = $(".overall-review-alt-button");

    AgreeButtonElement.on('click', () => {
      this.overallStarRating();
    });

    DeclineButtonElement.on('click', () => {
      // Send user an email here

      // Close modal to end
      Swal.close();
    });

  }

  /***
   * Show Star Rating
   * 
   * @type {void}
   */
  overallStarRating():void {
    const overallStarRating = `
      <div class="overall-star-rating-container">
        <div class="container">
          <div class="row">
            <img src="../../../assets/img/common/taptap-logo-colored.svg" alt="">
            <div class="d-flex mx-auto mt-4">
              <h5 class="text-center color-primary">How Has Your Experience Been With TapTap Medicare So Far?</h5>
            </div>
            <div class="d-flex w-100 mt-4 star-content">
              <div class="overall-star-icons d-flex">
                <i class="fa fColor-orange fa-star"></i>
                <i class="fa fColor-orange fa-star"></i>
                <i class="fa fColor-orange fa-star"></i>
                <i class="fa fColor-orange fa-star"></i>
                <i class="fa fColor-orange fa-star-o"></i>
                <span class="star-total">(4.5/5)</span>
              </div>
            </div>
            <div class="d-flex mx-auto mt-4">
              <button class="overall-review-main-button">Submit</button>
            </div>
          </div>
        </div>
      </div>
    `

    Swal.fire({
      html: overallStarRating,
      padding: 20,
      customClass: 'swal-overall-star-review',
      showConfirmButton: false,
      showCloseButton: true
    });

    const starRatingElement = $(".overall-star-rating-container .overall-review-main-button");

    starRatingElement.on('click', () => {
      // Submit overall star rating here
      Swal.close();
    });
  }

}
