import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-finish-preferences',
  templateUrl: './finish-preferences.component.html',
  styleUrls: ['./finish-preferences.component.scss']
})
export class FinishPreferencesComponent implements OnInit {
  
  @Input() details: any;
  @Input() planName: any;

  SelectedDate:any;

  isShowSignature:boolean = false;

  constructor() { }

  ngOnInit() {
  }

  onClickDob(){

  }

  onChange_IAgree_Finish(event){
    if(event.target.checked){
      this.isShowSignature = true;
    }else{
      this.isShowSignature = false;
    }
  }
}
