import {NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from './safepipe';
import {UrlPipe} from './urlpipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {AgmCoreModule} from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import {RouterModule} from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {SaveMyQuoteComponent} from './common/save-my-quote/save-my-quote.component';
import {EmailMyQuoteComponent} from './common/email-my-quote/email-my-quote.component';
import {HeaderBComponent} from './common/header-b/header-b.component';
import {ReadyToTalkComponent} from './common/ready-to-talk/ready-to-talk.component';
import {TestimonialsComponent} from './common/testimonials/testimonials.component';
import {NewToMedicareComponent} from './common/new-to-medicare/new-to-medicare.component';


import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import {ApplyOnThePhoneComponent} from './common/modal/apply-on-the-phone/apply-on-the-phone.component';

import {ApplyOnlineModalComponent} from './common/modal/apply-online-modal/apply-online-modal.component';

import {CompanyProfileComponent} from './common/modal/company-profile/company-profile.component';

import {ConsumerReviewsComponent} from './common/modal/consumer-reviews/consumer-reviews.component';

import {AdditionalInsightsComponent} from './common/modal/additional-insights/additional-insights.component';

import {EstimateDrugCostsComponent} from './common/modal/estimate-drug-costs/estimate-drug-costs.component';

import {PlansDrugCostsComponent} from './common/modal/plans-drug-costs/plans-drug-costs.component';

import {PharmaciesOrderTableComponent} from './common/pharmacies-order-table/pharmacies-order-table.component';

import {SpeakWithACounselorComponent} from './common/speak-with-a-counselor/speak-with-a-counselor.component';

import {MedicareServicesFormComponent} from './common/capture-forms/medicare-services-form/medicare-services-form.component';

import {AutoInsuranceFormComponent} from './common/capture-forms/auto-insurance-form/auto-insurance-form.component';

import {HomeownersInsuranceFormComponent} from './common/capture-forms/homeowners-insurance-form/homeowners-insurance-form.component';

import {RentersInsuranceFormComponent} from './common/capture-forms/renters-insurance-form/renters-insurance-form.component';

import {ResultNotFoundComponent} from './common/result-not-found/result-not-found.component';

import {MaterialModule} from './material.module';

import {RetireeDashboardNavigationComponent} from './common/retiree-dashboard-navigation/retiree-dashboard-navigation.component';

import {BeneficiaryDashboardNavigationComponent} from './common/beneficiary-dashboard-navigation/beneficiary-dashboard-navigation.component';

import {InsuranceLogoComponent} from './common/insurance-logo/insurance-logo.component';

import {LoadingComponent} from './common/loading/loading.component';

import {EnjoyableOptionsComponent} from './common/enjoyable-options/enjoyable-options.component';

import {ReadyToTalkLandingComponent} from './common/ready-to-talk-landing/ready-to-talk-landing.component';

import { SliderHeaderComponent } from './slider-form/slider-header/slider-header.component';

import {AddYourDrugsComponent} from './common/modal/add-your-drugs/add-your-drugs.component';

import { ViewAllRatingsComponent } from './common/modal/view-all-ratings/view-all-ratings.component';

import { ViewAllRatingsV2Component } from './common/modal/view-all-ratings-v2/view-all-ratings-v2.component';

import { MoreInsightsComponent } from './common/modal/more-insights/more-insights.component';

import { ApplyOnlineComponent } from './common/apply-online/apply-online.component';

import {SubmitAReviewComponent} from './common/modal/submit-a-review/submit-a-review.component';

import {SubmitAReviewNewComponent} from './common/modal/submit-a-review-new/submit-a-review-new.component';

import {InsuranceCoverageBoxComponent} from './common/insurance-coverage-box/insurance-coverage-box.component';

import {StarRatingComponent} from './common/star-rating/star-rating.component';

import {DobComponent} from './common/dob/dob.component';

import { DrugNotesFormComponent } from './common/drug-notes-form/drug-notes-form.component';

import { CommonDrugsComponent } from './common/common-drugs/common-drugs.component';
import { CommonStep1Component } from './common/common-drugs/common-step1/common-step1.component';
import { CommonStep2Component } from './common/common-drugs/common-step2/common-step2.component';
import { CommonStep3Component } from './common/common-drugs/common-step3/common-step3.component';
import { CommonStep4Component } from './common/common-drugs/common-step4/common-step4.component';
import { CommonYourDrugListComponent } from './common/common-drugs/common-your-drug-list/common-your-drug-list.component';
import { CommonLoginFormComponent } from './common/common-drugs/common-login-form/common-login-form.component';
import { CommonSignupFormComponent } from './common/common-drugs/common-signup-form/common-signup-form.component';
import { YourFullRxProfileComponent } from './common/common-drugs/your-full-rx-profile/your-full-rx-profile.component';
import { Version1Component as MapdVersion1 } from './common/plan-results/mapd/version/version1/version1.component';
import { Version2Component as MapdVersion2 } from './common/plan-results/mapd/version/version2/version2.component';
import { Version1Component as MedigapVersion1 } from './common/plan-results/medigap/version/version1/version1.component';
import { Version2Component as MedigapVersion2 } from './common/plan-results/medigap/version/version2/version2.component';
import { Version3Component as MedigapVersion3 } from './common/plan-results/medigap/version/version3/version3.component';
import { Version1Component as PdpVersion1 } from './common/plan-results/pdp/version/version1/version1.component';
import { Version2Component as PdpVersion2 } from './common/plan-results/pdp/version/version2/version2.component';
import { Version1Component as DentalVersion1 } from './common/plan-results/dental/version/version1/version1.component';
import { Version2Component as DentalVersion2 } from './common/plan-results/dental/version/version2/version2.component';
import { RequestPlanDocumentComponent } from './common/request-plan-document/request-plan-document.component';
import { Version1Component as OnlineApplicationMapdVersion1 } from './common/online-application/mapd/version1/version1.component';
import { ImportantInformationComponent } from './common/modal/apply-online-modal/sub/important-information/important-information.component';
import { AcknowledgementComponent } from './common/modal/apply-online-modal/sub/acknowledgement/acknowledgement.component';
import { PleaseReadAndSignComponent } from './common/online-application/mapd/version1/sub/please-read-and-sign/please-read-and-sign.component';
import { BasicInfoComponent } from './common/online-application/mapd/version1/sub/basic-info/basic-info.component';
import { ContactInfoComponent } from './common/online-application/mapd/version1/sub/contact-info/contact-info.component';
import { MedicareInfoComponent } from './common/online-application/mapd/version1/sub/medicare-info/medicare-info.component';
import { CoverageInfoComponent } from './common/online-application/mapd/version1/sub/coverage-info/coverage-info.component';
import { PolicyInfoComponent } from './common/online-application/mapd/version1/sub/policy-info/policy-info.component';
import { PayingYourPlanPremiumComponent } from './common/online-application/mapd/version1/sub/paying-your-plan-premium/paying-your-plan-premium.component';
import { PrimaryCareProviderSelectionComponent } from './common/online-application/mapd/version1/sub/primary-care-provider-selection/primary-care-provider-selection.component';
import { AttestationOfEligibilityForAnEnrollmentPeriodComponent } from './common/online-application/mapd/version1/sub/attestation-of-eligibility-for-an-enrollment-period/attestation-of-eligibility-for-an-enrollment-period.component';
import { FinishPreferencesComponent } from './common/online-application/mapd/version1/sub/finish-preferences/finish-preferences.component';
import { AuthorizedRepresentativeQuestionComponent } from './common/modal/apply-online-modal/sub/important-information/authorized-representative-question/authorized-representative-question.component';
import { Slide1Component as AuthorizedRepresentativeQuestionSlide1Component } from './common/modal/apply-online-modal/sub/important-information/authorized-representative-question/slide1/slide1.component';
import { Slide2Component as AuthorizedRepresentativeQuestionSlide2Component } from './common/modal/apply-online-modal/sub/important-information/authorized-representative-question/slide2/slide2.component';
import { Slide3Component as AuthorizedRepresentativeQuestionSlide3Component } from './common/modal/apply-online-modal/sub/important-information/authorized-representative-question/slide3/slide3.component';
import { CommonDrugsV2Component } from './common/common-drugs-v2/common-drugs-v2.component';
import { CommonStep1Component as CommonStep1V2Component } from './common/common-drugs-v2/common-step1/common-step1.component';
import { Version2Component as OnlineApplicationMapdVersion2 } from './common/online-application/mapd/version2/version2.component';
import { Screen1Component as OnlineApplicationMapdVersion2Screen1 } from './common/online-application/mapd/version2/sub/screen1/screen1.component';
import { Screen2Component as OnlineApplicationMapdVersion2Screen2 } from './common/online-application/mapd/version2/sub/screen2/screen2.component';
import { Screen3Component as OnlineApplicationMapdVersion2Screen3 } from './common/online-application/mapd/version2/sub/screen3/screen3.component';
import { Screen4Component as OnlineApplicationMapdVersion2Screen4 } from './common/online-application/mapd/version2/sub/screen4/screen4.component';
import { Screen5Component as OnlineApplicationMapdVersion2Screen5 } from './common/online-application/mapd/version2/sub/screen5/screen5.component';
import { Screen6Component as OnlineApplicationMapdVersion2Screen6 } from './common/online-application/mapd/version2/sub/screen6/screen6.component';
import { Screen7Component as OnlineApplicationMapdVersion2Screen7 } from './common/online-application/mapd/version2/sub/screen7/screen7.component';
import { Screen8Component as OnlineApplicationMapdVersion2Screen8 } from './common/online-application/mapd/version2/sub/screen8/screen8.component';
import { Screen9Component as OnlineApplicationMapdVersion2Screen9 } from './common/online-application/mapd/version2/sub/screen9/screen9.component';
import { Screen10Component as OnlineApplicationMapdVersion2Screen10 } from './common/online-application/mapd/version2/sub/screen10/screen10.component';
import { Screen11Component as OnlineApplicationMapdVersion2Screen11 } from './common/online-application/mapd/version2/sub/screen11/screen11.component';
import { PetInsuranceFormComponent } from './common/capture-forms/pet-insurance-form/pet-insurance-form.component';
import {ProviderStatusComponent} from './common/modal/provider-status/provider-status.component';
import { SharePlanComponent } from './common/share-plan/share-plan.component';
import { ProviderDetailComponent } from './insurance-marketplace/medicare-advantage/results/provider-detail/provider-detail.component';
import { OutOfPocketComponent } from './out-of-pocket/out-of-pocket.component';
import { MapdCustomFilterComponent } from './common/mapd-custom-filter/mapd-custom-filter.component';

import { MapdFilterResultModalComponent } from './common/mapd-filter-result-modal/mapd-filter-result-modal.component';
import { ShareFormComponent } from './common/modal/share-form/share-form.component';

// MEDIGAP STEPPER
import { Version1Component as OnlineApplicationMedigapVersion1 } from './common/online-application/medigap/version1/version1.component';
import { Screen1Component as OnlineApplicationMedigapVersion1Screen1 } from './common/online-application/medigap/version1/sub/screen1/screen1.component';
import { Screen2Component as OnlineApplicationMedigapVersion1Screen2 } from './common/online-application/medigap/version1/sub/screen2/screen2.component';
import { Screen3Component as OnlineApplicationMedigapVersion1Screen3 } from './common/online-application/medigap/version1/sub/screen3/screen3.component';
import { Screen4Component as OnlineApplicationMedigapVersion1Screen4 } from './common/online-application/medigap/version1/sub/screen4/screen4.component';
import { Screen5Component as OnlineApplicationMedigapVersion1Screen5 } from './common/online-application/medigap/version1/sub/screen5/screen5.component';
import { Screen6Component as OnlineApplicationMedigapVersion1Screen6 } from './common/online-application/medigap/version1/sub/screen6/screen6.component';
import { Screen7Component as OnlineApplicationMedigapVersion1Screen7 } from './common/online-application/medigap/version1/sub/screen7/screen7.component';
import { Screen8Component as OnlineApplicationMedigapVersion1Screen8 } from './common/online-application/medigap/version1/sub/screen8/screen8.component';
import { YourDrugsComponent } from './common/online-application/medigap/version1/sub/your-drugs/your-drugs.component';
import { SearchDrugsComponent } from './common/online-application/medigap/version1/sub/search-drugs/search-drugs.component';
import { Screen9Component as OnlineApplicationMedigapVersion1Screen9 } from './common/online-application/medigap/version1/sub/screen9/screen9.component';
import { Screen10Component as OnlineApplicationMedigapVersion1Screen10 } from './common/online-application/medigap/version1/sub/screen10/screen10.component';
import { Screen11Component as OnlineApplicationMedigapVersion1Screen11 } from './common/online-application/medigap/version1/sub/screen11/screen11.component';
import { Screen12Component as OnlineApplicationMedigapVersion1Screen12 } from './common/online-application/medigap/version1/sub/screen12/screen12.component';
import { Screen13Component as OnlineApplicationMedigapVersion1Screen13 } from './common/online-application/medigap/version1/sub/screen13/screen13.component';
import { Screen14Component as OnlineApplicationMedigapVersion1Screen14 } from './common/online-application/medigap/version1/sub/screen14/screen14.component';
import { Screen15Component as OnlineApplicationMedigapVersion1Screen15 } from './common/online-application/medigap/version1/sub/screen15/screen15.component';
import { Screen16Component as OnlineApplicationMedigapVersion1Screen16 } from './common/online-application/medigap/version1/sub/screen16/screen16.component';
import { Screen17Component as OnlineApplicationMedigapVersion1Screen17} from './common/online-application/medigap/version1/sub/screen17/screen17.component';
import { WysiwygComponent } from './common/wysiwyg/wysiwyg.component';
import { ProviderNetworkStatusComponent } from './common/modal/provider-network-status/provider-network-status.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { CustomPaginationComponent } from './common/custom-pagination/custom-pagination.component';

import { MapdAdditionalInsightsComponent } from './common/modal/mapd-additional-insights/mapd-additional-insights.component';
import { CarrierPlanDetailsComponent } from './common/modal/mapd-additional-insights/sub/carrier-plan-details/carrier-plan-details.component';
import { PlanRelatedDatesTimelineComponent } from './common/modal/mapd-additional-insights/sub/plan-related-dates-timeline/plan-related-dates-timeline.component';
import { PopularityEnrollmentComponent } from './common/modal/mapd-additional-insights/sub/popularity-enrollment/popularity-enrollment.component';
import { TaptapPlanCarrierInsightsComponent } from './common/modal/mapd-additional-insights/sub/taptap-plan-carrier-insights/taptap-plan-carrier-insights.component';
import { CustomLottiePlayerComponent } from './common/custom-lottie-player/custom-lottie-player.component';
import { FirstVariationComponent } from './life-insurance/first-variation/first-variation.component';
import { SecondVariationComponent } from './life-insurance/second-variation/second-variation.component';
import { ThirdVariationComponent } from './life-insurance/third-variation/third-variation.component';
import { MapdStarRatingComponent } from './common/mapd-star-rating/mapd-star-rating.component';
import { GoogleMapV14Component } from './common/google-map-v14/google-map-v14.component';
import { environment } from 'src/environments/environment';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MaterialModule,
        BsDatepickerModule,
        TimepickerModule,
        // DO NOT REMOVE COMMENT BELOW FOR RECAPTCHA MODULE
        // RecaptchaModule.forRoot({
        //     siteKey: '6LfYbNYUAAAAADJgUKeiBbQRZf03bRx-rjQLA1FA',
        // }),
        // GoogleMapsModule.forRoot({
        //     apiKey: 'AIzaSyAFvtu4EoZgrrIUsY8hKARR84RngUHGNqA'
        //     // libraries: ['places']
        // }),
        RouterModule,
        AutocompleteLibModule,
        MatAutocompleteModule,
        NgxPaginationModule,
        GoogleMapsModule
    ],
    declarations: [
        InsuranceLogoComponent,
        BeneficiaryDashboardNavigationComponent,
        RetireeDashboardNavigationComponent, 
        SafePipe, 
        UrlPipe, 
        SaveMyQuoteComponent, 
        EmailMyQuoteComponent, 
        HeaderBComponent, 
        ReadyToTalkComponent, 
        TestimonialsComponent, 
        NewToMedicareComponent, 
        ApplyOnThePhoneComponent, 
        ApplyOnlineModalComponent, 
        CompanyProfileComponent, 
        EstimateDrugCostsComponent, 
        SpeakWithACounselorComponent, 
        MedicareServicesFormComponent, 
        AutoInsuranceFormComponent, 
        HomeownersInsuranceFormComponent, 
        RentersInsuranceFormComponent, 
        ResultNotFoundComponent, 
        ConsumerReviewsComponent, 
        AdditionalInsightsComponent,
        PlansDrugCostsComponent, 
        PharmaciesOrderTableComponent, 
        LoadingComponent, 
        EnjoyableOptionsComponent, 
        ReadyToTalkLandingComponent, 
        SliderHeaderComponent, 
        AddYourDrugsComponent, 
        ViewAllRatingsComponent, 
        ViewAllRatingsV2Component,
        MoreInsightsComponent, 
        ApplyOnlineComponent, 
        SubmitAReviewComponent,
        SubmitAReviewNewComponent, 
        InsuranceCoverageBoxComponent, 
        StarRatingComponent,
        DobComponent,
        DrugNotesFormComponent, 
        CommonDrugsComponent, 
        CommonStep1Component, 
        CommonStep2Component, 
        CommonStep3Component, 
        CommonStep4Component, 
        CommonYourDrugListComponent, 
        CommonLoginFormComponent, 
        CommonSignupFormComponent, 
        YourFullRxProfileComponent, 
        MapdVersion1, 
        MedigapVersion1, 
        MapdVersion2, 
        MedigapVersion2, 
        MedigapVersion3, 
        PdpVersion1, 
        PdpVersion2, 
        DentalVersion1, 
        DentalVersion2, 
        RequestPlanDocumentComponent, 
        OnlineApplicationMapdVersion1, 
        ImportantInformationComponent, 
        AcknowledgementComponent, 
        PleaseReadAndSignComponent, 
        BasicInfoComponent, 
        ContactInfoComponent, 
        MedicareInfoComponent,
        CoverageInfoComponent, 
        PolicyInfoComponent, 
        PayingYourPlanPremiumComponent, 
        PrimaryCareProviderSelectionComponent, 
        AttestationOfEligibilityForAnEnrollmentPeriodComponent, 
        FinishPreferencesComponent, 
        CommonDrugsV2Component, 
        CommonStep1V2Component, 
        AuthorizedRepresentativeQuestionComponent, 
        AuthorizedRepresentativeQuestionSlide1Component, 
        AuthorizedRepresentativeQuestionSlide2Component, 
        AuthorizedRepresentativeQuestionSlide3Component, 
        OnlineApplicationMapdVersion2,
        OnlineApplicationMedigapVersion1,
        OnlineApplicationMapdVersion2Screen1, 
        OnlineApplicationMapdVersion2Screen2, 
        OnlineApplicationMapdVersion2Screen3, 
        OnlineApplicationMapdVersion2Screen4, 
        OnlineApplicationMapdVersion2Screen5, 
        OnlineApplicationMapdVersion2Screen6,
        OnlineApplicationMapdVersion2Screen7,
        OnlineApplicationMapdVersion2Screen8,
        OnlineApplicationMapdVersion2Screen9,
        OnlineApplicationMapdVersion2Screen10,
        OnlineApplicationMapdVersion2Screen11,
        PetInsuranceFormComponent,
        ProviderStatusComponent,
        SharePlanComponent,
        ProviderDetailComponent,
        OutOfPocketComponent,
        MapdCustomFilterComponent,
        MapdFilterResultModalComponent,
        ShareFormComponent,
        OnlineApplicationMedigapVersion1Screen1,
        OnlineApplicationMedigapVersion1Screen2,
        OnlineApplicationMedigapVersion1Screen3,
        OnlineApplicationMedigapVersion1Screen4,
        OnlineApplicationMedigapVersion1Screen5,
        OnlineApplicationMedigapVersion1Screen6,
        OnlineApplicationMedigapVersion1Screen7,
        OnlineApplicationMedigapVersion1Screen8,
        YourDrugsComponent,
        SearchDrugsComponent,
        OnlineApplicationMedigapVersion1Screen9,
        OnlineApplicationMedigapVersion1Screen10,
        OnlineApplicationMedigapVersion1Screen11,
        OnlineApplicationMedigapVersion1Screen12,
        OnlineApplicationMedigapVersion1Screen13,
        OnlineApplicationMedigapVersion1Screen14,
        OnlineApplicationMedigapVersion1Screen15,
        OnlineApplicationMedigapVersion1Screen16,
        OnlineApplicationMedigapVersion1Screen17,
        WysiwygComponent,
        ProviderNetworkStatusComponent,
        CustomPaginationComponent,
        MapdAdditionalInsightsComponent,
        CarrierPlanDetailsComponent,
        PlanRelatedDatesTimelineComponent,
        PopularityEnrollmentComponent,
        TaptapPlanCarrierInsightsComponent,
        CustomLottiePlayerComponent,
        FirstVariationComponent,
        SecondVariationComponent,
        ThirdVariationComponent,
        MapdStarRatingComponent,
        GoogleMapV14Component
        ],
    exports: [
        CommonModule,
        FormsModule,
        InsuranceLogoComponent,
        BeneficiaryDashboardNavigationComponent,
        RetireeDashboardNavigationComponent, 
        SafePipe, 
        UrlPipe, 
        SaveMyQuoteComponent, 
        EmailMyQuoteComponent, 
        HeaderBComponent, 
        ReadyToTalkComponent, 
        TestimonialsComponent, 
        NewToMedicareComponent, 
        MaterialModule, 
        // AgmCoreModule, 
        RouterModule, 
        RecaptchaModule, 
        ApplyOnThePhoneComponent, 
        CompanyProfileComponent, 
        ApplyOnlineModalComponent, 
        EstimateDrugCostsComponent, 
        SpeakWithACounselorComponent, 
        MedicareServicesFormComponent, 
        AutoInsuranceFormComponent, 
        HomeownersInsuranceFormComponent, 
        RentersInsuranceFormComponent, 
        ResultNotFoundComponent, 
        ConsumerReviewsComponent, 
        AdditionalInsightsComponent,
        PlansDrugCostsComponent, 
        PharmaciesOrderTableComponent, 
        LoadingComponent, 
        EnjoyableOptionsComponent,
        ReadyToTalkLandingComponent, 
        SliderHeaderComponent, 
        AddYourDrugsComponent, 
        ViewAllRatingsComponent, 
        ViewAllRatingsV2Component,
        MoreInsightsComponent, 
        ApplyOnlineComponent, 
        SubmitAReviewComponent, 
        SubmitAReviewNewComponent, 
        InsuranceCoverageBoxComponent, 
        StarRatingComponent,
        DobComponent, 
        DrugNotesFormComponent, 
        CommonDrugsComponent, 
        CommonStep1Component, 
        CommonStep2Component, 
        CommonStep3Component, 
        CommonStep4Component, 
        CommonYourDrugListComponent, 
        CommonLoginFormComponent, 
        CommonSignupFormComponent, 
        YourFullRxProfileComponent, 
        MapdVersion1, 
        MapdVersion2, 
        MedigapVersion1, 
        MedigapVersion2, 
        MedigapVersion3,
        PdpVersion1, 
        PdpVersion2, 
        DentalVersion1, 
        DentalVersion2, 
        RequestPlanDocumentComponent, 
        OnlineApplicationMapdVersion1, 
        ImportantInformationComponent, 
        AcknowledgementComponent, 
        PleaseReadAndSignComponent, 
        BasicInfoComponent, 
        ContactInfoComponent, 
        MedicareInfoComponent, 
        CoverageInfoComponent, 
        PolicyInfoComponent, 
        PayingYourPlanPremiumComponent, 
        PrimaryCareProviderSelectionComponent, 
        AttestationOfEligibilityForAnEnrollmentPeriodComponent, 
        FinishPreferencesComponent, 
        CommonDrugsV2Component,
        CommonStep1V2Component, 
        AuthorizedRepresentativeQuestionComponent, 
        AuthorizedRepresentativeQuestionSlide1Component,
        AuthorizedRepresentativeQuestionSlide2Component,
        AuthorizedRepresentativeQuestionSlide3Component, 
        OnlineApplicationMapdVersion2,
        OnlineApplicationMedigapVersion1,
        OnlineApplicationMapdVersion2Screen1, 
        OnlineApplicationMapdVersion2Screen2, 
        OnlineApplicationMapdVersion2Screen3, 
        OnlineApplicationMapdVersion2Screen4, 
        OnlineApplicationMapdVersion2Screen5, 
        OnlineApplicationMapdVersion2Screen6,
        OnlineApplicationMapdVersion2Screen7,
        OnlineApplicationMapdVersion2Screen8,
        OnlineApplicationMapdVersion2Screen9,
        OnlineApplicationMapdVersion2Screen10,
        OnlineApplicationMapdVersion2Screen11,
        PetInsuranceFormComponent,
        ProviderStatusComponent,
        MapdCustomFilterComponent,
        MapdFilterResultModalComponent,
        ShareFormComponent,
        OnlineApplicationMedigapVersion1Screen1,
        OnlineApplicationMedigapVersion1Screen2,
        OnlineApplicationMedigapVersion1Screen3,
        OnlineApplicationMedigapVersion1Screen4,
        OnlineApplicationMedigapVersion1Screen5,
        OnlineApplicationMedigapVersion1Screen6,
        OnlineApplicationMedigapVersion1Screen7,
        OnlineApplicationMedigapVersion1Screen8,
        YourDrugsComponent,
        SearchDrugsComponent,
        OnlineApplicationMedigapVersion1Screen9,
        OnlineApplicationMedigapVersion1Screen10,
        OnlineApplicationMedigapVersion1Screen11,
        OnlineApplicationMedigapVersion1Screen12,
        OnlineApplicationMedigapVersion1Screen13,
        OnlineApplicationMedigapVersion1Screen14,
        OnlineApplicationMedigapVersion1Screen15,
        OnlineApplicationMedigapVersion1Screen16,
        OnlineApplicationMedigapVersion1Screen17,
        WysiwygComponent,
        ProviderNetworkStatusComponent,
        NgxPaginationModule,
        CustomPaginationComponent,
        MapdAdditionalInsightsComponent,
        CarrierPlanDetailsComponent,
        PlanRelatedDatesTimelineComponent,
        PopularityEnrollmentComponent,
        TaptapPlanCarrierInsightsComponent,
        CustomLottiePlayerComponent,
        FirstVariationComponent,
        SecondVariationComponent,
        ThirdVariationComponent,
        MapdStarRatingComponent,
        GoogleMapV14Component
    ],
    providers: [
        {
          provide: RECAPTCHA_SETTINGS,
          useValue: {
            siteKey: environment.recaptcha.siteKey,
          } as RecaptchaSettings,
        },
    ]
})
export class SharedModule {
}
