import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-part-d-drug-coverage',
  templateUrl: './part-d-drug-coverage.component.html',
  styleUrls: ['./part-d-drug-coverage.component.scss']
})
export class PartDDrugCoverageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
