import {Injectable} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';    
import { filter } from 'rxjs/operators';


@Injectable()
export class FooterService {
    visible = true;
    footerTop = true;
    activatedUrl: any = "";
    scriptUrl:any;
    noMarginTop:boolean;
    fullPrimaryFooter:boolean = true;
   

    constructor(public router: Router) { // get router url
        router.events.pipe(
        filter(e => e instanceof NavigationEnd)
        ).subscribe((e: NavigationEnd) => {
            
            this.activatedUrl = e.url;
            this.scriptUrl = this.activatedUrl.substring(this.activatedUrl.lastIndexOf('/') + 1);
            
            this.visible = true;
            this.footerTop = true;
            this.noMarginTop = false;

            //remove footer top and Margin Top on specific router url
            if(this.scriptUrl == 'login'){
                this.footerTop = false;
            }

            if(this.scriptUrl == 'registration'){
                this.footerTop = false;
            }

            if(this.scriptUrl == 'update-password' || this.scriptUrl == 'forgot-password'){
                this.footerTop = false;
            }

            if(this.scriptUrl == 'partnership-opportunities'){
                // this.footerTop = false;
                this.fullPrimaryFooter = true;
            }

            if(this.scriptUrl == 'become-a-contributor'){
                // this.footerTop = false;
                this.fullPrimaryFooter = true;
            }

            if(this.scriptUrl == 'contact'){
                this.noMarginTop = true;
            }

            if(this.scriptUrl == 'apply-online'){
                this.noMarginTop = true;
            }

            if(this.scriptUrl == 'life-insurance'){
                this.noMarginTop = true;
            }

            if(this.scriptUrl == 'term'){
                this.noMarginTop = true;
            }

            if(this.scriptUrl == 'medicare-insurance'){
                this.noMarginTop = true;
            }

            if(this.scriptUrl == 'help-me-decide'){
                this.noMarginTop = true;
            }

            if(this.scriptUrl == 'plan-letter-table'){
                this.noMarginTop = true;
            }

            if(  
                this.scriptUrl == 'boston'               ||
                this.scriptUrl == 'hartford-connecticut' ||
                this.scriptUrl == 'manhattan'            ||
                this.scriptUrl == 'miami'                ||
                this.scriptUrl == 'philadelphia'         ||
                this.scriptUrl == 'phoenix-arizona'      ||
                this.scriptUrl == 'st-louis-missouri'    ||
                this.scriptUrl == 'texas'                ||
                this.scriptUrl == 'wilmington-delaware'  ||
                this.scriptUrl == 'denver'
            ){
                this.noMarginTop = true;
            }

            if( this.scriptUrl == 'retiree-dashboard'  || 
                this.scriptUrl == 'my-general-info'    ||
                this.scriptUrl == 'my-coverage-info'   ||
                this.scriptUrl == 'my-health-info'     ||
                this.scriptUrl == 'opportunities'      ||
                this.scriptUrl == 'my-documents'       ||
                this.scriptUrl == 'my-preferences'     ||
                this.scriptUrl == 'my-community' )
            {
                this.fullPrimaryFooter = false;
            }

            if(this.activatedUrl.includes('apply-online?')){
                this.noMarginTop = true;
            }
            
        });
    }


    hide() {
        this.visible = false;
    }

    show() {
        this.visible = true;
    }

    toggle() {
        this.visible = !this.visible;
    }

}
