<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-7 d-flex align-items-center">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">            
            TapTap Testimonials
					</h1>
					<p class="banner-sub-title">Happy, Supported, Organized Medicare Beneficiaries is Our Mission. 
            Here are just a few reviews from beneficiaries that TapTap has served.</p>
				</div>
			</div>
			<div class="col-md-5 px-0 banner-image-wrapper">
				<img src="assets/img/common/3-old-woman-standing.png" class="Testimonial Banner Image">
			</div>
		</div>
	</div>
</div>
<!--END BANNER-->

<!-- input quote section -->
<div class="container">
  <h5 class="text-center mt-4 mb-4 fWeight-600">We know that having a healthcare coverage - and support system - that you like, understand, and are confident in, can create significant value and peace of mind in your life.</h5>
  <h5 class="text-center mt-4 mb-4 fWeight-600">Our Goal and dedicated mission is to make the Medicare part of your life as cost-efficient, convenient, and organized as can be.</h5>
  <div class="testimonial-outside-box-wrapper">
    <div class="testimonial-outside-box">
      <div class="testimonial-box custom-box">
        <i class="fa fa-quote-left"></i>
        <p>
          "I was paying nearly $200 every month for my Medicare supplement insurance. I liked my coverage but the price had gotten out of control. Thankfully I got connected with TapTap Medicare and one of their counselors and they recommended switching to Plan G. I got my rate down $80 per month which let me breath! I am very thankful that the TapTap Medicare team helped me when I needed it!"
        </p>
      </div>
      <div class="testimonial-author-section">
        <div class="testimonial-author-pointer"></div>
        <div class="testimonial-author-img">
          <img src="assets/img/common/person-icon.png">
        </div>
        <div class="testimonial-author-name d-flex flex-column">
          <span>Elaine Whittaker, Age 73</span>
          <span>Plan F to Plan G, Saved $936 a Year</span>
        </div>
      </div>
    </div>
    <div class="testimonial-outside-box">
      <div class="testimonial-box custom-box">
        <i class="fa fa-quote-left"></i>
        <p>
          "I was so frustrated and stressed by the whole Medicare process before I met TapTap Medicare and experienced their way of helping people getting onto Medicare. I went from confused and overwhelmed to clear and comforted. Medicare actually started to make sense. I understood my options and I became clear and confident that I was choosing the right coverage setup for me, and I also knew why that coverage was right for me."
        </p>
      </div>
      <div class="testimonial-author-section">
        <div class="testimonial-author-pointer"></div>
        <div class="testimonial-author-img">
          <img src="assets/img/common/person-icon.png">
        </div>
        <div class="testimonial-author-name d-flex flex-column">
          <span>Julia Girardi, Age 65</span>
          <span>New to Medicare, Full Medicare Review</span>
        </div>
      </div>
    </div>
    <div class="testimonial-outside-box">
      <div class="testimonial-box custom-box">
        <i class="fa fa-quote-left"></i>
        <p>
          I got on Medicare 6 years ago and had my Medicare Supplement with the same company ever since. However, after price increases each year I was paying more than $59 a month more than when I first started the plan. I loved my plan and really wanted to keep it, Plan G was easy to understand and made healthcare easy. The cost was frustrating me though. Fortunately, after working with TapTap Medicare I was able to keep Plan G while saving almost $65 a month.
        </p>
      </div>
      <div class="testimonial-author-section">
        <div class="testimonial-author-pointer"></div>
        <div class="testimonial-author-img">
          <img src="assets/img/common/person-icon.png">
        </div>
        <div class="testimonial-author-name d-flex flex-column">
          <span>Alice Strough, Age 72</span>
          <span>Plan G: Saved $771 a Year</span>
        </div>
      </div>
    </div>
    <div class="testimonial-outside-box">
      <div class="testimonial-box custom-box">
        <i class="fa fa-quote-left"></i>
        <p>
          My wife Mary and I both had Medicare Supplement Plan F with the insurance company I had my life insurance with before I retired. We loved the coverage and liked the company we were dealing with, but the cost was getting high. On a fixed income, it was getting tough. We finally decided to get some information and take action and found out that we could keep the exact same coverage we had for over $100 less each month. We were hesitant at first, but after getting the specifics and learning what was involved, it turned out to be one of the easier insurance experiences we’ve had.
        </p>
      </div>
      <div class="testimonial-author-section">
        <div class="testimonial-author-pointer"></div>
        <div class="testimonial-author-img">
          <img src="assets/img/common/person-icon.png">
        </div>
        <div class="testimonial-author-name d-flex flex-column">
          <span>Bob and Mary Lansing, Ages 74 and 71</span>
          <span>Plan F: Saved a Total of $1309 a Year</span>
        </div>
      </div>
    </div>
    <div class="testimonial-outside-box">
      <div class="testimonial-box custom-box">
        <i class="fa fa-quote-left"></i>
        <p>
          I never liked dealing with insurance or insurance companies. However, I knew that Medicare Supplement insurance was “Standardized” by the government. Once I found out I could get my exact same plan for $500 less a year, I did what I had to to not overpay. I don’t want to pay any more than I have to for insurance! I already pay enough!
        </p>
      </div>
      <div class="testimonial-author-section">
        <div class="testimonial-author-pointer"></div>
        <div class="testimonial-author-img">
          <img src="assets/img/common/person-icon.png">
        </div>
        <div class="testimonial-author-name d-flex flex-column">
          <span>Jerry Turnos, Age 69</span>
          <span>Plan N: Saved $503 a Year</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end input quote section -->


<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>