import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-application-medigap-version1-screen8',
  templateUrl: './screen8.component.html',
  styleUrls: ['./screen8.component.scss']
})
export class Screen8Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
