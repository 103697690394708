<div class="notes-modal">
  <ng-container *ngIf="!universalMode">
    <div id="noteSlider" class="carousel slide" data-interval="false">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="bg-primary px-5 py-4">
            <div *ngIf="noteSaved || noteDeleted" class="alert alert-success alert-dismissible fade show" role="alert">
              <h5 class="alert-heading">Success!</h5>
              <p>{{
                noteSaved ? 'Your Note Was Successfully Created!' : 
                noteDeleted ? 'Successfully Deleted The Note!' : 'Your Notes Are Now Updated!'
              }}</p>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closePrompt()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h4 class="text-center col-orange mb-3">{{ notesName }}</h4>
            <h5 class="text-light sec-title">Create New Notes</h5>
            <div class="notes-creator-wrapper">
              <div id="notesCreator"></div>
            </div>
            <button class="btn btn-first btn-small mx-auto mt-2" (click)="saveNote()" [disabled]="noteSaving">
              <span *ngIf="noteSaving" class="spinner-border spinner-border-sm text-light mr-2" role="status" aria-hidden="true"></span>
              {{ noteSaving ? 'Saving...' : 'Save' }}
            </button>
          </div>
          <div class="pb-4 px-5 pt-3">
            {{ displayExistingNotes() }}
            <h5 class="sec-title">My Saved Notes</h5>
            <p *ngIf="!notesArray.length" class="text-center p-3 font-weight-normal text-secondary">No Saved Notes Yet...</p>
            <ng-container *ngIf="!noteDeleting && !noteSaving">
              <ng-container *ngFor="let i = index; let note of notesArray;">
                <div class="notes-display-wrapper d-flex my-2">
                  <div [id]="'editorView_'+i"></div>
                  <button (click)="editExistingNote(i)" class="edit-note mt-1 ml-2" data-target="#noteSlider"
                    data-slide="next"><i class="fas fa-pen"></i></button>
                  <button class="delete-note mt-1 ml-2" type="button" data-toggle="collapse"
                    [attr.data-target]="'#deleteConfirm_'+i" aria-expanded="false"
                    [attr.aria-controls]="'deleteConfirm_'+i"><i class="fas fa-trash"></i></button>
                </div>
                <div class="collapse" [id]="'deleteConfirm_'+i">
                  <div class="card card-body mb-5">
                    <p class="text-center font-weight-bold">
                      Are you sure that you want to delete this note?
                    </p>
                    <div class="d-flex align-items-center justify-content-center">
                      <button (click)="deleteNote(i)" class="btn btn-danger btn-xs font-weight-bold rounded-pill mx-2">
                        Yes
                      </button>
                      <button type="button" data-toggle="collapse" [attr.data-target]="'#deleteConfirm_'+i"
                        aria-expanded="false" [attr.aria-controls]="'deleteConfirm_'+i"
                        class="btn btn-secondary btn-xs font-weight-bold rounded-pill mx-2">
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="noteDeleting || noteSaving">
              <div class="d-flex justify-content-center align-items-center py-4">
                <div [class]="noteSaving ? 'text-primary' : noteDeleting ? 'text-danger' : ''" class="spinner-grow mr-2" role="status">
                  <span class="sr-only">
                    {{
                      noteSaving ? 'Saving...' : 
                      noteDeleting ? 'Deleting...' : 'Loading...'
                    }}
                  </span>
                </div>
                <strong class="text-secondary">
                  {{
                    noteSaving ? 'Adding To Your Notes...' : 
                    noteDeleting ? 'Deleting...' : 'Loading...'
                  }}
                </strong>
              </div>
            </ng-container>
          </div>
  
        </div>
        <div class="carousel-item">
          <div class="p-5">
            <div *ngIf="noteEdited" class="alert alert-success alert-dismissible fade show" role="alert">
              <h5 class="alert-heading">Success!</h5>
              <p>You've Successfully Updated Your Notes!</p>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closePrompt()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h5 class="sec-title">Edit Your Notes</h5>
            <div class="notes-display-wrapper">
              <div id="notesEditor"></div>
            </div>
            <span class="help-info">Click on the "SAVE CHANGES" button when you're done editing to update.</span>
            <div class="d-flex justify-content-center mt-3">
              <button class="btn btn-third btn-small mx-2" data-target="#noteSlider" data-slide="prev" [disabled]="noteEditing" (click)="closePrompt()">
                Cancel
              </button>
              <button class="btn btn-first btn-small mx-2" [disabled]="noteEditing" (click)="editNote()">
                <span *ngIf="noteEditing" class="spinner-border spinner-border-sm text-light mr-2" role="status" aria-hidden="true"></span>
                {{ noteEditing ? 'Updating...' : 'Update' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div [class.d-none]="!createNoteModal">
    <div class="bg-primary px-5 py-4">
      <div *ngIf="notesModalError" class="alert alert-danger alert-dismissible fade show" role="alert">
        <h5 class="alert-heading">Error!</h5>
        <p>We've encountered a problem creating your notes. Please make sure that all the fields are not empty.</p>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closePrompt()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="my-3">
        <h5 class="text-center col-orange">Create a New Note</h5>
        <h6 class="text-light fWeight-500">Enter Label</h6>
        <input type="text" [(ngModel)]="notesLabelField" class="form-control">
      </div>
      <div class="my-3">
        <h6 class="text-light fWeight-500">Compose Notes</h6>
        <div class="notes-creator-wrapper">
          <div id="createNoteModal"></div>
        </div>
      </div>
      <button class="btn btn-first btn-small mx-auto mt-2" (click)="createNewNotes()" [disabled]="notesModalLoading">
        <span *ngIf="notesModalLoading" class="spinner-border spinner-border-sm text-light mr-2" role="status" aria-hidden="true"></span>
        {{ noteSaving ? 'Saving...' : 'Save' }}
      </button>
    </div>
  </div>

  <div [class.d-none]="!editNoteModal">
    <div class="px-5 py-4">
      <div *ngIf="noteEdited" class="alert alert-success alert-dismissible fade show" role="alert">
        <h5 class="alert-heading">Success!</h5>
        <p>You've Successfully Updated Your Notes!</p>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closePrompt()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="my-3">
        <h5 class="text-center">Create a New Note</h5>
        <h6 class="dblue fWeight-500">Edit Label</h6>
        <input type="text" [(ngModel)]="notesLabelEditField" class="form-control">
      </div>
      <div class="my-3">
        <h6 class="dblue fWeight-500">Edit Notes</h6>
        <div class="notes-display-wrapper">
          <div id="editNoteModal"></div>
        </div>
      </div>
      <span class="help-info">Click on the "SAVE CHANGES" button when you're done editing to update.</span>
      <div class="d-flex justify-content-center mt-3">
        <button class="btn btn-first btn-small mx-2" [disabled]="notesModalLoading" (click)="editExistingNotes()">
          <span *ngIf="notesModalLoading" class="spinner-border spinner-border-sm text-light mr-2" role="status" aria-hidden="true"></span>
          {{ notesModalLoading ? 'Updating...' : 'Save Changes' }}
        </button>
      </div>
    </div>
  </div>
</div>