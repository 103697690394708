import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-testimonials-page',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsPageComponent implements OnInit {
  fullPrimaryHeader:boolean = true;
  isLoggedInUser:any;
  constructor(private _auth: AuthService ) { }

  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if(this.isLoggedInUser){
       this.fullPrimaryHeader = false;
    }
  }

}
