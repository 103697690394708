import {Component, OnInit, ElementRef, ViewChild, Inject, Optional } from '@angular/core';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {APIService} from '../services/api.service';
import {Router, ActivatedRoute } from '@angular/router';
import {AuthService} from "../services/auth.service";


@Component({
  selector: 'app-email-verificaton',
  templateUrl: './email-verificaton.component.html',
  styleUrls: ['./email-verificaton.component.scss']
})
export class EmailVerificatonComponent implements OnInit {

  isValidHash: boolean = false;
  showLoading: boolean = false;
  loadingHash: boolean = false;
  result: any;
  token: any = '';
  type: number = 1;
  public formGroup!: FormGroup;
  currentUser: any;

  constructor(private _api: APIService,  private route: ActivatedRoute, 
      private _router: Router, 
      private _authService: AuthService, private formBuilder: FormBuilder) {
  }
  ngOnInit() {
    
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.type = params['type'];
      this.checkIfValidRoute();
    });

    this.formGroup = this.formBuilder.group({
      password : ['', Validators.required],
      confirm_password : ['', Validators.required],
      token: [this.token]
    });
  }


  checkIfValidRoute() {
    this._api.callAPIEndpoint('/api/v1/email-verificaton', {
      'token' : this.token
    }).subscribe(
      (data: any) => {
        if (data['results'] && typeof data['results'] !== null) {
          this.isValidHash = true;
          this.result = data['results'];
        }
        this.loadingHash = true;
      })
  }

  closeModal() {
  }

  onSubmit() {
    console.log('this.grom', this.formGroup.value);


    if (this.formGroup.valid) {

      try {
        this._api.callAPIEndpoint('/api/v1/password-reset', {
          ...this.formGroup.value,
          'email': this.result.user.email
        }).subscribe(
          (data: any) => {
            alert('Password Successfully saved.');
            window.location.href = '/login';
          })
      } catch (e) {
        console.log('error', e);
      }
        
    } else {
      alert('Invalid passcode');
    }
  }
}
