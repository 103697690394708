import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {APIService} from '../../../services/api.service';

@Component({
    selector: 'app-estimate-drug-costs',
    templateUrl: './estimate-drug-costs.component.html',
    styleUrls: ['./estimate-drug-costs.component.scss']
})
export class EstimateDrugCostsComponent implements OnInit {

    isShowView: boolean = true;
    @Input() zipcode:any;

    drugsArray: Array<any> = [
        {
            'name': '',
            'dosage': '',
        },
    ];

    newAttribute: any = {};
    firstField = true;
    firstFieldName = 'First Item name';
    isEditItems: boolean;


    constructor(private route: ActivatedRoute, private _router: Router, private _api: APIService) {
    }

    ngOnInit() {
    }

    onSearch($event) {
        //console.log(this.drugsArray);
        this.saveDrugs();
        Swal.close();
        $event.preventDefault();
        this._router.navigate(['./insurance-marketplace/medicare-drug-coverage/results/zipcode-' + this.zipcode]);
    }

    addFieldValue(index) {

        this.newAttribute = {
            'name': '',
            'dosage': '',
        };
        this.drugsArray.push(this.newAttribute);
        this.newAttribute = {};
    }


    saveDrugs() {
        this._api.callAPIEndpoint('/api/v1/medicare-drug-coverage/save-drugs', {
        'drugs': this.drugsArray,
    })
        .subscribe(
            (data: any) => {
                /*if (data.code == 1) {
                    this.loading = false;
                    this.results = data.details;
                } else {
                    this.loading = false;
                    console.log('no results found');
                }*/
            },
            (err) => {
                //this.loading = false;
                console.log('no results found');
            }
        );

    }

}
