import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  set(key: string, value: any) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  get(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        const result = JSON.parse(data);
        return result;
      } catch (e) {
        return data;
      }
    }
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
