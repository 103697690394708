import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
import {MatStepper} from "@angular/material/stepper";
import {APIService} from '../../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-online-application-mapd-version1',
  templateUrl: './version1.component.html',
  styleUrls: ['./version1.component.scss']
})

export class Version1Component implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('appDOB') appDOB: ElementRef;

  isSpouseActive:boolean = false;

  currentMaritalStatus = 0;

  currentSpouseOnMedicare = 0;

  isSpouseOnMedicareActive:boolean = false;

  currentMemberOnMedicare = 0;

  dateOfBirth: string;

  memberDateOfBirth: string;

  memberDateofBirth2: string;

  partAEffectiveDate: string = '03-01-2016';

  partBEffectiveDate: string = '03-01-2016';

  dateFieldType: string;

  dateFieldHeading: string;

  gender:string;

  spouseGender:any;

  householdGender:any;

  customRadio: any;

  mailingAddress: any = true;

  currentStepperIndex: number = 0;

  myBaseInfoStepperSelectedIndex:number = 0;
  isShowMedCardPartA:boolean = true;
  isShowMedCardPartB:boolean = true;
  medCardName:any = 'John L Smith';
  medCardNumber1:any = '';
  medCardNumber2:any = '';
  medCardNumber3:any = '';
  _token = '';
  details:any;
  details2: any = {
    customer_info:null
  }

  showOtherHousehold:any = false;

  paramsObject: any;

  planName:any;

  planPremium: any;

  firstName:any;

  middleName:any;

  lastName:any;

  constructor(private _api: APIService, private route: ActivatedRoute) { 
    this._token = window.localStorage.getItem('auth_token');
    this.getUrlParams();
  }

  ngOnInit() {
    this.getUserInfo();
  }

  getUrlParams(){
    this.route.queryParamMap
      .subscribe((params) => {
          this.paramsObject = {...params };
        }
      );
      this.planName =  this.paramsObject['params'].plan;
      this.planPremium = this.paramsObject['params'].premium;
      this.firstName = this.paramsObject['params'].firstName;
      this.middleName = this.paramsObject['params'].middleName;
      this.lastName = this.paramsObject['params'].lastName;
  }

  onClick_maritalStatus(event){
    this.details.customer_info.marital_status = event.type;
    switch(event.number){
      case 1:
        this.onReset_maritalStatus();
        this.isSpouseActive = true;
        this.currentMaritalStatus = 1;
        break;

      case 2:
        this.onReset_maritalStatus();
        this.currentMaritalStatus = 2;
        break;

      case 3:
        this.onReset_maritalStatus();
        this.isSpouseActive = true;
        this.currentMaritalStatus = 3;  
        break;

      case 4:
        this.onReset_maritalStatus();
        this.currentMaritalStatus = 4;  
        break;

      case 5:
        this.onReset_maritalStatus();
        this.currentMaritalStatus = 5;  
        break;

      case 6:
        this.onReset_maritalStatus();
        this.currentMaritalStatus = 6; 
        break;
    }
  }

  onReset_maritalStatus(){
    this.isSpouseActive = false;
    this.currentMaritalStatus = 0;
    this.spouseGender = null;
    this.gender = null;
  }

  onClick_spouseOnMedicare(type){
    switch(type){
      case 1:
        this.onReset_spouseOnMedicare();
        this.currentSpouseOnMedicare = 1;
        break;

      case 2:
        this.onReset_spouseOnMedicare();
        this.currentSpouseOnMedicare = 2;
        break;

      case 3:
        this.onReset_spouseOnMedicare();
        this.currentSpouseOnMedicare = 3;  
        break;
    }
  }

  onReset_spouseOnMedicare(){
    this.currentSpouseOnMedicare = 0;
  }


  onClick_memberOnMedicare(type){
    switch(type){
      case 1:
        this.onReset_memberOnMedicare();
        this.currentMemberOnMedicare = 1;
        break;

      case 2:
        this.onReset_memberOnMedicare();
        this.currentMemberOnMedicare = 2;
        break;

      case 3:
        this.onReset_memberOnMedicare();
        this.currentMemberOnMedicare = 3;  
        break;
    }
  }

  onReset_memberOnMedicare(){
    this.currentMemberOnMedicare = 0;
  }

  onClickDob(event){
    const calendar = this.appDOB.nativeElement;
    this.dateFieldType = event.type;
    this.dateFieldHeading = event.heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
        // onClose: () => {
        //     if(!this.isClickDobModalGenerate){
        //         this.resetAgeDropdownValue="All Ages";
        //         this.allAgeSelectedText = "Other Ages"
        //         this.fieldValidity.age = false;
        //         this.ageAll = false;
        //         this.isDobCalendarDateSelected = false;
        //     }
        //     this.dobCalendarStep = 1;   
        // }
    })
  }

  onReceiveDate(dob: any){

    console.log('bunny',dob);
    const idate = new Date(dob.date);
    const fdate = this.formatDate(idate);
    if(dob.type == 'normal'){
      this.dateOfBirth = fdate;
      this.details.customer_info.date_of_birth = dob.date;
    }

    if(dob.type == 'member2'){
      this.memberDateofBirth2 = fdate; 
    }

    if(dob.type == 'partAEffectiveDate'){
      this.partAEffectiveDate = fdate;
      this.details.customer_info.part_a_effective_date = this.partAEffectiveDate;
    }

    if(dob.type == 'partBEffectiveDate'){
      this.partBEffectiveDate = fdate;
      this.details.customer_info.part_b_effective_date = this.partBEffectiveDate;
    }

    Swal.close();

    
  }

  onClick_gender(type){
    this.details.customer_info.gender = type;
  }

  onClick_spouseGender(type){
    this.spouseGender = type;
  }

  onClick_householdGender(type){
    this.householdGender = type;
  }

  selectionChange(event){
    this.currentStepperIndex =  event.selectedIndex;
  }

  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => 'number';
  }

  onClick_MyBaseInfoNav(event){
    setTimeout(()=> {
      this.myBaseInfoStepperSelectedIndex = event;
    }, 0);    
  }

  formatDate(date) {
    return (this.padTo2Digits(date.getMonth()+1)) + '-' + this.padTo2Digits(date.getDate()) + '-' + date.getFullYear();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  onCheckedDontHavePartA(event){
    if(event.target.checked){
      this.isShowMedCardPartA = true;
    }else{
      this.isShowMedCardPartA = false;
    }

    this.details.customer_info.has_no_part_a = this.isShowMedCardPartA;
  }
  
  onCheckedDontHavePartB(event){
    if(event.target.checked){
      this.isShowMedCardPartB = true;
    }else{
      this.isShowMedCardPartB = false;
    }
    this.details.customer_info.has_no_part_b = this.isShowMedCardPartB;
  }

  getUserInfo(){
    this._api.callAPIEndpointAuthenticated('/api/user/current',this._token, {
      
    })
      .subscribe(
          (result: any) => {
            
            if(result.code == "1") {
              this.details = result.details;
              let card_number = this.details.customer_info.medicare_card_number;

              if(card_number){
                var res = card_number.split("-");
                this.medCardNumber1 = res[0];
                this.medCardNumber2 = res[1];
                this.medCardNumber3 = res[2];
              }else{
                this.medCardNumber1 = '';
                this.medCardNumber2 = '';
                this.medCardNumber3 = '';
              }

              console.log('BALIW SIYA', res);
              
            }else{
              this.details = this.details2;
            }

            console.log('BATAS NATIN ITO HUHUHU',this.details);
            
           
          },
          (err) => {
            console.log(err);
          }
      );
  }

  clickSaveCurrentCustomerInfo() {
    this.details.customer_info.medicare_card_number = this.medCardNumber1+"-"+this.medCardNumber2+"-"+this.medCardNumber3;
    console.log(this.details);
  }

  submitCustomerInfo() {
    this.details.customer_info.medicare_card_number = this.medCardNumber1+"-"+this.medCardNumber2+"-"+this.medCardNumber3;
    this._api.callAPIEndpointAuthenticated('/api/user/update-customer-info',this._token, {"details": this.details
    })
      .subscribe(
          (result: any) => {
            
            if(result.code == "1") {
              console.log(result);
            }
           
          },
          (err) => {
            console.log(err);
          }
      );
  }

  onCheckedMailingAddress(event){
    if(event.target.checked){
      this.details.customer_info.is_mailing_address = true;
    }else{
      this.details.customer_info.is_mailing_address = false;
    }
  }
}
