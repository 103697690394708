import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APIService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class BennyDashService {

  private mobileSidebarToggleSource = new BehaviorSubject(false);

  private positiveQuoteAPI = "https://goquotes-api.herokuapp.com/api/v1/all/quotes";
  private gratitudeQuoteAPI = "https://goquotes-api.herokuapp.com/api/v1/all/quotes"; // Same value but different variables for future purposes.

  mobileSidebarCurrentToggleState = this.mobileSidebarToggleSource.asObservable();

  constructor(private http: HttpClient, private _api: APIService, private localStorage: StorageService) { }

  on_ToggleMobileSidebar(state: boolean){
    this.mobileSidebarToggleSource.next(state);
  }

  initializePositiveQuote() {
    let data = new Promise((resolve, reject) => {
      this.http.get(this.positiveQuoteAPI)
        .toPromise()
        .then(
          res => {
            resolve(res)
          }
        )
    })
    return data;
  }

  initializeGratitudeQuote() {
    let data = new Promise((resolve, reject) => {
      this.http.get(this.gratitudeQuoteAPI)
        .toPromise()
        .then(
          res => {
            resolve(res)
          }
        )
    })
    return data;
  }

  getRandomQuotes(type) {
    let currentQuote: any;

    if (type === 1) {
      currentQuote = this.localStorage.get('positiveQuote') 
    } else if (type === 2) {
      currentQuote = this.localStorage.get('gratitudeQuote');
    } else if (type === 3) {
      currentQuote = this.localStorage.get('HealthAndInsurance');
    }
    
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/get-quote-of-the-day', {
        id: currentQuote ? currentQuote.id : -1,
        type
      }, {
          method: 'GET',
          showLoading: true
      }).subscribe((d) => {
          if (d) {
              resolve(d);
          } else {
              reject('No results found. Please try other options');
          }
      }, (err) => {
          console.log('error', {
              detail: err
          });

          reject(err);
      })
    });
    return result; 
  }

}
