import {Injectable, NgZone} from '@angular/core';
//import { APIService } from '../../../services/api.service';
//import {AuthService} from "../../../services/auth.service";
//import { StorageService } from '../../../services/storage.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import {delay} from 'rxjs/operators';
import {APIService} from './api.service';
import {AuthService} from './auth.service';
import Swal from 'sweetalert2';

@Injectable({providedIn: 'root'})
export class DrugsService {

  private authToken = null;

  private myDrugSubject = new Subject<any>();
  private benny_myDrugSubject = new Subject<any>();
  private benny_myDrugSubject_new = new Subject<any>();
  private myPharmacySubject = new Subject<any>();
  private benny_myPharmacySubject = new Subject<any>();
  private benny_myPharmacySubject_new = new Subject<any>();
  private benny_myDrugListInfoSubject = new Subject<any>();
  private myDrugListInfoSubject = new Subject<any>();
  private benny_drugListSubject = new Subject<any>();
  private myDrug_InsightsSubject = new Subject<any>();
  
  public forbiddenDrugListName:string = 'my marketplace drugs';

  existingDrugs: any;
  existingPharmacy: any;
  benny_existingPharmacy: any;

  existingDrugs_list: any = [];
  benny_existingDrugs_list: any = [];
  benny_existingDrugs_list_new: any = [];
  existingPharmacy_list: any = [];
  benny_existingPharmacy_list: any = [];
  benny_existingPharmacy_list_new: any = [];
  benny_existingDrugs_listInfo: any = [];
  existingDrugs_listInfo: any = [];
  defaultDrugList = [];
  benny_drugList_array:any = [];

  constructor(private _api: APIService, private _authService: AuthService) {


    this.existingDrugs = localStorage.getItem('rx_add_to_drug_list');

    if (this.existingDrugs) {
      this.existingDrugs_list = JSON.parse(this.existingDrugs);
    }

    this.existingPharmacy_list = this.fetchAddedPharmacyList();

    const bratl = localStorage.getItem('benny_rx_add_to_drug_list');

    if (bratl) {
      this.benny_existingDrugs_list = JSON.parse(bratl);
    }

    //benny dash existing pharmacy
    this.benny_existingPharmacy_list = this.benny_fetchAddedPharmacyList();

    this.existingPharmacy_list = this.fetchAddedPharmacyList();

    const bratln = localStorage.getItem('benny_rx_add_to_drug_list_new');

    if (bratln) {
      this.benny_existingDrugs_list_new = JSON.parse(bratln);
    }
    
  }

  getAutoSuggest(key: string) {
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/get-autosuggest', {
        key,
      }, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  getFipsAutoSuggest(key: string) {
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/get-fips-autosuggest', {
        key,
      }, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  step1(key: string, allow_generics: any) {
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-1', {
        key, allow_generics,
      }, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  step2(rxcui: string) {
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-2', {
        rxcui,
      }, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  step3r1(rxcui: string) {
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-3-r1', {
        rxcui,
      }, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  step3r2(rxcui: string) {
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-3-r2', {
        rxcui,
      }, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  step4(params: string) {
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-4', params, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  step5(queryParams: object) {
    console.log('queryParams', queryParams);
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-5', queryParams, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  step6(queryParams: object) {
    // console.log('queryParams', queryParams);
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-6', queryParams, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });
        reject(err);
      });
    });
    return result;
  }


  step8(queryParams: object) {
    console.log('queryParams', queryParams);
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/step-8', queryParams, {
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  benny_getDrugDetails() {
    const token = window.localStorage.getItem('auth_token');
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/get-info', token)
        .subscribe(
          (d) => {
            if (d) {
              resolve(d);
            } else {
              reject('No results found. Please try other options');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
    return result;
  }

  benny_duplicateDrugList(drugListId) {
    const token = window.localStorage.getItem('auth_token');
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/duplicate-list/' + drugListId, token)
        .subscribe(
          (d) => {
            if (d) {
              resolve(d);
            } else {
              reject('No results found. Please try other options');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
    return result;
  }

  benny_removeRxList(drugListId) {
    const token = window.localStorage.getItem('auth_token');
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/remove-list/' + drugListId, token)
        .subscribe(
          (d) => {
            if (d) {
              resolve(d);
            } else {
              reject('No results found. Please try other options');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
    return result;
  }


  addList(list) {
    this.existingDrugs_list.push(list);
    this.myDrugSubject.next(this.existingDrugs_list);
  }

  benny_addList(list, type=1) {
    if(type == 1){
      this.benny_existingDrugs_list.push(list);
      this.benny_myDrugSubject.next(this.benny_existingDrugs_list);
    }else{
      this.benny_existingDrugs_list_new.push(list);
      this.benny_myDrugSubject_new.next(this.benny_existingDrugs_list_new);
    }    
  }

  set_BennyDrugList(list){
    this.benny_existingDrugs_list = list;
    this.benny_myDrugSubject.next(this.benny_existingDrugs_list);
  }

  // drugPropsRemoveID(show){
  //   const {drug_id, drug_name, rxcui, often_taken, times_taken, quantity, tags, notes, details} = show;
  //   return {drug_id, drug_name, rxcui, often_taken, times_taken, quantity, tags, notes, details};
  // }

  set_DrugList(list){
    this.existingDrugs_list = list;
    //this.existingDrugs_list = list.map(this.drugPropsRemoveID);
    //this.existingDrugs_list = this.existingDrugs_list.map(v => ({...v, notes: '', tags: ''}))
    //localStorage.setItem('rx_add_to_drug_list', JSON.stringify(this.existingDrugs_list));
    this.myDrugSubject.next(this.existingDrugs_list);
  }

  set_BennyDrugListInfo(info){
    this.benny_existingDrugs_listInfo = info;
    this.benny_myDrugListInfoSubject.next(this.benny_existingDrugs_listInfo);
  }

  set_DrugListInfo(info){
    this.existingDrugs_listInfo = info;
    this.myDrugListInfoSubject.next(this.existingDrugs_listInfo);
  }

  set_BennyPharmacyList(list){
    this.benny_existingPharmacy_list = list;
    this.benny_myPharmacySubject.next(this.benny_existingPharmacy_list);
  }

  // pharmaProps(show){
  //   const {notes, npi, pharmacy_address, pharmacy_name, tags, telephone_number } = show;
  //   return {notes, npi, pharmacy_address, pharmacy_name, tags, telephone_number };
  // }

  set_PharmacyList(list){
    this.existingPharmacy_list = list;
    //this.existingPharmacy_list = list.map(this.pharmaProps);
    //this.existingPharmacy_list = this.existingPharmacy_list.map(v => ({...v, notes: '', tags: ''}))
    //localStorage.setItem('rx_add_to_pharmacy_list', JSON.stringify(this.existingPharmacy_list));
    this.myPharmacySubject.next(this.existingPharmacy_list);
  }

  removeDrugList(list) {
    this.existingDrugs_list = this.existingDrugs_list.filter(data => data.rxcui !== list.rxcui);
    localStorage.setItem('rx_add_to_drug_list', JSON.stringify(this.existingDrugs_list));
    this.myDrugSubject.next(this.existingDrugs_list);
    this.fetchAddedDrugList();
  }

  removeDrugListByRxcui(list){
    this.existingDrugs_list = this.existingDrugs_list.filter(data => data.rxcui !== list.rxcui);
    localStorage.setItem('rx_add_to_drug_list', JSON.stringify(this.existingDrugs_list));
    this.myDrugSubject.next(this.existingDrugs_list);
  }

  addPharmacyList(list) {
    this.existingPharmacy_list.push(list);
    localStorage.setItem('rx_add_to_pharmacy_list', JSON.stringify(this.existingPharmacy_list));
    this.myPharmacySubject.next(this.existingPharmacy_list);
  }

  benny_addPharmacyList(list, type=1) {
    if(type == 1){
      this.benny_existingPharmacy_list.push(list);
      localStorage.setItem('benny_rx_add_to_pharmacy_list', JSON.stringify(this.benny_existingPharmacy_list));
      this.benny_myPharmacySubject.next(this.benny_existingPharmacy_list);
    }else{
      this.benny_existingPharmacy_list_new.push(list);
      localStorage.setItem('benny_rx_add_to_pharmacy_list_new', JSON.stringify(this.benny_existingPharmacy_list_new));
      this.benny_myPharmacySubject_new.next(this.benny_existingPharmacy_list_new);
    }
    
  }

  benny_removeDrugList(list, type=1) {
    if(type == 1){
      this.benny_existingDrugs_list = this.benny_existingDrugs_list.filter(data => data.rxcui !== list.rxcui);
      localStorage.setItem('benny_rx_add_to_drug_list', JSON.stringify(this.benny_existingDrugs_list));
      this.benny_myDrugSubject.next(this.benny_existingDrugs_list);
    }else{
      this.benny_existingDrugs_list_new = this.benny_existingDrugs_list_new.filter(data => data.rxcui !== list.rxcui);
      localStorage.setItem('benny_rx_add_to_drug_list_new', JSON.stringify(this.benny_existingDrugs_list_new));
      this.benny_myDrugSubject_new.next(this.benny_existingDrugs_list_new);
    }    
  }

  benny_removeDrugListByName(list, type=1) {
    if(type == 1){
      this.benny_existingDrugs_list = this.benny_existingDrugs_list.filter(data => data.drug_name !== list.drug_name);
      localStorage.setItem('benny_rx_add_to_drug_list', JSON.stringify(this.benny_existingDrugs_list));
      this.benny_myDrugSubject.next(this.benny_existingDrugs_list);
    }else{
      this.benny_existingDrugs_list_new = this.benny_existingDrugs_list_new.filter(data => data.drug_name !== list.drug_name);
      localStorage.setItem('benny_rx_add_to_drug_list_new', JSON.stringify(this.benny_existingDrugs_list_new));
      this.benny_myDrugSubject_new.next(this.benny_existingDrugs_list_new);
    }    
  }

  removePharmacyList(list) {
    this.existingPharmacy_list = this.existingPharmacy_list.filter(data => data.npi !== list.npi);
    localStorage.setItem('rx_add_to_pharmacy_list', JSON.stringify(this.existingPharmacy_list));
    this.myPharmacySubject.next(this.existingPharmacy_list);
  }

  benny_removePharmacyList(list, type=1) {
    if(type == 1){
      this.benny_existingPharmacy_list = this.benny_existingPharmacy_list.filter(data => data.npi !== list.npi);
      localStorage.setItem('benny_rx_add_to_pharmacy_list', JSON.stringify(this.benny_existingPharmacy_list));
      this.benny_myPharmacySubject.next(this.benny_existingPharmacy_list);
    }else{
      this.benny_existingPharmacy_list_new = this.benny_existingPharmacy_list_new.filter(data => data.npi !== list.npi);
      localStorage.setItem('benny_rx_add_to_pharmacy_list_new', JSON.stringify(this.benny_existingPharmacy_list_new));
      this.benny_myPharmacySubject_new.next(this.benny_existingPharmacy_list_new);
    }    
  }

  retrievePharmacyList(): Observable<string> {
    return this.myPharmacySubject.asObservable();
  }

  benny_retrievePharmacyList(): Observable<string> {
    return this.benny_myPharmacySubject.asObservable();
  }

  benny_retrievePharmacyList_new(): Observable<string> {
    return this.benny_myPharmacySubject_new.asObservable();
  }

  retrieveList(): Observable<string> {
    return this.myDrugSubject.asObservable();
  }

  benny_retrieveList(): Observable<string> {
    return this.benny_myDrugSubject.asObservable();
  }

  retrieveDrugInsights(): Observable<string> {
    return this.myDrug_InsightsSubject.asObservable();
  }

  benny_retrieveList_new(): Observable<string> {
    return this.benny_myDrugSubject_new.asObservable();
  }

  benny_retrieveDrugListInfo(){
    return this.benny_myDrugListInfoSubject.asObservable();
  }

  retrieveDrugListInfo(){
    return this.myDrugListInfoSubject.asObservable();
  }

  fetchAddedPharmacyList(){
    let List = [];
    const existingEntries = localStorage.getItem('rx_add_to_pharmacy_list');
    if (existingEntries) {
      List = JSON.parse(existingEntries);
      List = List.map(v => ({ ...v, pharmacy_name: this.removeSWordFromPharmacyName(v.pharmacy_name)}));
    } else {
      List = [];
    }
    return List;
  }

  fetchAddedDrugList(){
    let List = [];
    const existingEntries = localStorage.getItem('rx_add_to_drug_list');
    if (existingEntries) {
      List = JSON.parse(existingEntries);
    } else {
      List = [];
    }
    this.set_DrugList(List);
    this.get_DrugInsights(List);
    return List;
  }

  benny_fetchAddedPharmacyList(type=1){
    let List = [];
    let existingEntries;
    if(type == 1){
      existingEntries = localStorage.getItem('benny_rx_add_to_pharmacy_list');
    }else{
      existingEntries = localStorage.getItem('benny_rx_add_to_pharmacy_list_new');
    }    
    if (existingEntries) {
      List = JSON.parse(existingEntries);
      List = List.map(v => ({ ...v, pharmacy_name: this.removeSWordFromPharmacyName(v.pharmacy_name)}));
    } else {
      List = [];
    }
    return List;
  }

  saveDrugList(data): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
        this._api.callAPIEndpointAuthenticated(`/api/drugs/save`, token, data ).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });
    return result;
  }

  updateDrugList(data): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
        this._api.callAPIEndpointAuthenticated(`/api/drugs/update`, token, data ).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });
    return result;
  }

  

  getDrugLists(): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/get-info', token).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });
    return result;
  }

  benny_getDrugLists(): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/get-info', token).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });    

    this.benny_drugList_array = result;

    this.benny_drugListSubject.next(this.benny_drugList_array);

    return result;
  }

  benny_editSpecificDrug(data): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
        this._api.callAPIEndpointAuthenticated('/api/drugs/edit/'+ data.row_id, token, data ).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });
    return result;
  }

  benny_editSpecificPharmacy(data): Promise<any>{
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticated('/api/drugs/edit/pharmacy/'+ data.row_id, token, data ).subscribe((d) => {
          resolve(d);
      }, (err) => {
          console.log('error', {
              detail: err
          });
          reject(err);
      });
  });
  return result;
  }

  benny_deleteDrugList(drugListId) {
    const token = window.localStorage.getItem('auth_token');
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/delete/list/' + drugListId , token)
        .subscribe(
          (d) => {
            if (d) {
              resolve(d);
            } else {
              reject('No results found. Please try other options');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
    return result;
  }

  benny_deleteDrug(drugId) {
    const token = window.localStorage.getItem('auth_token');
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/delete/drug/' + drugId , token)
        .subscribe(
          (d) => {
            if (d) {
              resolve(d);
            } else {
              reject('No results found. Please try other options');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
    return result;
  }

  benny_deletePharmacy(pharmacyId) {
    const token = window.localStorage.getItem('auth_token');
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/delete/pharmacy/' + pharmacyId , token)
        .subscribe(
          (d) => {
            if (d) {
              resolve(d);
            } else {
              reject('No results found. Please try other options');
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
    return result;
  }

  getTaggedList(key: string) {
    const param = {};
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpoint('/api/v1/drugs/get-by-tag/' + key , param ,{
        method: 'GET',
        showLoading: true
      }).subscribe((d) => {
        if (d) {
          resolve(d);
        } else {
          reject('No results found. Please try other options');
        }
      }, (err) => {
        console.log('error', {
          detail: err
        });

        reject(err);
      });
    });
    return result;
  }

  //remove word 'LLC, INC, DEPT from Pharmcy Names'
  removeSWordFromPharmacyName(text){
    return text.toUpperCase()
               .replace(', L.L.C.','')
               .replace(',L.L.C.','')
               .replace('L.L.C.','')
               .replace(',LLC','')
               .replace(',LLC.','')
               .replace('LLC.','')
               .replace(', INC.','')
               .replace(', INC','')
               .replace(',INC','')
               .replace(',INC.','')
               .replace('INC.','')
               .replace(/( )(LLC)( ?)/g,' ')
               .replace(/( )(INC)( ?)/g,' ')
               .replace(/( )(DEPT)( ?)/g,' ')
               .replace(/[0-9]/g, '')
               .replace('#', '')
               .replace(/-/g, "");
  }

  getSpecificDrugList(druglistid): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/drugs/drug-list/'+ druglistid , token).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });

    return result;
  }

  editSpecificDrugList(id, data): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
        this._api.callAPIEndpointAuthenticated('/api/drugs/edit/list/'+ id, token, data ).subscribe((d) => {
            this.getDrugLists();
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });
    return result;
  }

  benny_fetchAddedDrugList(){
    let List = [];
    const existingEntries = localStorage.getItem('benny_rx_add_to_drug_list');
    if (existingEntries) {
      List = JSON.parse(existingEntries);
    } else {
      List = [];
    }
    return List;
  }

  //empty benny drug list 
  benny_resetDrugList(){
    this.benny_existingDrugs_list = [];
    this.benny_myDrugSubject.next(this.benny_existingDrugs_list);
  }

  benny_resetPharmacyList(){
    this.benny_existingPharmacy_list = [];
    this.benny_myPharmacySubject.next(this.benny_existingPharmacy_list);
  }

  saveIndividualDrug(data): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
        this._api.callAPIEndpointAuthenticated(`/api/drugs/drug-list/add-or-update-drug`, token, data ).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });
    return result;
  }

  saveIndividualPharmacy(data): Promise<any> {     
    const token = window.localStorage.getItem('auth_token'); 
    const result = new Promise((resolve, reject) => {
        this._api.callAPIEndpointAuthenticated(`/api/drugs/drug-list/add-pharmacy`, token, data ).subscribe((d) => {
            resolve(d);
        }, (err) => {
            console.log('error', {
                detail: err
            });
            reject(err);
        });
    });
    return result;
  }


  drugProps(show){
    const {id, drug_id, drug_name, rxcui, often_taken, times_taken, quantity, tags, notes, details} = show;
    return {id, drug_id, drug_name, rxcui, often_taken, times_taken, quantity, tags, notes, details};
  }

  async benny_fetch_drugList_data(id){ // kkk
    const data = await this.getSpecificDrugList(id);

    const d = data[0].drugs.map(this.drugProps);
    const drugs = d.map(v => ({...v, details: JSON.parse(v.details)}));

    const list_drugs = drugs;
    const list_pharmacies = data[0].pharmacies;

    const updated_at1 = new Date(data[0].updated_at);
    const updated_at = updated_at1.toLocaleDateString('en-US'); //fr-CA

    const list_info = {
      drug_list_name: data[0].name,
      drug_list_notes: data[0].notes,
      drug_list_tags: data[0].tags,
      drug_list_type: data[0].type,
      is_active: data[0].is_active,
      drug_notes:data[0].drug_notes,
      pharmacy_notes:data[0].pharmacy_notes,
      updated_at: updated_at
    }

    this.set_BennyDrugList(list_drugs);
    this.set_BennyPharmacyList(list_pharmacies);   
    this.set_BennyDrugListInfo(list_info); 
    this.get_DrugInsights(list_drugs); // call function to compute drug insights

    return data;

  }

  async fetch_drugList_data(id){
    const data = await this.getSpecificDrugList(id);

    const d = data[0].drugs.map(this.drugProps);
    const drugs = d.map(v => ({...v, details: JSON.parse(v.details)}));

    const list_drugs = drugs;
    const list_pharmacies = data[0].pharmacies;

    const updated_at1 = new Date(data[0].updated_at);
    const updated_at = updated_at1.toLocaleDateString('en-US'); //fr-CA
    const list_info = {
      drug_list_name: data[0].name,
      drug_list_notes: data[0].notes,
      drug_list_tags: data[0].tags,
      drug_list_type: data[0].type,
      is_active: data[0].is_active,
      drug_notes: data[0].drug_notes,
      pharmacy_notes:data[0].pharmacy_notes,
      updated_at: updated_at
    }

    this.set_DrugList(list_drugs);
    this.set_PharmacyList(list_pharmacies);   
    this.set_DrugListInfo(list_info); 

    this.get_DrugInsights(list_drugs); // call function to compute drug insights

    return data;

  }

  get_DrugInsights(list){

    const xlist = list.map(v => ({...v, drugForm: v.details.package.replace(/ *\[[^)]*\] */g, "")}));

    const counts = {};
    const drugType = {}

    list.forEach((x) => {
      counts[x.details.isbranded] = (counts[x.details.isbranded] || 0) + 1;
    });

    xlist.forEach((x) => {
      drugType[x.drugForm] = (drugType[x.drugForm] || 0) + 1;
    });

    const drugForms = Object.entries(drugType);

    const data = {
      'branded': counts['1'],
      'generic': counts['0'],
      'drug_forms': drugForms
    }

    this.myDrug_InsightsSubject.next(data);

    return data;
    
  }

  //get drugs brand name if "[]" exist, else get drug_name
  getDrugName(info){
    let name;
    if(info.name.indexOf('[') !== -1 && info.name.indexOf(']') !== -1){
      name = info.name.substring(
        info.name.indexOf("[") + 1, 
        info.name.lastIndexOf("]")
      );
    }else{
      name = info.drug_name.toLowerCase().replace('oral','').replace('tablet','').replace('injection','').replace('capsule','');
    }

    return name;
  }

  //format US phone number
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

}
