<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">
            <span>Our Medicare Services</span>
            Solutions, Service, and Support
					</h1>
					<p class="banner-sub-title">Instant access to all Medicare coverages and prices, clear explanations and
            guidance, and caring Medicare experts to personally answer your questions.
          </p>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">
				<img src="assets/img/common/woman-services-pc.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<!-- first view -->
<div class="container medicare-services-wrapper" *ngIf="isShow">
  <!-- <div class="row text-center heading-box mb-3">
    <p class="mb-3">TapTap Medicare is your solution for an all-in-one service and support system.</p>
    <p>Instant access to all Medicare coverages and prices, clear explanations and guidance, and caring Medicare experts to personally answer your questions.
      </p>
  </div> -->
  <div class="row mb-5">
    <div class="col-12 col-md-6 mx-auto text-center">
      <input type="text" class="form-control form-control-lg" placeholder="Search Keyword..." [(ngModel)]="searchKeyword">
    </div>
  </div>

  <ng-container *ngIf="!searchKeyword">
    <ng-container *ngTemplateOutlet="listItemsDefault"></ng-container>
  </ng-container>
  <ng-container *ngIf="searchKeyword">
    <ng-container *ngTemplateOutlet="listItemsFiltered"></ng-container>
  </ng-container>
  <!-- <ng-container *ngTemplateOutlet="listItems">

  </ng-container> -->

  <ng-template #listItemsDefault>
    <div class="row">
      <div class="col-md-6 p-2">
          <div class="med-service-box">
            <h2>{{list[0].category}}</h2>
            <div class="banner-pointer-wrapper">
              <div class="banner-pointer-background"></div>
            </div>
            <ul>
              <li><div><i class="fas fa-square"></i><a (click)="showForm(list[0].item[0])">Help Me {{list[0].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
              <li><div><i class="fas fa-square"></i><a (click)="showForm(list[0].item[1])">Help Me {{list[0].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
              <li><div><i class="fas fa-square"></i><a (click)="showForm(list[0].item[2])">Help Me {{list[0].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            </ul>
          </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="med-service-box">
          <h2>{{list[1].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[1].item[0])">Help Me {{list[1].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[1].item[1])">Help Me {{list[1].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[1].item[2])">Help Me {{list[1].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="med-service-box">
          <h2>{{list[2].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[2].item[0])">Help Me {{list[2].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[2].item[1])">Help Me {{list[2].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[2].item[2])">Help Me {{list[2].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="med-service-box">
          <h2>{{list[3].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[3].item[0])">Help Me {{list[3].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[3].item[1])">Help Me {{list[3].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[3].item[2])">Help Me {{list[3].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="med-service-box">
          <h2>{{list[4].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[4].item[0])">Help Me {{list[4].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[4].item[1])">Help Me {{list[4].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[4].item[2])">Help Me {{list[4].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="med-service-box">
          <h2>{{list[5].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[5].item[0])">Help Me {{list[5].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[5].item[1])">Help Me {{list[5].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[5].item[2])">Help Me {{list[5].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="med-service-box">
          <h2>{{list[6].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[6].item[0])">Help Me {{list[6].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[6].item[1])">Help Me {{list[6].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[6].item[2])">Help Me {{list[6].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="med-service-box">
          <h2>{{list[7].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[7].item[0])">Help Me {{list[7].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[7].item[1])">Help Me {{list[7].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li><div><i class="fas fa-square"></i><a (click)="showForm(list[7].item[2])">Help Me {{list[7].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #listItemsFiltered>
    <div class="row">
      <div class="col-md-6 p-2" *ngIf="list[0].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[0].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[0].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
          <div class="med-service-box">
            <h2>{{list[0].category}}</h2>
            <div class="banner-pointer-wrapper">
              <div class="banner-pointer-background"></div>
            </div>
            <ul>
              <li *ngIf="list[0].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[0].item[0])">Help Me {{list[0].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
              <li *ngIf="list[0].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[0].item[1])">Help Me {{list[0].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
              <li *ngIf="list[0].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[0].item[2])">Help Me {{list[0].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            </ul>
          </div>
      </div>
      <div class="col-md-6 p-2" *ngIf="list[1].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[1].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[1].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
        <div class="med-service-box">
          <h2>{{list[1].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li *ngIf="list[1].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[1].item[0])">Help Me {{list[1].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[1].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[1].item[1])">Help Me {{list[1].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[1].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[1].item[2])">Help Me {{list[1].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2" *ngIf="list[2].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[2].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[2].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
        <div class="med-service-box">
          <h2>{{list[2].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li *ngIf="list[2].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[2].item[0])">Help Me {{list[2].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[2].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[2].item[1])">Help Me {{list[2].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[2].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[2].item[2])">Help Me {{list[2].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2" *ngIf="list[3].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[3].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[3].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
        <div class="med-service-box">
          <h2>{{list[3].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li *ngIf="list[3].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[3].item[0])">Help Me {{list[3].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[3].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[3].item[1])">Help Me {{list[3].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[3].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[3].item[2])">Help Me {{list[3].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2" *ngIf="list[4].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[4].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[4].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
        <div class="med-service-box">
          <h2>{{list[4].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li *ngIf="list[4].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[4].item[0])">Help Me {{list[4].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[4].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[4].item[1])">Help Me {{list[4].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[4].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[4].item[2])">Help Me {{list[4].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2" *ngIf="list[5].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[5].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[5].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
        <div class="med-service-box">
          <h2>{{list[5].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li *ngIf="list[5].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[5].item[0])">Help Me {{list[5].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[5].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[5].item[1])">Help Me {{list[5].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[5].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[5].item[2])">Help Me {{list[5].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2" *ngIf="list[6].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[6].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[6].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
        <div class="med-service-box">
          <h2>{{list[6].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li *ngIf="list[6].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[6].item[0])">Help Me {{list[6].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[6].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[6].item[1])">Help Me {{list[6].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[6].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[6].item[2])">Help Me {{list[6].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 p-2" *ngIf="list[7].item[0].toLowerCase().includes(searchKeyword.toLowerCase()) || list[7].item[1].toLowerCase().includes(searchKeyword.toLowerCase()) || list[7].item[2].toLowerCase().includes(searchKeyword.toLowerCase())">
        <div class="med-service-box">
          <h2>{{list[7].category}}</h2>
          <div class="banner-pointer-wrapper">
            <div class="banner-pointer-background"></div>
          </div>
          <ul>
            <li *ngIf="list[7].item[0].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[7].item[0])">Help Me {{list[7].item[0]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[7].item[1].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[7].item[1])">Help Me {{list[7].item[1]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
            <li *ngIf="list[7].item[2].toLowerCase().includes(searchKeyword.toLowerCase())"><div><i class="fas fa-square"></i><a (click)="showForm(list[7].item[2])">Help Me {{list[7].item[2]}}</a></div><!--<i class="fas fa-angle-double-right"></i>--></li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
  
  <div class="col-12 d-flex justify-content-center align-items-center flex-column">
      <h4 class="h4-box">
          Just tell us anything at all that we can do to be helpful to you with
          your Medicare experience and we will be on the job for you.
      </h4>
      <div class="pointer-bg">
        <div class="banner-pointer-wrapper onTablet">
          <div class="banner-pointer-background"></div>
        </div>
        <img src="./assets/img/common/8-counselor-standing.png" class="message-box-image">
      </div>
  </div>
</div>

<!-- second view -->
<section *ngIf="!isShow">
  <app-medicare-services-form infoMessage="{{infoMessage}}"></app-medicare-services-form>
  
  <!-- back btn -->
  <div class="row justify-content-center my-3">
    <button class="btn btn-third btn-back mx-1"
            (click)="back()"><!--<i class="fas fa-angle-double-left"></i>-->
      <span>Back</span>
    </button>
  </div>
  <!-- end back btn -->
</section>


<mat-divider></mat-divider>
<app-medicare-services-form [isMedicareServices]="true" *ngIf="isShow" [infoMessage]="getDisplayContactDescription()"></app-medicare-services-form>
<app-testimonials></app-testimonials>
<div class="mb-5"></div>
<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>