import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit {
  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;
  @Input() pageButtons: number;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onPageChange(pageNumber: number): void {
    this.pageChanged.emit(pageNumber);
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }
 
  get pages(): number[] {
    const maxVisiblePages = this.pageButtons;
    const startPage = Math.max(1, Math.min(this.totalPages - maxVisiblePages + 1, this.currentPage - Math.floor(maxVisiblePages / 2)));
    const endPage = Math.min(this.totalPages, startPage + maxVisiblePages - 1);
    const pages: number[] = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  }

}
