<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-md-8">
        <div class="banner-text-wrapper">
          <h1 class="banner-main-title">
            Get The Best Medigap <span class="fColor-orange fWeight-inherit fSize-inherit">Plan G</span><br />
            Rate with TapTap Medicare
          </h1>
          <ul class="checklist">
            <li>Access To All Medigap Plan N Carriers And Prices</li>
            <li>Save Money On Your Insurance</li>
            <li>Find Lower Plan N Rates With The Exact Same Coverage</li>
            <li>Easy-To-Understand Answers & Explanations</li>
            <li>Guidance & Support Throughout The Year</li>
          </ul>
          <div class="d-flex align-items-center flex-column flex-sm-row">
            <button class="btn btn-first mr-2" (click)="moveToContent()">Learn More</button>
            <button class="btn btn-third" routerLink="/insurance-marketplace/medigap">Compare Prices</button>
          </div>
        </div>
      </div>
      <div class="col-md-4 px-0 banner-image-wrapper">
        <img src="assets/img/common/plan-n-landing-page/plan-n-banner-character.png" class="banner-main-image" />
      </div>
    </div>
  </div>
</div>

<div class="banner-pointer-wrapper" style="margin-bottom: 0px;">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>

<section id="target_learn_more">
  <div class="container">
    <h2 class="text-center">What Is Plan G?</h2>
    <div>
      <h3>Overview</h3>
      <p>When you have Medicare Supplement coverage this Means You are keeping Medicare as your
        primary and enrolling in a Medicare Supplement (aka Medigap plan) to eliminate your out-of-
        pocket costs for “Major Medical” services.</p>
      
      <p><strong class="colo-primary">Your prescription drug coverage</strong> is through a “Standalone” Medicare Part D Drug Plan, and your
        dental, vision, and hearing coverage is through a separate additional policy. These are not
        covered by your Medigap plan</p>
    </div>

    <app-insurance-coverage-box highlighted="medigap" size="large" class="col-12 col-md-10"></app-insurance-coverage-box>	

    <p>Medicare Supplement Plans are <strong class="color-primary">"Government Standardized"</strong> , which means that for a particular Plan
      Letter – like <strong class="color-primary">Plan F, Plan G, or Plan N</strong> – every carrier must provide the:</p>
    
    <ul class="d-block">
      <li class="fWeight-600">same coverage and benefits</li>
      <li class="fWeight-600">same doctors and healthcare providers</li>
      <li class="fWeight-600">same claims process</li>
      <li class="fWeight-600">same rules and guarantees</li>
    </ul>

    <p class="text-center"><strong>With Medigap Plan G,</strong> the only amount you pay out of pocket each year for major medical
      healthcare services is your once per year Part B Deductible of $203
    </p>   

    <div class="cover-list">
        <div class="col-12 details-p row">
          <ul>
            <li><img src="./assets/img/common/plan-n-landing-page/Diabetic Supplies@144x.png" alt="Doctor's Office Visits">Doctor's Office Visits</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Preventive Care@144x.png" alt="Preventive Care">Preventive Care</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Diagnostics@144x.png" alt="Diagnostic Tests">Diagnostic Tests</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Physical Theraphy@144x.png" alt="Physical Therapy">Physical Therapy</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Medical Equipment@144x.png" alt="Medical Equipment">Medical Equipment</li>
          </ul>
        </div>
        <div class="col-12 details-p row">
          <ul>
            <li><img src="./assets/img/common/plan-n-landing-page/Hospitalizations@144x.png" alt="Hospitalizations">Hospitalizations</li>
            <li><img src="./assets/img/common/plan-n-landing-page/outpatient procedures@144x.png" alt="Outpatient Procedures">Outpatient Procedures</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Skilled Nursing@144x.png" alt="Skilled Nursing">Skilled Nursing</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Surgeries@144x.png" alt="Surgeries">Surgeries</li>
            <li><img src="./assets/img/common/plan-n-landing-page/Mental Health@144x.png" alt="Mental Health">Mental Health</li>
          </ul>
        </div>
    </div>

    <p>Part B Excess Charges are a relatively uncommon occurrence in which a healthcare provider can
        charge up to 15% above the Medicare-approved amount for a service, and is only possible if the
        healthcare provider doesn't take Medicare assignment.
    </p>

    <a href="tel:18002155108" class="btn btn-first mx-auto max-width-300">Call us For Clarity</a>
  </div>
</section>

<section class="bg-grey" style="background:#f2f2f2;">
  <div class="container">
    <p>To understand <strong>what Medicare Supplement Plan G does,</strong> here is a simplified look at
      what you are responsible for <strong>if you just have Original Medicare.</strong> Plan G covers
      everything except the highlighted Part B Deductible of $203.</p>

    <mat-divider class="my-5"></mat-divider>

    <div class="row part-a-b-services">
      <div class="col-12 col-md-6">
        <span class="part-a-b-services-box-heading">FOR PART A (INPATIENT) SERVICES:</span>
        <div class="part-a-b-services-box-wrapper">
          <div class="part-a-b-services-box">
            <h4>Inpatient Hospitalization</h4>
            <ul class="checklist">
              <li>Your first $1,484 of charges (This is your Part A Deductible)</li>
              <li>$371/day during days 60–151 (This is your Part A Copay)</li> 
              <li>$742/day during day 91–151 (This is your Part A Reserve Copay)</li>
              <li>100% of costs after day 151</li>
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Skilled Nursing</h4>
            <ul class="checklist">
              <li>$0 during days 1–20</li>
              <li>$185.50/day during day 21–100</li> 
              <li>100% of costs after day 100</li>
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Hospice Care</h4>
            <ul class="checklist">
              <li>100% of inpatient hospice costs</li>
            </ul>
          </div>
        </div>
        
      </div>
      <div class="col-12 col-md-6">
        <span class="part-a-b-services-box-heading">FOR PART B (OUTPATIENT) SERVICES:</span>
        <div class="part-a-b-services-box-wrapper">
          <div class="part-a-b-services-box">
            <h4>Outpatient Services</h4>
            <ul class="checklist">
              <li class="highlighted">Your first $203 of charges. (This is your Part B Deductible)</li>
              <li>20% of all charges. (This is your Part B Coinsurance)</li> 
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Tests and Lab Services</h4>
            <ul class="checklist">
              <li>$0 for Any Medically Necessary Diagnostic Laboratory Tests</li>
              <li>20% of Charges for Outpatient MRI, X-Rays, etc.</li> 
            </ul>
          </div>

          <div class="part-a-b-services-box">
            <h4>Blood</h4>
            <ul class="checklist">
              <li>100% of Your First Three Pints</li>
              <li>0% of Additional Amount</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<div class="d-none">
  <div #videoContent>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <div
          class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true"
          style="height: 100%; position: relative; width: 100%"
        >
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            "
          >
            <img
              src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch"
              style="
                filter: blur(5px);
                height: 100%;
                object-fit: contain;
                width: 100%;
              "
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ready-to-talk [isLandingPage]="true" spacerColor="#f2f2f2"></app-ready-to-talk>

<div class="d-none">
  <div #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>
