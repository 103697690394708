import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-marketplace-widget-form',
  templateUrl: './marketplace-widget-form.component.html',
  styleUrls: ['./marketplace-widget-form.component.scss']
})
export class MarketplaceWidgetFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $('.marketplace-widget-form-close').click(function(){
      $('.marketplace-widget-overlay').removeClass('d-block');
      $('#marketplace-widget-form').removeClass('d-block');
      $('.marketplace-widget-overlay').addClass('d-none');
      $('#marketplace-widget-form').addClass('d-none');
    })

    $('.marketplace-widget-connect-btn').click(function(){
      $('#custom-widget-chat-btn-id').click();
      $('.marketplace-widget-form-close').click();
    })

    $('.marketplace-widget-connect-btn').click(function(){
      $('#custom-widget-chat-btn-id').click();
      $('.marketplace-widget-form-close').click();
    })
    
    $('.marketplace-widget-1-btn').click(function(){
      $('.marketplace-widget-form-close').click();
      const content = $('.help-me-understand-this-coverage').html();
      showModal(content);
    })

    $('.marketplace-widget-2-btn').click(function(){
      $('.marketplace-widget-form-close').click();
      const content = $('.is-this-the-right-coverage-for-me').html();
      showModal(content);
    })

    $('.marketplace-widget-3-btn').click(function(){
      $('.marketplace-widget-form-close').click();
      const content = $('.how-should-i-choose-a-carrier').html();
      showModal(content);
    })

    $('.marketplace-widget-4-btn').click(function(){
      $('.marketplace-widget-form-close').click();
      const content = $('.will-i-be-approved-for-this-coverage').html();
      showModal(content);
    })

    function showModal(content){
      Swal.fire({
        html: content,
        customClass: {
            popup: 'custom-modal-wrapper',
            content: 'text-left custom-modal-content'
        },
        showConfirmButton: false,
        showCloseButton:true
      })
    }
  }
}
