import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-apply-on-the-phone',
  templateUrl: './apply-on-the-phone.component.html',
  styleUrls: ['./apply-on-the-phone.component.scss']
})
export class ApplyOnThePhoneComponent implements OnInit {

  @Input() plan: any;
  @Input() logo: any;
  @Input() planPremium: any;
  @Input() planPremiumLabel: any;
  @Input() oneTimeEnrollmentFee: any;
  @Input() householdDiscount: any;
  @Input() insuranceType: any;
  

  

  constructor() {
  }

  ngOnInit() {
  }

  onClickScheduleTime() {
    window.open(
        'https://brightprice.gigabook.com/book/all/Fzq76FyApp0vPgOwwesh/i/',
        '_blank' // <- This is what makes it open in a new window.
    );
}

}
