import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {DrugsService} from '../../../../../services/drugs.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-mapd-version1',
  templateUrl: './version1.component.html',
  styleUrls: ['./version1.component.scss']
})
export class Version1Component implements OnInit {

  @Input() results;
  @Input() plan_val;
  @Input() part_d_id;
  @Input() countySelected;
  @Input() param_contract_id;
  @Input() param_plan_id;
  @Input() plan_id;
  @Input() base_img_loc;
  @Input() showProvider;
  @Input() showDrugs;
  @Output() emitGetConvertedRate = new EventEmitter;
  @Input() logComparedKeys;
  @Input() controls;
  @Input() planYear;
  @Input() providers;
  @Input() facilities;
  @Input() isViewDrugCost;
  @Input() groupDrugCost;
  @Input() isBlueBgHeading = true;
  @Input() drugsList;
  @Input() pharmacyList; 
  @Input() zipCode;   
  @Output() emitCompanyLogoCheck = new EventEmitter;
  @Output() emit_onclick_planDocsResources = new EventEmitter;
  @Output() emit_onclick_doctorDrugAnalysis = new EventEmitter;
  @Output() emit_onclick_planActionButtons = new EventEmitter;
  @Output() emit_onClick_SummaryOfBenefits = new EventEmitter;
  @Output() emit_onClick_AdditionalDocuments = new EventEmitter;
  @Output() emit_addDoctors = new EventEmitter;
  @Output() emit_showSection = new EventEmitter;
  @Output() emit_onClick_ViewThisPlansDrugCopaysPerTier = new EventEmitter;
  @Output() emit_addDrugs = new EventEmitter;
  @Output() emit_onClick_addToCompare = new EventEmitter;
  @Output() emit_onClick_EnrollOnline = new EventEmitter;
  @Output() emit_onClick_EnrollOnThePhone = new EventEmitter;
  @Output() emit_onClick_ViewMoreModal = new EventEmitter;
  @Output() emit_onClick_AdditionalPlanInsights = new EventEmitter;
  
  @ViewChild('specificDrugDetailsModal') specificDrugDetailsModal:ElementRef;
  promise1:any;
  promise2:any;
  isLoading:boolean = false;
  strip_drug_name:any;
  drugViewMoreDetail:any;
  formCheck:any = [];
  moreDrugDetails_text:string = 'More Drug Details';
  showMoreDrugDetails:boolean = false;
  viewMore_TimesTaken:any;
  viewMore_OftenTaken:any;
  viewMore_DaysSupply:any;

  logCartKeys: Array<Object> = [];

  addToCartLabel:string = 'Add to Cart';

  currentUrlPathName:any;

  constructor(private drugService: DrugsService) { 
    this.getExistingCartPlans();
  }

  ngOnInit() {
  }

  replaceSpaceWith(string, char = '-') {
    return string.replace(/ /g, char).replace(/,/g, '').replace(/'/g, '').replace(/\./g, '').toLowerCase();
  }

  getConvertedRate(rate) {
    return parseFloat((rate * .01).toFixed(2));
  }

  // getConvertedRate(rate){
  //   console.log('test', rate);
  //   return this.emitGetConvertedRate.emit(rate);
  // }

  companyLogoCheck($event){
    this.emitCompanyLogoCheck.emit($event);
  }

  onclick_planDocsResources(key){
    this.emit_onclick_planDocsResources.emit(key);
  }

  onclick_doctorDrugAnalysis(key){
    this.emit_onclick_doctorDrugAnalysis.emit(key);
  }

  onclick_planActionButtons(key){
    this.emit_onclick_planActionButtons.emit(key);
  }

  onClick_SummaryOfBenefits(result,type){
    this.emit_onClick_SummaryOfBenefits.emit({result,type});
  }

  onClick_AdditionalDocuments(modalWidth,result){
    this.emit_onClick_AdditionalDocuments.emit({modalWidth,result});
  }

  addDoctors(key, contract_id, plan_id, segment_id){
    this.emit_addDoctors.emit({key, contract_id, plan_id, segment_id})
  }
  
  over(id){
    const displayTimeout = setTimeout(delayOutput, 3000);
    function delayOutput() {
      jQuery('#' + id).addClass('d-block');
      jQuery('#' + id).removeClass('d-none');
      // jQuery('#' + id).addClass('noHover')
      clearTimeout(displayTimeout);
    }
  }

  showSection(type){
    this.emit_showSection.emit(type);
  }

  onClick_ViewThisPlansDrugCopaysPerTier(modalWidth, result){
    this.emit_onClick_ViewThisPlansDrugCopaysPerTier.emit({modalWidth, result});
  }

  addDrugs(param){
    this.emit_addDrugs.emit(param);
  }

  onClick_addToCompare(result,event){
    this.emit_onClick_addToCompare.emit({result,event})
  }

  onClick_EnrollOnline(result,index){
    this.emit_onClick_EnrollOnline.emit({result,index});
  }

  onClick_EnrollOnThePhone(result){
    this.emit_onClick_EnrollOnThePhone.emit(result);
  }

  onClickViewMoreModal(item){
    this.emit_onClick_ViewMoreModal.emit(item);
  }

  async getDrugMoreDetails(drug) {
    const rxcui = drug.rxcui;
    this.viewMore_TimesTaken = drug.times_taken;
    this.viewMore_OftenTaken = drug.often_taken;
    this.viewMore_DaysSupply = drug.quantity;
    this.showModal();
    this.isLoading = true;
    try {
      this.promise1 = await this.drugService.step3r1(rxcui);
      this.promise2 = await this.drugService.step3r2(rxcui);

      return Promise.all([this.promise1, this.promise2]).then((values) => {
        this.drugViewMoreDetail = values;
        this.checkDosageForm();
        this.strip_drug_name = this.drugViewMoreDetail[1].idGroup.name.toLowerCase().replace('oral','').replace('tablet','').replace('injection','').replace('capsule','');
        this.strip_drug_name = this.drugService.getDrugName(this.drugViewMoreDetail[1].idGroup.drug_info );
        this.isLoading = false;
        console.log('together', this.drugViewMoreDetail);
        return this.drugViewMoreDetail;
      });

    } catch (e) {
      console.log('error', e);
      this.isLoading = false;
    } finally {
    }
  }

  checkDosageForm() {
    const dosageName = this.drugViewMoreDetail[1].idGroup.name.toLowerCase();
    const dosageString = "capsule";
    const dosageString2 = "tablet";
    const dosageString3 = "injection";
    if(dosageName.includes(dosageString)) {
      this.formCheck = "capsule";
    }
    else if(dosageName.includes(dosageString2)) {
      this.formCheck = "tablet";
    }
    else if(dosageName.includes(dosageString3)) {
      this.formCheck = "injection";
    }
    else {
      this.formCheck = "meds";
    }
  }

  
  showModal(){
    this.showMoreDrugDetails = false;
    const modalViews = this.specificDrugDetailsModal.nativeElement;
    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 900,
    });
  }

  onClick_MoreDrugDetails(state){
    this.showMoreDrugDetails = !state;
    if(this.showMoreDrugDetails){
      this.moreDrugDetails_text = "Hide Additional Details";
    }else{     
      this.moreDrugDetails_text = "More Drug Details";
    }
  }

  getExistingCartPlans() {
    let existingComparedEntries = JSON.parse(localStorage.getItem('add_to_cart_mapd'));
    if (existingComparedEntries) {
        existingComparedEntries.forEach((element) => {
          this.logCartKeys.push(element.key);
        });
    }
  }

  onClick_AddToCart(result,event) {

    const addToCartBtn = document.querySelector('#ci_cart_btn__'+result.key);
    const viewCartBtn = document.querySelector('#view_cart_btn_'+result.key);

    if (event.target.checked) {

      this.addToCartLabel = 'Added to Cart';
      addToCartBtn.classList.add('active');

      var existingEntries = JSON.parse(localStorage.getItem('add_to_cart_mapd'));
      if (existingEntries == null) {
        existingEntries = [];
      }

      existingEntries.push(result);
      localStorage.setItem('add_to_cart_mapd', JSON.stringify(existingEntries));

      viewCartBtn.classList.add("d-block");
      viewCartBtn.classList.remove("d-none");

    } else {
      this.addToCartLabel = 'Add to Cart';
      addToCartBtn.classList.remove('active');
      this.removeToCart(result);
      viewCartBtn.classList.remove("d-block");
      viewCartBtn.classList.add("d-none");
    }
  }

  removeToCart(result) {

    const cartItem =  JSON.parse(window.localStorage.getItem('add_to_cart_mapd'));

    if (cartItem) {
      for (let x = 0; x < cartItem.length; x++) {
        if (result['key'] !== 'undefined' && cartItem[x]['key'] !== 'undefined') {
          if (result['key'] === cartItem[x]['key']) {
            cartItem.splice(x, 1);
          }
        }
      }
    }

    localStorage.setItem('add_to_cart_mapd', JSON.stringify(cartItem));

  }

  saveThisPlan(){    
    this.currentUrlPathName = window.location.pathname;
    console.log(this.currentUrlPathName);    
  }

  onClick_AdditionalPlanInsights():void{
    this.emit_onClick_AdditionalPlanInsights.emit();
  }

  onClick_Rating(content):void{
    Swal.fire({
      html: content,
      showConfirmButton: false,
      showCloseButton: true,
      width: 1100,
    });
  }
  
}
