<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b [showNumber]="true"></app-header-b>

  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-md-8">
        <div class="banner-text-wrapper">
          <h1 class="banner-main-title">
            <span>Your Enrollment Center</span><br />
            Simple, Managed, &<br />Accountable
          </h1>
          <p class="banner-sub-title">
            A clear & organized Medicare enrollment process to start<br />
            your Medicare experience on the right foot.
          </p>
        </div>
      </div>
      <div class="col-md-4 px-0 banner-image-wrapper">
        <img
          src="assets/img/common/medicare-part-b/woman-man-in-a-table.png"
          class="banner-main-image mb-0"
        />
      </div>
    </div>
  </div>
</div>

<div class="banner-pointer-wrapper">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>

<section class="container-fluid main-content-wrapper home-wrapper">
  <div class="row home-content">
    <div class="col-12 row">
      <div class="col-md-12 home-content-row-div mt-50">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-4 row justify-content-center">
              <div class="col-11">
                <h5 class="center">This Custome Guide</h5>
                <p class="center welcome-paragraphs">
                  was created personally for you to help you have the clarity
                  you deserve in regards to your Medicare enrollment situation,
                  and the processes, conditions, and timelines involved.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 row justify-content-center">
              <div class="col-11">
                <h5 class="center">Our Focus</h5>
                <p class="center welcome-paragraphs">
                  is on creating the most clear & organized Medicare experience
                  for you - one that delivers the most value, clarity, &
                  security throughout your Medicare journey.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 row justify-content-center">
              <div class="col-11">
                <h5 class="center">The Following Pages</h5>
                <p class="center welcome-paragraphs">
                  are your "Enrollment Dashboard". Your Dashboard contains each
                  step, and resource, you need to complete your desired Medicare
                  enrollment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row blue-bg">
      <div class="col-md-12">
        <div class="container">
          <div class="row mt-50 d-block d-md-none d-lg-none d-xl-none">
            &nbsp;
          </div>
          <div class="row align-items-center">
            <div
              class="col-12 col-md-6 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0"
            >
              <img
                style="margin-top: -80px; margin-bottom: -210px"
                src="assets/img/common/medicare-part-b/woman-in-blue-jacket.png"
              />
            </div>
            <div class="row mt-50 d-block d-md-none d-lg-none d-xl-none">
              &nbsp;
            </div>
            <div
              class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5"
            >
              <div class="home-text-box">
                <h2 class="fColor-white">Your Enrollment Profile</h2>
                <p class="fColor-white">
                  An Easy-to-understand Outline of Your Specific Enrollment<br />
                  Scenario, What It means, & Why This is You
                </p>

                <p class="quote mt-50">
                  What a new face courage<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;puts on everything.
                </p>
                <p class="mt-20 quote-author">Ralph Waldo Emerson</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-50">
      <div class="col-md-12 mt-50">
        <div class="container">
          <div class="row justify-content-center mt-50">
            <div class="col-10 row justify-content-center">
              <div class="col-12 col-md-2 header-left-label">STEP: 1</div>
              <div class="col-12 col-md-8 row header-right-label">
                <label class="col-12">Complete The Following Documents:</label>
              </div>
              <div class="col-2 d-none d-md-block d-lg-block d-xl-block">
                <img
                  class="header-right-img"
                  src="assets/img/common/medicare-part-b/paper-pen.png"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 row justify-content-center">
              <ul class="col-7 enrollment-req-list">
                <li>The Medicare <b>Part B Enrollment Form</b></li>
                <li>The Request for <b>Employment Information Form</b></li>
                <li>An Enrollment Request Letter (Optional)</li>
              </ul>
            </div>
          </div>

          <div class="row justify-content-center mt-50">
            <div class="col-10 row justify-content-center">
              <div class="col-12 col-md-2 header-left-label">STEP: 2</div>
              <div class="col-12 col-md-8 row header-right-label">
                <label class="col-12">Submit Your Enrollment Documents:</label>
              </div>
              <div class="col-2 d-none d-md-block d-lg-block d-xl-block">
                <img
                  class="header-right-img"
                  src="assets/img/common/medicare-part-b/paper-mail.png"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 row justify-content-center">
              <ul class="col-7 enrollment-req-list">
                <label>To Your Local Social Security Office</label>
                <li>
                  <b>In-Person</b>
                </li>
                <li>
                  <b>Mail Them</b>
                </li>
                <li>
                  <b>Fax Them</b>
                </li>
              </ul>
            </div>
          </div>

          <div class="row justify-content-center mt-50">
            <div class="col-10 row justify-content-center">
              <div class="col-12 col-md-2 header-left-label">STEP: 3</div>
              <div class="col-12 col-md-8 row header-right-label">
                <label class="col-12"
                  >Confirm The Processing Of Your Documents:</label
                >
              </div>
              <div class="col-2 d-none d-md-block d-lg-block d-xl-block">
                <img
                  class="header-right-img"
                  src="assets/img/common/medicare-part-b/mail-box.png"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 row justify-content-center">
              <ul class="col-7 enrollment-req-list">
                <label
                  >To make sure your forms have been received and are being
                  processed in the appropriate manner, there are 2<br />
                  options:
                </label>
                <li>Wait For Confirmation To Come In the Mail</li>
                <li>Speak With Them On The Phone</li>
              </ul>
            </div>
          </div>

          <div class="row justify-content-center mt-50">
            <button
              id="chat_now_pbe"
              class="btn btn-first padding-20"
              style="font-size: 35px"
              (click)="onClickChat($event)"
            >
              CALL US OR CHAT NOW TO BEGIN
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 row">
      <div class="col-md-12 mt-50">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div
              class="row col-12 justify-content-center text-center padding-50"
            >
              <h2>3 Enjoyable Options To Choose From</h2>
              <h5 class="normal mt-20">
                Click to Choose Any of The Options Below:
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div
        class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block"
        style="z-index: 1"
      >
        <i class="fa fa-arrow-down" style="color: #007bff"></i>
        <div class="banner-pointer-background inverse-colored-down-arrow"></div>
      </div>

      <div class="col-md-12 blue-bg neg-83-margin-top">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div
              class="row col-12 justify-content-center text-center padding-50 mt-50"
            >
              <a
                href="tel:18002155108"
                class="col-md-3 row justify-content-center card-option"
              >
                <div class="col-12 center">
                  <h5>Call Us Now</h5>
                </div>
                <div class="col-8 center mt-30">
                  <div class="thumbnail1"></div>
                </div>
                <div class="col-12">&nbsp;</div>
              </a>

              <a
                href="https://brightprice.gigabook.com/book/all#panel_step_choose"
                target="_blank"
                class="col-md-3 row justify-content-center card-option"
              >
                <div class="col-12 center">
                  <h5>Schedule a Time<br />For Later</h5>
                </div>
                <div class="col-8 center mt-30">
                  <div class="thumbnail2"></div>
                </div>
                <div class="col-12">&nbsp;</div>
              </a>

              <div
                (click)="onClickChat($event)"
                class="col-md-3 row justify-content-center card-option"
                id="send_msg_ntm"
              >
                <div class="col-12 center">
                  <h5>Send Us a Message</h5>
                </div>
                <div class="col-8 center mt-30">
                  <div class="thumbnail3"></div>
                </div>
                <div class="col-12">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 blue-bg" style="height: 200px; margin-top: -20px">
        <div class="container"></div>
      </div>

      <div class="col-md-12">
        <div class="container">
          <div class="row flex-md-row align-items-center">
            <div class="row col-12 justify-content-center text-center">
              <div class="col-10 row justify-content-center lets-talk">
                <h3>Not Sure What You Need? Let's Talk.</h3>
                <p>
                  Reserve your own dedicated time with an in-house counselor to
                  receive clear<br />
                  explanations, a full consultation, or a brief "Coverage
                  Clarity" session to better<br />
                  understand your own current coverage.
                </p>
                <button
                  class="col-md-5 btn btn-first-plain padding-20 width-auto"
                  (click)="speakToCounselorEvent(speakToCounselorModal, 540)"
                >
                  Speak With a Concierge >>
                </button>
              </div>
              <div class="col-10 row justify-content-center">
                <img
                  src="assets/img/common/medicare/old-woman-with-a-kit-half.png"
                  class="lets-talk-old-woman"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="d-none">
  <div #videoContent>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <div
          class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true"
          style="height: 100%; position: relative; width: 100%"
        >
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            "
          >
            <img
              src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch"
              style="
                filter: blur(5px);
                height: 100%;
                object-fit: contain;
                width: 100%;
              "
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <span class="wistia_embed wistia_async_9rgfxy7pqm popover=true popoverContent=link" style="display:inline;position:relative"><a href="#">This is my popover!</a></span> -->

<div class="d-none {{ dblocking }}" #speakToCounselorModal>
  <app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>
