<!-------------------------------------------------------------------------------------------------------------
----------------------------Show if Route is NOT in INTERACTIVE WELCOME ---------------------------
--------------------------------------------------------------------------------------------------------------->
<footer class="mx-0 row text-sm-left" *ngIf="footer.visible && !footer.activatedUrl.includes('interactive')">
    
    <!-- don't show footer primary if on beneficiary-dashboard -->
    <div class="footer-primary footer-section" *ngIf="footer.fullPrimaryFooter && !header.isRetireeDashboard">
        <div class="container-fluid footer-top px-sm-0" *ngIf="footer.footerTop">
            <div class="container footer-links-section" [class.noMarginTop]="footer.noMarginTop">
                <div class="row">
                    <div class="col-12 col-sm-10">
                        <div class="row footer-quick-links-footer-info-wrapper">
                            <div class="footer-quick-links-wrapper">
                                <h3>Quick Links</h3>
                                <ul class="quick-links d-flex flex-wrap flex-column flex-lg-row">
                                    <li class="col-12 col-md-6"><a routerLink="/insurance-marketplace" routerLinkActive="active">Plans</a>
                                    </li>
                                    <li class="col-12 col-md-6"><a routerLink="/about" routerLinkActive="active">About</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/medicare-services" routerLinkActive="active">Our Services</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/community" routerLinkActive="active">Community</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/become-a-contributor" routerLinkActive="active">Become a Contributor</a>
                                    </li>
                                    <li class="col-12 col-md-6"><a routerLink="/education" routerLinkActive="active">Education Center</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/partnership-opportunities" routerLinkActive="active">Partnership
                                            Opportunities</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/i-am-new-to-medicare" routerLinkActive="active">New to Medicare?</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/login" routerLinkActive="active">Login</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/terms-of-services" routerLinkActive="active">Terms of Services</a></li>
                                    <li class="col-12 col-md-6"><a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></li>
                                </ul>
                            </div>
                            <div class="footer-contact-info">
                                <h3>Contact Info</h3>
                                <p>
                                    <b>TapTap Medicares</b><br>
                                    230 S. Broad St. 17th Floor<br>
                                    Philadelphia, PA 19102
                                    <!-- The BrightPrice Group <br>
                                    2001 Market St. 25th Floor<br>
                                    Philadelphia, PA 19103 -->
                                </p>
                                <p class="mb-0 force-white-color">Toll Free Phone: <b class="f-weight-800">1(800) 215-5108</b></p>
                                <p class="force-white-color">Member Line: <b class="f-weight-800">1(844) 200-8433</b></p>
                                <p><a href="mailto:Team@TapTapMedicare.com" class="force-white-color"><b class="f-weight-800">Team@TapTapMedicare.com</b></a></p>
                                <ul class="footer-social-icon">
                                    <li><a href="https://www.facebook.com/taptapmedicareofficial" target="_blank"><span
                                                class="fa fa-facebook-f"></span></a></li>
                                    <!-- <li><a href="https://twitter.com/TapTapMedicare" target="_blank"><span class="fa fa-twitter"></span></a></li>
                                    <li><a href="https://www.pinterest.com/a245809541e5870293dd2e0684cf0d/overview/" target="_blank"><span class="fa fa-pinterest"></span></a></li> -->
                                    <li><a href="https://www.instagram.com/taptapmedicare" target="_blank"><span class="fa fa-instagram"></span></a></li>
                                <li><a href="https://www.youtube.com/channel/UCf-zYgKrvp988N9j_ScXFuA" target="_blank"><span class="fa fa-youtube"></span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="disclaimer">
                                <h6>Quick & Simple Disclaimer:</h6>
                                <p>TapTap Medicare is privately owned and operated as 
                                    a non-government resource for those looking for 
                                    guidance and clarity regarding Medicare and related 
                                    insurance planning topics. If you need to visit the Federal Government’s 
                                    official Medicare website, you can access it here: <a href="https://www.medicare.gov/" target="_blank" style="font-size:inherit">www.medicare.gov</a></p>
                                <p> This website and all of the content provided is for
                                     education and illustration purposes only. 
                                     We recommend you speak with a TapTap Counselor 
                                     before applying for coverage through our site. 
                               </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-2 d-flex flex-column flex-lg-row align-items-start">
                        <div class="d-flex align-items-center align-items-md-center footer-logo align-self-end">
                            <a routerLink="/"><img src="assets/img/common/logo-white.png" alt="company-logo"
                                    class="company-logo-footer"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid footer-copyright-section d-flex">
            <div class="container d-flex align-items-center">
                <p class="footer-credits">&copy; {{ dateNow }} Taptap Medicare. All Rights Reserved. | <button routerLink="/privacy-policy" class="btn-plain">Privacy Policy</button></p>   
            </div>
        </div>
    </div>

    <!-- show loggedin but not in beneficiary-dashboard -->
    <div class="footer-retiree-dashboard" *ngIf="!footer.fullPrimaryFooter && !header.isRetireeDashboard">
        <div class="footer-retiree-dashboard-top">
            <div class="container">
                <div class="row logo-help">
                    <a routerLink="/"><img src="assets/img/common/logo-white.png" alt="company-logo"
                        class="retiree-company-logo-footer"></a>
                    <div class="logo-help-right">
                        <p class="fWeight-600">NEED HELP?</p>
                        <p class="fWeight-100">Toll Free Medicare: 1(800) 215-5108 &nbsp;&nbsp;&nbsp; Counselor Line: 1(844) 200-8433</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-retiree-dashboard-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 text-center text-md-left">
                        <p class="footer-retiree-dashboard-color">{{ dateNow }} Taptap Medicare. All Rights Reserved.</p>
                    </div>
                    <div class="col-12 col-md-6 text-center text-md-right">
                        <a routerLink="/terms-and-conditions" class="footer-retiree-dashboard-color ml-md-3 ml-0">Terms & Conditions</a>
                        <span class="ml-md-3 ml-0">|</span>
                        <a routerLink="/privacy-policy" class="footer-retiree-dashboard-color ml-md-3 ml-0">Privacy Policy</a>       
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- show if on beneficiary-dashboard -->
    <div class="footer__beneficiary" *ngIf="header.isRetireeDashboard">
        
        <div class="footer__beneficiary_left">
            <!-- <p><span class="fWeight-500">NEED HELP? Toll</span> &nbsp; Toll Free Medicare: 1(800) 215-5108 &nbsp;&nbsp; Counselor Line: 1(844) 200-8433</p> -->
            <div class="d-flex align-items-center">
                <button class="icon-button mx-1" (click)="showSupportOptions()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z"/></svg>
                    <span class="tooltip1 px-2 bg-dark text-light">Click To Chat</span>
                </button>
                <button class="icon-button mx-1" routerLink="/benny-dash/insights">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"/></svg>
                    <span class="tooltip2 px-2 bg-dark text-light">HQ Home</span>
                </button>
                <button class="icon-button mx-1" (click)="showModal(shareThisPlan)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M503.7 226.2l-176 151.1c-15.38 13.3-39.69 2.545-39.69-18.16V272.1C132.9 274.3 66.06 312.8 111.4 457.8c5.031 16.09-14.41 28.56-28.06 18.62C39.59 444.6 0 383.8 0 322.3c0-152.2 127.4-184.4 288-186.3V56.02c0-20.67 24.28-31.46 39.69-18.16l176 151.1C514.8 199.4 514.8 216.6 503.7 226.2z"/></svg>
                    <span class="tooltip2 px-2 bg-dark text-light">Share With Others</span>
                </button>
                <!-- <p class="mx-2 mb-0">Toll Free Medicare: <strong>1(800) 215-5108</strong></p> -->
            </div>
        </div>
        <div class="footer__beneficiary_right text-right">
            <p>
                Toll Free TapTap Benny Line: <strong>1(800)215-5108</strong>
                <!-- {{ dateNow }} Taptap Medicare. All Rights Reserved.
                <a routerLink="/terms-and-conditions" class="footer-retiree-dashboard-color ml-md-3 ml-0">Terms & Conditions</a>
                <span class="ml-md-3 ml-0">|</span>
                <a routerLink="/privacy-policy" class="footer-retiree-dashboard-color ml-md-3 ml-0">Privacy Policy</a>        -->
            </p>
        </div>
    </div>
</footer>


<div class="d-none">
    <div #shareThisPlan>
        <app-share-form></app-share-form>
    </div>
</div>