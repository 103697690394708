<h4 class="header bg-primary">Acknowledgement</h4>
<div class="bordered-wrapper">
  <h5 class="uppercase fColor-black fWeight-600 mb-4 text-center">I Confirm To You That:</h5>
  <h6 class="fWeight-600">Important Notices:</h6>
  <ul>
    <li>I can access and read this "Consent to Use of Electronic Signatures and Receipt of Disclosures Exclusively Through Electronic Means;" and</li>
    <li>I am able to send email and receive email with hyperlinks to Web sites and attached files; and</li>
    <li>Until or Unless I notify TapTap Medicare as described above, I consent to receive all required notices and disclosures relating to my application for insurance exclusively through electronic means; and</li>
    <li>I also consent to the use of electronic signatures in connection with my insurance application in place of handwritten signatures; and</li>
    <li>I am authorized to consent.</li>
  </ul>
  <br>
  <h5 class="uppercase fColor-black fWeight-600 mb-3 text-center">Fraud Warning</h5>
  <p>Any person who, with intent to defraud or knowing that he/she is facilitating a fraud against an insurer, submits an application containing a false, incomplete or deceptive statement may be guilty of insurance fraud.</p>
  <p>By Checking The Boxes and Entering My Name Below I Am Indicating My Intent To Electronically Sign This Application and Warrant That All Of The Information I Have Provided Is True, Complete, and Accurate.</p>

  <div class="d-flex justify-content-center">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="iAgree_acknowledgement" (change)="onChange_IAgree_acknowledgement($event)">    
      <label class="custom-control-label" for="iAgree_acknowledgement">I Agree</label>    
    </div>
  </div>
  <div *ngIf="isIAgree" class="row justify-content-center">
    <div class="form-group col-12 col-md-4 p-2">
      <label for="fname" class="text-label">First Name</label>
      <input type="text" class="form-control blurred" id="fname"/>
    </div>
    <div class="form-group col-12 col-md-4 p-2">
      <label for="lname" class="text-label">Last Name</label>
      <input type="text" class="form-control blurred" id="lname"/>
    </div>
    <div class="form-group col-12 col-md-3 p-2">
      <label for="date" class="text-label">Date</label>
      <input type="text" class="form-control blurred" id="date"/>
    </div>
  </div>
</div>