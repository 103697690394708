<h4 class="uppercase text-center">Consumer Reviews</h4>
<div class="text-center modal-popupWrapper modal-consumerReview mt-5">
  <img onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
      src="{{ image }}" class="modal-companyLogo">

  <div class="d-flex align-items-center justify-content-center star-rating">
    <div class="mr-2" *ngIf="+consumerRating > 0">
      <i class="fa fColor-orange {{consumerRating > 0 && consumerRating < 1  ? 'fa-star-half-o' : consumerRating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa fColor-orange {{consumerRating > 1 && consumerRating < 2  ? 'fa-star-half-o' : consumerRating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa fColor-orange {{consumerRating > 2 && consumerRating < 3  ? 'fa-star-half-o' : consumerRating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa fColor-orange {{consumerRating > 3 && consumerRating < 4  ? 'fa-star-half-o' : consumerRating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa fColor-orange {{consumerRating > 4 && consumerRating < 5  ? 'fa-star-half-o' : consumerRating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
    </div>
    <span class="fWeight-600 fColor-orange mb-4 mt-3" *ngIf="+consumerRating > 0">{{ consumerRating }}</span>
    <span class="fWeight-600 fColor-orange mb-4 mt-3 d-none" *ngIf="+consumerRating <= 0 && +type !== 2">Plan Is New</span>
  </div>

  <p *ngIf="+consumerRating > 0" class="rating-heading">Here are the Consumer Reviews on <span class="fWeight-600">{{ companyName }}</span> that have been rated as the most helpful by fellow Consumers:</p>

  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let review of reviewResult;let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
        <div class="row mx-0 my-0 my-sm-4">
          <div class="col-12 col-sm-6 row mx-0 px-0">
            <div class="mx-auto star-rating-section" *ngIf="type != 2">
                <span class="fWeight-500 fColor-blue">Service Score:</span>
                <i class="fa fColor-orange {{review.customer_service > 0 && review.customer_service < 1  ? 'fa-star-half-o' : review.customer_service >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
                <i class="fa fColor-orange {{review.customer_service > 1 && review.customer_service < 2  ? 'fa-star-half-o' : review.customer_service >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
                <i class="fa fColor-orange {{review.customer_service > 2 && review.customer_service < 3  ? 'fa-star-half-o' : review.customer_service >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
                <i class="fa fColor-orange {{review.customer_service > 3 && review.customer_service < 4  ? 'fa-star-half-o' : review.customer_service >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
                <i class="fa fColor-orange {{review.customer_service > 4 && review.customer_service < 5  ? 'fa-star-half-o' : review.customer_service >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>

            <div class="mx-auto star-rating-section" *ngIf="type == 2">
              <span class="fWeight-500 fColor-blue">Ease of Use:</span>
              <i class="fa fColor-orange {{review.ease_of_use > 0 && review.ease_of_use < 1  ? 'fa-star-half-o' : review.ease_of_use >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.ease_of_use > 1 && review.ease_of_use < 2  ? 'fa-star-half-o' : review.ease_of_use >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.ease_of_use > 2 && review.ease_of_use < 3  ? 'fa-star-half-o' : review.ease_of_use >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.ease_of_use > 3 && review.ease_of_use < 4  ? 'fa-star-half-o' : review.ease_of_use >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.ease_of_use > 4 && review.ease_of_use < 5  ? 'fa-star-half-o' : review.ease_of_use >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
          </div>
          </div>
          <div class="col-12 col-sm-6 row mx-0 px-0">
            <div class="mx-auto star-rating-section" *ngIf="type != 2">
              <span class="fWeight-500 fColor-blue">Consumer Clarity Score:</span>
              <i class="fa fColor-orange {{review.consumer_clarity > 0 && review.consumer_clarity < 1  ? 'fa-star-half-o' : review.consumer_clarity >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_clarity > 1 && review.consumer_clarity < 2  ? 'fa-star-half-o' : review.consumer_clarity >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_clarity > 2 && review.consumer_clarity < 3  ? 'fa-star-half-o' : review.consumer_clarity >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_clarity > 3 && review.consumer_clarity < 4  ? 'fa-star-half-o' : review.consumer_clarity >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_clarity > 4 && review.consumer_clarity < 5  ? 'fa-star-half-o' : review.consumer_clarity >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>

            <div class="mx-auto star-rating-section" *ngIf="type == 2">
              <span class="fWeight-500 fColor-blue">Price Stability:</span>
              <i class="fa fColor-orange {{review.price_stability > 0 && review.price_stability < 1  ? 'fa-star-half-o' : review.price_stability >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 1 && review.price_stability < 2  ? 'fa-star-half-o' : review.price_stability >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 2 && review.price_stability < 3  ? 'fa-star-half-o' : review.price_stability >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 3 && review.price_stability < 4  ? 'fa-star-half-o' : review.price_stability >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 4 && review.price_stability < 5  ? 'fa-star-half-o' : review.price_stability >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>
          </div>
        </div>
        <div class="row mx-0 my-0 my-sm-4">
          <div class="col-12 col-sm-6 row mx-0 px-0">
            <div class="mx-auto star-rating-section" *ngIf="type != 2">
              <span class="fWeight-500 fColor-blue">Price Stability Score:</span>
              <i class="fa fColor-orange {{review.price_stability > 0 && review.price_stability < 1  ? 'fa-star-half-o' : review.price_stability >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 1 && review.price_stability < 2  ? 'fa-star-half-o' : review.price_stability >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 2 && review.price_stability < 3  ? 'fa-star-half-o' : review.price_stability >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 3 && review.price_stability < 4  ? 'fa-star-half-o' : review.price_stability >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.price_stability > 4 && review.price_stability < 5  ? 'fa-star-half-o' : review.price_stability >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>

            <div class="mx-auto star-rating-section" *ngIf="type == 2">
              <span class="fWeight-500 fColor-blue">Consumer Rating:</span>
              <i class="fa fColor-orange {{review.consumer_rating > 0 && review.consumer_rating < 1  ? 'fa-star-half-o' : review.consumer_rating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_rating > 1 && review.consumer_rating < 2  ? 'fa-star-half-o' : review.consumer_rating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_rating > 2 && review.consumer_rating < 3  ? 'fa-star-half-o' : review.consumer_rating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_rating > 3 && review.consumer_rating < 4  ? 'fa-star-half-o' : review.consumer_rating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.consumer_rating > 4 && review.consumer_rating < 5  ? 'fa-star-half-o' : review.consumer_rating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>
          </div>
          
          <div class="mx-auto star-rating-section" *ngIf="type == 2">
            <span class="fWeight-500 fColor-blue">Consumer Clarity:</span>
            <i class="fa fColor-orange {{review.consumer_clarity > 0 &&  review.consumer_clarity < 1  ? 'fa-star-half-o' :  review.consumer_clarity >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
            <i class="fa fColor-orange {{review.consumer_clarity > 1 &&  review.consumer_clarity < 2  ? 'fa-star-half-o' :  review.consumer_clarity >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
            <i class="fa fColor-orange {{review.consumer_clarity > 2 &&  review.consumer_clarity < 3  ? 'fa-star-half-o' :  review.consumer_clarity >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
            <i class="fa fColor-orange {{review.consumer_clarity > 3 &&  review.consumer_clarity < 4  ? 'fa-star-half-o' :  review.consumer_clarity >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
            <i class="fa fColor-orange {{review.consumer_clarity > 4 &&  review.consumer_clarity < 5  ? 'fa-star-half-o' :  review.consumer_clarity >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
          </div>

          <div class="col-12 col-sm-6 row mx-0 px-0">
            <div class="mx-auto star-rating-section" *ngIf="type != 2">
              <span class="fWeight-500 fColor-blue">Overall Score:</span>
              <i class="fa fColor-orange {{review.overall_score > 0 &&  review.overall_score < 1  ? 'fa-star-half-o' :  review.overall_score >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 1 &&  review.overall_score < 2  ? 'fa-star-half-o' :  review.overall_score >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 2 &&  review.overall_score < 3  ? 'fa-star-half-o' :  review.overall_score >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 3 &&  review.overall_score < 4  ? 'fa-star-half-o' :  review.overall_score >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 4 &&  review.overall_score < 5  ? 'fa-star-half-o' :  review.overall_score >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>
          </div>

          <div class="col-12 row mx-0 px-0 mt-15" style="margin-top:25px">
            <div class="mx-auto star-rating-section" *ngIf="type == 2">
              <span class="fWeight-500 fColor-blue">Service Score:</span>
              <i class="fa fColor-orange {{review.customer_service > 0 && review.customer_service < 1  ? 'fa-star-half-o' : review.customer_service >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.customer_service > 1 && review.customer_service < 2  ? 'fa-star-half-o' : review.customer_service >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.customer_service > 2 && review.customer_service < 3  ? 'fa-star-half-o' : review.customer_service >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.customer_service > 3 && review.customer_service < 4  ? 'fa-star-half-o' : review.customer_service >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.customer_service > 4 && review.customer_service < 5  ? 'fa-star-half-o' : review.customer_service >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>

            <div class="mx-auto star-rating-section" *ngIf="type == 2">
              <span class="fWeight-500 fColor-blue">Overall Score:</span>
              <i class="fa fColor-orange {{review.overall_score > 0 &&  review.overall_score < 1  ? 'fa-star-half-o' :  review.overall_score >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 1 &&  review.overall_score < 2  ? 'fa-star-half-o' :  review.overall_score >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 2 &&  review.overall_score < 3  ? 'fa-star-half-o' :  review.overall_score >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 3 &&  review.overall_score < 4  ? 'fa-star-half-o' :  review.overall_score >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
              <i class="fa fColor-orange {{review.overall_score > 4 &&  review.overall_score < 5  ? 'fa-star-half-o' :  review.overall_score >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
            </div>
          </div>
        </div>
        <div class="message">
          {{review.review}}
          <i class="fa fa-quote-left"></i>
        </div>
        <div class="consumerReview-comment-wrapper">
          <p class="author-name">{{review.reviewer_name}}</p>
        </div>
      </div>
    </div>
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators"  *ngFor="let item of reviewResult;let index = index" [attr.data-slide-to]="index"></li>
    </ol>
    <a class="carousel-nav-btn carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"  *ngIf="reviewResult.length > 0">
      <i class="fas fa-chevron-left"></i>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-nav-btn carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next" *ngIf="reviewResult.length > 0">
      <i class="fas fa-chevron-right"></i>
      <span class="sr-only">Next</span>
    </a>
  </div>

  <div class="d-flex justify-content-center align-items-center mt-5">
    <button class="btn btn-first col-12 col-sm-5 text-center" (click)="showSubmitAReview(submitAReviewModal)">Submit a Review</button>
    <!--<button class="btn btn-first col-5">Discuss in Community</button>-->
  </div>
</div>

<div class="d-none">
  <!-- <div #submitAReviewModal>
    <app-submit-a-review
      pdsCode="{{ this.pdsCode }}"
      type="{{ this.type }}"
    >
    </app-submit-a-review>
  </div> -->
  <div #submitAReviewModal>
    <app-submit-a-review
      pdsCode="{{ this.pdsCode }}"
      type="{{ this.type }}"
      id="{{ this.id }}"
    >
    </app-submit-a-review>
  </div>
</div>