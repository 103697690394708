<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">Medicare Part D Drug Coverage Overview
					</h1>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">        
        <img src="./assets/img/learn-more/44775471_m.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<div class="container">
  <h5 class="fColor-base">Overview</h5>
  <p><strong class="fWeight-500">The Coverage Structure</strong> of your Medicare drug coverage is determined by whether you have Coverage Option 1 (Medicare Supplement coverage) or Coverage Option 2 (Medicare Advantage coverage).</p>
  <p><strong class="fWeight-500">The Way Your Drug Coverage Works</strong> and the factors that determine your drug costs, however, are exactly the same regardless of your Coverage Option.</p>

    <div class="coverage-content">
      <div class="overview-coverage-4-boxes-wrapper-heading"><span>Coverage Option 1</span><h4>Your drug coverage is a separate policy known as a Standalone Part D Drug Plan.</h4></div>
      <div class="list-container">
        <div class="list-group fWeight-600">
          <span class="fWeight-600">The Structure of this Coverage Setup is:</span>
          <ul type="square">
            <li>Original Medicare as Your Primary for Major-Medical</li>
            <li>A Medigap Plan as Your Secondary for Major-Medical</li>
            <li>A "Standalone" Part D Plan as Your Drug Coverage</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="overview-coverage-4-boxes-wrapper">
      <div class="overview-coverage-4-inner">
        <h6>Primary Coverage</h6>
        <p>Original Medicare</p>
        <img src="./assets/img/retiree-dashboard/original-medicare-card.png" alt="">
      </div>
      <div class="rd-oc-box-v1-plus">
        <i class="fa fa-plus"></i>
      </div>
      <div class="overview-coverage-4-inner">
        <h6>Secondary Coverage</h6>
        <p>Medicare Supplement</p>
        <img src="./assets/img/retiree-dashboard/overview-coverage_box-secondary.png" alt="">
        <b>Medigap</b>
      </div>
      <div class="rd-oc-box-v1-plus">
        <i class="fa fa-plus"></i>
      </div>
      <div class="overview-coverage-4-inner rd-highlighted">
        <h6>Drug Coverage</h6>
        <p>Standalone Part D Plans</p>
        <img src="./assets/img/retiree-dashboard/overview-coverage_box-drug.png" alt="">
        <b>Medicare part D Drug Plan</b>
      </div>
      <div class="rd-oc-box-v1-plus">
        <i class="fa fa-plus"></i>
      </div>
      <div class="overview-coverage-4-inner">
        <h6>Dental Coverage</h6>
        <p>Individual Dental Policy</p>
        <img src="./assets/img/retiree-dashboard/overview-coverage_box-dental.png" alt="">
        <b>Dental Insurance</b>
      </div>
    </div>
</div>

<div class="container mt-5">
  <div class="coverage-content">
    <div class="overview-coverage-4-boxes-wrapper-heading"><span>Coverage Option 2</span><h4>Your drug coverage is combined into your Medicare Advantage Plan.</h4></div>
    <div class="list-container">
      <div class="list-group fWeight-600">
        <span class="fWeight-600">The Structure of this Coverage Setup is:</span>
        <ul type="square">
          <li>Original Medicare is replaced by your Medicare Advantage plan</li>
          <li>Your Medicare Advantage plan wraps together all of the benefits of Medicare Part A, Part B, and Part D into one plan</li>
          <li>This Becomes Your Primary Coverage for major-medical healthcare services as well as prescription drugs</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="coverage-option-2-group">
    <div class="left-content">
      <span class="d-block">
        Original Medicare is <b>NOT</b> your Primary Coverage
      </span>
      <img class="mt-3" src="./assets/img/learn-more/Group 310.png" alt="">
    </div>
    <img class="option-2-right-arrow" src="./assets/img/beneficiary/benny-nav/benny-dash-arrow-right.png"/>
    <div class="right-content">
      <span class="d-block">
        <b class="color-primary">MEDICARE ADVANTAGE</b> is Your <b>PRIMARY AND</b> Drug Coverage & Dental, Vision & Hearing
      </span>
      <img class="mt-3" src="./assets/img/learn-more/Group 311.png" alt="">
    </div>
  </div>
</div>

<div class="container mt-5">
  <div class="informative-content">
    <h2 class="fColor-black">When Is Your Part D Drug Coverage Used?</h2>
    <p>
      Your Medicare Part D drug coverage, whether it is an individual “Standalone” Part D drug plan or it is combined within your Medicare Advantage plan, is for all prescription drug needs from a pharmacy or mail-order system.
    </p>
    <p>
      Any drugs provided in an inpatient hospital setting or in an outpatient setting, such as a doctor's office or clinic, will fall under the Major Medical part of your coverage.
    </p>
  </div>
</div>

<div class="container mt-5">
  <div class="informative-content">
    <h2 class="fColor-black">The Four “Need-to-Knows” of Part D Drug Coverage:</h2>
    <span class="fColor-black fWeight-600">FORMULARIES:</span>
    <p>
      Each drug coverage has a unique Formulary, which determines which "Tiers' your drugs fall into. Matching the plan's formulary with your specific drugs will give you the lowest copays.
    </p>
    <span class="fColor-black fWeight-600">COVERAGE STAGES:</span>
    <p>
      Your costs depend on the "Coverage Stage" you are in at that particular part of the year. There are 4 stages: Deductible Stage, Regular Stage, Donut Hole, & Post-Donut Hole
    </p>
    <span class="fColor-black fWeight-600">PHARMACY NETWORKS:</span>
    <p>
      You can use any pharmacy with your plan (or mail order) - but some pharmacies will be "preferred" with your plan, which will have lower copays
    </p>
    <span class="fColor-black fWeight-600">COVERAGE SUITABILITY:</span>
    <p>
      The "right" drug coverage for you is based on your unique situation - your specific prescriptions, dosages, frequency of refills, & preference of pharmacy.
    </p>
  </div>
</div>

<div class="container mt-4">
  <div class="additional-note-wrapper">
    <img src="./assets/img/common/medigap/pink-lady.png" alt="additional note">
    <div>
      <p>We explain each of these in more detail in our “Extra Guides” section.</p>
    </div>
  </div>
</div>

<div class="container mt-5">
  <div class="informative-content">
    <h2 class="fColor-black">The Big Picture On Part D Drug Coverage</h2>
    <ul type="square" class="circle-blue">
      <li>The Medicare Part D drug coverage system is overly complex.</li>
      <li>It was unfortunately not designed to be simple and clear, and it could and should be simpler for Medicare consumers.</li>
      <li>TapTap Medicare takes the confusion and complexity out of the system and provides a proactive service that ensures you have the optimal drug coverage for your particular scenario each year.</li>
    </ul>
  </div>
</div>

<section class="bg-primary px-5 pt-5 0 mt-5">
  <div class="container d-flex justify-content-center">
    <div>
      <h4 class="fColor-white text-center mb-4 fWeight-600">How To Choose A Part D Drug Coverage</h4>
      <div class="helpful-criteria-wrapper">
        <div class="helpful-criteria">
          <span>Rule 1</span>
          <span>Understand your times to enroll in a plan. Generally, this is within 3 months of starting Medicare, or the Annual Enrollment Period each year from October 15th to December 7th. Additionally, you may be eligible for Special Enrollment Periods throughout the year based on specific life circumstances.</span>
        </div>
        <div class="helpful-criteria">
          <span>Rule 2</span>
          <span>Evaluate the total costs associated with your drug coverage, which equals your monthly premium + your deductible + copays.</span>
        </div>
        <div class="helpful-criteria">
          <span>Rule 3</span>
          <span>Be aware of important characteristics of your drugs, such as formulary restrictions, quantity limits, availability as generics, have close substitutes that are much less expensive, or if they are less expensive outside the Medicare Part D System.</span>
        </div>
        <div class="helpful-criteria">
          <span>Rule 4</span>
          <span>Generally, you will pay the lowest costs with the plan that has a formulary that puts your particular drugs & dosages in the lowest “Tiers”.</span>
        </div>
        <div class="helpful-criteria">
          <span>Rule 5</span>
          <span>Determine which plan’s have your desired pharmacy as a “Preferred” Network pharmacy</span>
        </div>
      </div>

    </div>

  </div>
</section>


<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>