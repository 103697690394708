import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import  Swal  from 'sweetalert2';

@Component({
  selector: 'app-plans-drug-costs',
  templateUrl: './plans-drug-costs.component.html',
  styleUrls: ['./plans-drug-costs.component.scss']
})
export class PlansDrugCostsComponent implements OnInit {
  
  @Input() insurance_logo:any;
  @Input() drugBenefitTypeDetail:any;
  @Input() annualDrugDeductible:any;
  @Input() monthRate:any;
  @Input() preferredMailOrder: Array<Object> = [];
  @Input() standardPharmacies: Array<Object> = [];
  @Input() preferredRetail: Array<Object> = [];
  @Input() isShowTableOnly: boolean = false;


  drugsTier: Array<Object> = [];

  tableTitle:string;
  viewTable:boolean = false;
  isAddYourDrugs:boolean = false;
  isGeneralDrugCopay:boolean = false;
  
  constructor() {}

  viewGeneralDrugCopays(){
      this.isGeneralDrugCopay = true;
      this.isAddYourDrugs = false;
  }

  viewAddYourDrugs(){
    this.isAddYourDrugs = true;
    this.isGeneralDrugCopay = false;
  }

  viewDefault(){
    this.isGeneralDrugCopay = false;
    this.isAddYourDrugs = false;
  }

  ngOnInit() {}

  getConvertedRate(rate) {
        return parseFloat((rate * .01).toFixed(2));
  }

}
