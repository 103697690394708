import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dental-version2',
  templateUrl: './version2.component.html',
  styleUrls: ['./version2.component.scss']
})
export class Version2Component implements OnInit {

  @Input() results:any;

  @Input() controls:any;

  @Input() logComparedKeys: any;

  @Input() base_img_loc: any;

  @Input() isBlueBgHeading = true;

  @Input() dental_array_id = false;

  @Output() onClickPlanDocsResources = new EventEmitter();

  @Output() onClickCompanyProfile = new EventEmitter();

  @Output() onClickConsumerReview = new EventEmitter();

  @Output() onClickAdditionalInsights = new EventEmitter();

  @Output() onClickApplyOnline = new EventEmitter();

  @Output() onClickApplyOnThePhone = new EventEmitter();

  @Output() onClickAddToCompareFunc = new EventEmitter();

  addToCartLabel:string = 'Add to Cart';

  logCartKeys: Array<Object> = [];

  currentUrlPathName:any;
  
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('zz', changes)
  }

  companyLogoCheck(event) {
    $(event.target).parent().find('.no-img-plan-name').addClass('d-block').removeClass('d-none');
  }

  clickPlanDocsResources(event){
    this.onClickPlanDocsResources.emit(event);
  }

  companyProfile(result){
    this.onClickCompanyProfile.emit(result)
  }

  consumerReview(result){
    this.onClickConsumerReview.emit(result);
  }

  additionalInsights(result){
    this.onClickAdditionalInsights.emit(result)
  }

  applyOnline(result, index){
    this.onClickApplyOnline.emit({result,index});
  }

  applyOnThePhone(result){
    this.onClickApplyOnThePhone.emit(result);
  }

  addToCompareFunc(result, event, index, parent1, child){
    let vcb = document.querySelector('#view_compare_btn_'+index);
    vcb.classList.toggle('d-block');
    this.onClickAddToCompareFunc.emit({result, event, index, parent1, child});
  }
  

  onclick_planDocsResources(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.toggle('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.remove('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.toggle('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.toggle('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.remove('d-md-flex');

  }

  onclick_doctorDrugAnalysis(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.toggle('d-flex');
    pab.classList.remove('d-flex');
    
    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.toggle('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.toggle('d-md-flex');
    arrow3.classList.remove('d-md-flex');
    
  }
  
  onclick_planActionButtons(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.toggle('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.toggle('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.toggle('d-md-flex');

  }

  getExistingCartPlans() {
    let existingComparedEntries = JSON.parse(localStorage.getItem('add_to_cart_dental'));
    if (existingComparedEntries) {
        existingComparedEntries.forEach((element) => {
          this.logCartKeys.push(element.key);
        });
    }
  }

  onClick_AddToCart(result,event) {

    const addToCartBtn = document.querySelector('#ci_cart_btn__'+result.plan_pricing_id);
    const viewCartBtn = document.querySelector('#view_cart_btn_'+result.plan_pricing_id);

    if (event.target.checked) {

      this.addToCartLabel = 'Added to Cart';
      addToCartBtn.classList.add('active');

      var existingEntries = JSON.parse(localStorage.getItem('add_to_cart_dental'));
      if (existingEntries == null) {
        existingEntries = [];
      }

      existingEntries.push(result);
      localStorage.setItem('add_to_cart_dental', JSON.stringify(existingEntries));

      viewCartBtn.classList.add("d-block");
      viewCartBtn.classList.remove("d-none");

    } else {
      this.addToCartLabel = 'Add to Cart';
      addToCartBtn.classList.remove('active');
      this.removeToCart(result);
      viewCartBtn.classList.remove("d-block");
      viewCartBtn.classList.add("d-none");
    }
  }

  removeToCart(result) {

    const cartItem =  JSON.parse(window.localStorage.getItem('add_to_cart_dental'));

    if (cartItem) {
      for (let x = 0; x < cartItem.length; x++) {
        if (result['plan_pricing_id'] !== 'undefined' && cartItem[x]['plan_pricing_id'] !== 'undefined') {
          if (result['plan_pricing_id'] === cartItem[x]['plan_pricing_id']) {
            cartItem.splice(x, 1);
          }
        }
      }
    }

    localStorage.setItem('add_to_cart_dental', JSON.stringify(cartItem));

  }

  saveThisPlan(){    
    this.currentUrlPathName = window.location.pathname;
    console.log(this.currentUrlPathName);    
  }

}
