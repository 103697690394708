import { Component, OnInit, Input } from '@angular/core';
import Swal from "sweetalert2";
import {HeaderService} from '../../services/header.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-header-b',
  templateUrl: './header-b.component.html',
  styleUrls: ['./header-b.component.scss']
})
export class HeaderBComponent implements OnInit {
  @Input() showNumber: boolean = false;
  @Input() hasNoMarginTop: boolean = false;
  @Input() hasNoMarginBottom: boolean = false;
  @Input() isAepLandingPage: boolean = false;
  
  auth_token: any;
  fullPrimaryHeader:boolean = true;
  isLoggedIn:boolean = false;
  dblocking = 'd-none';

  constructor(public header: HeaderService, public authLogin: AuthService) { }

  ngOnInit() {      
      if(this.authLogin.isAuthenticated()){ // check if user is logged in
        this.isLoggedIn = true;
      }else{
        this.isLoggedIn = false;
      }
  }

  speakToCounselorEvent(modalViews: any) {
      Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,      
      width: 'auto',
      padding: '0px',
      customClass: {
        popup: 'speak-to-counselor-popup-box',
        closeButton: 'speak-to-counselor-popup-box-close-btn'
      }
    });  
  }
}
