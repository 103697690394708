import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-coverage-info',
  templateUrl: './coverage-info.component.html',
  styleUrls: ['./coverage-info.component.scss']
})
export class CoverageInfoComponent implements OnInit {

  @Input() details: any;

  constructor() { }

  ngOnInit() {
  }

  isOnChangeTopOption_1(event){}
  isOnChangeTopOption_3(event){}
  
}
