<div class="plan-selected">
  <div *ngIf="result && result.json_details" class="pns-box">
    <img class="" src="{{base_img_loc + replaceSpaceWith(result.json_details.organization_name) + '.jpg'}}" alt="">
    <p class="org-label text-center">{{ result.json_details.plan_name }}</p>
    <div class="arrow-up mx-auto"></div>
    <button class="btn btn-first btn-small bg-blue w-100 shadow-sm" (click)="openModal(networkStatus)">
      View Providers Network Status
    </button>
  </div>
  <div *ngIf="result && !result.json_details" class="pns-box">
    <p class="org-label-big text-center">{{ result.contract_id + '-' + result.plan_id }}</p>
    <div class="arrow-up mx-auto"></div>
    <button class="btn btn-first btn-small bg-blue w-100 shadow-sm" (click)="openModal(networkStatus)">
      View Providers Network Status
    </button>
  </div>
  <hr class="my-4">
  <p class="or-split">OR</p>
  <div class="pns-box">
    <img class="img-all" src="./assets/img/doctors-providers-new.png" alt="">
    <div class="arrow-up2 mx-auto"></div>
    <button class="btn-all btn btn-small btn-first w-100 shadow-sm" (click)="openLargeModal(allPlans)">
      <span>View Providers Status With <strong>ALL PLANS</strong></span>
    </button>
  </div>
</div>


<div class="d-none">
  <div #networkStatus>
    <div class="status-table-wrapper p-4 bg-primary">
      <h4 class="status-header text-center mb-3">
        Provider's Network Status
      </h4>
      <table class="network-status-table table table-striped table-borderless">
        <thead>
          <tr class="text-center">
            <td class="p-0">
              <div class="row">
                <div class="col-12 col-md-6 p-4 d-flex align-items-center justify-content-center">
                  <div class="dropdown">
                    <button class="plan-dropdown btn btn-secondary dropdown-toggle text-capitalize" type="button" data-toggle="dropdown" aria-expanded="false">
                      {{ selection }}
                    </button>
                    <div class="dropdown-menu">
                      <button *ngIf="selection == 'facilities'" class="dropdown-item" (click)="changeSelection('doctors')">Doctors</button>
                      <button *ngIf="selection == 'doctors'" class="dropdown-item" (click)="changeSelection('facilities')">Facilities</button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 p-4 bg-trans d-flex align-items-center justify-content-center">
                  <img class="plan-img" src="{{base_img_loc + replaceSpaceWith(result.json_details.organization_name) + '.jpg'}}" alt="">
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="selection == 'doctors'">
            <ng-container *ngFor="let provider of providers | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }" >
              <tr>
                <td class="p-0">
                  <div class="row">
                    <div class="col-12 col-md-6 p-3 d-flex align-items-center">
                      <div class="provider-wrapper d-flex align-items-center">
                        <img class="doctor mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
                        <div>
                          <p class="provider-name">{{provider.other_prefix}} {{provider.first_name}} {{provider.middle_name}} {{provider.last_name}} {{provider.suffix}} {{provider.credential_text}}</p>
                          <span class="provider-loc">{{ provider.mailing_first_address }}, {{ provider.mailing_city }} {{ provider.mailing_state }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 px-3 bg-trans d-flex align-items-center">
                      <div class="provider-status d-flex align-items-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                        <span 
                          [class.in-network]="provider.status == ''" 
                          [class.out-network]="provider.status == ''" 
                          [class.location]="provider.status == ''" 
                          [class.ppo-out]="provider.status == ''" 
                          [class.carrier-out]="provider.status == ''" 
                          [class.undetermined]="provider.status == 'Undetermined'"
                        >
                          {{ provider.status }}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selection == 'facilities'">
            <ng-container *ngFor="let provider of facilities | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }" >
              <tr>
                <td class="p-0">
                  <div class="row">
                    <div class="col-12 col-md-6 p-3 d-flex align-items-center">
                      <div class="provider-wrapper d-flex align-items-center">
                        <img class="facility mr-2" src="./assets/img/providers/hospital-icon.svg" alt="">
                        <div>
                          <p class="provider-name">{{provider.name}}</p>
                          <span class="provider-loc">{{ provider.mailing_first_address }}, {{ provider.mailing_city }} {{ provider.mailing_state }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 px-3 bg-trans d-flex align-items-center">
                      <div class="provider-status d-flex align-items-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                        <span 
                          [class.in-network]="provider.status == ''" 
                          [class.out-network]="provider.status == ''" 
                          [class.location]="provider.status == ''" 
                          [class.ppo-out]="provider.status == ''" 
                          [class.carrier-out]="provider.status == ''" 
                          [class.undetermined]="provider.status == 'Undetermined'"
                        >
                          {{ provider.status }}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
      <ng-container *ngIf="selection == 'doctors'">
        <div class="d-flex justify-content-center p-3">
          <app-custom-pagination [currentPage]="currentPage" [itemsPerPage]="itemsPerPage" [pageButtons]="5" [totalItems]="providers.length" (pageChanged)="onPageChange($event)"></app-custom-pagination>
        </div>
      </ng-container>
      <ng-container *ngIf="selection == 'facilities'">
        <div class="d-flex justify-content-center p-3">
          <app-custom-pagination [currentPage]="currentPage" [itemsPerPage]="itemsPerPage" [pageButtons]="5" [totalItems]="facilities.length" (pageChanged)="onPageChange($event)"></app-custom-pagination>
        </div>
      </ng-container>
    </div>
  </div>

  <div #allPlans>
    <div class="status-table-wrapper p-4 bg-primary">
      <h4 class="status-header text-center mb-3">
        Provider's Status With All Plans
      </h4>
      <div class="status-guide mb-3">
        <p class="status-title">These indicators describe the each plan's network status based on provider below:</p>
        <div class="row">
          <div class="col-12 col-md-4 p-2 d-flex align-items-center">
            <img class="icon-img mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
            <h6 class="status-label in-network">In-Network</h6>
          </div>
          <div class="col-12 col-md-4 p-2 d-flex align-items-center">
            <img class="icon-img mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
            <h6 class="status-label undetermined">Undetermined</h6>
          </div>
          <div class="col-12 col-md-4 p-2 d-flex align-items-center">
            <img class="icon-img mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
            <h6 class="status-label ppo-out">Takes This PPO Plan as Out-of-Network Provider</h6>
          </div>
          <div class="col-12 col-md-4 p-2 d-flex align-items-center">
            <img class="icon-img mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
            <h6 class="status-label out-network">Out-of-Network</h6>
          </div>
          <div class="col-12 col-md-4 p-2 d-flex align-items-center">
            <img class="icon-img mr-2" src="./assets/img/provider-network-status/location.png" alt="">
            <h6 class="status-label location">Not At This Location</h6>
          </div>
          <div class="col-12 col-md-4 p-2 d-flex align-items-center">
            <img class="icon-img mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
            <h6 class="status-label carrier-out">Take This Carrier’s Plan As An Out-of-Network Provider</h6>
          </div>
        </div>
      </div>
      <table class="network-status-table table table-striped table-borderless">
        <thead>
          <tr class="text-center">
            <td class="p-0">
              <div class="row">
                <div class="col-12 col-md-4 p-4 d-flex align-items-center justify-content-center">
                  <div class="dropdown">
                    <button class="plan-dropdown btn btn-secondary dropdown-toggle text-capitalize" type="button" data-toggle="dropdown" aria-expanded="false">
                      {{ selection }}
                    </button>
                    <div class="dropdown-menu">
                      <button *ngIf="selection == 'facilities'" class="dropdown-item" (click)="changeSelection('doctors')">Doctors</button>
                      <button *ngIf="selection == 'doctors'" class="dropdown-item" (click)="changeSelection('facilities')">Facilities</button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-2 p-2 bg-trans d-flex align-items-center justify-content-center">
                  <img class="plan-img" src="{{base_img_loc + replaceSpaceWith(result.json_details.organization_name) + '.jpg'}}" alt="">
                </div>
                <div class="col-12 col-md-2 p-2 d-flex align-items-center justify-content-center">
                  <img class="plan-img" src="{{base_img_loc + replaceSpaceWith(result.json_details.organization_name) + '.jpg'}}" alt="">
                </div>
                <div class="col-12 col-md-2 p-2 bg-trans d-flex align-items-center justify-content-center">
                  <img class="plan-img" src="{{base_img_loc + replaceSpaceWith(result.json_details.organization_name) + '.jpg'}}" alt="">
                </div>
                <div class="col-12 col-md-2 p-2 d-flex align-items-center justify-content-center">
                  <img class="plan-img" src="{{base_img_loc + replaceSpaceWith(result.json_details.organization_name) + '.jpg'}}" alt="">
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="selection == 'doctors'">
            <ng-container *ngFor="let provider of providers | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }" >
              <tr>
                <td class="p-0">
                  <div class="row">
                    <div class="col-12 col-md-4 p-3 d-flex flex-column justify-content-center">
                      <div class="provider-wrapper d-flex align-items-center">
                        <img class="doctor mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
                        <div>
                          <p class="provider-name">{{provider.other_prefix}} {{provider.first_name}} {{provider.middle_name}} {{provider.last_name}} {{provider.suffix}} {{provider.credential_text}}</p>
                          <p class="provider-loc">{{ provider.mailing_first_address }}, {{ provider.mailing_city }} {{ provider.mailing_state }}</p>
                          <button class="btn btn-first btn-xs view-profile" (click)="closeModal()"
                          [routerLink]="['/insurance-marketplace/provider-detail/', sumUpLocations, provider.other_prefix ? provider.other_prefix : 'null', provider.first_name, provider.middle_name, provider.last_name, provider.suffix ? provider.suffix : 'null', provider.credential_text ? provider.credential_text : 'null']">
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 bg-trans d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 bg-trans d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selection == 'facilities'">
            <ng-container *ngFor="let provider of facilities | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }" >
              <tr>
                <td class="p-0">
                  <div class="row">
                    <div class="col-12 col-md-4 p-3 d-flex align-items-center">
                      <div class="provider-wrapper d-flex align-items-center">
                        <img class="facility mr-2" src="./assets/img/providers/hospital-icon.svg" alt="">
                        <div>
                          <p class="provider-name">{{provider.name}}</p>
                          <span class="provider-loc">{{ provider.mailing_first_address }}, {{ provider.mailing_city }} {{ provider.mailing_state }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 bg-trans d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 bg-trans d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-md-2 px-3 d-flex align-items-center justify-content-center">
                      <div class="provider-status d-flex align-items-center justify-content-center">
                        <img *ngIf="provider.status == 'Undetermined'" class="mr-2" src="./assets/img/provider-network-status/undetermined.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/in-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/out-network.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/location.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/ppo-out.png" alt="">
                        <img *ngIf="provider.status == ''" class="mr-2" src="./assets/img/provider-network-status/carrier-out.png" alt="">
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
      <ng-container *ngIf="selection == 'doctors'">
        <div class="d-flex justify-content-center p-3">
          <app-custom-pagination [currentPage]="currentPage" [itemsPerPage]="itemsPerPage" [pageButtons]="5" [totalItems]="providers.length" (pageChanged)="onPageChange($event)"></app-custom-pagination>
        </div>
      </ng-container>
      <ng-container *ngIf="selection == 'facilities'">
        <div class="d-flex justify-content-center p-3">
          <app-custom-pagination [currentPage]="currentPage" [itemsPerPage]="itemsPerPage" [pageButtons]="5" [totalItems]="facilities.length" (pageChanged)="onPageChange($event)"></app-custom-pagination>
        </div>
      </ng-container>
    </div>
  </div>
</div>