import { Component, OnInit, Input } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ready-to-talk',
  templateUrl: './ready-to-talk.component.html',
  styleUrls: ['./ready-to-talk.component.scss']
})
export class ReadyToTalkComponent implements OnInit {

  @Input('isDefault') isDefault: boolean = true;
  @Input() isLandingPage: boolean = false;
  @Input() thirdVariation: boolean = false;
  @Input() spacerColor: string = 'transparent';
  
  constructor() { }

  ngOnInit() {
  }

  speaktoCounselor(modalViews){
    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 'auto',
      padding: '0',
      customClass: {
        popup: 'speak-to-counselor-popup-box',
        closeButton: 'speak-to-counselor-popup-box-close-btn'
      },
    });
  }
}