<div class="text-left">
  <div class="mb-3">
    <h5 class="fw-semibold text-dblue mb-0">What Is Your Home Address?</h5>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-sm-8 p-1">
        <label for="authorizedRepresentativeStreetAddress" class="text-label">Street Address</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeStreetAddress"/>
      </div>
      <div class="form-group col-12 col-sm-4 p-1">
        <label for="authorizedRepresentativeApt" class="text-label">Apt/Unit #</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeApt"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeCity" class="text-label">City</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeCity"/>
      </div>
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeState" class="text-label">State</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeState"/>
      </div>
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeZipCode" class="text-label">Zip Code</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeZipCode"/>
      </div>
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeCounty" class="text-label">County</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeCounty"/>
      </div>
    </div>
    <div class="p-1">
      <span class="text-dblue fw-medium">Is This Home Address Also Your (The Authorized Representative's) Mailing Address?</span>
      <div class="p-1">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="isHomeAddress_1" name="isHomeAddress" class="custom-control-input" value="yes" [(ngModel)]="isHomeAddress" (change)="onChange_isHomeAddress('yes')">
          <label class="custom-control-label fw-semibold text-primary" for="isHomeAddress_1">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="isHomeAddress_2" name="isHomeAddress" class="custom-control-input" value="no" [(ngModel)]="isHomeAddress" (change)="onChange_isHomeAddress('no')">
          <label class="custom-control-label fw-semibold text-primary" for="isHomeAddress_2">No</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isHomeAddress == 'no'">
    <h5 class="fw-semibold text-dblue mb-0">What Is Your Mailing Address?</h5>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-sm-8 p-1">
        <label for="authorizedRepresentativeStreetAddress" class="text-label">Street Address</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeStreetAddress"/>
      </div>
      <div class="form-group col-12 col-sm-4 p-1">
        <label for="authorizedRepresentativeApt" class="text-label">Apt/Unit #</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeApt"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeCity" class="text-label">City</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeCity"/>
      </div>
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeState" class="text-label">State</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeState"/>
      </div>
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeZipCode" class="text-label">Zip Code</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeZipCode"/>
      </div>
      <div class="form-group col-12 col-sm-6 p-1">
        <label for="authorizedRepresentativeCounty" class="text-label">County</label>
        <input type="text" class="form-control blurred" id="authorizedRepresentativeCounty"/>
      </div>
    </div>
  </div>
  <div class="p-1 mb-3">
    <span class="text-dblue fw-medium">Would You Like All Mail To Be Sent To You?</span>
    <div class="p-1">
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="allMailSent_1" name="allMailSent" class="custom-control-input" value="yes">
        <label class="custom-control-label fw-semibold text-primary" for="allMailSent_1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="allMailSent_2" name="allMailSent" class="custom-control-input" value="no">
        <label class="custom-control-label fw-semibold text-primary" for="allMailSent_2">No</label>
      </div>
    </div>
  </div>
</div>