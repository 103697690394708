<div class="retiree-navigation-wrapper">
  <div class="retiree-navigation-wrapper-box">
    <ul class="nav flex-column">
      <li class="nav-item nav-dropdown" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link top-level" routerLink="/beneficiary/my-core-info" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <img src="/assets/img/beneficiary/new-icon/My Core Info.png" alt="">
          <span>My Core Info</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
            <a (click)="findParentActive($event)" class="nav-link" routerLink="/beneficiary/my-core-info/basics-and-about-me" routerLinkActive="active">
              <img src="/assets/img/beneficiary/new-icon/Basics-_-About-Me.png" alt="">
              <span>Basics & About Me</span>
            </a>            
          </li>
          <!-- <li class="nav-item" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link">
              <img src="/assets/img/beneficiary/new-icon/My Financial Clarity.png" alt="">
              <span>My Financial Clarity</span>
            </a>            
          </li> -->
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/beneficiary/my-core-info/my-household">
              <img src="/assets/img/beneficiary/new-icon/My Household.png" alt="">
              <span>My Household</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" routerLink="/beneficiary/my-profiles/" routerLinkActive="active">
          <img src="/assets/img/beneficiary/new-icon/My Profiles.png" alt="">
          <span>My Profiles</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" routerLinkActive="active">
              <a class="nav-link">
                  <img src="/assets/img/beneficiary/new-icon/Overview.png" alt="">
                  <span>Overview</span>
              </a>
          </li>
          <li class="nav-item" 
              (click)="scrollToView('my-medicare-beneficiary-profiles-id')"
              routerLinkActive="active">
              <a class="nav-link" 
                 routerLink="/beneficiary/my-profiles/my-medicare-beneficiary-profiles">
                 <img src="/assets/img/beneficiary/new-icon/My Profiles (2).png" alt="">
                 <span>My Profiles</span>
              </a>
          </li>   
          <li class="nav-item" 
              routerLinkActive="active">
              <a class="nav-link"
                 routerLink="/beneficiary/my-profiles/my-enrollment-periods">
                 <img src="/assets/img/beneficiary/new-icon/My Enrollment Periods.png" alt="">
                <span>My Enrollment Periods</span>
              </a>
          </li>
          <li class="nav-item" 
              (click)="scrollToView('my-plan-availability-id')"
              routerLinkActive="active">
              <a class="nav-link"
                 routerLink="/beneficiary/my-profiles/my-plan-availability">
                 <img src="/assets/img/beneficiary/new-icon/My Plan Availability.png" alt="">
                <span>My Plan Availability</span>
              </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" routerLink="/beneficiary/my-financial-assistance-programs" routerLinkActive="active">
              <img src="/assets/img/beneficiary/new-icon/My Financial Assistance Programs.png" alt="">
              <span>My Financial Assistance Programs</span>
            </a>
          </li>     
        </ul>
      </li>

      <li class="nav-item nav-dropdown"           
          (click)="scrollToView('my-healthcare-organizer-id')"
          routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" routerLink="/beneficiary/my-healthcare-organizer" routerLinkActive="active">
          <img src="/assets/img/beneficiary/new-icon/My Healthcare Organizer.png" alt="">
          <span>My Healthcare Organizer</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/beneficiary/my-healthcare-organizer/my-providers">
            <img src="/assets/img/beneficiary/new-icon/My Providers.png" alt="">
            <span>My Providers</span></a></li>
          <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/beneficiary/my-healthcare-organizer/my-drugs">
            <img src="/assets/img/beneficiary/new-icon/My Drugs.png" alt="">
            <span>My Drugs</span></a></li>
          <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/beneficiary/my-healthcare-organizer/my-claims">
            <img src="/assets/img/beneficiary/new-icon/My Claims.png" alt="">
            <span>My Claims</span></a></li>
          <li class="nav-item" routerLinkActive="active"><a class="nav-link">
              <img src="/assets/img/beneficiary/new-icon/My Health Notes.png" alt="">
              <span>My Health Notes</span></a></li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="http://api.taptapmedicare.com/v1/admin/reports/view/2020/9/Wellness%20&%20Fitness%20Guide.pdf">
              <img src="/assets/img/beneficiary/new-icon/My Wellness _ Fitness Opportunities.png" alt="">
              <span>My Wellness & Fitness Opportunities</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices" routerLinkActive="active">
          <img src="/assets/img/beneficiary/new-icon/My Marketplace - Plans _ Prices.png" alt="">
          <span>My Marketplace - Plans & Prices</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item bordered-bottom nav-dropdown" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" routerLink="/beneficiary/my-coverage-preferences" routerLinkActive="active">
              <img src="/assets/img/beneficiary/new-icon/My Coverage Preferences.png" alt="">
              <span>My Coverage Preferences</span>
            </a>
            <ul class="nav-sub-menu">
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" 
                   routerLink="/beneficiary/my-coverage-preferences/general-coverage-type-and-experience">
                   <img src="/assets/img/beneficiary/new-icon/folder.png" alt="">   
                   <span>General Coverage Type Preferences</span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" 
                   routerLink="/beneficiary/my-coverage-preferences/medigap-preferences">
                   <img src="/assets/img/beneficiary/new-icon/folder.png" alt="">     
                   <span>Medicare Supplement Coverage Preferences</span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" 
                   routerLink="/beneficiary/my-coverage-preferences/medicare-advantage-plan-preferences">
                   <img src="/assets/img/beneficiary/new-icon/folder.png" alt="">     
                   <span>Medicare Advantage Coverage Preferences</span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" 
                   routerLink="/beneficiary/my-coverage-preferences/dental-insurance-preferences">
                   <img src="/assets/img/beneficiary/new-icon/folder.png" alt="">     
                   <span>Dental Coverage Preferences</span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" 
                   routerLink="/beneficiary/my-coverage-preferences/part-d-drug-coverage-preferences">
                   <img src="/assets/img/beneficiary/new-icon/folder.png" alt="">     
                   <span>Part D Drug Coverage Preferences</span>
                </a>
              </li>
            </ul>
          </li>           
          <li class="nav-item"  routerLinkActive="active" (click)="scrollToView('my-medicare-supplement-plans-id')">
            <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices/medicare-supplement-plans">
              <img src="/assets/img/beneficiary/new-icon/My Medicare Supplement Plans.png" alt=""> 
              <span>My Medicare Supplement Plans</span></a>
          </li>          
          <li class="nav-item"  routerLinkActive="active" (click)="scrollToView('my-medicare-advantage-plans-id')">
            <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices/my-medicare-advantage-plans">
              <img src="/assets/img/beneficiary/new-icon/My Medicare Advantage Plan.png" alt="">
              <span>My Medicare Advantage Plan</span></a>
          </li>          
          <li class="nav-item nav-dropdown" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" routerLink="/beneficiary/my-network-status" routerLinkActive="active">
                <img src="/assets/img/beneficiary/new-icon/My MAPD Network Status.png" alt="">
                <span>My MAPD Network Status</span>
              </a>
              <ul class="nav-sub-menu">
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" routerLink="/beneficiary/my-network-status/my-provider-network-status">
                    <img src="/assets/img/beneficiary/new-icon/folder.png" alt="">
                    <span>My Providers Network Status</span></a></li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" routerLink="/beneficiary/my-network-status/my-carriers-network-status">
                    <img src="/assets/img/beneficiary/new-icon/folder.png" alt="">
                    <span>My Carriers Network Status</span></a>
                </li>
              </ul>
          </li>
          <li class="nav-item bordered-bottom" routerLinkActive="active" >
            <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices/my-drug-costs-analysis">
              <img src="/assets/img/beneficiary/new-icon/My Drug Cost Analysis.png" alt="">
              <span>My Drug Costs Analysis</span></a>
          </li>

          <li class="nav-item"  routerLinkActive="active" (click)="scrollToView('my-medicare-advantage-plans-id')">
              <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices/my-dental-insurance-plans">
                <img src="/assets/img/beneficiary/new-icon/My Dental-Insurance Plans.png" alt="">
                <span>My Dental Insurance Plans</span></a>
            </li>

          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices/my-life-insurance-marketplace">
              <img src="/assets/img/beneficiary/new-icon/My Life Insurance Marketplace.png" alt="">
              <span>My Life Insurance Marketplace</span></a>
          </li>
          
          <li class="nav-item bordered-bottom" routerLinkActive="active" >
            <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices/my-supporting-plans-marketplace">
              <img src="/assets/img/beneficiary/new-icon/My Gap Coverage Marketplace.png" alt="">
              <span>My Gap Coverage Marketplace</span></a>
          </li>

          <li class="nav-item" routerLinkActive="active" >
            <a class="nav-link" routerLink="/beneficiary/my-marketplace-plans-and-prices/my-supporting-plans-marketplace">
              <img src="/assets/img/beneficiary/new-icon/Other Types of Coverage.png" alt="">
              <span>Other Types of Coverage</span></a>
          </li>         
          
        </ul>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" 
           routerLink="/beneficiary/my-coverage-organizer" 
           routerLinkActive="active">
              <img src="/assets/img/beneficiary/new-icon/My Policy Info.png" alt="">
              <span>My Policy Info</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/beneficiary/my-coverage-organizer/my-new-coverage-summary" 
               class="nav-link">
                  <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Coverage Summary</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/beneficiary/my-coverage-organizer/my-coverage-outline" 
               class="nav-link">
               <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Coverage Outline</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/beneficiary/my-coverage-organizer/my-total-coverage-organizer" 
               class="nav-link">
               <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Total Coverage Organizer</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/beneficiary/my-coverage-organizer/my-coverage-explainer" 
               class="nav-link">
               <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Coverage Explainer</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" target="_blank" href="https://api.taptapmedicare.com/v1/admin/reports/view/2020/8/TAP-TAP-MEDICARE%20-%20MY-MEDICARE-%20My%20Recommendations%20-%20Cover%20Letter%20(1).pdf">
              <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
              <span>My Medicare Recommendations</span>
            </a>
            <ul class="nav-sub-menu">
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link"  routerLink="/beneficiary/my-recommendations/my-coverage-type-recommendation">
                  <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Coverage Type
                    Recommendation</span></a></li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/beneficiary/my-recommendations/my-specific-plan-recommendation">
                  <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Specific Plan
                    Recommendation</span></a></li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/beneficiary/my-recommendations/my-additional-coverages-recommendation">
                  <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Additional Coverages
                    Recommendation </span></a></li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/beneficiary/my-recommendations/my-cost-savings-opportunities">
                  <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
                  <span>My Cost Savings
                    Opportunities</span></a></li>
            </ul>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/beneficiary/my-coverage-organizer/my-coverage-explainer" 
               class="nav-link"> 
               <img src="/assets/img/beneficiary/new-icon/folder1.png" alt="">
               <span>My Other Insurances</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" routerLink="/beneficiary/my-personal-docs-and-my-custom-reports" routerLinkActive="active">
          <img src="/assets/img/beneficiary/new-icon/My Personal Docs _ Custom Reports.png" alt="">
          <span>My Personal Docs and Custom Reports</span>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="li-active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" target="_blank" href="https://api.taptapmedicare.com/v1/admin/reports/view/2020/8/MY-GUIDES-AND-EDUCATION%20-%20First%202%20Pages%20-%20NO%20TITLE.pdf">
          <img src="/assets/img/beneficiary/new-icon/My Guidance _Counsel.png" alt="">
          <span>My Guidance & Counsel</span>
        </a>
        <ul class="nav-sub-menu">
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="http://api.taptapmedicare.com/v1/admin/reports/view/2020/9/MY-GUIDES-AND-EDUCATION%20-%20EVEN-FOCUS%20-%20REGULAR%20STATES.pdf"><span>My Core Guides</span></a></li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="https://api.taptapmedicare.com/v1/admin/reports/view/2020/8/TAPTAP%20EXTRA%20GUIDES%20NO%20TITLE%20(1).pdf"><span>My Extra Guides</span></a></li>
        </ul>
      </li>

      <li class="nav-item">
        <a class="nav-link" target="_blank" href="https://taptapmedicare.com/community">
          <img src="/assets/img/beneficiary/new-icon/My Community.png" alt="">
          <span>My Community</span>
        </a>
      </li>  
    </ul>
  </div>
</div>