import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { APIService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-medicare-services-form',
  templateUrl: './medicare-services-form.component.html',
  styleUrls: ['./medicare-services-form.component.scss']
})
export class MedicareServicesFormComponent implements OnInit {
  @Input() infoMessage: string;
  @Input() isMedicareServices = false;

  taptapRecaptcha = new FormControl(false);

  constructor(private _api: APIService) { }

  ngOnInit() {
  }

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  onInquire() {
    this._api.callAPIEndpoint('/api/v1/inquiry', {
        'first_name': this.controls.first_name.value,
        'last_name': this.controls.last_name.value,
        'email': this.controls.email.value,
        'phone': this.controls.phone.value,
        'message': this.controls.message.value,
        'source_id': 'Website | All Services Page',
        'source_description': 'Message Box Entry',
    })
        .subscribe(
            (data: any) => {
                this.emptyForm();
                this.alertMessage();
            },
            (err) => {
                this.emptyForm();
            }
        );
  }

  alertMessage() {
    Swal.fire({
      html: '<h1>Inquiry Sent!</h1>',
      customClass: {
      },
      showConfirmButton: true,
      showCloseButton: true
    });
  }

  emptyForm() {
    this.controls.first_name.reset();
    this.controls.last_name.reset();
    this.controls.email.reset();
    this.controls.phone.reset();
    this.controls.what_can_we_help_for_you.reset();
    this.controls.how_did_you_hear_about_us.reset();
    this.controls.message.reset();
  }  

  controls: any = {
    first_name: new FormControl('', [
        Validators.required,
    ]),
    last_name: new FormControl('', [
        Validators.required,
    ]),
    email: new FormControl('', [
        Validators.required,
    ]),
    phone: new FormControl('', [
        Validators.required,
    ]),
    message: new FormControl('', [
        Validators.required,
    ]),
  }
}
