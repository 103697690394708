<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Medicare Info</h4>
    <div class="yellow-line"></div>
    <div class="d-flex flex-column align-items-center">
      <div class="d-flex justify-content-center mb-4">
        <div class="medicare-card-section mx-auto">
          <span class="label">Medicare Card Number</span>
          <div class="medicare-card">
            <div class="blue py-3">
              <h5 class="text-center text-light">Medicare Health Insurance</h5>
            </div>
            
            <div class="row py-2 px-2">
              <div class="col-12">
                  <span class="med-card-label">Name/Nombre</span>
                  <span class="med-card-value">{{ medicareCardName }}</span>
              </div> 
              
              <div class="col-12 pt-4 pb-3">
                <span class="med-card-label">Medicare Number/Numero de Medicare</span>
                <span class="med-card-value">{{ medicareCardNumber.firstSet }} - {{ medicareCardNumber.secondSet }} - {{ medicareCardNumber.thirdSet }}</span>
              </div>

              <div class="col-12">
                <table class="med-card-table">
                  <tr>
                    <th><span class="med-card-label">Entitled to/Con derecho a</span></th>
                    <th><span class="med-card-label">Coverage starts/Cobertura empleza</span></th>
                  </tr>
                  <tr *ngIf="!hasNoPartA">
                    <td><span class="med-card-value">Hospital (Part A)</span></td>
                    <td><span class="med-card-value">{{ effectiveDatePartA }}</span></td>
                  </tr>
                  <tr *ngIf="!hasNoPartB">
                    <td><span class="med-card-value">Medical (Part B)</span></td>
                    <td><span class="med-card-value">{{ effectiveDatePartB }}</span></td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="red"></div>
          </div>
        </div>
      </div>
      <div class="security-box my-4">
        <i class="fas fa-lock"></i>
        <span>Your Information is protected with us for your safety and privacy with standard (SSL) encryption.</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="email" class="text-label">Medicare Card Number</label>
        <div class="medicare-card-form">
          <input type="text" [(ngModel)]="medicareCardNumber.firstSet" name="" maxlength="4" id="">
          <span>-</span>
          <input type="text" [(ngModel)]="medicareCardNumber.secondSet" name="" maxlength="3" id="">
          <span>-</span>
          <input type="text" [(ngModel)]="medicareCardNumber.thirdSet" name="" maxlength="4" id="">
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="phone" class="text-label">Full Medicare Name</label>
        <input type="text" class="form-control blurred" id="phone" [(ngModel)]="medicareCardName" />
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="exactName">    
          <label class="custom-control-label fWeight-500 text-dblue uppercase" for="exactName">This Is The Exact Name On Your/The Policy Insured’s Medicare Card</label>    
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="medicaidNumber" class="text-label">Medicaid Number</label>
        <input type="text" class="form-control blurred" id="medicaidNumber"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="ssn" class="text-label">SSN</label>
        <input type="text" class="form-control blurred" id="ssn"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="partA" class="text-label">Medicare Part A Effective Date</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" [(ngModel)]="effectiveDatePartA" id="partA" (click)="onClickDob(appDOB,'partAEffectiveDate','Select Effective Date for Part A')"/>
          <i class="fas fa-calendar"></i>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="noPartA" (change)="onCheckedDontHavePartA($event)" [checked]="hasNoPartA">    
          <label class="custom-control-label fWeight-500 text-dblue uppercase" for="noPartA">I Do Not Have Part A Yet</label>    
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="partB" class="text-label">Medicare Part b Effective Date</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" id="partB" [(ngModel)]="effectiveDatePartB" (click)="onClickDob(appDOB,'partBEffectiveDate','Select Effective Date for Part B')"/>
          <i class="fas fa-calendar"></i>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="noPartB" (change)="onCheckedDontHavePartB($event)" [checked]="hasNoPartB">    
          <label class="custom-control-label fWeight-500 text-dblue uppercase" for="noPartB">I Do Not Have Part B Yet</label>    
        </div>
      </div>
    </div>
    <div class="dashed-box p-3 mt-4">
      <h6 class="text-label fw-semibold">Which Best Describes Your/The Policy Insured’s Medicare-Enrollment Status?</h6>
      <div class="row pt-2">
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_1" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_1">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_2" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_2">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_3" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_3">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_4" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_4">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
      </div>
    </div>

    <div class="dashed-box p-3 mt-4">
      <h6 class="text-label fw-semibold">Which of The Following Is Your Eligibility Reason Getting On To Medicare Under Age 65?</h6>
      <div class="row pt-2">
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_1" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_1">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_2" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_2">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_3" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_3">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="enrollmentStatus_4" name="enrollmentStatus" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="enrollmentStatus_4">LOREM IPSUM DOLOR TESTING</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>