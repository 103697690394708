import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aep-sidenav',
  templateUrl: './aep-sidenav.component.html',
  styleUrls: ['./aep-sidenav.component.scss']
})
export class AepSidenavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  slideToSection(sectionID){
    $('html,body').animate({ scrollTop: $("#target_" + sectionID ).offset().top - 60},'slow');
  }

}
