import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapd-star-rating',
  templateUrl: './mapd-star-rating.component.html',
  styleUrls: ['./mapd-star-rating.component.scss']
})
export class MapdStarRatingComponent implements OnInit {

  showExpandAllRatings:boolean = true;

  rating:number = 5;

  constructor() { }

  ngOnInit() {
  }

  onClickExpandAllRatings():void{
    const col = document.querySelectorAll('button.btn.btn-link.collapsed');
    col.forEach((item) => {
      (<HTMLElement>item).click();
    });
    this.showExpandAllRatings = false;
  }

  onClickCollapseAllRatings():void{    
    const col = document.querySelectorAll('button.btn.btn-link[aria-expanded="true"]');
    col.forEach((item) => {
      (<HTMLElement>item).click();
    });
    this.showExpandAllRatings = true;
  }

}
