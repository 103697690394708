<h4 class="title mb-5">Contact Info</h4>
<div class="row px-0">
    <div class="col-12">
      <div class="form-group">
          <div class="row">
            <label class="col-12 mb-3">My Marital Status</label>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
                <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('married_living_together', 1)" [class.active]="details?.customer_info.marital_status == 'married_living_together'">Married - Living Together <i class="fas fa-check ml-2"></i></button>
            </div>
            <div class="col-12 col-md-4 px-2">
                <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('divorced', 2)" [class.active]="details?.customer_info.marital_status == 'divorced'">Divorced <i class="fas fa-check ml-2"></i></button>
            </div>
            <div class="col-12 col-md-4">
                <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('married_not_living_together', 3)" [class.active]="details?.customer_info.marital_status == 'married_not_living_together'">Married - Not Living Together <i class="fas fa-check ml-2"></i></button>
            </div>
            <div class="col-12 col-md-4">
                <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('widowed', 4)" [class.active]="details?.customer_info.marital_status == 'widowed'">Widowed <i class="fas fa-check ml-2"></i></button>
            </div>
            <div class="col-12 col-md-4 px-2">
                <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('single', 5)" [class.active]="details?.customer_info.marital_status == 'single'">Single <i class="fas fa-check ml-2"></i></button>
            </div>
            <div class="col-12 col-md-4">
                <button class="btn btn-first btn-small btn-plain fluid mb-3 marketplace-input-quote-btn" (click)="onClick_maritalStatus('separated', 6)" [class.active]="details?.customer_info.marital_status == 'separated'">Separated <i class="fas fa-check ml-2"></i></button>
            </div>
          </div>
      </div>
    </div>
    <div class="col-12 col-sm-4" *ngIf="isSpouseActive">
        <div class="form-group">
          <label for="">Spouse's Date of Birth</label>
          <input type="text" class="form-control blurred" (click)="onClickDob('normal','Enter Spouse\'s Date of Birth')" [disabled]="!isSpouseActive"/>
        </div>
    </div>
</div>
<div class="row px-0">
  <div class="col-12 col-sm-6 pr-2">
      <div class="form-group">
        <label for="emailAddress">Email Address</label>
        <input type="text" class="form-control blurred" id="emailAddress"/>           
      </div>
  </div>
  <div class="col-12 col-sm-6 pr-2">
      <div class="form-group">
        <label for="phoneNumber">Phone Number</label>
        <input type="text" class="form-control blurred" id="phoneNumber"/>           
      </div>
  </div>
</div>
<div class="row px-0">
    <div class="col-12 col-sm-8 pr-2">
          <div class="form-group">
          <label for="">Address</label>
          <input type="text" class="form-control blurred" [(ngModel)]="details?.customer_info.address">
          </div>
    </div>
    <div class="col-12 col-sm-4">
        <div class="form-group">
        <label for="">Apt/Unit #</label>
        <input type="text" class="form-control blurred"/>
        </div>
    </div>
    <div class="col-12 col-sm-6 pr-2">
        <div class="form-group">
        <label for="">City</label>
        <input type="text" class="form-control blurred" [(ngModel)]="details?.customer_info.city"/>
        </div>
    </div>
    <div class="col-12 col-sm-6">
        <div class="form-group">
        <label for="">State</label>
        <input type="text" class="form-control blurred" [(ngModel)]="details?.customer_info.state"/>
        </div>
    </div>
    <div class="col-12 col-sm-6 pr-2">
        <div class="form-group">
        <label for="">Zip Code</label>
        <input type="text" class="form-control blurred" [(ngModel)]="details?.customer_info.zipcode"/>
        </div>
    </div>
    <div class="col-12 col-sm-6">
        <div class="form-group">
        <label for="">County</label>
        <input type="text" class="form-control blurred" [(ngModel)]="details?.customer_info.county"/>
        </div>
    </div>
    <div class="col-12 col-sm-6 pr-2">
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" (change)="onCheckedMailingAddress($event)" [checked]="details?.customer_info.is_mailing_address" class="custom-control-input" id="mailing_address_as_well">
          <label class="custom-control-label fWeight-600" for="mailing_address_as_well">This is My Mailing Address As Well</label>
        </div>
    </div>     
</div>
<ng-container *ngIf="!details?.customer_info.is_mailing_address">
  <div class="separator dashed my-4"></div>
  <div class="row px-0">
      <h5 class="col-12 mb-3 fColor-black fWeight-600 px-0">My Mailing Address</h5>
      <div class="col-12 col-sm-8 pr-2">
          <div class="form-group">
          <label for="">Address</label>
          <input type="text" class="form-control blurred"/>
          </div>
      </div>
      <div class="col-12 col-sm-4">
          <div class="form-group">
          <label for="">Apt/Unit #</label>
          <input type="text" class="form-control blurred"/>
          </div>
      </div>
      <div class="col-12 col-sm-6 pr-2">
          <div class="form-group">
          <label for="">City</label>
          <input type="text" class="form-control blurred"/>
          </div>
      </div>
      <div class="col-12 col-sm-6">
          <div class="form-group">
          <label for="">State</label>
          <input type="text" class="form-control blurred"/>
          </div>
      </div>
      <div class="col-12 col-sm-6 pr-2">
          <div class="form-group">
          <label for="">Zip Code</label>
          <input type="text" class="form-control blurred"/>
          </div>
      </div>
      <div class="col-12 col-sm-6">
          <div class="form-group">
          <label for="">County</label>
          <input type="text" class="form-control blurred"/>
          </div>
      </div>   
  </div>
</ng-container>