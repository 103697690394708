export class State {
    id: string;
    name: string;
}

export const STATES: State[] = [
    { id: "AL", name: 'Alabama' },
    { id: "AK", name: 'Alaska' },
    { id: "AZ", name: 'Arizona' },
    { id: "AR", name: 'Arkansas' },
    { id: "CA", name: 'California' },
    { id: "CO", name: 'Colorado' },
    { id: "CT", name: 'Connecticut' },
    { id: "DE", name: 'Delaware' },
    { id: "DC", name: 'Columbia' },
    { id: "FL", name: 'Florida' },
    { id: "GA", name: 'Georgia' },
    { id: "HI", name: 'Hawaii' },
    { id: "ID", name: 'Idaho' },
    { id: "IL", name: 'Illinois' },
    { id: "IN", name: 'Indiana' },
    { id: "IA", name: 'Iowa' },
    { id: "KS", name: 'Kansas' },
    { id: "KY", name: 'Kentucky' },
    { id: "LA", name: 'Louisiana' },
    { id: "ME", name: 'Maine' },
    { id: "MD", name: 'Maryland' },
    { id: "MA", name: 'Massachusetts' },
    { id: "MI", name: 'Michigan' },
    { id: "MN", name: 'Minnesota' },
    { id: "MS", name: 'Mississippi' },
    { id: "MO", name: 'Missouri' },
    { id: "MT", name: 'Montana' },
    { id: "NE", name: 'Nebraska' },
    { id: "NV", name: 'Nevada' },
    { id: "NH", name: 'Hampshire' },
    { id: "NJ", name: 'Jersey' },
    { id: "NM", name: 'Mexico' },
    { id: "NY", name: 'York' },
    { id: "NC", name: 'Carolina' },
    { id: "ND", name: 'Dakota' },
    { id: "OH", name: 'Ohio' },
    { id: "OK", name: 'Oklahoma' },
    { id: "OR", name: 'Oregon' },
    { id: "PA", name: 'Pennsylvania' },
    { id: "RI", name: 'Island' },
    { id: "SC", name: 'Carolina' },
    { id: "SD", name: 'Dakota' },
    { id: "TN", name: 'Tennessee' },
    { id: "TX", name: 'Texas' },
    { id: "UT", name: 'Utah' },
    { id: "VT", name: 'Vermont' },
    { id: "VA", name: 'Virginia' },
    { id: "WA", name: 'Washington' },
    { id: "WV", name: 'Virginia' },
    { id: "WI", name: 'Wisconsin' },
    { id: "WY", name: 'Wyoming' },
];