<div class="row den-ins-table-wrapper">
  <div *ngFor=" let containerResults of results; let i = index" class="full-width">
    <h4 class="fColor-base text-center" *ngIf="containerResults[0].annual_max == 0">N/A Annual Maximum Benefit
    </h4>
    <h4 class="fColor-base text-center" *ngIf="containerResults[0].annual_max != 0">

      {{ containerResults[0].annual_max }} Annual Maximum Benefit</h4>

      <div class="mapd-result-version-5 container type-1 vp-btn-inside"
        *ngFor="let result of containerResults; let x = index">
        <table class="mapd-result-table">
          <tr class="tr-result-heading">
            <th>Company</th>
            <th class="column-triple-content">
              <span class="column-visits">Preventive</span>
              <span class="column-tests">Basic</span>
              <span class="column-surgery">Comprehensive</span>
              <span class="column-vision">Includes</span>
            </th>
            <th>Costs</th>
            <th class="action-wrapper">Actions</th>
          </tr>
          <tr>
            <td class="result-logo-wrapper">
              <span class="no-img-plan-name d-none">

                {{ result?.plan_name }}</span>
                <img src="{{ base_img_loc + result?.logo }}"
                  onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
                  alt="{{ result.plan_name }}" width="900px" class="result-logo"
                  (error)="companyLogoCheck($event)" id="logo_{{result.carrier_name}}_{{result.id}}_{{x}}">

                <div class="medicare-advantage-custom-title-rating-wrapper">
                  <h2 #h2ResultPlanName class="results-plan-name mb-0">

                    {{ result?.plan_name }}</h2>
                </div>
            </td>
            <td class="column-triple-content">
              <div class="column-triple-content-inner">
                <div class="column-visits">
                  <div class="value-wrapper">
                    <span class="main-value result-value d-block"
                      [innerHTML]="(result?.preventive_headline) ? result?.preventive_headline : 'N/A'"></span>
                    <small class="fColor-base fWeight-600 d-block text-center"
                      [innerHTML]="(result?.preventive_subline) ? result?.preventive_subline : 'N/A'"></small>
                  </div>
                </div>
                <div class="column-tests">
                  <div class="value-wrapper">
                    <span class="main-value result-value d-block"
                      [innerHTML]="(result?.basic_headline) ? result?.basic_headline : 'N/A'"></span>
                    <small class="fColor-base fWeight-600 d-block text-center"
                      [innerHTML]="(result?.basic_subline) ? result?.basic_subline : 'N/A'"></small>
                  </div>
                </div>
                <div class="column-surgery">
                  <div class="value-wrapper">
                    <span class="main-value result-value d-block"
                      [innerHTML]="(result?.comprehensive_headline) ? result?.comprehensive_headline : 'N/A'"></span>
                    <small class="fColor-base fWeight-600 d-block text-center"
                      [innerHTML]="(result?.comprehensive_subline) ? result?.comprehensive_subline : 'N/A'"></small>
                  </div>
                </div>
                <div class="column-vision result-include-icons">
                  <div *ngIf="result?.includes_vision == 1"><img
                      src="./assets/img/common/mapd-includes-icon-vision.PNG" class="result-vision-icon"
                      alt="vision icon" matTooltip="Vision" matTooltipClass="bg-primary"
                      matTooltipPosition="above"></div>
                  <div *ngIf="result?.includes_hearing == 1"><img
                      src="./assets/img/common/mapd-includes-icon-hearing.png" class="result-hearing-icon"
                      alt="hearing icon" matTooltip="Hearing" matTooltipClass="bg-primary"
                      matTooltipPosition="above"></div>
                  <div *ngIf="result?.ppo_plan == 1"><img src="./assets/img/common/icons/no-image-icon.png"
                      class="result-includes-no-icon" alt="PPO Access" matTooltip="PPO Access"
                      matTooltipClass="bg-primary" matTooltipPosition="above"></div>
                  <div *ngIf="result?.dental_implants == 1"><img src="./assets/img/common/icons/no-image-icon.png"
                      class="result-includes-no-icon" alt="Coverage For Dental Implants"
                      matTooltip="Coverage For Dental Implants" matTooltipClass="bg-primary"
                      matTooltipPosition="above"></div>
                  <div *ngIf="result?.reimburse_any_dentist == 1"><img
                      src="./assets/img/common/icons/no-image-icon.png" class="result-includes-no-icon"
                      alt="Reimburse For Any Dentist" matTooltip="Reimburse For Any Dentist"
                      matTooltipClass="bg-primary" matTooltipPosition="above"></div>
                </div>
              </div>
              <div class="plan-documents-button-wrapper">
                <a class="btn btn-first btn-xs mr-3" target="_blank"
                  href="/insurance-marketplace/dental/plan-profile/{{result.id}}/{{this.controls.zipcode.value}}?age={{controls.age.value}}"
                  id="view_benefits_{{result.carrier_name}}_{{result.id}}_{{x}}"><i class="fa fa-search mr-2"
                    aria-hidden="true"></i>View All Benefits</a>
                <button class="btn btn-first btn-xs" (click)="clickPlanDocsResources($event)"><i
                    class="fa fa-file-text-o mr-2" aria-hidden="true"></i> Plan Docs & Resources</button>
              </div>
            </td>
            <td class="costs-wrapper">
              <span class="fColor-blue uppercase fWeight-500" style="font-size:20px;">Premium</span>
              <span class="main-value fColor-black mt-0">${{(result.price) ? result.price : 'N/A' }}</span>
              <span class="sub-value mb-3">Per Month</span>

              <span class="fColor-blue uppercase fWeight-500" style="font-size:13px;">Annual Max</span>
              <span class="main-value fColor-black mt-0"
                style="font-size:20px;">{{(result?.annual_max) ? result?.annual_max : 'N/A' }}</span>
            </td>
            <td class="action-wrapper">
              <button class="btn btn-first btn-xs mb-2" (click)="applyOnline(result, x)">
                Apply Online
              </button>
              <button class="btn btn-first btn-xs" (click)="applyOnThePhone(result)">
                Apply on Phone
              </button>
              <button class="btn btn-first mt-2 saveThisPlanButton">Save This Plan</button>
              <div class="result-action-compare-add">
                <!-- <div class="custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" class="custom-control-input" id="saveThisPlan-{{result.id}}">
                <label class="custom-control-label" for="saveThisPlan-{{result.id}}">Save Selected Plan</label>
            </div>                      -->
                <div class="compared-plans-btn-wrapper custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" class="custom-control-input" id="addToCompare_{{result.plan_pricing_id}}"
                    (change)="addToCompareFunc(result, $event, x, '.compared-plans-btn-wrapper', 'a.viewComparePlansBtn')"
                    [checked]="logComparedKeys.includes(result.plan_pricing_id)">
                  <label class="custom-control-label" for="addToCompare_{{result.plan_pricing_id}}">Add to
                    Compare</label>
                  <a target="_blank" href="/insurance-marketplace/dental/results/compare-plans?compare={{dental_array_id}}&age={{controls.age.value}}"
                    class="viewComparePlansBtn btn btn-third-plain btn-small d-none ff"
                    [class.d-block]="logComparedKeys.includes(result.plan_pricing_id)">View Compare Plans</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="plan-documents d-none">
          <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="plan documents icon">
          <div class="plan-documents-table">
            <div class="plan-documents-table-heading">
              <h4>Plan Documents & Resources</h4>
            </div>
            <div class="plan-documents-table-row">

              <a href="javascript:void(0)" class="btn btn-third-plain btn-small">Policy Brochure</a>

              <mat-divider [vertical]="true"></mat-divider>

              <button class="btn btn-third-plain btn-small"
                (click)="companyProfile(result)">Company Profile</button>

              <mat-divider [vertical]="true"></mat-divider>

              <button class="btn btn-third-plain btn-small"
                (click)="consumerReview(result)">Consumer Reviews</button>

              <mat-divider [vertical]="true"></mat-divider>

              <button class="btn btn-third-plain btn-small"
                (click)="additionalInsights(additionalDocuments, result)">Additional Insights</button>

              <mat-divider [vertical]="true"></mat-divider>
            </div>
          </div>
        </div>
      </div>

      <!-----------------------------------------------------------------------
-----------------------OCT 13, 2021 - END VERSION 5- TYPE 2 - hide and show plan doc DESIGN------------------------
--------------------------------------------------------------------------->

  </div>
</div>