import {Component, OnInit, ElementRef, ViewChild, Inject, Optional, Output, EventEmitter } from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {APIService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {AuthService} from "../../../services/auth.service";
import {Title} from "@angular/platform-browser";
import {DrugsService} from '../../../services/drugs.service';
import Swal from 'sweetalert2';
export interface DialogData {
  email: string;
  name: string;
  isModal: boolean;
}

@Component({
  selector: 'app-common-login-form',
  templateUrl: './common-login-form.component.html',
  styleUrls: ['./common-login-form.component.scss']
})
export class CommonLoginFormComponent implements OnInit {

  @Output() showSignUpForm = new EventEmitter<any>();
  @ViewChild('modal_WithList')  modalWithList: ElementRef;
  @ViewChild('modal_success_added_to_drug_list')  modalSuccessAddedDrugList: ElementRef;
  

  submitIsClicked: boolean = false;
  isValidCredentials: boolean = true;
  showLoading: boolean = false;
  isWrongLogin: boolean = false;
  isSignInUsingExternalParty: boolean = false;
  externalParty: string;
  existingUserMessage: string = null;
  auth_token: any;

  onScriptLoad() {
      console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
      console.log('Something went long when loading the Google reCAPTCHA')
  }

  private baseUrl = window.location.origin;

  form: any;

  loginForm = new FormGroup({
      email: new FormControl('', [
          Validators.required,
      ]),
      password: new FormControl('', [
          Validators.required,
      ]),

      taptapRecaptcha: new FormControl(false),
      // captcha: new FormControl('', [
      //     Validators.requiredTrue,
      // ]),
  });

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
  get taptapRecaptcha() { return this.loginForm.get('taptapRecaptcha'); }
  // get captcha() { return this.loginForm.get('captcha'); }

  currentUser: any;

  constructor(private _api: APIService,
      private drugsService: DrugsService,
      private _router: Router, 
      private _authService: AuthService, 
      @Optional() public dialogRef: MatDialogRef<CommonLoginFormComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: DialogData, private titleService:Title) {
          if (dialogData && dialogData.email !== undefined && dialogData.email !== null && dialogData.email) {
              this.loginForm.controls['email'].setValue(dialogData.email);
          }
          this.titleService.setTitle("Medicare Advantage - Drugs");
  }

  // @ViewChild('recaptcha') recaptchaElement: ElementRef;

  ngOnInit() {
      // this.addRecaptchaScript();
  }

  saveToCookies():void{
      let d = new Date();
      d.setTime(d.getTime() + (24*60*60*1000));
      let expires = d.toUTCString();
      document.cookie = `bp_user_id=${this.currentUser.id};expires=${expires};path=${this.baseUrl};`;
      document.cookie = `bp_user_name=${this.currentUser.name};expires=${expires};path=${this.baseUrl};`
      document.cookie = `bp_user_picture=${this.currentUser.picture};expires=${expires};path=${this.baseUrl};`;
      document.cookie = `bp_user_email=${this.currentUser.email};expires=${expires};path=${this.baseUrl};`;
      document.cookie = `bp_user_accounttype=${this.currentUser.accountType};expires=${expires};path=${this.baseUrl};`;
  }

  closeModal() {
      if (this.dialogRef) {
          this.dialogRef.close(false);
      }
  }

  async loginUsingExternalParty(externalParty: string): Promise<any> {
      this.submitIsClicked = true;
      this.isValidCredentials = true;
      this.isSignInUsingExternalParty = true;
      this.externalParty = externalParty;
      this.existingUserMessage = null;
      this.isWrongLogin = false;
      
      //this.showLoading = true;
      try {
          const result = await this._authService.signInUsingExternalParty(externalParty);
          if(result){
              if(result.exists) {
                  this.showLoading = false;
                  this.isValidCredentials = false;
                  this.isWrongLogin = true;
                  this.existingUserMessage = result.message;
              } else {
                  const { email, password } = result;
                  this._authService.signUserInOnServer({
                      'email': email,
                      'password': password,
                  }).subscribe(
                      (details: any) => {
                          if (details.code === 1) {
                              this._authService.setActiveUserUsingJSON(details.details, externalParty);

                              if (this.dialogRef) {
                                  this.dialogRef.close(true);
                              } else {
                                  this.isWrongLogin = false;
                                  window.location.href = '/benny-dash';
                              }
                          }
                      },

                      (response) => {
                          if(response.error){
                              this.showLoading = false;
                              this.isValidCredentials = false;
                              this.isWrongLogin = true;
                          }
                      }
                  );
              }
          }
      } catch(error) {
          if(error.code === 'auth/account-exists-with-different-credential') {
              const userData = await this._authService.checkUserExists(error.email);
              if(userData && userData.length > 0) {
                  if(userData[0].external_party !== null) {
                      this.existingUserMessage = `You are already registered to our system using ${ userData[0].external_party }, with the email of ${ error.email }. Kindly login using that account.`;
                  }
              } else {
                  this.existingUserMessage = error.message;
              }
          }

          if (this.dialogRef) {
              this.dialogRef.close(true);
          }

          if(error.code !== 'auth/cancelled-popup-request') {
              this.showLoading = false;
              this.isValidCredentials = false;
              this.isWrongLogin = true;
          }
      }
  }

  login() {
      this.submitIsClicked = true;
      this.isValidCredentials = true;
      this.isSignInUsingExternalParty = false;

      if (this.loginForm.valid) {
          this.showLoading = true;
          this._authService.signUserInOnServer({
              'email': this.loginForm.controls.email.value,
              'password': this.loginForm.controls.password.value,
          }).subscribe(
              (data: any) => {
                  if (data.code === 1) {
                      this.showLoading = false;
                      this._authService.setActiveUserUsingJSON(data.details);
                      this.auth_token = window.localStorage.getItem('auth_token'); // get user token after successful login
                      this.checkIfMarketplaceDefaultListExist() // check if the user has already have default marketplace 
                      if (this.dialogRef) {
                          this.dialogRef.close(true);
                      }
                  }

              },

              (response) => {
                  if(response.error){
                      this.showLoading = false;
                      this.isValidCredentials = false;
                  }
              }
          )
      }
  }

  async checkIfMarketplaceDefaultListExist(){
    const list = await this.drugsService.getDrugLists();
    let listInfo = list.data.map(v => ({...v, drugs: v.drugs.map(x =>({...x, details: JSON.parse(x.details)}))}))
    const listInfo_filter = listInfo.filter(list => list.name.toLowerCase() == this.drugsService.forbiddenDrugListName);
    
    if(!listInfo_filter.length){
        this.onAutoSaveDefaultDrugList(); // create default drug list
    }else{
        Swal.fire({
            html: '<h4 class="text-center">Retrieving Drugs And Pharmacies from your Default List<h4>',
            timer: 5000,
            showConfirmButton: false,
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            }).then(() => {
                this.removeLocalItems();
                this.onReloadPage_AfterLogin();
            }) 
    }
  }

  //save drugs and pharmacies to DB
  onAutoSaveDefaultDrugList() {  
    const drugs = JSON.parse(localStorage.getItem('rx_add_to_drug_list'));  
    const pharmacies = JSON.parse(localStorage.getItem('rx_add_to_pharmacy_list'));    
    const drugsNotes = localStorage.getItem('rx_drugs_notes'); 
    const pharmaciesNotes = localStorage.getItem('rx_pharmacies_notes'); 
    try {      
      this._api.callAPIEndpointAuthenticated('/api/drugs/save', this.auth_token, {
        "drug_list_type": 'prime',
        "monthCoverage": '',
        drugs: drugs,
        pharmacies: pharmacies,
        'notes' : '',
        'drug_notes': (drugsNotes) ? drugsNotes : '',
        'pharmacy_notes':(pharmaciesNotes) ? pharmaciesNotes : ''
      }).subscribe(
        (result: any) => {
          Swal.fire({
            html: '<h4 class="text-center">Your Drugs and Pharmacies has been saved to your Benny Dash Default List<h4>',
            timer: 4000,
            showConfirmButton: false,
            }).then(() => {
                this.onReloadPage_AfterLogin();
            }) 
            this.removeLocalItems();
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (e) {
      console.log('error', e);
    }
  }

  removeLocalItems(){ // remove local storage drugs pharmacies
    localStorage.removeItem('rx_add_to_pharmacy_list');
    localStorage.removeItem('rx_add_to_drug_list'); 
    localStorage.removeItem('rx_default_drug_list_id');
    localStorage.removeItem('rx_drugs_notes');
    localStorage.removeItem('rx_pharmacies_notes');
  }

  onReloadPage_AfterLogin(){ // reload current page
    location.reload();
  }

  onClick_ShowSignUpForm(){
    this.showSignUpForm.emit();
  }


  //   async onClick_DoneAddingDrugsPharma_Update(){
//     const pharmacies = JSON.parse(localStorage.getItem('rx_add_to_pharmacy_list'));
//     const drugs = JSON.parse(localStorage.getItem('rx_add_to_drug_list'));
//     const drugListId = localStorage.getItem('rx_default_drug_list_id'); 
//     const data = {
//       "rx_list_id": drugListId, 
//       drugs: drugs,
//       pharmacies: pharmacies
//     }
    
//     try{      
//       const updateStatus: any = await this.drugsService.updateDrugList(data);
//       if(updateStatus){         
//         Swal.fire({
//             html: '<h4 class="text-center">Your Default Drug List has been updated<h4>',
//             timer: 3000,
//             showConfirmButton: false,
//         }).then(() => {
//             this.onReloadPage_AfterLogin();
//         })             
//       }

//     }catch(e){
//       console.log('error', e);
//     }finally{

//     }
//   }

//   async getMarketplaceDrugList(content){
//     const list = await this.drugsService.getDrugLists();
//     let listInfo = list.data.map(v => ({...v, drugs: v.drugs.map(x =>({...x, details: JSON.parse(x.details)}))}))
//     const listInfo_filter = listInfo.filter(list => list.name.toLowerCase() == this.drugsService.forbiddenDrugListName);

//     console.log('bbbbbb', list);

//     this.drugsService.fetch_drugList_data(listInfo_filter[0].id);
//     localStorage.setItem('rx_default_drug_list_id', listInfo_filter[0].id);
//     console.log('listInfo', listInfo[0].drugs );
//     if(listInfo[0].drugs < 1 && listInfo[0].pharmacies < 1){ // if list is empty update benny drug list
//         console.log('overwrite existing drug list');
//         this.onClick_DoneAddingDrugsPharma_Update();
//         this.showModal_addToExistingDefaultList(content);
//     }else{
//         Swal.fire({
//             html: '<h4 class="text-center">We are fetching your Drugs and Pharmacies from your benny dash default drug list<h4>',
//             timer: 3000,
//             showConfirmButton: false,
//         }).then(() => {
//             this.onReloadPage_AfterLogin();
//         })        
//     }
//   }


}
