<!--START BANNER-->
<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">
            		<span>See How We Can Be Of Value To You.</span><br>         
            		Get In Touch With Us
					</h1>
					<p class="banner-sub-title">It is truly an honor to be able to serve and help you in any way. 
						Let us know anything at all that we can do to be of service to you and our team will be on the job to start finding solutions. 
						</p>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">
				<img src="./assets/img/common/woman-holding-phone-contact-us.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>
<!--END BANNER-->

<section class="contact-top">
	<div class="container-fluid">
		<h4 class="form-input-heading">
			Send us a message here and our team will assist you right away!
		</h4>
		<div class="container">
			<div class="d-flex justify-content-center">
				<div class="w-100 mx-md-4">
					<form class="contact-form">
						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="firstName">First name</label>
								<input type="text" class="form-control" id="firstName" [formControl]="controls.first_name">
							</div>
							<div class="form-group col-md-6">
								<label for="lastName">Last name</label>
								<input type="text" class="form-control" id="lastName" [formControl]="controls.last_name">
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="email">Email</label>
								<input type="email" class="form-control" id="email" [formControl]="controls.email">
							</div>
							<div class="form-group col-md-6">
								<label for="phoneNumber">Phone</label>
								<input type="text" class="form-control" id="phoneNumber" [formControl]="controls.phone">
							</div>
						</div>
						<div class="form-group">
							<label for="exampleFormControlTextarea1">Message</label>
							<textarea class="form-control" id="exampleFormControlTextarea1" rows="5"
								[formControl]="controls.message"></textarea>
						</div>
						<div class="row justify-content-around justify-content-md-between align-items-center">
							<!-- new recaptcha template - stephen 11-13-2019 -->
							<recaptcha [formControl]="taptapRecaptcha" (scriptLoad)="onScriptLoad()"
								(scriptError)="onScriptError()"
								style="transform: scale(0.9);-moz-transform: scale(0.9);-webkit-transform: scale(0.9);"
								class="mt-2"></recaptcha>
							<!-- end new recaptcha template -->
		
							<button class="btn btn-first mt-2" (click)="onInquire()">Send Message Now <i class="fas fa-paper-plane ml-3"></i></button>
						</div>
					</form>	
					<div class="contact-info mt-5">
						<div class="row">
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Phone Numbers:</h6>
								<p class="mb-1">Toll Free Phone: <span class="fWeight-500">1 (800) 215-5108</span></p>
								<p class="mb-1">Member Line: <span class="fWeight-500">1 (844) 200-8433</span></p>
							</div>
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Email Address:</h6>
								<a href="mailto:help@TapTapMedicare.com" class="text-secondary font-weight-normal text-decoration-none">help@TapTapMedicare.com</a>
							</div>
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Office Address:</h6>
								<p class="mb-1">TapTap Medicare</p>
								<p class="mb-1">230 S. Broad St. 17th Floor</p>
								<p class="mb-1">Philadelphia, PA 19102</p>
							</div>
							<div class="p-3 col-lg-4">
								<h6 class="text-secondary fWeight-600">Social Media:</h6>
								<div class="social-media-links d-flex align-items-center">
									<a class="social-button mx-1" href="https://facebook.com/taptapmedicareofficial" target="_blank"><i class="fa fa-facebook"></i></a>
									<a class="social-button mx-1" href="https://twitter.com/TapTapMedicare" target="_blank"><i class="fa fa-twitter"></i></a>
									<a class="social-button mx-1" href="https://www.pinterest.com/a245809541e5870293dd2e0684cf0d/overview/" target="_blank"><i class="fa fa-pinterest"></i></a>
									<a class="social-button mx-1" href="#"><i class="fa fa-play"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block contact-asset align-self-start" src="./assets/img/common/contact-us-woman-pink-dress.png" alt="Contact us image">
			</div>
		</div>	
	</div>
</section>

<section class="map-section container-fluid my-5">
	<div class="view-wrapper">
		<div class="footer-map-wrapper">
			<app-google-map-v14></app-google-map-v14>
		</div>
		<div class="footer-statue-wrapper">
		</div>
	</div>
	<div class="row justify-content-center align-items-center mt-4 mb-4">
		<a href="https://brightprice.gigabook.com/book/all-team-members/loc#panel_step_choose"
			target="_blank" class="btn btn-first">
			Schedule an Appointment
		</a>
	</div>
</section>