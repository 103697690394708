import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {APIService} from '../../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-online-application-mapd-version2',
  templateUrl: './version2.component.html',
  styleUrls: ['./version2.component.scss']
})
export class Version2Component implements OnInit {

  screenStep:any = 1;

  _token = '';

  medCardNumber1:any = '';
  medCardNumber2:any = '';
  medCardNumber3:any = '';

  details:any;

  details2: any = {
    customer_info:null
  }

  paramsObject: any;

  planName:any;

  planPremium: any;

  firstName:any;

  middleName:any;

  lastName:any;



  constructor(private _api: APIService, private route: ActivatedRoute) { 
    this._token = window.localStorage.getItem('auth_token');
    this.getUrlParams();
  }

  ngOnInit() {
    this.getUserInfo();
  }

  navigateToStep(page) {
    const screenSlide1 = document.querySelectorAll('.screen-slide');
    if(this.screenStep < page) {
      for (const slide of screenSlide1 as any) {
        slide.classList.remove('animate__fadeInLeft');
        slide.classList.add('animate__fadeInRight');
      }
      this.screenStep = page;
    } else {
      for (const slide of screenSlide1 as any) {
        slide.classList.remove('animate__fadeInRight');
        slide.classList.add('animate__fadeInLeft');
      }
      this.screenStep = page;
    }
  }

  nextScreen() {
    const screenSlide1 = document.querySelectorAll('.screen-slide');
    for (const slide of screenSlide1 as any) {
      slide.classList.remove('animate__fadeInLeft');
      slide.classList.add('animate__fadeInRight');
    }
    this.screenStep = this.screenStep + 1;
    console.log(this.screenStep)
  }

  previousScreen() {
    const screenSlide1 = document.querySelectorAll('.screen-slide');
    for (const slide of screenSlide1 as any) {
      slide.classList.remove('animate__fadeInRight');
      slide.classList.add('animate__fadeInLeft');
    }
    this.screenStep = this.screenStep - 1
    console.log(this.screenStep)
  }

  getUrlParams(){
    this.route.queryParamMap
      .subscribe((params) => {
          this.paramsObject = {...params };
        }
      );
      this.planName =  this.paramsObject['params'].plan;
      this.planPremium = this.paramsObject['params'].premium;
      this.firstName = this.paramsObject['params'].firstName;
      this.middleName = this.paramsObject['params'].middleName;
      this.lastName = this.paramsObject['params'].lastName;
  }

  getUserInfo(){
    this._api.callAPIEndpointAuthenticated('/api/user/current',this._token, {
      
    })
      .subscribe(
          (result: any) => {
            
            if(result.code == "1") {
              this.details = result.details;
              let card_number = this.details.customer_info.medicare_card_number;

              if(card_number){
                var res = card_number.split("-");
                this.medCardNumber1 = res[0];
                this.medCardNumber2 = res[1];
                this.medCardNumber3 = res[2];
              }else{
                this.medCardNumber1 = '';
                this.medCardNumber2 = '';
                this.medCardNumber3 = '';
              }

              console.log('BALIW SIYA', res);
              
            }else{
              this.details = this.details2;
            }

            console.log('BATAS NATIN ITO HUHUHU',this.details);
            
           
          },
          (err) => {
            console.log(err);
          }
      );
  }

}
