import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pdp-version2',
  templateUrl: './version2.component.html',
  styleUrls: ['./version2.component.scss']
})
export class Version2Component implements OnInit {

  @Input() results;
  @Input() logComparedKeys;
  @Input() controls;
  @Input() base_img_loc;
  @Input() loading;
  @Input() planYear;
  @Input() plan_val;
  @Input() isBlueBgHeading = true;
  
  @Output() showModalPharmacyEmit = new EventEmitter;
  @Output() showModalLisExtraEmit = new EventEmitter;
  @Output() showModalSeniorSavingsEmit = new EventEmitter;
  @Output() showModalViewGeneralCopaysPerTierEmit = new EventEmitter;
  @Output() showModalApplyOnlineEmit = new EventEmitter;
  @Output() showModalApplyOnThePhoneEmit = new EventEmitter;
  @Output() addToCompareFuncEmit = new EventEmitter;
  @Output() showModalMedicareStarRatings = new EventEmitter; 
  @Output() showModalFullFormularyEmit = new EventEmitter; 

  logCartKeys: Array<Object> = [];
  addToCartLabel:string = 'Add to Cart';
  currentUrlPathName:any;

  constructor(private _router: Router, private route: ActivatedRoute) { 
    this.getExistingCartPlans();
  }

  ngOnInit() {
  }

  getConvertedRate(rate) {
      return parseFloat((rate * .01).toFixed(2));
  }

  replaceSpaceWith(string, char = '-') {
    if(string)
        return string.replace(/ /g, char).replace(/,/g, '').replace(/\./g, '').toLowerCase();
  }

  companyLogoCheck(event){
      $(event.target).parent().find('.no-img-plan-name').addClass('d-block').removeClass('d-none');
  }

  showDrugModal(){

  }

  onClickPharmacyNetwork(result) {
    this.showModalPharmacyEmit.emit(result);
  }

  onClick_LisExtra(result){
    this.showModalLisExtraEmit.emit(result);
  }

  onClick_SeniorSavings(result){
    this.showModalSeniorSavingsEmit.emit(result);
  }

  onClick_ViewFullFormulary(result){
    this.showModalFullFormularyEmit.emit(result);
  }

  onClick_ViewGeneralCopaysPerTier(result){
    this.showModalViewGeneralCopaysPerTierEmit.emit(result);
  }

  onClick_ApplyOnline(result,i){
    this.showModalApplyOnlineEmit.emit({result, i});
  }

  onClick_ApplyOnThePhone(result){
    this.showModalApplyOnThePhoneEmit.emit(result);
  }

  addToCompareFunc(result,event,x){
    this.addToCompareFuncEmit.emit({result,event,x});
  }



  onclick_planDocsResources(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.toggle('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.remove('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.toggle('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.toggle('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.remove('d-md-flex');

  }

  onclick_doctorDrugAnalysis(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.toggle('d-flex');
    pab.classList.remove('d-flex');
    
    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.toggle('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.toggle('d-md-flex');
    arrow3.classList.remove('d-md-flex');
    
  }
  
  onclick_planActionButtons(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.toggle('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.toggle('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.toggle('d-md-flex');

  }

  onClick_MedicareStarRatings(){
    this.showModalMedicareStarRatings.emit();
  }

  
  addDrugs(modalViews, params) {
    const link = this._router.serializeUrl(this._router.createUrlTree(['/insurance-marketplace/medicare-drug-coverage/drugs'], {
      queryParams: params,
      queryParamsHandling: 'merge',
      relativeTo: this.route
    }));

    Swal.fire({
      html: modalViews,
      customClass: {
        popup: 'custom-modal-wrapper',
        content: 'text-left custom-modal-content'
      },
      padding: '40px',
      showCloseButton: true,
      width: 600,
      focusConfirm: false,
      confirmButtonColor: '#FFA710',
      confirmButtonText: 'GO',
    }).then((result) => {
        if (result.value) {
          window.open(link, '_blank');
        }
      });
  }

  redirectToAddingPharmacy(result){
    this._router.navigateByUrl('/insurance-marketplace/medicare-drug-coverage/drugs/step4?marketplace_type=pdp&plan_type='+ this.plan_val +'&state=' + result.state + '&county=' + result.county + '&zipcode=' + this.controls.zipcode.value + '&contract_id=' + result.contract_id + '&plan_id=' + result.plan_id + '&segment_id=' + result.segment_id + '&plan_year=' + this.planYear);
  }

  getExistingCartPlans() {
    let existingComparedEntries = JSON.parse(localStorage.getItem('add_to_cart_pdp'));
    if (existingComparedEntries) {
        existingComparedEntries.forEach((element) => {
          this.logCartKeys.push(element.key);
        });
    }
  }

  onClick_AddToCart(result,event) {

    const addToCartBtn = document.querySelector('#ci_cart_btn__'+result.key);
    const viewCartBtn = document.querySelector('#view_cart_btn_'+result.key);

    if (event.target.checked) {

      this.addToCartLabel = 'Added to Cart';
      addToCartBtn.classList.add('active');

      var existingEntries = JSON.parse(localStorage.getItem('add_to_cart_pdp'));
      if (existingEntries == null) {
        existingEntries = [];
      }

      existingEntries.push(result);
      localStorage.setItem('add_to_cart_pdp', JSON.stringify(existingEntries));

      viewCartBtn.classList.add("d-block");
      viewCartBtn.classList.remove("d-none");

    } else {
      this.addToCartLabel = 'Add to Cart';
      addToCartBtn.classList.remove('active');
      this.removeToCart(result);
      viewCartBtn.classList.remove("d-block");
      viewCartBtn.classList.add("d-none");
    }
  }

  removeToCart(result) {

    const cartItem =  JSON.parse(window.localStorage.getItem('add_to_cart_pdp'));

    if (cartItem) {
      for (let x = 0; x < cartItem.length; x++) {
        if (result['key'] !== 'undefined' && cartItem[x]['key'] !== 'undefined') {
          if (result['key'] === cartItem[x]['key']) {
            cartItem.splice(x, 1);
          }
        }
      }
    }

    localStorage.setItem('add_to_cart_pdp', JSON.stringify(cartItem));

  }

  saveThisPlan(){    
    this.currentUrlPathName = window.location.pathname;
    console.log(this.currentUrlPathName);    
  }

}
