import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-retiree-dashboard-navigation',
  templateUrl: './retiree-dashboard-navigation.component.html',
  styleUrls: ['./retiree-dashboard-navigation.component.scss']
})
export class RetireeDashboardNavigationComponent implements OnInit {

    hoverState: boolean = false;

  constructor(public _auth: AuthService,private elRef: ElementRef) { }
  ngOnInit() {
    //  console.log(this.elRef.nativeElement.parentElement(''));
      //this.router.navigate(['login']);
  }

  scrollToView(viewId){
    document.getElementById(viewId).scrollIntoView();
  }

  navHoverOver(event){
    console.log(event);
    jQuery(event.target).parent().parent().addClass('li-active');
  }

  navHoverOut(event){
    jQuery(event.target).parent().parent().removeClass('li-active');
  }


  onLogout() {
      this._auth.logUserOut();
  }
}
