<!-- <div class="container-fluid banner-section">
    <div class="row">
        <div class="col banner d-flex justify-content-center align-items-center">
            <div class="banner-box">
                <h1>Your Medicare Advantage Plan Marketplace</h1>
                <hr class="col-1">
                <p>See All Carriers, Plans, and Rates.<br><span>Clearly and Conveniently.</span></p>
            </div>
        </div>
    </div>
</div> -->
<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper">
		<div class="row">
			<div class="col-12">
				<h1 class="medium banner-main-title text-center">
					Marketplace Online Application
				</h1>
			</div>
		</div>
	</div>
</div>
<!--END BANNER-->

<!-- START BANNER POINTER -->
<div class="banner-pointer-wrapper">
    <i class="fa fa-arrow-down"></i>
    <div class="banner-pointer-background"></div>
</div>
<!-- END BANNER POINTER -->

<div class="container">
    <div class="row my-5 d-flex justify-content-center" *ngIf="showForm">
        <div class="px-0 col-md-9 form-wrapper">
            <div class="row mx-0">
                <h4 class="form-input-heading mx-0 mx-md-5">
                    Hooray! You Are One Step Closer Achieving Your
                    Financial Goals! Please  Fill this Form Below
                </h4>
                <form [formGroup]="applyOnlineForm">
                    <mat-horizontal-stepper formGroupName="formArray" labelPosition="bottom">

                        <ng-template matStepperIcon="edit">
                            <mat-icon style="color:#fff">done</mat-icon>
                        </ng-template> 
                        
                        <!-- BASIC INFO VIEW -->
                        <mat-step formGroupName="basicInfo" [stepControl]="formArray?.get(['basicInfo'])">   
                            <ng-template matStepLabel>Basic Info</ng-template>
                            <div class="row px-0 px-md-4 mt-5">
                                <div class="form-group col-md-6 ">
                                    <label for="first_name">First Name</label>
                                    <input type="text" formControlName="first_name" class="form-control" id="first_name" [(ngModel)]="first_name">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="last_name">Last Name</label>
                                    <input type="text" formControlName="last_name" class="form-control" id="last_name" [(ngModel)]="last_name">
                                </div>
                                <div class="form-group col-md-6 ">
                                    <label for="dob">Date of Birth</label>
                                    <input type="text" 
                                        formControlName="dob" 
                                        class="form-control" 
                                        id="dob" 
                                        [(ngModel)]="dob"
                                        [bsConfig]="bsConfig"
                                        bsDatepicker
                                        (bsValueChange)="handleDateChange($event, 'dob')"
                                        autocomplete="off">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="gender">Gender</label>
                                    <select class="form-control" formControlName="gender" id="gender" [(ngModel)]="gender">
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                
                                <div class="form-group col-md-6 ">
                                    <label for="marital_status">Marital Status</label>
                                    <select class="form-control" formControlName="marital_status" id="marital_status" [(ngModel)]="marital_status">
                                        <option value="Single">Single</option>
                                        <option value="Widowed">Widowed</option>
                                        <option value="Married">Married</option>
                                        <option value="Divorced">Divorced</option>
                                        <option value="Married Living Separately">Married Living Separately</option>
                                    </select>
                                </div>
                                <div class="form-group col-12 d-flex justify-content-center mt-4">
                                    <button class="btn btn-first" matStepperNext>
                                        Continue
                                        <!--<i class="fas fa-angle-double-right ml-2"></i>-->
                                    </button>
                                </div>
                            </div>
                        </mat-step>

                        <!-- MEDICARE INFO VIEW -->
                        <mat-step formGroupName="medicareInfo" [stepControl]="formArray?.get(['medicareInfo'])">
                            <ng-template matStepLabel>Medicare Info</ng-template>
                            <div class="row px-0 px-md-2 mt-5">
                                <div class="form-group col-md-6 ">
                                    <label for="part_a_effective_date">Part A Effective Date</label>
                                    <input type="text" 
                                        formControlName="part_a_effective_date" 
                                        class="form-control" 
                                        id="part_a_effective_date" 
                                        [(ngModel)]="part_a_effective_date"
                                        [bsConfig]="bsConfig"
                                        bsDatepicker
                                        (bsValueChange)="handleDateChange($event, 'part_a_effective_date')"
                                        autocomplete="off">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="part_b_effective_date">Part B Effective Date</label>
                                    <input type="text" 
                                        formControlName="part_b_effective_date" 
                                        class="form-control"
                                        id="part_b_effective_date" 
                                        [(ngModel)]="part_b_effective_date"
                                        [bsConfig]="bsConfig"
                                        bsDatepicker
                                        (bsValueChange)="handleDateChange($event, 'part_b_effective_date')"
                                        autocomplete="off">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="medicare_claim_number">Medicare Claim Number</label>
                                    <input type="text" formControlName="medicare_claim_number" class="form-control"
                                            id="medicare_claim_number" [(ngModel)]="medicare_claim_number">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="social_security_number">Social Security Number</label>
                                    <input type="text" formControlName="social_security_number" class="form-control"
                                            id="social_security_number" [(ngModel)]="social_security_number">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="quality_for_garrenteed_issue">Do You Qualify for Guaranteed
                                            Issue?</label>
                                    <input type="text" formControlName="quality_for_garrenteed_issue" class="form-control"
                                            id="quality_for_garrenteed_issue" [(ngModel)]="quality_for_garrenteed_issue">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="new_coverage_effective_date">New Coverage Effective Date</label>
                                    <input type="text" 
                                        formControlName="new_coverage_effective_date" 
                                        class="form-control"
                                        id="new_coverage_effective_date" 
                                        [(ngModel)]="new_coverage_effective_date"
                                        [bsConfig]="bsConfig"
                                        bsDatepicker
                                        (bsValueChange)="handleDateChange($event, 'new_coverage_effective_date')"
                                        autocomplete="off">
                                </div>
                                <div class="form-group col-12 d-flex justify-content-between mt-4">
                                    <button class="btn btn-third" matStepperPrevious>
                                        <!--<i class="fas fa-angle-double-left mr-2"></i>-->
                                        Back
                                    </button>
                                    <button class="btn btn-first" matStepperNext>
                                        Next
                                        <!--<i class="fas fa-angle-double-right ml-2"></i>-->
                                    </button>
                                </div>
                            </div>
                        </mat-step>

                        <!-- ABOUT YOU VIEW -->
                        <mat-step formGroupName="aboutYou" [stepControl]="formArray?.get(['aboutYou'])">
                            <ng-template matStepLabel>About You</ng-template>
                            <div class="row px-0 px-md-4 mt-5">
                                <div class="form-group col-md-6 ">
                                    <label for="height">Height</label>
                                    <select formControlName="height" id="height" class="form-control" [(ngModel)]="height">
                                        <option value="5">5ft and Below</option>
                                        <option value="6">6ft</option>
                                        <option value="7">7ft</option>
                                        <option value="8">8ft</option>
                                        <option value="9">9ft</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="weight">Weight</label>
                                    <select formControlName="weight" id="weight" class="form-control" [(ngModel)]="weight">
                                        <option *ngFor="let a of weightValues; let i = index;"
                                                value="{{weightValues[i] + '-' + (weightValues[i+1] ? weightValues[i+1] : weightValues[i] + 10) }}">{{weightValues[i] + '-' + (weightValues[i+1] ? weightValues[i+1] : weightValues[i] + 10) }} lbs
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="street_address">Street Address</label>
                                    <input type="text" formControlName="street_address" class="form-control" id="street_address" [(ngModel)]="street_address">
                                </div>            
                                <div class="form-group col-md-6">
                                    <label for="city_town">City / Town</label>
                                    <input type="text" formControlName="city_town" class="form-control" id="city_town" [(ngModel)]="city_town">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="state">State</label>
                                    <!-- <input type="text" formControlName="state" class="form-control" id="state"> -->
                                    <select id="state" class="custom-select form-control" formControlName="state" [(ngModel)]="state">
                                        <option value="AL" selected="selected">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="zip_code">Zip Code</label>
                                    <input type="text" formControlName="zip_code" class="form-control" id="zip_code" [(ngModel)]="zip_code">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="phone_number">Phone Number</label>
                                    <input type="text" formControlName="phone_number" class="form-control" id="phone_number" [(ngModel)]="phone_number">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="alternative_phone_number">Alternative Phone Number</label>
                                    <input type="text" formControlName="alternative_phone_number" class="form-control"
                                            id="alternative_phone_number" [(ngModel)]="alternative_phone_number">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="email">Email</label>
                                    <input type="email" formControlName="email" class="form-control" id="email" [(ngModel)]="email"  email="true">

                                    <div *ngIf="aboutYouEmail.value !== '' && aboutYouEmail.invalid && (aboutYouEmail.dirty || aboutYouEmail.touched)" class="alert alert-danger">
                                        <div *ngIf="aboutYouEmail.errors.email">
                                            Invalid format
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="tobacco_use">Tobacco Use</label>
                                    <select formControlName="tobacco_use" id="tobacco_use" class="form-control" [(ngModel)]="tobacco_use">
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="take_prescription_medication">Do You Currently Take Any Prescription Medications?</label>
                                    <select formControlName="take_prescription_medication" id="take_prescription_medication"
                                                class="form-control" [(ngModel)]="take_prescription_medication">
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div class="col-12" *ngIf="take_prescription_medication === 'Yes'">
                                    <div class="bordered-left row mx-0 ml-md-2 pl-0 pl-md-3">
                                        <div class="form-group col-12">
                                            <label for="prescription_drug_name1">1. Prescription Drug Name + Reason for taking</label>
                                            <input type="text" formControlName="prescription_drug_name1" class="form-control"
                                            id="prescription_drug_name1" [(ngModel)]="prescription_drug_name1">
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="prescription_drug_name2">2. Prescription Drug Name + Reason for taking</label>
                                            <input type="text" formControlName="prescription_drug_name2" class="form-control"
                                                    id="prescription_drug_name2" [(ngModel)]="prescription_drug_name2">
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="prescription_drug_name3">3. Prescription Drug Name + Reason for taking</label>
                                            <input type="text" formControlName="prescription_drug_name3" class="form-control"
                                                    id="prescription_drug_name3" [(ngModel)]="prescription_drug_name3">
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="prescription_drug_name4">4. Prescription Drug Name + Reason for taking</label>
                                            <input type="text" formControlName="prescription_drug_name4" class="form-control"
                                                    id="prescription_drug_name4" [(ngModel)]="prescription_drug_name4">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12 d-flex justify-content-between mt-4">
                                    <button class="btn btn-third" matStepperPrevious>
                                        Back
                                    </button>
                                    <button class="btn btn-first" matStepperNext>
                                        Next
                                        <!--<i class="fas fa-angle-double-right ml-2"></i>-->
                                    </button>
                                </div>
                            </div>

                        </mat-step>
                        
                        <!-- PAYMENT INFO VIEW -->
                        <mat-step formGroupName="paymentInfo" [stepControl]="formArray?.get(['paymentInfo'])">
                            <ng-template matStepLabel>Payment Info</ng-template>
                            <div class="row px-0 px-md-2 mt-5 payment-info-section">
                                <div class="form-group col-md-6">
                                    <label for="payment_mode">Payment Mode</label>
                                    <select formControlName="payment_mode" id="payment_mode" class="form-control" [(ngModel)]="payment_mode">
                                        <option value="Monthly">Monthly</option>
                                        <option value="Quarterly">Quarterly</option>
                                        <option value="Semi-Annual">Semi-Annual</option>
                                        <option value="Annual">Annual</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="payment_method">Payment Method</label>
                                    <select formControlName="payment_method" id="payment_method" class="form-control" [(ngModel)]="payment_method">
                                        <option value="Send Me The Bill in The Mail">Send Me The Bill in The Mail</option>
                                        <option value="AutoPay">AutoPay</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="bank_name">Bank Name</label>
                                    <input type="text" formControlName="bank_name" class="form-control" id="bank_name" [(ngModel)]="bank_name">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="routing_number">Routing Number</label>
                                    <input type="text" formControlName="routing_number" class="form-control" id="routing_number" [(ngModel)]="routing_number">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="account_number">Account Number</label>
                                    <input type="text" formControlName="account_number" class="form-control" id="account_number" [(ngModel)]="account_number">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="requested_date_of_health_interview">Requested Date of Health Interview</label>
                                    <input type="text" 
                                        formControlName="requested_date_of_health_interview" 
                                        class="form-control date-requested"
                                        id="requested_date_of_health_interview" 
                                        [(ngModel)]="requested_date_of_health_interview"
                                        [bsConfig]="bsConfig"
                                        bsDatepicker
                                        (bsValueChange)="handleDateChange($event, 'requested_date_of_health_interview')"
                                        autocomplete="off">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="requested_time_for_health_interview">Requested Time for Health Interview</label>
                                    <timepicker formControlName="requested_time_for_health_interview" [(ngModel)]="requested_time_for_health_interview"></timepicker>
                                </div>
                                <div class="form-group col-12">
                                    <label for="additional_notes">Additional Notes (If Any)</label>
                                    <input type="text" formControlName="additional_notes" class="form-control" id="additional_notes" [(ngModel)]="additional_notes">
                                </div>
                                <div class="form-group col-12 d-flex justify-content-between mt-4">
                                    <button class="btn btn-third" matStepperPrevious>
                                        Back
                                    </button>
                                    <button class="btn btn-first" matStepperNext>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </mat-step>

                        <!-- CONFIRMATION VIEW -->
                        <mat-step formGroupName="confirmation" [stepControl]="formArray?.get(['confirmation'])">
                            <ng-template matStepLabel>Confirmation</ng-template>
                            <div class="row confirmation-section">
                                <div class="col-12">
                                    <h5>Basic Info</h5>
                                    <table class="table table-bordered">
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">First Name</td>
                                            <td>{{ first_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Last Name</td>
                                            <td>{{ last_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Date of Birth</td>
                                            <td>{{ dateValues['dob'] ? dateValues['dob'] : '' }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Gender</td>
                                            <td>{{ gender }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Marital Status</td>
                                            <td>{{ marital_status }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12">
                                    <h5>Medicare Info</h5>
                                    <table class="table table-bordered">
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Part A Effective Date</td>
                                            <td>{{ dateValues['part_a_effective_date'] ? dateValues['part_a_effective_date'] : '' }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Part B Effective Date</td>
                                            <td>{{ dateValues['part_b_effective_date'] ? dateValues['part_b_effective_date'] : '' }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Medicare Claim Number</td>
                                            <td>{{ medicare_claim_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Social Security Number</td>
                                            <td>{{ social_security_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Do You Qualify for Guaranteed Issue?</td>
                                            <td>{{ quality_for_garrenteed_issue }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">New Coverage Effective Date</td>
                                            <td>{{ dateValues['new_coverage_effective_date'] ? dateValues['new_coverage_effective_date'] : '' }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12">
                                    <h5>About You</h5>
                                    <table class="table table-bordered">
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Height</td>
                                            <td>{{ height }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Weight</td>
                                            <td>{{ weight }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Street Address</td>
                                            <td>{{ street_address }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">City / Town</td>
                                            <td>{{ city_town }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">State</td>
                                            <td>{{ state }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Zip Code</td>
                                            <td>{{ zip_code }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Phone Number</td>
                                            <td>{{ phone_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Alternative Phone Number</td>
                                            <td>{{ alternative_phone_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Email</td>
                                            <td>{{ email }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Tobacco Use</td>
                                            <td>{{ tobacco_use }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Do You Currently Take Any Prescription Medications?</td>
                                            <td>{{ take_prescription_medication }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">1. Prescription Drug Name + Reason for taking</td>
                                            <td>{{ prescription_drug_name1 }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">2. Prescription Drug Name + Reason for taking</td>
                                            <td>{{ prescription_drug_name2 }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">3. Prescription Drug Name + Reason for taking</td>
                                            <td>{{ prescription_drug_name3 }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">4. Prescription Drug Name + Reason for taking</td>
                                            <td>{{ prescription_drug_name4 }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12">
                                    <h5>Payment Info</h5>
                                    <table class="table table-bordered">
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Payment Mode</td>
                                            <td>{{ payment_mode }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Payment Method</td>
                                            <td>{{ payment_method }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Bank Name</td>
                                            <td>{{ bank_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Routing Number</td>
                                            <td>{{ routing_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Account Number</td>
                                            <td>{{ account_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Requested Date of Health Interview</td>
                                            <td>{{ dateValues['requested_date_of_health_interview'] ? dateValues['requested_date_of_health_interview'] : '' }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">Requested Time for Health Interview</td>
                                            <td>{{ requested_time_for_health_interview | date: 'shortTime' }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bg-primary fColor-white fWeight-500">additional Notes (If Any)</td>
                                            <td>{{ additional_notes }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12">
                                    <div class="form-group col-12 d-flex justify-content-between mt-4">
                                        <button class="btn btn-third" matStepperPrevious>
                                            Back
                                        </button>
                                        <button (click)="onSchedClick()" class="btn btn-first">
                                            Submit Application Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </mat-step>

                    </mat-horizontal-stepper>
                </form>
            </div> 
        </div>
    </div>

    <!-- THANK YOU SECTION -->
    <div class="row d-flex align-items-center  d-none" *ngIf="!showForm && !isShowRequiredField">
                    
        <div class="px-0 col-sm-7 col-lg-8 form-wrapper offset-lg-2 text-center">
            <h4 class="form-input-heading">
                Hooray! You Have Successfully Submitted<br>
                Your Medigap Insurance Application!
            </h4>
            <p style="font-size:20px;" class="font-secondary">
                Next step is to wait for the conﬁrmation call from <br>
                one of our ﬁnancial representatives.
            </p>
            <a routerLink="/home" class="btn btn-first mt-5 col-12 col-md-4 mx-auto">
                Back to Home
            </a>
        </div>
        <div class="col-sm-5 col-lg-2 form-left-img">
            <img src="./assets/img/common/old-woman-with-safety-kit.png" alt="">
        </div>

    </div>

    <div class="row d-flex align-items-center" *ngIf="isShowRequiredField">
        <form [formGroup]="applyOnlineRequiredForm">

            <div class="px-0 col-12 form-wrapper d-flex flex-column align-items-center mb-5">
                <h4 class="form-input-heading text-center">
                    Kindly Fill-in Required Field to Proceed!
                </h4>
                <div class="form-group">
                    <label for="phone_number">Phone Number</label>
                    <input type="text" formControlName="phone_number" class="form-control" id="phone_number" required>
                </div>
                <button (click)="onSchedClick(true)" class="btn btn-first">
                    Submit Application Now   
                </button>
            </div>
        </form>
    </div>
</div>