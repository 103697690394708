<h4 class="title mb-4">Paying Your Plan Premium</h4>
<p class="mb-5">You can pay your monthly plan premium (including any late enrollment penalty that you currently have or may owe) by mail or Electronic Funds Transfer (EFT) each month. You can also choose to pay your premium by automatic deduction from your Social Security or Railroad Retirement Board (RRB) benefit check each month.</p>
<h6 class="text-center">Your Monthly Premium Payment For This Plan Is:</h6>
<h5 class="text-center">{{ planName }}</h5>
<div class="d-flex justify-content-center mt-2 mb-5">
  <h5 class="fWeight-600 fColor-black">Plan Premium:</h5>
  <h5 class="fWeight-700 fColor-black">{{ planPremium ? planPremium: '$0.00' }} </h5>
</div>
<p>If you don’t select a payment option, you will get a bill each month (even if your Plan Premium is $0.00).
</p>

<h5 class="fColor-black fWeight-600">Select A Premium Payment Method:</h5>
<div class="row">
  <div class="col-12">
    <div class="custom-control custom-checkbox mt-2">
      <input type="checkbox" class="custom-control-input" id="selectAPremiumPaymentMethod_1">
      <label class="custom-control-label" for="selectAPremiumPaymentMethod_1">Monthly Coupon Book/Paper Bill</label>
    </div>
  </div>
  <div class="col-12">
    <div class="custom-control custom-checkbox mt-2">
      <input type="checkbox" class="custom-control-input" id="selectAPremiumPaymentMethod_2">
      <label class="custom-control-label" for="selectAPremiumPaymentMethod_2">Automatic deduction from your monthly Social Security or Railroad Retirement Board (RRB) benefit check.</label>
    </div>
    <div class="pl-5">
      <p class="fWeight-600">I get monthly benefits from:</p>
      <div class="flex">
        <div class="custom-control custom-checkbox mt-2 mr-4">
          <input type="checkbox" class="custom-control-input" id="iGetMonthlyBenefitsFrom_1">
          <label class="custom-control-label" for="iGetMonthlyBenefitsFrom_1">Social Security</label>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="iGetMonthlyBenefitsFrom_2">
          <label class="custom-control-label" for="iGetMonthlyBenefitsFrom_2">RRB</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="custom-control custom-checkbox mt-2">
      <input type="checkbox" class="custom-control-input" id="selectAPremiumPaymentMethod_3">
      <label class="custom-control-label" for="selectAPremiumPaymentMethod_3">Monthly Auto Draft From Bank Account</label>
    </div>
  </div>
</div>

