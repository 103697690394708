<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue pt-3">Important Statements for Applicants to Read</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <div class="dashed-box border shadow p-4">
        <h4 class="text-center fw-semibold">Please Read and Sign.</h4>
        <div>
          <p class="text-label">By completing this form, I agree to the following:</p>
          <div class="p-2">
            <div class="">
              <h6 class="d-flex justify-content-center text-center">Important Statements for Applicants to Read</h6>
            </div>
          </div>
          <div class="p-2">
            <ul>
              <li>
                You do not need more than one Medicare Supplement policy.
              </li>
              <li>
                If you purchase this policy, you may want to evaluate your existing health coverage and decide if you need multiple coverages.
              </li>
              <li>
                You may be eligible for benefits under Medicaid and may not need a Medicare Supplement policy.
              </li>
              <li>
                If, after purchasing this policy, you become eligible for Medicaid, the benefits and premiums under your Medicare Supplement policy can be suspended, if requested, during your entitlement to benefits under Medicaid for 24 months. You must request this suspension within 90 days of becoming eligible for Medicaid. If you are no longer entitled to Medicaid, your suspended Medicare Supplement policy (or, if that is no longer available, a substantially equivalent policy) will be reinstituted if requested within 90 days of losing Medicaid eligibility. If the Medicare Supplement policy provided coverage for outpatient prescription drugs and you enrolled in Medicare Part D while your policy was suspended, the reinstituted policy will not have outpatient prescription drug coverage but will otherwise be substantially equivalent to your coverage before the date of the suspension.
              </li>
              <li>
                If you are eligible for and have enrolled in a Medicare Supplement policy by reason of disability and you later become covered by an employer or union-based group health plan, the benefits and premiums under your Medicare Supplement policy can be suspended, if requested, while you are covered under the employer or union-based group health plan. If you suspend your Medicare Supplement policy under these circumstances and later lose your employer or union-based group health plan, your suspended Medicare Supplement policy (or, if that is no longer available, a substantially equivalent policy) will be reinstituted if requested within 90 days of losing your employer or union-based group health plan. If the Medicare Supplement policy provided coverage for outpatient prescription drugs and you enrolled in Medicare Part D while your policy was suspended, the reinstituted policy will not have outpatient prescription drug coverage but will otherwise be substantially equivalent to your coverage before the date of the suspension.
              </li>
              <li>
                Counseling services are available in Delaware to provide advice concerning your purchase of Medicare Supplement insurance and concerning medical assistance through the state Medicaid program, including benefits as a Qualified Medicare Beneficiary (QMB) and a Specified Low-income Medicare Beneficiary (SLMB). Call ELDER info at 800-336-9500 or 302-674-7364.
              </li>
            </ul>
            <p>
              I hereby apply to <b>[Insurance Carrier NAIC Name]</b> for coverage to be issued based upon the truth and completeness of the answers to the above questions, and understand and agree that:
            </p>
            <ul>
              <li>
                (1) no agent has the authority to waive the answer to any questions on the application;
              </li>
              <li>
                (2) no insurance will be effective until (a) a policy has been issued by the Company and (b) the initial premium has been paid; and
              </li>
              <li>
                (3) I have received the Outline of Medicare Supplement Coverage for the policy applied for, the required Guide to Health Insurance for People with Medicare, and the MIB Notice.
              </li>
            </ul>
          </div>
          <div class="py-3">
            <p>
              <strong>CAUTION:</strong> Please review your answers to the questions on the application. It is important to the issuance of this policy that all questions are answered correctly and truthfully.
            </p>
            <p>
              <strong>WARNING:</strong> Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance may be guilty of a crime and may be subject to fines and confinement in prison.
            </p>
          </div>
        </div>
        <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" [(ngModel)]="importantStatementsAgreeDate" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="iAgree14">    
              <label class="custom-control-label fw-semibold text-dblue" for="iAgree14">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>