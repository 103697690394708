import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  
  @Input() details: any;

  @Input() isSpouseActive = false;

  @Output() emit_onClick_maritalStatus = new EventEmitter;

  @Output() emit_onCheckedMailingAddress = new EventEmitter;

  @Output() emit_onClickCalendar = new EventEmitter;
  
  constructor() { }

  ngOnInit() {
  }

  onClick_maritalStatus(type,number){
    this.emit_onClick_maritalStatus.emit({type, number});
  }

  onCheckedMailingAddress(event){
    this.emit_onCheckedMailingAddress.emit(event);
  }

  onClickDob(type, heading){
    this.emit_onClickCalendar.emit({type,heading});
  }

}
