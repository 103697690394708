export class AuthenticatedUser {
    id?:string;
    auth_token:string;
    first_name:string;
    last_name:string;
    external_party:string;
    email:string;
    name:string;

    public static initFromJSON(json: Object) {
        if (typeof json == 'string') {
            json = JSON.parse(<string>json);
        }

        let obj      = new AuthenticatedUser(),
            jsonKeys = Object.keys(json);

        jsonKeys.map((key, index) => {
            obj[key] = json[key];
        });

        return obj;
    }
}
