import {Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, Event } from '@angular/router';    
import { filter } from 'rxjs/operators';
import {Title, Meta} from "@angular/platform-browser";
import {ActivatedRoute} from '@angular/router';

@Injectable()
export class HeaderService {
    visible: boolean = true;
    fullPrimaryHeader:boolean = true;
    isRetireeDashboard:boolean = false;
    isInteractive:boolean = false;
    fullHeaderContent:boolean = false;
    isNoMinHeight:boolean = false;
    showMarketplaceWidgetBtn = false;
    isNoHeader:boolean = false;
    isNewBennyDash:boolean = false;

    isBennyDashHeaderFixed = false;

    public renderer: Renderer2;

    constructor(public router: Router, private _renderer: RendererFactory2, private titleService:Title, private route: ActivatedRoute, private metaTagService: Meta) { // inject router to access urls
        
        this.renderer = _renderer.createRenderer(null, null);
        
        this.router.events.subscribe((event: Event) => {
            
            if (event instanceof NavigationEnd) {
                
                const myArray = this.router.url.split("/");
                let currentSegment = myArray[3];
                let currentSegmentFirst = myArray[1];
                
                if( currentSegment == "372401352")
                {
                    this.titleService.setTitle("Education - Medicare");
                }else if( currentSegment == "372401352")
                {
                    this.titleService.setTitle("Education - Life Insurance");
                    
                }
                else if( currentSegment == "372401382")
                {
                    this.titleService.setTitle("Education - Long Term Care Insurance ");
                    
                }
                else if( currentSegment == "372401422")
                {
                    this.titleService.setTitle("Education - Loan Refinancing");
                }
                else if( currentSegment == "372401442")
                {
                    this.titleService.setTitle("Education - Reverse Mortgages ");
                }
                else if( currentSegment == "372401443")
                {
                    this.titleService.setTitle("Education - Medicare Enrollment");
                }
                else if( currentSegment == "372401444")
                {
                    this.titleService.setTitle("Education - Medicare Penalties ");
                }
                else if( currentSegment == "372401445")
                {
                    this.titleService.setTitle("Education - Indemnity Coverage ");
                }
                else if( currentSegment == "372401446")
                {
                    this.titleService.setTitle("Education - Medicare Supplement Medigap"); 
                }
                else if( currentSegment == "372401447")
                {
                    this.titleService.setTitle("Education - Dental Coverage");
                }
                else if( currentSegment == "372401448")
                {
                    this.titleService.setTitle("Education - Social Security");
                }
                else if( currentSegment == "372401449")
                {
                  
                    this.titleService.setTitle("Education - Financial Assistance Programs");  
                }
                else if( currentSegment == "372401450" )
                {
                 
                    this.titleService.setTitle("Education - Medicare Advantage");     
                }
                else if( currentSegment == "372401451" )
                {
                    
                    this.titleService.setTitle("Education - Medicare Part D Drug Coverage"); 
                }
                else if( currentSegment == "372401452" )
                {
                    this.titleService.setTitle("Education - Retirement Income Planning");   
                }
                else if( currentSegment == "372401453" )
                {
                    this.titleService.setTitle("Education - Vision Coverage "); 
                }
                else if( currentSegment == "372401462" )
                {
                    this.titleService.setTitle("Education - Debt Relief + Loan Refinance");    
                }
                else if( currentSegment == "1628419953000" )
                {
                    this.titleService.setTitle("Education - Medicare Basics");   
                }
                else if( currentSegment == "1628419996000" )
                {
                    this.titleService.setTitle("Education - Medicare Part A");   
                }
                else if( currentSegment == "1628420290000" )
                {
                    this.titleService.setTitle("Education - Medicare Part B");   
                }
                else if( currentSegment == "articles" )
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Learn and read all our Medicare articles here at TapTap Education Center Hub. Click Here To Read More.' }
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare articles,taptap medicare' }
                    );
                }
                else if( currentSegment == "videos" )
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Watch all our educational videos about Medicare here at TapTap Medicare Education Center. Visit Our Page To Watch More Videos.' }
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare videos,taptap medicare' }
                    );   
                }
                //////End of third segment

                if( currentSegmentFirst == 'about')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'TapTap Medicare is dedicated to serve and improve your Medicare experience, financial clarity, and cost-efficiency. Learn More About TapTap Medicare Here.' }
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'about taptap medicare, taptap medicare' }
                    );
                }else if( currentSegmentFirst == 'home')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Get access to all plans and carriers & have a clear and well-organized Medicare experience with TapTap Medicare. Click Here For More Information.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare, taptap medicare, medicare experience' },
                    );
                    
                }else if( currentSegmentFirst == 'insurance-marketplace')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Shop at different Medicare plans with ease and convenience here at TapTap Medicare Insurance Marketplace. Visit Our Page For More Information.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare insurance marketplace,medicare insurance,medicare marketplace,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'medicare-services')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'TapTap Medicare provides instant and convenient access to all Medicare services & solutions with clear explanations and guidance. Visit Our Page Now.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare services,medicare solutions,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'organizer')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Stay organized with the information about your health with our Medicare organizer here at TapTap Medicare. Click Here For More Details.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare organizer, taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'organizer')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Got some questions or concerns about our services? Dont hesitate to get in touch with us here at TapTap Medicare. Visit Our Page For More Details.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'terms-of-services')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Access and read our Terms of Services here at TapTap Medicare. Click Here To Read More. ' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'terms of use,terms of service,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'partnership-opportunities')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Looking for partnership opportunities? TapTap Medicare offers ways to work on financial planning alongside with our consumers. Click Here & Discuss With Us.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'partnership opportunities,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'education')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Learn and understand different Medicare-related topics and explanations with TapTap Medicare Guidance & Education Center. Access Our Page Here.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'taptap education center,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'become-a-contributor')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Do you have insights, knowledge, or past experiences about financial planning? Become a contributor here at TapTap Medicare! Click Here For More Details.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'become a contributor,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'part-b-enrollment-center')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Start your Medicare enrollment experience with our simple & organized Enrollment Center at TapTap Medicare. Visit Our Page For More Details.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare enrollment,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'i-am-new-to-medicare')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Make your "New To Medicare" experience a breeze with our easy-to-understand approach and guidance here at TapTap Medicare. Click Here To Get Started.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'new to medicare,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'community')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Share, & discuss your insights & experiences with others in the same Medicare community here at TapTap Medicare. Visit Our Page & Connect With Us.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'medicare community,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == 'registration')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: 'Start your Medicare journey by creating your own Medicare account here at TapTap Medicare. Click Here To Register.' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: 'create medicare account,taptap medicare' },
                    );
                    
                }else if( currentSegmentFirst == '')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: '' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: '' },
                    );
                    
                }else if( currentSegmentFirst == '')
                {
                    this.metaTagService.updateTag(
                        { name: 'description', content: '' },
                    );
                    this.metaTagService.updateTag(
                        { name: 'keywords', content: '' },
                    );
                    
                }
               
                
                


                if(this.router.url.includes('beneficiary')){

                    this.fullPrimaryHeader = false;
                    this.isRetireeDashboard = true;
                    this.isNoMinHeight = true;
    
                }else if(this.router.url.includes('benny')){

                    this.fullPrimaryHeader = false;
                    this.isRetireeDashboard = true;
                    this.isNoMinHeight = true;
                    this.isBennyDashHeaderFixed = true;
                    this.isNewBennyDash = true;
    
                }else if(this.router.url.includes('slider-form')){
                    
                    this.fullPrimaryHeader = false;
                    this.isInteractive = true;
                    $('.app-main-content').addClass('flat-bg-content');
                    this.renderer.addClass(document.body, "slider-form-body");
                    
                }else if(this.router.url.includes('interactive')){
    
                    this.fullPrimaryHeader = false;
                    this.isInteractive = true;
                    $('.app-main-content').addClass('flat-bg-content');
    
                }else if(this.router.url.includes('login')){

                    this.fullHeaderContent = false;
                    this.isNoMinHeight = true;

                }else if(this.router.url.includes('registration')){

                    this.fullHeaderContent = true;

                }else if(this.router.url.includes('medigap/plan-n')){

                    this.isNoHeader = true;
                
                }else if(this.router.url.includes('medigap/plan-letter-table')){

                    this.isNoMinHeight = true;

                }else{

                    this.fullPrimaryHeader = true;
                    this.isRetireeDashboard = false;
                    this.isInteractive = false;                    
                    this.fullHeaderContent = false;
                    this.isNoHeader = false;
                    $('.app-main-content').removeClass('flat-bg-content');
                    this.isNoMinHeight = false;

                }
                if(this.router.url.includes('results') && this.router.url.includes('insurance-marketplace')){   
                    $('#custom-widget-chat-btn-id').addClass('d-none');
                    $('.bennyDash-widget-btn').addClass('d-none');
                    $('.marketplace-widget-btn').removeClass('d-none');
                }else if(this.router.url.includes('benny-dash')){
                    $('#custom-widget-chat-btn-id').addClass('d-none');
                    $('.marketplace-widget-btn').addClass('d-none');
                    $('.bennyDash-widget-btn').removeClass('d-none');
                   //  $('#marketplace-widget-form').addClass('d-none');
                   //  $('.marketplace-widget-overlay').addClass('d-none');                    
                }else{                
                    $('#custom-widget-chat-btn-id').removeClass('d-none');
                    $('.marketplace-widget-btn').addClass('d-none');
                    $('.bennyDash-widget-btn').addClass('d-none');
                }
            }
        });
       
    }
        
    hide() {
        this.visible = false;
    }

    show() {
        this.visible = true;
    }

    toggle() {
        this.visible = !this.visible;
    }
}
