<div class="container-fluid header-banner-wrapper home-banner-wrapper">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper text-center text-md-left">
    <div class="row">
      <div class="col-md-8">
        <div class="banner-text-wrapper">
          <h1 class="banner-main-title">
            <span>Your Medicare Marketplace</span><br />
            Made Simple and Clear.
          </h1>
          <p class="banner-sub-title">
            TapTap Medicare has made viewing Medicare plans easy, convenient,
            and transparent.
          </p>
        </div>
      </div>
      <div class="col-md-4 px-0 banner-image-wrapper">
        <img
          src="assets/img/common/old-woman-half-body-with-basket-v1.png"
          class="banner-main-image"
        />
      </div>
    </div>
  </div>
</div>

<div class="banner-pointer-wrapper" style="margin-bottom: 0px;">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>

<app-insurance-logo [isNoPaddingTop]="true"></app-insurance-logo>

<section class="container-fluid main-content-wrapper home-wrapper">
  <div class="row home-content">
    <div class="col-12 row home-content-first-box-wrapper">
      <div class="col-md-12 home-content-row-div blue-bg">
        <div
          class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block mb-1"
          style="z-index: 1"
        >
          <i class="fa fa-arrow-down font-blue color-primary"></i>
          <div
            class="banner-pointer-background inverse-colored-down-arrow"
          ></div>
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-12 col-md-5 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0"
            >
              <img
                class="flip-horizontal"
                src="assets/img/common/5-cat-woman.png"
              />
            </div>
            <div
              class="row col-12 col-md-7 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5"
            >
              <h4 class="col-12 fColor-white text-center fWeight-500">
                5 Key Criteria For<br> Choosing a Medigap Carrier:
              </h4>
              <table class="col-12 table-criteria mt-20">
                <tr>
                  <td class="first-col">Criteria 1</td>
                  <td class="second-col">Current Price Competitiveness</td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 2</td>
                  <td class="second-col">Track Record of Price Stability</td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 3</td>
                  <td class="second-col">
                    Financial Ratings and Overall Reputation
                  </td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 4</td>
                  <td class="second-col">
                    Quality and Convenience of Customer Service
                  </td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 5</td>
                  <td class="second-col">
                    Inclusion of Wellness and Fitness Membership
                  </td>
                </tr>
              </table>

              <div class="col-12 row justify-content-center mt-20">
                <button class="btn btn-first-plain" (click)="speakToCounselorEvent(speakToCounselorModal, 400)">
                  Speak With a Concierge Now
                </button>
              </div>
              <div class="col-12 row justify-content-center mt-20">
                <p class="col-7 fColor-white" style="font-size: 14px; text-align: center">
                  Or Scroll Below To Start Viewing Plans and Prices Now
                </p>
              </div>
              <div class="col-12 row">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block"
      >
        <i class="fa fa-arrow-down"></i>
        <div class="banner-pointer-background"></div>
      </div>
      
      <div class="col-md-12 home-content-row-div mt-50">
        <div class="container-fluid">
          <div
            class="view-wrapper d-flex justify-content-around align-items-center"
          >
            <div class="view-left"></div>
            <div class="view-center">
              <h4 class="form-input-heading" style="line-height: 44px">
                <span>Medigap Prices Made Easy.</span>
                <span>The Full Marketplace</span>
                <span>No Tricks. No Gimmicks</span>
              </h4>

              <form>
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <!-- zip code -->
                    <div class="form-group 1st-level" *ngIf="isShow">
                      <label for="zipCode">Zip Code</label>
                      <input
                        type="text"
                        class="form-control"
                        id="zipCode"
                        [formControl]="controls.zipcode"
                      />
                    </div>
                    <!-- end zip code -->

                    <!-- plan letter -->
                    <div class="form-group 2nd-level" *ngIf="!isShow">
                      <label for="planLetter">
                        Plan Letter
                        <i
                          class="fa fa-question global-info-icon-first"
                        ></i>
                      </label>
                      <div
                        class="d-flex justify-content-between planLetterButtonWrapper"
                      >
                        <!-- not massachusets -->
                        <button
                          (click)="planLetterFunc('F')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterF }"
                          *ngIf="!isMassachusetts"
                        >
                          Plan F
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="planLetterFunc('G')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterG }"
                          *ngIf="!isMassachusetts"
                        >
                          Plan G
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="planLetterFunc('N')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterN }"
                          *ngIf="!isMassachusetts"
                        >
                          Plan N
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="planLetterFunc('All')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterAP }"
                          *ngIf="!isMassachusetts"
                        >
                          All Plans
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <!-- end not massachusets -->

                        <!-- massachusets option -->
                        <button
                          (click)="planLetterFunc('MA_CORE')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterCorePlan }"
                          *ngIf="isMassachusetts"
                        >
                          Core Plan
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="planLetterFunc('MA_SUPP1')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterPlan1 }"
                          *ngIf="isMassachusetts"
                        >
                          Plan 1
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="planLetterFunc('MA_SUPP2')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterPlan1A }"
                          *ngIf="isMassachusetts"
                        >
                          Plan 1A
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="planLetterFunc('All-MA')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: planLetterMAAP }"
                          *ngIf="isMassachusetts"
                        >
                          All Plans
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <!-- end massachusets option -->
                      </div>
                      <div class="form-group-info d-none">
                        <select
                          [(ngModel)]="planLetterSelected"
                          id="planLetter"
                          class="custom-select form-control"
                          [formControl]="controls.plan"
                        >
                          <option value="F" selected *ngIf="!isMassachusetts">
                            F
                          </option>
                          <option value="N" *ngIf="!isMassachusetts">N</option>
                          <option value="G" *ngIf="!isMassachusetts">G</option>
                          <option value="All" *ngIf="!isMassachusetts">
                            All Plans
                          </option>
                          <option
                            value="MA_CORE"
                            selected
                            *ngIf="isMassachusetts"
                          >
                            Core Plan
                          </option>
                          <option value="MA_SUPP1" *ngIf="isMassachusetts">
                            Plan 1
                          </option>
                          <option value="MA_SUPP2" *ngIf="isMassachusetts">
                            Plan 1A
                          </option>
                          <option value="All-MA" *ngIf="isMassachusetts">
                            All Plans
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- end plan letter -->
                  </div>

                  <div class="col-12 d-flex justify-content-center">
                    <!-- age -->
                    <div class="form-group 1st-level" *ngIf="isShow">
                      <label for="age">Your Age</label>
                      <div
                        class="d-flex justify-content-between ageButtonWrapper"
                      >
                        <button
                          (click)="ageFunc('65')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: age65 }"
                        >
                          65
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="ageFunc('66')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: age66 }"
                        >
                          66
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="ageFunc('67')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: age67 }"
                        >
                          67
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="ageFunc('68')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: age68 }"
                        >
                          68
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="ageFunc('69')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: age69 }"
                        >
                          69
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="ageFunc('all')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: ageAll }"
                          style="position: relative"
                        >
                          {{ allAgeSelectedText }}
                          <i class="fas fa-check ml-2"></i>
                          <div style="position: absolute; width: 100%">
                            <mat-form-field>
                              <mat-select
                                (selectionChange)="selectedAgeFunc($event)"
                              >
                                <mat-option value="64"
                                  >64 - Disabled or ESRD</mat-option
                                >
                                <mat-option value="70">70</mat-option>
                                <mat-option value="71">71</mat-option>
                                <mat-option value="72">72</mat-option>
                                <mat-option value="73">73</mat-option>
                                <mat-option value="74">74</mat-option>
                                <mat-option value="75">75</mat-option>
                                <mat-option value="76">76</mat-option>
                                <mat-option value="77">77</mat-option>
                                <mat-option value="78">78</mat-option>
                                <mat-option value="79">79</mat-option>
                                <mat-option value="80">80</mat-option>
                                <mat-option value="81">81</mat-option>
                                <mat-option value="82">82</mat-option>
                                <mat-option value="83">83</mat-option>
                                <mat-option value="84">84</mat-option>
                                <mat-option value="85">85</mat-option>
                                <mat-option value="85">86</mat-option>
                                <mat-option value="85">87</mat-option>
                                <mat-option value="85">88</mat-option>
                                <mat-option value="85">89</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </button>
                      </div>
                      <!-- old age dropdown -->
                      <select
                        [(ngModel)]="allAgeSelectedValue"
                        *ngIf="isAllAgesSelected"
                        id="age"
                        class="custom-select form-control d-none"
                        [formControl]="controls.age"
                      >
                        <option value="64">64 - Disabled or ESRD</option>
                        <option selected value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                        <option value="81">81</option>
                        <option value="82">82</option>
                        <option value="83">83</option>
                        <option value="84">84</option>
                        <option value="85">85</option>
                        <option value="85">86</option>
                        <option value="85">87</option>
                        <option value="85">88</option>
                        <option value="85">89</option>
                      </select>

                      <select
                        [(ngModel)]="ageSelected"
                        *ngIf="!isAllAgesSelected"
                        id="age"
                        class="custom-select form-control d-none"
                        [formControl]="controls.age"
                      >
                        <option value="64">Under Age 64</option>
                        <option value="64">64 - Disabled or ESRD</option>
                        <option selected value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                        <option value="81">81</option>
                        <option value="82">82</option>
                        <option value="83">83</option>
                        <option value="84">84</option>
                        <option value="85">85</option>
                        <option value="85">86</option>
                        <option value="85">87</option>
                        <option value="85">88</option>
                        <option value="85">89</option>
                      </select>
                      <!-- end old age dropdown -->
                    </div>
                    <!-- end age -->

                    <!-- tobacco use -->
                    <div class="form-group 2nd-level" *ngIf="!isShow">
                      <div *ngIf="isMassachusetts">
                        <label for="apply_household_discount">
                          Are You Within 6 Months of:
                          <i
                            class="fa fa-question global-info-icon-first d-none"
                          ></i>
                        </label>
                        <div
                          class="d-flex flex-column justify-content-between household-button-wrapper"
                        >
                          <div class="form-row">
                            <button
                              (click)="withinSixMonthsOfFunc(1)"
                              class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                              [ngClass]="{ active: partBEffectiveDate }"
                            >
                              Part B Effective Date
                              <i class="fas fa-check ml-2"></i>
                            </button>
                          </div>
                          <div class="form-row">
                            <button
                              (click)="withinSixMonthsOfFunc(2)"
                              class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                              [ngClass]="{ active: sixtyFifthBirthdayMonth }"
                            >
                              65th Birthday Month
                              <i class="fas fa-check ml-2"></i>
                            </button>
                          </div>
                          <div class="form-row">
                            <button
                              (click)="withinSixMonthsOfFunc(3)"
                              class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                              [ngClass]="{ active: losingEmployerCoverage }"
                            >
                              Losing Employer Coverage
                              <i class="fas fa-check ml-2"></i>
                            </button>
                          </div>
                          <div class="form-row">
                            <button
                              (click)="withinSixMonthsOfFunc(4)"
                              class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                              [ngClass]="{ active: movingToMassachusetts }"
                            >
                              Moving To Massachusetts
                              <i class="fas fa-check ml-2"></i>
                            </button>
                          </div>
                          <div class="form-row">
                            <button
                              (click)="withinSixMonthsOfFunc(5)"
                              class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                              [ngClass]="{
                                active: movingAndLosingAccessToHmoPlan
                              }"
                            >
                              Moving & Losing Access to HMO Plan
                              <i class="fas fa-check ml-2"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="!isMassachusetts">
                        <label for="tobacco_use">Tobacco Use</label>
                        <div class="d-flex justify-content-between">
                          <button
                            (click)="tobaccoUseFunc(1)"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: tobaccoUseYes }"
                          >
                            Yes
                            <i class="fas fa-check ml-2"></i>
                          </button>
                          <button
                            (click)="tobaccoUseFunc(0)"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: tobaccoUseNo }"
                          >
                            No
                            <i class="fas fa-check ml-2"></i>
                          </button>
                        </div>
                        <!-- old tobacco dropdown -->
                        <select
                          [(ngModel)]="tobaccoUseSelected"
                          id="tobacco_use"
                          class="custom-select form-control d-none"
                          [formControl]="controls.tobacco"
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        <!-- end old tobacco dropdown -->
                      </div>
                    </div>
                    <!-- end tobacco use -->
                  </div>

                  <div class="col-12 d-flex justify-content-center">
                    <!-- gender -->
                    <div class="form-group 2nd-level" *ngIf="isShow">
                      <label for="gender">Gender</label>
                      <div class="d-flex justify-content-between">
                        <button
                          (click)="genderFunc('f')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: genderFemale }"
                        >
                          Female
                          <i class="fas fa-check ml-2"></i>
                        </button>
                        <button
                          (click)="genderFunc('m')"
                          class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                          [ngClass]="{ active: genderMale }"
                        >
                          Male
                          <i class="fas fa-check ml-2"></i>
                        </button>
                      </div>

                      <!-- old gender dropdown -->
                      <select
                        [(ngModel)]="genderSelected"
                        id="gender"
                        class="custom-select form-control d-none"
                        [formControl]="controls.gender"
                      >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                      <!-- end old gender dropdown -->
                    </div>
                    <!-- end gender -->

                    <!-- apply household discount -->
                    <div class="form-group 1st-level" *ngIf="!isShow">
                      <label for="apply_household_discount">
                        Apply Household Discount
                        <i
                          class="fa fa-question global-info-icon-first d-none"
                        ></i>
                      </label>
                      <div
                        class="d-flex flex-column justify-content-between household-button-wrapper"
                      >
                        <div class="form-row">
                          <button
                            (click)="householdFunc('nhm')"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: noHouseholdMembers }"
                          >
                            No, No Household Members
                            <i class="fas fa-check ml-2"></i>
                          </button>
                          <i
                            class="fa fa-question global-info-icon-first"
                          ></i>
                        </div>
                        <div class="form-row">
                          <button
                            (click)="householdFunc('awhm')"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: applyingWithHouseholdMember }"
                          >
                            Yes, Applying With Household Member
                            <i class="fas fa-check ml-2"></i>
                          </button>
                          <i
                            class="fa fa-question global-info-icon-first"
                          ></i>
                        </div>
                        <div class="form-row" *ngIf="!isMassachusetts">
                          <button
                            (click)="householdFunc('mnat')"
                            class="btn btn-first btn-small marketplace-input-quote-btn d-flex justify-content-center align-items-center"
                            [ngClass]="{ active: marriedNotApplyingTogether }"
                          >
                            Married, But Not Applying Together
                            <i class="fas fa-check ml-2"></i>
                          </button>
                          <i
                            class="fa fa-question global-info-icon-first"
                          ></i>
                        </div>
                      </div>
                      <select
                        [(ngModel)]="householdSelected"
                        id="apply_household_discount"
                        class="custom-select form-control d-none"
                        [formControl]="controls.household"
                      >
                        <option value="0">No</option>
                        <option value="1" *ngIf="!isThisIsMeActive_Top">
                          Yes, Applying Together
                        </option>
                        <option value="0" *ngIf="!isThisIsMeActive_Bottom">
                          Married, Not Applying Together
                        </option>

                      </select>
                    </div>
                    <!-- end apply household discount -->
                  </div>
                </div>

                <div class="row mt-3 justify-content-center">
                  <div
                    class="form-group d-flex justify-content-between"
                    *ngIf="!isShow"
                  >
                    <button
                      type="submit"
                      class="btn btn-third btn-back mx-1"
                      id="medigap_back_btn"
                      (click)="hideInputs()"
                    >
                      <span>Back</span>
                    </button>
                    <button
                      type="submit"
                      class="btn btn-first mx-1 getMyQuoteBtn"
                      (click)="onSearch()"
                    >
                      See My Prices
                    </button>
                  </div>
                  <div
                    class="form-group d-flex justify-content-center"
                    *ngIf="isShow"
                  >
                    <button
                      type="submit"
                      class="btn btn-first"
                      (click)="showInputs()"
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="view-right">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 home-content-row-div mt-50 blue-bg height-auto">
        <div
          class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block"
          style="z-index: 9999"
        >
          <i class="fa fa-arrow-down font-blue color-primary"></i>
          <div
            class="banner-pointer-background inverse-colored-down-arrow"
          ></div>
        </div>
        <div class="container">
          <div
            class="row col-12 col-md-12 d-flex justify-content-center text-center text-md-left"
          >
            <p
              class="fColor-white fWeight-300 center"
              style="font-size: 24px !important"
            >
              Although there are 10 different Medigap Plans available to
              consumer, the<br />
              vast majority of Medicare Beneficiaries have either
              <b class="fColor-white">Plan F, Plan G</b> or
              <b class="fColor-white">Plan N</b><br />
              due to their
              <b class="fColor-white"
                >clear set of benefits, ease of use, and
                cost-competitiveness.</b
              >
            </p>
          </div>
          <div
            class="row col-12 col-md-12 d-flex justify-content-center text-center text-md-left"
          >
            <button class="col-md-3 col-sm-10 btn btn-first-plain plan-btn">
              PLAN F
            </button>
            <button class="col-md-3 col-sm-10 btn btn-first-plain plan-btn">
              PLAN G
            </button>
            <button class="col-md-3 col-sm-10 btn btn-first-plain plan-btn">
              PLAN N
            </button>
          </div>
          <div
            class="row col-12 col-md-12 d-flex justify-content-center text-center text-md-left pr-md-5 mt-50"
          >
            &nbsp;
          </div>
        </div>
      </div>
      <div
        class="banner-pointer-wrapper d-none d-md-block d-lg-block d-xl-block mb-3"
      >
        <i class="fa fa-arrow-down"></i>
        <div class="banner-pointer-background"></div>
      </div>

      <div class="col-md-12 home-content-row-div height-auto">
        <div class="container">
          <div class="row flex-column justify-content-center align-items-center">
            <div class="col-8 home-text-box center">
              <h4 class="center">
                What Does Your Total Coverage Package<br />With A Medigap Look
                Like?
              </h4>
            </div>
            <img src="./assets/img/common/icons/total-coverage-package.png" alt="Total Coverage Package" class="total-coverage-package-img">
            
            <h6 class="col-12 col-md-4 btn-type orange">
              Option 1 Coverage Package Example
            </h6>

            <h4 class="col-md-8 col-sm-10 row justify-content-center col-sm-10 fColor-white btn-type blue">
                Example Total Premiums & Out-of-Pocket
            </h4>

            <!-- mobile -->
            <div class="col-10 row justify-content-center d-block d-md-none d-lg-none d-xl-none">
              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Original Medicare</label>
                <div class="col-12 center">
                  <img src="assets/img/retiree-dashboard/original-medicare-card.png" />
                </div>
                <div class="col-12 row justify-content-center border-bottom">
                  <span class="col mt-20">Premium</span>
                </div>
                <div class="col-12 row justify-content-center border-bottom">
                  <span class="col mt-20">Out-of-Pocket</span>
                </div>
              </div>

              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Medicare Supplement</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img
                      src="assets/img/retiree-dashboard/overview-coverage_box-secondary.png"/>
                  </div>
                  <span class="col-10 mt-20">MEDIGAP</span>
                  <span class="col-10 mt-20">MAJOR MEDICAL</span>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-green col mt-20">$100 - $150/month</div>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-blue col mt-20">$0 - $198/year</div>
                </div>
              </div>

              <div
                class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Standalone Part D Plan</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img src="assets/img/retiree-dashboard/overview-coverage_box-drug.png"/>
                  </div>
                  <span class="col-10 mt-20">MEDICARE PART D DRUG PLAN</span>
                  <span class="col-10 mt-20">PRESCRIPTION DRUGS</span>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-green col mt-20">$15 - $25/month</div>
                </div>
                <div class="col-12 row justify-content-center border-bottom table-coverage-footer">
                  <div class="label-blue col mt-20">$0 - $3,000</div>
                </div>
              </div>

              <div class="col-12 row justify-content-center table-coverage-content mt-20">
                <label class="col-12 center">Individual Dental Policy</label>
                <div class="col-12 row justify-content-center">
                  <div class="col-10 center">
                    <img
                      src="assets/img/retiree-dashboard/overview-coverage_box-dental.png"
                    />
                  </div>
                  <span class="col-10 mt-20">DENTAL INSURANCE</span>
                  <span class="col-10 mt-20">DENTAL</span>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer"
                >
                  <div class="label-green col mt-20">$25 - $35/month</div>
                </div>
                <div
                  class="col-12 row justify-content-center border-bottom table-coverage-footer"
                >
                  <div class="label-blue col mt-20">$0 - $1000/year</div>
                </div>
              </div>
            </div>

            <app-insurance-coverage-box highlighted="medigap" size="large" class="col-12 col-md-10"></app-insurance-coverage-box>	

            <div class="col-12 col-md-10 row justify-content-center table-coverage-footer">
              <div class="col-12 col-md-3 row center border-bottom border-right light-green">
                <span class="col-10" class="fColor-base">Premium</span>
              </div>
              <div class="col-12 col-md-3 row center border-bottom border-right light-green">
                <div class="label-green col-12 center">
                  $100 - $150/month
                </div>
              </div>
              <div class="col-12 col-md-3 row center border-bottom border-right light-green">
                <div class="label-green col-12 center">
                  $15 - $25/month
                </div>
              </div>
              <div class="col-12 col-md-3 row center border-bottom light-green">
                <div class="label-green col-12 center">
                  $25 - $35/month
                </div>
              </div>

              <div class="col-12 col-md-3 row center border-right light-blue">
                <span class="col-10">Out-of-Pocket</span>
              </div>
              <div class="col-12 col-md-3 row center border-right light-blue">
                <div class="label-blue col-12 center">
                  $0 - $198/year
                </div>
              </div>
              <div class="col-12 col-md-3 row center border-right light-blue">
                <div class="label-blue col-12 center">$0 - $3,000</div>
              </div>
              <div class="col-12 col-md-3 row center light-blue">
                <div class="label-blue col-12 center">
                  $0 - $1000/year
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="d-none">
  <div #videoContent>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <div
          class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true"
          style="height: 100%; position: relative; width: 100%"
        >
          <div
            class="wistia_swatch"
            style="
              height: 100%;
              left: 0;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              transition: opacity 200ms;
              width: 100%;
            "
          >
            <img
              src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch"
              style="
                filter: blur(5px);
                height: 100%;
                object-fit: contain;
                width: 100%;
              "
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>

<div class="d-none {{ dblocking }}" #speakToCounselorModal>
  <app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>
