import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MetadataService } from '../services/metadata.service';
import Swal from 'sweetalert2';
import {Title, Meta} from "@angular/platform-browser";

declare var window: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [MetadataService]
})
export class AboutComponent implements OnInit {
  private currentUrl = window.location.href;
  private baseUrl = window.location.origin;
  fullPrimaryHeader: boolean = true;
  isLoggedInUser: any;

  constructor(
    public _auth: AuthService,
    private metadataService: MetadataService, 
    private titleService:Title,
    private metaTagService: Meta
  ) {
    this.titleService.setTitle("About Our Company - TapTap Medicare");
  }

  

  

  injectSEO(): void {
    this.metadataService.getMetaData('/about', (a) => {
      const { data } = a;
      const metatitle = document.getElementById('meta-title');
      const metadesc = document.getElementById('meta-desc');
      const keywords = document.getElementById('meta-keywords');
      const fbMetaTitle = document.getElementById('fb-meta-title');
      const fbMetaDescription = document.getElementById('fb-meta-description');
      const fbMetaUrl = document.getElementById('fb-meta-url');
      const fbMetaImage = document.getElementById('fb-meta-image');

      metatitle.setAttribute('content', data['meta-title']);
      metadesc.setAttribute('content', data['meta-desc']);
      keywords.setAttribute('content', data['meta-keywords']);
      fbMetaTitle.setAttribute('content', data['meta-title']);
      fbMetaDescription.setAttribute('content', data['meta-desc']);
      fbMetaUrl.setAttribute('content', this.currentUrl);
      fbMetaImage.setAttribute(
        'content',
        `${this.baseUrl}/assets/company-logo.jpg`
      );
      window.prerenderReady = true;
    });
  }

  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if (this.isLoggedInUser) {
      this.fullPrimaryHeader = false;
    }

    this.injectSEO();
  }

  watchVideo(content) {
    Swal.fire({
      html: content,
      showConfirmButton: false,
      width: 900
    });
  }
}
