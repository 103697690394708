import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compare-plans',
  templateUrl: './compare-plans.component.html',
  styleUrls: ['./compare-plans.component.scss']
})
export class ComparePlansComponent implements OnInit {
  auth_token: any
  fullPrimaryHeader: boolean = true;
  plan_text = '';
  loading:boolean = false;
  results:any;
  result:any;
  forCompare: Array<Object> = [];
  base_img_loc = './assets/img/medicare-advantage/';
  hasResult:boolean = false;

  constructor() {

      this.results =  window.localStorage.getItem('add_to_compare');
      this.results = JSON.parse(this.results);

      if(this.results){
        this.hasResult = true;
      }
  }





  ngOnInit() {

    this.auth_token = window.localStorage.getItem('auth_token');
    if (this.auth_token) {
      this.fullPrimaryHeader = false;
    }



    /*if( this.forCompare ) {
        this.forCompare = JSON.parse(this.forCompare);
    }*/

    //console.log(this.forCompare);


  }

  replaceSpaceWith(string, char = '-') {
     return string.replace(/ /g, char).replace(/,/g, '').replace(/\./g, '').toLowerCase();
  }

    getConvertedRate(rate) {
        return parseFloat((rate * .01).toFixed(2));
    }

  clearComparePlans() {
      window.localStorage.removeItem('add_to_compare');
      this.results = [];
      this.hasResult = false;
  }


}
