<section class="aep-dashboard-wrapper" [class.active]="isSidebarActive"> 
  <div class="aep-dashboard-sidebar">
    <app-aep-sidenav></app-aep-sidenav>
    <button class="aep-toggle-sidebar-menu-btn" (click)="sidebarMenuToggle()">
      <i class="fas" [class.fa-times]="isSidebarActive" [class.fa-bars]="!isSidebarActive"></i>
    </button>
  </div>
  <div class="aep-dashboard-content">
    <router-outlet></router-outlet>
  </div>
</section>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>