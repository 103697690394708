import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-application-medigap-version1-screen1',
  templateUrl: './screen1.component.html',
  styleUrls: ['./screen1.component.scss']
})
export class Screen1Component implements OnInit {

  dateFieldType: string;
  dateFieldHeading: string;

  policyInsured: boolean = false;
  dateOfBirthFormat = null
  customerDateOfBirth = null

  constructor() { }

  ngOnInit() {
  }

  noteModal(content:any):void {
    Swal.fire({
      html: content,
      showCloseButton: true,
      showConfirmButton: false,
      buttonsStyling: false,
      width: 640,
      padding:'35px',
    })
  }

  closeModal():void {
    Swal.close()
  }

}
