<div *ngIf="loading" class="margin-top-100 margin-bottom-100" style="height:300px; width: 100%; text-align: center;">
        <div class="text-center">
            <div class="spinner-border" style="width: 4rem; height: 4rem; margin-top: 150px;" role="status">
                <span class="sr-only">Sending...</span>
            </div>
        </div>
</div>

<div *ngIf="!loading">

    <!-- <h4 class="modal-title-heading">Save Your Quotes</h4>

    <form [formGroup]="saveMyQuoteForm" [class.form-validated] = "isFormSubmit" autocomplete="off">
        <div class="row justify-content-center">
            <div class="form-group col-12">
                <label for="name_emailMyQuotes">First Name</label>
                <input type="search" autocomplete="off" class="form-control" id="name_emailMyQuotes" formControlName="first_name">
                <ng-container *ngIf="(first_name.invalid && first_name.touched) || first_name.dirty || isFormSubmit">
                    <small *ngIf="first_name.invalid && first_name.errors.required" class="text-danger">
                        First Name is Required
                    </small>
                </ng-container>
            </div>
            <div class="form-group col-12">
                <label for="lastname_emailMyQuotes">Last Name</label>
                <input type="search" autocomplete="off" class="form-control" id="lastname_emailMyQuotes" formControlName="last_name">
                <ng-container *ngIf="(last_name.invalid && last_name.touched) || last_name.dirty || isFormSubmit">
                    <small *ngIf="last_name.invalid && last_name.errors.required" class="text-danger">
                        Last Name is Required
                    </small>
                </ng-container>
            </div>
            <div class="form-group col-12">
                <label for="email_emailMyQuotes">Email</label>
                <input type="search" autocomplete="off" class="form-control" id="email_emailMyQuotes" formControlName="email">
                <ng-container *ngIf="(email.invalid && email.touched) || email.dirty || isFormSubmit">
                    <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
                    <small *ngIf="email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                </ng-container>
            </div>
            <div class="form-group col-12">
                <label for="phone_emailMyQuotes">Mobile Phone</label>
                <input type="search" autocomplete="off" class="form-control" id="phone_emailMyQuotes" formControlName="phone">
            </div>
            <div class="form-group col-12 d-none">
                <label for="phone_emailMyQuotes">Url</label>
                <input type="search" autocomplete="off" class="form-control" id="url" value="{{ getQuotationUrl() }}">
            </div>
            <div class="form-group col-12 d-flex justify-content-center">
                <button type="submit" class="btn btn-first fluid"
                (click)="saveYourQuote()">
                Save My Quote
                </button>
            </div>
        </div>
    </form> -->
    <h6 class="text-center fWeight-500 fColor-base">Please press CTRL + D on your Keyboard to Bookmark this page. Thank you.</h6>
</div>