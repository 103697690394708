import {Component, OnInit } from '@angular/core';
import {DrugsService} from '../../../services/drugs.service';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-common-step1',
  templateUrl: './common-step1.component.html',
  styleUrls: ['./common-step1.component.scss']
})
export class CommonStep1Component implements OnInit {
  viewDefault:boolean = true;
  viewCommonListDrugs:boolean = false;
  viewTypesOfMedications:boolean = false;
  view_drug_list:boolean = false;
  view_selectDosage:boolean = false;
  isLoading: boolean = false;
  results: any[] = [];
  fips: any[] = [];
  fips_code: any = '';
  searchFieldDrugname: any = '';
  step1Results: any[] = [];
  branded: any[] = [];
  generic: any[] = [];
  selectedDrug: any;
  drugTableName:string = 'Most Commonly Taken Drugs';
  drugTable_prev_view = 'common';
  tagResults: any[] = [];
  generic_grouped: any = [];
  branded_grouped: any = [];
  paramsObject: any;
  isEditDrug:boolean = false;
  editDrugName: any;
  editDrugId: any;
  searchDrugName: any;

  step1Form = new FormGroup({
    drugname: new FormControl('', [
      Validators.required,
    ]),
    fipscode: new FormControl('', [
      Validators.required,
    ]),
    allow_generics: new FormControl(true, [
      Validators.required,
    ]),
  });

  selectYourDrugsFromList:boolean = false;
  isShowCommonMedication:boolean = false;

  constructor(private _router: Router, private route: ActivatedRoute, private drugService: DrugsService) {
    this.route.queryParamMap
      .subscribe((params) => {
          this.paramsObject = {...params };
          this.isEditDrug = this.paramsObject['params'].edit_drug;
          this.editDrugName = this.paramsObject['params'].edit_drug_name;
          this.searchDrugName = this.paramsObject['params'].search_drug_name;          
          this.editDrugId = this.paramsObject['params'].edit_drug_id;

          if(this.isEditDrug){            
            this.onSubmit(event);
          }else{
            this.view_drug_list = false;
            this.branded = [];
            this.generic = [];
          }
        }
      );
  }

  ngOnInit() {
  }

  async getSuggestedList(keyword) {
    try {
      //this.isLoading = true;
      const results: any = await this.drugService.getAutoSuggest(keyword);

      if (results) {
        this.results = results;
      }
      //this.isLoading = false;
    } catch(e) {
      //this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
      //this.isLoading = false;
    }
  }

  async getFipsSuggestedList(keyword) {
    try {
      //this.isLoading = true;
      const fips: any = await this.drugService.getFipsAutoSuggest(keyword);

      if (fips) {
        this.fips = fips;
      }
      //this.isLoading = false;
    } catch(e) {
      // console.log('error', e);
      //this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
      //this.isLoading = false;
    }
  }

  updateMySelection(event: any) {
    this.selectedDrug = event;
  }

  fullnameValueChanged(event: any) {
    const text = event;

    if (text.length > 2) {
      this.getSuggestedList(text);
    }else{
      this.results = [];
    }
  }

  fipsValueChanged(event: any) {
    const text = event;

    if (text.length > 2) {
      this.getFipsSuggestedList(text);
    }
  }

  fipsChange(data) {
    this.fips_code = data.code;
  }


  async onSubmit(event: any) {
    this.view_drug_list = true;
    this.isLoading = true;
    this.branded = [];
    this.generic = [];
    this.branded_grouped = [];
    this.generic_grouped = [];
    
    try {
      let step1Results;
      //this.isLoading[type] = true;
      if(this.isEditDrug){
        step1Results = await this.drugService.step1(this.editDrugName, this.step1Form.controls['allow_generics'].value);
      }else{
        step1Results = await this.drugService.step1(this.step1Form.controls['drugname'].value, this.step1Form.controls['allow_generics'].value);
      }
      
      this.step1Results = step1Results;
      this.branded = step1Results.branded;
      this.generic = step1Results.generic;

      if(this.isEditDrug){
        this.branded = this.branded.filter(item => item.drug_info.brand_name.toLowerCase() == this.editDrugName.toLowerCase());
        this.generic = this.generic.filter(item => item.drug_info.drug_name.toLowerCase() == this.editDrugName.toLowerCase());
      }
      
      // group generic drug by package
      const genGroup = this.generic.reduce((r, a) => {
        r[a.drug_info.package] = [...r[a.drug_info.package] || [], a];
        return r;
      }, {});      
      for (var key of Object.keys(genGroup)) {
          this.generic_grouped.push({'type': key, 'drugs':genGroup[key]});
      }

      // group branded drug by package
      const brandGroup = this.branded.reduce((r, a) => {
        r[a.drug_info.package] = [...r[a.drug_info.package] || [], a];
        return r;
      }, {});

      for (var key of Object.keys(brandGroup)) {
        this.branded_grouped.push({'type': key, 'drugs':brandGroup[key]});
      }

      /*if (step1Results) {
        this.step1Results = step1Results;
      }*/
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
    }

  }

  viewNextStep(drug, isBranded) {

    const rxcui = drug.rxcui;

    const selectedDname = (drug.drug_info.brand_name) ? drug.drug_info.brand_name : drug.drug_info.drug_name;
    
    const selectedDrugId = (this.isEditDrug) ? this.editDrugId : this.selectedDrug.id;
    const selectedDrugName = (this.isEditDrug) ? this.editDrugName : this.selectedDrug.name;

    this._router.navigate(['step2', rxcui], { queryParams : {'drug_id': selectedDrugId, 'drug_name': selectedDrugName, 'selected_drug_name': selectedDname.toLowerCase(), 'is_branded': isBranded }, queryParamsHandling: 'merge', relativeTo: this.route });
  }

  viewNextStep_TypesMedication() {
    this._router.navigate(['step2', '262095'], { queryParams : {'drug_id': '2820', 'drug_name': 'lipitor'}, queryParamsHandling: 'merge', relativeTo: this.route });
  }


  onClick_ViewDefault(){
    this.viewDefault = true;
    this.viewCommonListDrugs = false;
    this.viewTypesOfMedications = false;
  }

  onClick_ViewTheMostCommonDrugs(tag){
    this.viewDefault = false;
    this.viewCommonListDrugs = true;
    this.viewTypesOfMedications = false;
    this.drugTable_prev_view = 'common';
    this.getTaggedList(tag);

  }

  onClick_ViewTypesOfMedications(){
    this.viewDefault = false;
    this.viewCommonListDrugs = false;
    this.viewTypesOfMedications = true;
    this.drugTable_prev_view = 'types';
  }

  onClick_types_of_medications(tableTitle,tag){
    this.viewDefault = false;
    this.viewCommonListDrugs = true;
    this.viewTypesOfMedications = false;
    this.drugTableName = tableTitle;
    this.getTaggedList(tag);
  }

  onClick_backToPrevView(type){
    if(type == 'common'){
      this.onClick_ViewDefault();
    }else{
      this.onClick_ViewTypesOfMedications();
    }
  }

  async getTaggedList(tag) {
    try {
      this.isLoading = true;
      const results: any = await this.drugService.getTaggedList(tag);

      if (results) {
        this.isLoading = false;
        this.tagResults = results;
      }
      //this.isLoading = false;
    } catch(e) {      
      //this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
      //this.isLoading = false;
    }
  }

  otherWaysToAddModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true
    })
  }


  
  
}
