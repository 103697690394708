import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs/index';
import { AuthService } from '../services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  fullPrimaryHeader:boolean = true;
  isLoggedInUser:any;
  users: Observable<any>;
  constructor(private db: AngularFirestore,public _auth: AuthService) { }

  ngOnInit() {

    this.users = this.db.collection('users')
          .valueChanges();

    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if(this.isLoggedInUser){
        this.fullPrimaryHeader = false;
    }

  }

}
