<div class="container testimonial-section">
  <h2>Don’t Just Take Our Word For It</h2>
  <span>We are committed to making your Medicare experience great. And our members appreciate it. 
    </span>

  <div class="testimonial-outside-box-wrapper row justify-content-between mx-auto">
    <div class="testimonial-outside-box">
      <div class="testimonial-box custom-box">
        <i class="fa fa-quote-left"></i>
        <p>
          "I was paying nearly $200 every month for my Medicare supplement insurance. I liked my coverage but the price had gotten out of control. Thankfully I got connected with TapTap Medicare and one of their counselors and they recommended switching to Plan G. I got my rate down $80 per month which let me breath! I am very thankful that the TapTap Medicare team helped me when I needed it!"
        </p>
      </div>
      <div class="testimonial-author-section">
        <div class="testimonial-author-pointer"></div>
        <div class="testimonial-author-img">
          <img src="assets/img/common/person-icon.png">
        </div>
        <div class="testimonial-author-name d-flex flex-column">
          <span>Elaine Whittaker, Age 73</span>
          <span>Plan F to Plan G, Saved $936 a Year</span>
        </div>
      </div>
    </div>
    <div class="testimonial-outside-box">
      <div class="testimonial-box custom-box">
        <i class="fa fa-quote-left"></i>
        <p>
          "I was so frustrated and stressed by the whole Medicare process before I met TapTap Medicare and experienced their way of helping people getting onto Medicare. I went from confused and overwhelmed to clear and comforted. Medicare actually started to make sense. I understood my options and I became clear and confident that I was choosing the right coverage setup for me, and I also knew why that coverage was right for me."
        </p>
      </div>
      <div class="testimonial-author-section">
        <div class="testimonial-author-pointer"></div>
        <div class="testimonial-author-img">
          <img src="assets/img/common/person-icon.png">
        </div>
        <div class="testimonial-author-name d-flex flex-column">
          <span>Julia Girardi, Age 65</span>
          <span>New to Medicare, Full Medicare Review</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center mt-5 mb-5">
      <button routerLink="/testimonials" class="btn btn-third">View More Testimonials</button>
    </div>
  </div>
</div>