import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs/index';
import {UtilityService} from '../utility.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
  providers: [UtilityService]
})
export class ArticlesComponent implements OnInit {

  articles: Observable<any>;

  constructor(private db: AngularFirestore, private utilityService: UtilityService) { }

  convertDateTime(dt): string{
      return this.utilityService.createReadableTimeDateStamp(dt);
  }

  convertBoolean(bool): string{
       return this.utilityService.evulateBoolean(bool);
  }

  ngOnInit() {



  }

}
