<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">
            <span>Your Medicare Organizer</span><br>         
            <!-- A Clear and Organized Healthcare Experience -->
            Clarity, Access, and Ease
					</h1>
					<p class="banner-sub-title">Stay Organized With The Information That Matters To You and Your Health</p>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">
				<img src="assets/img/medicare-services/old-woman-holding-eye-glasses.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<section class="medicare-organizer-content-wrapper main-content-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-healthcare-organizer.png" alt="My Healthcare Organizer">
        </div>
        <h6 routerLink="/benny-dash/my-healthcare-info"> My Healthcare Organizer </h6>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-coverage-preferences.png" alt="My Coverage Preferences">
        </div>
        <h6 routerLink="/part-b-enrollment-center"> My Coverage Preferences </h6>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-financial-assistance-eligibility.png" alt="My Financial Assistance Eligibility">
        </div>
        <h6 routerLink="/benny-dash/my-money/my-opportunities"> My Financial Assistance Eligibility </h6>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-providers-network-status.png" alt="My Providers Network Status">
        </div>
        <a href="/insurance-marketplace/medicare-advantage/providers/H3952/020/000/2022/19102" class="direct-link"><h6>My Doctors & Providers</h6></a>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mo-box">
          <div class="mo-box-icon" routerLink="/login">
              <img src="./assets/img/medicare-organizer/mo_my-coverage-and-enrollment-profiles.png" alt="My Coverage & Enrollment Profiles">
          </div>
          <h6 routerLink="/part-b-enrollment-center"> My Enrollment Periods </h6>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-wellness-and-fitness-programs.png" alt="My Wellness & Fitness Programs">
        </div>
        <h6 routerLink="/benny-dash/my-healthcare-info/my-wellness"> My Wellness & Fitness Programs </h6>
      </div>

      <!-- <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-plan-availability.png" alt="My Plan Availability">
        </div>
        <h6 routerLink="/login"> My Plan Availability </h6>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-coverage-explainer.png" alt="My Coverage Explainer">
        </div>
        <h6 routerLink="/login"> My Coverage Explainer </h6>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mo-box">
        <div class="mo-box-icon" routerLink="/login">
          <img src="./assets/img/medicare-organizer/mo_my-opportunities.png" alt="My Opportunities">
        </div>
        <h6 routerLink="/login"> My Opportunities </h6>
      </div> -->

    </div>
  </div>
</section>
<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>