import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-pet-insurance-form',
  templateUrl: './pet-insurance-form.component.html',
  styleUrls: ['./pet-insurance-form.component.scss']
})
export class PetInsuranceFormComponent implements OnInit {

  taptapRecaptcha = new FormControl(false);

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  onInquire() {
    // this._api.callAPIEndpoint('/api/v1/inquiry', {
    //     'first_name': this.controls.first_name.value,
    //     'last_name': this.controls.last_name.value,
    //     'email': this.controls.email.value,
    //     'phone': this.controls.phone.value,
    //     'what_can_we_help_for_you': this.controls.what_can_we_help_for_you.value,
    //     'how_did_you_hear_about_us': this.controls.how_did_you_hear_about_us.value,
    //     'message': this.controls.message.value,
    // })
    //     .subscribe(
    //         (data: any) => {
    //             this.emptyForm();
    //             this.alertMessage();
    //         },
    //         (err) => {
    //             this.emptyForm();
    //         }
    //     );
  }

  constructor() { }

  ngOnInit() {
  }

  controls: any = {
    first_name: new FormControl('', [
        Validators.required,
    ]),
    last_name: new FormControl('', [
        Validators.required,
    ]),
    email: new FormControl('', [
        Validators.required,
    ]),
    phone: new FormControl('', [
        Validators.required,
    ]),
    message: new FormControl('', [
        Validators.required,
    ]),
  }

}
