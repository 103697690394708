import {Component, OnInit, ElementRef, ViewChild, Inject, Optional } from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {APIService} from '../services/api.service';
import {Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {Title} from "@angular/platform-browser";

export interface DialogData {
    email: string;
    name: string;
    isModal: boolean;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    submitIsClicked: boolean = false;
    isValidCredentials: boolean = true;
    showLoading: boolean = false;
    isWrongLogin: boolean = false;
    isSignInUsingExternalParty: boolean = false;
    externalParty: string;
    existingUserMessage: string = null;

    onScriptLoad() {
        console.log('Google reCAPTCHA loaded and is ready for use!')
    }
 
    onScriptError() {
        console.log('Something went long when loading the Google reCAPTCHA')
    }

    private baseUrl = window.location.origin;

    form: any;

    loginForm = new FormGroup({
        email: new FormControl('', [
            Validators.required,
        ]),
        password: new FormControl('', [
            Validators.required,
        ]),

        taptapRecaptcha: new FormControl(false),
        // captcha: new FormControl('', [
        //     Validators.requiredTrue,
        // ]),
    });

    get email() { return this.loginForm.get('email'); }
    get password() { return this.loginForm.get('password'); }
    get taptapRecaptcha() { return this.loginForm.get('taptapRecaptcha'); }
    // get captcha() { return this.loginForm.get('captcha'); }

    currentUser: any;

    constructor(private _api: APIService, 
        private _router: Router, 
        private _authService: AuthService, 
        @Optional() public dialogRef: MatDialogRef<LoginComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: DialogData, private titleService:Title) {
            if (dialogData && dialogData.email !== undefined && dialogData.email !== null && dialogData.email) {
                this.loginForm.controls['email'].setValue(dialogData.email);
            }
            this.titleService.setTitle("Login");
    }

    // @ViewChild('recaptcha') recaptchaElement: ElementRef;

    ngOnInit() {
        // this.addRecaptchaScript();
    }

    saveToCookies():void{
        let d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        let expires = d.toUTCString();
        document.cookie = `bp_user_id=${this.currentUser.id};expires=${expires};path=${this.baseUrl};`;
        document.cookie = `bp_user_name=${this.currentUser.name};expires=${expires};path=${this.baseUrl};`
        document.cookie = `bp_user_picture=${this.currentUser.picture};expires=${expires};path=${this.baseUrl};`;
        document.cookie = `bp_user_email=${this.currentUser.email};expires=${expires};path=${this.baseUrl};`;
        document.cookie = `bp_user_accounttype=${this.currentUser.accountType};expires=${expires};path=${this.baseUrl};`;
    }

    closeModal() {
        if (this.dialogRef) {
            this.dialogRef.close(false);
        }
    }

    async loginUsingExternalParty(externalParty: string): Promise<any> {
        this.submitIsClicked = true;
        this.isValidCredentials = true;
        this.isSignInUsingExternalParty = true;
        this.externalParty = externalParty;
        this.existingUserMessage = null;
        this.isWrongLogin = false;
        
        //this.showLoading = true;
        try {
            const result = await this._authService.signInUsingExternalParty(externalParty);
            if(result){
                if(result.exists) {
                    this.showLoading = false;
                    this.isValidCredentials = false;
                    this.isWrongLogin = true;
                    this.existingUserMessage = result.message;
                } else {
                    const { email, password } = result;
                    this._authService.signUserInOnServer({
                        'email': email,
                        'password': password,
                    }).subscribe(
                        (details: any) => {
                            if (details.code === 1) {
                                this._authService.setActiveUserUsingJSON(details.details, externalParty);

                                if (this.dialogRef) {
                                    this.dialogRef.close(true);
                                } else {
                                    this.isWrongLogin = false;
                                    window.location.href = '/benny-dash';
                                }
                            }
                        },

                        (response) => {
                            if(response.error){
                                this.showLoading = false;
                                this.isValidCredentials = false;
                                this.isWrongLogin = true;
                            }
                        }
                    );
                }
            }
        } catch(error) {
            if(error.code === 'auth/account-exists-with-different-credential') {
                const userData = await this._authService.checkUserExists(error.email);
                if(userData && userData.length > 0) {
                    if(userData[0].external_party !== null) {
                        this.existingUserMessage = `You are already registered to our system using ${ userData[0].external_party }, with the email of ${ error.email }. Kindly login using that account.`;
                    }
                } else {
                    this.existingUserMessage = error.message;
                }
            }

            if (this.dialogRef) {
                this.dialogRef.close(true);
            }

            if(error.code !== 'auth/cancelled-popup-request') {
                this.showLoading = false;
                this.isValidCredentials = false;
                this.isWrongLogin = true;
            }
        }
    }

    login() {
        this.submitIsClicked = true;
        this.isValidCredentials = true;
        this.isSignInUsingExternalParty = false;
        
        if (this.loginForm.valid) {
            this.showLoading = true;
            this._authService.signUserInOnServer({
                'email': this.loginForm.controls.email.value,
                'password': this.loginForm.controls.password.value,
            }).subscribe(
                (data: any) => {
                    if (data.code === 1) {
                        this.showLoading = false;
                        this._authService.setActiveUserUsingJSON(data.details);
                       
                        if (this.dialogRef) {
                            this.dialogRef.close(true);
                        } else {
                            window.location.href = '/benny-dash';
                        }
                    }

                },

                (response) => {
                    if(response.error){
                        this.showLoading = false;
                        this.isValidCredentials = false;
                    }
                }
            )


            /*this._api.callAPIEndpoint('/api/v1/member-login', {
                'email': this.loginForm.controls.email.value,
                'password': this.loginForm.controls.password.value,
            })
                .subscribe(
                    (result: any) => {
                        if (result) {
                            let data = result.success;
                            this.currentUser = {
                                id: data.user.id,
                                name: `${data.user.first_name} ${data.user.last_name}`,
                                picture: '',
                                email: data.user.email,
                                accountType: 'BP',
                                token: data.token
                            };
                            localStorage.setItem('user', JSON.stringify(this.currentUser));
                            this.saveToCookies();
                            this._router.navigate(['/retiree-dashboard/my-general-info']);
                            console.log(data);
                        } else {
                            console.log('no results found');
                        }
                    },
                    (err) => {
                        this.isValidCredentials = false;
                        //console.log('no results found');
                    }
                );*/
        }



        /*console.log(this.controls.email.status);
        console.log(this.controls.password.status);*/

        /*this._api.callAPIEndpoint('/api/v1/login', {
            'email': this.controls.email.value,
            'password': this.controls.password.value,
        })
            .subscribe(
                (data: any) => {
                    if (data) {
                        this._router.navigate(['./']);
                        console.log(data);
                    } else {
                        console.log('no results found');
                    }
                },
                (err) => {
                    console.log('no results found');
                }
            );*/
    }


    // renderReCaptch() {
    //     window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
    //         //'sitekey': '6Lfp86kUAAAAAE8aPoqkw4mfXOPgCCbLkGdFvQ8Q',
    //         'sitekey': '6LfYbNYUAAAAADJgUKeiBbQRZf03bRx-rjQLA1FA',
    //         'callback': (response) => {
    //             this.loginForm.controls['captcha'].setValue(true);
    //         }
    //     });
    // }

    // addRecaptchaScript() {

    //     window['grecaptchaCallback'] = () => {
    //         this.renderReCaptch();
    //     }

    //     (function (d, s, id, obj) {
    //         var js, fjs = d.getElementsByTagName(s)[0];
    //         if (d.getElementById(id)) {
    //             obj.renderReCaptch();
    //             return;
    //         }
    //         js = d.createElement(s);
    //         js.id = id;
    //         js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
    //         fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'recaptcha-jssdk', this));
    // }

}
