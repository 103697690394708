<div class="modal-popupWrapper modal-apply modal-applyOnline">
    <ng-container *ngIf="isShowDefaultView">
        <div class="row">
            <div class="col-12 bg-primary modal-header">
                <h2 class="mb-1 text-center px-4 fColor-white">TapTap Enroll Online</h2>
                <h5 class="fWeight-500 fColor-white text-center fColor-white">Below Is A Summary For Your Convenience:</h5>
            </div>
        </div>
        <div class="row px-5 pt-2 pb-5">
            <div class="col-md-8 d-flex modal-RightWrapper">
                <div class="modal-applyBox">
                    <div class="boxApply mt-5">
                        <img onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';" src="{{ logo }}"
                            class="modal-companyLogo">
                        
                        <h5 class="mt-2 text-center uppercase" *ngIf="insuranceType == 'Medicare Supplement Plan' && (plan == 'Core Plan' || plan == 'Plan 1' || plan == 'Plan 1A' || plan == 'All Plans')"><span [innerHTML]="insuranceType.replace('Plan', '')" class="span-insurance-type"></span> {{ plan }}</h5>
                        <h5 class="mt-2 text-center uppercase" *ngIf="insuranceType == 'Medicare Supplement Plan' && !(plan == 'Core Plan' || plan == 'Plan 1' || plan == 'Plan 1A' || plan == 'All Plans')">{{ insuranceType }} {{ plan }}</h5>
                        <h5 class="mt-2 text-center uppercase" *ngIf="insuranceType != 'Medicare Supplement Plan'">{{ plan }}</h5>
    
                        <app-star-rating [rating]="overallStarRating" size="large"></app-star-rating>

                        <div class="d-flex flex-column align-items-center">
                            <ng-container *ngIf="planPremium" >
                                <h3 class="fColor-orange text-center">{{ planPremium }}</h3>
                                <h6 class="fColor-black text-center uppercase mb-4">{{ planPremiumLabel }}</h6>
                            </ng-container>
                            <ng-container *ngIf="insuranceType == 'Medicare Advantage Plan'">
                                <h3 class="fColor-orange text-center" *ngIf="oneTimeEnrollmentFee">{{ oneTimeEnrollmentFee }}</h3>
                                <h3 class="fColor-orange text-center" *ngIf="!oneTimeEnrollmentFee">$0.00</h3>
                                <h6 class="fColor-black text-center uppercase">TapTap Enrollment Fee:</h6>
                            </ng-container>
                            <ng-container *ngIf="insuranceType == 'Medicare Supplement Plan'">
                                <h3 class="fColor-orange text-center" *ngIf="oneTimeEnrollmentFee != '' &&  insuranceType != 'Medicare Advantage Plan'">{{ oneTimeEnrollmentFee }}</h3>
                                <h3 class="fColor-orange text-center" *ngIf="oneTimeEnrollmentFee == 0 || oneTimeEnrollmentFee == '' || insuranceType == 'Medicare Advantage Plan'">$0.00</h3>
                                <h6 class="fColor-black text-center uppercase">One-Time Enrollment Fee:</h6>
                            </ng-container>
                            <ng-container *ngIf="insuranceType == 'Medicare Supplement Plan'">
                                <h3 class="fColor-orange text-center" *ngIf="householdDiscount && householdDiscount != 0">{{ householdDiscount | currency }}</h3>
                                <h3 class="fColor-orange text-center" *ngIf="!householdDiscount || householdDiscount == 0 || householdDiscount == ''">$0.00</h3>
                                <h6 class="fColor-black text-center uppercase">Household Discount Included:</h6>
                            </ng-container>
                        </div>

                        <button class="btn btn-first mx-auto mt-4" (click)="showFirstPage()">Next</button>

                        <!--<table class="mt-3">
                            <tr>
                                <td>Plan Premium:</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="planPremium">{{ planPremium }}</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="!planPremium">$0.00</td>
                            </tr>
                            <tr *ngIf="insuranceType == 'Medicare Advantage Plan'">
                                <td>TapTap Enrollment Fee:</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="oneTimeEnrollmentFee">{{ oneTimeEnrollmentFee }}</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="!oneTimeEnrollmentFee">$0.00</td>
                            </tr>
                            <tr *ngIf="insuranceType == 'Medicare Supplement Plan'"> <!-SHOW ONLY IF MEDIGAP ->
                                <td>One-Time Enrollment Fee:</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="oneTimeEnrollmentFee != '' &&  insuranceType != 'Medicare Advantage Plan'">{{ oneTimeEnrollmentFee }}</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="oneTimeEnrollmentFee == 0 || oneTimeEnrollmentFee == '' || insuranceType == 'Medicare Advantage Plan'">$0.00</td>
                            </tr>
                            <tr *ngIf="insuranceType == 'Medicare Supplement Plan'"> <!-SHOW ONLY IF MEDIGAP ->
                                <td>Household Discount Included:</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="householdDiscount && householdDiscount != 0">{{ householdDiscount }}</td>
                                <td class="fWeight-600 fColor-blue" *ngIf="!householdDiscount || householdDiscount == 0 || householdDiscount == ''">$0.00</td>
                            </tr>
                        </table>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 modal-LeftImgWrapper">
                <img src="assets/img/common/old-woman-with-basket.png" alt="" *ngIf="insuranceType != 'Medicare Supplement Plan' && insuranceType != 'Medicare Part D Drug Plan' && insuranceType.indexOf('Life Insurance') == -1 && insuranceType != 'Medicare Advantage Plan' && insuranceType != 'Dental'">
                <img src="assets/img/common/old-woman-with-basket-medigap.png" alt="" *ngIf="insuranceType == 'Medicare Supplement Plan'">
                <img src="assets/img/common/old-woman-with-basket-mpdp.png" alt="" *ngIf="insuranceType == 'Medicare Part D Drug Plan'">
                <img src="assets/img/common/old-woman-with-basket-life-insurance.png" alt="" *ngIf="insuranceType.indexOf('Life Insurance') > -1">
                <img src="assets/img/common/old-woman-with-basket-mapd.png" alt="" *ngIf="insuranceType == 'Medicare Advantage Plan'">
                <img src="assets/img/common/old-woman-with-basket-dental.png" alt="" *ngIf="insuranceType == 'Dental'">
            </div>
        </div>        
    </ng-container>    

    <div *ngIf="isShowModalPage1">
        <app-important-information (emit_ShowAcknowledgement)="showSecondPage()" (emit_ImportantInformationDate)="showEnrollOnlineModal()" (emit_showDefaultView)="showDefaultPage()" [view1]="isShowImportantInformation1stView" [view2]="isShowImportantInformation2ndView"></app-important-information>
    </div>

    <div *ngIf="isShowModalPage2">
        <app-acknowledgement (emit_isAgree)="onCheck_acknowledgementIAgree($event)"></app-acknowledgement>
        <div class="my-4 d-flex justify-content-center flex-column flex-sm-row align-items-center">
            <button class="btn btn-third btn-back mr-0 mr-sm-3 mb-2 mb-sm-0" (click)="showImportantInformationLastPage()">
                Back
            </button>
            <!-- make the param after insurance-marketplace dynamic -->
            <button *ngIf="isShowProceedToOnlineApplication" class="btn btn-first" (click)="onClick_ProceedToOnlineApplication()" routerLink="/{{parentUrl}}/{{subUrl}}/apply-online" [queryParams]="{
                  plan:controlPlan,
                  firstName:firstName,
                  middleName:middleName,
                  lastName:lastName,
                  age:controlAge,
                  gender:controlGender,
                  zipcode:controlZipcode,
                  tobacco:controlTobacco,
                  household:controlHousehold,
                  naic:controlNaic,
                  premium:controlPremium,
                  insuranceType:insuranceType,
                  pdsCode:pdsCode,
                  csgKey:csgKey,
                  type:controlType,
                  carrierLogo:controlCarrierLogo,
                  carrierBrandName:controlCarrierBrandName,
                  planLetter:controlPlanLetter,
                  productType:controlProductType,
                  linkToSinglePlanPage: controlLinkToSinglePlanPage,
                  maxOutOfPocket: controlMaxOutOfPocket,
                  contractId: controlContractId,
                  planDeductible: controlPlanDeductible,
                  planId: controlPlanId,
                  annualMax: controlAnnualMax,
                  deathBenefit: controlDeathBenefit
                }">Proceed to Online Application
            </button>
        </div>
    </div>
</div>
