import {Component, OnInit, HostListener, Inject, ViewChild, ElementRef} from '@angular/core';
import {HeaderService} from '../../services/header.service';
import Swal from "sweetalert2";
import {AuthService} from '../../services/auth.service';
import { BennyDashService } from '../../benny-dash/benny-dash.service';
import { Router } from "@angular/router";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    u:any;
    isLoggedInUser:any;
    dblocking = 'd-none';
    auth_token: any;
    fullPrimaryHeader:boolean = true;
    navBarNav_show: boolean = false;
    isLoggedIn:boolean = false;
    mobileToggleState: boolean;
    userDetail:any;

    showNotifications:any = false;

    @ViewChild('toggleButtonNavbarNav') toggleButton: ElementRef;

    constructor(public header: HeaderService, public authLogin: AuthService, private benny: BennyDashService, private _router: Router) {
        
    }
    
    ngOnInit() {
        if(this.authLogin.isAuthenticated()){ // check if user is logged in
            this.isLoggedIn = true;
            this.getUserDetails();
          }else{
            this.isLoggedIn = false;
          }

        this.benny.mobileSidebarCurrentToggleState.subscribe( state => this.mobileToggleState = state );  
          
       
        
    }


    async getUserDetails(){
        this.userDetail = await this.authLogin.getUserDetails();
        console.log('this.userDetail', this.userDetail);
    }

    toggleNavbarNav(){
        this.toggleButton.nativeElement.click();
    }

    public isSticky:boolean = false;
    public headerClass:string = "";
    public isScrolled = false;
    @HostListener('window:scroll', [])
    
    onWindowScroll() {
        var number = window.pageYOffset;
        if (number > 0) {
            this.isSticky = true;
            this.headerClass="stickyNavBarWrapper";
        } else {
            this.isSticky = false;
            this.headerClass="111111";
        }
    }

    speakToCounselorEvent(modalViews: any) {
        this.dblocking = 'd-block';
        Swal.fire({
            html: modalViews,
            showConfirmButton: false,
            showCloseButton: true,      
            width: 'auto',
            padding: '0',
            customClass: {
              popup: 'speak-to-counselor-popup-box',
              closeButton: 'speak-to-counselor-popup-box-close-btn'
            },
            onClose: () => {
                this.dblocking = 'd-none';
            }
        });  
    }

    onToggleBennyMobileState(state){
        this.benny.on_ToggleMobileSidebar(state);
    }

    toggleNotifs() {
        this.showNotifications = !this.showNotifications;
    }

    showModal(content) {
        Swal.fire({
            html: content,
            showConfirmButton: false,
            showCloseButton: true,      
            width: 500,
            padding: '0px',
        });  
    }

    shareWithEmail(content) {
        Swal.fire({
            html: content,
            showConfirmButton: false,
            showCloseButton: true,      
            width: 600,
            padding: '0px',
        });  
    }

    /**
     * Redirects to Insurance Marketplace Page and opens tab content.
     * @type { void }
     * @param { string } 'coverage types and tab content ID'
     */
    loadDOM(coverageType: string, tabContent: string):void {
        this._router.navigate(['/insurance-marketplace']);
        setTimeout(() => {
            this.toggleNavbarWithTarget(coverageType, tabContent)
        }, 1000)
    }

    /**
     * Change active classes
     */
    toggleNavbarWithTarget(coverageType: string, tabContent: string):void {
        const tabActiveLink = $(`#${coverageType}`);
        const tabActiveContentLink = $(`#${tabContent}`);
        const tabLinkContainer = $("#nav-tab").children();
        const tabContentId = $("#nav-tabContent").children();
        $('html, body').animate({
            scrollTop: $(`#${coverageType}`).offset().top
        }, 300)

        switch (coverageType) {
            case "nav-medicare-tab":
                tabLinkContainer.each((index, item) => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                });
                tabActiveLink.addClass('active');
                tabContentId.each((index, item) => {
                    if (item.classList.contains('show')) {
                        item.classList.remove('active');
                        item.classList.remove('show');
                    }
                });
                tabActiveContentLink.addClass('show active');
                break;
            case "nav-supporting-tab":
                tabLinkContainer.each((index, item) => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                })
                tabActiveLink.addClass('active');
                tabContentId.each((index, item) => {
                    if (item.classList.contains('show')) {
                        item.classList.remove('active');
                        item.classList.remove('show');
                    }
                });
                tabActiveContentLink.addClass('show active');
                break;
            case "nav-personal-tab":
                tabLinkContainer.each((index, item) => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                })
                tabActiveLink.addClass('active');
                tabContentId.each((index, item) => {
                    if (item.classList.contains('show')) {
                        item.classList.remove('active');
                        item.classList.remove('show');
                    }
                });
                tabActiveContentLink.addClass('show active');
                break;
            case "nav-telehealth-tab": // proactive health
                tabLinkContainer.each((index, item) => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                })
                tabActiveLink.addClass('active');
                tabContentId.each((index, item) => {
                    if (item.classList.contains('show')) {
                        item.classList.remove('active');
                        item.classList.remove('show');
                    }
                });
                tabActiveContentLink.addClass('show active');
                break;
        }
    }
}
