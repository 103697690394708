import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-out-of-pocket',
  templateUrl: './out-of-pocket.component.html',
  styleUrls: ['./out-of-pocket.component.scss']
})
export class OutOfPocketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
