import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import Quill from 'quill';

@Component({
  selector: 'app-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss']
})
export class WysiwygComponent implements AfterViewInit {

  toolbarOptions = [
    [{ 'size': ['small', false, 'large', 'huge'] }],  // text sizes
    [{ 'color': [] }],                                // dropdown with defaults from theme
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'align': [] }],                                // text align
    [{ 'list': 'ordered'}, { 'list': 'bullet' }]      // list/bullet
  ];
  
  editorOptions = {
    placeholder: 'Start typing your notes here...',
    theme: 'snow',
    modules: {
      toolbar: this.toolbarOptions,
    }
  };

  noToolbar = {
    theme: "snow",
    readOnly: true,
    modules: {
        toolbar: false,
    }
  };
  
  notesSelectedIndex:any;
  selectedList:any = null;

  notesCreator: any;
  notesEditor: any;

  @Input() notesName: string;
  @Input() notesArray: Array<string> = [];
  notes: any;
  notes_edit:any;

  noteSaving: boolean = false;
  noteDeleting: boolean = false;
  noteEditing: boolean = false;

  noteSaved: boolean = false;
  noteDeleted: boolean = false;
  noteEdited: boolean = false;

  @Output() saveNotes = new EventEmitter<any>();
  @Output() editNotes = new EventEmitter<any>();
  @Output() deleteNotes = new EventEmitter<any>();

  noteCreate: any;
  noteEdit: any;

  @Input() universalMode: boolean = false;
  
  createNoteModal: boolean = false;
  editNoteModal: boolean = false;

  @Output() createNoteEmitter = new EventEmitter<any>();
  @Output() editNoteEmitter = new EventEmitter<any>();

  notesLabelField: any = null;
  notesLabelEditField: any = null;
  notesModalLoading: boolean = false;
  notesModalError: boolean = false;
  @Input() receivedNotesForEditing: any;

  constructor() { }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    if(!this.universalMode) {
      this.notesCreator = new Quill('#notesCreator', this.editorOptions);
      this.notesEditor = new Quill('#notesEditor', this.editorOptions);
    }
  }

  editExistingNote(index) {
    if(this.notesEditor == null) {
      this.notesEditor = new Quill('#notesEditor', this.editorOptions);
    }
    this.notesSelectedIndex = index;
    let contents = this.notesArray[index].replace(/(^"|"$)/g, '');
    this.notesEditor.setContents(JSON.parse(contents));
  }

  displayExistingNotes() {
    for(let i = 0; i < this.notesArray.length; i++) {
      const fetch = document.getElementById('editorView_' + i);
      if(fetch && (fetch.classList.length == 0)) {
        let contents = this.notesArray[i].replace(/(^")|("$)/g, '');
        new Quill('#editorView_' + i, this.noToolbar).setContents(JSON.parse(contents));
      }
    }
  }

  saveNote() {
    this.notes = this.notesCreator.getContents();
    this.notesArray.push('"' + JSON.stringify(this.notes) + '"');
    const outputArray = this.notesArray.toString();
    this.saveNotes.emit(outputArray);
  }

  editNote() {
    this.notes_edit = this.notesEditor.getContents();
    this.notesArray[this.notesSelectedIndex] = '"' + JSON.stringify(this.notes_edit) + '"';
    const outputArray = this.notesArray.toString();
    this.editNotes.emit(outputArray);
  }

  deleteNote(index) {
    this.notesArray.splice(index, 1);
    const outputArray = this.notesArray.toString();
    this.deleteNotes.emit(outputArray);
  }

  showSavedPrompt() {
    this.noteSaved = true;
  }

  showDeletedPrompt() {
    this.noteDeleted = true;
  }
  
  showEditedPrompt() {
    this.noteEdited = true;
  }

  closePrompt() {
    this.noteSaved = false;
    this.noteDeleted = false;
    this.noteEdited = false;
  }

  initCreateNoteModal() {
    this.createNoteModal = true;
    setTimeout(() => {
      if(this.noteCreate == null) {
        this.noteCreate = new Quill('#createNoteModal', this.editorOptions);
      }
    }, 0)
    
  }

  initEditNoteModal(notes) {
    this.editNoteModal = true;
    this.notesLabelEditField = notes.notes_label;
    const contents = JSON.parse(notes.notes_content);
    setTimeout(() => {
      if(this.noteEdit == null) {
        this.noteEdit = new Quill('#editNoteModal', this.editorOptions);
      }
      this.noteEdit.setContents(contents.ops);
    }, 0)
  }


  
  createNewNotes() {
    this.notesModalLoading = true;
    let notesData = {
      'notes_label': this.notesLabelField,
      'notes_content': this.noteCreate.getContents()
    }
    this.createNoteEmitter.emit(notesData);
  }
  
  editExistingNotes() {
    this.notesModalLoading = true;
    let notesData = {
      'notes_label': this.notesLabelEditField,
      'notes_content': this.noteEdit.getContents()
    }
    this.editNoteEmitter.emit(notesData);
  }


  
  createNewNotesSuccess() {
    setTimeout(() => {
      this.createNoteModal = false;
    }, 500);
    this.notesModalLoading = false;
    this.notesModalError = false;
    this.notesLabelField = null;
    this.noteCreate.setContents([{ insert: '\n' }]);
  }

  editNotesSuccess() {
    this.notesModalLoading = false;
    this.noteEdited = true;
  }
  
}
