<!-------------------------------------------------------------------------------------------------------------
----------------------------------------------PRIMARY HEADER---------------------------------------------------
--------------------------------------------------------------------------------------------------------------->
<div style="height: 100px;"></div>
<header id="header-primary" [ngClass]="{'loginPageHeader':header.fullHeaderContent ,'stickyNavBarWrapper':isSticky, 'd-block':fullPrimaryHeader, 'd-none' :!fullPrimaryHeader }" *ngIf="!header.isRetireeDashboard">
    <div class="block w-100 border-bottom p-3 announcment-bar">
        <svg class="bg-stripes" data-name="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390.25 373.75" preserveAspectRatio="none"><defs></defs><title>cbimage</title><path style="fill:#eff7ff;" class="wave-path" d="M0,0H123.21S78.08,119.5,224.35,129.28c0,0,48-2,48.64,52.07,0,2.78.14,12.52.14,12.52-.46,10.93-4.46,41.88,2.63,70.54,15,60.8,93.83,100.38,114.25,109.27.31.14.34,0,0,0H0Z" transform="translate(0 0)"></path></svg>

        <div class="content-wrap container d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <div class="d-flex flex-column flex-md-row align-items-center">
                <img src="/assets/img/megaphone.png" alt="" class="animate__animated animate__infinite animate__tada megaphone mr-3">
                <div class="text-center text-md-left mt-3 mt-md-0">
                    <p class="ann-heading mb-1 font-poppins">We have migrated to a new and improved platform, <span class="font-poppins">TapTapHealth</span>!</p>
                    <p class="ann-sub-heading mb-0 font-poppins">Simply click on the button to visit our new website.</p>
                </div>
            </div>
            <a href="https://taptaphealth.com/" class="link-button d-flex align-items-center px-3 py-2 mt-3 mt-lg-0 animate__animated animate__infinite animate__pulse font-poppins font-weight-bold">
                Go to TapTapHealth
                <i class="fas fa-angle-double-right ml-2"></i>
            </a>
        </div>
    </div>
    <div class="container top-header-section">
        <div class="row">
            <nav class="navbar navbar-expand-lg navbar-light col-12 custom-navbar">
                <div class="hidden-left-mobile-nav-div d-lg-none below-300px">&nbsp;</div>
                <img src="../../../assets/img/common/taptap-logo-colored.svg" width="100" class="company-logo mobile-navbar-logo" alt="">
                <button class="navbar-toggler" type="button" #toggleButtonNavbarNav data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbarNav()">
                    <span class="navbar-toggler-icon">
                        <i class="fas fa-bars color-primary" style="font-size:28px;"></i>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav" #navbarNav>
                    <ul class="navbar-nav header-menu row col-12 mx-0 d-flex align-items-center">
                        <li class="nav-item hideOnTablet" *ngIf="isSticky || header.fullHeaderContent">
                            <a class="navbar-brand" href="#" routerLink="/home" style="padding-top:0;">
                                <img src="../../../assets/img/common/taptap-logo-colored.svg" width="300"
                                    class="company-logo d-inline-block" alt="">
                            </a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="!header.fullHeaderContent" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" routerLink="/education" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Learn
                            </a>
                            <div class="dropdown-menu text-center" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item" routerLink="/education/hub/videos" routerLinkActive="active" (click)="toggleNavbarNav()">Videos</a>
                              <a class="dropdown-item" routerLink="/education/hub/infographics" routerLinkActive="active" (click)="toggleNavbarNav()">Courses</a>
                              <a class="dropdown-item" routerLink="/education/hub/articles" routerLinkActive="active" (click)="toggleNavbarNav()">Articles</a>
                              <a class="dropdown-item" routerLink="/education/hub/guides" routerLinkActive="active" (click)="toggleNavbarNav()">Guides</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown" *ngIf="!header.fullHeaderContent" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" routerLink="/insurance-marketplace" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Marketplace
                            </a>
                            <div class="dropdown-menu text-center" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item" routerLinkActive="active" (click)="loadDOM('nav-medicare-tab', 'nav-medicare')">Medicare Coverages</a>
                              <a class="dropdown-item" routerLinkActive="active" (click)="loadDOM('nav-supporting-tab', 'nav-supporting')">Supporting Coverages</a>
                              <a class="dropdown-item" routerLinkActive="active" (click)="loadDOM('nav-personal-tab', 'nav-personal')">Personal Coverages</a>
                              <a class="dropdown-item" routerLinkActive="active" (click)="loadDOM('nav-telehealth-tab', 'nav-telehealth')">Proactive Health</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown" *ngIf="!header.fullHeaderContent" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" routerLink="/organizer" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Get Organized
                            </a>
                            <div class="dropdown-menu text-center" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item" routerLink="/benny-dash/my-healthcare-info/my-providers" routerLinkActive="active" (click)="toggleNavbarNav()">My Doctors & Providers</a>
                              <a class="dropdown-item" routerLink="/benny-dash/my-healthcare-info/my-drugs" routerLinkActive="active" (click)="toggleNavbarNav()">My Drugs & Pharmacies</a>
                              <a class="dropdown-item" routerLink="/benny-dash/my-coverage/my-coverage-preferences" routerLinkActive="active" (click)="toggleNavbarNav()">My Coverage Preferences</a>
                              <a class="dropdown-item" routerLink="/benny-dash/my-money" routerLinkActive="active" (click)="toggleNavbarNav()">My Financial Assistance Programs</a>
                              <a class="dropdown-item" routerLink="/benny-dash/my-healthcare-info/my-wellness" routerLinkActive="active" (click)="toggleNavbarNav()">My Wellness Programs & Benefits</a>
                            </div>
                        </li>
                        <li class="nav-item divider onDesktop" *ngIf="!header.fullHeaderContent">
                            <mat-divider [vertical]="true"></mat-divider>
                        </li>
                        <li class="nav-item dropdown" *ngIf="!header.fullHeaderContent" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                New to Medicare?
                            </a>
                            <div class="dropdown-menu text-center" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item" routerLink="/part-b-enrollment-center" routerLinkActive="active" (click)="toggleNavbarNav()">I Just Got Onto Medicare</a>
                              <a class="dropdown-item" routerLink="/i-am-new-to-medicare" routerLinkActive="active" (click)="toggleNavbarNav()">I Am Turning 65 | Where To Begin</a>
                              <a class="dropdown-item" routerLink="/part-b-enrollment-center" routerLinkActive="active" (click)="toggleNavbarNav()">Part A & B Enrollment</a>
                            </div>
                        </li>
                        <li class="nav-item" *ngIf="header.fullHeaderContent">
                            <a routerLink="/part-b-enrollment-center" routerLinkActive="active" (click)="toggleNavbarNav()">Part B Enrollment</a>
                        </li>
                        <li class="nav-item divider onDesktop" *ngIf="!header.fullHeaderContent">
                            <mat-divider [vertical]="true"></mat-divider>
                        </li>
                        <li class="nav-item dropdown" *ngIf="!header.fullHeaderContent" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Our Services
                            </a>
                            <div class="dropdown-menu text-center" aria-labelledby="navbarDropdown">                                
                                <a class="dropdown-item" routerLink="/medicare-services" routerLinkActive="active" (click)="toggleNavbarNav()">View All Services</a>
                                <a class="dropdown-item" routerLink="/contact" routerLinkActive="active" (click)="toggleNavbarNav()">Contact Us</a>
                                <a class="dropdown-item" routerLink="/community" routerLinkActive="active" (click)="toggleNavbarNav()"> Consumer Communities</a>
                                <a class="dropdown-item" routerLink="/login" routerLinkActive="active" (click)="toggleNavbarNav()">Login</a>
                            </div>
                        </li>
                        <li class="nav-item mobileOnly" *ngIf="isLoggedIn">
                            <a routerLink="/beneciary" routerLinkActive="active" (click)="toggleNavbarNav()">Beneficiary Dashboard</a>
                        </li>
                        <li class="nav-item mobileOnly" *ngIf="isLoggedIn">
                            <a routerLink="/logout" (click)="toggleNavbarNav()">Logout</a>
                        </li>
                        <li class="nav-item" *ngIf="isSticky || header.fullHeaderContent">
                            <button class="btn btn-first header-speak-with-counselor"
                                (click)="speakToCounselorEvent(speakToCounselorModal)">Speak With a Concierge
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>


<!-------------------------------------------------------------------------------------------------------------
------------------------------------------- Beneficiary Dashboard  --------------------------------------------
--------------------------------------------------------------------------------------------------------------->
<header *ngIf="header.isRetireeDashboard" id="header-beneficiary" [class.header-fixed]="header.isBennyDashHeaderFixed">
    <div class="retiree-top-header-section">
        <button id="bennyDashMobileMenuToggleBbtn" (click)="onToggleBennyMobileState(!mobileToggleState)"><i class="fas" [class.fa-bars]="!mobileToggleState" [class.fa-times]="mobileToggleState"></i></button>
        <a routerLink="/" *ngIf="!header.isNewBennyDash">
            <img src="assets/img/common/taptap-logo-colored.svg" 
                 alt="company-logo"
                 class="retiree-company-logo-header">
        </a>
        <a class="logo-button border rounded-pill px-3 py-1 my-1" routerLink="/benny-dash" *ngIf="header.isNewBennyDash">
            <img src="./assets/img/taptap-house-icon-v2.png" 
                 alt="company-logo"
                 class="retiree-company-logo-header-benny">
        </a>
        <div class="retiree-notification" *ngIf="!header.isNewBennyDash">
            <a routerLink="/beneficiary/my-dashboard"><img src="./assets/img/common/home-icon.png" alt="" class="retiree-notification-icon"></a>
            <div class="dropdown">
                <button class="btn-plain dropdown-toggle" type="button" id="retireeDashboardHeaderUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="retireeDashboardHeaderUserBadge">
                        <img src="./assets/img/common/user-icon.png" alt="" class="retiree-notification-user-icon">
                    </div>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="retireeDashboardHeaderUser">
                  <a routerLink="/logout" class="logout-btn">Logout</a>
                </div>
              </div>
        </div>
        <div class="d-flex align-items-center" *ngIf="header.isNewBennyDash">
            <button class="notifications-btn mr-3" (click)="toggleNotifs()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
                <span class="notif-num">3</span>
                <div class="notification-box shadow border" *ngIf="showNotifications">
                    <div>
                        <div class="notif-head d-flex justify-content-between align-items-center">
                            <h5>Notifications</h5>
                            <button class="notif-settings" routerLink="/benny-dash/settings/settings-notification">
                                <i class="fas fa-cog"></i>
                            </button>
                        </div>
                        <div>
                            <button class="notification d-flex">
                                <i class="fas fa-info-circle"></i>
                                <div class="pr-2">
                                    <h5>Live updates about your plan</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt laudantium ab earum nobis soluta ex! Suscipit doloribus, minima, facilis et pariatur perspiciatis molestias dignissimos esse deleniti veritatis, ipsum ad reprehenderit.</p>
                                </div>
                            </button>
                            <button class="notification d-flex">
                                <i class="fas fa-info-circle"></i>
                                <div class="pr-2">
                                    <h5>New Plan has been posted!</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt laudantium ab earum nobis soluta ex! Suscipit doloribus, minima, facilis et pariatur perspiciatis molestias dignissimos esse deleniti veritatis, ipsum ad reprehenderit.</p>
                                </div>
                            </button>
                            <button class="notification d-flex">
                                <i class="fas fa-info-circle"></i>
                                <div class="pr-2">
                                    <h5>Reminder: Take your medecine daily</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt laudantium ab earum nobis soluta ex! Suscipit doloribus, minima, facilis et pariatur perspiciatis molestias dignissimos esse deleniti veritatis, ipsum ad reprehenderit.</p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </button>
            <div class="dropdown benny-account-option">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="bennyAccountDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Welcome, <strong *ngIf="userDetail">{{ userDetail.first_name }}!</strong> <i class="fas fa-user"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="bennyAccountDropdown">
                    <button class="dropdown-item" type="button" routerLink="/benny-dash/my-base-info">
                        My Info
                        <svg class="w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </button>
                    <button class="dropdown-item" type="button" routerLink="/benny-dash/settings">
                        My Settings
                        <svg class="w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </button>
                    <button class="dropdown-item" type="button" routerLink="/benny-dash/community">
                        My Community
                        <svg class="w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </button>
                    <hr class="my-1">
                    <button class="dropdown-item" type="button" (click)="showModal(shareThisPlan)">
                        Share
                        <!-- <svg class="w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> -->
                    </button>
                    <a class="dropdown-item" routerLink="/logout" (click)="toggleNavbarNav()">
                        Logout
                        <!-- <svg class="w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="d-none {{dblocking}}" #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
</div>

<div class="d-none">
    <div #shareThisPlan>
        <app-share-form></app-share-form>
    </div>
</div>