<ng-container *ngIf="!isShowTableOnly">
  <div *ngIf="!isGeneralDrugCopay && !isAddYourDrugs">
    <h4 class="uppercase fColor-blue fWeight-700 text-center mb-5">Plan Drug Coverage</h4>
    <img class="plan-drug-img" src="./assets/img/common/icons/Asset 4xxxhdpi1.png" (click)="viewGeneralDrugCopays()" alt="">
    <h6 class="uppercase text-center mb-5 max-width-285 mx-auto cursor-pointer" (click)="viewGeneralDrugCopays()">View The General Drug Copays In Each Stage & Pharmacy Type</h6>

    <img class="plan-drug-img" src="./assets/img/common/icons/Asset 7xxxhdpi1.png" (click)="viewAddYourDrugs()" alt="">
    <h6 class="uppercase text-center max-width-285 mx-auto cursor-pointer" (click)="viewAddYourDrugs()">Enter Drugs For A Full Report</h6>
  </div>
  <!-- 
  <div class="view-info plans-drug-costs-wrapper" *ngIf="!viewTable">
    <img src="{{ insurance_logo }}" alt="Insurance Logo">
    <h1>{{ currentSelectedResult }}</h1>
    <div class="d-flex justify-content-around align-items-center">
      <ul>
        <li>Benefit Type: <span>{{ this.drugBenefitTypeDetail }}</span></li>
        <li>Monthly Premium: <span>{{getConvertedRate(monthRate) | currency }}</span></li>
        <li *ngIf="annualDrugDeductible=='-1'">Part D Deductible: <span>This Plan Does Not Have Drug Coverage</span></li>
        <li *ngIf="annualDrugDeductible!='-1'">Part D Deductible: <span>{{getConvertedRate(annualDrugDeductible) | currency }}</span></li>
      </ul>
      <ul>
        <li>
          <button class="btn btn-third-plain" (click)="viewTableFunc('Preferred Pharmacies', this.preferredMailOrder)">Preferred Pharmacies</button>
        </li>
        <li>
          <button class="btn btn-third-plain" (click)="viewTableFunc('Standard Pharmacies', this.standardPharmacies)">Standard Pharmacies</button>
        </li>
        <li>
          <button class="btn btn-third-plain" (click)="viewTableFunc('Mail Order', this.preferredRetail)">Mail Order</button>
        </li>
      </ul>
    </div>
  </div>
  -->
  <div class="view-table" *ngIf="isGeneralDrugCopay">
    <button class="btn btn-first btn-small" (click)="viewDefault()">Back</button>
    <h4 class="mx-auto text-center">Plan Copays In The Regular Coverage Stage</h4>
    <button class="btn btn-third my-4 btn-xs mx-auto">Learn About Part D Coverage Stages</button>
    <app-pharmacies-order-table tableTitle="Preferred Pharmacies" [drugsTier]="[preferredMailOrder]"></app-pharmacies-order-table>
    <app-pharmacies-order-table tableTitle="Standard Pharmacies" [drugsTier]="[standardPharmacies]"></app-pharmacies-order-table>
    <app-pharmacies-order-table tableTitle="Mail Order" [drugsTier]="[preferredRetail]"></app-pharmacies-order-table>
  </div>
  <div *ngIf="isAddYourDrugs">
    <button class="btn btn-first btn-small" (click)="viewDefault()">Back</button>
    <app-add-your-drugs></app-add-your-drugs>
  </div>
</ng-container>

<ng-container *ngIf="isShowTableOnly">
  <h4 class="mx-auto text-center">Plan Copays In The Regular Coverage Stage</h4>
  <button class="btn btn-third my-4 btn-xs mx-auto">Learn About Part D Coverage Stages</button>
  <div class="view-table">
    <app-pharmacies-order-table tableTitle="Preferred Pharmacies" [drugsTier]="[preferredMailOrder]"></app-pharmacies-order-table>
    <app-pharmacies-order-table tableTitle="Standard Pharmacies" [drugsTier]="[standardPharmacies]"></app-pharmacies-order-table>
    <app-pharmacies-order-table tableTitle="Mail Order" [drugsTier]="[preferredRetail]"></app-pharmacies-order-table>
  </div>
</ng-container>