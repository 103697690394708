import { Component, OnInit, Input, Output } from '@angular/core';
import {DrugsService} from '../../services/drugs.service';
import {FormControl, Validators, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-common-drugs',
  templateUrl: './common-drugs.component.html',
  styleUrls: ['./common-drugs.component.scss']
})
export class CommonDrugsComponent implements OnInit {

  @Input() step:any;
  view_default:boolean = true;
  view_drug_list:boolean = false;
  view_selectDosage:boolean = false;
  view_selectPackaging:boolean = false;
  view_selectAmount: boolean = false;
  loading: boolean = false;
  results: any[] = [];
  searchFieldDrugname: any = '';
  step1Results: any[] = [];

  step1Form = new FormGroup({
    drugname: new FormControl('', [
      Validators.required,
    ]),
    allow_generics: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(private drugService: DrugsService) {
  }

  ngOnInit() {
  }

  async getSuggestedList(keyword) {
    try {
      //this.isLoading[type] = true;
      const results: any = await this.drugService.getAutoSuggest(keyword);
      console.log('result', results);

      if (results) {
        this.results = results;
      }
    } catch(e) {
      console.log('error', e);
    } finally {
      //this.isLoading[type] = false;
    }
  }

  fullnameValueChanged(event: any) {
    const text = event;
    console.log(text);

    if (text.length > 2) {
      this.getSuggestedList(text);
    }
  }


  async onSubmit(event: any) {
    this.view_drug_list = true;

    try {
      //this.isLoading[type] = true;
      const step1Results: any = await this.drugService.step1(this.step1Form.controls['drugname'].value, this.step1Form.controls['allow_generics'].value);
      console.log('result', step1Results);

      if (step1Results) {
        this.step1Results = step1Results;
      }
    } catch(e) {
      console.log('error', e);
    } finally {
      //this.isLoading[type] = false;
    }

  }

  addList(list){
    this.drugService.addList(list);
  }

}
