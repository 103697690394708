<div id="authSlider" class="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="border-dashed-radius">
                <app-authorized-representative-question-slide1></app-authorized-representative-question-slide1>
                <div class="d-flex justify-content-between align-items-end mt-2">
                    <span><strong>NOTE:</strong> This form is only for <strong>Authorized Representatives</strong> only.</span>
                    <div class="d-flex align-items-center">
                        <button class="btn btn-first btn-xs" type="button" data-target="#authSlider" data-slide="next">Next</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <div class="border-dashed-radius">
                <app-authorized-representative-question-slide2></app-authorized-representative-question-slide2>
                <div class="d-flex justify-content-between align-items-end mt-2">
                    <span><strong>NOTE:</strong> This form is only for <strong>Authorized Representatives</strong> only.</span>
                    <div class="d-flex align-items-center">
                        <button class="back-sm-btn btn btn-first btn-xs mr-2" type="button" data-target="#authSlider" data-slide="prev">Back</button>
                        <button class="btn btn-first btn-xs" type="button" data-target="#authSlider" data-slide="next">Next</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <div class="border-dashed-radius">
                <app-authorized-representative-question-slide3></app-authorized-representative-question-slide3>
                <div class="d-flex justify-content-between align-items-end mt-2">
                    <span><strong>NOTE:</strong> This form is only for <strong>Authorized Representatives</strong> only.</span>
                    <div class="d-flex align-items-center">
                        <button class="back-sm-btn btn btn-first btn-xs" type="button" data-target="#authSlider" data-slide="prev">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>