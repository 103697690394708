import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-primary-care-provider-selection',
  templateUrl: './primary-care-provider-selection.component.html',
  styleUrls: ['./primary-care-provider-selection.component.scss']
})
export class PrimaryCareProviderSelectionComponent implements OnInit {

  isSpecifyPreferredPrimaryCareDoctor:boolean = false;
  
  areYourACurrentPatient:boolean = false;

  @Input() details: any;
  
  constructor() { }

  ngOnInit() {
  }

  onChange_isSpecifyPreferredPrimaryCareDoctor(value){}

  onChange_areYourACurrentPatient(value){}

}
