import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of',
  templateUrl: './what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of.component.html',
  styleUrls: ['./what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of.component.scss']
})
export class WhatAreSomeOtherMedicareEnrollmentPeriodsIShouldBeAwareOfComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  clickCoverageOption(modalContent){
    Swal.fire({
      html: modalContent,
      customClass: {
        popup: 'custom-info-modal-wrapper'
      },
      width: 750,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

}
