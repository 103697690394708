<section class="new-to-medicare-section-wrapper">
    <div class="container">
      <div class="row new-to-medicare-section-wrapper-heading mb-4 {{headerText}}">
          <h2>{{ headingTitle }}</h2>
          <p>With the right approach, and support from the TapTap Team, you can understand it all, and get the most out of your Medicare experience.</p>
          <p class="mt-4">And even enjoy doing so while you're at it :)</p>
          <!-- <p>
            With the right approach, and guidance, you can understand it all very simply, and make all the right choices. <br>
             <b class="fColor-white">And even enjoy doing so while you're at it.</b><br><br> -->
             <!-- This is why we have pioneered 4 effective ways to help you with your "New to Medicare" experience. <br>
             <b class="fColor-white">Choose the option you'd like below:</b> -->
          <!-- </p>			 -->
          <!-- <p><b class="fColor-white">Choose Which Option You’d Like To Begin With:</b></p> -->
          <h4 class="mt-4"><b class="fColor-white">Choose Your Preferred Method To Begin</b></h4>
          
        </div>
      <div class="row new-to-medicare-tile-wrapper card-list">
        <div class="col-12 col-md-6 col-lg-4">
          <mat-card>
            <mat-card-title>The Dedicated Counselor</mat-card-title>
              <div class="mat-card-img-wrapper">
                <img mat-card-image src="assets/img/home/new/the-dedicated-counselor.png" alt="The Dedicated Counselor">
              </div>
              <mat-card-actions *ngIf="!getStarted_first" class="mb-0">
                <button routerLink="/registration" class="btn btn-first">Get Started</button>
              </mat-card-actions>
              <mat-card-content *ngIf="getStarted_first">
                <p>
                  A dedicated 1-on-1 consultation with a professional Medicare counselor. Your choice of  over the phone or online with information presented on your screen.
                </p>
                <mat-divider></mat-divider>
                <p>
                  <span class="color-primary fWeight-500">Use This If: </span>
                  You like “real person” guidance and the ability to discuss and ask questions with a dedicated counselor.
                </p>
              </mat-card-content>
              <mat-card-actions *ngIf="getStarted_first">
                <button routerLink="/registration" class="btn btn-first">Get Started</button>
              </mat-card-actions>
              <mat-card-actions *ngIf="!getStarted_first">
                <button (click)="getStarted_first = true" class="btn btn-second btn-xs mt-0">Learn More</button>
              </mat-card-actions>
          </mat-card>		
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <mat-card>
              <mat-card-title>The Self Learner</mat-card-title>
              <div class="mat-card-img-wrapper">
                <img mat-card-image src="assets/img/home/new/the-self-learner.png" alt="The Self learner">
              </div>
              <mat-card-actions *ngIf="!getStarted_second" class="mb-0">
                <button routerLink="/registration" class="btn btn-first">Get Started</button>
              </mat-card-actions>
              <mat-card-content *ngIf="getStarted_second">
                <p>
                  Navigate our guided “Big Picture” knowledge sessions to start learning on your own time, with all the most helpful resources readily available.
                </p>
                <mat-divider></mat-divider>
                <p>
                  <span class="color-primary fWeight-500">Use This If: </span>
                  You like to understand things independently (at least at ﬁrst) and enjoy self-directed learning.
                </p>
              </mat-card-content>
              <mat-card-actions *ngIf="getStarted_second">
                <button routerLink="/registration" class="btn btn-first">Get Started</button>
              </mat-card-actions>
              <mat-card-actions *ngIf="!getStarted_second">
                <button (click)="getStarted_second = true" class="btn btn-second btn-xs mt-0">Learn More</button>
              </mat-card-actions>
          </mat-card>		
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <mat-card>
              <mat-card-title>The TapTap Recommendation Engine</mat-card-title>
              <div class="mat-card-img-wrapper">
                <img mat-card-image src="assets/img/home/new/the-recommendation-engine-new.png" alt="The Recommendation Engine">
              </div>
              <mat-card-actions *ngIf="!getStarted_third" class="mb-0">
                <button routerLink="/registration" class="btn btn-first">Get Started</button>
              </mat-card-actions>
              <mat-card-content *ngIf="getStarted_third">
                <p>
                  Just plug in a few details, your coverage preferences, your healthcare preferences, and our TapTap Recommendation Engine will provide you with the top plans, and related insights, for your specific situation.
                </p>
                <mat-divider></mat-divider>
                <p>
                  <span class="color-primary fWeight-500">Use This If: </span>
                  You like automated guidance & self service and want to get right to the point & start seeing plans that are suitable for you.
                </p>
              </mat-card-content>
              <mat-card-actions *ngIf="getStarted_third">
                <button routerLink="/registration" class="btn btn-first">Get Started</button>
              </mat-card-actions>
              <mat-card-actions *ngIf="!getStarted_third">
                <button (click)="getStarted_third = true" class="btn btn-second btn-xs mt-0">Learn More</button>
              </mat-card-actions>
          </mat-card>		
        </div>

        
        <!-- <div class="col-12 col-sm-6 col-lg-3">
          <mat-card>
            <mat-card-title>The Note Taker</mat-card-title>
            <img mat-card-image src="assets/img/home/new/icon4.png" alt="Medicare Coverage">
            <mat-card-content>
              <p>
                <span class="color-primary fWeight-500">Description:</span>
                This option allows you to sign in and start saving all of your research, top facts and notes, and all of the questions you’d like further explanations on. This option also gives you full access to our pioneering “Consumer Community”
              </p>
              <mat-divider></mat-divider>
              <p>
                <span class="color-primary fWeight-500">Use This If: </span>
                You like to have a system of learning and want to make sure all the research You do can be saved and easily retrieved, shared, and referenced for later.
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button routerLink="/registration" class="btn btn-third">Get Started</button>
            </mat-card-actions>
          </mat-card>		
        </div> -->
      </div>
    </div>
  </section>