<h6>Table of Contents</h6>
<div class="accordion" id="aep-nav">
    <div class="card">
      <div class="card-header">
        <i class="fas fa-caret-right"></i>
        <button class="btn btn-link" (click)="slideToSection('overview')" type="button" data-target="#collapseOne"
          aria-expanded="true" aria-controls="collapseOne">
          Overview
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingTwo">
        <i class="fas fa-caret-right"></i>
        <button class="btn btn-link collapsed"
          (click)="slideToSection('what-does-this-annual-enrollment-period-mean-for-me')" type="button"
          data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          What Does This Annual Enrollment Period Mean For Me?
        </button>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#aep-nav">
        <div class="card-body">
          <ul>
            <li>
              <button class="btn btn-link"
                (click)="slideToSection('what-does-this-annual-enrollment-period-mean-for-me')" type="button"
                aria-expanded="false">
                What Does It Allow Me To Do?
              </button>
            </li>
            <li>
              <button class="btn btn-link" (click)="slideToSection('what-doesnt-it-allow-me-to-do')" type="button"
                aria-expanded="false">
                What Doesn’t It Allow Me To Do?
              </button>
            </li>
            <li>
              <button class="btn btn-link" (click)="slideToSection('what-questions-should-i-be')" type="button"
                aria-expanded="false">
                What Questions Should I Be
              </button>
            </li>
            <li>
              <button class="btn btn-link" (click)="slideToSection('is-there-anything-i-absolutely-have-to-do')"
                type="button" aria-expanded="false">
                Is There Anything I Absolutely Have To Do?
              </button>
            </li>
            <li>
              <button class="btn btn-link" (click)="slideToSection('what-if-i-do-nothing')" type="button"
                aria-expanded="false">
                What If I Do Nothing?
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <i class="fas fa-caret-right"></i>
        <button class="btn btn-link collapsed" type="button"
          (click)="slideToSection('what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of')"
          aria-expanded="false">
          What Are Some Other Medicare Enrollment Periods I Should Be Aware Of?
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <i class="fas fa-caret-right"></i>
        <button class="btn btn-link collapsed"
          (click)="slideToSection('an-updated-big-picture-refresher-on-your-medicare-coverage-options')" type="button"
          aria-expanded="false">
          An Updated Big Picture Refresher On Your Medicare Coverage Options
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <i class="fas fa-caret-right"></i>
        <button class="btn btn-link collapsed" (click)="slideToSection('whats-new-with-medicare-in-2021')" type="button"
          aria-expanded="false">
          What’s New With Medicare in 2021?
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <i class="fas fa-caret-right"></i>
        <button class="btn btn-link collapsed" (click)="slideToSection('opportunities-for-medicare-beneficiaries')"
          type="button" aria-expanded="false">
          Opportunities For Medicare Beneficiaries
        </button>
      </div>
    </div>
</div>


