<div class="container-fluid header-banner-wrapper">
  <app-header-b></app-header-b>
  <div class="container px-0">
    <div class="row">
      <div class="col-12 col-lg-5 p-0 text-label-wrapper">
        <div class="top-left-wrapper">
          <h1 class="top-left-wrapper-heading-title">Partnership Opportunities</h1>
          <p>
            TapTap Medicare offers ways for high-reputation
            companies and providers to work alongside our 
            unique community of consumers and the 
            technology infrastructure that supports them in
            their financial planning. 
          </p>
          <p>
            Send us a brief message to discuss
            oppotunities with our team.
          </p>
        </div>
        <img src="./assets/img/common/half-j2.png" alt="Sign Up Image" class="signup-img d-none d-lg-block">
      </div>
      <div class="col-12 col-lg-7 box-wrapper">
        <div class="primary-box">
          <div class="box-top">
            <form [formGroup]="partnershipForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="first_name">First Name<sup>*</sup></label>
                  <input type="text" class="form-control" name="first_name" id="first_name" formControlName="first_name">
                </div>
                <div class="form-group col-md-6">
                  <label for="last_name">Last Name<sup>*</sup></label>
                  <input type="text" class="form-control" name="last_name" id="last_name" formControlName="last_name">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="email">Email<sup>*</sup></label>
                  <input type="email" class="form-control" name="email" id="email" formControlName="email">
                </div>
                <div class="form-group col-md-6">
                  <label for="phone">Phone</label>
                  <input type="number" class="form-control" name="phone" id="phone" formControlName="phone">
                </div>
              </div>
              <div class="form-group">
                <label for="howDidYouHearAboutUs">How Did You Hear About Us?<sup>*</sup></label>
                <select id="howDidYouHearAboutUs" class="custom-select form-control" formControlName="how_did_you_hear_about_us">
                  <option value="F" selected>Facebook</option>
                  <option value="G">Twitter</option>
                  <option value="N">Linked In</option>
                </select>
              </div>
              <div class="form-group">
                <label for="yourMessage">Your Message</label>
                <textarea name="yourMessage" id="yourMessage" cols="30" rows="5" formControlName="yourMessage"></textarea>
              </div>
              <div class="form-row mb-4">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheck1"
                    formControlName="terms_and_condition">
                  <label class="custom-control-label" for="customCheck1">I agree with service <a
                      class="capitalize">Terms & Conditions!</a></label>
                </div>
              </div>
              <div>
                <p class="text-danger" *ngIf="show_captcha_message">Recaptcha required</p>
              </div>
              <div class="d-flex flex-wrap justify-content-md-around justify-content-between">
                <!-- <div #recaptcha style="width:200px"></div> -->
                <!-- new recaptcha  - stephen(11-13-2019)-->
                <recaptcha [formControl]="taptapRecaptcha" (scriptLoad)="onScriptLoad()" (scriptError)="onScriptError()"
                  class="recaptcha-wrapper my-2"></recaptcha>
                <!-- end new recaptcha  - stephen(11-13-2019)-->

                <button type="submit" class="btn btn-first" (click)="register()">Signup Now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-ready-to-talk></app-ready-to-talk>