import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2'; 
@Component({
  selector: 'app-medigap-version2',
  templateUrl: './version2.component.html',
  styleUrls: ['./version2.component.scss']
})
export class Version2Component implements OnInit {

  @Input() results: any;

  @Input() logComparedKeys: Array<Object>;

  @Input() controls: any;

  @Input() isMassachusetts: boolean;

  @Input() part_d_id;

  @Input() base_img_loc: any;

  @Input() isBlueBgHeading = false;

  @Input() istaptap;

  @Output() onClickCompanyProfile = new EventEmitter();

  @Output() onClickConsumerReviews = new EventEmitter();

  @Output() onClickMoreInsights = new EventEmitter();

  @Output() onClickApplyOnline = new EventEmitter();

  @Output() onClickApplyOnThePhone = new EventEmitter();

  @Output() onClickAddToCompare = new EventEmitter();

  @Output() onChangeCostSelector = new EventEmitter();

  public selectedPricingRate: string = 'month';

  costToggleState: boolean = false;

  allowedZipCodeToHaveDiscounts = ['73504', '31119', '79987', '65641', '67059', '71412', '61999'];

  selectedCostSelectorIndex: any;

  addToCartLabel:string = 'Add to Cart';

  logCartKeys: Array<Object> = [];

  constructor() { 
    this.getExistingCartPlans();
  }

  ngOnInit() {
  }

  companyLogoCheck(event){
    $(event.target).parent().find('.no-img-plan-name').addClass('d-block').removeClass('d-none');
    $(event.target).parents('tr').find('.applyActionBtn_noImage').addClass('d-block').removeClass('d-none');
    $(event.target).parents('tr').find('.applyActionBtn').addClass('d-none');
  }

  changePremiumRate_new(event) {
    this.selectedPricingRate = event.target.value;
    $(event.target).next('.width_tmp_select').find(".width_tmp_option").html($(event.target).find('option:selected').text());
    $('.custom-select-single-icon').width($(event.target).next(".width_tmp_select").width() + 30);
  }

  on_costSelectorDropdown(costType, index){    
    //$('.costSelectorDropdown').toggleClass('active');
    $('#costSelectorDropdown_'+ index).toggleClass('active');
    this.selectedCostSelectorIndex = index;
    this.selectedPricingRate = costType;
    this.costToggleState = false;
    this.onChangeCostSelector.emit(costType);
  }

  onClickCostToggleState(index){
    $('#costSelectorDropdown_'+ index).toggleClass('active');
  }

  getConvertedRate2(rate, discountRate, naic) {

    rate = parseFloat((rate * .01).toFixed(2));

    if (this.controls.household.value == 2) { //apply only selected naic;
        if (this.allowedZipCodeToHaveDiscounts.includes(naic)) {
            if (discountRate !== 0 || discountRate !== null) {
                rate = rate - (rate * discountRate);
            }
        }
    } else if (this.controls.household.value == 1) { //;
        if (discountRate !== 0 || discountRate !== null) {
            rate = rate - (rate * discountRate);
        }
    }

    return rate;
  }

  companyProfile(result){
    this.onClickCompanyProfile.emit(result);
  }

  consumerReview(result){
    this.onClickConsumerReviews.emit(result);
  }

  additionalInsights(result){
    this.onClickMoreInsights.emit(result);
  }

  applyOnline(result, index, img){
    this.onClickApplyOnline.emit({result, index, img});
  }

  applyOnThePhone(result, img){
    this.onClickApplyOnThePhone.emit({result, img});
  }

  addToCompareFunc(result,event,index){
    let vcb = document.querySelector('#view_compare_btn_'+index);
    vcb.classList.toggle('d-block');
    this.onClickAddToCompare.emit({result,event,index});
  }

  onclick_planDocsResources(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.toggle('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.remove('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.toggle('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.toggle('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.remove('d-md-flex');

  }

  onclick_doctorDrugAnalysis(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.toggle('d-flex');
    pab.classList.remove('d-flex');
    
    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.toggle('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.toggle('d-md-flex');
    arrow3.classList.remove('d-md-flex');
    
  }
  
  onclick_planActionButtons(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.toggle('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.toggle('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.toggle('d-md-flex');

  }

  onClick_AddToCart(result, event){

    const addToCartBtn = document.querySelector('#ci_cart_btn__'+result.key);
    const viewCartBtn = document.querySelector('#view_cart_btn_'+result.key);
    
    if ( event.target.checked ) {

      this.addToCartLabel = 'Added to Cart';
      addToCartBtn.classList.add('active');

      var existingEntries = JSON.parse(localStorage.getItem("add_to_cart_medigap"));            

      if(existingEntries == null) {
          existingEntries = [];
      }

      existingEntries.push(result);
      localStorage.setItem("add_to_cart_medigap", JSON.stringify(existingEntries));

      viewCartBtn.classList.add("d-block");
      viewCartBtn.classList.remove("d-none");

    }else{
      this.addToCartLabel = 'Add to Cart';
      addToCartBtn.classList.remove('active');
      this.removeToCart(result);
      viewCartBtn.classList.remove("d-block");
      viewCartBtn.classList.add("d-none");
    }
  }

  removeToCart(result){

    const cartItem =  JSON.parse(window.localStorage.getItem('add_to_cart_medigap'));

    if( cartItem ) {
         for (let x = 0; x < cartItem.length; x++) {
             if (result['key'] !== 'undefined' && cartItem[x]['key'] !== 'undefined') {
                 if (result['key'] === cartItem[x]['key']) {
                  cartItem.splice(x, 1);
                 }
             }
         }
     }

    localStorage.setItem("add_to_cart_medigap", JSON.stringify(cartItem));

  }

  getExistingCartPlans(){
      let addToCartItem = localStorage.getItem("add_to_cart_medigap");

      if(addToCartItem){
          let existingCartEntries = JSON.parse(addToCartItem);

          if(existingCartEntries){
              existingCartEntries.forEach( (element) => {
                  this.logCartKeys.push(element.key);
              });
          }
      }
  }

  onClick_DiscussThisPlanWithOthers(modalContent){
    Swal.fire({
      html: modalContent,
      showConfirmButton: false,
      showCloseButton: true,
      width: '500px'
    })
  }

}