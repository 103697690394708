<!-- DISPLAY RESULT SECTION -->
<div class="result-version-5 container mb-3" *ngFor="let result of results; let i = index">
  <table class="container result-section">
      <tr class="tr-result-heading" [class.bgBlue]="isBlueBgHeading">
          <th class="heading-company">Company</th>
          <th class="heading-company-info">Plan Info</th>
          <th class="heading-ratings">Ratings</th>
          <th class="heading-pricing">
              <div class="costSelectorDropdown" id="costSelectorDropdown_{{ i }}"> <!-- [ngClass]="{ 'active': costToggleState }"-->
                  <button (click)="on_costSelectorDropdown('annual', i)">Annually</button>
                  <button (click)="on_costSelectorDropdown('month', i)">Monthly</button>
                  <button (click)="on_costSelectorDropdown('quarter', i)">Quarterly</button>
                  <button (click)="on_costSelectorDropdown('semi_annual', i)">Semi Annually</button>
              </div>
              Costs
              <i class="fa fa-chevron-down" (click)="onClickCostToggleState(i)"></i>       <!--(click)="costToggleState = !costToggleState"-->                     
              <!-- <div id="costSelectorWrapperDropdown" class="custom-select-single-icon d-none">
                  <select class="resizing_select custom-select col-12" (change)="changePremiumRate_new($event)">
                      <option value="annual">Annually</option>
                      <option value="month" selected>Monthly</option>
                      <option value="quarter">Quarterly</option>
                      <option value="semi_annual">Semi Annually</option>
                  </select>
                  <select class="width_tmp_select" style="display:none">
                      <option class="width_tmp_option"></option>
                  </select>
                  <i class="fa fa-chevron-down"></i>
              </div> -->
          </th>
          <th class="heading-action d-none">Actions</th>
      </tr>
      <tr [ngClass]="{'planTypeStartLetterTr': result?.plan_start }">
          <td class="text-center planTypeStartLetter" *ngIf="result?.plan_start">
              <h4 *ngIf="result?.plan_start == 'MA_CORE'">Core Plan</h4>
              <h4 *ngIf="result?.plan_start == 'MA_SUPP1'">Plan 1</h4>
              <h4 *ngIf="result?.plan_start == 'MA_SUPP1A'">Plan 1A</h4>
              <h4 *ngIf="result?.plan_start == 'All-MA'">All Plans</h4>
          </td>
          <td class="result-logo-wrapper">
              <span class="no-img-plan-name d-none">{{ result.company_base.name }}</span>
              <img class="result-logo" id="logo_{{result.company_base.naic}}_{{i}}"
                   onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
                   src="{{ base_img_loc + result.company_base.naic + '.png' }}"
                   alt="{{ result.company_base.name_full }}"
                   (error)="companyLogoCheck($event)">

              <h6 class="results-plan-name" *ngIf="!isMassachusetts">Medigap Plan {{ result.plan }}</h6>
              <h6 class="results-plan-name" *ngIf="isMassachusetts">Medigap {{ result.plan }}</h6>

          </td>
          <td class="result-company-info-wrapper">
            <app-view-all-ratings
                image="{{ base_img_loc + result.taptap_details.naic + '.png' }}"                
                [hasHeading]="false"
                [forResult]="true"
                [result]="result">
            </app-view-all-ratings>
          </td>

          <td class="result-ratings-wrapper align-items-center justify-content-center">
              <span class="fWeight-600 color-primary uppercase">Financial Rating</span>
              <span class="fColor-black rating-content capitalize d-block onMobileTabletResultValue" [innerHTML]="(result?.taptap_details?.financial_rating) ? result?.taptap_details?.financial_rating : (result?.company_base?.ambest_rating) ? result?.company_base?.ambest_rating : 'N/A'"></span>
              <div class="divider dashed w-75 my-3 border-thin"></div>
              <!-- <span class="fWeight-600 color-primary uppercase text-center">Price Stability Rating:</span> -->
              <!-- <span class="fColor-black rating-content capitalize d-block onMobileTabletResultValue" [innerHTML]=""></span> -->
              <!-- <button class="btn btn-first btn-xs mt-2" (click)="additionalInsights(result)">View all Ratings</button> -->
          </td>

          <td class="result-pricing-wrapper align-items-center justify-content-start border-0">
              <div class="pricing-content onMobileTabletResultValue with-household" *ngIf="result.household">
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='month' && result.household">
                      <span>Applicant 1:</span>
                      <span class="price-value">{{ getConvertedRate2(result.rate.month, result.discounts[0]?.value, result.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='month' && result.household">
                      <span>Applicant 2:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.month, result.household?.discounts[0]?.value, result.household?.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='month' && result.household">
                      <span>Combined Price:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.month, result.household?.discounts[0]?.value, result.household?.company_base.naic ) + getConvertedRate2(result.rate.month, result.discounts[0]?.value, result.company_base.naic )  | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='quarter' && result.household">
                      <span>Applicant 1:</span>
                      <span class="price-value">{{ getConvertedRate2(result.rate.quarter, result.discounts[0]?.value, result.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='quarter' && result.household">
                      <span>Applicant 2:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.quarter, result.household?.discounts[0]?.value, result.household?.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='quarter' && result.household">
                      <span>Combined Price:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.quarter, result.household?.discounts[0]?.value, result.household?.company_base.naic ) + getConvertedRate2(result.rate.quarter, result.discounts[0]?.value, result.company_base.naic )  | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='semi_annual' && result.household">
                      <span>Applicant 1:</span>
                      <span class="price-value">{{ getConvertedRate2(result.rate.semi_annual, result.discounts[0]?.value, result.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='semi_annual' && result.household">
                      <span>Applicant 2:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.semi_annual, result.household?.discounts[0]?.value, result.household?.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='semi_annual' && result.household">
                      <span>Combined Price:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.semi_annual, result.household?.discounts[0]?.value, result.household?.company_base.naic ) + getConvertedRate2(result.rate.semi_annual, result.discounts[0]?.value, result.company_base.naic )  | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='annual' && result.household">
                      <span>Applicant 1:</span>
                      <span class="price-value">{{ getConvertedRate2(result.rate.annual, result.discounts[0]?.value, result.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='annual' && result.household">
                      <span>Applicant 2:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.annual, result.household?.discounts[0]?.value, result.household?.company_base.naic ) | currency }}</span>
                  </p>
                  <p class="d-block text-right text-md-left" *ngIf="selectedPricingRate==='annual' && result.household">
                      <span>Combined Price:</span>
                      <span class="price-value">{{ getConvertedRate2(result.household?.rate.annual, result.household?.discounts[0]?.value, result.household?.company_base.naic ) + getConvertedRate2(result.rate.annual, result.discounts[0]?.value, result.company_base.naic )  | currency }}</span>
                  </p>

              </div>

              <div class="pricing-content onMobileTabletResultValue" *ngIf="!result.household && istaptap">
                <span class="fColor-blue uppercase fWeight-600 d-block text-center">Premium:</span>
                    <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em" *ngIf="controls.tobacco.value == 0">
                        {{ result.plan_pricing.rate | currency }}
                    </span>
                    <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em" *ngIf="controls.tobacco.value == 1">
                      {{ result.plan_pricing.tobacco_rate | currency }}
                  </span>
              </div>

              <div class="pricing-content onMobileTabletResultValue" *ngIf="!result.household && !istaptap">
                  <span class="fColor-blue uppercase fWeight-600 d-block text-center">Premium:</span>
                      <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em" *ngIf="selectedPricingRate==='month'">
                          {{ getConvertedRate2(result.rate.month, result.discounts[0]?.value, result.company_base.naic ) | currency }}
                      </span>

                      <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em" *ngIf="selectedPricingRate==='quarter'">
                          {{ getConvertedRate2(result.rate.quarter, result.discounts[0]?.value, result.company_base.naic ) | currency }}
                      </span>

                      <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em" *ngIf="selectedPricingRate==='semi_annual'">
                          {{ getConvertedRate2(result.rate.semi_annual, result.discounts[0]?.value, result.company_base.naic ) | currency }}
                      </span>

                      <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em" *ngIf="selectedPricingRate==='annual'">
                          {{ getConvertedRate2(result.rate.annual, result.discounts[0]?.value, result.company_base.naic ) | currency }}
                      </span>
                      <small class="fColor-base fWeight-600 d-block text-center" *ngIf="selectedPricingRate==='month'">per month</small>
                      <small class="fColor-base fWeight-600 d-block text-center" *ngIf="selectedPricingRate==='quarter'">per quarter</small>
                      <small class="fColor-base fWeight-600 d-block text-center" *ngIf="selectedPricingRate==='semi_annual'">per semi annual</small>
                      <small class="fColor-base fWeight-600 d-block text-center" *ngIf="selectedPricingRate==='annual'">per annual</small>

              </div>
          </td>

          <td class="result-action-wrapper d-none">
              <div class="action-content result-button-td result-button-wrapper">
                  <button class="btn btn-first mb-2 btn-xs applyActionBtn" (click)="applyOnline(result, i, base_img_loc + result.company_base.naic + '.png')">
                      Apply Online
                  </button>
                  <button class="btn btn-first btn-xs applyActionBtn_noImage mb-2 d-none" (click)="applyOnline(result, i, '')">
                      Apply Online
                  </button>
                  <button class="btn btn-first mb-2 btn-xs applyActionBtn" (click)="applyOnThePhone(result, base_img_loc + result.company_base.naic + '.png')">
                      Apply on Phone
                  </button>
                  <button class="btn btn-first btn-xs applyActionBtn_noImage mb-2 d-none" (click)="applyOnThePhone(result, '')">
                      Apply on Phone
                  </button>
                  <button class="btn btn-first mt-2 saveThisPlanButton">Save This Plan</button>
              </div>

              <div class="result-action-compare-add">
                  <!-- <div class="custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" class="custom-control-input" id="saveThisPlan_{{i}}">
                  <label class="custom-control-label" for="saveThisPlan_{{i}}">Save Selected Plan</label>
                  </div> -->
                  <div class="compared-plans-btn-wrapper custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" class="custom-control-input" id="addToCompare_{{i}}" (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.key)">
                  <label class="custom-control-label" for="addToCompare_{{i}}">Add To Compare</label>
                  <a target="_blank" href="/insurance-marketplace/medigap/results/compare-plans?zipcode={{controls.zipcode.value}}&age={{controls.age.value}}&tobacco={{controls.tobacco.value}}&household={{controls.household.value}}&plan={{controls.plan.value}}&gender={{this.controls.gender.value}}&compare={{part_d_id}}" class="viewComparePlansBtn btn btn-third-plain btn-small d-none" [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a>
                  </div>
              </div>
          </td>
      </tr>
      <tr>
        <div class="plan-document-buttons d-flex flex-column flex-md-row pt-3">                      
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button1_{{result.key}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_planDocsResources(result.key)">
              <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
              More Company Details
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow1_{{result.key}}">
              <div class="arrow"></div>
            </div>
          </div>
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button2_{{result.key}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_doctorDrugAnalysis(result.key)">
              <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
              More Plan Details
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow2_{{result.key}}">
              <div class="arrow"></div>
            </div>
          </div>
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button3_{{result.key}}" class="compare-button btn btn-third btn-xs position-relative" (click)="onclick_planActionButtons(result.key)">
              Save, Compare, or Enroll
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow3_{{result.key}}">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
      </tr>
  </table>

  <!-- MORE BENEFITS & DOCUMENTS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="docs_resources_id_{{result.key}}">
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="p-4 bg-light border h-100">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/company-details-no-icon.png" alt="">
        </div>
        <h4 class="title-header text-center text-dgray">Company Details</h4>
        <div class="w-full d-flex flex-column justify-content-around">          
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="companyProfile(result)">Company Profile</button>
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second">Additional Company Insights</button>
          <!-- <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="consumerReview(result)">Consumer Reviews About This Company</button> -->
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="p-4 bg-light yellow-border h-100">
        <div class="d-flex justify-content-center">
          <img src="./assets/img/retiree-dashboard/8-old-seniors-cropped.png" alt="">
        </div>
        <h4 class="title-header text-center text-dgray">Consumer Community</h4>
        <div class="w-full d-flex flex-column justify-content-around">
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first" (click)="consumerReview(result)">Consumer Reviews About This Company</button>     
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first">Discuss This Company With Others</button>
        </div>
      </div>
    </div>
  </div>

  <!-- DOCTORS & DRUGS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="doctors_drugs_id_{{result.key}}">
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="bg-light border p-4 h-100">
        <div class="d-flex justify-content-center">
            <img src="./assets/img/plan-benefits.png" alt="">
        </div>
        <h4 class="title-header text-center">Plan Benefits</h4>
        <div class="w-full d-flex flex-column justify-content-around">
          <!-- <a class="d-none link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" target="_blank" href="#">TapTap Interactive Plan Guide</a> -->
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" routerLink="/insurance-marketplace/medigap/single-plan" target="_blank">TapTap Interactive Plan Page</button>
          <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="additionalInsights(result)">Additional Plan Insights</button>
                   
        </div>        
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4 mx-auto">
      <div class="bg-light yellow-border p-4 h-100">
        <div class="d-flex justify-content-center">
            <img src="./assets/img/retiree-dashboard/8-old-seniors-cropped.png" alt="">
        </div>
        <h4 class="title-header text-center">Consumer Community</h4>
        <div class="w-full d-flex flex-column justify-content-around">        
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first">Consumer Reviews About This Plan</button>   
          <button class="link-button-bg-unset w-md-full mx-md-2 my-2 btn btn-small btn-first" (click)="onClick_DiscussThisPlanWithOthers(discussThisPlanWithOthersModal)">Discuss This Plan With Others</button>
        </div>        
      </div>
    </div>
  </div>

  <!-- PLAN ACTION BUTTONS -->
  <div class="d-none row doctors-drugs-actions2 mx-auto" id="plan_actions_id_{{result.key}}">
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100 enrollment-button">
        <!-- <button class="saveplan-button w-100 my-2 btn btn-small btn-second">Save This Plan</button>
        <div class="addcompare-button w-100 my-2 d-flex align-items-center justify-content-center">
          <input type="checkbox" class="compare-input" id="addToCompare_{{result.key}}" (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.key)">
          <label class="compare-label mb-0" for="addToCompare_{{result.key}}">Add To Compare</label>
        </div>
        <a target="_blank" id="view_compare_btn_{{i}}" href="/insurance-marketplace/medigap/results/compare-plans?zipcode={{controls.zipcode.value}}&age={{controls.age.value}}&tobacco={{controls.tobacco.value}}&household={{controls.household.value}}&plan={{controls.plan.value}}&gender={{this.controls.gender.value}}&compare={{part_d_id}}" class="viewComparePlansBtn btn btn-third-plain btn-small d-none" [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a> -->
        
        <button class="save d-flex align-items-center px-3 py-2 my-2">
          <img class="small-icon" src="./assets/img/common/save.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>SAVE THIS PLAN</span>
          </div>
        </button>
        <app-share-plan [sharePlanId]=result.key></app-share-plan>
        <button class="add d-flex align-items-center my-2 add-to-compare-button">
          <div class="checkbox-wrapper d-flex align-items-center justify-content-center ml-4">
            <input type="checkbox" class="compare-input" id="addToCompare_{{result.key}}"
            (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.key)">
          </div>
          <div class="d-flex justify-content-center flex-grow-1 add-to-compare-label-wrapper">
            <label for="addToCompare_{{result.key}}">ADD TO COMPARE</label>
          </div>
        </button>
        <a target="_blank"
        href="/insurance-marketplace/medigap/results/compare-plans?zipcode={{controls.zipcode.value}}&age={{controls.age.value}}&tobacco={{controls.tobacco.value}}&household={{controls.household.value}}&plan={{controls.plan.value}}&gender={{this.controls.gender.value}}&compare={{part_d_id}}"
        class="viewComparePlansBtn btn btn-third-plain btn-sm d-none ff mt-2"
        id="view_compare_btn_{{i}}"
        [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a>
      </div>
    </div>
    <div class="col-12 col-lg-6 p-4">
      <div class="p-4 bg-light border h-100 enrollment-button">
        <!-- <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnline(applyOnlineModal,result,i)">Enroll Online</button> -->
        <!-- <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnline(result, i, base_img_loc + result.company_base.naic + '.png')">Enroll Online</button>
        <button class="apply-button w-100 my-2 btn btn-small btn-second" (click)="applyOnThePhone(result, base_img_loc + result.company_base.naic + '.png')">Enroll On The Phone</button> -->


        <button class="online d-flex align-items-center px-3 py-2 my-2" (click)="applyOnline(result, i, base_img_loc + result.company_base.naic + '.png')">
          <img class="small-icon" src="./assets/img/common/online.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>ENROLL ONLINE</span>
          </div>
        </button>
        <button class="phone d-flex align-items-center px-3 py-2 my-2" (click)="applyOnThePhone(result, base_img_loc + result.company_base.naic + '.png')">
          <img class="small-icon" src="./assets/img/common/phone.svg" alt="">
          <div class="d-flex justify-content-center flex-grow-1">
            <span>ENROLL ON THE PHONE</span>
          </div>
        </button>
        <button class="cart d-flex align-items-center p-0" id="ci_cart_btn__{{result.key}}" [class.active]="logCartKeys.includes(result.key)">
          <label class="cart-btn-label" for="ci_add_to_cart__{{result.key}}">
            <img class="small-icon" src="./assets/img/common/cart.svg" alt="">
            <span class="flex-grow-1">{{ addToCartLabel }}</span>
          </label>
        </button>
        <input type="checkbox" class="d-none" id="ci_add_to_cart__{{ result.key }}" (change)="onClick_AddToCart(result,$event)" [checked]="logCartKeys.includes(result.key)">
        <a target="_blank"
            href="/cart"
            class="viewCartBtn btn btn-third-plain btn-sm d-none ff mt-2"
            id="view_cart_btn_{{result.key}}"
            [class.d-block]="logCartKeys.includes(result.key)">View Cart</a>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #discussThisPlanWithOthersModal>
    <h4 class="uppercase text-center">Discuss This Plan</h4>
    <a class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second-fill mt-3px" target="_blank" routerLink="/insurance-marketplace/medigap/single-plan">TapTap Interactive Plan Guide</a>
  </div>
</div>