<h4 class="title">Policy Info</h4>
<div class="row px-0 pt-2">
  <div class="col-12 col-sm-6 pr-2">
      <div class="form-group">
        <label>MAPD Requested Effective Date Confirmation</label>
        <input type="text" class="form-control blurred"/>
      </div>
  </div>
  <div class="col-12 col-sm-6 pr-2">
    <div class="form-group">
      <label>MAPD Insurance Carrier Confirmation</label>
      <input type="text" class="form-control blurred"/>
    </div>
  </div>
  <div class="col-12 col-sm-6 pr-2">
    <div class="form-group">
      <label>MAPD Plan Name Confirmation</label>
      <input type="text" class="form-control blurred" [value]="planName"/>
    </div>
  </div>
  <div class="col-12 col-sm-6 pr-2">
    <div class="form-group">
      <label>Medicaid Number</label>
      <input type="text" class="form-control blurred"/>
    </div>
  </div>
  <div class="col-12 col-sm-6 pr-2">
    <div class="form-group">
      <label>Social Security Number</label>
      <input type="text" class="form-control blurred"/>
    </div>
  </div>
</div>