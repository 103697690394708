<div class="d-none" [class.d-block]="dobCalendarStep == 1">
  <div class="row justify-content-center">
    <h5 class="text-center">{{heading}}</h5>
    <!-- <h5 class="text-center">What Month & Year Were You Born?</h5> -->
  </div>
  <div class="row">
    <div class="form-group p-2 col-6">
      <label for="month">Month</label>
      <select [(ngModel)]="selectMonth" class="form-control" name="month" id="month">
        <option [ngValue]="01">January</option>
        <option [ngValue]="02">February</option>
        <option [ngValue]="03">March</option>
        <option [ngValue]="04">April</option>
        <option [ngValue]="05">May</option>
        <option [ngValue]="06">June</option>
        <option [ngValue]="07">July</option>
        <option [ngValue]="08">August</option>
        <option [ngValue]="09">September</option>
        <option [ngValue]="10">October</option>
        <option [ngValue]="11">November</option>
        <option [ngValue]="12">December</option>
      </select>
    </div>
    <div class="form-group p-2 col-6">
      <label for="year">Year</label>
      <!-- <select [(ngModel)]="selectYear" class="form-control" name="year" id="year">
        <option [ngValue]="1965">1965</option>
        <option [ngValue]="1964">1964</option>
        <option [ngValue]="1963">1963</option>
        <option [ngValue]="1962">1962</option>
        <option [ngValue]="1961">1961</option>
        <option [ngValue]="1960">1960</option>
        <option [ngValue]="1959">1959</option>
        <option [ngValue]="1958">1958</option>
        <option [ngValue]="1957">1957</option>
        <option [ngValue]="1956">1956</option>
        <option [ngValue]="1955">1955</option>
        <option [ngValue]="1954">1954</option>
        <option [ngValue]="1953">1953</option>
        <option [ngValue]="1952">1952</option>
        <option [ngValue]="1951">1951</option>
        <option [ngValue]="1950">1950</option>
        <option [ngValue]="1949">1949</option>
        <option [ngValue]="1948">1948</option>
        <option [ngValue]="1947">1947</option>
        <option [ngValue]="1946">1946</option>
        <option [ngValue]="1945">1945</option>
        <option [ngValue]="1944">1944</option>
        <option [ngValue]="1943">1943</option>
        <option [ngValue]="1942">1942</option>
        <option [ngValue]="1941">1941</option>
        <option [ngValue]="1940">1940</option>
      </select> -->
      <select [(ngModel)]="selectYear" class="form-control" name="year" id="year">
        <option [ngValue]="2023">2023</option>
        <option [ngValue]="2022">2022</option>
        <option [ngValue]="2021">2021</option>
        <option [ngValue]="2020">2020</option>
        <option [ngValue]="2019">2019</option>
        <option [ngValue]="2018">2018</option>
        <option [ngValue]="2017">2017</option>
        <option [ngValue]="2016">2016</option>
        <option [ngValue]="2015">2015</option>
        <option [ngValue]="2014">2014</option>
        <option [ngValue]="2013">2013</option>
        <option [ngValue]="2012">2012</option>
        <option [ngValue]="2011">2011</option>
        <option [ngValue]="2010">2010</option>
        <option [ngValue]="2009">2009</option>
        <option [ngValue]="2008">2008</option>
        <option [ngValue]="2007">2007</option>
        <option [ngValue]="2006">2006</option>
        <option [ngValue]="2005">2005</option>
        <option [ngValue]="2004">2004</option>
        <option [ngValue]="2003">2003</option>
        <option [ngValue]="2002">2002</option>
        <option [ngValue]="2001">2001</option>
        <option [ngValue]="2000">2000</option>
        <option [ngValue]="1999">1999</option>
        <option [ngValue]="1998">1998</option>
        <option [ngValue]="1997">1997</option>
        <option [ngValue]="1996">1996</option>
        <option [ngValue]="1995">1995</option>
        <option [ngValue]="1994">1994</option>
        <option [ngValue]="1993">1993</option>
        <option [ngValue]="1992">1992</option>
        <option [ngValue]="1991">1991</option>
        <option [ngValue]="1990">1990</option>
        <option [ngValue]="1989">1989</option>
        <option [ngValue]="1988">1988</option>
        <option [ngValue]="1987">1987</option>
        <option [ngValue]="1986">1986</option>
        <option [ngValue]="1985">1985</option>
        <option [ngValue]="1984">1984</option>
        <option [ngValue]="1983">1983</option>
        <option [ngValue]="1982">1982</option>
        <option [ngValue]="1981">1981</option>
        <option [ngValue]="1980">1980</option>
        <option [ngValue]="1979">1979</option>
        <option [ngValue]="1978">1978</option>
        <option [ngValue]="1977">1977</option>
        <option [ngValue]="1976">1976</option>
        <option [ngValue]="1975">1975</option>
        <option [ngValue]="1974">1974</option>
        <option [ngValue]="1973">1973</option>
        <option [ngValue]="1972">1972</option>
        <option [ngValue]="1971">1971</option>
        <option [ngValue]="1970">1970</option>
        <option [ngValue]="1969">1969</option>
        <option [ngValue]="1968">1968</option>
        <option [ngValue]="1967">1967</option>
        <option [ngValue]="1966">1966</option>
        <option [ngValue]="1965">1965</option>
        <option [ngValue]="1964">1964</option>
        <option [ngValue]="1963">1963</option>
        <option [ngValue]="1962">1962</option>
        <option [ngValue]="1961">1961</option>
        <option [ngValue]="1960">1960</option>
        <option [ngValue]="1959">1959</option>
        <option [ngValue]="1958">1958</option>
        <option [ngValue]="1957">1957</option>
        <option [ngValue]="1956">1956</option>
        <option [ngValue]="1955">1955</option>
        <option [ngValue]="1954">1954</option>
        <option [ngValue]="1953">1953</option>
        <option [ngValue]="1952">1952</option>
        <option [ngValue]="1951">1951</option>
        <option [ngValue]="1950">1950</option>
        <option [ngValue]="1949">1949</option>
        <option [ngValue]="1948">1948</option>
        <option [ngValue]="1947">1947</option>
        <option [ngValue]="1946">1946</option>
        <option [ngValue]="1945">1945</option>
        <option [ngValue]="1944">1944</option>
        <option [ngValue]="1943">1943</option>
        <option [ngValue]="1942">1942</option>
        <option [ngValue]="1941">1941</option>
        <option [ngValue]="1940">1940</option>

        <option [ngValue]="1939">1939</option>
        <option [ngValue]="1938">1938</option>
        <option [ngValue]="1937">1937</option>
        <option [ngValue]="1936">1936</option>
        <option [ngValue]="1935">1935</option>
        <option [ngValue]="1934">1934</option>
        <option [ngValue]="1933">1933</option>
        <option [ngValue]="1932">1932</option>
        <option [ngValue]="1931">1931</option>
        <option [ngValue]="1930">1930</option>
        <option [ngValue]="1929">1929</option>
        <option [ngValue]="1928">1928</option>
        <option [ngValue]="1927">1927</option>
        <option [ngValue]="1926">1926</option>
        <option [ngValue]="1925">1925</option>
        <option [ngValue]="1924">1924</option>
        <option [ngValue]="1923">1923</option>
        <option [ngValue]="1922">1922</option>
        <option [ngValue]="1921">1921</option>
        <option [ngValue]="1920">1920</option>        
      </select>
    </div>
  </div>
  <div class="mt-3">
    <button (click)="nextStep()" class="btn btn-first btn-small mx-auto" *ngIf="isShowDaySelection">Next</button>
    <button (click)="calculateAge(false)" class="btn btn-first btn-small mx-auto" *ngIf="!isShowDaySelection">Submit</button>
  </div>
</div>
<div *ngIf="dobCalendarStep == 2">
  <div class="row justify-content-center">
    <h5 class="text-center">{{heading}}</h5>
    <!-- <h5 class="text-center">What Day Were You Born?</h5> -->
  </div>
  <div class="px-3">
    <mat-calendar [startAt]="startAt" [selected]="selectedDate" (selectedChange)="getAgeDate($event)" #coverageDateInput></mat-calendar>
  </div>
  <div class="row mt-3">
    <div class="col-6 p-2">
      <button (click)="dobCalendarStep = 1" class="w-100 btn btn-second btn-small">Back</button>
    </div>
    <div class="col-6 p-2">
      <button class="w-100 btn btn-first btn-small" [disabled]="!isDobCalendarDateSelected" (click)="calculateAge()">Submit</button>
    </div>
  </div>
</div>
