<div class="ready-to-talk-section">

  <div class="container schedule-section">
      <div class="row justify-content-center mx-0">
          <div class="custom-box col-12 schedule-box d-flex align-items-center justify-content-center">
              <div class="schedule-box-inside">
                  <h2>Not Sure What You Need? Let's Talk</h2>
                  <p>Reserve your own dedicated time with an in-house counselor to reeive clear
                    explanations, a full consultation, or a brief "Coverage Clarity" session to better
                    understand your own current coverage.
                  </p>
                  
                  <a target="_blank" (click)="speakToCounselorEvent(speakToCounselorModal)" class="btn btn-first mx-auto">Speak With a Concierge
                  </a>
              </div>
          </div>
      </div>
  </div>
  <div class="container d-flex justify-content-center align-items-start">
    <img src="./assets/img/common/old-woman-halfbody-with-safety-kit.png" alt="Let's Talk Image" class="bottom-character">
  </div>
  <div class="schedule-box-bg-div top"></div>
  <div class="schedule-box-bg-div bottom"></div>

</div>
<div class="d-none">
  <div #speakToCounselorModal>
      <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>