import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-paying-your-plan-premium',
  templateUrl: './paying-your-plan-premium.component.html',
  styleUrls: ['./paying-your-plan-premium.component.scss']
})
export class PayingYourPlanPremiumComponent implements OnInit {
  
  @Input() details: any;
  @Input() planName: any;
  @Input() planPremium: any;
  
  constructor() { }

  ngOnInit() {
  }

}
