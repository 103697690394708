<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">Out-of-Pocket Indemnity Coverage Overview
					</h1>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">        
        <img src="./assets/img/medicare-advantage-gap/103446492_s.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<div class="container">
  <h4 class="fColor-black">How Does It Work?</h4>
  <p>Out-of-Pocket Indemnity Insurance is designed to help protect you from the co-payments, deductibles and out-of-pocket expenses of your health insurance plan. </p>
  <p>These policies pay a cash benefit directly to you to help cover the costs associated with specific health care services such as:</p>
  <div class="double-column-list">
    <ul>
      <li class="fWeight-600">Inpatient Hospitalization</li>
      <li class="fWeight-600">Outpatient Procedures</li>
      <li class="fWeight-600">Cancer Diagnoses</li>
      <li class="fWeight-600">Critical Accidents</li>
    </ul>
    <ul>
      <li class="fWeight-600">Heart Attack</li>
      <li class="fWeight-600">Stroke</li>
      <li class="fWeight-600">Skilled Nursing Stays</li>
      <li class="fWeight-600">Extended Physical Therapy</li>
    </ul>
  </div>
</div>

<div class="container">
  <h4 class="fColor-black">The Use of Indemnity Plans with Medicare Advantage Coverage</h4>
  <p>
    <strong class="fColor-base">Summary:</strong> When you enroll in a Medicare Advantage Plan, you replace Medicare Part A and B with a privatized HMO or PPO plan that will serve as your new major medical and drug coverage.
  </p>
  <p>
    This coverage type leaves you with some sources of significant out-of-pocket risk, which can be protected against with an Out-of-Pocket Indemnity policy.
  </p>
</div>

<div class="container coverage-process mt-3">
  <div class="row">
    <div class="col">
      <span class="d-block text-center">
        Original Medicare is <b>NOT</b> your Primary Coverage
      </span>
      <img class="mt-3 mx-auto d-flex" src="./assets/img/learn-more/Group 310.png" alt="">
    </div>
    <img class="option-2-right-arrow" src="./assets/img/beneficiary/benny-nav/benny-dash-arrow-right.png"/>
    <div class="col">
      <span class="d-block text-center">
        <b class="color-primary">MEDICARE ADVANTAGE</b> is Your <b>PRIMARY AND</b> Drug Coverage & Dental, Vision & Hearing
      </span>
      <img class="mt-3 mx-auto d-flex" src="./assets/img/learn-more/Group 311.png" alt="">
    </div>
    <i class="fa fa-plus"></i>
    <div class="col">
      <span class="d-block text-center">
        <b class="color-primary">INDEMNITY COVERAGE</b> to Cover Out-of-Pocket Risk
      </span>
      <img class="mt-3 mx-auto d-flex" class="indemnity-img" src="./assets/img/medicare-advantage-gap/dental-coverage-for-dental-services-icon.png" alt="">
    </div>
  </div>
</div>

<div class="container mt-4">
  <h4 class="fColor-black">The Key Features of Indemnity Coverage Are:</h4>
  <ul>
    <li class="fWeight-600">You receive payment even if you have other insurance</li>
    <li class="fWeight-600">Pay you directly, and you may use the payment however you want</li>
    <li class="fWeight-600">There’s no deductible and no hospital precertification required</li>
    <li class="fWeight-600">There’s no network, so you use any doctors and providers you’d like Your policy remains in force as long as your premiums are paid on time</li>
  </ul>
</div>

<div class="container mt-4">
  <h4 class="fColor-black">The Impact of Out-of-Pocket Hospitalization Costs</h4>
  <div class="cost-with-percentage-group">
    <div class="number-figure color-primary fWeight-600 f-22px">44%</div>
    <div class="ml-3 f-22px">Set up a payment plan with a hospital or health care provider</div>
  </div>
  <div class="cost-with-percentage-group">
    <div class="number-figure color-primary fWeight-600 f-22px">42%</div>
    <div class="ml-3 f-22px">Spent all or most of their personal savings</div>
  </div>
  <div class="cost-with-percentage-group">
    <div class="number-figure color-primary fWeight-600 f-22px">27%</div>
    <div class="ml-3 f-22px">Unable to pay for basic necessities like food, heat or housing</div>
  </div>
  <div class="cost-with-percentage-group">
    <div class="number-figure color-primary fWeight-600 f-22px">23%</div>
    <div class="ml-3 f-22px">Taken on credit card debt that may be hard to pay back</div>
  </div>
  <div class="cost-with-percentage-group">
    <div class="number-figure color-primary fWeight-600 f-22px">7%</div>
    <div class="ml-3 f-22px">Declared Bankruptcy</div>
  </div>
</div>

<div class="container mt-4">
  <h4 class="fColor-black">Why Is It Important?</h4>
  <p>
    Unexpected medical costs can add up - especially if you have any negative health events when covered by a Major Medical health insurance plan.
  </p>
  <p>
    Out-of-Pocket Indemnity Insurance is an affordable way to help protect your financial health from all the costs of deductibles, co-pays and additional expenses not covered by your major health insurance plan.
  </p>
  <p>
    Benefits are paid directly to you so you can use the funds any way you choose.
  </p>
</div>

<div class="container mt-4">
  <div class="additional-note-wrapper">
    <img src="./assets/img/medicare-advantage-gap/97576810_m.png" alt="additional note">
    <div>
      <p>Source: “Patient's Perspectives on Health Care in the United States,”
        National Public Radio, the Robert Wood Johnson Foundation, and the Harvard
        T.H. Chan School of Public Health, February 2016.</p>
    </div>
  </div>
</div>

<div class="container">
  <p>Out-of-Pocket Indemnity policies are <b>not </b><strong class="color-primary">"Standardized"</strong>.</p>
  <p>
    This means the coverage, costs, and claims process can vary depending on exact plan you choose and the insurance company you purchase it from.
  </p>
  <p>
    Once you have an idea of the marketplace of options you have available to you, the following characteristics serve as a guide to the criteria you should use to choose your Out-of-Pocket Indemnity Policy.
  </p>
</div>

<div class="container mt-4">
  <h4 class="fColor-black">How To Choose An Indemnity Plan</h4>
  <div class="criteria-wrapper">
    <div class="criteria-item"> <span>Criteria 1</span> <span>Covers the Benefits You Want</span></div>
    <div class="criteria-item"> <span>Criteria 2</span> <span>Price Competitiveness</span></div>
    <div class="criteria-item"> <span>Criteria 3</span> <span>Ease of Use/Convenience of Claims</span></div>
    <div class="criteria-item"> <span>Criteria 4</span> <span>Financial Ratings and Overall Reputation</span></div>
    <div class="criteria-item"> <span>Criteria 5</span> <span>Quality of Customer Service</span></div>
  </div>
</div>


<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>