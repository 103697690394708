<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Eligible Enrollment Periods</h4>
    <div class="yellow-line"></div>
    <div class="plan-premium-regular-question">
      <h6 class="fw-semibold text-dblue mt-4">For You Application For Medigap Coverage, Do you have now, or in the last two (2) years have you:</h6>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="sle-q1-a1">
        <label class="form-check-label" for="sle-q1-a1">
          Had a new or existing diagnosis of
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="sle-q1-a2">
        <label class="form-check-label" for="sle-q1-a2">
          Been treated for
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="sle-q1-a3">
        <label class="form-check-label" for="sle-q1-a3">
          Been advised by a medical professional to have treatment
        </label>
      </div>
    </div>

    <div *ngFor="let item of healthData; let i = index;">
      <div :id="plan-premium-accordion-{{item.id}}" class="mt-4">
        <div class="card">
          <div class="card-header" :id="heading-{{item.id}}">
            <h5 class="mb-0">
              <button :class="{{ item.id === 1 ? 'btn btn-link': 'btn btn-link collapsed' }}" data-toggle="collapse" attr.data-target="#collapse-{{item.id}}" attr.aria-expanded="{{ item.id === 1 ? 'true': 'false' }}" attr.aria-controls="collapse-{{item.id}}">
                {{ item.title }}
              </button>
            </h5>
          </div>
      
          <div :id="collapse-{{item.id}}" :class="{{ item.id === 1 ? 'collapse show': 'collapse' }}" attr.aria-labelledby="heading-{{item.id}}" attr.data-parent="#plan-premium-accordion-{{item.id}}">
            <div class="card-body">
              <div class="plan-yes-no">
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-2"><b>Yes</b></div>
                  <div class="col-md-2"><b>No</b></div>
                </div>
              </div>
              <div class="plan-checkbox-container">
                <div class="row plan-radio-border" *ngFor="let conditionName of item.value; let idx = index;">
                  <div class="col-md-8">
                    <label class="form-check-label">
                      {{ conditionName }}
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="form-check">
                      <input class="form-check-input" name="c_{{ item.id + '_' + idx }}" type="radio" value="" :id="c_{{ item.id + '_' + idx }}">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-check">
                      <input class="form-check-input" name="c_{{ item.id + '_' + idx }}" type="radio" value="" :id="c_{{ item.id + '_' + idx }}">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>

<!-- <div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div> -->

