import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-your-drugs',
  templateUrl: './add-your-drugs.component.html',
  styleUrls: ['./add-your-drugs.component.scss']
})
export class AddYourDrugsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
