import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-new-to-medicare',
  templateUrl: './new-to-medicare.component.html',
  styleUrls: ['./new-to-medicare.component.scss']
})
export class NewToMedicareComponent implements OnInit {
  @Input() hasHeaderText: string;
  @Input() headingTitle: string = 'Ensure A Great Medicare Experience';

  headerText: string = 'd-block';

  constructor() { }

  ngOnInit() {
      if(this.hasHeaderText == 'no'){
        this.headerText = 'd-none'
      }else{
        this.headerText = 'd-block'
      }
  }

  getStarted_first:boolean = false;
  getStarted_second:boolean = false;
  getStarted_third:boolean = false;

}
