import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-important-information',
  templateUrl: './important-information.component.html',
  styleUrls: ['./important-information.component.scss']
})
export class ImportantInformationComponent implements OnInit {

  @Output() emit_ShowAcknowledgement = new EventEmitter;
  @Output() emit_ImportantInformationDate = new EventEmitter;
  @Output() emit_showDefaultView = new EventEmitter;
  @Input() view1:boolean = true;
  @Input() view2:boolean = false; 
 
  isIUnderstand: boolean = false;
  isIUnderstand2: boolean = false;

  dateFieldType: string;
  dateFieldHeading: string;
  importantInformationSelectedDate:any;

  firstName:string;
  middleName:string;
  lastName:string;

  isAuthorizedRepresentative:string = 'no';

  isAllMailSentToAuthorizedRepresentative:string = 'no';

  isShowAllMailSentToAuthorizedRepresentative:boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

  showFirstView(){
    this.view1 = true;
    this.view2 = false;
  }

  showSecondView(){
    this.view1 = false;
    this.view2 = true;
  }

  showAcknowledgement(){
    const data = {
      first_name:this.firstName,
      middle_name:this.middleName,
      last_name:this.lastName
    }
    

    localStorage.setItem('online_application_mapd_full_name', JSON.stringify(data));

    this.emit_ShowAcknowledgement.emit();
  }

  showDefaultView(){
    this.emit_showDefaultView.emit();
  }

  onChange_IUnderstand(event){
    console.log('testing', event);
    if(event.target.checked){
      this.isIUnderstand = true;
    }else{
      this.isIUnderstand = false;
    }
  }

  onChange_IUnderstandTwo(event){
    if(event.target.checked){
      this.isIUnderstand2 = true;
    }else{
      this.isIUnderstand2 = false;
    }
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

  onReceiveDate(event){
    const idate = new Date(event.date);
    const fdate = this.formatDate(idate);
    this.importantInformationSelectedDate = fdate; 
    this.emit_ImportantInformationDate.emit();
  }

  formatDate(date) {
    return (this.padTo2Digits(date.getMonth()+1)) + '-' + this.padTo2Digits(date.getDate()) + '-' + date.getFullYear();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  onChange_isAuthorizedRepresentative(value){
    if(value === 'yes'){
      this.isShowAllMailSentToAuthorizedRepresentative = true;
    }else{
      this.isShowAllMailSentToAuthorizedRepresentative = false;
    }
  }

  onChange_isAllMailSentToAuthorizedRepresentative(value){
    console.log('cctv', value);
    // if(value === 'yes'){
    //   this.isShowAllMailSentToAuthorizedRepresentative = true;
    // }else{
    //   this.isShowAllMailSentToAuthorizedRepresentative = false;
    // }
  }
  
}
