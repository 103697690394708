import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MetadataService } from '../services/metadata.service';
import {Title} from "@angular/platform-browser";

declare var window: any;

@Component({
  selector: 'app-i-am-new-to-medicare',
  templateUrl: './i-am-new-to-medicare.component.html',
  styleUrls: ['./i-am-new-to-medicare.component.scss'],
  providers: [MetadataService]
})
export class IAmNewToMedicareComponent implements OnInit {
  private currentUrl = window.location.href;
  private baseUrl = window.location.origin;

  fullPrimaryHeader: boolean = true;
  isLoggedInUser: any;

  constructor(
    public _auth: AuthService,
    private metadataService: MetadataService,
    private titleService:Title
  ) {
    this.titleService.setTitle("New To Medicare - TapTap Medicare");
  }

  injectSEO(): void {
    this.metadataService.getMetaData('/i-am-new-to-medicare', (a) => {
      const { data } = a;
      const metatitle = document.getElementById('meta-title');
      const metadesc = document.getElementById('meta-desc');
      const keywords = document.getElementById('meta-keywords');
      const fbMetaTitle = document.getElementById('fb-meta-title');
      const fbMetaDescription = document.getElementById('fb-meta-description');
      const fbMetaUrl = document.getElementById('fb-meta-url');
      const fbMetaImage = document.getElementById('fb-meta-image');

      metatitle.setAttribute('content', data['meta-title']);
      metadesc.setAttribute('content', data['meta-desc']);
      keywords.setAttribute('content', data['meta-keywords']);
      fbMetaTitle.setAttribute('content', data['meta-title']);
      fbMetaDescription.setAttribute('content', data['meta-desc']);
      fbMetaUrl.setAttribute('content', this.currentUrl);
      fbMetaImage.setAttribute(
        'content',
        `${this.baseUrl}/assets/company-logo.jpg`
      );
      window.prerenderReady = true;
    });
  }

  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if (this.isLoggedInUser) {
      this.fullPrimaryHeader = false;
    }

    this.injectSEO();
  }

  public show: boolean = true;

  public dateOptionActiveStateToday: string = '';
  public dateOptionActiveStateTomorrow: string = '';
  public dateOptionActiveStateCalendar: string = '';

  isOpen = false;
  public isShowMe: boolean = false;

  showDateActive(type: number) {
    if (type === 1) {
      this.dateOptionActiveStateToday = 'active';
      this.dateOptionActiveStateTomorrow = '';
      this.dateOptionActiveStateCalendar = '';
      this.isShowMe = false;
    }

    if (type === 2) {
      this.dateOptionActiveStateToday = '';
      this.dateOptionActiveStateTomorrow = 'active';
      this.dateOptionActiveStateCalendar = '';
      this.isShowMe = false;
    }

    if (type === 3) {
      this.isShowMe = true;
      this.dateOptionActiveStateToday = '';
      this.dateOptionActiveStateTomorrow = '';
      this.dateOptionActiveStateCalendar = 'active';
      this.isOpen = !this.isOpen;
    }
  }
  showContent() {
    this.show = false;
  }
}
