<section class="container-fluid header-banner-wrapper py-0">
	<app-header-b [hasNoMarginTop]="true" [hasNoMarginBottom]="true" [isAepLandingPage]="true"></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
    <h3 class="fColor-white">
      Blessings Ahead From The TapTap Team 
    </h3>
    <h3 class="fColor-white">
      For The 2022 Medicare Annual Enrollment Period!
    </h3>
    <div class="row">
			<div class="col-md-5">
				<div class="banner-text-wrapper">
					<p class="banner-sub-title">The Annual Enrollment Period (AEP) Starts October 15th & Ends December 7th. <br> <br> It’s The Year 2022, This Is Your Guide On The Essential Insights & Perspectives You Need To Know For The 2023 Plan Year</p>
				</div>
			</div>
			<div class="col-md-7 px-0 banner-image-wrapper">
				<img src="./assets/img/aep-landing-page/Repeat Grid 1.png" class="banner-main-image">
			</div>
		</div>
	</div>
</section>

<section>
  <div class="container">
    <h4>An Outline of Your Steps:</h4>
    <ul>
      <li><strong class="color-primary">First Step:</strong> Do Not Worry. We Will Help You With Everything :)</li>
      <li><strong class="color-primary">Second Step:</strong> Understand What This AEP Period Means</li>
      <li><strong class="color-primary">Third Step:</strong> Think of Any Questions You’d Like Answered & Explained</li>
      <li><strong class="color-primary">Fourth Step:</strong> Choose If You’d Like Us To Customize Your Reports For Your Specific Prescription Drugs & Doctors</li>
      <li><strong class="color-primary">Fifth Step:</strong> Schedule Your Review With Us At The Time That Works Best For You</li>
      <li><strong class="color-primary">Final Step:</strong> Review And Discuss Your Custom Reports With Your TapTap Counselor</li>
    </ul>

    <div class="border-bottom-dashed" id="target_overview">
      <img src="./assets/img/aep-landing-page/QuickOverviewCharacters.png" class="float-none float-sm-right max-width-250">

      <h4 class="mt-5">A Quick Overview of AEP:</h4>
      <p>The Annual Enrollment Period (AEP) is the yearly period from October
        15th – December 7th when Medicare beneficiaries can make specific
        changes to their coverage.</p>
      <p>In short, during this period, if you’re enrolled in Medicare, you can:</p>
      
      <ul class="border-bottom-dashed">
        <li>Enroll or Disenroll From Medicare Advantage Plans</li>
        <li>Enroll or Disenroll From Standalone Medicare Part D Drug Plans</li>
        <li>Switch From One Medicare Advantage Plan To Another, Or From One Standalone Medicare Part D Drug Plan To Another</li>    
      </ul>
      <p><strong>Perspective:</strong> In regards to healthcare, this is the best time ever to be a Medicare beneficiary :)</p>
    </div>

    <div id="target_what-does-this-annual-enrollment-period-mean-for-me">
      <img src="./assets/img/aep-landing-page/2-cut-counselor-male-female.png" class="float-none float-sm-right max-width-250">    
      <h5 class="mt-5">What Does This Annual Enrollment Period Mean For Me?</h5>
      <p>In short, this period simply exists for 3 reasons:</p>
      <ol>
        <li>The Release of New Medicare Advantage and Medicare Part D Drug Plans For The Upcoming Calendar Year</li>
        <li>It Allows Medicare Beneficiaries To Enroll or Disenroll From Medicare Advantage Plans</li>    
        <li>It Allows Medicare Beneficiaries To Enroll or Disenroll From Standalone Medicare Part D Drug Plans</li>
      </ol>    
    </div>

    <h5 class="mt-4">Additional Note:</h5>
    <ul class="border-bottom-dashed">
      <li>Switch From One Medicare Advantage Plan To Another, Or From One Standalone Medicare Part D Drug Plan To Another</li>
      <li>During This Period, Beneficiaries Can Also Switch From Medicare Advantage Coverage Back To Original Medicare</li>    
    </ul>

    <div id="target_what-doesnt-it-allow-me-to-do">
      <h5 class="mt-4">What Doesn’t AEP Allow Me To Do?</h5>
      <ul>
        <li>It Does Not Allow You To Disenroll From Any Coverage Before January 1st</li>
        <li>It Does Not Eliminate The Underwriting Health Questions When Applying For Medicare Supplement (Medigap) Coverage</li>
        <li><b>Note:</b> Some States Are Continuous Open Enrollment for Medigap Coverage and Do Not Require Health Underwriting At All (Such As New York, Massachusetts, & Connecticut</li>    
      </ul>
    </div>

    <a class="btn btn-first mx-auto schedule-review-button max-width-500" href="https://brightprice.gigabook.com/book/all/7nOgpHyF4FSJJhPPZ49H/i/" target="_blank">Schedule a Review Time Now</a>
  </div>
</section>

<section id="target_is-there-anything-i-absolutely-have-to-do" class="bg-grey">  
  <div class="container d-flex align-items-center flex-column flex-md-row">
    <div>
      <h4>Is There Anything I Absolutely Have To Do?</h4>
      <p>In short, there is nothing that you absolutely have to do.</p>
      <p>
        However, there are a few things that are strongly encouraged to make sure you are in the most suitable coverage for the upcoming year.
      </p>

      <h5>The Top 3 Encourage Steps To Take Are:</h5>
      <ol>
        <li>Given Your Preferences, Economics, & Health Circumstances, Determine Whether Medicare Supplement (Medigap) OR Medicare Advantage (HMO & PPO Plans) Is Most Suitable For You</li>
        <li>Make Sure Your Medicare Prescription Drug Coverage (Either Through a Standalone Part D Drug Plan, or Within Your Medicare Advantage Plan) Is Still Compatible With Your Drug & Pharmacy Preferences</li>
        <li>If You Have a Medicare Advantage Plan, Make Sure Your Doctors & Providers Are Still In-Network or Accessible With Your Plan</li>
      </ol>  
    </div>
    <img src="./assets/img/aep-landing-page/boy-counselor-red-shirt.png" class="hide-on-mobile ml-5 max-width-250 max-height-370">
  </div>
</section>

<section id="target_what-if-i-do-nothing">
  <div class="container insurance-table">
    <img src="./assets/img/aep-landing-page/girl-counselor-black-suit.png" class="hide-on-mobile mr-5 max-width-250 max-height-500">
    <div>
      <h4 class="mt-5 d-flex justify-content-center">What If I Do Nothing?</h4>
      <h5>If You Have a Medicare Supplement (Medigap) Plan</h5>
      <table class="table table-bordered">
        <tr>
          <th>If You Do Nothing</th>
          <th>If You Do Take Action, Your Top Priority Should Be</th>
        </tr>
        <tr>
          <td>Your Exact Same Medigap Plan Will Just Continue  Into The New Year As Normal.</td>
          <td>Review The Price You Are Paying For Your Medicare Supplement (Medigap) Premium To Make Sure It Is Still Competitive With The Market & Is As Cost-Efficient As You Are Eligible For</td>
        </tr>
      </table>

      <h5>If You Have a Medicare Advantage (HMO or PPO) Plan</h5>
      <table class="table table-bordered">
        <tr>
          <th>If You Do Nothing</th>
          <th>If You Do Take Action, Your Top Priority Should Be</th>
        </tr>
        <tr>
          <td>
            You Will Stay Enrolled In That Same Plan For The New Year, You Are Not Required To Actively Renew It. <br><br>
            In Rare Cases, A Specific Medicare Advantage Plan Can Get Discontinued, However, If This Happens To Your Plan, You Will Automatically Be Enrolled In Your Insurance Company’s Most Similar Plan.
          </td>
          <td>Review Whether Your Current Medicare Advantage Plan Is Still Aligned and Compatible With Your Current Doctors & Providers, Your Drugs & Pharmacy, The Healthcare Services You Expect To Need Most In The Upcoming Year, and Any Other Key Benefits or Preferences You May Want For The New Year.</td>
        </tr>
      </table>

      <h5>If You Have a Standalone Part D Drug Plan</h5>
      <table class="table table-bordered">
        <tr>
          <th>If You Do Nothing</th>
          <th>If You Do Take Action, Your Top Priority Should Be</th>
        </tr>
        <tr>
          <td>
            You Will Stay Enrolled In That Same Plan For The New Year, You Are Not Required To Actively Renew It. <br><br>
            Every So Often, a Standalone Part D Drug Plan May Be Discontinued, However, If This Happens To Your Plan, You Will Automatically Be Enrolled In Your Insurance Company’s Most Similar Plan.
          </td>
          <td>
            Review Whether Your Current Drug Plan Is Still The Most Suitable & Cost Efficient For Your Current Drugs, Pharmacy, & Method of Receiving Your Drugs. 
          </td>
        </tr>
      </table>
    </div>
  </div>
</section>

<section id="target_what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of">
  <div class="container">
    <h4>What Are The Other Enrollment Periods I Need To Be Aware Of?</h4>
    <p><strong class="color-primary">Medicare Advantage Open Enrollment Period (MA OEP):</strong> This period occurs each year from January 1
      through March 31, and allows you to switch from one Medicare Advantage Plan to another, or to
      disenroll from a Medicare Advantage Plan and go back to Original Medicare.</p>

    <p><strong class="color-primary">Medicare Advantage Trial Right:</strong> This applies to you if you drop a Medigap policy to join a Medicare
      Advantage Plan for the first time, and you want to switch back within less than a year, you have a
      guaranteed issue right to re-enroll in your previous Medigap policy.</p>

    <p><strong class="color-primary">Special Enrollment Periods:</strong> These periods are specific to you and your own personal circumstances,
      and can occur at any time of the year if you have a qualifying situation or event. Having a Special
      Enrollment Period allows you to make changes to your Medicare Advantage or Medicare prescription
      drug coverage.</p>

    <p>There are about 15 different Special Enrollment Period circumstances, but the most common ones
      relate to involuntarily losing any existing coverage that you have, moving to a new residence outside
      your existing plan’s service area, and having, or losing, eligibility for any type of Medicare-related
      financial assistance program.</p>

      <a class="btn btn-first mx-auto schedule-review-button max-width-500" href="https://brightprice.gigabook.com/book/all/7nOgpHyF4FSJJhPPZ49H/i/" target="_blank">Schedule a Review Time Now</a>
  </div>
</section>

<section id="target_what-questions-should-i-be" class="bg-grey border-radius-20">
  <div class="container">
    <h4 class="text-center mb-4">What Questions Should I Be Asking?</h4>
    <ul>
      <li>Is My Current Coverage Type Still The Most Suitable For Me?</li>
      <li>Is My Specific Plan Still The Most Suitable For Me?</li>
      <li>Have I Had Any Significant Changes In My Health That May Change The Type of Coverage
        That Is Suitable For Me?</li>
      <li>Do I Expect Any Upcoming Significant Changes In My Health? Such As Upcoming Procedures,
        New Diagnoses, Or Changes In My Prescription Drugs?</li>
      <li>Have I Had Any Other Life Changes That Impacts How I Should Evaluate My Coverage</li>
      <li>Have My Costs For My Current Coverage Gone Up?</li>
      <li>Are There Any Healthcare Services That Would Add Value To My Life That I Am Not Currently
        Receiving With My Current Coverage Type and Specific plan?</li>
      <li>Do I have new doctors/providers I need to check the status of</li>
    </ul>
  </div>
</section>

<section class="big-picture" id="target_an-updated-big-picture-refresher-on-your-medicare-coverage-options">
  <div class="container">
    <h4 class="fColor-black text-center mb-4">A Big Picture Refresher On Your Medicare Coverage Options</h4>
    <div class="refresher-columns d-flex">
      <div class="refresher-paragraphs text-justify pr-3">
        <p class="font-size-1-2em pt-3"><strong class="color-primary">As a Medicare Beneficiary,</strong> you get the opportunity to choose between 2 different ways of setting up your Medicare healthcare. </p>
        <p><strong class="color-primary">Option 1</strong>  is keeping Original Medicare as your primary coverage and adding a Medicare Supplement (Medigap) plan as your secondary coverage for major-medical services, and adding a separate Standalone Part D Drug plan. </p>
        <p><strong class="color-primary">Option 2</strong> is replacing Original Medicare as your primary coverage with an individual Medicare HMO or PPO plan that becomes your full set of coverage for both major-medical services and prescription drug coverage, and often includes additional healthcare services within it, such as dental and vision benefits.</p>
      </div>
      <div class="d-flex align-items-center mb-4 flex-column flex-md-row">
        <div class="big-picture-button-wrapper justify-content-center">
          <div>
            <button class="btn btn-first mb-2 coverage-btn" (click)="clickCoverageOption(coverageOption1Modal)">
              <!-- <i class="fas fa-external-link-alt"></i>  -->
              <span>Coverage Option 1 <small>is using a Medicare Supplement plan</small></span>
            </button>
            <button class="btn btn-first coverage-btn" (click)="clickCoverageOption(coverageOption2Modal)">
              <!-- <i class="fas fa-external-link-alt"></i>  -->
              <span>Coverage Option 2 <small>is using a Medicare Advantage plan</small></span>
            </button>
          </div>
          <img src="./assets/img/aep-landing-page/blue-old-man-standing.png" class="hide-on-mobile mr-3 max-width-80">
        </div>
      </div>
    </div>
  </div>
</section>

<section id="target_opportunities-for-medicare-beneficiaries">
  <div class="container">
    <h4 class="fColor-base">What Do Most Beneficiaries Prefer?</h4>
    <p><strong class="color-primary">Among Current Experienced and Savvy Medicare Beneficiaries,</strong> some swear by Coverage Option 1, while others swear by Coverage Option 2.</p>
    <p><strong class="color-primary">The Right Coverage Type For You</strong> should be based on your particular preferences, goals, and health outlook.</p>
    <mat-divider></mat-divider>
    <p><strong class="color-primary">For Reference,</strong> in 2020 about <strong>39%[2] </strong> of eligible Medicare beneficiaries have a Medicare
      Supplement plan (Coverage Option 1) and <strong>56%[1]</strong> have a Medicare Advantage plan (Coverage
      Option 2.)
    </p>
    <ol>
      <li>AHIP (2019, May 30). State of Medigap 2019 Retrieved From <a href="https://www.ahip.org/state-of-medigap-2019/" target="blank">https://www.ahip.org/state-of-medigap-2019/</a></li>
      <li>Jacobson, G (2019, June 6). What Percent of New Medicare Beneficiaries Are Enrolling in Medicare Advantage? Retrieved From
        <a href="https://www.kff.org/medicare/issue-brief/what-percent-of-new-medicarebeneficiaries-are-enrolling-in-medicare-advantage/" target="blank">https://www.kff.org/medicare/issue-brief/what-percent-of-new-medicarebeneficiaries-are-enrolling-in-medicare-advantage/</a></li>
    </ol>
  </div>
</section>

<section class="the-state-of-medicare" id="target_whats-new-with-medicare-in-2021">
  <div class="container">
    <h4 class="text-center fWeight-600">The State of Medicare: <span class="color-inherit fWeight-500">Where Things Are Going</span></h4>
    <p><strong class="color-primary">Medicare Is Still Confusing,</strong> and unnecessarily complex. It is a system in which insurance
      companies, the healthcare and pharmaceutical companies, and the government are all
      jostling to stake their claim, and ultimately write the rules they like.</p>
    <p><strong class="color-primary">But On The Bright Side,</strong> there are a number of positive and encouraging trends taking place
      within the Medicare ecosystem.</p>
    <p><strong class="color-primary">We Are Actually Moving,</strong> slowly but surely, to a better system for all.</p>
    <p><strong class="color-primary">Here Are Just A Few</strong> of these positive trends:</p>

    <div class="image-with-text-box">
      <p>Medigap prices are becoming more cost-effective. There is greater
        transparency of prices, and carriers compete more than ever, making it is
        easier to identify the best companies and prices. Read More on this at
        <a href="https://www.taptap.st/Medigap-Efficiency">www.taptap.st/Medigap-Efficiency</a>
      </p>
      <img src="./assets/img/aep-landing-page/cooking.png" class="max-width-110">
    </div>

    <div class="image-with-text-box">
      <p>Medicare Advantage plans are getting more comprehensive and offering
        more “whole-health” benefits every year. While the networks and higher out
        of pocket risk are still the downsides, these plans are ecoming more valuable
        and providing better healthcare overage experiences. Read More on this at
        <a href="https://www.taptap.st/Advantage-Plan-Horizon">www.taptap.st/Advantage-Plan-Horizon</a>
      </p>
      <img src="./assets/img/aep-landing-page/eating-with-vege.png" class="max-width-110">
    </div>

    <div class="image-with-text-box">
      <p>The Medicare system is starting to get a hold on drug costs! And (though to
        a lesser degree) clarity and simplicity. Read More on this at
        <a href="https://www.taptap.st/Part-D-Clarity">www.taptap.st/Part-D-Clarity</a>
      </p>
      <img src="./assets/img/aep-landing-page/drinking.png" class="max-width-110">
    </div>

    <a class="btn btn-first mx-auto schedule-review-button max-width-500" href="https://brightprice.gigabook.com/book/all/7nOgpHyF4FSJJhPPZ49H/i/" target="_blank">Schedule a Review Time Now</a>
  </div>
</section>

<section class="visit-us-online text-center">
  <div class="container">
    <h4 class="mb-4">Tools & Resources Designed For You As A Medicare Beneficiary</h4>

    <div class="image-with-text-box">
      <img src="./assets/img/aep-landing-page/seniors-with-computer.png" class="max-width-150">
      <h3>
        TapTap Education Center
        <a href="https://www.taptapmedicare.com/education">www.taptapmedicare.com/education</a>
      </h3>
    </div>

    <div class="image-with-text-box">    
      <h3>
        TapTap Marketplace
        <a href="https://www.taptapmedicare.com/insurance-marketplace">www.taptapmedicare.com/insurance-marketplace</a>
      </h3>
      <img src="./assets/img/common/old-woman-half-body-with-basket-v1.png" class="max-width-150">
    </div>

    <div class="image-with-text-box">
      <img src="./assets/img/aep-landing-page/8-old-seniors.png" class="max-width-150">
      <h3>
        TapTap Community
        <a href="https://www.taptapmedicare.com/community">www.taptapmedicare.com/community</a>
      </h3>
    </div>

    <div class="image-with-text-box">    
      <h3>
        Jonathan’s Latest Insights & Explanations On Medicare
        <a href="https://www.taptapmedicare.com/health-insights-fun">www.taptapmedicare.com/health-insights-fun</a>
      </h3>
      <img src="./assets/img/aep-landing-page/jonathan.png" class="max-width-150">
    </div>
  </div>
</section>

<section class="the-custom-reports-wrapper">
  <div class="container">
    <h4 class="text-center mb-4">Custom Reports We Are Happy To Create For You</h4>

    <div class="image-with-text-box-highlight left"> 
      <img src="./assets/img/common/old-woman-holding-paper-eyeglass-cut-half.png">   
      <div class="text-left flex-auto">
        <h4 class="left border-bottom-right-radius-30">YOUR HEALTHCARE COVERAGE ORGANIZER</h4>
        <p>Your Custom Medicare Organizer Containing All The Information
          That Matters to Your Medicare Experience.</p>
      </div>    
    </div>

    <div class="image-with-text-box-highlight right your-guide-education">     
      <div class="text-right flex-auto">
        <h4 class="right border-bottom-left-radius-30">YOUR GUIDES & EDUCATION</h4>
        <p>Your Custom Medicare Organizer Containing All The
          Information That Matters to Your Medicare Experience.</p>
      </div>    
      <img src="./assets/img/aep-landing-page/boy-counselor-with-computer.png">
    </div>

    <div class="image-with-text-box-highlight left"> 
      <img src="./assets/img/common/old-woman-half-body-with-basket.png">   
      <div class="text-left flex-auto">
        <h4 class="left border-bottom-right-radius-30">YOUR MEDICARE MARKETPLACE</h4>
        <p>The Full Marketplace of Medicare Insurance Coverages and
          Carriers. Made Clear & Organized.</p>
      </div>    
    </div>

    <div class="image-with-text-box-highlight right">
      <div class="text-right flex-auto">
        <h4 class="right border-bottom-left-radius-30">YOUR NETWORK STATUS REPORT</h4>
        <p>Your Custom Report on Every Medicare Advantage Plan’s
          Network & Which Ones Are Accepted By Your</p>
      </div>    
      <img src="./assets/img/aep-landing-page/girl-counselor-yellow-jacket.png">   
    </div>

    <div class="image-with-text-box-highlight left">
      <img src="./assets/img/aep-landing-page/drinking.png">   
      <div class="text-left flex-auto">
        <h4 class="left border-bottom-right-radius-30">YOUR DRUG COSTS ANALYSIS</h4>
        <p>Personalized, Clear Analysis of Your Drug Copays With
          Each Plan, and How to Pay The Least Possible.</p>
      </div>        
    </div>
  </div>
</section>

<section class="recap-box-wrapper pb-0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="recap-box">
          <h4 class="mb-4">Recap of Next Steps:</h4>
          <ul>
            <li>Choose If You’d Like Us To Customize Your Reports For Your Specific Prescription Drugs
              & Doctors (We Will Also Help You Organize These Going Forward)</li>
            <li>Send These To Us In Any Way That Is Convenient For You:
              <ul>
                <li>You Can Text Them To Us At: (215) 315-7793</li>
                <li>Complete The Easy Form Online Here: <a href="https://www.TapTap.st/AEP-RxDr">www.TapTap.st/AEP-Rx-Drs-Preferences</a></li>
                <li>Call Us Anytime At: 1 (800) 215–5108</li>
              </ul>
            </li>
            <li>Schedule Your Review Time Here: <a href="https://www.TapTap.st/AEP-Review" target="blank">www.TapTap.st/AEP-Review</a> </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <h4 class="fColor-white d-flex justify-content-center mt-5">Thank You Again From The TapTap Team</h4>
        <h4 class="fColor-white d-flex justify-content-center">& Health, Happiness, Grace, & Goodness Ahead!</h4>
        <img src="./assets/img/aep-landing-page/5-counselor.png">
      </div>
    </div>
  </div>
</section>

<div class="d-none">
  <div class="coverageOptionModal" #coverageOption1Modal>
    <div class="option-heading">
      <span>Option 1</span>
      <h5>Medicare Supplement Coverage</h5>
    </div>
    <h5 class="fWeight-500 fColor-base">The Big Picture:</h5>
    <ul>
      <li>You "Keep" Original Medicare As Your Primary</li>
      <li>You Can Go To Any Healthcare Provider - No "Networks"</li>
      <li>No Out-of-Pocket For Major-Medical (Max of $198 a year)</li>
      <li>Higher Premiums - About $200 More a Month Than Option 2</li>
      <li>Your Drug Coverage Is Separate - Called a “Standalone” Part D Plan</li>
      <li>Your Dental Coverage Is a Separate Policy</li>
    </ul>

    <mat-divider class="dashed"></mat-divider>

    <p><strong>Summary:</strong> Keep Medicare as your primary and enroll in a Medicare Supplement (aka Medigap
        plan) to eliminate your Out-of-Pocket costs for major medical services. Your prescription drug
        coverage is a standalone Medicare Part D drug plan. Your dental, vision, & hearing coverage is
        a separate policy
    </p>

    <app-insurance-coverage-box highlighted="medigap" size="small"></app-insurance-coverage-box>
    
    <h5 class="fColor-base">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">Like</span> About This Coverage?</h5>
    <ul class="checklist">
      <li>Eliminates Your Out-of-Pocket for Drs, Hospitals, Surgerys, Tests, & Therapies</li>
      <li>No Networks - You Can Go to Any Healthcare Provider</li>
      <li>The Coverage Stays The Same Every Year</li>
      <li>Very Convenient & Straightforward</li>
    </ul>

    <mat-divider class="dashed"></mat-divider>

    <h5 class="fColor-base mt-5">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">NOT Like</span> About This Coverage?</h5>
    <ul class="xlist">
        <li>Higher Premiums - Must Pay Premiums Even If You Are Not Using the Coverage</li>
        <li>Must Get Your Medicare Part D Drug Coverage Separatelys</li>
        <li>Must Get your Dental, Vision, & Hearing Coverage Separately</li>
        <li>No Access to Benefits Like Health & Wellness, & Rewards Programs, & OTC Items</li>
    </ul>
  </div>

  <div class="coverageOptionModal" #coverageOption2Modal>
    <div class="option-heading">
      <span>Option 2</span>
      <h5>Medicare Advantage Coverage</h5>
    </div>
    <h5 class="fWeight-500 fColor-base">The Big Picture:</h5>
    <ul>
      <li>You “Replace” Original Medicare As Your Primary</li>
      <li>You Have a “Network” of Healthcare Providers: Plans Are HMOs or PPOs</li>
      <li>More Out-of-Pocket Risk For Major-Medical, But Capped For The Year</li>
      <li>Low Or No Premiums: Usually $0, And Almost Always Under $60/Month</li>
      <li>Your Drug Coverage Is Included: Wrapped In With Your Other Benefits</li>
      <li>Your Dental And Vision Coverage Is Usually Included</li>
    </ul>

    <mat-divider class="dashed"></mat-divider>

    <p><strong>Replace:</strong> Medicare Part A and B with a privatized HMO or PPO plan that will serve as your new
      total healthcare coverage that includes you drug coverage. These plans often include dental
      and vision coverage, and often additional benefits like Over-the-Counter items, transportation,
      and fitness & wellness programs.
    </p>

    <app-insurance-coverage-box [isDefault]="false" class="my-5 d-block"></app-insurance-coverage-box>
    
    <h5 class="fColor-base">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">Like</span> About This Coverage?</h5>
    <ul class="checklist">
      <li>Low or No Monthly Premiums, & Includes Your Drug Coverage</li>
      <li>Streamlined Approach With All Healthcare Services Covered Under One Plan</li>
      <li>Most Plans Include Dental & Vision & Additional Health & Wellenss Benefits</li>
      <li>Can Include Extra Benefits LIke Gym Memberships, Transportation, & OTC Items</li>
    </ul>

    <mat-divider class="dashed"></mat-divider>

    <h5 class="fColor-base mt-5">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">NOT Like</span> About This Coverage?</h5>
    <ul class="xlist">
      <li>More Potential Out-of-Pocket Costs</li>
      <li>Plans Involve a Network, & Potentially Referrals & Pre-Certifications</li>
      <li>Plans Change Annually So There is More Oversight Needed</li>
      <li>Possibly Less Convenience Due To Networks & OUt-of-Pocket Bills</li>
    </ul>
  </div>
</div>

