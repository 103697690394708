<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain">
  <app-header-b></app-header-b>
  <div class="container banner-wrapper">
    <div class="row">
      <div class="col-12">
        <h1 class="medium banner-main-title text-center">
          Medicare Advantage Marketplace
        </h1>
      </div>
    </div>
  </div>
</div>
<div class="banner-pointer-wrapper">
  <i class="fa fa-arrow-down"></i>
  <div class="banner-pointer-background"></div>
</div>
<!--END BANNER-->

<!-- input quote section -->
<div class="container-fluid">

  <div class="col-12 tt-med-add-input-top tt-medadd-bg" style="background-image: url('/assets/img/home/new/bg4.png');background-repeat: repeat-y;" [ngClass]="{'d-none' : isShowZipCode == true}">

    <!-- Fully Customize my Plan -->
    <div class="med-ad-selector-wrapper">
      <section class="container med-ad-selector-container">
        <div class="row justify-content-center med-ad-selector-heading">
          <h2 class="text-center">MAPD Plan Customizer for Health & Fitness Enthusiasts</h2>
          <p style="font-size:1.3em">Select The Criteria That You Would Like To Search Plans.
            <i *ngIf="!viewOnly" class="fa fa-info global-info-icon-first ml-2"
              (click)="showCriteriaModal(selectCriteria,900)"></i>
          </p>
        </div>
        <div class="row selector-criteria-buttons">
          <div class="selector-criteria-button-inside">
            <button class="btn btn-first small" [ngClass]="{'active':mustMatchAllActive}" (click)="mustMatchAll()">Must
              Match All
              <i class="fa fa-check"></i>
            </button>
            <button class="btn btn-first small" [ngClass]="{'active':mustMatchAnyActive}" (click)="mustMatchAny()">Must
              Match Any
              <i class="fa fa-check"></i>
            </button>
            <button class="btn btn-first small" [ngClass]="{'active':prioritizeActive}"
              (click)="prioritize()">Prioritize
              <i class="fa fa-check"></i>
            </button>
          </div>
        </div>
        <div class="row d-none">
          <input type="radio" id="matchAllID" name="selector-type" class="form-control" value="match-all" checked
            > Must Match all
          <input type="radio" id="matchAnyID" name="selector-type" class="form-control" value="match-any"
            > Must Match match-any
          <input type="radio" id="prioritizeID" name="selector-type" class="form-control" value="prioritize"
            > Prioritize
        </div>
        <div class="row mb-5">
          <div class="col-12 col-sm-6 med-ad-selector-img d-flex justify-content-md-center align-items-center mb-3">
            <img src="assets/img/medicare-advantage/selector/plan-selector-monthly-premium.png">
          </div>
          <div
            class="col-12 col-sm-6 med-ad-selector-selection d-flex justify-content-center align-items-center text-sm-left text-md-left text-center">
            <div class="md-ad-selector-selection-inside right">
              <h3 class="justify-content-sm-start justify-content-center">Health, Wellness, & Fitness Benefits
                <i class="fa fa-info global-info-icon-first ml-2"
                  *ngIf="!viewOnly"></i></h3>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" [disabled]="monthlyPremiumIsChecked[6]"
                  class="custom-control-input"
                  id="mp-customCheck6">
                <label class="custom-control-label" for="mp-customCheck6">Plans with Free Gym Membership</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" [disabled]="monthlyPremiumIsChecked[6]"
                  class="custom-control-input"
                  id="mp-customCheck6">
                <label class="custom-control-label" for="mp-customCheck6">Plans with Additional Wellness
                  Benefits</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" [disabled]="monthlyPremiumIsChecked[6]"
                  id="mp-customCheck6">
                <label class="custom-control-label" for="mp-customCheck6">Plans with Coverage for Over-the-Counter
                  Items</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" [disabled]="monthlyPremiumIsChecked[6]"
                  id="mp-customCheck6">
                <label class="custom-control-label" for="mp-customCheck6">Plans with Fitness Wearables</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-5 flex-column-reverse flex-sm-row">
          <div
            class="col-12 col-sm-6 med-ad-selector-selection d-flex justify-content-center align-items-center text-md-right text-center mb-3">
            <div class="md-ad-selector-selection-inside right">
              <h3 class="justify-content-sm-end justify-content-center">
                Additional Features
                <i class="fa fa-info global-info-icon-first ml-2"
                  *ngIf="!viewOnly"></i>
              </h3>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="9"
                  class="custom-control-input" id="oc-customCheck9">
                <label class="custom-control-label checkbox-right" for="oc-customCheck9">Plans with Dental and
                  Vision</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="1"
                  class="custom-control-input" id="oc-customCheck1">
                <label class="custom-control-label checkbox-right" for="oc-customCheck1">Plans with Strong Acupuncture
                  Coverage</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="3"
                  class="custom-control-input" id="oc-customCheck3">
                <label class="custom-control-label checkbox-right" for="oc-customCheck3">Plans with Strong Chiropractic
                  Care Coverage</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="4"
                  class="custom-control-input" id="oc-customCheck4">
                <label class="custom-control-label checkbox-right" for="oc-customCheck4">Plans with Low Physical Therapy
                  Copays</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="5"
                  class="custom-control-input" id="oc-customCheck5">
                <label class="custom-control-label checkbox-right" for="oc-customCheck5">Plans with Low MRI Costs
                </label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="5"
                  class="custom-control-input" id="oc-customCheck5">
                <label class="custom-control-label checkbox-right" for="oc-customCheck5">Plans with Low X-Ray
                  Costs</label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 med-ad-selector-img d-flex justify-content-center align-items-center">
            <img src="assets/img/medicare-advantage/selector/plan-selector-other-categories.png">
          </div>
        </div>



        <div class="row mb-5 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-6 med-ad-selector-img d-flex justify-content-md-center align-items-center">
            <img src="assets/img/medicare-advantage/selector/plan-selector-additional-benefits.png">
          </div>
          <div
            class="col-12 col-sm-6 med-ad-selector-selection d-flex justify-content-center align-items-center text-md-left text-center mb-3">
            <div class="md-ad-selector-selection-inside left">
              <h3 class="justify-content-sm-start justify-content-center">
                Plan Type
                <i class="fa fa-info global-info-icon-first ml-2"
                  *ngIf="!viewOnly"></i>
              </h3>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="1"
                  class="custom-control-input" id="ab-customCheck1">
                <label class="custom-control-label" for="ab-customCheck1">HMO Plans</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="3" 
                  class="custom-control-input" id="ab-customCheck3">
                <label class="custom-control-label" for="ab-customCheck3">PPO Plans</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="5"
                  class="custom-control-input" id="ab-customCheck5">
                <label class="custom-control-label" for="ab-customCheck5">Regional PPO Plans</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="7"
                  class="custom-control-input" id="ab-customCheck7">
                <label class="custom-control-label" for="ab-customCheck7">Plans Without Drug Coverage</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" value="6"
                  class="custom-control-input" id="ab-customCheck6">
                <label class="custom-control-label" for="ab-customCheck6">Plans Rated 3 Stars or Above</label>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-12 text-center d-flex justify-content-center">
            <button class="btn btn-third btn-back mx-1 btn-back" (click)="onClickZipCodeBackParent()">
              <!--<i class="fas fa-angle-double-left"></i>-->
              <span>Back</span>
            </button>
            &nbsp;
            &nbsp;
            &nbsp;
            <button (click)="selectorProceed()"
              class="btn btn-first ml-4">Proceed</button>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- end Fully Customize my Plan -->


  <div class="view-wrapper d-flex justify-content-around align-items-center" *ngIf="isShowZipCode">
    <div class="view-left">
    </div>
    <div class="view-center">
      <h4 class="form-input-heading">
        Great! <br><br> Last step and your custom plan selection will be right up!
    </h4>
  
    <form class="col-12">
        <div class="row justify-content-center">
            <!-- zipcode -->
            <div class="form-group col-12 mx-auto">
                <label for="zipCode">Zip Code</label>
                <input type="text" name="zipcode" class="form-control" id="zipCode" autofocus>
            </div>
  
            <!-- planyear -->
            <div class="form-group col-12 plan-year-wrapper mx-auto">
                <label class="col-12">Plan Year</label>
                <div class="col-12">
                    <div class="custom-control custom-radio custom-control-inline mr-5">
                        <input type="radio" id="planYear2020" value="2020" name="planYear" class="custom-control-input">
                        <label class="custom-control-label" for="planYear2020">2020</label>
                    </div>
                    <div class="custom-control custom-radio  custom-control-inline">
                        <input type="radio" id="planYear2021" value="2021" name="planYear" class="custom-control-input">
                        <label class="custom-control-label" for="planYear2021">2021</label>
                    </div>
                </div>
            </div>
        </div>
  
  
        <div class="row mt-4">
            <div class="col-12 d-flex justify-content-center">
                <div class="form-group d-flex justify-content-between">
                    <button class="btn btn-third btn-back mx-1 btn-back" (click)="onClickZipCodeBackButton()" *ngIf="!isShowCustomPlan">
                        <!--<i class="fas fa-angle-double-left"></i>-->
                        <span>Back</span>
                    </button>
                    <button class="btn btn-third btn-back mx-1 btn-back"
                            (click)="onClickCustomPlanBack()" *ngIf="isShowCustomPlan">
                        <!--<i class="fas fa-angle-double-left"></i>-->
                        <span>Back</span>
                    </button>
                    <button class="btn btn-first getMyQuoteBtn">
                        <!-- Get My Prices -->
                        See My Plans
                        <!--<i class="fas fa-angle-double-right ml-2"></i>-->
                    </button>
                </div>
            </div>
        </div>
    </form>
    </div>
    <div class="view-right">
      <!-- <img src="./assets/img/common/old-woman-with-basket-medigap.png" alt="senior citizen holding a basket"> -->
    </div>
  </div>

</div>
<!-- end input quote section -->

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>








<div #preferenceRatingModal class="d-none">
  <div class="preference-rating-wrapper">
    <h5>Your Preference Rating</h5>
    <div class="preference-rating">
      <div class="preference-rating-inside">
        <span class="top-label">Lowest <br> Importance</span>
        <input type="radio" id="pr1" name="pr-level" class="form-control" value="1">
        <span class="bottom-label">1</span>
      </div>
      <div class="preference-rating-inside">
        <span class="top-label visibility-hidden">hidden</span>
        <input type="radio" id="pr2" name="pr-level" class="form-control" value="2">
        <span class="bottom-label">2</span>
      </div>
      <div class="preference-rating-inside">
        <span class="top-label">Average <br>Importance</span>
        <input type="radio" id="pr3" name="pr-level" class="form-control" value="3">
        <span class="bottom-label">3</span>
      </div>
      <div class="preference-rating-inside">
        <span class="top-label visibility-hidden">hidden</span>
        <input type="radio" id="pr4" name="pr-level" class="form-control" value="4">
        <span class="bottom-label">4</span>
      </div>
      <div class="preference-rating-inside">
        <span class="top-label"> Most <br>Importance</span>
        <input type="radio" id="pr5" name="pr-level" class="form-control" value="5" checked>
        <span class="bottom-label">5</span>
      </div>
    </div>
  </div>
</div>

<!-- Modals -->
<div class="d-none">
  <div #selectCriteria>
    <div class="custom-selector-modal-content criteria-modal">
      <h3 class="text-center" style="font-size:35px;">TapTap Medicare Explains:</h3>
      <mat-divider class="mt-4 mb-5"></mat-divider>
      <h4 class="text-center" style="font-size:29px;">You have 3 methods to customize your selection of plans</h4>
      <h6 class="uppercase mt-5">Must Match All:</h6>
      <img src="./assets/img/common/girl-counselor-pointing-pen.png" alt=""
        style="float:right;max-width: 300px;margin-left:1.5em;background: #fff;">
      <p class="fWeight-500">This method allows you to make sure you are only seeing plans that include your “Must
        Haves”. With this method you can select as many features from each section as you’d like, however, the plans
        shown will need to meet ALL of the plan criteria you’ve selected. Plans that just meet one or some of your
        criteria, but not all, will not be shown.</p>
      <ul>
        <li>Additionally, some criteria are incompatible with others. For example, you cannot select the criteria “HMO
          Plans with $0 Premium” and “PPO with Premium of $20 of Less” since all plans are either an HMO or a PPO, but
          cannot be both.”</li>
        <li>Since you are customizing your plan with each selection, the more criteria that you select will result in
          less plans being displayed in your results, since the only plans shown must meet ALL of the criteria you’ve
          selected.</li>
      </ul>

      <mat-divider class="mt-5 mb-5"></mat-divider>

      <h6 class="uppercase">Must Match Any:</h6>
      <p class="fWeight-500">This method allows you to see a broader selection of plans that include at least some of
        your desired plan preferences. With this method you can identify all the plan features from each section that
        you’d like and TapTap will display all the plans that meet ANY of the criteria you’ve selected. </p>

      <mat-divider class="mt-5 mb-5"></mat-divider>

      <h6 class="uppercase">Prioritize:</h6>
      <p class="fWeight-500">This method allows you to “Rank” or “Prioritize” the plan features in the order of their
        important to you. With this method you to see a broader selection of plans that will be displayed according to
        how you have ranked your preferences.</p>
      <ul>
        <li>Once select a feature by clicking the check box next to any individual feature, then you will see a little
          popup box that allows you to identify your "Preference Rating" for that particular feature</li>
        <li>5 is the Highest (Most Important) Preference Rating, and 1 is the Lowest (Least Important) Preference Rating
        </li>
      </ul>

      <mat-divider class="mt-5 mb-5"></mat-divider>
      <p class="text-center">Of course, you may always give us a call at 1(800)215-5108 to help you with your plan
        customization.</p>

    </div>
  </div>
  <div #monthlyPremiums>
    <p>This section gives you to customize your plans by their
      monthly premium amount. This monthly premium is what
      is charged to you when you are enrolled in the plan.
      *If you receive “Extra Help” from the government then
      you may pay less than the stated amount.</p>
  </div>
  <div #outOfPocketCost>
    <p>
      This section gives you to customize your plans by their
      out-of-pocket costs, for specific healthcare services,
      as well as for the plan as a whole. You may want to use
      this section if you have a specific procedure or course
      of treatments coming up that you would like to minimize
      your costs for. Plans shown for low out-of-pocket for a
      specific type of service is based relative to the
      out-of-pocket for other available plans.
    </p>
  </div>
  <div #extraBenefits>
    <p>This section gives you to customize your plans by some
      of the popular additional coverage benefits that
      Medicare Advantage plans can offer. “Plans with
      Comprehensive Dental Coverage” provide dental coverage
      beyond just the routine and preventive services that
      are more common.</p>
  </div>
  <div #additionalFeatures>
    <p>This section gives you the ability to further customize
      the plan options that would be most suitable for you.
      For example, “Part B Premium Reduction” will show plans
      that have $0 premium as well as reduce the Medicare
      premium you are charged for Part B”.</p>
  </div>
</div>


<div class="d-none" #formHeadingHTML>
  Great! <br><br> Last step and your custom plan selection will be right up!
</div>
