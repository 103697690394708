<div class="container-fluid header-banner-wrapper">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">My Dental, Vision, & Hearing Marketplace
					</h1>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">        
        <img src="./assets/img/learn-more/Asset 1.png" class="banner-main-image">
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>

<div class="container mt-5">
  <div class="dental-coverage-info">
    <div class="dental-heading-btn-content">
      <h2 class="fColor-black text-center">Can Be Used With Either:</h2>
      <div class="dental-btn-group">
        <div class="overview-coverage-4-boxes-wrapper-heading background-remover"><span>Coverage Option 1</span></div>
        <div class="overview-coverage-4-boxes-wrapper-heading background-remover"><span>Coverage Option 2</span></div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <h5 class="color-primary">Overview</h5>
  <p>
    As a Medicare beneficiary,you need clarity when it comes to your dental healthcare, and how to deal with the costs associated with proactive, and reactive, dental health.
  </p>
  <p>
    Dental costs can be substantial, and your overall dental health can significantly impact your overall health and wellbeing.
  </p>
  <p>
    <strong class="color-primary fWeight-500">How You Get Your Dental Coverage</strong> is partially dependent on whether you have Medicare Coverage <strong class="fWeight-500">Option 1</strong> or <strong class="fWeight-500">Option 2.</strong>
  </p>

    <div class="coverage-content">
      <div class="overview-coverage-4-boxes-wrapper-heading mr-3"><span>Option 1</span><h5>Medicare Supplement Coverage</h5></div>
      <div class="overview-coverage-4-boxes-wrapper-heading ml-3"><span>Option 2</span><h5>Medicare Advantage Coverage</h5></div>
    </div>
</div>

<div class="container mt-5">
  <div class="informative-content">
    <h2 class="color-primary">When Does My Major Medical Pay For Dental?</h2>
    <p>
      Original Medicare (Part A and B) will only cover dental services when they are considered "Major or Restorative" AND "Medically Necessary" for a major medical healthcare procedure that is covered under Medicare Part A or B.
    </p>
    <p>
      For example, an oral examination before a heart surgery, or a reconstructive jaw surgery, would be covered by Medicare (and therefore also a Medigap policy).
    </p>
  </div>
</div>

<div class="container mt-5">
  <div class="informative-content">
    <h2 class="color-primary">Dental Coverage With Option 1 and Option 2</h2>
    <p>
      However, <strong class="fWeight-500">Original Medicare (Part A and B)</strong> does not cover any of the typical preventive or major dental services that most people think of when it comes to dental healthcare, and therefore, Medicare Supplement plans do not either.
    </p>
    <p>
      Many <strong class="fWeight-500">Medicare Advantage plans (Part C)</strong> will provide some dental benefits, however, these benefits can be limited, may not cover major procedures, and/or cover a small percentage of costs, leaving a major need for additional protection against the risk of out-of-pocket costs.
    </p>
  </div>
</div>

<div class="container mt-5">
  <div class="informative-content">
    <h2 class="color-primary">Helpful Terms & Definitions</h2>
    <p>
      <strong class="fWeight-500 color-primary">Dental Insurance Often Includes Vision & Hearing Coverage</strong> , and these policies typically categorize their coverage benefits into these services:
    </p>
  </div>
</div>

<section class="px-5">
  <div class="container d-flex justify-content-center">
    <div class="criteria-main-border">
      <div class="helpful-criteria-wrapper">
        <div class="helpful-criteria">
          <span>Preventive Dental Services</span>
          <span class="border-top-lightgray">Typically includes twice-per-year cleanings, exams, and routine X-Rays.</span>
        </div>
        <div class="helpful-criteria">
          <span>Basic Dental Services</span>
          <span>Typically includes fillings, extractions, and additional diagnostic X-Rays.</span>
        </div>
        <div class="helpful-criteria">
          <span>Major (aka Comprehensive) Dental Services</span>
          <span>Typically includes bridges, crowns, full or partial dentures, full mouth extractions, and root canals (and with some policies, dental implants).</span>
        </div>
        <div class="helpful-criteria">
          <span>Vision Services</span>
          <span>Typically includes routine eye exams, and the cost of eyeglass, contacts, and lenses.</span>
        </div>
        <div class="helpful-criteria">
          <span>Hearing Services</span>
          <span>Typically includes hearing exams and the cost of new hearing aids, and hearing aid repairs.</span>
        </div>
        <div class="helpful-criteria">
          <span>Deductible</span>
          <span>This is the total amount you must pay each policy year before your benefits start (some policies eliminate this for preventive services).</span>
        </div>
        <div class="helpful-criteria">
          <span>Annual Maximum</span>
          <span>This is the total amount your policy will pay out in a particular “Policy Year” - which is the 12 month period that starts on the day you begin your policy.</span>
        </div>
      </div>

    </div>

  </div>
</section>

<div class="container mt-5">
  <h5 class="color-primary">Overview</h5>
  <p>
    Dental Insurance coverage can vary substantially from one policy to the next. There are important differences between what, when, how much, and how easily specific dental services covered.
  </p>
  <p>
    As a result, the right policy for one person may not be the best policy for another, and therefore it helps to individually evaluate which coverage will be most beneficial for you.
  </p>
</div>

<div class="container mt-5">
  <h4 class="text-center color-primary mb-4">Helpful Criteria To Evaluate a Dental Insurance Plan:</h4>
  <div class="criteria-wrapper">
    <div class="criteria-item"> <span>Criteria 1</span> <span>Are Your Needed Procedures Covered?</span></div>
    <div class="criteria-item"> <span>Criteria 2</span> <span>Cost Competitiveness</span></div>
    <div class="criteria-item"> <span>Criteria 3</span> <span>Percentage of Costs Covered</span></div>
    <div class="criteria-item"> <span>Criteria 4</span> <span>Timeline of Waiting Periods</span></div>
    <div class="criteria-item"> <span>Criteria 5</span> <span>Annual Maximum Payout Amount</span></div>
    <div class="criteria-item"> <span>Criteria 6</span> <span>Does it Include Vision and Hearing Aid Coverage?</span></div>
    <div class="criteria-item"> <span>Criteria 7</span> <span>Is There a Deductible?</span></div>
    <div class="criteria-item"> <span>Criteria 8</span> <span>Convenience of The Claims Process</span></div>
    <div class="criteria-item"> <span>Criteria 9</span> <span>Does it Have a Network? Can You Use Your Dentist?</span></div>
    <div class="criteria-item"> <span>Criteria 10</span> <span>Ratings and Reputation of The Insurance Company</span></div>
  </div>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>