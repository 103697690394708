import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pdp-version1',
  templateUrl: './version1.component.html',
  styleUrls: ['./version1.component.scss']
})
export class Version1Component implements OnInit {

  @Input() results;
  @Input() logComparedKeys;
  @Input() controls;
  @Input() base_img_loc;
  @Input() loading;
  @Input() isBlueBgHeading = false;
  @Input() part_d_id;
  
  @Output() showModalPharmacyEmit = new EventEmitter;
  @Output() showModalAdditionalInsightsEmit = new EventEmitter;
  @Output() showModalViewGeneralCopaysPerTierEmit = new EventEmitter;
  @Output() showModalApplyOnlineEmit = new EventEmitter;
  @Output() showModalApplyOnThePhoneEmit = new EventEmitter;
  @Output() addToCompareFuncEmit = new EventEmitter;
  constructor() { }

  ngOnInit() {
  }

  getConvertedRate(rate) {
      return parseFloat((rate * .01).toFixed(2));
  }

  replaceSpaceWith(string, char = '-') {
    if(string)
        return string.replace(/ /g, char).replace(/,/g, '').replace(/\./g, '').toLowerCase();
  }

  companyLogoCheck(event){
      $(event.target).parent().find('.no-img-plan-name').addClass('d-block').removeClass('d-none');
  }

  showDrugModal(){

  }

  clickPlanDocsResources(event){
      console.log(event);
      const planDocBtn = event.target;

      let element__planDocument = $(planDocBtn).parents('.pdp-version-5').find('.plan-documents');
          if($(element__planDocument).hasClass("d-none")){
              $(element__planDocument).addClass('d-flex').removeClass('d-none');
              if ( planDocBtn.nodeName == "BUTTON" ) {
                  $(planDocBtn).html('<i class="fa fa-file-text-o mr-2" style="color:#818181 !important" aria-hidden="true"></i> Hide Docs & Resources');
              }else{
                  $(planDocBtn).parent('button').html('<i class="fa fa-file-text-o mr-2" aria-hidden="true"></i> Hide Docs & Resources');
              }
          }else{
              $(element__planDocument).addClass('d-none').removeClass('d-flex');
              if ( planDocBtn.nodeName == "BUTTON" ) {
                  $(planDocBtn).html('<i class="fa fa-file-text-o mr-2" aria-hidden="true"></i> Show Docs & Resources');
              }else{
                  $(planDocBtn).parent('button').html('<i class="fa fa-file-text-o mr-2" aria-hidden="true"></i> Show Docs & Resources');
              }
      }
  }

  onclick_doctorDrugAnalysis(key){
    let ddi = document.querySelector('#doctors_drugs_id_'+key);

    let line2 = document.querySelector('#line2_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    ddi.classList.toggle('d-flex');

    line2.classList.toggle('d-flex');
    buttonColor2.classList.toggle('button-toggled');
    
  }

  onClickPharmacyNetwork(result) {
    this.showModalPharmacyEmit.emit(result);
  }

  onClick_AdditionalInsights(result){
    this.showModalAdditionalInsightsEmit.emit(result);
  }

  onClick_ViewGeneralCopaysPerTier(result){
    this.showModalViewGeneralCopaysPerTierEmit.emit(result);
  }

  onClick_ApplyOnline(result,i){
    this.showModalApplyOnlineEmit.emit({result, i});
  }

  onClick_ApplyOnThePhone(result){
    this.showModalApplyOnThePhoneEmit.emit(result);
  }

  addToCompareFunc(result,event,x){
    this.addToCompareFuncEmit.emit({result,event,x});
  }

}
