<section class="contact-top">
	<div class="container-fluid">
		<div class="view-wrapper d-flex justify-content-around align-items-center">
			<div class="view-left">
			</div>
			<div class="view-center">
				<h4 class="form-input-heading">
					We Will Be Happy to Help You
					With Your Auto Insurance
				</h4>
				<form>
					<div class="form-row">
						<div class="form-group col-md-6">
							<label for="firstName">First name</label>
							<input type="text" class="form-control" id="firstName" [formControl]="controls.first_name">
						</div>
						<div class="form-group col-md-6">
							<label for="lastName">Last name</label>
							<input type="text" class="form-control" id="lastName" [formControl]="controls.last_name">
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-md-6">
							<label for="email">Email</label>
							<input type="email" class="form-control" id="email" [formControl]="controls.email">
						</div>
						<div class="form-group col-md-6">
							<label for="phoneNumber">Phone</label>
							<input type="text" class="form-control" id="phoneNumber" [formControl]="controls.phone">
						</div>
					</div>
					<div class="form-group">
						<label for="exampleFormControlTextarea1">Message</label>
						<textarea class="form-control" id="exampleFormControlTextarea1" rows="5" [formControl]="controls.message"></textarea>
					</div>
					<div class="row justify-content-around justify-content-md-between align-items-center">
						<!-- <div class="card-body">
									<div #recaptcha ></div>
								</div> -->
						<!-- new recaptcha template - stephen 11-13-2019 -->
						<recaptcha [formControl]="taptapRecaptcha" (scriptLoad)="onScriptLoad()"
							(scriptError)="onScriptError()"
							style="transform: scale(0.9);-moz-transform: scale(0.9);-webkit-transform: scale(0.9);"
							class="mt-2"></recaptcha>
						<!-- end new recaptcha template -->

						<button class="btn btn-first mt-2" (click)="onInquire()">Send Message Now </button>
                
          </div>
				</form>
			</div>
			<div class="view-right">
				<!-- <img src="./assets/img/common/old-woman-with-a-phone.png" alt="senior citizen holding a basket"> -->
			</div>
		</div>
	</div>
</section>