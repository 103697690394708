import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webinar-v2',
  templateUrl: './webinar-v2.component.html',
  styleUrls: ['./webinar-v2.component.scss']
})
export class WebinarV2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  signUpToWebinar(){
    $('html,body').animate({
        scrollTop: $("#section_webinar_save_your_seat").offset().top},
        'slow');
  }

}
