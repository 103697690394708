<!-- STAR REVIEW, MESSAGE, VERIFY REVIEW FIELD SECTION -->
<div class="review-wrapper-modal" *ngIf="!showThankYou">
  <div class="review-wrapper-modal-character">
    <img src="./assets/img/common/contact-us-woman-pink-dress.png" alt="">
  </div>
  <div class="review-wrapper-modal-content">
    <h5 class="text-center fWeight-600 uppercase mb-4">Review This {{ insuranceType }} Carrier</h5>

    <div class="row mb-4" *ngIf="showDefaultView && !showLoginSection">
        <img onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';" src="{{ logo }}" class="modal-companyLogo">
        
        <div class="col-12 star-rating d-flex justify-content-center my-4">
          <div class="stars">
            <i class="cursor-pointer fa fColor-orange {{insurance_total_score > 0 && insurance_total_score < 1  ? 'fa-star-half-o' : insurance_total_score >= 1 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('total',1)"></i>
            <i class="cursor-pointer fa fColor-orange {{insurance_total_score > 1 && insurance_total_score < 2  ? 'fa-star-half-o' : insurance_total_score >= 2 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('total',2)"></i>
            <i class="cursor-pointer fa fColor-orange {{insurance_total_score > 2 && insurance_total_score < 3  ? 'fa-star-half-o' : insurance_total_score >= 3 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('total',3)"></i>
            <i class="cursor-pointer fa fColor-orange {{insurance_total_score > 3 && insurance_total_score < 4  ? 'fa-star-half-o' : insurance_total_score >= 4 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('total',4)"></i>
            <i class="cursor-pointer fa fColor-orange {{insurance_total_score > 4 && insurance_total_score < 5  ? 'fa-star-half-o' : insurance_total_score >= 5 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('total',5)"></i>
          </div>
          <span class="score_counter">( {{ insurance_total_score }} )</span>
        </div>
    
        <div class="col-12 star-rating" [class.error]="!star_rating_valid">
          <span>Customer Service Score: </span>
          <div class="stars">
            <i class="cursor-pointer fa fColor-orange {{customer_service_score > 0 && customer_service_score < 1  ? 'fa-star-half-o' : customer_service_score >= 1 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('service',1)"></i>
            <i class="cursor-pointer fa fColor-orange {{customer_service_score > 1 && customer_service_score < 2  ? 'fa-star-half-o' : customer_service_score >= 2 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('service',2)"></i>
            <i class="cursor-pointer fa fColor-orange {{customer_service_score > 2 && customer_service_score < 3  ? 'fa-star-half-o' : customer_service_score >= 3 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('service',3)"></i>
            <i class="cursor-pointer fa fColor-orange {{customer_service_score > 3 && customer_service_score < 4  ? 'fa-star-half-o' : customer_service_score >= 4 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('service',4)"></i>
            <i class="cursor-pointer fa fColor-orange {{customer_service_score > 4 && customer_service_score < 5  ? 'fa-star-half-o' : customer_service_score >= 5 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('service',5)"></i>
          </div>
          <span class="score_counter">( {{ customer_service_score }} )</span>
        </div>
        <div class="col-12 star-rating" [class.error]="!star_rating_valid">
          <span>Consumer Clarity Score: </span>
          <div class="stars">
            <i class="cursor-pointer fa fColor-orange {{consumer_clarity_score > 0 && consumer_clarity_score < 1  ? 'fa-star-half-o' : consumer_clarity_score >= 1 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('clarity',1)"></i>
            <i class="cursor-pointer fa fColor-orange {{consumer_clarity_score > 1 && consumer_clarity_score < 2  ? 'fa-star-half-o' : consumer_clarity_score >= 2 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('clarity',2)"></i>
            <i class="cursor-pointer fa fColor-orange {{consumer_clarity_score > 2 && consumer_clarity_score < 3  ? 'fa-star-half-o' : consumer_clarity_score >= 3 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('clarity',3)"></i>
            <i class="cursor-pointer fa fColor-orange {{consumer_clarity_score > 3 && consumer_clarity_score < 4  ? 'fa-star-half-o' : consumer_clarity_score >= 4 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('clarity',4)"></i>
            <i class="cursor-pointer fa fColor-orange {{consumer_clarity_score > 4 && consumer_clarity_score < 5  ? 'fa-star-half-o' : consumer_clarity_score >= 5 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('clarity',5)"></i>
          </div>
          <span class="score_counter">( {{ consumer_clarity_score }} )</span>
        </div>
        <div class="col-12 star-rating" [class.error]="!star_rating_valid">
          <span>Price Stability Score: </span>
          <div class="stars">
            <i class="cursor-pointer fa fColor-orange {{price_stability_score > 0 && price_stability_score < 1  ? 'fa-star-half-o' : price_stability_score >= 1 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('stability',1)"></i>
            <i class="cursor-pointer fa fColor-orange {{price_stability_score > 1 && price_stability_score < 2  ? 'fa-star-half-o' : price_stability_score >= 2 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('stability',2)"></i>
            <i class="cursor-pointer fa fColor-orange {{price_stability_score > 2 && price_stability_score < 3  ? 'fa-star-half-o' : price_stability_score >= 3 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('stability',3)"></i>
            <i class="cursor-pointer fa fColor-orange {{price_stability_score > 3 && price_stability_score < 4  ? 'fa-star-half-o' : price_stability_score >= 4 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('stability',4)"></i>
            <i class="cursor-pointer fa fColor-orange {{price_stability_score > 4 && price_stability_score < 5  ? 'fa-star-half-o' : price_stability_score >= 5 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('stability',5)"></i>
          </div>
          <span class="score_counter">( {{ price_stability_score }} )</span>
        </div>
        <div class="col-12 star-rating" [class.error]="!star_rating_valid">
          <span>Overall Score: </span>
          <div class="stars">
            <i class="cursor-pointer fa fColor-orange {{overall_score > 0 && overall_score < 1  ? 'fa-star-half-o' : overall_score >= 1 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('overall',1)"></i>
            <i class="cursor-pointer fa fColor-orange {{overall_score > 1 && overall_score < 2  ? 'fa-star-half-o' : overall_score >= 2 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('overall',2)"></i>
            <i class="cursor-pointer fa fColor-orange {{overall_score > 2 && overall_score < 3  ? 'fa-star-half-o' : overall_score >= 3 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('overall',3)"></i>
            <i class="cursor-pointer fa fColor-orange {{overall_score > 3 && overall_score < 4  ? 'fa-star-half-o' : overall_score >= 4 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('overall',4)"></i>
            <i class="cursor-pointer fa fColor-orange {{overall_score > 4 && overall_score < 5  ? 'fa-star-half-o' : overall_score >= 5 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('overall',5)"></i>
          </div>
          <span class="score_counter">( {{ overall_score }} )</span>
        </div>        
        <div class="col-12 text-center invalid-feedback d-block" *ngIf="!star_rating_valid">
          Please Provide Star Rating
        </div>
        
        <div class="col-12 d-flex justify-content-center mt-5">          
          <button class="btn btn-first" (click)="next()">Next</button>
        </div>
    </div>
    
    <div class="row mb-4" *ngIf="showMessageView && !showLoginSection">
      <div class="col-12">
        <p class="form-label text-center">What Insights or Advice Would You Give To Others About This Carrier? <span>*</span></p>
        <textarea rows="10" id="review_message" [formControl]="messageFormControl" name="reviewMessage" [class.error]="messageFormControl.hasError('required') && submitReviewFirstBtnClicked"></textarea>
        
        <!-- ERROR MESSAGE -->
        <div class="invalid-feedback d-block" *ngIf="messageFormControl.hasError('required') && submitReviewFirstBtnClicked">
          Review Message is required
        </div>
        <!-- END ERROR MESSAGE -->
      </div>  
      <div class="col-12 d-flex justify-content-center mt-5">   
          <button class="btn btn-third mr-2" (click)="back_second()">Back</button> 
          <button class="btn btn-first" (click)="submit_review_first()">Submit Review</button>
        </div>
    </div>

    <div class="row mb-4" *ngIf="showVerifyReview && !isLoggedIn">
      <p class="mx-auto mb-4">Verify Your Review</p>
      <div class="col-12">
        <i (click)="loginUsingExternalParty('facebook')" title="Login to Facebook" class="fab fa-facebook" style="font-size: 30px; cursor:pointer; color: #4267b2;"></i> &nbsp;
        <i (click)="loginUsingExternalParty('twitter')" title="Login to Twitter"  class="fab fa-twitter" style="font-size: 30px; cursor:pointer; color: #55aded;"></i> &nbsp;
        <i title="Login to Google" class="fab fa-google" style="font-size: 30px; cursor:pointer; color: #4285f4;"></i> &nbsp;
        <img (click)="showLoginBpModal(1)" title="Login as Member" src="../assets/1favicon_126i.ico" width="50px" height="50px"  style="cursor:pointer; margin-top:-15px;"/> &nbsp;
        <!--<img (click)="login(5)" title="Login as Agent" src="../assets/img/plan-letter-modal-icon.png" width="40px" height="40px"  style="cursor:pointer; margin-top:-11px;"/>-->
      </div>
      <div class="form-group">
        <label>Full Name</label>
        <input class="form-control" [(ngModel)]="verify_review_fullname" [formControl]="verifyFullNameFormControl" [class.error]="verifyFullNameFormControl.hasError('required') && submitReviewSecondBtnClicked">
        <!-- ERROR MESSAGE -->
        <div class="invalid-feedback d-block" *ngIf="verifyFullNameFormControl.hasError('required') && submitReviewSecondBtnClicked">
          Full Name is required
        </div>
        <!-- END ERROR MESSAGE -->
      </div>
      <div class="form-group">
        <label>Email</label>
        <input class="form-control" type="email" [(ngModel)]="verify_review_email" [formControl]="verifyEmailFormControl" [class.error]="verifyEmailFormControl.hasError('required') && submitReviewSecondBtnClicked">
        <!-- ERROR MESSAGE -->
        <div class="invalid-feedback d-block" *ngIf="verifyEmailFormControl.hasError('required') && submitReviewSecondBtnClicked">
          Email is required
        </div>

        <div class="invalid-feedback d-block" *ngIf="verifyEmailFormControl.hasError('pattern') && submitReviewSecondBtnClicked">
          Email Pattern is Invalid
        </div>

        
        <!-- END ERROR MESSAGE -->
      </div>
      <div class="form-group d-none">
        <label>Phone Number</label>
        <input class="form-control" matInput [(ngModel)]="verify_phone_email" [formControl]="verifyPhoneFormControl" required>
      </div>
      <div class="form-group d-none" id="uploadImageWraper">
        <label>Upload a Picture <span class="fColor-base">(Optional)</span></label>
        <div id="uploadImageInner" class="form-control p-1">
          <!-- Display files names -->
          <span id="uploadImageFile">{{ fileAttr }}</span>
          
          <!-- Browse Button -->
          <div id="uploadImageButtonWrapper">
            <button class="browseFileBtn">Choose a file<i class="fa fa-upload"></i></button>
            <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile" accept="image/*" />
          </div>
        </div>
      </div>
      <div class="form-group d-flex justify-content-center mt-4">
        <button class="btn btn-first" (click)="submit_review_second()">Submit Review</button>
      </div>
    </div>
    
  </div>
</div>

<!-- THANK YOU AND EXPERIENCE SCORE SECTION -->
<div class="thank-you-section" *ngIf="showThankYou">
  <h1>Thank You!</h1>
  <p class="thank-you-subtitle">Do You Have a Moment To Review TapTap Medicare?!</p>
  <div class="d-flex align-items-center justify-content-around">
    <button class="btn btn-third" (click)="pleaseEmail()">
      No, Please Email Me A Link To Complete Later!
    </button>
    <button class="btn btn-first" (click)="yesLetsDoIt()">
      Yes, Let's Do It!
    </button>
  </div>

  <div class="experience-score-section" *ngIf="showExperienceRating">    
    <div class="experience-score-section-left">
      <img src="./assets/img/common/contact-us-woman-pink-dress.png" alt="">
    </div>
    <div class="experience-score-section-right">
      <h4>How Has Your Experience Been With TapTap Medicare So Far?</h4>
      <div class="experience-score-star-rating">
        <span>{{ experience_score }}</span>
        <div class="stars">
          <i class="cursor-pointer fa fColor-orange {{experience_score > 0 && experience_score < 1  ? 'fa-star-half-o' : experience_score >= 1 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('experience',1)"></i>
          <i class="cursor-pointer fa fColor-orange {{experience_score > 1 && experience_score < 2  ? 'fa-star-half-o' : experience_score >= 2 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('experience',2)"></i>
          <i class="cursor-pointer fa fColor-orange {{experience_score > 2 && experience_score < 3  ? 'fa-star-half-o' : experience_score >= 3 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('experience',3)"></i>
          <i class="cursor-pointer fa fColor-orange {{experience_score > 3 && experience_score < 4  ? 'fa-star-half-o' : experience_score >= 4 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('experience',4)"></i>
          <i class="cursor-pointer fa fColor-orange {{experience_score > 4 && experience_score < 5  ? 'fa-star-half-o' : experience_score >= 5 ? 'fa-star' : 'fa-star-o'}}" (click)="starClick('experience',5)"></i>
        </div>
      </div>
      <button class="btn btn-first mx-auto" (click)="submit_experience_score()">Submit</button>
    </div>
  </div>
</div>

<section class="rateBg container-fluid" [hidden]="!toLoginBp">
  <div class="row justify-content-center">
      <div class="score-board col-3 row justify-content-center">
          <div class="col-12 row">
              <label *ngIf="toLoginBpMode == 1" class="community-board-list-title col-11">
                  <i class="fas fa-lock"></i> &nbsp;
                  Login To Your TapTap Account
              </label>
              <label *ngIf="toLoginBpMode == 2" class="community-board-list-title col-11">
                  <i class="fas fa-lock"></i> &nbsp;
                  Agent login 
              </label>
              <span class="col-1"><i class="fa fa-close" (click)="toLoginBp = false"></i></span>
          </div>
          <div class="col-12 row margin-top-20">
              <form [formGroup]="loginForm" class="col-12">
                  <div class="form-group">
                      <label for="email">Email<sup>*</sup></label>
                      <input type="email" class="form-control" name="email" id="email" formControlName="email"
                          required />
                  </div>
                  <div class="form-group">
                      <label for="password">Password<sup>*</sup></label>
                      <input type="password" class="form-control" name="password" id="password"
                          formControlName="password" required />
                  </div>
                  <div class="form-group d-flex justify-content-between">

                      <div id="captcha-div2" style="width:200px"></div>
                  </div>
                  <div class="form-group" *ngIf="toLoginBpMode == 1">
                      <p>Forgot your login credentials? <a routerLink="/forgot-password"
                              class="color-primary fWeight-600">Click Here</a></p>
                  </div>
                  <div class="form-group d-flex justify-content-end">
                      <button *ngIf="toLoginBpMode == 1" type="submit" class="btn btn-first"
                          (click)="loginToBp()">Login Now
                          <!--<i class="fas fa-angle-double-right ml-2"></i>--></button>
                      <button *ngIf="toLoginBpMode == 2" type="submit" class="btn btn-first"
                          (click)="loginAsAgent()">Login Now
                          <!--<i class="fas fa-angle-double-right ml-2"></i>--></button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</section>