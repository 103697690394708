import { Component, OnInit, Input,  } from '@angular/core';
import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import {DrugsService} from '../../../services/drugs.service';
import { APIService } from '../../../services/api.service';

@Component({
  selector: 'app-your-full-rx-profile',
  templateUrl: './your-full-rx-profile.component.html',
  styleUrls: ['./your-full-rx-profile.component.scss']
})
export class YourFullRxProfileComponent implements OnInit {
  auth_token: any;
  @Input('isTypeView') isTypeView :boolean = true;
  isLoading:boolean = false;
  rxProfileForm: FormGroup;
  viewCounter:number = 1;
  isView_1:boolean = true;
  isView_2:boolean = false;
  isView_3:boolean = false;
  isView_4:boolean = false;
  isView_5:boolean = false;
  isView_6:boolean = false;
  existingDrugs_list:any = [];
  existingPharmacies_list:any = [];
  data: any;
  coverages: Coverages;
  customer_id: number;

  constructor(private formBuilder: FormBuilder,public drugService: DrugsService, private _api: APIService) { }

  ngOnInit() {
    this.auth_token = window.localStorage.getItem('auth_token');
    this.getCustomerPreferencesDetails();

    this.existingDrugs_list = this.drugService.fetchAddedDrugList();
    this.existingPharmacies_list = this.drugService.fetchAddedPharmacyList(); 

    this.rxProfileForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      dateofbirth: ['', Validators.required],
      zipcode: ['', Validators.required],
      state: ['', Validators.required]
    });
  }

  onClick_next(){
    if(this.viewCounter < 6){
      this.viewCounter++;
        
      switch(this.viewCounter){
        case 1:
          this.onResetViews();
          this.isView_1 = true;
          break;
        case 2:
          this.onResetViews();
          this.isView_2 = true;
          break;
        case 3:
          this.onResetViews();
          this.isView_3 = true;
          break;
        case 4:
          this.onResetViews();
          this.isView_4 = true;
          break;
         case 5:
          this.onResetViews();
          this.isView_5 = true;
          break;
        case 6:
          this.onResetViews();
          this.isView_6 = true;
          break;
      }
      $('.span-view').removeClass('active');
      document.querySelector('#view_'+this.viewCounter).classList.add('active');
    }
  }

  onClick_prev(){
    if(this.viewCounter > 1){
      this.viewCounter--;
    
      switch(this.viewCounter){
        case 1:
          this.onResetViews();
          this.isView_1 = true;
          break;
        case 2:
          this.onResetViews();
          this.isView_2 = true;
          break;
        case 3:
          this.onResetViews();
          this.isView_3 = true;
          break;
      }
      $('.span-view').removeClass('active');
      document.querySelector('#view_'+this.viewCounter).classList.add('active');
    } 
  }

  onResetViews(){
    this.isView_1 = false;
    this.isView_2 = false;
    this.isView_3 = false;
    this.isView_4 = false;
    this.isView_5 = false;
    this.isView_6 = false;
  }

  getCustomerPreferencesDetails() {
      this._api.callAPIEndpointAuthenticatedGetRequest('/api/beneficiary/preference/customer-preference/edit', this.auth_token).subscribe(
          (result: any) => {
              this.coverages = result;
              console.log(this.coverages);
              this.customer_id = this.coverages.customer.id;
          },
          (err) => {
              console.log(err);
          }
      );
  }

  sendUpdateRequest(coveragePreferenceId, importance) {
      this.isLoading = true;
      this.data = {
          coverage_preference_id: coveragePreferenceId,
          importance: importance
      };
      this._api.callAPIEndpointAuthenticatedPutRequest('/api/beneficiary/preference/customer-preference/update', this.auth_token, this.data).subscribe(
          (result: any) => {
              /*this.coverages = result;
              console.log(this.coverages);*/
              this.isLoading = false;
          },
          (err) => {
              console.log(err);
          }
      );
  }

  isChecked(coveragePreferenceId, importance) {
      if( this.coverages ){
          if (this.coverages.preferences[this.customer_id][coveragePreferenceId][importance] ) {
              return true;
          }
      }
      return false;
  }

}

export class Coverages {
  preferences: any;
  customer: any;
}