<div class="container-fluid header-banner-wrapper access-forms">

    <button *ngIf="dialogData" class="close close-icon" mat-button (click)="closeModal()">
        <mat-icon class="color_white">close</mat-icon>
    </button>
    
	<app-header-b *ngIf="!dialogData"></app-header-b>

	<div class="container px-0">
    <div class="row flex-column-reverse flex-md-row">
        <div class="col-12 col-md-6 p-0 form-character-wrapper">
            <div class="registration-left-text">            
                <h5 class="mb-4">The Power of Your TapTap Beneficiary Dashboard:</h5>            
                <ul>
                    <li>Being a Medicare Beneficiary is Hard...</li>
                    <li>And There Are a Lot of Moving Pieces</li>
                    <li>I Need One Central Place to Organize Everything, and See Everything</li>
                    <li>A Place That Will Help Me Understand My Situation At All Times</li>
                    <li>And Guide Me Through My Options & Choices</li>
                    <li>Proactive, Clear, and Organized.</li>
                </ul>
                <img src="./assets/img/common/woman-pink-dress-cut-with-gradient.svg" alt="Sign Up Character"> 
                <h5 class="mt-5 mb-3">Key Sections Of Your Dashboard</h5>
                <ul>
                    <li>My Healthcare</li>
                    <li>My Financials</li>
                    <li>My Coverages</li>
                    <li>My Documents</li>
                </ul>            
            </div>        
        </div>
        <div class="col-12 col-md-6 box-wrapper">
            <!-- HIDDEN CONTENT -->
            <div class="primary-box d-none">
                <div class="box-top">
                    <!-- start error messages -->
                    <p *ngIf="success" class="alert alert-success">
                        Account successfully created.
                    </p>

                    <!-- end error messages -->
                    <h4 class="fColor-base fWeight-600">Create An Account</h4>
                    <form [formGroup]="registerForm" [class.form-validated] = "isFormSubmit">
                        <ng-container *ngIf="viewDefault">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="first_name">First Name<sup>*</sup></label>
                                    <input type="text" class="form-control" name="first_name" formControlName="first_name" id="first_name" value="{{ passed_firstname }}">
                                    <ng-container *ngIf="(first_name.invalid && first_name.touched) || first_name.dirty || isFormSubmit">
                                        <small *ngIf="first_name.invalid && first_name.errors['required']" class="text-danger">
                                            First Name is required
                                        </small>
                                    </ng-container>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="last_name">Last Name<sup>*</sup></label>
                                    <input type="text" class="form-control" name="last_name" formControlName="last_name" id="last_name" value="{{ passed_lastname }}">
                                    <ng-container *ngIf="(last_name.invalid && last_name.touched) || last_name.dirty || isFormSubmit">
                                        <small *ngIf="last_name.invalid && last_name.errors['required']" class="text-danger">
                                            Last Name is required
                                        </small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-group">
                                    <label for="mobile_phone">Mobile Phone<sup>*</sup></label>
                                    <input type="number" class="form-control" name="mobile_phone" id="mobile_phone" formControlName="mobile_phone" value="{{ passed_mobile }}">
                                    <ng-container *ngIf="(mobile_phone.invalid && mobile_phone.touched) || mobile_phone.dirty || isFormSubmit">
                                        <small *ngIf="mobile_phone.invalid && mobile_phone.errors['required']" class="text-danger">
                                            Mobile Phone is required
                                        </small>
                                    </ng-container>
                            </div>
                            <div class="d-flex flex-column flex-wrap flex-sm-row justify-content-between mb-4">
                                <div class="d-flex flex-column recaptcha-inner-wrapper">
                                    <ng-container *ngIf="taptapRecaptcha.invalid && isFormSubmit">
                                        <small class="text-danger">
                                            Please Verify your a human 
                                        </small>
                                    </ng-container>
                                </div>
                                
                                <button type="submit" class="btn btn-first proceed-btn" (click)="clickProceed()">Proceed</button>
                            </div>
                            <div class="row justify-content-center login-separator">
                                <label> OR </label>
                            </div>
                            <div class="row btn-social">
                                <button type="submit" class="btn btn-facebook">
                                    <span><i [class.ml-neg-20]="dialogData" class="fa fa-facebook mr-1 text-start color_white"></i></span>
                                    <span>Login with Facebook</span>
                                </button>
                            </div>
                            <div class="row">
                                <button type="submit" class="btn btn-twitter">
                                    <i [class.ml-neg-20]="dialogData" class="fa fa-twitter mr-1 text-start color_white"></i>
                                    Login with Twitter
                                </button>
                            </div>
                            <div class="row">
                                <button type="submit" class="btn btn-google">
                                    <i class="fa fa-google mr-1 text-start color_white"></i>
                                    Login with Google
                                </button>
                            </div>
                            <div class="row">
                                <a *ngIf="!dialogData" routerLink="/login" id="alreadyHaveAccountBtn" class="mb-5 btn">Already have an account? Login Here</a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!viewDefault">
                            <div class="form-group">
                                    <label for="email">Email<sup>*</sup></label>
                                    <input type="email" class="form-control" name="email" id="email" formControlName="email" value="{{ passed_email }}" [class.error-border]="errors && errors.email">
                                    <ng-container *ngIf="(email.invalid && email.touched) || email.dirty || isFormSubmit">
                                        <small *ngIf="email.errors['required']" class="text-danger">Email is required</small>
                                        <small *ngIf="email.errors['pattern']" class="text-danger">Please provide a valid email address</small>
                                        <small *ngIf="errors && errors.email" class="text-danger">{{ errors.email[0] }}</small>
                                    </ng-container>
                            </div>              
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="password">Password<sup>*</sup></label>
                                    <input type="password" class="form-control" name="password" id="password" formControlName="password">
                                    <ng-container *ngIf="(password.invalid && password.touched) || password.dirty || isFormSubmit">
                                        <small *ngIf="password.invalid && password.errors['required']" class="text-danger">
                                            Password is required
                                        </small>
                                    </ng-container>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="c_password">Confirm Password<sup>*</sup></label>
                                    <input type="password" class="form-control" name="c_password" id="c_password" formControlName="c_password" [class.error-border]="errors && errors.c_password">
                                    <ng-container *ngIf="(c_password.invalid && c_password.touched) || c_password.dirty || isFormSubmit">
                                        <small *ngIf="c_password.invalid && c_password.errors['required']" class="text-danger">
                                            Confirm Password is required
                                        </small>
                                        <small *ngIf="errors && errors.c_password" class="text-danger">
                                            {{ errors.c_password[0] }}
                                        </small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center flex-wrap pb-5">
                                <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="terms_and_condition">
                                        <label class="custom-control-label" for="customCheck1">I agree with service <a class="capitalize">Terms & Conditions!</a></label>
                                </div>
                                <button type="submit" class="btn btn-first btn-signup-now" (click)="register()">Signup Now</button>
                            </div>
                        </ng-container>
                    </form>
                </div>          
            </div>

            <!-- NEW DESIGN -->
            <div class="primary-box">
                <div class="box-top p-4 p-md-5">
                    <h4 class="text-center fWeight-600 mb-4">Create An Account</h4>
                    <p *ngIf="success" class="alert alert-success">
                        Account successfully created.
                    </p>
                    <form [formGroup]="registerForm" [class.form-validated] = "isFormSubmit">
                        <div class="row mb-3">
                            <div class="col-12 col-lg-6 px-1">
                                <label class="form-label" for="first_name">First Name</label>
                                <input type="text" class="form-field" name="first_name" formControlName="first_name" id="first_name" value="{{ passed_firstname }}" />
                                <ng-container *ngIf="(first_name.invalid && first_name.touched) || first_name.dirty || isFormSubmit">
                                    <small *ngIf="first_name.invalid && first_name.errors['required']" class="text-danger">
                                        First Name is required
                                    </small>
                                </ng-container>
                            </div>
                            <div class="col-12 col-lg-6 px-1">
                                <label class="form-label" for="last_name">Last Name</label>
                                <input type="text" class="form-field" name="last_name" formControlName="last_name" id="last_name" value="{{ passed_lastname }}" />
                                <ng-container *ngIf="(last_name.invalid && last_name.touched) || last_name.dirty || isFormSubmit">
                                    <small *ngIf="last_name.invalid && last_name.errors['required']" class="text-danger">
                                        Last Name is required
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12 col-lg-6 px-1">
                                <label class="form-label" for="dob">Date of Birth</label>
                                <input type="text" class="form-field" name="dob" id="dob" formControlName="dob" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
                                <ng-container *ngIf="(dob.invalid && dob.touched) || dob.dirty || isFormSubmit">
                                    <small *ngIf="dob.invalid && dob.errors['required']" class="text-danger">
                                        Date of Birth is required
                                    </small>
                                </ng-container>
                            </div>
                            <div class="col-12 col-lg-6 px-1">
                                <label class="form-label">Gender</label>
                                <div class="dropdown custom-dropdown">
                                    <button class="btn btn-secondary dropdown-toggle capitalize" [class.selected]="registerForm.controls.gender.value" type="button" data-toggle="dropdown" aria-expanded="false">
                                      {{ (registerForm.controls.gender.value) ? registerForm.controls.gender.value : 'Select a Gender' }}
                                    </button>
                                    <div class="dropdown-menu">
                                      <button class="dropdown-item" [class.d-none]="registerForm.controls.gender.value == 'male'" (click)="selectGender('male')">Male</button>
                                      <button class="dropdown-item" [class.d-none]="registerForm.controls.gender.value == 'female'" (click)="selectGender('female')">Female</button>
                                    </div>
                                </div>
                                <ng-container *ngIf="(gender.invalid && gender.touched) || gender.dirty || isFormSubmit">
                                    <small *ngIf="gender.invalid && gender.errors['required']" class="text-danger">
                                        Gender is required
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col px-1">
                                <label class="form-label">
                                    {{ activeContactField == 'email' ? 'Email' : 'Phone Number' }}
                                </label>
                                
                                <!-- EMAIL INPUT FIELD -->
                                <input *ngIf="activeContactField == 'email'" type="email" class="form-field" name="email" id="email" formControlName="email" value="{{ passed_email }}" [class.error-border]="errors && errors.email" />
                                
                                <!-- PHONE INPUT FIELD -->
                                <div *ngIf="activeContactField == 'phone'" class="phone-field">
                                    <span class="tooltiptext capitalize">Kindly input a mobile number for this field.</span>
                                    <input  type="tel" class="form-field" autocomplete="tel" maxlength="14" (input)="telFormat($event, 'mobile_phone')" id="mobile_phone" formControlName="mobile_phone"/>
                                </div>

                                <div class="d-flex align-items-center justify-content-between">
                                    <ng-container *ngIf="activeContactField == 'email'">
                                        <ng-container *ngIf="(email.invalid && email.touched) || email.dirty || isFormSubmit">
                                            <small *ngIf="email.errors['required'] || !registerForm.controls.email.value" class="text-danger">
                                                Email is required
                                            </small>
                                            <small *ngIf="email.errors['pattern']" class="text-danger">
                                                Please provide a valid email address
                                            </small>
                                            <small *ngIf="errors && errors.email" class="text-danger">
                                                {{ errors.email[0] }}
                                            </small>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="activeContactField == 'phone'">
                                        <ng-container *ngIf="(mobile_phone.invalid && mobile_phone.touched) || mobile_phone.dirty || isFormSubmit">
                                            <small *ngIf="mobile_phone.errors['required'] || !registerForm.controls.mobile_phone.value" class="text-danger">
                                                Mobile Phone Number is required
                                            </small>
                                            <small *ngIf="mobile_phone.errors['minLength']" class="text-danger">
                                                Invalid Phone Number
                                            </small>
                                        </ng-container>
                                    </ng-container>
                                    <button (click)="switchField(activeContactField == 'email' ? 'phone' : 'email')" class="trouble-btn ml-auto">
                                        <small>
                                            {{ activeContactField == 'email' ? 'Use your Phone Number instead' : 'Use your Email instead' }}
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col px-1">
                                <label class="form-label" for="password">Password</label>
                                <input type="password" class="form-field" name="password" id="password" formControlName="password" [class.error-border]="!passwordMatch"/>
                                <ng-container *ngIf="(password.invalid && password.touched) || password.dirty || isFormSubmit">
                                    <small *ngIf="password.invalid && password.errors['required']" class="text-danger">
                                        Password is required
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col px-1">
                                <label class="form-label" for="c_password">Confirm Password</label>
                                <input type="password" class="form-field" name="c_password" id="c_password" formControlName="c_password" [class.error-border]="errors && errors.c_password || !passwordMatch" />
                                <ng-container *ngIf="(c_password.invalid && c_password.touched) || c_password.dirty || isFormSubmit">
                                    <small *ngIf="c_password.invalid && c_password.errors['required']" class="text-danger">
                                        Confirm Password is required
                                    </small>
                                    <small *ngIf="errors && errors.c_password" class="text-danger">
                                        {{ errors.c_password[0] }}
                                    </small>
                                    <small *ngIf="errors && !passwordMatch" class="text-danger">
                                        Password does not match. Please re-enter your password.
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col px-1 d-flex align-items-center justify-content-center">
                                <input class="mr-1 big-checkbox" type="checkbox" formControlName="terms_and_condition" [class.error-border]="errors && errors.terms_and_condition">
                                <label class="mb-0">I agree with service <a class="capitalize">Terms & Conditions</a></label>
                            </div>
                            <small *ngIf="errors && terms_and_condition.errors" class="col-12 text-danger text-center">
                                Please Agree to the Terms and Conditions
                            </small>
                        </div>
                        <div class="d-flex flex-column flex-lg-row mb-3 align-items-center justify-content-between">
                            <div class="d-flex flex-column recaptcha-inner-wrapper">
                                <re-captcha
                                    [formControl]="getTapTapRecaptcha"
                                    style="transform:scale(0.80);-webkit-transform:scale(0.80);transform-origin:0 0;-webkit-transform-origin:0 0;"
                                    class="recaptcha-wrapper my-2"
                                ></re-captcha>
                                <ng-container *ngIf="taptapRecaptcha.invalid && isFormSubmit">
                                    <small class="text-danger">
                                        Please Verify you're a human 
                                    </small>
                                </ng-container>
                            </div>
                            <button (click)="register()" type="submit" class="btn btn-first btn-small btn-login-now" >
                                Register Now
                            </button>
                        </div>
                        <hr>
                        <div class="row justify-content-center">
                            <p>Already a member? <a routerLink="/login" class="color-primary fWeight-600">Login Here</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>

<div class="d-none">
    <div #appDOB>
        <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)" [isShowDaySelection]="isShowDaySelection"></app-dob>
    </div>
</div>