<div class="star-rating-main-heading">
  <h3 class="fColor-base">Star Ratings</h3>
  <div class="d-flex flex-column align-items-end">
    <button class="btn btn-third btn-xs mb-2 orange-btn-shadow">       
      <img src="./assets/img/star-rating-doc-icon.png" class="mr-2 w-20">
      View how this plan compares to Original Medicare
    </button>
    <button class="btn btn-third btn-xs expand mb-1" (click)="onClickExpandAllRatings()" *ngIf="showExpandAllRatings"><i class="fa fa-plus mr-2 color-inherit"></i>Expand All Ratings</button>
    <button class="btn btn-third btn-xs expand mb-1" (click)="onClickCollapseAllRatings()" *ngIf="!showExpandAllRatings"><i class="fa fa-minus mr-2 color-inherit"></i>Collapse All Ratings</button>    
  </div>  
</div>
<div class="highest-rating-heading">
  <img src="./assets/img/5-star-icon.png">
  <p>This plan has the highest rating. You can switch into a plan with a 5-star rating anytime during the year, even outside of an enrollment period.</p>
</div>
<div id="accordion" class="star-rating-accordion">
  <div class="card summary overall-summary">
    <div class="card-header">
      <h5 class="mb-0">Overall star rating<br><span>Overall rating is based on the categories below.</span></h5>  
      <div class="d-flex align-items-center">
        <img src="./assets/img/5-star-icon.png" class="w-30 mr-3">
        <p>This plan got Medicare's <b class="color-inherit">highest rating</b> (5 stars)</p>
      </div>          
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2"></i>Health plan star rating
        </button>
      </h5>
    </div>
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne">
      <div class="card-body top-level">
        <ng-container [ngTemplateOutlet]="secondLevel"></ng-container>
      </div>
    </div>
  </div>
  <div class="card summary">
    <div class="card-header">
      <h5 class="mb-0">Summary rating of health plan quality</h5>
      <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2"></i>Drug plan star rating
        </button>
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
      <div class="card-body top-level">
        <ng-container [ngTemplateOutlet]="secondLevel2"></ng-container>
      </div>
    </div>
  </div>
  <div class="card summary">
    <div class="card-header">
      <h5 class="mb-0">Summary rating of drug plan quality</h5>
      <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
    </div>
  </div>
</div>

<ng-template #starRatingIcons>
  <div class="star-rating-icons mr-2">
    <i class="fa {{rating > 0 && rating < 1  ? 'fa-star-half-o' : rating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
    <i class="fa {{rating > 1 && rating < 2  ? 'fa-star-half-o' : rating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
    <i class="fa {{rating > 2 && rating < 3  ? 'fa-star-half-o' : rating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
    <i class="fa {{rating > 3 && rating < 4  ? 'fa-star-half-o' : rating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
    <i class="fa {{rating > 4 && rating < 5  ? 'fa-star-half-o' : rating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
  </div>
</ng-template>

<ng-template #secondLevel>
  <div class="star-rating-accordion">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_second_level_1" aria-expanded="true" aria-controls="collapseOne">
            <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2"></i>Staying healthy: screenings, tests, & vaccines
          </button>
        </h5>
        <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
      </div>
      <div id="collapse_second_level_1" class="collapse" aria-labelledby="headingOne">
        <div class="card-body last-rating-result-body">
          <div class="last-rating-result">
            <span>Breast cancer screening</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
          <div class="last-rating-result">
            <span>Colorectal cancer screening</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
          <div class="last-rating-result">
            <span>Yearly flu vaccine</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
          <div class="last-rating-result">
            <span>Monitoring physical activity</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
        </div>
      </div>

      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_second_level_2" aria-expanded="true" aria-controls="collapseOne">
            <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2"></i>Managing Chronic (long term) conditions
          </button>
        </h5>
        <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
      </div>
      <div id="collapse_second_level_2" class="collapse" aria-labelledby="headingOne">
        <div class="card-body last-rating-result-body">
          <div class="last-rating-result">
            <span>Breast cancer screening</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
          <div class="last-rating-result">
            <span>Colorectal cancer screening</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
          <div class="last-rating-result">
            <span>Yearly flu vaccine</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
          <div class="last-rating-result">
            <span>Monitoring physical activity</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>


<ng-template #secondLevel2>
  <div class="star-rating-accordion">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_second_level_3" aria-expanded="true" aria-controls="collapseOne">
            <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2"></i>Drug plan customer service
          </button>
        </h5>
        <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
      </div>
      <div id="collapse_second_level_3" class="collapse" aria-labelledby="headingOne">
        <div class="card-body last-rating-result-body">
          <div class="last-rating-result">
            <span>Availability of TTY services and foreign language interpretation when prospective members call the drug plan</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
        </div>
      </div>

      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse_second_level_4" aria-expanded="true" aria-controls="collapseOne">
            <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2"></i>Member complaints & changes in the drug plan's performance
          </button>
        </h5>
        <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
      </div>
      <div id="collapse_second_level_4" class="collapse" aria-labelledby="headingOne">
        <div class="card-body last-rating-result-body">
          <div class="last-rating-result">
            <span>Complaints about the drug plan (more stars are better because it means fewer complaints)</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
        </div>
        <div class="card-body last-rating-result-body">
          <div class="last-rating-result">
            <span>Members choosing to leave the plan (more stars are better because it means fewer members choose to leave the plan)</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
        </div>
        <div class="card-body last-rating-result-body">
          <div class="last-rating-result">
            <span>Improvement (if any) in the drug plan's performance</span>
            <ng-container [ngTemplateOutlet]="starRatingIcons"></ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>