import {Component, OnInit, ElementRef, ViewChild, Inject, Optional } from '@angular/core';
import {Validators, FormGroup, FormBuilder} from '@angular/forms';
import {APIService} from '../services/api.service';
import {Router, ActivatedRoute } from '@angular/router';
import {AuthService} from "../services/auth.service";

export interface DialogData {
  email: string;
  name: string;
  isModal: boolean;
}

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  
  isValidHash: boolean = false;
  showLoading: boolean = false;
  loadingHash: boolean = false;
  userInfo: any;
  hash: any = '';
  public formGroup!: FormGroup;
  currentUser: any;

  constructor(private _api: APIService,  private route: ActivatedRoute, 
      private _router: Router, 
      private _authService: AuthService, private formBuilder: FormBuilder) {
  }
  ngOnInit() {
    
    this.route.queryParams.subscribe(params => {
      this.hash = params['hash'];
      this.checkIfValidRoute();
    });

    this.formGroup = this.formBuilder.group({
      passcode : ['', Validators.required],
    });

    console.log('this.hash', this.hash);
  }


  checkIfValidRoute() {
    this._api.callAPIEndpoint('/api/v1/validate-hash', {
      'hash' : this.hash
    }).subscribe(
      (data: any) => {
        if (data['results'] && typeof data['results'] !== null) {
          this.isValidHash = true;
          this.userInfo = data['results'];
        }

        this.loadingHash = true;
      })
  }

  closeModal() {
  }

  login() {
    if (this.formGroup.valid && this.formGroup.get('passcode').value === this.userInfo.passcode ) {
        this.showLoading = true;
        this._authService.signUserInOnServer({
            'email': this.userInfo.email,
            'password': this.userInfo.passcode,
            // 'password': 'test',
        }).subscribe(
            (data: any) => {
                if (data.code === 1) {
                    this.showLoading = false;
                    this._authService.setActiveUserUsingJSON(data.details);
                    window.location.href = '/benny-dash';
                }
            },
            (response) => {
                if(response.error){
                    this.showLoading = false;
                }
            }
        )
    } else {
      alert('Invalid passcode');
    }
  }
}
