import { RIGHT_ARROW } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, Output, ViewChild, OnChanges } from '@angular/core';
import { ConsumerReviewService } from 'src/app/services/consumer-review.service';
import Swal from "sweetalert2";
import { SubmitAReviewComponent } from '../submit-a-review/submit-a-review.component';

@Component({
  selector: 'app-consumer-reviews',
  templateUrl: './consumer-reviews.component.html',
  styleUrls: ['./consumer-reviews.component.scss']
})
export class ConsumerReviewsComponent implements OnInit {
  @Input() image: any;
  @Input() companyName: any;
  @Input() reviews: any;
  @Input() consumerRating:any;
  @Input() customerServiceRating:any;
  @Input() overallRating:any;
  @Input() priceStabilityRating:any;
  @Input() id: any;
  @Input() pdsCode:any;
  @Input() type: any;

  reviewResult: any;
  currentSlide: number;

  constructor(private _consumerReviewService: ConsumerReviewService) { }

  ngOnChanges(changes) {
    //this.overAllRating = 5;
    if(changes.id ) {
      this.reviewResult = [];
      this.id = changes.id.currentValue;
    }

    if(this.id) {
      this.getCustomerReviews();
    }
  }

  ngOnInit() {
    this.type = 0;
    this.currentSlide = 0;
    this.reviewResult = [];
  }

  async getCustomerReviews(): Promise<any> {
    const result = await this._consumerReviewService.getCustomerReviews({'id': this.id, 'type': this.type});
    if(result) {
      this.reviewResult = result.results.filter(item => item.review !== null);
    }

    // temporary
    /*this.reviewResult = [
      {
        "id": 1,
        "medigap_map_id": 1,
        "customer_service": "4.5",
        "consumer_clarity": "3.5",
        "price_stability": "5.0",
        "overall_score": "4.5",
        "taptap_exp_score": "4.0",
        "review": "Nice app 1",
        "reviewer_name": "John Doe 1",
        "email": "test@gmail.com",
        "phone": null,
        "picture": null,
        "is_taptap_approved": null,
        "is_taptap_review": 0,
        "created_at": "2021-09-12 18:36:19",
        "updated_at": "2021-09-12 10:36:19",
        "ease_of_use":"3",
        "consumer_rating": "4"
      },
      {
        "id": 345,
        "medigap_map_id": 1,
        "customer_service": "1",
        "consumer_clarity": "1",
        "price_stability": "3",
        "overall_score": "4.5",
        "taptap_exp_score": "4.0",
        "review": "Nice app 2",
        "reviewer_name": "John Doe 2",
        "email": "test@gmail.com",
        "phone": null,
        "picture": null,
        "is_taptap_approved": null,
        "is_taptap_review": 0,
        "created_at": "2021-09-09 23:21:25",
        "updated_at": "2021-09-08 00:46:56",
        "ease_of_use":"2",
        "consumer_rating": "1"
      },
      {
        "id": 345,
        "medigap_map_id": 1,
        "customer_service": "5",
        "consumer_clarity": "5",
        "price_stability": "3",
        "overall_score": "4",
        "taptap_exp_score": "2",
        "review": "Nice app 3",
        "reviewer_name": "John Doe 3",
        "email": "test@gmail.com",
        "phone": null,
        "picture": null,
        "is_taptap_approved": null,
        "is_taptap_review": 0,
        "created_at": "2021-09-09 23:21:25",
        "updated_at": "2021-09-08 00:46:56",
        "ease_of_use":"5",
        "consumer_rating": "5"
      }
    ];*/
  }

  showSubmitAReview(modalViews: any) {
      Swal.fire({
          html: modalViews,
          customClass: {
              popup: 'quote-popup-wrapper',
              content: 'custom-modal-content text-left'
          },
          showConfirmButton: false,
          showCloseButton: false,
          padding:'30px',
          width: 700,
          allowOutsideClick: false,
          allowEscapeKey: false
      })
  }
}

