<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Basic Info</h4>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="fname" class="text-label">First Name</label>
        <input type="text" class="form-control blurred" id="fname"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="fname" class="text-label">Date of Birth</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" [(ngModel)]="customerDateOfBirth" id="fname" (click)="onClickDob(appDOB,'normal','What Month & Year Were You Born?')"/>
          <i class="fas fa-calendar"></i>
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="fname" class="text-label">Middle Name</label>
        <input type="text" class="form-control blurred" id="fname"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="fname" class="text-label">Gender</label>
        <div class="d-flex align-items-center">
          <div class="custom-control custom-radio custom-control-inline mx-2">
            <input type="radio" id="gender_1" name="gender" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="gender_1">Male</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline mx-2">
            <input type="radio" id="gender_2" name="gender" class="custom-control-input">
            <label class="custom-control-label fw-semibold text-dblue" for="gender_2">Female</label>
          </div>
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="fname" class="text-label">Last Name</label>
        <input type="text" class="form-control blurred" id="fname"/>
      </div>
    </div>
    <div class="py-3">
      <h5 class="text-center fw-semibold text-dblue">
        Is This Policy Covering Just Yourself As The Insured or Will There Be A Separate Individual As The Policy Insured?
      </h5>
      <div class="d-flex justify-content-center">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="policyInfo_1" name="policyInfo" class="custom-control-input" [value]="true" [(ngModel)]="policyInsured">
          <label class="custom-control-label fw-semibold text-primary" for="policyInfo_1">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="policyInfo_2" name="policyInfo" class="custom-control-input" [value]="false" [(ngModel)]="policyInsured">
          <label class="custom-control-label fw-semibold text-primary" for="policyInfo_2">No</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="policyInsured">
    <hr>
    <h4 class="fw-semibold text-dblue">Policy Info</h4>
    <div class="yellow-line"></div>
    <div class="row">
      <div class="form-group col-12 col-md-6 p-2">
        <label for="pfname" class="text-label">Policy Insured First Name</label>
        <input type="text" class="form-control blurred" id="pfname"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="pmname" class="text-label">Policy Insured Middle Name</label>
        <input type="text" class="form-control blurred" id="pmname"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="plname" class="text-label">Policy Insured Last Name</label>
        <input type="text" class="form-control blurred" id="plname"/>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <label for="pdob" class="text-label">Policy Insured Date of Birth</label>
        <div class="dob-field">
          <input type="text" class="form-control blurred" id="fname" (click)="onClickDob(appDOB,'normal','Policy Insured Date of Birth')"/>
          <i class="fas fa-calendar"></i>
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <div class="p-2">
          <label for="pgender" class="text-label">Policy Insured Gender</label>
          <input type="text" class="form-control blurred" id="pgender"/>
        </div>
        <div class="p-2">
          <label for="fname" class="text-label">Policy Insured Email</label>
          <input type="text" class="form-control blurred" id="fname"/>
        </div>
        <div class="p-2">
          <label for="fname" class="text-label">Policy Insured Phone</label>
          <input type="text" class="form-control blurred" id="fname"/>
        </div>
      </div>
      <div class="form-group col-12 col-md-6 p-2">
        <div class="dashed-box p-3">
          <h6 class="text-label fw-semibold">Policy Insured’s Relationship To You</h6>
          <div class="row pt-2">
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_1" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_1">Husband</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_2" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_2">Wife</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_3" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_3">Mother</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_4" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_4">Father</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_5" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_5">Brother</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_6" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_6">Sister</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_7" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_7">Significant Other</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_8" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_8">Friend</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_9" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_9">Caretaker</label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="pRelationship_10" name="pRelationship" class="custom-control-input">
                <label class="custom-control-label" for="pRelationship_10">Co-Worker</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>