<div class="doctor-detail-box">
  <div class="list-card mb-3">
    <div class="p-4">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h6 class="fWeight-700 mb-0">LIST OF DRUGS</h6>
        <span class="text-primary">{{ existingDrugs_list?.length }} Drugs</span>
      </div>
      <div class="doctor-your-drug-list" *ngIf="existingDrugs_list">
        <div class="your-drug-detail" *ngFor="let existingDrugs of existingDrugs_list | slice:0:3; let i = index">
          <img src="./assets/img/my-drugs/orange-white-capsule.JPG" alt="Capsule Icon">
          <div class="d-flex flex-column">
            <strong class="your-drug-detail-name text-capitalize">{{ (existingDrugs.drug_name) ? existingDrugs.drug_name : existingDrugs.details.drug_name }}</strong>
            <ng-container *ngIf="existingDrugs.details.dosage">
              <small class="your-drug-detail-name-dosage">{{ (existingDrugs.details.dosage) ? existingDrugs.details.dosage : 'n/a' }} | {{ (existingDrugs.times_taken) ? existingDrugs.times_taken : 'n/a' }}/{{ (existingDrugs.often_taken) ? existingDrugs.often_taken.replace('per-','') : 'n/a' }}</small>
            </ng-container>
          </div>        
        </div>
      </div>
    </div>
    <ng-container *ngIf="existingDrugs_list">
      <button class="view-list-button w-100 py-1 w-100 bg-light border-0" (click)="viewYourList(modalView)">VIEW ALL <span *ngIf="existingDrugs_list.length > 3" class="more-text">({{ existingDrugs_list.length - 3 }} more)</span></button>
    <!-- <button *ngIf="existingDrugs_list.length < 3" class="view-list-button w-100 py-1 w-100 bg-light border-0" (click)="viewYourList(modalView)">VIEW ALL</button> -->
    </ng-container>
  </div>
  <ng-container *ngIf="existingPharmacies_list && existingDrugs_list">
    <div *ngIf="existingPharmacies_list.length && existingDrugs_list.length" class="list-card">
      <div class="p-4">
        <h5 class="text-center fWeight-700">Done adding your Drugs and Pharmacies?</h5>
        <h6 class="text-center text-secondary fWeight-600 mb-3">Click here to proceed!</h6>
        <button class="btn btn-first btn-small mx-auto my-1 w-100" *ngIf="!isUserLoggedIn" (click)="showLoginModal(loginModal)">Save RX Profile</button>
        <button class="btn btn-first btn-small mx-auto my-1 w-100" (click)="onClick_FullRxProfile(yourFullRxProfile)" *ngIf="isUserLoggedIn">View RX Profile</button>
        <button class="btn btn-first btn-small mx-auto my-1 w-100" (click)="onClick_PharmacyResultProceed(monthCoverageEffective)">View Drug Cost</button>
      </div>
    </div>
  </ng-container>
</div>

<div class="d-none">
  <div class="your-drug-list-modal pb-3" #modalView>
    <div class="d-flex flex-column align-items-center py-3">
      <img class="drug-list-icon" src="./assets/img/my-drugs/drug-list-icon.svg" alt="capsule">
      <h3>Your Drugs List</h3>
    </div>

    <div class="mb-4" *ngIf="existingDrugs_list">
      <table class="w-100 table-striped table-borderless table-hover">
        <tbody>
          <tr *ngFor="let existingDrugs of existingDrugs_list; let i = index">
            <td>
              <div class="px-4 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <img class="mr-2" src="./assets/img/my-drugs/orange-capsule.svg">
                  <div class="d-flex flex-column">
                    <span class="text-capitalize font-weight-bold">{{ (existingDrugs.drug_name) ? existingDrugs.drug_name : existingDrugs.details.drug_name }}</span>
                    <ng-container *ngIf="existingDrugs.details.dosage">
                      <!-- <span>{{ existingDrugs.details.dosage }} | {{ existingDrugs.quantity }}/{{ existingDrugs.often_taken }}</span> -->
                      <span>{{ (existingDrugs.details.dosage) ? existingDrugs.details.dosage : 'n/a' }} | {{ (existingDrugs.times_taken) ? existingDrugs.times_taken : 'n/a' }}/{{ (existingDrugs.often_taken) ? existingDrugs.often_taken.replace('per-','') : 'n/a' }}</span>
                    </ng-container>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <!-- <button *ngIf="editDrugList == true" class="btn btn-second btn-xs px-4 my-3 mr-2" (click)="changeDrugModal(changeDrug)">
                    Change
                  </button> -->
                  <button *ngIf="editDrugList == true" class="btn btn-second btn-xs px-4 my-3 mr-2" (click)="editYourDrug(existingDrugs)">
                    Change
                  </button>
                  <button class="btn btn-first btn-xs px-4 my-3 modal-remove-button" (click)="removeDrug(existingDrugs, deleteConfirmationModal)" *ngIf="existingDrugs_list.length > 1">
                    Remove
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <div class="notes-form p-3 m-4">
        <div class="d-flex align-items-center justify-content-between">
          <span class="notes-title">
            <strong>Your Notes For Your Whole Drug List</strong>
          </span>
          <span *ngIf="drugListInfo && isUserLoggedIn"> 
            <strong>Last Updated: </strong> {{ drugListInfo.updated_at }}
          </span>
        </div>
        <hr class="my-1">
        <ng-container *ngIf="drugListInfo">
          <textarea class="notes-field" name="" id="" rows="7" placeholder="Enter your notes here..." [(ngModel)]="drugListInfo.drug_notes">{{drugListInfo.drug_notes}} </textarea>
          <button class="btn btn-second btn-xs" (click)="onClick_SaveDrugsPharmaciesNotes()">Save Note</button>
        </ng-container>
        <ng-container *ngIf="!drugListInfo">
          <textarea class="notes-field" name="" id="" rows="7" placeholder="Enter your notes here..." [(ngModel)]="drugsNotes">{{drugsNotes}} </textarea>
          <button class="btn btn-second btn-xs" (click)="onSave_DrugsNotesToLocalStorage()">Save Note</button>
        </ng-container>        
      </div>
    </div>

    <div *ngIf="editDrugList == true">
      <button (click)="editDrugList = false" class="btn btn-second btn-small mx-auto">
        Back
      </button>
    </div>
  </div>

  <div class="your-drug-list-modal pb-3" #pharmacyModal>
    <div class="d-flex flex-column align-items-center py-3">
      <img src="./assets/img/my-drugs/pharmacy-logo.svg" alt="capsule">
      <h3>Your Pharmacy List</h3>
    </div>

    <div class="mb-4" *ngIf="existingPharmacies_list">
      <table class="w-100 table-striped table-borderless table-hover">
        <tbody>
          <tr *ngFor="let existingPharmacy of existingPharmacies_list; let i = index">
            <td>
              <div class="px-4 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <img class="p-2 mr-2" src="./assets/img/my-drugs/pharmacy.svg">
                  <div class="d-flex flex-column">
                    <span class="text-capitalize font-weight-bold">{{ existingPharmacy.pharmacy_name }}</span>
                    <span>{{ existingPharmacy.pharmacy_address }}</span>
                  </div>
                </div>
                <button class="btn btn-danger rounded-pill px-4 my-2 modal-remove-button" (click)="removePharmacy(existingPharmacy, deletePharmConfirmationModal)" *ngIf="existingPharmacies_list.length > 1">
                  <span class="font-weight-bold text-light">
                    Remove
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <div class="notes-form p-3 m-4">
        <div class="d-flex align-items-center justify-content-between">
          <span class="notes-title">
            <strong>Your Notes For Your Whole Pharmacy List</strong>
          </span>
          <span *ngIf="drugListInfo && isUserLoggedIn"> 
            <strong>Last Updated: </strong> {{ drugListInfo.updated_at }}
          </span>
        </div>
        <hr class="my-1">
        <ng-container *ngIf="drugListInfo">
          <textarea class="notes-field" name="" id="" rows="10" placeholder="Enter your notes here..." [(ngModel)]="drugListInfo.pharmacy_notes">{{ drugListInfo.pharmacy_notes }}</textarea>
          <button class="btn btn-second btn-xs" (click)="onClick_SaveDrugsPharmaciesNotes()">Save Note</button>
        </ng-container>
        <ng-container *ngIf="!drugListInfo"> 
          <textarea class="notes-field" name="" id="" rows="10" placeholder="Enter your notes here..." [(ngModel)]="pharmaciesNotes">{{ pharmaciesNotes }}</textarea>
          <button class="btn btn-second btn-xs" (click)="onSave_PharmaciesNotesToLocalStorage()">Save Note</button>
        </ng-container>        
      </div>
    </div>

    <div class="row px-3">
      <div class="col-12 col-md-6 p-2">
        <button (click)="addAnotherPharmacy()" class="py-3 modal-buttons w-100 btn border d-flex flex-column justify-content-center align-items-center">
          <img src="./assets/img/my-drugs/add-pharmacy.svg" alt="">
          <span class="font-weight-bold">Add More Pharmacies To Your List</span>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button (click)="showModal(yourPharmacyListInsightsModal)" class="py-3 modal-buttons w-100 btn border d-flex flex-column justify-content-center align-items-center">
          <img class="logo px-4" src="./assets/img/my-drugs/company-logo.png" alt="">
          <span class="font-weight-bold">TapTap Tips, Insights & Analysis</span>
        </button>
      </div>
    </div>

  </div>

  <div class="notes-modal text-center" #yourDrugListNotesModal>
    <img src="./assets/img/my-drugs/your-notes.png" class="regular-icon mb-3" alt="Hands Clap">
    <h5>Your Drug List Notes</h5>
    <p class="fWeight-600">Please enter your notes below:</p>
    <div class="text-left p-3">
      <textarea class="notes-field" name="existingRxDrugListNotes" [(ngModel)]="existingRxDrugListNotes" id="" placeholder="Enter your notes here...">{{existingRxDrugListNotes}}</textarea>
    </div>
    <button class="btn btn-first btn-small mx-auto" (click)="onSaveRxDrugListNotes()">Save</button>
  </div>

  <div class="text-center" #yourDrugListInsightsModal>
    <img src="./assets/img/my-drugs/your-insights.png" class="regular-icon mb-3" alt="Hands Clap">
    <h5>Your Drug List Insights</h5>
    <p class="fWeight-600">Please see below:</p>
    <div class="text-left p-3">
      <ul>
        <li>You Take <span class="fWeight-600">{{ existingDrugs_list?.length }}</span> Total Prescription Drugs</li>

        <ng-container *ngIf="drugInsights?.drug_forms">
          <li *ngFor="let df of drugInsights.drug_forms"><span class="fWeight-600">{{df[1]}}</span> Prescription Drugs Are <span class="fWeight-600">{{df[0]}} Form</span></li>
        </ng-container>
        
        <li *ngIf="drugInsights?.branded > 1"><span class="fWeight-600">{{drugInsights.branded}}</span> are <span class="fWeight-600">Branded</span> Drugs</li>
        <li *ngIf="drugInsights?.branded == 1"><span class="fWeight-600">{{drugInsights.branded}}</span> is a <span class="fWeight-600">Brand</span> Name Drug</li>
        <li *ngIf="drugInsights?.generic > 1"><span class="fWeight-600">{{drugInsights.generic}}</span> are <span class="fWeight-600">Generic</span> Drug</li>
        <li *ngIf="drugInsights?.generic == 1"><span class="fWeight-600">{{drugInsights.generic}}</span> is a <span class="fWeight-600">Generic</span> Name Drug</li>
      </ul>
    </div>
  </div>
  

  <div class="text-center" #yourPharmacyListInsightsModal>
    <img src="./assets/img/my-drugs/your-insights.png" class="regular-icon mb-3" alt="Hands Clap">
    <h5>Your Pharmacy List Insights</h5>
    <p class="fWeight-600">Please see below:</p>
    <div class="text-left p-3">
      <ul>
        <li>You added <span class="fWeight-600">{{ existingPharmacies_list?.length }}</span> Total Pharmacies</li>        
        <li>Total <span class="fWeight-600">Community Pharmacies</span> On Your List: <span class="fWeight-600">3</span></li>
        <li><span class="fWeight-600">Mail Order Pharmacy</span> Preference: <span class="fWeight-600">5</span></li>
        <li>Total Updates You’ve Made To Your Pharmacies List: <span class="fWeight-600">2</span></li>
        <li><span class="fWeight-600">Highly Rated Independent Pharmacies</span> Close To You: <span class="fWeight-600">3</span></li>
        <li><span class="fWeight-600">Opportunities</span> At These Pharmacies: <span class="fWeight-600">1</span></li>
      </ul>
    </div>
    <button class="btn btn-first btn-small mx-auto mt-4">Complete Your Benny Dash To See More</button>    
  </div>

  <div #changeDrug>

    <!-- Step 0 -->
    <div *ngIf="editStep == 0" class="dp-card">
      <div class="drug-selection-card with-table-separator">
        <div class="d-flex align-items-center justify-content-center py-3">
          <img src="./assets/img/my-drugs/generic-name.svg" alt="Tablet Icon" class="mt-0 mr-3 max-w-90">
          <h4 class="text-capitalize">Branded Versions</h4>
        </div>
        <!-- new -->
        <ng-container>
          <h5 class="text-center py-2">Oral Tablet</h5>
          <table class="drug-table table table-striped table-borderless text-left">
            <thead>
              <tr>
                <th scope="col" class="col-name fWeight-600 pl-4">Name</th>
                <th scope="col" class="col-dosage fWeight-600">Dosage</th>
                <th scope="col" class="col-type fWeight-600">Type</th>
                <th scope="col" class="col-icon">&nbsp;</th>
                <th scope="col" class="col-action">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="fColor-blue fWeight-600 pl-4 col-name">Lipitor</td>
                <td class="col-dosage">80 MG</td>
                <td class="col-type">Oral Tablet</td>
                <td class="col-icon">

                  <ng-container>
                    <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                  </ng-container>

                  <!-- <ng-template #temp_capsule>
                    <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('capsule'); else temp_injection">
                      <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                    </ng-container>
                  </ng-template>
                  
                  <ng-template #temp_injection>
                    <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('injection'); else temp_meds">
                      <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                    </ng-container>
                  </ng-template>

                  <ng-template #temp_meds>
                    <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                  </ng-template> -->
                  
                </td>
                <td class="text-right col-action">
                  <a class="select-button btn btn-second btn-xs mr-3 px-4 text-primary max-w-85">Select</a>
                </td>
              </tr>
              <tr>
                <td class="fColor-blue fWeight-600 pl-4 col-name">Lipitor</td>
                <td class="col-dosage">80 MG</td>
                <td class="col-type">Oral Tablet</td>
                <td class="col-icon">

                  <ng-container>
                    <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                  </ng-container>

                  <!-- <ng-template #temp_capsule>
                    <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('capsule'); else temp_injection">
                      <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                    </ng-container>
                  </ng-template>
                  
                  <ng-template #temp_injection>
                    <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('injection'); else temp_meds">
                      <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                    </ng-container>
                  </ng-template>

                  <ng-template #temp_meds>
                    <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                  </ng-template> -->
                  
                </td>
                <td class="text-right col-action">
                  <a class="select-button btn btn-second btn-xs mr-3 px-4 text-primary max-w-85">Select</a>
                </td>
              </tr>
              <tr>
                <td class="fColor-blue fWeight-600 pl-4 col-name">Lipitor</td>
                <td class="col-dosage">80 MG</td>
                <td class="col-type">Oral Tablet</td>
                <td class="col-icon">

                  <ng-container>
                    <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                  </ng-container>

                  <!-- <ng-template #temp_capsule>
                    <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('capsule'); else temp_injection">
                      <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                    </ng-container>
                  </ng-template>
                  
                  <ng-template #temp_injection>
                    <ng-container *ngIf="drug.drug_info.name.toLowerCase().includes('injection'); else temp_meds">
                      <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                    </ng-container>
                  </ng-template>

                  <ng-template #temp_meds>
                    <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
                  </ng-template> -->
                  
                </td>
                <td class="text-right col-action">
                  <a class="select-button btn btn-second btn-xs mr-3 px-4 text-primary max-w-85">Select</a>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button (click)="editStep = 1" class="btn btn-first btn-small mx-0">Proceed</button>
      </div>
    </div>

    <!-- Step 1 -->
    <div *ngIf="editStep == 1" id="form_view_default" class="dp-card mt-3">
      <h2 class="fWeight-600 mb-4 d-block text-center">Enter The Amount You<br> Take of This Drug</h2>
      <label class="text-center d-block mt-4 col-12 mb-3">This Often:</label>
      <div class="row px-2">
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-day-1"(click)="onClick_shortcutOften('1','per-day')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 208c0-61.86 50.14-111.1 111.1-111.1c52.65 0 96.5 36.45 108.5 85.42C334.7 173.1 354.7 168 375.1 168c4.607 0 9.152 .3809 13.68 .8203l24.13-34.76c5.145-7.414 .8965-17.67-7.984-19.27L317.2 98.78L301.2 10.21C299.6 1.325 289.4-2.919 281.9 2.226L208 53.54L134.1 2.225C126.6-2.92 116.4 1.326 114.8 10.21L98.78 98.78L10.21 114.8C1.326 116.4-2.922 126.7 2.223 134.1l51.3 73.94L2.224 281.9c-5.145 7.414-.8975 17.67 7.983 19.27L98.78 317.2l16.01 88.58c1.604 8.881 11.86 13.13 19.27 7.982l10.71-7.432c2.725-35.15 19.85-66.51 45.83-88.1C137.1 309.8 96 263.9 96 208zM128 208c0 44.18 35.82 80 80 80c9.729 0 18.93-1.996 27.56-5.176c7.002-33.65 25.53-62.85 51.57-83.44C282.8 159.3 249.2 128 208 128C163.8 128 128 163.8 128 208zM575.2 325.6c.125-2 .7453-3.744 .7453-5.619c0-35.38-28.75-64-63.1-64c-12.62 0-24.25 3.749-34.13 9.999c-17.62-38.88-56.5-65.1-101.9-65.1c-61.75 0-112 50.12-112 111.1c0 3 .7522 5.743 .8772 8.618c-49.63 3.75-88.88 44.74-88.88 95.37C175.1 469 218.1 512 271.1 512h272c53 0 96-42.99 96-95.99C639.1 373.9 612.7 338.6 575.2 325.6z"/></svg>
            1 Per Day 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
    
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-day-2" (click)="onClick_shortcutOften('2','per-day')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 208c0-61.86 50.14-111.1 111.1-111.1c52.65 0 96.5 36.45 108.5 85.42C334.7 173.1 354.7 168 375.1 168c4.607 0 9.152 .3809 13.68 .8203l24.13-34.76c5.145-7.414 .8965-17.67-7.984-19.27L317.2 98.78L301.2 10.21C299.6 1.325 289.4-2.919 281.9 2.226L208 53.54L134.1 2.225C126.6-2.92 116.4 1.326 114.8 10.21L98.78 98.78L10.21 114.8C1.326 116.4-2.922 126.7 2.223 134.1l51.3 73.94L2.224 281.9c-5.145 7.414-.8975 17.67 7.983 19.27L98.78 317.2l16.01 88.58c1.604 8.881 11.86 13.13 19.27 7.982l10.71-7.432c2.725-35.15 19.85-66.51 45.83-88.1C137.1 309.8 96 263.9 96 208zM128 208c0 44.18 35.82 80 80 80c9.729 0 18.93-1.996 27.56-5.176c7.002-33.65 25.53-62.85 51.57-83.44C282.8 159.3 249.2 128 208 128C163.8 128 128 163.8 128 208zM575.2 325.6c.125-2 .7453-3.744 .7453-5.619c0-35.38-28.75-64-63.1-64c-12.62 0-24.25 3.749-34.13 9.999c-17.62-38.88-56.5-65.1-101.9-65.1c-61.75 0-112 50.12-112 111.1c0 3 .7522 5.743 .8772 8.618c-49.63 3.75-88.88 44.74-88.88 95.37C175.1 469 218.1 512 271.1 512h272c53 0 96-42.99 96-95.99C639.1 373.9 612.7 338.6 575.2 325.6z"/></svg>
            2 Per Day 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-day-3" (click)="onClick_shortcutOften('3','per-day')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 208c0-61.86 50.14-111.1 111.1-111.1c52.65 0 96.5 36.45 108.5 85.42C334.7 173.1 354.7 168 375.1 168c4.607 0 9.152 .3809 13.68 .8203l24.13-34.76c5.145-7.414 .8965-17.67-7.984-19.27L317.2 98.78L301.2 10.21C299.6 1.325 289.4-2.919 281.9 2.226L208 53.54L134.1 2.225C126.6-2.92 116.4 1.326 114.8 10.21L98.78 98.78L10.21 114.8C1.326 116.4-2.922 126.7 2.223 134.1l51.3 73.94L2.224 281.9c-5.145 7.414-.8975 17.67 7.983 19.27L98.78 317.2l16.01 88.58c1.604 8.881 11.86 13.13 19.27 7.982l10.71-7.432c2.725-35.15 19.85-66.51 45.83-88.1C137.1 309.8 96 263.9 96 208zM128 208c0 44.18 35.82 80 80 80c9.729 0 18.93-1.996 27.56-5.176c7.002-33.65 25.53-62.85 51.57-83.44C282.8 159.3 249.2 128 208 128C163.8 128 128 163.8 128 208zM575.2 325.6c.125-2 .7453-3.744 .7453-5.619c0-35.38-28.75-64-63.1-64c-12.62 0-24.25 3.749-34.13 9.999c-17.62-38.88-56.5-65.1-101.9-65.1c-61.75 0-112 50.12-112 111.1c0 3 .7522 5.743 .8772 8.618c-49.63 3.75-88.88 44.74-88.88 95.37C175.1 469 218.1 512 271.1 512h272c53 0 96-42.99 96-95.99C639.1 373.9 612.7 338.6 575.2 325.6z"/></svg>
            3 Per Day 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>

        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-meal-1" (click)="onClick_shortcutOften('1','per-meal')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M221.6 148.7C224.7 161.3 224.8 174.5 222.1 187.2C219.3 199.1 213.6 211.9 205.6 222.1C191.1 238.6 173 249.1 151.1 254.1V472C151.1 482.6 147.8 492.8 140.3 500.3C132.8 507.8 122.6 512 111.1 512C101.4 512 91.22 507.8 83.71 500.3C76.21 492.8 71.1 482.6 71.1 472V254.1C50.96 250.1 31.96 238.9 18.3 222.4C10.19 212.2 4.529 200.3 1.755 187.5C-1.019 174.7-.8315 161.5 2.303 148.8L32.51 12.45C33.36 8.598 35.61 5.197 38.82 2.9C42.02 .602 45.97-.4297 49.89 .0026C53.82 .4302 57.46 2.303 60.1 5.259C62.74 8.214 64.18 12.04 64.16 16V160H81.53L98.62 11.91C99.02 8.635 100.6 5.621 103.1 3.434C105.5 1.248 108.7 .0401 111.1 .0401C115.3 .0401 118.5 1.248 120.9 3.434C123.4 5.621 124.1 8.635 125.4 11.91L142.5 160H159.1V16C159.1 12.07 161.4 8.268 163.1 5.317C166.6 2.366 170.2 .474 174.1 .0026C178-.4262 181.1 .619 185.2 2.936C188.4 5.253 190.6 8.677 191.5 12.55L221.6 148.7zM448 472C448 482.6 443.8 492.8 436.3 500.3C428.8 507.8 418.6 512 408 512C397.4 512 387.2 507.8 379.7 500.3C372.2 492.8 368 482.6 368 472V352H351.2C342.8 352 334.4 350.3 326.6 347.1C318.9 343.8 311.8 339.1 305.8 333.1C299.9 327.1 295.2 320 291.1 312.2C288.8 304.4 287.2 296 287.2 287.6L287.1 173.8C288 136.9 299.1 100.8 319.8 70.28C340.5 39.71 369.8 16.05 404.1 2.339C408.1 .401 414.2-.3202 419.4 .2391C424.6 .7982 429.6 2.62 433.9 5.546C438.2 8.472 441.8 12.41 444.2 17.03C446.7 21.64 447.1 26.78 448 32V472z"/></svg>
            1 Per Meal 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-meal-2" (click)="onClick_shortcutOften('2','per-meal')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M221.6 148.7C224.7 161.3 224.8 174.5 222.1 187.2C219.3 199.1 213.6 211.9 205.6 222.1C191.1 238.6 173 249.1 151.1 254.1V472C151.1 482.6 147.8 492.8 140.3 500.3C132.8 507.8 122.6 512 111.1 512C101.4 512 91.22 507.8 83.71 500.3C76.21 492.8 71.1 482.6 71.1 472V254.1C50.96 250.1 31.96 238.9 18.3 222.4C10.19 212.2 4.529 200.3 1.755 187.5C-1.019 174.7-.8315 161.5 2.303 148.8L32.51 12.45C33.36 8.598 35.61 5.197 38.82 2.9C42.02 .602 45.97-.4297 49.89 .0026C53.82 .4302 57.46 2.303 60.1 5.259C62.74 8.214 64.18 12.04 64.16 16V160H81.53L98.62 11.91C99.02 8.635 100.6 5.621 103.1 3.434C105.5 1.248 108.7 .0401 111.1 .0401C115.3 .0401 118.5 1.248 120.9 3.434C123.4 5.621 124.1 8.635 125.4 11.91L142.5 160H159.1V16C159.1 12.07 161.4 8.268 163.1 5.317C166.6 2.366 170.2 .474 174.1 .0026C178-.4262 181.1 .619 185.2 2.936C188.4 5.253 190.6 8.677 191.5 12.55L221.6 148.7zM448 472C448 482.6 443.8 492.8 436.3 500.3C428.8 507.8 418.6 512 408 512C397.4 512 387.2 507.8 379.7 500.3C372.2 492.8 368 482.6 368 472V352H351.2C342.8 352 334.4 350.3 326.6 347.1C318.9 343.8 311.8 339.1 305.8 333.1C299.9 327.1 295.2 320 291.1 312.2C288.8 304.4 287.2 296 287.2 287.6L287.1 173.8C288 136.9 299.1 100.8 319.8 70.28C340.5 39.71 369.8 16.05 404.1 2.339C408.1 .401 414.2-.3202 419.4 .2391C424.6 .7982 429.6 2.62 433.9 5.546C438.2 8.472 441.8 12.41 444.2 17.03C446.7 21.64 447.1 26.78 448 32V472z"/></svg>
            2 Per Meal 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-meal-3" (click)="onClick_shortcutOften('3','per-meal')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M221.6 148.7C224.7 161.3 224.8 174.5 222.1 187.2C219.3 199.1 213.6 211.9 205.6 222.1C191.1 238.6 173 249.1 151.1 254.1V472C151.1 482.6 147.8 492.8 140.3 500.3C132.8 507.8 122.6 512 111.1 512C101.4 512 91.22 507.8 83.71 500.3C76.21 492.8 71.1 482.6 71.1 472V254.1C50.96 250.1 31.96 238.9 18.3 222.4C10.19 212.2 4.529 200.3 1.755 187.5C-1.019 174.7-.8315 161.5 2.303 148.8L32.51 12.45C33.36 8.598 35.61 5.197 38.82 2.9C42.02 .602 45.97-.4297 49.89 .0026C53.82 .4302 57.46 2.303 60.1 5.259C62.74 8.214 64.18 12.04 64.16 16V160H81.53L98.62 11.91C99.02 8.635 100.6 5.621 103.1 3.434C105.5 1.248 108.7 .0401 111.1 .0401C115.3 .0401 118.5 1.248 120.9 3.434C123.4 5.621 124.1 8.635 125.4 11.91L142.5 160H159.1V16C159.1 12.07 161.4 8.268 163.1 5.317C166.6 2.366 170.2 .474 174.1 .0026C178-.4262 181.1 .619 185.2 2.936C188.4 5.253 190.6 8.677 191.5 12.55L221.6 148.7zM448 472C448 482.6 443.8 492.8 436.3 500.3C428.8 507.8 418.6 512 408 512C397.4 512 387.2 507.8 379.7 500.3C372.2 492.8 368 482.6 368 472V352H351.2C342.8 352 334.4 350.3 326.6 347.1C318.9 343.8 311.8 339.1 305.8 333.1C299.9 327.1 295.2 320 291.1 312.2C288.8 304.4 287.2 296 287.2 287.6L287.1 173.8C288 136.9 299.1 100.8 319.8 70.28C340.5 39.71 369.8 16.05 404.1 2.339C408.1 .401 414.2-.3202 419.4 .2391C424.6 .7982 429.6 2.62 433.9 5.546C438.2 8.472 441.8 12.41 444.2 17.03C446.7 21.64 447.1 26.78 448 32V472z"/></svg>
            3 Per Meal 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>

        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-week-1" (click)="onClick_shortcutOften('1','per-week')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM80 256C71.16 256 64 263.2 64 272V336C64 344.8 71.16 352 80 352H368C376.8 352 384 344.8 384 336V272C384 263.2 376.8 256 368 256H80z"/></svg>
            1 Per Week 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-week-2" (click)="onClick_shortcutOften('2','per-week')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM80 256C71.16 256 64 263.2 64 272V336C64 344.8 71.16 352 80 352H368C376.8 352 384 344.8 384 336V272C384 263.2 376.8 256 368 256H80z"/></svg>
            2 Per Week 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-week-3" (click)="onClick_shortcutOften('3','per-week')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM80 256C71.16 256 64 263.2 64 272V336C64 344.8 71.16 352 80 352H368C376.8 352 384 344.8 384 336V272C384 263.2 376.8 256 368 256H80z"/></svg>
            3 Per Week 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>

        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-month-1" (click)="onClick_shortcutOften('1','per-month')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z"/></svg>
            1 Per Month 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-month-2" (click)="onClick_shortcutOften('2','per-month')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z"/></svg>
            2 Per Month 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
        <div class="col-12 col-md-4 p-3">
          <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-month-3" (click)="onClick_shortcutOften('3','per-month')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z"/></svg>
            3 Per Month 
            <i class="fas fa-check ml-2"></i>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button (click)="editStep = 0" class="btn btn-second btn-small mr-2 mx-0">Back</button>
        <button (click)="editStep = 2" class="btn btn-third btn-small mr-2 mx-0">More Options</button>
        <button (click)="editStep = 3" class="btn btn-first btn-small mx-0">Proceed</button>
      </div>
    </div>

    <!-- Step 2 - More Options -->
    <div *ngIf="editStep == 2" id="form_view_more_option" class="dp-card mt-4">
      <h2 class="fWeight-600 mb-4 d-block text-center">Enter The Custom Amount <br> You Take of This Drug:</h2>
      <div class="row custom-amount">
        <div class="option-select col-12">
          <label for="times_taken" class="text-center d-block">I Take This Many:<br> (This will serve as quantity)</label>
          <select class="d-none form-control mx-auto max-width-247 blurred" name="times_taken" id="times_taken" formControlName="times_taken" [(ngModel)]="timesTaken">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>

            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>

            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>

            <option value="45">45</option>
            <option value="60">60</option>
            <option value="90">90</option>
            <option value="120">120</option>
            <option value="150">150</option>
            <option value="180">180</option>
            <option value="210">210</option>
            <option value="240">240</option>
            <option value="360">360</option>
          </select>
          <div class="d-flex justify-content-center">
            <mat-form-field appearance="fill" class="mat-select-blurred mat-form-field-can-float mat-form-field mat-primary mat-form-field-type-mat-select mat-form-field-appearance-fill">
              <mat-select [(value)]="timesTaken">
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>

                <mat-option [value]="11">11</mat-option>
                <mat-option [value]="12">12</mat-option>
                <mat-option [value]="13">13</mat-option>
                <mat-option [value]="14">14</mat-option>
                <mat-option [value]="15">15</mat-option>
                <mat-option [value]="16">16</mat-option>
                <mat-option [value]="17">17</mat-option>
                <mat-option [value]="18">18</mat-option>
                <mat-option [value]="19">19</mat-option>
                <mat-option [value]="20">20</mat-option>

                <mat-option [value]="21">21</mat-option>
                <mat-option [value]="22">22</mat-option>
                <mat-option [value]="23">23</mat-option>
                <mat-option [value]="24">24</mat-option>
                <mat-option [value]="25">25</mat-option>
                <mat-option [value]="26">26</mat-option>
                <mat-option [value]="27">27</mat-option>
                <mat-option [value]="28">28</mat-option>
                <mat-option [value]="29">29</mat-option>
                <mat-option [value]="30">30</mat-option>

                <mat-option [value]="45">45</mat-option>
                <mat-option [value]="60">60</mat-option>
                <mat-option [value]="90">90</mat-option>
                <mat-option [value]="120">120</mat-option>
                <mat-option [value]="150">150</mat-option>
                <mat-option [value]="180">180</mat-option>
                <mat-option [value]="210">210</mat-option>
                <mat-option [value]="240">240</mat-option>
                <mat-option [value]="360">360</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="packaging-list col-12">
          <div class="d-none">
            <input type="radio" name="often_taken" class="form-control" value="per-day" formControlName="often_taken" id="often_taken_day" [(ngModel)]="oftenTaken"> Per Day
            <input type="radio" name="often_taken" class="form-control" value="per-meal" formControlName="often_taken" id="often_taken_meal" [(ngModel)]="oftenTaken"> Per Meal
            <input type="radio" name="often_taken" class="form-control" value="per-week" formControlName="often_taken" id="often_taken_week" [(ngModel)]="oftenTaken"> Per Week
            <input type="radio" name="often_taken" class="form-control" value="per-month" formControlName="often_taken" id="often_taken_month" [(ngModel)]="oftenTaken"> Per Month
          </div>
        </div>
      </div>

      <label class="text-center d-block mt-4 col-12">This Often:</label>
      <div class="packaging-list col-12">   
        <div class="row">
          <div class="col-12 col-md-6 p-2">
            <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto" (click)="click_often_taken($event, 'day')">Per Day <i class="fas fa-check ml-2"></i></a>
          </div>
          <div class="col-12 col-md-6 p-2">
            <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto" (click)="click_often_taken($event, 'meal')">Per Meal <i class="fas fa-check ml-2"></i></a>
          </div>
          <div class="col-12 col-md-6 p-2">
            <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto" (click)="click_often_taken($event, 'week')">Per Week <i class="fas fa-check ml-2"></i></a>
          </div>
          <div class="col-12 col-md-6 p-2">
            <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto" (click)="click_often_taken($event, 'month')">Per Month <i class="fas fa-check ml-2"></i></a>
          </div>
        </div>             
      </div>
      <div class="d-flex col-12 mt-5 justify-content-center">
        <button (click)="editStep = 1" class="btn btn-third btn-small mr-2">Back</button>
        <button (click)="editStep = 3" class="btn btn-first btn-small fColor-white max-w-160">Proceed</button>
      </div>
    </div>

    <!-- Step 3 -->
    <div *ngIf="editStep == 3" id="form_view_how_often"  class="dp-card mt-4">
      <div class="row custom-amount">
        <div class="packaging-list col-12">
          <div class="d-none">
            <input type="radio" name="days_supply" class="form-control" value="30" formControlName="days_supply" id="days_supply_30"> 30 Days
            <input type="radio" name="days_supply" class="form-control" value="60" formControlName="days_supply" id="days_supply_60"> 60 Days
            <input type="radio" name="days_supply" class="form-control" value="90" formControlName="days_supply" id="days_supply_90"> 90 Days
          </div>
          <h2 class="fWeight-600 mb-4 d-block text-center">How Often Do You Get Your <br> Supply of This Drug?</h2>
          <div class="w-100 d-flex justify-content-center">
            <label>Choose one</label>
          </div>
          <div class="row">
            <div class="col-12 p-1">
              <a class="btn input-quote-btn btn-small btn-often mx-auto">30 Days <i class="fas fa-check ml-2"></i></a>
            </div>
            <div class="col-12 p-1">
              <a class="btn input-quote-btn btn-small btn-often mx-auto">60 Days <i class="fas fa-check ml-2"></i></a>
            </div>
            <div class="col-12 p-1">
              <a class="btn input-quote-btn btn-small btn-often mx-auto">90 Days <i class="fas fa-check ml-2"></i></a>
            </div> 
          </div> 
        </div>
        <div class="col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-third btn-small mr-2" (click)="editStep = 1">Back</button>
          <button class="btn btn-first btn-small mx-0" (click)="editStep = 4">Proceed</button>
        </div>
      </div>
    </div>

    <!-- Step 4 -->
    <div *ngIf="editStep == 4">
      <div class="mapd-drugs-left p-0 mx-auto">
        <div class="dp-card">
          <h4 class="text-center fWeight-600 mt-3">Specific Drug Details</h4>
          <h6 class="fColor-base fWeight-600 text-center">Confirm Your Drug</h6>
          <div>
            <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <!-- <div *ngIf="formCheck == 'tablet'">
            <img src="./assets/img/my-drugs/tablet.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <div *ngIf="formCheck == 'capsule'">
            <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <div *ngIf="formCheck == 'injection'">
            <img src="./assets/img/my-drugs/injection.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div>
          <div *ngIf="formCheck == 'meds'">
            <img src="./assets/img/my-drugs/meds.svg" alt="Capsule Icon" class="mt-0 mx-auto max-w-90">
          </div> -->
          
          <!-- default details -->
          <div class="row drug-details text-center text-md-left mt-4">
            <div class="col-12">
              <div class="row">
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Drug Name:</span>
                <span class="col-12 col-md-6 fWeight-500">Atorvastatin 80 Mg [Lipitor]</span>
              </div>
              <div class="row mt-3">
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Dosage:</span>
                <span class="col-12 col-md-6 fWeight-500">80 Mg</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Package Form:</span>
                <span class="col-12 col-md-6 fWeight-500">ORAL TABLET, FILM COATED</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Dosage Form:</span>
                <span class="col-12 col-md-6 fWeight-500">ORAL TABLET, FILM COATED</span>
              </div>
            </div>
          </div>

          <!-- additional details -->
          <div *ngIf="editStep == 4 && showMoreDrugDetails" class="row drug-details text-center text-md-left mt-4">
            <h5 class="col-12 fWeight-600 mb-2">Additional Details</h5>
            <div class="col-12">              
              <div class="row mt-3">
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Manufacturer:</span>
                <span class="col-12 col-md-6 fWeight-500">Parke-Davis Div Of Pfizer Inc</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">US Drug Release Date:</span>
                <span class="col-12 col-md-6 fWeight-500">04/07/2000</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Brand Name Version:</span>
                <span class="col-12 col-md-6 fWeight-500">Lipitor</span>
              </div>
              <div class="row mt-3"> 
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Generic Name Version:</span>
                <span class="col-12 col-md-6 fWeight-500">Atorvastatin Calcium</span>
              </div>
              <div class="row mt-3">
                <span class="fWeight-600 col-12 col-md-6 fWeight-600 fColor-black pr-3">Drug NDC:</span>
                <span class="col-12 col-md-6 fWeight-500">0071-0158</span>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <button class="btn btn-second-fill btn-small" (click)="onClick_MoreDrugDetails(showMoreDrugDetails)">{{ moreDrugDetails_text }}</button>
          </div>

          <button class="btn btn-first btn-small mx-auto mt-4">Save</button>
        </div>
      </div>
    </div>

  </div>

</div>

<ng-container *ngIf="existingPharmacies_list && existingDrugs_list">
  <div *ngIf="existingPharmacies_list.length && existingDrugs_list.length" class="d-none done-modal">
    <h5 class="text-center">Done adding your Drugs and Pharmacies?</h5>
    <h6 class="text-center text-secondary">Click here to proceed!</h6>
    <div class="d-flex flex-column justify-content-center mt-2">
      <!-- <button class="btn btn-first btn-small mx-auto my-1 w-100" (click)="onClick_FullRxProfile(saveYourFullRxProfile)" *ngIf="!isUserLoggedIn">Save RX Profile</button> -->
      <button class="btn btn-first btn-small mx-auto my-1 w-100" *ngIf="!isUserLoggedIn" (click)="showLoginModal(loginModal)">Save RX Profile</button>
      
      <button class="btn btn-first btn-small mx-auto my-1 w-100" (click)="onClick_FullRxProfile(yourFullRxProfile)" *ngIf="isUserLoggedIn">View RX Profile</button>
      <!-- <button class="btn btn-first btn-small mx-auto mt-3" (click)="onClick_DoneAddingDrugsPharma()">Finish Adding Drugs & Pharmacies</button> -->
      <!-- <button class="btn btn-first btn-small mx-auto my-1 w-100" routerLink="/registration">Save RX Profile</button> -->
      <!-- <button class="btn btn-first btn-small mx-auto my-1 w-100">View RX Profile</button> -->
      <button class="btn btn-first btn-small mx-auto my-1 w-100" (click)="onClick_PharmacyResultProceed(monthCoverageEffective)">View Drug Cost</button>
    </div>
  </div>
</ng-container>

<div class="d-none">
  <div #monthCoverageEffective>
    <ng-container *ngIf="!viewMonthCoverage">
      <h4 class="text-center mb-2">Your Drug Costs Analysis</h4>
      <div class="row">
        <div class="col-12 p-3">
          <button class="btn btn-first mx-auto max-w-fit" (click)="onClick_drugCostAnalysisType('A')">View Analysis For Your Selected Plan</button>
        </div>
        <div class="col-12 p-3">
          <button class="btn btn-first mx-auto max-w-fit" (click)="onClick_drugCostAnalysisType('B')">View Drug Costs Analysis For All Available Plans</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="viewMonthCoverage">
      <h5 class="text-center uppercase mb-4">What Month Will Your Coverage Be Effective?</h5>
      <!-- <mat-form-field class="mx-auto max-w-fit d-block" >
          <mat-label>Select month</mat-label>
          <mat-select [(ngModel)]="selectedMonth" (selectionChange)="monthChanged($event.value)">
          <mat-option *ngFor="let month of months" [value]="month">
            {{month}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <div class="d-none">
        <input type="radio" name="selected_month" class="form-control" value="January" formControlName="selected_month" id="selected_month_january"> January
        <input type="radio" name="selected_month" class="form-control" value="February" formControlName="selected_month" id="selected_month_february"> February
        <input type="radio" name="selected_month" class="form-control" value="March" formControlName="selected_month" id="selected_month_march"> March
        <input type="radio" name="selected_month" class="form-control" value="April" formControlName="selected_month" id="selected_month_april"> April
        <input type="radio" name="selected_month" class="form-control" value="May" formControlName="selected_month" id="selected_month_may"> May
        <input type="radio" name="selected_month" class="form-control" value="June" formControlName="selected_month" id="selected_month_june"> June
        <input type="radio" name="selected_month" class="form-control" value="July" formControlName="selected_month" id="selected_month_july"> July
        <input type="radio" name="selected_month" class="form-control" value="August" formControlName="selected_month" id="selected_month_august"> August
        <input type="radio" name="selected_month" class="form-control" value="September" formControlName="selected_month" id="selected_month_september"> September
        <input type="radio" name="selected_month" class="form-control" value="October" formControlName="selected_month" id="selected_month_october"> October
        <input type="radio" name="selected_month" class="form-control" value="November" formControlName="selected_month" id="selected_month_november"> November
        <input type="radio" name="selected_month" class="form-control" value="December" formControlName="selected_month" id="selected_month_december"> December
      </div> -->
      <div class="row">
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'January')" [class.passed]="0 < currentMonthNumber">January <i class="fas fa-check ml-2"></i></a>
        </div>
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'February')" [class.passed]="1 < currentMonthNumber">February <i class="fas fa-check ml-2"></i></a>
        </div>
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'March')" [class.passed]="2 < currentMonthNumber">March <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'April')" [class.passed]="3 < currentMonthNumber">April <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'May')" [class.passed]="4 < currentMonthNumber">May <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'June')" [class.passed]="5 < currentMonthNumber">June <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'July')" [class.passed]="6 < currentMonthNumber">July <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'August')" [class.passed]="7 < currentMonthNumber">August <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'September')" [class.passed]="8 < currentMonthNumber">September <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'October')" [class.passed]="9 < currentMonthNumber">October <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'November')" [class.passed]="10 < currentMonthNumber">November <i class="fas fa-check ml-2"></i></a>
        </div> 
        <div class="col-12 col-md-3 p-1">
          <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="select_pharmacy($event, 'December')">December <i class="fas fa-check ml-2"></i></a>
        </div> 
      </div> 
      <div class="invalid-feedback d-block text-center my-4" *ngIf="!isCoverageMonthSelected && isClickMonthProceed">
        Please choose a month first before you proceed.
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-third mt-3 btn-small choose-pharmacy-results-item-btn mr-2" (click)="viewMonthCoverage = false">Back</button>
        <button class="btn btn-first mt-3 btn-small choose-pharmacy-results-item-btn" (click)="onClick_Monthproceed()">Proceed</button>
      </div>      
    </ng-container>
  </div>

  <div #registerModal>
    <h4 class="text-center mb-2">Your Full RX Profile</h4>
    <div class="d-flex justify-content-center mb-4">
      <img src="./assets/img/my-drugs/safety-kit-supply.JPG" alt="Your Full RX Profile" class="rx-icon mx-auto">
    </div>
    <form [formGroup]="registerForm">
      <div class="form-row">
        <div class="form-group col-12 col-md-6">
          <label for="fullname">Full Name</label>
          <input type="text" id="fullname" class="form-control blurred" placeholder="Enter fullname..." formControlName="fullname">
        </div>    
        <div class="form-group col-12 col-md-6">
          <label for="dateofbirth">Date of Birth</label>
          <input type="date" id="dateofbirth" class="form-control blurred" formControlName="dateofbirth">
        </div>  
      </div>
      <div class="form-row">
        <div class="form-group col-12 w-100 mb-4" style="max-width: 100% !important;">
          <label for="contact">Email or Phone Number</label>
          <input type="text" id="contact" class="form-control blurred" placeholder="Enter email or phone number here..." formControlName="contact">
        </div> 
      </div>
      <div class="d-flex align-items-center justify-content-end mb-4">
        <button class="btn btn-first btn-small">Register</button>
      </div>
      <div class="row justify-content-center login-separator">
        <label> OR </label>
      </div>
      <div class="col-12 d-flex justify-content-center">
          <div class="verifyReview_icon fb" title="Login to Facebook"></div>
          <div class="verifyReview_icon twitter" title="Login to Twitter"></div>  
          <div class="verifyReview_icon google" title="Login to Google"></div>
      </div>
    </form>
    <div class="box-bottom d-flex justify-content-between align-items-center mt-3">
        <a id="dontHaveAccountBtn" class="btn" routerLink="/registration">Don't have an account? Register Here</a>
    </div>
  </div>

  <div #loginModal>    
    <app-common-login-form *ngIf="showLoginForm" (showSignUpForm)="onClick_ShowSignUpForm()"></app-common-login-form>
    <app-common-signup-form *ngIf="showSignupForm" (showLoginForm)="onClick_ShowLoginForm()"></app-common-signup-form>
  </div>

  <div #deleteConfirmationModal>
    <div class="row justify-content-center mb-2">
      <img class="delete-icon" src="./assets/img/common/delete-icon.png" alt="">
    </div>
    <h4 class="text-center">Are You Sure That You Want To Delete This Drug?</h4>
  </div>

  <div #deletePharmConfirmationModal>
    <div class="row justify-content-center mb-2">
      <img class="delete-icon" src="./assets/img/common/delete-pharm-icon.png" alt="">
    </div>
    <h4 class="text-center">Are You Sure That You Want To Delete This Pharmacy?</h4>
  </div>

  <div class="d-none">
    <div class="rx-modal" #yourFullRxProfile>
      <h4 class="text-center">You will be redirected to your Benny Dash Default Marketplace Drug List</h4>
      <button class="btn btn-first mt-5 mx-auto" (click)="onClick_ProceedToRxProile()">Proceed</button>
    </div>
  </div>

  <div class="rx-modal d-none">
    <!-- <app-your-full-rx-profile></app-your-full-rx-profile> -->
    <div class="d-flex justify-content-center">
      <img class="mx-auto rx-icon mb-3" src="./assets/img/my-drugs/safety-kit-supply.JPG" alt="Your Full RX Profile">
    </div>
    <div class="my-2" *ngIf="!registeredLoggedIn && !registeredNotLoggedIn && !notRegistered">
      <h5 class="text-center">These Buttons Are For Testing Purposes Only</h5>
      <button class="btn btn-first btn-small mb-2 mx-auto max-w-button" (click)="registeredLoggedIn = true && saveSelection = true">Registered & Logged In</button>
      <button class="btn btn-first btn-small mb-2 mx-auto max-w-button" (click)="registeredNotLoggedIn = true && saveSelection = true">Registered But Not Logged In</button>
      <button class="btn btn-first btn-small mb-2 mx-auto max-w-button" (click)="notRegistered = true && saveSelection = true">Not Registered</button>
    </div>
    <!-- IF THE USER IS LOGGED IN -->
    <div *ngIf="registeredLoggedIn">
      <div *ngIf="saveSelection">
        <div *ngIf="saveSelectionStep == 1" class="my-2">
          <h4 class="text-center">Would You Like to Save This List First?</h4>
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-second btn-small mx-2" (click)="saveSelectionStep = 2">Yes</button>
            <button class="btn btn-second btn-small mx-2">No</button>
          </div>
        </div>
        <div *ngIf="saveSelectionStep == 2" class="my-2">
          <h4 class="text-center">Would You Like to Save This List As A New List or Add To An Existing List?</h4>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveNewList()">Save This List as a New List</button>
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveExistingList()">Add To An Existing List</button>
          </div>
        </div>
      </div>
      <!-- ADD TO NEW LIST -->
      <div *ngIf="newList">
        <div class="my-2">
          <div *ngIf="newListStep == 1">
            <h4 class="text-center">Title Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Title</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter title here...">
            </div>
          </div>
          <div *ngIf="newListStep == 2">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="newListStep == 3">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="newListStep == 1" [class.disabled-hidden]="newListStep == 1" (click)="newListStep = newListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="newListStep == 3" (click)="newListStep = newListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="newListStep == 3">Finish</button>
          </div>
        </div>
      </div>
      <!-- ADD TO EXISTING LIST -->
      <div *ngIf="existingList">
        <div class="my-2">
          <div *ngIf="existingListStep == 1">
            <h4 class="text-center">Add New Drugs To An Existing List in Your Rx Profile</h4>
            <p class="font-weight-bold text-center">Choose Your Existing List To Add To</p>
            <div class="d-flex align-items-center justify-content-center">
              <select class="form-control input-field">
                <option value="" selected disabled>- Select RX Profile List -</option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
            </div>
          </div>
          <div *ngIf="existingListStep == 2">
            <h4 class="text-center">Would You Like To Update The Title of Your List?</h4>
            <p class="font-weight-bold text-center">Click Change to Update <span>(Click Next if No Changes are needed)</span></p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" value="Drug List Name">
              <button class="px-3 py-2 bg-primary text-white h-100 border-0">Change</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 3">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 4">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="existingListStep == 1" [class.disabled-hidden]="existingListStep == 1" (click)="existingListStep = existingListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 4">
                <span class="font-weight-bold">4</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="existingListStep == 4" (click)="existingListStep = existingListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="existingListStep == 4">Finish</button>
          </div>
        </div>
      </div>
    </div>
    <!-- IF THE USER IS REGISTERED BUT NOT LOGGED IN -->
    <div *ngIf="registeredNotLoggedIn">
      <div *ngIf="saveSelection">
        <div *ngIf="saveSelectionStep == 1" class="my-2">
          <h4 class="text-center">Would You Like to Save This List First?</h4>
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-second btn-small mx-2" (click)="saveSelectionStep = 2">Yes</button>
            <button class="btn btn-second btn-small mx-2">No</button>
          </div>
        </div>
        <div *ngIf="saveSelectionStep == 2" class="my-2">
          <h4 class="text-center">Would You Like to Save This List As A New List or Add To An Existing List?</h4>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveNewList()">Save This List as a New List</button>
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="showLoginModal(loginModal)">Add To An Existing List</button> 
          </div>
        </div>
      </div>
      <div *ngIf="newList">
        <div class="my-2">
          <div *ngIf="newListStep == 1">
            <h4 class="text-center">Title Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Title</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter title here...">
            </div>
          </div>
          <div *ngIf="newListStep == 2">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="newListStep == 3">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="newListStep == 1" [class.disabled-hidden]="newListStep == 1" (click)="newListStep = newListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="newListStep == 3" (click)="newListStep = newListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="newListStep == 3" (click)="showLoginModal(loginModal)">Finish</button>
          </div>
        </div>
      </div>
      <div *ngIf="existingList">
        <div class="my-2">
          <div *ngIf="existingListStep == 1">
            <h4 class="text-center">Add New Drugs To An Existing List in Your Rx Profile</h4>
            <p class="font-weight-bold text-center">Choose Your Existing List To Add To</p>
            <div class="d-flex align-items-center justify-content-center">
              <select class="form-control input-field">
                <option value="" selected disabled>- Select RX Profile List -</option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
            </div>
          </div>
          <div *ngIf="existingListStep == 2">
            <h4 class="text-center">Would You Like To Update The Title of Your List?</h4>
            <p class="font-weight-bold text-center">Click Change to Update <span>(Click Next if No Changes are needed)</span></p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" value="Drug List Name">
              <button class="px-3 py-2 bg-primary text-white h-100 border-0">Change</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 3">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 4">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="existingListStep == 1" [class.disabled-hidden]="existingListStep == 1" (click)="existingListStep = existingListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 4">
                <span class="font-weight-bold">4</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="existingListStep == 4" (click)="existingListStep = existingListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="existingListStep == 4">Finish</button>
          </div>
        </div>
      </div>
    </div>
    <!-- IF THE USER IS NOT REGISTERED -->
    <div *ngIf="notRegistered">
      <div *ngIf="saveSelection">
        <div *ngIf="saveSelectionStep == 1" class="my-2">
          <h4 class="text-center">Would You Like to Save This List First?</h4>
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-second btn-small mx-2" (click)="saveSelectionStep = 2">Yes</button>
            <button class="btn btn-second btn-small mx-2">No</button>
          </div>
        </div>
        <div *ngIf="saveSelectionStep == 2" class="my-2">
          <h4 class="text-center">Would You Like to Save This List As A New List or Add To An Existing List?</h4>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveNewList()">Save This List as a New List</button>
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="showLoginModal(registerModal)">Add To An Existing List</button> 
          </div>
        </div>
      </div>
      <div *ngIf="newList">
        <div class="my-2">
          <div *ngIf="newListStep == 1">
            <h4 class="text-center">Title Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Title</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter title here...">
            </div>
          </div>
          <div *ngIf="newListStep == 2">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="newListStep == 3">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="newListStep == 1" [class.disabled-hidden]="newListStep == 1" (click)="newListStep = newListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="newListStep == 3" (click)="newListStep = newListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="newListStep == 3" (click)="showLoginModal(registerModal)">Finish</button>
          </div>
        </div>
      </div>
      <div *ngIf="existingList">
        <div class="my-2">
          <div *ngIf="existingListStep == 1">
            <h4 class="text-center">Add New Drugs To An Existing List in Your Rx Profile</h4>
            <p class="font-weight-bold text-center">Choose Your Existing List To Add To</p>
            <div class="d-flex align-items-center justify-content-center">
              <select class="form-control input-field">
                <option value="" selected disabled>- Select RX Profile List -</option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
            </div>
          </div>
          <div *ngIf="existingListStep == 2">
            <h4 class="text-center">Would You Like To Update The Title of Your List?</h4>
            <p class="font-weight-bold text-center">Click Change to Update <span>(Click Next if No Changes are needed)</span></p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" value="Drug List Name">
              <button class="px-3 py-2 bg-primary text-white h-100 border-0">Change</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 3">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 4">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="existingListStep == 1" [class.disabled-hidden]="existingListStep == 1" (click)="existingListStep = existingListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 4">
                <span class="font-weight-bold">4</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="existingListStep == 4" (click)="existingListStep = existingListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="existingListStep == 4">Finish</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="rx-modal" #saveYourFullRxProfile>
    <!-- <app-your-full-rx-profile [isTypeView]="false"></app-your-full-rx-profile> -->
    <div class="d-flex justify-content-center">
      <img class="mx-auto rx-icon mb-3" src="./assets/img/my-drugs/safety-kit-supply.JPG" alt="Your Full RX Profile">
    </div>
    <div class="my-2" *ngIf="!registeredLoggedIn && !registeredNotLoggedIn && !notRegistered">
      <h5 class="text-center">These Buttons Are For Testing Purposes Only</h5>
      <button class="btn btn-first btn-small mb-2 mx-auto max-w-button" (click)="registeredLoggedIn = true && saveSelection = true">Registered & Logged In</button>
      <button class="btn btn-first btn-small mb-2 mx-auto max-w-button" (click)="registeredNotLoggedIn = true && saveSelection = true">Registered But Not Logged In</button>
      <button class="btn btn-first btn-small mb-2 mx-auto max-w-button" (click)="notRegistered = true && saveSelection = true">Not Registered</button>
    </div>
    <!-- IF THE USER IS LOGGED IN -->
    <div *ngIf="registeredLoggedIn">
      <div *ngIf="saveSelection">
        <div *ngIf="saveSelectionStep == 1" class="my-2">
          <h4 class="text-center">Would You Like to Save This List First?</h4>
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-second btn-small mx-2" (click)="saveSelectionStep = 2">Yes</button>
            <button class="btn btn-second btn-small mx-2">No</button>
          </div>
        </div>
        <div *ngIf="saveSelectionStep == 2" class="my-2">
          <h4 class="text-center">Would You Like to Save This List As A New List or Add To An Existing List?</h4>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveNewList()">Save This List as a New List</button>
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveExistingList()">Add To An Existing List</button>
          </div>
        </div>
      </div>
      <!-- ADD TO NEW LIST -->
      <div *ngIf="newList">
        <div class="my-2">
          <div *ngIf="newListStep == 1">
            <h4 class="text-center">Title Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Title</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter title here...">
            </div>
          </div>
          <div *ngIf="newListStep == 2">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="newListStep == 3">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="newListStep == 1" [class.disabled-hidden]="newListStep == 1" (click)="newListStep = newListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="newListStep == 3" (click)="newListStep = newListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="newListStep == 3">Finish</button>
          </div>
        </div>
      </div>
      <!-- ADD TO EXISTING LIST -->
      <div *ngIf="existingList">
        <div class="my-2">
          <div *ngIf="existingListStep == 1">
            <h4 class="text-center">Add New Drugs To An Existing List in Your Rx Profile</h4>
            <p class="font-weight-bold text-center">Choose Your Existing List To Add To</p>
            <div class="d-flex align-items-center justify-content-center">
              <select class="form-control input-field">
                <option value="" selected disabled>- Select RX Profile List -</option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
            </div>
          </div>
          <div *ngIf="existingListStep == 2">
            <h4 class="text-center">Would You Like To Update The Title of Your List?</h4>
            <p class="font-weight-bold text-center">Click Change to Update <span>(Click Next if No Changes are needed)</span></p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" value="Drug List Name">
              <button class="px-3 py-2 bg-primary text-white h-100 border-0">Change</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 3">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 4">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="existingListStep == 1" [class.disabled-hidden]="existingListStep == 1" (click)="existingListStep = existingListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 4">
                <span class="font-weight-bold">4</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="existingListStep == 4" (click)="existingListStep = existingListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="existingListStep == 4">Finish</button>
          </div>
        </div>
      </div>
    </div>
    <!-- IF THE USER IS REGISTERED BUT NOT LOGGED IN -->
    <div *ngIf="registeredNotLoggedIn">
      <div *ngIf="saveSelection">
        <div *ngIf="saveSelectionStep == 1" class="my-2">
          <h4 class="text-center">Would You Like to Save This List First?</h4>
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-second btn-small mx-2" (click)="saveSelectionStep = 2">Yes</button>
            <button class="btn btn-second btn-small mx-2">No</button>
          </div>
        </div>
        <div *ngIf="saveSelectionStep == 2" class="my-2">
          <h4 class="text-center">Would You Like to Save This List As A New List or Add To An Existing List?</h4>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveNewList()">Save This List as a New List</button>
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="showLoginModal(loginModal)">Add To An Existing List</button> 
          </div>
        </div>
      </div>
      <div *ngIf="newList">
        <div class="my-2">
          <div *ngIf="newListStep == 1">
            <h4 class="text-center">Title Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Title</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter title here...">
            </div>
          </div>
          <div *ngIf="newListStep == 2">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="newListStep == 3">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="newListStep == 1" [class.disabled-hidden]="newListStep == 1" (click)="newListStep = newListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="newListStep == 3" (click)="newListStep = newListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="newListStep == 3" (click)="showLoginModal(loginModal)">Finish</button>
          </div>
        </div>
      </div>
      <div *ngIf="existingList">
        <div class="my-2">
          <div *ngIf="existingListStep == 1">
            <h4 class="text-center">Add New Drugs To An Existing List in Your Rx Profile</h4>
            <p class="font-weight-bold text-center">Choose Your Existing List To Add To</p>
            <div class="d-flex align-items-center justify-content-center">
              <select class="form-control input-field">
                <option value="" selected disabled>- Select RX Profile List -</option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
            </div>
          </div>
          <div *ngIf="existingListStep == 2">
            <h4 class="text-center">Would You Like To Update The Title of Your List?</h4>
            <p class="font-weight-bold text-center">Click Change to Update <span>(Click Next if No Changes are needed)</span></p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" value="Drug List Name">
              <button class="px-3 py-2 bg-primary text-white h-100 border-0">Change</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 3">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 4">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="existingListStep == 1" [class.disabled-hidden]="existingListStep == 1" (click)="existingListStep = existingListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 4">
                <span class="font-weight-bold">4</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="existingListStep == 4" (click)="existingListStep = existingListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="existingListStep == 4">Finish</button>
          </div>
        </div>
      </div>
    </div>
    <!-- IF THE USER IS NOT REGISTERED -->
    <div *ngIf="notRegistered">
      <div *ngIf="saveSelection">
        <div *ngIf="saveSelectionStep == 1" class="my-2">
          <h4 class="text-center">Would You Like to Save This List First?</h4>
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-second btn-small mx-2" (click)="saveSelectionStep = 2">Yes</button>
            <button class="btn btn-second btn-small mx-2">No</button>
          </div>
        </div>
        <div *ngIf="saveSelectionStep == 2" class="my-2">
          <h4 class="text-center">Would You Like to Save This List As A New List or Add To An Existing List?</h4>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="saveNewList()">Save This List as a New List</button>
            <button class="btn btn-first btn-small mb-2 mx-2 max-w-button" (click)="showLoginModal(registerModal)">Add To An Existing List</button> 
          </div>
        </div>
      </div>
      <div *ngIf="newList">
        <div class="my-2">
          <div *ngIf="newListStep == 1">
            <h4 class="text-center">Title Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Title</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter title here...">
            </div>
          </div>
          <div *ngIf="newListStep == 2">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="newListStep == 3">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="newListStep == 1" [class.disabled-hidden]="newListStep == 1" (click)="newListStep = newListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="newListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="newListStep == 3" (click)="newListStep = newListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="newListStep == 3" (click)="showLoginModal(registerModal)">Finish</button>
          </div>
        </div>
      </div>
      <div *ngIf="existingList">
        <div class="my-2">
          <div *ngIf="existingListStep == 1">
            <h4 class="text-center">Add New Drugs To An Existing List in Your Rx Profile</h4>
            <p class="font-weight-bold text-center">Choose Your Existing List To Add To</p>
            <div class="d-flex align-items-center justify-content-center">
              <select class="form-control input-field">
                <option value="" selected disabled>- Select RX Profile List -</option>
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
              </select>
            </div>
          </div>
          <div *ngIf="existingListStep == 2">
            <h4 class="text-center">Would You Like To Update The Title of Your List?</h4>
            <p class="font-weight-bold text-center">Click Change to Update <span>(Click Next if No Changes are needed)</span></p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" value="Drug List Name">
              <button class="px-3 py-2 bg-primary text-white h-100 border-0">Change</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 3">
            <h4 class="text-center">Designate This List As Your New �Prime� List or a �Reserve List�</h4>
            <p class="font-weight-bold text-center">Choose One Below</p>
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-second btn-small max-w-button2 mx-2">Prime</button>
              <button class="btn btn-second btn-small max-w-button2 mx-2">Reserve</button>
            </div>
          </div>
          <div *ngIf="existingListStep == 4">
            <h4 class="text-center">Tag Your List</h4>
            <p class="font-weight-bold text-center">Enter Your Tags</p>
            <div class="d-flex align-items-center justify-content-center">
              <input class="form-control input-field" type="text" placeholder="Enter tags here...">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-5" >
            <button class="btn btn-third btn-xs" [disabled]="existingListStep == 1" [class.disabled-hidden]="existingListStep == 1" (click)="existingListStep = existingListStep - 1">Back</button>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 1">
                <span class="font-weight-bold">1</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 2">
                <span class="font-weight-bold">2</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 3">
                <span class="font-weight-bold">3</span>
              </div>
              <div class="mx-2 page-indicator d-flex align-items-center justify-content-center" [class.page-active-indicator]="existingListStep == 4">
                <span class="font-weight-bold">4</span>
              </div>
            </div>
            <button class="btn btn-first btn-xs" [class.d-none]="existingListStep == 4" (click)="existingListStep = existingListStep + 1">Next</button>
            <button class="btn btn-first btn-xs d-none" [class.d-block]="existingListStep == 4">Finish</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>