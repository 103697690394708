<h4 class="title mb-3">Final Questions</h4>

<div class="col-12 mb-4">
  <p class="fWeight-600 fColor-black mb-0">Do you work?</p>
  <div class="custom-control custom-radio custom-control-inline">
    <input type="radio" id="doYouWork_1" name="doYouWork" class="custom-control-input" value="yes" [(ngModel)]="doYouWork" (change)="onChange_doYouWork($event.target.value)">
    <label class="custom-control-label" for="doYouWork_1">Yes</label>
  </div>
  <div class="custom-control custom-radio custom-control-inline">
    <input type="radio" id="doYouWork_2" name="doYouWork" class="custom-control-input" value="no" [(ngModel)]="doYouWork" (change)="onChange_doYouWork($event.target.value)" checked="true">
    <label class="custom-control-label" for="doYouWork_2">No</label>
  </div>
</div>

<div class="col-12 mb-4">
  <p class="fWeight-600 fColor-black mb-0">Does your spouse work?</p>
  <div class="custom-control custom-radio custom-control-inline">
    <input type="radio" id="doesYourSpouseWork_1" name="doesYourSpouseWork" class="custom-control-input" value="yes" [(ngModel)]="doesYourSpouseWork" (change)="onChange_doesYourSpouseWork()">
    <label class="custom-control-label" for="doesYourSpouseWork_1">Yes</label>
  </div>
  <div class="custom-control custom-radio custom-control-inline">
    <input type="radio" id="doesYourSpouseWork_2" name="doesYourSpouseWork" class="custom-control-input" value="no" [(ngModel)]="doesYourSpouseWork" (change)="onChange_doesYourSpouseWork()" checked="true">
    <label class="custom-control-label" for="doesYourSpouseWork_2">No</label>
  </div>
</div>

<div class="row bordered p-3">
  <div class="col-12">    
    <p class="fWeight-600 fColor-black">Will you have other prescription drug coverage (like VA, TRICARE) in addition to this Medicare Advantage Plan, [Medicare Advantage Plan Name]?</p>  
    <div class="d-flex">
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="haveOtherPrescriptionDrug_1" name="haveOtherPrescriptionDrug" class="custom-control-input" value="yes" [(ngModel)]="haveOtherPrescriptionDrug" (change)="onChange_haveOtherPrescriptionDrug($event.target.value)">
        <label class="custom-control-label" for="haveOtherPrescriptionDrug_1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="haveOtherPrescriptionDrug_2" name="haveOtherPrescriptionDrug" class="custom-control-input" value="no" [(ngModel)]="haveOtherPrescriptionDrug" (change)="onChange_haveOtherPrescriptionDrug($event.target.value)" checked="true">
        <label class="custom-control-label" for="haveOtherPrescriptionDrug_2">No</label>
      </div>
    </div>        
  </div>
  <div class="col-12 mt-4" *ngIf="isShowHaveOtherPrescriptionDrug">
    <div class="row px-0">
      <div class="col-12 col-sm-4 pr-2">
          <div class="form-group">
              <label for="coverage_name">Name of other coverage</label>
              <input type="text" class="form-control blurred" id="coverage_name"/>
          </div>        
      </div>
      <div class="col-12 col-sm-4">
          <div class="form-group">
              <label for="coverage_id">ID # for this coverage</label>
              <input type="text" class="form-control blurred" id="coverage_id"/>
          </div>
      </div>
      <div class="col-12 col-sm-4 pl-2">
          <div class="form-group">
              <label for="coverage_group">Group # for this coverage:</label>
              <input type="text" class="form-control blurred" id="coverage_group"/>
          </div>
      </div>
  </div> 
  </div>
</div>
<div class="row bordered p-3 mt-4">
  <div class="col-12">    
    <p class="fWeight-600 fColor-black">Are you a resident in a long-term care facility, such as a nursing home?</p>  
    <div class="d-flex">
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="isLongTermResident_1" name="isLongTermResident" class="custom-control-input" value="yes" [(ngModel)]="isLongTermResident" (change)="onChange_isLongTermResident($event.target.value)">
        <label class="custom-control-label" for="isLongTermResident_1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="isLongTermResident_2" name="isLongTermResident" class="custom-control-input" value="no" [(ngModel)]="isLongTermResident" (change)="onChange_isLongTermResident($event.target.value)" checked="true">
        <label class="custom-control-label" for="isLongTermResident_2">No</label>
      </div>
    </div> 
  </div>
  <div class="col-12" *ngIf="isShowLongTermResident">
    <div class="row px-0">
      <div class="col-12">
        <h5>Please provide the following information:</h5>
      </div>
      <div class="col-12 pr-2">
          <div class="form-group">
              <label for="legalFirstName">Name of Institution:</label>
              <input type="text" class="form-control blurred" id="legalFirstName"/>
          </div>        
      </div>
      <div class="col-12">            
          <div class="form-group">
              <label for="legalMiddleName">Address & Phone Number of Institution (number and street):</label>
              <textarea name="" id="" class="form-control blurred" cols="30" rows="10"></textarea>
          </div>
      </div>
    </div> 
  </div>
</div>


  <!-- <h4 class="title">Please Read and Sign</h4>
  <h6 class="mb-4 fWeight-600 fColor-black">By completing this form, I agree to the following:</h6>
  <div class="row px-0">
    <div class="col-12">
        <div class="form-group">     
          <div class="custom-control custom-checkbox mt-2">
              <input type="checkbox" class="custom-control-input" id="thisIsAMedicareAdvantagePlan">
              <label class="custom-control-label" for="thisIsAMedicareAdvantagePlan">This is a Medicare Advantage plan. It has a contract with the federal government. This is not a Medicare Supplement plan</label>
          </div>
        </div>
    </div>
    <div class="col-12">
      <div class="form-group">     
        <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="iMustKeepBothPartAB">
            <label class="custom-control-label" for="iMustKeepBothPartAB">I must keep both Part A and Part B to stay in UnitedHealthcare. I must keep paying my Part B premium if I have one, unless Medicaid or someone else pays for it.</label>
        </div>
      </div>
    </div>
  </div> -->
