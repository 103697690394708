import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-application-medigap-version1-screen3',
  templateUrl: './screen3.component.html',
  styleUrls: ['./screen3.component.scss']
})
export class Screen3Component implements OnInit {

  thisIsMyMailing: any = false;

  constructor() { }

  ngOnInit() {
  }

}
