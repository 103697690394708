import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroupDirective,
    NgForm,
    Validators,
    FormsModule,
    ReactiveFormsModule,
    FormGroup,
    FormArray,
    AbstractControl
} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {STATES} from 'src/app/services/state.service';
import {APIService} from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import * as _ from 'lodash';


@Component({
  selector: 'app-apply-online',
  templateUrl: './apply-online.component.html',
  styleUrls: ['./apply-online.component.scss']
})

export class ApplyOnlineComponent implements OnInit {
    public states = STATES;
    public results: any;
    public loading: boolean;
    public showForm: boolean = true;
    public productInformation: any;
    public isShowRequiredField: boolean = false;
    public isRequiredForm_phone_number_empty: boolean = false;
    weightValues = [80,90,100,110,120,130,140,150,160,170,180,190,200,210,220,230,240,250,260,270,280,290,300,310,320,330,340,350];
    dateValues: any  = {
        dob: '',
        part_a_effective_date: '',
        part_b_effective_date: '',
        new_coverage_effective_date: '',
        requested_date_of_health_interview: ''
    };

    arrayControl: any;

    applyOnlineForm: FormGroup;

    applyOnlineRequiredForm: FormGroup;

    applyOnlineNewFormControl:any;

    applyOnlineNewForm: FormGroup;
    
    aboutYouEmail: AbstractControl;
    
     /** Returns a FormArray with the name 'formArray'. */
    get formArray(): AbstractControl | null { return this.applyOnlineForm.get('formArray'); }

    bsConfig: Partial<BsDatepickerConfig> = {
        containerClass: 'theme-blue',
        dateInputFormat: 'MM/DD/YYYY'
    };

    constructor(private _api: APIService, private route: ActivatedRoute) {
        Swal.close();
        this.route.queryParams.subscribe(params => {
            this.productInformation = params;
        });
    }

    ngOnInit() {

        this.applyOnlineForm = new FormGroup({

            formArray: new FormGroup({

                basicInfo: new FormGroup({
                    first_name: new FormControl('', [Validators.required]),
                    last_name: new FormControl('', [Validators.required]),
                    dob: new FormControl('', [Validators.required]),
                    gender: new FormControl('', [Validators.required]),
                    marital_status: new FormControl('', [Validators.required])

                }),

                medicareInfo: new FormGroup({
                    part_a_effective_date: new FormControl('', [Validators.required]),
                    part_b_effective_date: new FormControl('', [Validators.required]),
                    medicare_claim_number: new FormControl('', [Validators.required]),
                    social_security_number: new FormControl('', [Validators.required]),
                    /*part_b_in_last_6_months: new FormControl('', [Validators.required]),*/
                    quality_for_garrenteed_issue: new FormControl('', [Validators.required]),
                    new_coverage_effective_date: new FormControl('', [Validators.required])

                }),

                aboutYou: new FormGroup({
                    // state_of_birth: new FormControl('', [Validators.required]),
                    height: new FormControl('', [Validators.required]),
                    weight: new FormControl('', [Validators.required]),
                    street_address: new FormControl('', [Validators.required]),
                    city_town: new FormControl('', [Validators.required]),
                    state: new FormControl('', [Validators.required]),
                    zip_code: new FormControl('', [Validators.required]),
                    phone_number: new FormControl('', [Validators.required]),
                    alternative_phone_number: new FormControl('', [Validators.required]),
                    email: new FormControl('', [Validators.email]),
                    tobacco_use: new FormControl('', [Validators.required]),
                    /*current_medicare_suppliment: new FormControl('', [Validators.required]),*/
                    /*current_insurance_company: new FormControl('', [Validators.required]),*/
                    /*current_plan: new FormControl('', [Validators.required]),*/
                    take_prescription_medication: new FormControl('', []),
                    prescription_drug_name1: new FormControl('', []),
                    prescription_drug_name2: new FormControl('', []),
                    prescription_drug_name3: new FormControl('', []),
                    prescription_drug_name4: new FormControl('', [])
                }),

                paymentInfo: new FormGroup({
                    payment_mode: new FormControl('', [Validators.required]),
                    payment_method: new FormControl('', [Validators.required]),
                    bank_name: new FormControl('', [Validators.required]),
                    routing_number: new FormControl('', [Validators.required]),
                    account_number: new FormControl('', [Validators.required]),
                    requested_date_of_health_interview: new FormControl('', [Validators.required]),
                    requested_time_for_health_interview: new FormControl('', [Validators.required]),
                    additional_notes: new FormControl('', [])
                }),

                confirmation: new FormGroup({})
            })
        });


        this.applyOnlineRequiredForm = new FormGroup({
            email: new FormControl('', [Validators.required]),
            phone_number: new FormControl('', [Validators.required]),
        });

        const aboutYouFormGroup = this.formArray.get('aboutYou');
        this.aboutYouEmail = aboutYouFormGroup.get('email');
    }

    // GET EACH FORMGROUPNAME AS JSON

    get applyOnlineRequiredForm_formControl_phone_number(): any {
        return this.applyOnlineRequiredForm.get('phone_number');
    }

    get applyOnlineRequiredForm_formControl_email(): any {
        return this.applyOnlineRequiredForm.get('email');
    }

    get basicInfo_formControl(): any {
        return this.formArray.get('basicInfo');
    }

    get medicareInfo_formControl(): any {
        return this.formArray.get('medicareInfo');
    }

    get aboutYou_formControl(): any {
        return this.formArray.get('aboutYou');
    }

    get paymentInfo_formControl(): any {
        return this.formArray.get('paymentInfo');
    }

    //CLICK FUNCTION
    onSchedClick(isRequiredSubmit=false) {
        console.log(" ### FORM ARRAY ###");
        console.log(this.formArray);
        //SUBMIT REGARDLESS IF REQUIRED FIELD ARE FILLED OUT OR NOT
        if ( isRequiredSubmit ) {
             this.aboutYou_formControl.value.phone_number = this.applyOnlineRequiredForm_formControl_phone_number.value;
        }

        console.log('===VALUE OF PHONE NUMBER ===');
        console.log(this.aboutYou_formControl.value.phone_number);

        //IF REQUIRED FIELD IS EMPTY SHOW REQUIRED FIELD FORM AND HIDE THE MAIN FORM
        if ( this.aboutYou_formControl.value.phone_number == undefined && isRequiredSubmit == false ) {

             this.isShowRequiredField = true;
             this.isRequiredForm_phone_number_empty = true;
             this.showForm = false;

        } else if( (this.aboutYou_formControl.value.phone_number == '' && this.aboutYou_formControl.controls.phone_number.touched) && isRequiredSubmit == false ){
            
            this.isShowRequiredField = true;
            this.isRequiredForm_phone_number_empty = true;
            this.showForm = false;

        } else {

            // CALL API SUBMIT
            this.submitApi();
        }        

        // SCROLL PAGE TO TOP    
        window.scroll(0,0);
    }

    handleDateChange(value: Date, field) {
        if (value) {
            this.dateValues[field] = moment(value).format('MM/DD/YYYY');
        }
    }

    submitApi(){
        // SUBMIT FORM
        if (this.aboutYou_formControl.value.take_prescription_medication === 'No') {
            this.aboutYou_formControl.value.prescription_drug_name1 = '';
            this.aboutYou_formControl.value.prescription_drug_name2 = '';
            this.aboutYou_formControl.value.prescription_drug_name3 = '';
            this.aboutYou_formControl.value.prescription_drug_name4 = '';
        }

        _.forEach(this.dateValues, (value, key) => {
            this.dateValues[key] = moment(value).format('YYYY-MM-DD');
        });

        this._api.callAPIEndpoint('/api/v1/apply-online', {

            //BIND BASIC INFO DATA
            'first_name'    : this.basicInfo_formControl.value.first_name,
            'last_name'     : this.basicInfo_formControl.value.last_name,
            'date_of_birth' : this.dateValues['dob'],
            'gender'        : this.basicInfo_formControl.value.gender,
            'marital_status': this.basicInfo_formControl.value.marital_status,

            //BIND MEDICARE INFO DATA
            'part_a_effective_date'       : this.dateValues['part_a_effective_date'],
            'part_b_effective_date'       : this.dateValues['part_b_effective_date'],
            'medicare_claim_number'       : this.medicareInfo_formControl.value.medicare_claim_number,
            'social_security_number'      : this.medicareInfo_formControl.value.social_security_number,
            'quality_for_garrenteed_issue': this.medicareInfo_formControl.value.quality_for_garrenteed_issue,
            'new_coverage_effective_date' : this.dateValues['new_coverage_effective_date'],

            //BIND ABOUT YOU DATA
            'height'                      : this.aboutYou_formControl.value.height,
            'weight'                      : this.aboutYou_formControl.value.weight,
            'street_address'              : this.aboutYou_formControl.value.street_address,
            'city_town'                   : this.aboutYou_formControl.value.city_town,
            'state'                       : this.aboutYou_formControl.value.state,
            'zip_code'                    : this.aboutYou_formControl.value.zip_code,
            'phone_main'                  : this.aboutYou_formControl.value.phone_number,
            'phone_alt'                   : this.aboutYou_formControl.value.alternative_phone_number,
            'email'                       : this.aboutYou_formControl.value.email,
            'tobacco_use'                 : this.aboutYou_formControl.value.tobacco_use,
            'take_prescription_medication': this.aboutYou_formControl.value.take_prescription_medication,
            'prescription_drug_name1'     : this.aboutYou_formControl.value.prescription_drug_name1,
            'prescription_drug_name2'     : this.aboutYou_formControl.value.prescription_drug_name2,
            'prescription_drug_name3'     : this.aboutYou_formControl.value.prescription_drug_name3,
            'prescription_drug_name4'     : this.aboutYou_formControl.value.prescription_drug_name4,

            //BIND PAYMENT INFO
            'payment_mode'                       : this.paymentInfo_formControl.value.payment_mode,
            'payment_method'                     : this.paymentInfo_formControl.value.payment_method,
            'bank_name'                          : this.paymentInfo_formControl.value.bank_name,
            'routing_number'                     : this.paymentInfo_formControl.value.routing_number,
            'account_number'                     : this.paymentInfo_formControl.value.account_number,
            'requested_date_of_health_interview' : this.dateValues['requested_date_of_health_interview'],
            'requested_time_for_health_interview': this.paymentInfo_formControl.value.requested_time_for_health_interview ? moment(this.paymentInfo_formControl.value.requested_time_for_health_interview).format('HH:mm'): '',
            'additional_notes'                   : this.paymentInfo_formControl.value.additional_notes,
            //'product_type' : 'medigap'
            'product_information'                : this.productInformation,
            'plan_url' : ''

        })
        .subscribe(
            (data: any) => {
                if (data.code == 1) {
                    //success.
                    this.loading = false;
                    //this.results = data.details;
                    this.showForm = false;
                    this.isShowRequiredField = false;
                    console.log('data has been found');

                } else {
                    this.showForm = false;
                    this.isShowRequiredField = false;
                    //this.loading = false;
                    console.log('no results found');
                }
            },
            (err) => {
                this.showForm = false;
                this.loading = false;
                this.isShowRequiredField = false;
                console.log('no results found');
            }
        );
    }
}

