<!--START BANNER-->
<div class="container-fluid header-banner-wrapper">
	<app-header-b hasNoMarginBottom="yes"></app-header-b>
	<div class="container banner-wrapper text-center text-md-left">
		<div class="row">
			<div class="col-md-8">
				<div class="banner-text-wrapper">
					<h1 class="banner-main-title">
            <span>New to Medicare?</span><br>         
            A Clear and Guided Experience
					</h1>
					<p class="banner-sub-title">The “New to Medicare” experience was not designed for simplicity. <br> The TapTap experience makes it a breeze.</p>
				</div>
			</div>
			<div class="col-md-4 px-0 banner-image-wrapper">
        <!-- <img src="assets/img/common/old-woman-sitting-yoga.png" class="banner-main-image"> -->
        <img src="./assets/img/i-am-new-to-medicare/updated/i-am-new-to-medicare-confuse.png" class="banner-main-image">
        
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>
<!--END BANNER-->

<div class="container">
  <div class="row flex-column align-items-center first-row">
    <h2 class="common-border">
      The "New to Medicare" process is not easy. <br>
      Not for anyone. <br> 
      In fact, it can be pretty daunting.
    </h2>
    <img src="./assets/img/i-am-new-to-medicare/old-man-doubt.png" alt="old man confuse" class="first-img common-img"> 
  </div>
  <div class="row flex-column align-items-center">
      <h2 class="common-border">
          However, it can actually be made quite <br>
         <strong>Simple and Clear.</strong>
        </h2>
        <img src="./assets/img/i-am-new-to-medicare/old-man-idea.png" alt="old man idea" class="common-img"> 
  </div>
</div>

<div class="container-fluid horizontal-bar-blue text-center">
    <h2>
       With the right approach, and guidance, you can understand it all
       very simply, and make all the right choices
    </h2>
    <h3>And Even Enjoy Doing So While You're At It.</h3>
</div>
<div class="banner-pointer-wrapper">
    <i class="fa fa-arrow-down"></i>
    <div class="banner-pointer-background bc-secondary"></div>
</div>

<div class="container-fluid horizontal-bar-white text-center">
    <h2>
      3 Enjoyable Options To Choose From
       <!-- This Is Why We Have Pioneered 3 Effective Ways -->
    </h2> 
    <h3>To Make Sure Your Medicare Experience Is Clear and Organized Right From The Start:</h3>
</div>
<!-- <div class="banner-pointer-wrapper">
    <i class="fa fa-arrow-down"></i>
    <div class="banner-pointer-background"></div>
</div> -->
<app-new-to-medicare hasHeaderText="no"></app-new-to-medicare>

<div class="container d-flex flex-column align-items-center my-4 text-center">
  <h2>Join The Free TapTap Webinar</h2>
  <!-- <button class="btn btn-first d-block">View The Upcoming Times of Our Free  <b>New to Medicare</b> Group Education Session</button> -->
  <button class="btn btn-first d-block" routerLink="/webinar">View Upcoming Schedule</button>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>



<!-- <div class="container">
  <div class="row">
    <div class="col-12 text-center first-row">
      <h3>The <strong>“New to Medicare”</strong>  process...</h3>
      <img src="./assets/img/i-am-new-to-medicare/new-to-medicare.png" alt="" class="first-img"> 
      <h3>-is <strong>not easy</strong>. Not for <strong>anyone.</strong></h3>
      <img src="./assets/img/i-am-new-to-medicare/updated/i-am-new-to-medicare-confuse.png" alt="" class="second-img">
      <h3>In fact, it can be pretty <strong>daunting.</strong></h3>
      <h3>However, <strong>it can</strong> actually be made quite <strong>simple and clear.</strong></h3>
      <img src="./assets/img/i-am-new-to-medicare/relax.png" alt="" class="third-img">
      <h3>With the right approach, <strong>and guidance,</strong> you can understand it all very simply, and make all <strong>the right choices.</strong></h3>
      <h3>And even <strong>enjoy doing so</strong> while you’re at it. </h3>
      <h3>This is why we have pioneered <strong>4 effective ways</strong> to help you with your <strong>“New to Medicare” experience.</strong></h3>
    </div>
  </div>

  <div class="col-12" style="margin: 90px 0 50px 0;">
    <h3 class="text-center mb-5 fColor-dblue">Choose the option you'd like:</h3>
 </div>

  <div class="row second-row text-sm-left" *ngIf="show">
    <div class="col-12">
      <div class="row align-items-center">
        <div class="col-12 col-sm-6 text-center">
          <img src="./assets/img/i-am-new-to-medicare/updated/i-am-new-to-medicare-professor.png" alt="" class="fourth-img">
        </div>
        
        <div class="col-12 col-sm-6 box-text-content d-flex flex-column">
          <h3>Use The Medicare Coverage Suitability Professor</h3>
          <p>I like self service and want to get right to the point. I would like to provide my current circumstance and personal preferences and show me suitable plans.</p>
          <button class="btn btn-first mx-auto mx-sm-0">Get Started</button>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="row flex-column-reverse flex-sm-row align-items-center">
          <div class="col-12 col-sm-6 box-text-content d-flex flex-column">
              <h3>Dedicated Consultation with a Professional Counselor</h3>
              <p>I like to have things explained clearly and simply and be able to ask an expert any questions I’d like while I am learning.</p>
              <button class="btn btn-first mx-auto mx-sm-0" (click)="showContent()">Get Started</button>
            </div>
    
            <div class="col-12 col-sm-6 text-center">
              <img src="./assets/img/i-am-new-to-medicare/updated/i-am-new-to-medicare-counselor.png" alt="" class="fifth-img">
            </div>
      </div>
    </div>

    <div class="col-12">
        <div class="row align-items-center">
            <div class="col-12 col-sm-6 text-center">
                <img src="./assets/img/i-am-new-to-medicare/updated/i-am-new-to-medicare-learning.png" alt="" class="sixth-img">
              </div>
      
              <div class="col-12 col-sm-6 box-text-content d-flex flex-column">
                <h3>Start Learning from Our "Big Picture" Course on Being 'New to Medicare'</h3>
                <p>I like to learn independently (at least at first) and enjoy 
                    self-directed research into things. I feel confident consuming the relevant info to progress my understanding.</p>
                <button class="btn btn-first mx-auto mx-sm-0">Get Started</button>
              </div>
        </div>
    </div>

    <div class="col-12">
        <div class="row flex-column-reverse flex-sm-row align-items-center">
            <div class="col-12 col-sm-6 box-text-content d-flex flex-column">
                <h3>Sign in and Start Saving Your Research, Notes, and Questions</h3>
                <p>I like to have a system of learning and want to make sure all the research I do can be saved and easily retrieved, shared, and referenced for later.</p>
                <button class="btn btn-first mx-auto mx-sm-0">Get Started</button>
              </div>
              <div class="col-12 col-sm-6 text-center">
                <img src="./assets/img/i-am-new-to-medicare/new/sign-in.jpg" alt="" class="seventh-img">
              </div>
        </div>
    </div>
  </div>
  <div class="row third-row" *ngIf="!show">
     <div class="col-12 text-center">
        <h2 class="my-5 mt-sm-0 text-center" style="color:#fff;line-height: 1.4em;">Schedule Your Initial<br>Consultation Here </h2>
      </div>
      <div class="col-12">
        <div class="row">
            <div class="col-md-6 px-5">
                <form>
                  <div class="form-group">
                    <label for="name"><sup>*</sup>Name</label>
                    <input type="text" class="form-control" id="name">
                  </div>
                  <div class="form-group">
                    <label for="dateOfBirth"><sup>*</sup>Date of Birth</label>
                    <input type="text" class="form-control" id="dateOfBirth">
                  </div>
                  <div class="form-group" style="border-bottom:1px solid #fff;padding-bottom:2em;">
                    <label for="phone"><sup>*</sup>Phone</label>
                    <input type="text" class="form-control" id="phone">
                  </div>
                  <div class="form-group" style="border-bottom:1px solid #fff;padding-bottom:2em;">
                    <label for="zipCode"><sup>*</sup>Zip Code</label>
                    <input type="text" class="form-control" id="zipCode">
                  </div>
                </form>
                <img src="./assets/img/i-am-new-to-medicare/research-note.png" alt="schedule-a-consultation-background">
            </div>
            <div class="col-md-6 px-5 text-center">
                <label class="mb-3">Choose the Date for Your Consultation:</label>
                <div class="row date-option" style="margin-bottom:100px;">
                  <div class="col-md-4 d-flex flex-column text-center">
                    <span>Today</span>
                    <span class="rounded-circle {{dateOptionActiveStateToday}}" (click)="showDateActive(1)"></span>
                  </div>
                  <div class="col-md-4 d-flex flex-column text-center">
                      <span>Tomorrow</span>
                      <span class="rounded-circle {{dateOptionActiveStateTomorrow}}" (click)="showDateActive(2)"></span>
                 </div>
                  <div class="col-md-4 d-flex flex-column text-center">
                      <span>Calendar</span>
                      <span class="rounded-circle {{dateOptionActiveStateCalendar}}" (click)="showDateActive(3)"></span>
                  </div>
                 <div class="col-12" *ngIf="isShowMe">
                    <!-- <input type="text" class="form-control" bsDatepicker [bsConfig]="{ containerClass: 'theme-blue' }" [isOpen]="isOpen"> -->
                  <!-- </div>
                    
                 </div>
                 
                 <mat-form-field>
                  <input matInput [matDatepicker]="myDatepicker">
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepicker></mat-datepicker>
                </mat-form-field>
                
                <label class="mb-3" style="margin-top:200px;">Choose the Time for Your Consultation:</label>
                <div class="row" style="background:#fff;padding:20px;width:100%;border-radius:10px;margin: auto;">
                    
                    <div class="col-12 d-flex justify-content-between mb-3 p-0">
                      <span><i class="fa fa-chevron-left"></i></span>
                      <span>AM</span>
                      <span><i class="fa fa-chevron-right"></i></span>
                    </div>
                    <div class="col-12 text-center p-0">
                        <div class="row time-list">
                            <span class="col-3">06:00 <sup>AM</sup></span>
                            <span class="col-3">07:00 <sup>AM</sup></span>
                            <span class="col-3">08:00 <sup>AM</sup></span>
                            <span class="col-3">09:00 <sup>AM</sup></span>
                            <span class="col-3">10:00 <sup>AM</sup></span>
                            <span class="col-3 active">11:00 <sup>AM</sup></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-first" style="background:#fff;color: #47cbff !important;">Get Started</button>
      </div>
  </div>
</div> -->