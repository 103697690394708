import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {
  
  @Input() details: any;
  @Input() firstName;
  @Input() middleName;
  @Input() lastName;
  @Output() emit_onClickCalendar = new EventEmitter;
  @Output() emit_onClick_gender = new EventEmitter;

  constructor() { }

  ngOnInit() {
  }

  onClickDob(type, heading){
    this.emit_onClickCalendar.emit({type,heading});
  }

  onClick_gender(type){
    this.emit_onClick_gender.emit(type);
  }

}
