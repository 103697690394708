<div class="ready-to-talk-section" *ngIf="isDefault && !isLandingPage">
    <div class="container schedule-section p-0">
        <div class="row justify-content-center mx-0">
            <div class="custom-box col-12 schedule-box d-flex align-items-center justify-content-center">
                <div class="schedule-box-inside">
                    <h2>We Are Here To Help</h2>
                    <p>We provide dedicated 1-on-1 access to our expert in-house counselors that can help you with any of your Medicare needs.</p>
                    <p>Receive easy-to-understand explanations, a full Medicare review and consultation, or even just a quick answer to your question.</p>
                    <!-- <a href="https://brightprice.gigabook.com/book/all-team-members/loc#panel_step_choose" target="_blank" class="btn btn-first mx-auto">Speak With a Concierge
                    </a> -->
                    <a target="_blank" (click)="speaktoCounselor(speakToCounselorModal)" class="btn btn-first mx-auto mt-5">Speak With a Concierge</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ready-to-talk-section" *ngIf="!isDefault" [class.isGreyBg]="!isDefault && !isLandingPage">
    <div class="container schedule-section p-0">
        <div class="row justify-content-center mx-0">
            <div class="custom-box col-12 schedule-box d-flex align-items-center justify-content-center">
                <div class="schedule-box-inside">
                    <h2>TapTap Medicare <br> Provides Clarity And Solution</h2>
                    <p style="font-size: 24px;">
                        Easily Compare Plans and Prices <br> Identify Insurance Carrier You Want Most <br> Gain Understading of your Coverage</p>                  
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ready-to-talk-section landing-page" *ngIf="isLandingPage" [style.background]="thirdVariation ? 'unset' : spacerColor">
    <div class="container schedule-section p-0">
        <div class="row justify-content-center mx-0">            
            <div class="custom-box col-12 schedule-box">
                <div class="row schedule-section-heading">
                    <h3 class="col-12">TapTap Medicare Provides Clarity And Solution</h3>
                </div>
                <div class="schedule-section-content">
                    <div class="schedule-box-img">
                        <img *ngIf="thirdVariation" src="./assets/img/third-variation/guy-in-a-jacket-blue-crop-cut.png" alt="TapTap Medicare Provides Clarity And Solution">
                        <img *ngIf="!thirdVariation" src="./assets/img/common/medigap/guy-in-a-jacket-crop-cut.png" alt="TapTap Medicare Provides Clarity And Solution">
                    </div>
                    <div class="schedule-box-inside">  
                        <ul lstyle="font-size: 24px;">
                            <li><img src="./assets/img/common/medigap/schedule-check-icon.png">Easily Compare Plans and Prices</li>
                            <li><img src="./assets/img/common/medigap/schedule-check-icon.png">Identify Insurance Carrier You Want Most</li>
                            <li><img src="./assets/img/common/medigap/schedule-check-icon.png">Gain Understading of your Coverage</li>
                        </ul>       
                        <button class="btn btn-first" (click)="speaktoCounselor(speakToCounselorModal)">Speak With a Concierge</button>       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-none">
    <div #speakToCounselorModal>
        <app-speak-with-a-counselor></app-speak-with-a-counselor>
    </div>
</div>