import {Component, OnInit} from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {APIService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

    fullPrimaryHeader:boolean = true;
    isLoggedInUser:any;

    submitIsClicked: boolean = false;
    isValid: boolean = true;
    success: boolean = false;

    private baseUrl = window.location.origin;

    form: any;
    token: string;

    updatePasswordForm = new FormGroup({
        email: new FormControl('', [
            Validators.required,
        ]),
        password: new FormControl('', [
            Validators.required,
        ]),
        c_password: new FormControl('', [
            Validators.required,
        ]),
        taptapRecaptcha: new FormControl(false)
    });

    get getTapTapRecaptcha(): FormControl {
        return this.updatePasswordForm.get('taptapRecaptcha') as FormControl;
    }
      
    get email() {
        return this.updatePasswordForm.get('email');
    }

    get password() {
        return this.updatePasswordForm.get('password');
    }

    get c_password() {
        return this.updatePasswordForm.get('c_password');
    }

    get taptapRecaptcha() {
        return this.updatePasswordForm.get('taptapRecaptcha');
    }

    currentUser: any;

    constructor(private _api: APIService, private _router: Router, private route: ActivatedRoute,private _auth: AuthService ) {
        this.route.params.subscribe(params => {
            this.token = params['token'];

        });
    }

    ngOnInit() {
        this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
        if(this.isLoggedInUser){
        this.fullPrimaryHeader = false;
        }
    }

    update() {
        this.submitIsClicked = true;
        this.isValid = true;
        this.success = false;
        if (this.updatePasswordForm.valid) {
            this._api.callAPIEndpoint('/api/v1/password/reset', {
                'email': this.updatePasswordForm.controls.email.value,
                'password': this.updatePasswordForm.controls.password.value,
                'password_confirmation': this.updatePasswordForm.controls.c_password.value,
                'token': this.token,
            })
            .subscribe({
                next: (result:any) => {
                    if (result) {
                        this.success = true;
                        this.updatePasswordForm.reset();
                        this.submitIsClicked = false;
                    }
                },
                error: (e) => {
                    this.isValid = false;
                    console.error(e)
                }
            })
        }
    }
}
