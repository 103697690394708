<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue">Medical Records Release Authorization</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <h5 class="text-center fw-regular text-secondary text-italic">If you were required to answer medical questions on your Medicare Supplement Enrollment Application, you must complete this authorization to be eligible for enrollment.</h5>
    </div>
    <div class="py-3">
      <div class="dashed-box border shadow p-4">
        <h4 class="text-center fw-semibold">Please Read and Sign.</h4>
        <div>
          <p class="text-label">By completing this form, I agree to the following:</p>
          <div class="p-2">
            <h6 class="fw-semibold">Purpose of the Authorization:</h6>
            <p>
              By signing this form, you will authorize the disclosure and use of the protected health information described below for pre-enrollment underwriting or to determine your eligibility for enrollment or benefits under an insurance plan.
            </p>
          </div>
          <div class="p-2">
            <h6 class="fw-semibold">Information we will use and/or disclose</h6>
            <p>
              I authorize any physician, medical or health care practitioner, hospital, clinic, veterans administration facility, other medical or medically related facility, third party administrator, Pharmacy Benefit Manager, insurance, HMO or reinsuring company, employer or the Consumer Reporting Agency having information regarding myself including information concerning advice, diagnosis, treatment and care of the physical, psychiatric, mental or emotional conditions, drug, substance or alcohol abuse, illness and copies of all hospital or medical records, non-public personal health information and any other non-medical information to share any and all such information with <b>[Insurance Carrier NAIC Name]</b>, its reinsurer or its legal representatives, and its affiliates.
            </p>
            <ul>
              <li>
                The information obtained by use of this authorization may be used by Humana Benefit Plan of Illinois, Inc. to determine eligibility for coverage.
              </li>
              <li>
                Any information obtained will not be released by Humana Benefit Plan of Illinois, Inc. to any person or organization except to reinsuring companies, or other persons or organizations performing health care operations or business or legal services in connection with any application, claim or as may be otherwise lawfully required, or as we may further authorize. If a Consumer Reporting Agency is used, I may request to be interviewed in connection with the preparation of the report and I may request a copy of the report.
              </li>
              <li>
                Once personal and health (including medical and pharmacy) information is disclosed pursuant to this authorization, it may be redisclosed by the recipient and the information may not be protected by federal and state privacy requirements.
              </li>
            </ul>
          </div>
          <div class="p-2">
            <h6 class="fw-semibold">Expiration and Revocation</h6>
            <ul>
              <li>
                A copy of this authorization is available to me or my legal representative upon written request. A photographic copy of this authorization shall be as valid as the original.
              </li>
              <li>
                This authorization shall be valid for 2 years from the date shown below. I have the right to revoke this authorization at any time.
              </li>
            </ul>
          </div>
          <div class="p-2">
            <h6 class="fw-semibold">To revoke this authorization:</h6>
            <ul>
              <li>
                I must do so in writing and send my written revocation to <b>[Insurance Carrier NAIC Name]</b>.
              </li>
              <li>
                The revocation will not apply to information that has already been released in response to this authorization.
              </li>
              <li>
                The revocation may adversely affect my application, a claim or a pending insurance action.
              </li>
              <li>
                The revocation will become effective after it is received by <b>[Insurance Carrier NAIC Name]</b>.
              </li>
            </ul>
          </div>
        </div>
        <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" [(ngModel)]="recordsReleaseAgreedDate" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="iAgree">    
              <label class="custom-control-label fw-semibold text-dblue" for="iAgree">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>