<img class="d-none d-lg-block banner-image" src="/assets/img/beneficiary/my-providers/bg-providers.svg" alt="">

<app-loading *ngIf="isLoading" class="loading-fixed"></app-loading>

<div class="container-fluid header-banner-wrapper-plain banner-result">
  
  <app-header-b></app-header-b>
  <div class="container banner-wrapper pb-0">
    <div class="row">
      <div class="col-12">
        <h1 class="banner-main-title text-center">
          My RX Preferences
        </h1>
      </div>
    </div>
  </div>
</div>

<div class="main-content-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-9 mapd-drugs-left">

          <!-- drug heading -->
          <div class="dp-card">
            <h4 class="text-center text-capitalize">Selected Drug</h4>
            <div *ngIf="!results.length" class="d-flex py-3 justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center" *ngIf="results.length">
              <div class="d-flex align-items-center">
                <div *ngIf="formCheck == 'tablet'">
                  <img src="./assets/img/my-drugs/tablet.svg" alt="Tablet Icon" class="mt-0 mr-3 max-w-90">
                </div>
                <div *ngIf="formCheck == 'capsule'">
                  <img src="./assets/img/my-drugs/capsule.svg" alt="Capsule Icon" class="mt-0 mr-3 max-w-90">
                </div>
                <div *ngIf="formCheck == 'injection'">
                  <img src="./assets/img/my-drugs/injection.svg" alt="Injection Icon" class="mt-0 mr-3 max-w-90">
                </div>
                <div *ngIf="formCheck == 'meds'">
                  <img src="./assets/img/my-drugs/meds.svg" alt="Meds Icon" class="mt-0 mr-3 max-w-90">
                </div>
                <!-- <img src="./assets/img/my-drugs/black-orange-white-capsule.JPG" class="mt-0 mr-3 max-w-80" alt="Capsule Icon"> -->
                <div class="text-left">
                  <!-- <h5 class="fColor-base mb-0 text-capitalize">{{ results[1].idGroup.drug_info.drug_name }}</h5> -->
                  <h5 class="fColor-base mb-0 text-capitalize">{{ strip_drug_name }}</h5>
                  <p class="fWeight-500 mb-0">{{ results[1].idGroup.drug_info.dosage }} | {{ results[1].idGroup.drug_info.package }}</p>
                </div>
              </div>
              <button class="btn btn-second fColor-blue mx-0 btn-small" (click)="onClick_ChangeDrug()">Change</button>
            </div>
          </div>

          <form [formGroup]="drugForm" (ngSubmit)="onSubmit()" id="drugFormId">
              
              <!-- DEFAULT VIEW -->
              <div id="form_view_default" class="dp-card mt-3">
                <h2 class="fWeight-600 mb-4 d-block text-center">Enter The Amount You<br> Take of This Drug</h2>
                <label class="text-center d-block mt-4 col-12 mb-3">This Often:</label>
                <div class="row px-2">
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-day-1"(click)="onClick_shortcutOften('1','per-day')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 208c0-61.86 50.14-111.1 111.1-111.1c52.65 0 96.5 36.45 108.5 85.42C334.7 173.1 354.7 168 375.1 168c4.607 0 9.152 .3809 13.68 .8203l24.13-34.76c5.145-7.414 .8965-17.67-7.984-19.27L317.2 98.78L301.2 10.21C299.6 1.325 289.4-2.919 281.9 2.226L208 53.54L134.1 2.225C126.6-2.92 116.4 1.326 114.8 10.21L98.78 98.78L10.21 114.8C1.326 116.4-2.922 126.7 2.223 134.1l51.3 73.94L2.224 281.9c-5.145 7.414-.8975 17.67 7.983 19.27L98.78 317.2l16.01 88.58c1.604 8.881 11.86 13.13 19.27 7.982l10.71-7.432c2.725-35.15 19.85-66.51 45.83-88.1C137.1 309.8 96 263.9 96 208zM128 208c0 44.18 35.82 80 80 80c9.729 0 18.93-1.996 27.56-5.176c7.002-33.65 25.53-62.85 51.57-83.44C282.8 159.3 249.2 128 208 128C163.8 128 128 163.8 128 208zM575.2 325.6c.125-2 .7453-3.744 .7453-5.619c0-35.38-28.75-64-63.1-64c-12.62 0-24.25 3.749-34.13 9.999c-17.62-38.88-56.5-65.1-101.9-65.1c-61.75 0-112 50.12-112 111.1c0 3 .7522 5.743 .8772 8.618c-49.63 3.75-88.88 44.74-88.88 95.37C175.1 469 218.1 512 271.1 512h272c53 0 96-42.99 96-95.99C639.1 373.9 612.7 338.6 575.2 325.6z"/></svg>
                      1 Per Day 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>              
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-day-2" (click)="onClick_shortcutOften('2','per-day')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 208c0-61.86 50.14-111.1 111.1-111.1c52.65 0 96.5 36.45 108.5 85.42C334.7 173.1 354.7 168 375.1 168c4.607 0 9.152 .3809 13.68 .8203l24.13-34.76c5.145-7.414 .8965-17.67-7.984-19.27L317.2 98.78L301.2 10.21C299.6 1.325 289.4-2.919 281.9 2.226L208 53.54L134.1 2.225C126.6-2.92 116.4 1.326 114.8 10.21L98.78 98.78L10.21 114.8C1.326 116.4-2.922 126.7 2.223 134.1l51.3 73.94L2.224 281.9c-5.145 7.414-.8975 17.67 7.983 19.27L98.78 317.2l16.01 88.58c1.604 8.881 11.86 13.13 19.27 7.982l10.71-7.432c2.725-35.15 19.85-66.51 45.83-88.1C137.1 309.8 96 263.9 96 208zM128 208c0 44.18 35.82 80 80 80c9.729 0 18.93-1.996 27.56-5.176c7.002-33.65 25.53-62.85 51.57-83.44C282.8 159.3 249.2 128 208 128C163.8 128 128 163.8 128 208zM575.2 325.6c.125-2 .7453-3.744 .7453-5.619c0-35.38-28.75-64-63.1-64c-12.62 0-24.25 3.749-34.13 9.999c-17.62-38.88-56.5-65.1-101.9-65.1c-61.75 0-112 50.12-112 111.1c0 3 .7522 5.743 .8772 8.618c-49.63 3.75-88.88 44.74-88.88 95.37C175.1 469 218.1 512 271.1 512h272c53 0 96-42.99 96-95.99C639.1 373.9 612.7 338.6 575.2 325.6z"/></svg>
                      2 Per Day 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-day-3" (click)="onClick_shortcutOften('3','per-day')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 208c0-61.86 50.14-111.1 111.1-111.1c52.65 0 96.5 36.45 108.5 85.42C334.7 173.1 354.7 168 375.1 168c4.607 0 9.152 .3809 13.68 .8203l24.13-34.76c5.145-7.414 .8965-17.67-7.984-19.27L317.2 98.78L301.2 10.21C299.6 1.325 289.4-2.919 281.9 2.226L208 53.54L134.1 2.225C126.6-2.92 116.4 1.326 114.8 10.21L98.78 98.78L10.21 114.8C1.326 116.4-2.922 126.7 2.223 134.1l51.3 73.94L2.224 281.9c-5.145 7.414-.8975 17.67 7.983 19.27L98.78 317.2l16.01 88.58c1.604 8.881 11.86 13.13 19.27 7.982l10.71-7.432c2.725-35.15 19.85-66.51 45.83-88.1C137.1 309.8 96 263.9 96 208zM128 208c0 44.18 35.82 80 80 80c9.729 0 18.93-1.996 27.56-5.176c7.002-33.65 25.53-62.85 51.57-83.44C282.8 159.3 249.2 128 208 128C163.8 128 128 163.8 128 208zM575.2 325.6c.125-2 .7453-3.744 .7453-5.619c0-35.38-28.75-64-63.1-64c-12.62 0-24.25 3.749-34.13 9.999c-17.62-38.88-56.5-65.1-101.9-65.1c-61.75 0-112 50.12-112 111.1c0 3 .7522 5.743 .8772 8.618c-49.63 3.75-88.88 44.74-88.88 95.37C175.1 469 218.1 512 271.1 512h272c53 0 96-42.99 96-95.99C639.1 373.9 612.7 338.6 575.2 325.6z"/></svg>
                      3 Per Day 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-meal-1" (click)="onClick_shortcutOften('1','per-meal')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M221.6 148.7C224.7 161.3 224.8 174.5 222.1 187.2C219.3 199.1 213.6 211.9 205.6 222.1C191.1 238.6 173 249.1 151.1 254.1V472C151.1 482.6 147.8 492.8 140.3 500.3C132.8 507.8 122.6 512 111.1 512C101.4 512 91.22 507.8 83.71 500.3C76.21 492.8 71.1 482.6 71.1 472V254.1C50.96 250.1 31.96 238.9 18.3 222.4C10.19 212.2 4.529 200.3 1.755 187.5C-1.019 174.7-.8315 161.5 2.303 148.8L32.51 12.45C33.36 8.598 35.61 5.197 38.82 2.9C42.02 .602 45.97-.4297 49.89 .0026C53.82 .4302 57.46 2.303 60.1 5.259C62.74 8.214 64.18 12.04 64.16 16V160H81.53L98.62 11.91C99.02 8.635 100.6 5.621 103.1 3.434C105.5 1.248 108.7 .0401 111.1 .0401C115.3 .0401 118.5 1.248 120.9 3.434C123.4 5.621 124.1 8.635 125.4 11.91L142.5 160H159.1V16C159.1 12.07 161.4 8.268 163.1 5.317C166.6 2.366 170.2 .474 174.1 .0026C178-.4262 181.1 .619 185.2 2.936C188.4 5.253 190.6 8.677 191.5 12.55L221.6 148.7zM448 472C448 482.6 443.8 492.8 436.3 500.3C428.8 507.8 418.6 512 408 512C397.4 512 387.2 507.8 379.7 500.3C372.2 492.8 368 482.6 368 472V352H351.2C342.8 352 334.4 350.3 326.6 347.1C318.9 343.8 311.8 339.1 305.8 333.1C299.9 327.1 295.2 320 291.1 312.2C288.8 304.4 287.2 296 287.2 287.6L287.1 173.8C288 136.9 299.1 100.8 319.8 70.28C340.5 39.71 369.8 16.05 404.1 2.339C408.1 .401 414.2-.3202 419.4 .2391C424.6 .7982 429.6 2.62 433.9 5.546C438.2 8.472 441.8 12.41 444.2 17.03C446.7 21.64 447.1 26.78 448 32V472z"/></svg>
                      1 Per Meal 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-meal-2" (click)="onClick_shortcutOften('2','per-meal')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M221.6 148.7C224.7 161.3 224.8 174.5 222.1 187.2C219.3 199.1 213.6 211.9 205.6 222.1C191.1 238.6 173 249.1 151.1 254.1V472C151.1 482.6 147.8 492.8 140.3 500.3C132.8 507.8 122.6 512 111.1 512C101.4 512 91.22 507.8 83.71 500.3C76.21 492.8 71.1 482.6 71.1 472V254.1C50.96 250.1 31.96 238.9 18.3 222.4C10.19 212.2 4.529 200.3 1.755 187.5C-1.019 174.7-.8315 161.5 2.303 148.8L32.51 12.45C33.36 8.598 35.61 5.197 38.82 2.9C42.02 .602 45.97-.4297 49.89 .0026C53.82 .4302 57.46 2.303 60.1 5.259C62.74 8.214 64.18 12.04 64.16 16V160H81.53L98.62 11.91C99.02 8.635 100.6 5.621 103.1 3.434C105.5 1.248 108.7 .0401 111.1 .0401C115.3 .0401 118.5 1.248 120.9 3.434C123.4 5.621 124.1 8.635 125.4 11.91L142.5 160H159.1V16C159.1 12.07 161.4 8.268 163.1 5.317C166.6 2.366 170.2 .474 174.1 .0026C178-.4262 181.1 .619 185.2 2.936C188.4 5.253 190.6 8.677 191.5 12.55L221.6 148.7zM448 472C448 482.6 443.8 492.8 436.3 500.3C428.8 507.8 418.6 512 408 512C397.4 512 387.2 507.8 379.7 500.3C372.2 492.8 368 482.6 368 472V352H351.2C342.8 352 334.4 350.3 326.6 347.1C318.9 343.8 311.8 339.1 305.8 333.1C299.9 327.1 295.2 320 291.1 312.2C288.8 304.4 287.2 296 287.2 287.6L287.1 173.8C288 136.9 299.1 100.8 319.8 70.28C340.5 39.71 369.8 16.05 404.1 2.339C408.1 .401 414.2-.3202 419.4 .2391C424.6 .7982 429.6 2.62 433.9 5.546C438.2 8.472 441.8 12.41 444.2 17.03C446.7 21.64 447.1 26.78 448 32V472z"/></svg>
                      2 Per Meal 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-meal-3" (click)="onClick_shortcutOften('3','per-meal')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M221.6 148.7C224.7 161.3 224.8 174.5 222.1 187.2C219.3 199.1 213.6 211.9 205.6 222.1C191.1 238.6 173 249.1 151.1 254.1V472C151.1 482.6 147.8 492.8 140.3 500.3C132.8 507.8 122.6 512 111.1 512C101.4 512 91.22 507.8 83.71 500.3C76.21 492.8 71.1 482.6 71.1 472V254.1C50.96 250.1 31.96 238.9 18.3 222.4C10.19 212.2 4.529 200.3 1.755 187.5C-1.019 174.7-.8315 161.5 2.303 148.8L32.51 12.45C33.36 8.598 35.61 5.197 38.82 2.9C42.02 .602 45.97-.4297 49.89 .0026C53.82 .4302 57.46 2.303 60.1 5.259C62.74 8.214 64.18 12.04 64.16 16V160H81.53L98.62 11.91C99.02 8.635 100.6 5.621 103.1 3.434C105.5 1.248 108.7 .0401 111.1 .0401C115.3 .0401 118.5 1.248 120.9 3.434C123.4 5.621 124.1 8.635 125.4 11.91L142.5 160H159.1V16C159.1 12.07 161.4 8.268 163.1 5.317C166.6 2.366 170.2 .474 174.1 .0026C178-.4262 181.1 .619 185.2 2.936C188.4 5.253 190.6 8.677 191.5 12.55L221.6 148.7zM448 472C448 482.6 443.8 492.8 436.3 500.3C428.8 507.8 418.6 512 408 512C397.4 512 387.2 507.8 379.7 500.3C372.2 492.8 368 482.6 368 472V352H351.2C342.8 352 334.4 350.3 326.6 347.1C318.9 343.8 311.8 339.1 305.8 333.1C299.9 327.1 295.2 320 291.1 312.2C288.8 304.4 287.2 296 287.2 287.6L287.1 173.8C288 136.9 299.1 100.8 319.8 70.28C340.5 39.71 369.8 16.05 404.1 2.339C408.1 .401 414.2-.3202 419.4 .2391C424.6 .7982 429.6 2.62 433.9 5.546C438.2 8.472 441.8 12.41 444.2 17.03C446.7 21.64 447.1 26.78 448 32V472z"/></svg>
                      3 Per Meal 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-week-1" (click)="onClick_shortcutOften('1','per-week')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM80 256C71.16 256 64 263.2 64 272V336C64 344.8 71.16 352 80 352H368C376.8 352 384 344.8 384 336V272C384 263.2 376.8 256 368 256H80z"/></svg>
                      1 Per Week 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-week-2" (click)="onClick_shortcutOften('2','per-week')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM80 256C71.16 256 64 263.2 64 272V336C64 344.8 71.16 352 80 352H368C376.8 352 384 344.8 384 336V272C384 263.2 376.8 256 368 256H80z"/></svg>
                      2 Per Week 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-week-3" (click)="onClick_shortcutOften('3','per-week')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM80 256C71.16 256 64 263.2 64 272V336C64 344.8 71.16 352 80 352H368C376.8 352 384 344.8 384 336V272C384 263.2 376.8 256 368 256H80z"/></svg>
                      3 Per Week 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-month-1" (click)="onClick_shortcutOften('1','per-month')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z"/></svg>
                      1 Per Month 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-month-2" (click)="onClick_shortcutOften('2','per-month')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z"/></svg>
                      2 Per Month 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4 p-3 mb-1 mb-md-4">
                    <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn" id="per-month-3" (click)="onClick_shortcutOften('3','per-month')">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z"/></svg>
                      3 Per Month 
                      <i class="fas fa-check ml-2"></i>
                    </a>
                  </div>
                </div>       
                <div class="alert alert-danger d-none" role="alert" id="oftenTakenWarningID">
                  Please Enter The Amount You Take of This Drug
                </div>         
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <button type="button" class="btn-more-options btn btn-third btn-small mb-3 mx-0 max-w-max" (click)="onClick_MoreOptions_New()">More Options</button>
                  <button type="button" class="btn btn-first btn-small mx-0 max-w-max" (click)="onClick_DefaultViewProceed_New()">Proceed</button>
                </div>
              </div>

              <!-- MORE OPTION VIEW -->
              <div  id="form_view_more_option" class="dp-card mt-4 d-none">
                <h2 class="fWeight-600 mb-4 d-block text-center">Enter The Custom Amount <br> You Take of This Drug:</h2>
                <div class="row custom-amount">
                  <div class="option-select col-12">
                    <label for="times_taken" class="text-center d-block">I Take This Many:<br> (This will serve as quantity)</label>
                    <select class="d-none form-control mx-auto max-width-247 blurred" name="times_taken" id="times_taken" formControlName="times_taken" [(ngModel)]="times_taken">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>

                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>

                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>

                      <option value="45">45</option>
                      <option value="60">60</option>
                      <option value="90">90</option>
                      <option value="120">120</option>
                      <option value="150">150</option>
                      <option value="180">180</option>
                      <option value="210">210</option>
                      <option value="240">240</option>
                      <option value="360">360</option>
                    </select>
                    <mat-form-field appearance="fill" class="mat-select-blurred">
                        <mat-select [(value)]="times_taken" (selectionChange)="selectedTimesTaken()">
                        <mat-option [value]="1">1</mat-option>
                        <mat-option [value]="2">2</mat-option>
                        <mat-option [value]="3">3</mat-option>
                        <mat-option [value]="4">4</mat-option>
                        <mat-option [value]="5">5</mat-option>
                        <mat-option [value]="6">6</mat-option>
                        <mat-option [value]="7">7</mat-option>
                        <mat-option [value]="8">8</mat-option>
                        <mat-option [value]="9">9</mat-option>
                        <mat-option [value]="10">10</mat-option>

                        <mat-option [value]="11">11</mat-option>
                        <mat-option [value]="12">12</mat-option>
                        <mat-option [value]="13">13</mat-option>
                        <mat-option [value]="14">14</mat-option>
                        <mat-option [value]="15">15</mat-option>
                        <mat-option [value]="16">16</mat-option>
                        <mat-option [value]="17">17</mat-option>
                        <mat-option [value]="18">18</mat-option>
                        <mat-option [value]="19">19</mat-option>
                        <mat-option [value]="20">20</mat-option>

                        <mat-option [value]="21">21</mat-option>
                        <mat-option [value]="22">22</mat-option>
                        <mat-option [value]="23">23</mat-option>
                        <mat-option [value]="24">24</mat-option>
                        <mat-option [value]="25">25</mat-option>
                        <mat-option [value]="26">26</mat-option>
                        <mat-option [value]="27">27</mat-option>
                        <mat-option [value]="28">28</mat-option>
                        <mat-option [value]="29">29</mat-option>
                        <mat-option [value]="30">30</mat-option>

                        <mat-option [value]="45">45</mat-option>
                        <mat-option [value]="60">60</mat-option>
                        <mat-option [value]="90">90</mat-option>
                        <mat-option [value]="120">120</mat-option>
                        <mat-option [value]="150">150</mat-option>
                        <mat-option [value]="180">180</mat-option>
                        <mat-option [value]="210">210</mat-option>
                        <mat-option [value]="240">240</mat-option>
                        <mat-option [value]="360">360</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="packaging-list col-12">
                    <div class="d-none">
                      <input type="radio" name="often_taken" class="form-control" value="per-day" formControlName="often_taken" id="often_taken_day" [(ngModel)]="often_taken"> Per Day
                      <input type="radio" name="often_taken" class="form-control" value="per-meal" formControlName="often_taken" id="often_taken_meal" [(ngModel)]="often_taken"> Per Meal
                      <input type="radio" name="often_taken" class="form-control" value="per-week" formControlName="often_taken" id="often_taken_week" [(ngModel)]="often_taken"> Per Week
                      <input type="radio" name="often_taken" class="form-control" value="per-month" formControlName="often_taken" id="often_taken_month" [(ngModel)]="often_taken"> Per Month
                    </div>
                  </div>
                </div>

                <label class="text-center d-block mt-4 col-12">This Often:</label>
                <div class="packaging-list col-12">   
                  <div class="row">
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn2" (click)="click_often_taken($event, 'day')">Per Day <i class="fas fa-check ml-2"></i></a>
                    </div>
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn2" (click)="click_often_taken($event, 'meal')">Per Meal <i class="fas fa-check ml-2"></i></a>
                    </div>
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn2" (click)="click_often_taken($event, 'week')">Per Week <i class="fas fa-check ml-2"></i></a>
                    </div>
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-packaging-list-item mx-auto timesOftenBtn2" (click)="click_often_taken($event, 'month')">Per Month <i class="fas fa-check ml-2"></i></a>
                    </div>
                  </div>             
                </div>
                <div class="alert alert-danger d-none" role="alert" id="oftenTakenWarningID2">
                  Please Enter The Amount You Take of This Drug
                </div>
                <div class="d-flex justify-content-center mt-5">
                  <a class="btn btn-second btn-small mx-auto fColor-blue" (click)="onClick_AsNeeded()">As Needed</a>
                </div>
                <div class="d-flex col-12 mt-3 justify-content-center">
                  <button type="button" class="btn btn-third btn-small mr-2" (click)="onClick_MoreOptionBack_New()">Back</button>
                  <button type="button" class="btn btn-first btn-small fColor-white max-w-160" (click)="onClick_MoreOptionProceed_New()">Proceed</button>
                </div>
              </div>
              
              <!-- REFILL OF THIS DRUG -->
              <div  id="form_refill_option" class="dp-card mt-4 d-none">
                <h2 class="fWeight-600 mb-4 d-block text-center">How Often Do You Typically <br> Get A Refill of This Drug?</h2>
                <div class="row custom-amount">
                  <div class="packaging-list col-12">
                    <div class="d-none">
                      <input type="radio" name="drug_refill" class="form-control" value="1" formControlName="drug_refill" id="drug_refill_1" [(ngModel)]="drug_refill"> Per Day
                      <input type="radio" name="drug_refill" class="form-control" value="2" formControlName="drug_refill" id="drug_refill_2" [(ngModel)]="drug_refill"> Per Meal
                      <input type="radio" name="drug_refill" class="form-control" value="3" formControlName="drug_refill" id="drug_refill_3" [(ngModel)]="drug_refill"> Per Week
                      <input type="radio" name="drug_refill" class="form-control" value="4" formControlName="drug_refill" id="drug_refill_4" [(ngModel)]="drug_refill"> Per Month
                    </div>
                  </div>
                </div>

                <label class="text-center d-block mt-4 col-12">This Often:</label>
                <div class="packaging-list col-12">   
                  <div class="row">
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-refill mx-auto refillBtn" (click)="click_drug_refill($event, '1')">Once Per Year <i class="fas fa-check ml-2"></i></a>
                    </div>
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-refill mx-auto refillBtn" (click)="click_drug_refill($event, '2')">Twice Per Year <i class="fas fa-check ml-2"></i></a>
                    </div>
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-refill mx-auto refillBtn" (click)="click_drug_refill($event, '3')">3-4 Times Per Year <i class="fas fa-check ml-2"></i></a>
                    </div>
                    <div class="col-12 col-md-6 p-2">
                      <a class="btn input-quote-btn btn-small btn-refill mx-auto refillBtn" (click)="click_drug_refill($event, '4')">5-6 Times Per year <i class="fas fa-check ml-2"></i></a>
                    </div>
                  </div>             
                </div>
                <div class="alert alert-danger d-none" role="alert" id="refillSelectionWarning">
                  Please Select One of the Choices
                </div>
                <div class="d-flex col-12 mt-5 justify-content-center">
                  <!-- <button type="button" class="btn btn-third btn-small mx-2" (click)="onClick_DrugRefillBack_New()">Back</button>
                  <button type="button" class="btn btn-first btn-small fColor-white mx-2" (click)="onClick_DrugRefillProceed_New()">Proceed</button> -->
                  <button type="button" class="btn btn-first btn-small fColor-white mx-2" (click)="onClick_RefillDone()">Done</button>
                </div>
              </div>

              <!-- 30 days supply -->
              <div id="form_view_how_often"  class="dp-card mt-4 d-none">
                <div class="row custom-amount">
                  <div *ngIf="!oftenSelection" class="packaging-list col-12">
                    <div class="d-none">
                      <input type="radio" name="days_supply" class="form-control" value="30" formControlName="days_supply" id="days_supply_30"> 30 Days
                      <input type="radio" name="days_supply" class="form-control" value="60" formControlName="days_supply" id="days_supply_60"> 60 Days
                      <input type="radio" name="days_supply" class="form-control" value="90" formControlName="days_supply" id="days_supply_90"> 90 Days
                    </div>
                    
                    <h2 class="fWeight-600 mb-4 d-block text-center">Do you get all of your drugs at a “30 Day Supply?"</h2>
                    <div class="row py-3">
                      <div class="col-12 p-1">
                        <a class="btn input-quote-btn btn-small btn-often mx-auto" id="days_supply_always_30" (click)="click_drug_supply($event, 30, true)" [class.active]="!isShowNo_day_supply">Yes <i class="fas fa-check ml-2"></i></a>
                      </div>
                      <div class="col-12 p-1" *ngIf="isShowNo_day_supply">
                        <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="oftenSelection = true;isAllDrug_30_day_supply = false">No <i class="fas fa-check ml-2"></i></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row custom-amount">
                  <div *ngIf="oftenSelection" class="packaging-list col-12">
                    <div class="d-none">
                      <input type="radio" name="days_supply" class="form-control" value="30" formControlName="days_supply" id="days_supply_30"> 30 Days
                      <input type="radio" name="days_supply" class="form-control" value="60" formControlName="days_supply" id="days_supply_60"> 60 Days
                      <input type="radio" name="days_supply" class="form-control" value="90" formControlName="days_supply" id="days_supply_90"> 90 Days
                    </div>
                    
                    <h2 class="fWeight-600 mb-4 d-block text-center">How Often Do You Get Your <br> Supply of This Drug?</h2>
                    <label>Choose one</label>
                    <div class="row">
                      <div class="col-12 p-1">
                        <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="click_drug_supply($event, 30)">30 Days <i class="fas fa-check ml-2"></i></a>
                      </div>
                      <div class="col-12 p-1">
                        <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="click_drug_supply($event, 60)">60 Days <i class="fas fa-check ml-2"></i></a>
                      </div>
                      <div class="col-12 p-1">
                        <a class="btn input-quote-btn btn-small btn-often mx-auto" (click)="click_drug_supply($event, 90)">90 Days <i class="fas fa-check ml-2"></i></a>
                      </div> 
                    </div> 
                  </div>

                  <div class="alert alert-danger col-12 p-2 mt-3 d-none" role="alert" id="drugSupplyWarningID">
                    Please Enter The Supply this drug.
                  </div>

                  <div class="col-12 d-flex justify-content-center mt-4">
                    <a class="btn btn-third btn-small mr-2" (click)="onClickHowOften_Back_New()">Back</a>
                    <button type="button" class="btn btn-first btn-small mx-0" (click)="checkSupplyValue()">Proceed</button>
                  </div>
                </div>
              </div>

          </form>
      
      </div>
      <div class="col-12 col-md-3 mapd-drugs-right">
        <app-common-your-drug-list></app-common-your-drug-list>
      </div>
    </div>
  </div>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk class="noprint"></app-ready-to-talk>


