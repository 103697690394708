<div class="container-fluid header-banner-wrapper access-forms force-height-auto" *ngIf="loadingHash">
  <button *ngIf="dialogData" class="close close-icon" mat-button (click)="closeModal()">
      <mat-icon class="color_white">close</mat-icon>
  </button>
  <div class="container px-0">
    <div class="row flex-column-reverse flex-md-row">
        <div class="col-12 col-md-6 p-0 form-character-wrapper">
        <div class="login-left-text">            
            <h5 class="mb-4">The Power of Your TapTap Beneficiary Dashboard:</h5>            
            <ul>
                <li>Being a Medicare Beneficiary is Hard...</li>
                <li>And There Are a Lot of Moving Pieces</li>
                <li>I Need One Central Place to Organize Everything, and See Everything</li>
                <li>A Place That Will Help Me Understand My Situation At All Times</li>
                <li>And Guide Me Through My Options & Choices</li>
                <li>Proactive, Clear, and Organized.</li>
            </ul>
            <img src="./assets/img/common/woman-pink-dress-cut-with-gradient.svg" alt="Sign Up Character"> 
            <h5 class="mt-5 mb-3">Key Sections Of Your Dashboard</h5>
            <ul>
                <li>My Healthcare</li>
                <li>My Financials</li>
                <li>My Coverages</li>
                <li>My Documents</li>
            </ul>            
        </div>   
        </div>
        <ng-container *ngIf="isValidHash">
          <div class="col-12 col-md-6 box-wrapper">
            <div class="primary-box">
                <div class="box-top p-4 p-md-5">
                  <h4 class="text-center fWeight-600 mb-4">Login To Your TapTap Account</h4>
                  <form [formGroup]="formGroup">
                      <div class="row mb-1">
                          <div class="col px-1">
                              <label class="form-label" for="passcode">Passcode<sup>*</sup></label>
                              <input type="passcode" class="form-field" name="passcode" id="passcode" formControlName="passcode" required />
                          </div>
                      </div>
                        <div class="d-flex flex-column flex-lg-row mt-3 mb-3 align-items-center justify-content-between">
                            <button type="submit" class="btn btn-first btn-small btn-login-now" (click)="login()">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
              </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isValidHash">
          <div class="col-12 col-md-6 box-wrapper">
            <div class="primary-box">
                <div class="box-top p-4 p-md-5">
                  <h4 class="text-center fWeight-600 mb-4">Sorry Your Hash is either Invalid or Expired.</h4>
                </div>
              </div>
          </div>
        </ng-container>
    </div>
  </div>
</div>
