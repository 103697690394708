<h4 class="title mb-3">Finish | Preference</h4>

<div class="col-12 mb-4">
  <p class="fWeight-600 fColor-black mb-0">Please check any of the boxes below if you would prefer us to send you information in a language other than English or in an accessible format:</p>
  <div class="row px-0">
    <div class="col-12 mt-4">
      <div class="row">
        <div class="form-group mb-0 col-4 mx-0 pl-0">     
          <div class="custom-control custom-checkbox mt-2">
              <input type="checkbox" class="custom-control-input" id="selectLanguage_1">
              <label class="custom-control-label" for="selectLanguage_1">Other language (please specify)</label>
          </div>
        </div>
        <div class="form-group mb-0 col">     
          <input type="text" class="form-control blurred"/>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group mb-0">     
        <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="selectLanguage_2">
            <label class="custom-control-label" for="selectLanguage_2">Braille</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group mb-0">     
        <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="selectLanguage_3">
            <label class="custom-control-label" for="selectLanguage_3">Audio tape</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group mb-0">     
        <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="selectLanguage_4">
            <label class="custom-control-label" for="selectLanguage_4">Large print</label>
        </div>
      </div>
    </div>
  </div>
</div>

<p>Please contact this Medicare Advantage Plan Insurance Carrier, {{ planName }}
   if you need information in an accessible format or language other than what is listed above</p>

<p><strong>Privacy Act Statement</strong></p>

<p>The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track
  beneficiary enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1851 and 1860D-1 of the Social Security Act and 42 CFR §§ 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and exchange enrollment 
  data from Medicare beneficiaries as specified in the System of Records Notice (SORN) “Medicare Advantage Prescription Drug (MARx)”, System No. 09-70-0588. Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan. 
</p>

<h5 class="text-center my-5">Please Read and Sign.</h5>

<h6 class="fColor-black mb-4">By completing this form, I agree to the following:</h6>

<div class="col-12">
  <div class="form-group">     
    <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" class="custom-control-input" id="thisIsAMapdPlan">
        <label class="custom-control-label" for="thisIsAMapdPlan">This is a Medicare Advantage plan. It has a contract with the federal government. This is not a Medicare Supplement plan.</label>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="form-group">     
    <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" class="custom-control-input" id="mustKeepAB">
        <label class="custom-control-label" for="mustKeepAB">I must keep both Part A and Part B to stay in this Medicare Advantage Plan, [Medicare Advantage Plan Name]. I must keep paying my Part B premium if I have one, unless Medicaid or someone else pays for it.</label>
    </div>
  </div>
</div>

<div class="col-12 text-center mt-5">
  <div class="form-group">     
    <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" class="custom-control-input" id="iAgree_Finish" (change)="onChange_IAgree_Finish($event)">
        <label class="custom-control-label" for="iAgree_Finish">I Agree</label>
    </div>
  </div>
</div>

<div class="mx-auto row mb-4 col-9" *ngIf="isShowSignature">
  <div class="row col-12 col-md-6">
    <div class="form-group col-12 p-1">
      <label for="">Applicant's Signature:</label>
      <input type="text" class="form-control blurred" [(ngModel)]="applicant_signature"/>
    </div>    
  </div>    
  <div class="form-group col-12 col-md-6 p-1">
    <label for="">Select Date</label>
    <input type="text" class="form-control blurred" [(ngModel)]="SelectedDate"/>
  </div>
</div>


  <!-- <h4 class="title">Please Read and Sign</h4>
  <h6 class="mb-4 fWeight-600 fColor-black">By completing this form, I agree to the following:</h6>
  <div class="row px-0">
    <div class="col-12">
        <div class="form-group">     
          <div class="custom-control custom-checkbox mt-2">
              <input type="checkbox" class="custom-control-input" id="thisIsAMedicareAdvantagePlan">
              <label class="custom-control-label" for="thisIsAMedicareAdvantagePlan">This is a Medicare Advantage plan. It has a contract with the federal government. This is not a Medicare Supplement plan</label>
          </div>
        </div>
    </div>
    <div class="col-12">
      <div class="form-group">     
        <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="iMustKeepBothPartAB">
            <label class="custom-control-label" for="iMustKeepBothPartAB">I must keep both Part A and Part B to stay in UnitedHealthcare. I must keep paying my Part B premium if I have one, unless Medicaid or someone else pays for it.</label>
        </div>
      </div>
    </div>
  </div> -->
