import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TermService {

    public fullUnderwriting: any = {
        deathBenefit: '',
    }

    constructor() {
    }

    /*setValue(value) {
        this.fullUnderwriting.deathBenefit = value;
    }

    getValue() {
        return this.fullUnderwriting.deathBenefit;
    }*/
}
