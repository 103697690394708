<div *ngIf="!loading && results.length">
  <div class="result-item pdp-version-5 result-version-5 container columns3 vp-btn-inner" *ngFor="let result of results; let i = index">
    <table class=" result-section">
      <tr class="tr-result-heading" [class.bgBlue]="isBlueBgHeading">
        <th class="heading-company first-column">Company</th>
        <th class="heading-ratings second-column">Benefits</th>
        <th class="heading-ratings third-column">Ratings</th>
        <th class="heading-pricing fourth-column">Costs</th>
      </tr>
      <tr>
        <td class="first-column result-logo-wrapper" *ngIf="result.plan_name=='SilverScript SmartRx (PDP)' || result.plan_name=='SilverScript Choice (PDP)' || result.plan_name=='SilverScript Plus (PDP)'">
          <span class="no-img-plan-name d-none">{{ result.plan_name }}</span>
          <img src="{{base_img_loc + 'silverscript.jpg'}}"
               onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
               alt="{{ result.plan_name }}"
               class="result-logo"
               (error)="companyLogoCheck($event)"
               id="logo_{{result.organization_name}}_{{result.key}}_{{i}}">

            <h6 class="results-plan-name">{{ result.plan_name }}</h6>

            <div class="d-flex align-items-center justify-content-center star-rating">
              <app-star-rating rating="{{ result?.overall_star_rating }}"></app-star-rating>
            </div>
        </td>

        <td class="first-column result-logo-wrapper"  *ngIf="result.plan_name!='SilverScript SmartRx (PDP)' && result.plan_name!='SilverScript Choice (PDP)' && result.plan_name!='SilverScript Plus (PDP)'">
            <span class="no-img-plan-name d-none">{{ result.plan_name }}</span>

            <img src="{{base_img_loc + replaceSpaceWith(result.organization_name) + '.jpg'}}"
                 onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
                 alt="{{ result.plan_name }}"
                 class="result-logo"
                 (error)="companyLogoCheck($event)"
                 id="logo_{{result.organization_name}}_{{result.key}}_{{i}}">

            <h6 class="results-plan-name">{{ result.plan_name }}</h6>

            <div class="d-flex align-items-center justify-content-center star-rating">
              <app-star-rating rating="{{result?.overall_star_rating}}"></app-star-rating>
            </div>
        </td>

        <td class="second-column">
          <div class="row">
            <div class="col-12 col-md-6 text-center mb-5">
              <h6 class="fColor-blue fWeight-600 mb-0">Part D Benefit Type</h6>
              <span class="fColor-black fWeight-600">Enhanced</span>
            </div>
            <div class="col-12 col-md-6 text-center mb-5">
              <h6 class="fColor-blue fWeight-600 mb-0">Includes Gap Coverage</h6>
              <span class="fColor-black fWeight-600">Yes</span>
            </div>
            <div class="col-12 col-md-6 text-center">
              <h6 class="fColor-blue fWeight-600 mb-0">Beneficiaries Enrolled<br> In Pennsylvania</h6>
              <span class="fColor-black fWeight-600">123,456</span>
            </div>
            <div class="col-12 col-md-6 text-center">
              <h6 class="fColor-blue fWeight-600 mb-0">Total Drugs On <br> Formulary</h6>
              <span class="fColor-black fWeight-600">3,456</span>
            </div>
          </div>
        </td>

        <td class="third-column benefits-costs-wrapper">
          <div class="benefits-costs-inner">
            <div class="d-flex flex-column text-center">
              <span class="fColor-blue uppercase fWeight-600 d-block font-18">Deductible</span>
              <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em font-20 fWeight-600">{{getConvertedRate(result.annual_drug_deductible) | currency }}</span>
              <small class="fColor-base fWeight-600 d-block">Per Year</small>                
              <div class="d-flex flex-column align-items-center mt-3">
                <div class="d-flex align-items-center">
                  <span class="fColor-faint-grey uppercase fWeight-600 d-block text-center mr-2 font-13 line-height-0-5cm">Tiers Exempt</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z"/></svg>
                </div>
                <span class="line-height-0-5cm">
                  <strong class="line-height-0-5cm font-16" [innerHTML]="(result?.taptap_details?.tiers_exempt_from_deductible) ? result?.taptap_details?.tiers_exempt_from_deductible : 'N/A'"></strong>
                </span>
              </div>
              <div class="divider dashed border-width-thin my-2"></div>
              <h6 class="fColor-blue fWeight-600 mb-0">Tier 1 Drugs:</h6>
              <span class="fColor-black fWeight-600">456</span>
            </div>
          </div>
        </td>

        <td class="fourth-column">
          <div class="d-flex flex-column benefits-costs-wrapper-right pricing-content">
            <span class="fColor-blue uppercase fWeight-600 d-block text-center font-18">Premium</span>
            <span class="price-value d-block fColor-black text-right text-md-center lHeight-1em font-28 fWeight-600">{{getConvertedRate(result.month_rate) | currency }}</span>
            <small class="fColor-base fWeight-600 d-block text-center mb-3">Per Month</small>
          </div>
        </td>
      </tr>
      <tr class="tr-action-btn">
        <div class="plan-document-buttons d-flex flex-column flex-md-row pt-3">                      
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button1_{{result.key}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_planDocsResources(result.key)">
              <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
              More Benefits & Details
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow1_{{result.key}}">
              <div class="arrow"></div>
            </div>
          </div>
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button2_{{result.key}}" class="more-button btn btn-third btn-xs position-relative" (click)="onclick_doctorDrugAnalysis(result.key)">
              <i class="fa fa-file-text-o mr-2" aria-hidden="true"></i>
              Your Drugs & Pharmacies
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow2_{{result.key}}">
              <div class="arrow"></div>
            </div>
          </div>
          <div class="w-100 mx-md-2 mb-2 mb-md-0">
            <button id="#button3_{{result.key}}" class="compare-button btn btn-third btn-xs position-relative" (click)="onclick_planActionButtons(result.key)">
              Save, Compare, or Enroll
            </button>
            <div class="d-none justify-content-center mt-2" id="arrow3_{{result.key}}">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
      </tr>
    </table>
    
    <!-- MORE BENEFITS & DOCUMENTS -->
    <div class="d-none row doctors-drugs-actions2 mx-auto" id="docs_resources_id_{{result.key}}">
      <div class="col-12 col-lg-6 p-4">
        <div class="p-4 bg-light border h-100">
          <div class="d-flex justify-content-center">
            <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="">
          </div>
          <h4 class="title-header text-center text-dgray">Plan Benefits</h4>
          <div class="w-full d-flex flex-column justify-content-around">          
            <a href="/insurance-marketplace/medicare-drug-coverage/plan-profile/{{result.key}}/{{this.controls.zipcode.value}}?zipcode={{controls.zipcode.value}}&planyear={{planYear}}&plan={{controls.plan.value}}" target="_blank" class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second">View Full Page Of This Plan’s Benefits</a>
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="onClick_MedicareStarRatings()">View This Plan’s Full Set of Medicare Star Ratings</button>
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="onClick_ViewFullFormulary(result)">View This Plan’s Full Formulary</button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 p-4">
        <div class="p-4 bg-light border h-100">
          <div class="d-flex justify-content-center">
            <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="">
          </div>
          <h4 class="title-header text-center text-dgray">Plan Benefits</h4>
          <div class="w-full d-flex flex-column justify-content-around">          
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="onClick_LisExtra(result)">View This Plan’s Monthly Premiums with LIS Extra Help</button>
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="onClick_SeniorSavings(result)">View This Plan’s Senior Savings Model Details</button>
          </div>
        </div>
      </div>
    </div>

    <!-- DOCTORS & DRUGS -->
    <div class="d-none row doctors-drugs-actions2 mx-auto" id="doctors_drugs_id_{{result.key}}">
      <div class="col-12 col-lg-6 p-4">
        <div class="bg-light p-4">
          <div class="d-flex justify-content-center">
              <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="">
          </div>
          <h4 class="title-header text-center">Plan Benefits</h4>
          <div class="w-full d-flex flex-column justify-content-around">
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="onClick_ViewGeneralCopaysPerTier(result)">View This Plan’s Drug Copays Per Tier In The Regular Coverage Stage</button>
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="addDrugs(addMyDrugsModal, { marketplace_type: 'pdp', plan_type: plan_val ,state: result.state, county: result.county, zipcode: controls.zipcode.value, contract_id: result.contract_id, plan_id: result.plan_id, segment_id: result.segment_id, plan_year: planYear })">Add My Drugs To See My Specific Drug Copays Throughout The Year</button>
          </div>        
        </div>
      </div>
      <div class="col-12 col-lg-6 p-4">
        <div class="bg-light p-4">
          <div class="d-flex justify-content-center">
              <img src="./assets/img/common/icons/plan-display/plan documents-icon.svg" alt="">
          </div>
          <h4 class="title-header text-center">Plan Benefits</h4>
          <div class="w-full d-flex flex-column justify-content-around">
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="onClickPharmacyNetwork(result)">View The Status of The Most Common Pharmacies Within This Plan’s Pharmacy Network</button>
            <button class="link-button w-md-full mx-md-2 my-2 btn btn-small btn-second" (click)="redirectToAddingPharmacy(result)">Search For Your Own Pharmacy Within This Plan’s Pharmacy Network</button>
          </div>        
        </div>
      </div>
    </div>

    <!-- PLAN ACTION BUTTONS -->
    <div class="d-none row doctors-drugs-actions2 mx-auto" id="plan_actions_id_{{result.key}}">
      <div class="col-12 col-lg-6 p-4">
        <div class="p-4 bg-light border h-100 enrollment-button">
          <button class="save d-flex align-items-center px-3 py-2 my-2" (click)="saveThisPlan()">
            <img class="small-icon" src="./assets/img/common/save.svg" alt="">
            <div class="d-flex justify-content-center flex-grow-1">
              <span>SAVE THIS PLAN</span>
            </div>
          </button>
          <app-share-plan [sharePlanId]=result.key></app-share-plan>
          <div class="addcompare-button w-100 my-2 d-flex align-items-center justify-content-center">
            <input type="checkbox" class="compare-input" id="addToCompare_{{result.key}}" (change)="addToCompareFunc(result,$event,i)" [checked]="logComparedKeys.includes(result.key)">
            <label class="compare-label mb-0" for="addToCompare_{{result.key}}">Add To Compare</label>
          </div>
          <a id="viewComparePlansBtn_{{i}}" target="_blank" href="/insurance-marketplace/medicare-drug-coverage/results/compare-plans?zipcode={{controls.zipcode.value}}&planyear={{planYear}}&plan={{controls.plan.value}}&compare={{part_d_id}}" class="viewComparePlansBtn btn btn-third-plain btn-xs d-none mt-2" [class.d-block]="logComparedKeys.includes(result.key)">View Compare Plans</a>
      </div>
      </div>
      <div class="col-12 col-lg-6 p-4">
        <div class="p-4 bg-light border h-100 enrollment-button">
  
          <button class="online d-flex align-items-center px-3 py-2 my-2" (click)="onClick_ApplyOnline(result, i)">
            <img class="small-icon" src="./assets/img/common/online.svg" alt="">
            <div class="d-flex justify-content-center flex-grow-1">
              <span>ENROLL ONLINE</span>
            </div>
          </button>
          <button class="phone d-flex align-items-center px-3 py-2 my-2" (click)="onClick_ApplyOnThePhone(result)">
            <img class="small-icon" src="./assets/img/common/phone.svg" alt="">
            <div class="d-flex justify-content-center flex-grow-1">
              <span>ENROLL ON THE PHONE</span>
            </div>
          </button>
          <button class="cart d-flex align-items-center p-0" id="ci_cart_btn__{{result.key}}" [class.active]="logCartKeys.includes(result.key)">
            <label class="cart-btn-label" for="ci_add_to_cart__{{result.key}}">
              <img class="small-icon" src="./assets/img/common/cart.svg" alt="">
              <span class="flex-grow-1">{{ addToCartLabel }}</span>
            </label>
          </button>
          <input type="checkbox" class="d-none" id="ci_add_to_cart__{{ result.key }}" (change)="onClick_AddToCart(result,$event)" [checked]="logCartKeys.includes(result.key)">
          <a target="_blank"
              href="/cart"
              class="viewCartBtn btn btn-third-plain btn-sm d-none ff mt-2"
              id="view_cart_btn_{{result.key}}"
              [class.d-block]="logCartKeys.includes(result.key)">View Cart</a>
        </div>
      </div>
    </div>
    
  </div>
</div>

<div class="d-none">
  <div #addMyDrugsModal class="d-flex align-items-center flex-column justify-content-center text-center">
    <img src="./assets/img/common/icons/plan-display/drug_pharmacy.svg" style="max-width:180px" class="mb-3" alt="SilverSneakers Program Guide">
    <p style="font-size:25px; color: #003DA8;" class="fWeight-600">You Will Be Taken To TapTap’s Rx Profile Dash To Input Your Prescription Drugs & Pharmacy Preferences. </p>
    <p style="font-size:22px" class="fWeight-600">Click "GO" to Proceed </p>
    <!-- <button RouterLink="" class="btn btn-first btn-small px-5">Go</button> -->
  </div>
</div>