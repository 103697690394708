<div class="container">
  <div>
    <h4 class="fw-semibold text-dblue pt-3">Replacement Notice: Statement To The Applicant</h4>
    <div class="yellow-line"></div>
    <div class="py-3">
      <div class="dashed-box border shadow p-4">
        <h4 class="text-center fw-semibold">Please Read and Sign.</h4>
        <div>
          <p class="text-label">By completing this form, I agree to the following:</p>
          <div class="p-2">
            <div class="">
              <h6 class="d-flex justify-content-center text-center">Statement to the Applicant by Issuer, Agent (Broker or Other Representative)</h6>
            </div>
          </div>
          <div class="py-2">
            <p class="text-center d-flex justify-content-center">
              I have reviewed your current medical or health insurance coverage. To the best of my knowledge, this Medicare Supplement policy will not duplicate your existing Medicare Supplement or, if applicable, Medicare Advantage coverage because you intend to terminate your existing Medicare Supplement coverage or leave your Medicare Advantage plan.
            </p>
          </div>
        </div>
        <div class="py-2">
          <h6 class="fw-semibold">The replacement policy/certificate is being purchased for the following reason (check one):</h6>
        </div>
        <div class="py-2">
          <ul class="radioCheckbox">
            <li><label for="muhRadio1"><input type="radio" name="selectedValue" (click)="getSelected('additionalBenefits')" value=""/> Additional Benefits </label></li>
            <li><label for="muhRadio2"><input type="radio" name="selectedValue" (click)="getSelected('noChange')" value=""/> No Change In Benefits, But Lower Premiums</label></li>
            <li><label for="muhRadio3"><input type="radio" name="selectedValue" (click)="getSelected('fewerBenefits')" value=""/> Fewer Benefits and Lower Premiums</label></li>
            <li><label for="muhRadio4"><input type="radio" name="selectedValue" (click)="getSelected('outpatientPartD')" value=""/> My Plan Has Outpatient Prescription Drug Coverage and I Am Enrolling In Part D</label></li>
            <li><label for="muhRadio5"><input type="radio" name="selectedValue" (click)="getSelected('disenrollment')" value=""/> Disenrollment From A Medicare Advantage Plan (Please Explain Reason For Disenrollment)</label>
              <ul *ngIf="disenrollment">
                <li>
                  <label for="common1">Common Reason #1:</label>
                  <input type="text">
                </li>
                <li>
                  <label for="common2">Common Reason #2:</label>
                  <input type="text">
                </li>
              </ul>
            </li>
           
            <li>
              <label for="muhRadio6">
              <input type="radio" (click)="getSelected('others')" name="selectedValue" value=""/> Other(Please Specify)</label>

              <ul *ngIf="others">
                <li>
                    <input type="text">
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="py-2">
          <ol>
            <li>
              Health conditions which you may presently have (pre-existing conditions) may not be immediately or fully covered under the new policy. This could result in denial or delay of a claim for benefits under the new policy, whereas a similar claim might have been payable under your present policy.
            </li>
            <li>
              State law provides that your replacement policy or certificate may not contain new pre-existing conditions, waiting periods, elimination periods or probationary periods. The insurer will waive any time periods applicable to pre-existing conditions, waiting periods, elimination periods or probationary periods in the new policy (or coverage) for similar benefits to the extent such time was spent (depleted) under the original policy.
            </li>
            <li>
              If you still wish to terminate your present policy/certificate and replace it with new coverage, be certain to truthfully and completely answer all questions on the application concerning your medical and health history. Failure to include all material medical information on an application may provide a basis for the company to deny any future claims and to refund your premium as though your policy/certificate had never been in force. After the application has been completed and before you sign it, review it carefully to be certain that all information has been properly recorded. 
            </li>
          </ol>
          <h6 class="d-flex justify-content-center text-center mt-3">Do not cancel your present policy/certificate until you have received your new policy/certificate and are sure that you want to keep it.</h6>
        </div>
        <div class="py-3">
          <h6 class="text-center fw-medium text-secondary mb-2">Upload Your Signature</h6>
          <div class="custom-upload-button mx-auto">
            <span class="label"><i class="fas fa-upload"></i> CLICK HERE TO UPLOAD AN IMAGE</span>
            <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File">
          </div>
          <p class="text-center mt-2"><em>You can take a photo of your signature from a paper and upload the photo here.</em></p>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="form-group col-12 col-md-3 p-2">
            <label for="date" class="text-label">Date for Today</label>
            <div class="dob-field">
              <input type="text" class="form-control blurred" [(ngModel)]="applicantStatementAgreeDate" id="date" (click)="onClickDob(appDOB,'normal','What is the Date for Today?')"/>
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-3">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="iAgree16">    
              <label class="custom-control-label fw-semibold text-dblue" for="iAgree16">I Agree</label>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #appDOB>
    <app-dob [fieldType]="dateFieldType" [heading]="dateFieldHeading" (dobValue)="onReceiveDate($event)"></app-dob>
  </div>
</div>