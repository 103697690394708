import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
/*import { UserComponent } from './user/user.component';*/
import { HomeComponent } from './home/home.component';
import { ArticlesComponent } from './articles/articles.component';
/*import { AdminComponent } from './admin/admin.component';*/
/*import { SupportComponent } from './support/support.component';*/
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { CreateTicketComponent } from './create-ticket/create-ticket.component';
import { RegistrationComponent } from './registration/registration.component';
import { MedicareServicesComponent } from './medicare-services/medicare-services.component';
import { IAmNewToMedicareComponent } from './i-am-new-to-medicare/i-am-new-to-medicare.component';
import { LoginComponent } from './login/login.component';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { TestimonialsPageComponent } from './testimonials/testimonials.component';
import { PartnershipOpportunitiesComponent } from './partnership-opportunities/partnership-opportunities.component';
import { BecomeAContributorComponent } from './become-a-contributor/become-a-contributor.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { LogoutComponent } from './logout/logout.component';
// import { PartBEnrollmentCenterComponent } from './part-b-enrollment-center/part-b-enrollment-center.component';
import { MedicareOrganizerComponent } from './medicare-organizer/medicare-organizer.component';
import { AuthGuardService } from './login/auth-guard.service';
import { TaptapMedicareMedigapPricesComponent } from './taptap-medicare-medigap-prices/taptap-medicare-medigap-prices.component';
import { TaptapMedicareMedigapPricesTwoComponent } from './taptap-medicare-medigap-prices-two/taptap-medicare-medigap-prices-two.component';
import { TaptapNewToMedicareComponent } from './taptap-new-to-medicare/taptap-new-to-medicare.component';
import { TaptapNewToMedicareTurningSixtyFiveComponent } from './taptap-new-to-medicare-turning-sixty-five/taptap-new-to-medicare-turning-sixty-five.component';
import { TaptapNewToMedicarePartBComponent } from './taptap-new-to-medicare-part-b/taptap-new-to-medicare-part-b.component';
import { TaptapMedicarePartBEnrollmentComponent } from './taptap-medicare-part-b-enrollment/taptap-medicare-part-b-enrollment.component';
/*import { BeneficiaryModule } from './beneficiary/beneficiary.module';*/
import { ComparePlansComponent } from './compare-plans/compare-plans.component';


/*************************************
 **********LANDING PAGES************** 
 *************************************/
import { StLouisMissouriComponent } from './city-based/st-louis-missouri/st-louis-missouri.component';
import { BostonComponent } from './city-based/boston/boston.component';
import { HartfordConnecticutComponent } from './city-based/hartford-connecticut/hartford-connecticut.component';
import { ManhattanComponent } from './city-based/manhattan/manhattan.component';
import { MiamiComponent } from './city-based/miami/miami.component';
import { PhiladelphiaComponent } from './city-based/philadelphia/philadelphia.component';
import { PhoenixArizonaComponent } from './city-based/phoenix-arizona/phoenix-arizona.component';
import { TexasComponent } from './city-based/texas/texas.component';
import { WilmingtonDelawareComponent } from './city-based/wilmington-delaware/wilmington-delaware.component';
import { MedigapLandingPageComponent } from './medigap-landing-page/medigap-landing-page.component';
import { MedigapLandingPageTwoComponent } from './medigap-landing-page-two/medigap-landing-page-two.component';
import { DenverComponent } from './city-based/denver/denver.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


import { MapdPlanCustomizerDualEligibleComponent } from './mapd-customizer/mapd-plan-customizer-dual-eligible/mapd-plan-customizer-dual-eligible.component';
import { MapdPlanCustomizerHealthFitnessComponent } from './mapd-customizer/mapd-plan-customizer-health-fitness/mapd-plan-customizer-health-fitness.component';
import { MapdPlanCustomizerVeteransComponent } from './mapd-customizer/mapd-plan-customizer-veterans/mapd-plan-customizer-veterans.component';
import { WebinarComponent } from './webinar/webinar.component';
import { WebinarV2Component } from './webinar-v2/webinar-v2.component';

import { SliderFormComponent } from './slider-form/slider-form.component';

import { AepComponent } from './aep/aep.component';
import { ContentAComponent } from './aep/contents/content-a/content-a.component';
import { ContentBComponent } from './aep/contents/content-b/content-b.component';
import { WhatAreSomeOtherMedicareEnrollmentPeriodsIShouldBeAwareOfComponent } from './aep/contents/what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of/what-are-some-other-medicare-enrollment-periods-i-should-be-aware-of.component';

import { MedigapPlanGV2Component } from './medigap-plan-g-v2/medigap-plan-g-v2.component';
import { MedigapPlanGV1Component } from './medigap-plan-g-v1/medigap-plan-g-v1.component';
import { MedigapPlanNV2Component } from './medigap-plan-n-v2/medigap-plan-n-v2.component';
import { MedigapPlanNV1Component } from './medigap-plan-n-v1/medigap-plan-n-v1.component';

import { RetirementLivingComponent } from './retirement-living/retirement-living.component';
import { MedicareAdvantageBaseComponent } from './medicare-advantage-base/medicare-advantage-base.component';
import { PartDDrugCoverageComponent } from './part-d-drug-coverage/part-d-drug-coverage.component';
import { IndividualDentalInsuranceComponent } from './individual-dental-insurance/individual-dental-insurance.component';
import { ProviderDetailComponent } from './insurance-marketplace/medicare-advantage/results/provider-detail/provider-detail.component';
import { OutOfPocketComponent } from './out-of-pocket/out-of-pocket.component';
import { DemoComponent } from './demo/demo.component';
import { EmailVerificatonComponent } from './email-verificaton/email-verificaton.component';


const routes: Routes = [
    /*{ path: 'users', loadChildren: './users/users.module#UsersModule' },*/
    {
        path: 'insurance-marketplace',
        loadChildren:
            () => import('./insurance-marketplace/insurance-marketplace.module').then(m => m.InsuranceMarketplaceModule),
        data: { animation: 'InsuranceMarketplacePage' }
    },
    {
        path: 'life-insurance',
        loadChildren: () => import('./life-insurance/life-insurance.module').then(m => m.LifeInsuranceModule),
        data: { animation: 'LifeInsurancePage' }
    },
    {
        path: 'life-insurance-v2',
        loadChildren: () => import('./life-insurance-v2/life-insurance-v2.module').then(m => m.LifeInsuranceV2Module),
        data: { animation: 'LifeInsurancePage' }
    },
    /*{ path: 'user', component: UserComponent , canActivate: [AuthGuardService] },*/
    { path: 'home', component: HomeComponent, data: { animation: 'HomePage' } },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    /*{ path: 'admin', component: AdminComponent },*/
    { path: 'articles', component: ArticlesComponent },
    { path: 'create-ticket', component: CreateTicketComponent },
    /*{ path: 'support', component: SupportComponent },*/
    { path: 'contact', component: ContactComponent, data: { animation: 'ContactPage' } },
    { path: 'about', component: AboutComponent, data: { animation: 'AboutPage' } },
    {
        path: 'education',
        loadChildren:
            () => import('./educationcenter/educationcenter.module').then(m => m.EducationcenterModule),
        data: { animation: 'EducationPage' }
    },
    {
        path: 'community',
        loadChildren:
            () => import('./community-dashboard/community-dashboard.module').then(m => m.CommunityDashboardModule),
        data: { animation: 'CommunityPage' }
    },
    { path: 'registration', component: RegistrationComponent, data: { animation: 'RegistrationPage' } },
    { path: 'registration/:passed_firstname/:passed_lastname/:passed_email/:passed_mobile', component: RegistrationComponent, data: { animation: 'RegistrationPage' } },
    { path: 'medicare-services', component: MedicareServicesComponent, data: { animation: 'MedicareServicesPage' } },
    { path: 'i-am-new-to-medicare', component: IAmNewToMedicareComponent, data: { animation: 'NewToMedicarePage' } },
    {
        path: 'login',
        component: LoginComponent,
        data: { animation: 'LoginPage' },
    },
    { path: 'logout', component: LogoutComponent },
    { path: 'terms-of-services', component: TermsOfServicesComponent, data: { animation: 'TermsOfServicesPage' } },
    { path: 'testimonials', component: TestimonialsPageComponent, data: { animation: 'TestimonialsPage' } },
    /*{
        path: 'retiree-dashboard',
        loadChildren: './retiree-dashboard/retiree-dashboard.module#RetireeDashboardModule',
        /!*canActivate: [AuthGuardService],*!/
        data: {animation:'RetireeDashboardPage'}
    },*/
    {
        path: 'beneficiary',
        loadChildren: () => import('./beneficiary/beneficiary.module').then(m => m.BeneficiaryModule),
        data: { animation: 'BeneficiaryModule' },
        canActivate: [AuthGuardService],
    },
    {
        path: 'benny-dash',
        loadChildren: () => import('./benny-dash/benny-dash.module').then(m => m.BennyDashModule),
        data: { animation: 'HomePage' },
        canActivate: [AuthGuardService],
    },
    {
        path: 'partnership-opportunities',
        component: PartnershipOpportunitiesComponent,
        data: { animation: 'PartnershipOpportunitiesPage' }
    },
    { path: 'become-a-contributor', component: BecomeAContributorComponent, data: { animation: 'BecomeAContributorPage' } },
    { path: 'forgot-password', component: ForgotPasswordComponent, data: { animation: 'ForgotPasswordPage' } },
    { path: 'reset-password/:token', component: UpdatePasswordComponent, data: { animation: 'ResetPasswordPage' } },
    // {
    //     path: 'part-b-enrollment-center',
    //     component: PartBEnrollmentCenterComponent,
    //     data: { animation: 'PartBEnrollmentCenterPage' }
    // },
    {
        path: 'part-b-enrollment-center',
        loadChildren:
            () => import('./part-b-enrollment-center/part-b-enrollment-center.module').then(m => m.PartBEnrollmentCenterModule),
        data: { animation: 'PartBEnrollmentCenterPage' }
    },

    { path: 'organizer', component: MedicareOrganizerComponent, data: { animation: 'MedicareOrganizerPage' } },
    { path: 'taptap-medicare-medigap-prices', component: TaptapMedicareMedigapPricesComponent },
    { path: 'taptap-medicare-medigap-prices-details', component: TaptapMedicareMedigapPricesTwoComponent },
    { path: 'taptap-new-to-medicare', component: TaptapNewToMedicareComponent },
    { path: 'taptap-new-to-medicare-turning-sixty-five', component: TaptapNewToMedicareTurningSixtyFiveComponent },
    { path: 'taptap-new-to-medicare-part-b', component: TaptapNewToMedicarePartBComponent },
    { path: 'taptap-medicare-part-b-enrollment', component: TaptapMedicarePartBEnrollmentComponent },
    //LANDING PAGES
    { path: 'st-louis-missouri', component: StLouisMissouriComponent, data: { animation: 'HomePage' } },
    { path: 'boston', component: BostonComponent, data: { animation: 'HomePage' } },
    { path: 'denver', component: DenverComponent, data: { animation: 'HomePage' } },    
    { path: 'hartford-connecticut', component: HartfordConnecticutComponent, data: { animation: 'HomePage' } },
    { path: 'manhattan', component: ManhattanComponent, data: { animation: 'HomePage' } },
    { path: 'miami', component: MiamiComponent, data: { animation: 'HomePage' } },
    { path: 'philadelphia', component: PhiladelphiaComponent, data: { animation: 'HomePage' } },
    { path: 'phoenix-arizona', component: PhoenixArizonaComponent, data: { animation: 'HomePage' } },
    { path: 'texas', component: TexasComponent, data: { animation: 'HomePage' } },
    { path: 'wilmington-delaware', component: WilmingtonDelawareComponent, data: { animation: 'HomePage' } },

    { path: 'medigap-landing-page', component: MedigapLandingPageComponent, data: { animation: 'HomePage' } },
    { path: 'medigap-landing-page-two', component: MedigapLandingPageTwoComponent, data: { animation: 'HomePage' } },

    {
        path: 'interactive',
        loadChildren:
            () => import('./interactive-welcome/interactive-welcome.module').then(m => m.InteractiveWelcomeModule),
        data: { animation: 'homepage' }
    },

    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { animation: 'HomePage' } },
    { path: 'mapd-customizer/mapd-plan-customizer-dual-eligible', component: MapdPlanCustomizerDualEligibleComponent, data: { animation: 'HomePage' } },
    { path: 'mapd-customizer/mapd-plan-customizer-health-fitness', component: MapdPlanCustomizerHealthFitnessComponent, data: { animation: 'HomePage' } },
    { path: 'mapd-customizer/mapd-plan-customizer-veterans', component: MapdPlanCustomizerVeteransComponent, data: { animation: 'HomePage' } },
    //{ path: 'compare-plans', component: ComparePlansComponent, data: { animation: 'HomePage' } },
    { path: 'webinar', component: WebinarComponent, data: { animation: 'HomePage' } },
    { path: 'medicare-webinar', component: WebinarV2Component, data: { animation: 'HomePage' } },
    { path: 'page-not-found', component: PageNotFoundComponent, data: { animation: 'HomePage' } },
    {
        path: 'slider-form',
        loadChildren: () => import('./slider-form/slider-form.module').then(m => m.SliderFormModule), 
        data: { animation: 'HomePage' }
    },
    // { path: 'interactive-slider-form', component: SliderFormComponent, data: { animation: 'HomePage' } },
    {
        path: 'welcome-page',
        component: AepComponent,
        children: [
            {
               path: 'overview',
               component: ContentAComponent
            },
            {
               path: 'aep/2022',
               component: WhatAreSomeOtherMedicareEnrollmentPeriodsIShouldBeAwareOfComponent
            }        
        ],
        data: { animation: 'HomePage' }
    },
    { path: 'medigap-plan-g-v2', component: MedigapPlanGV2Component, data: { animation: 'HomePage' } },
    { path: 'medigap-plan-g-v1', component: MedigapPlanGV1Component, data: { animation: 'HomePage' } },
    { path: 'medigap-plan-n-v2', component: MedigapPlanNV2Component, data: { animation: 'HomePage' } },
    { path: 'medigap-plan-n-v1', component: MedigapPlanNV1Component, data: { animation: 'HomePage' } },
    { path: 'retirement-living', component: RetirementLivingComponent, data: { animation: 'HomePage' } },
    { path: 'medicare-advantage', component: MedicareAdvantageBaseComponent, data: { animation: 'HomePage' } },
    {
        path: 'cart',
        loadChildren:
            () => import('./cart/cart.module').then(m => m.CartModule),
        data: { animation: 'HomePage' }
    },
    { path: 'part-d-drug-coverage', component: PartDDrugCoverageComponent, data: { animation: 'HomePage' } },
    { path: 'individual-dental-insurance', component: IndividualDentalInsuranceComponent, data: { animation: 'HomePage' } },
    { path: 'insurance-marketplace/provider-detail/:address/:other_prefix/:first_name/:middle_name/:last_name/:suffix/:credential_text', component: ProviderDetailComponent, data: { animation: 'HomePage' } },
    { path: 'out-of-pocket-coverage', component: OutOfPocketComponent, data: { animation: 'HomePage' } },
    { path: 'demo', component: DemoComponent },
    { path: 'email-verification', component: EmailVerificatonComponent },
    {
        path: '**',
        redirectTo: '/page-not-found'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
    })],
    exports: [RouterModule],
    providers: [AuthGuardService],
    declarations: []
})
export class AppRoutingModule {
}
