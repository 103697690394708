  <h3 class="text-center text-capitalize mb-4">Add Your Drugs</h3>
  <h5 class="text-center uppercase mb-4">Enter Your Provider Here:</h5>
  <table class="doctorsNetworkModal">
    <tr>
      <th class="fWeight-600 fColor-base">Drug Name</th>
      <th class="fWeight-600 fColor-base">Dosage</th>
      <th class="fWeight-600 fColor-base">Frequency</th>
    </tr>
    <tr>
      <td><input type="text" class="form-control" name="your-name"></td>
      <td><input type="email" class="form-control" name="your-email"></td>
      <td><input type="text" class="form-control" name="your-phone"></td>
    </tr>
    <tr>
      <td><input type="text" class="form-control" name="your-name"></td>
      <td><input type="email" class="form-control" name="your-email"></td>
      <td><input type="text" class="form-control" name="your-phone"></td>
    </tr>
    <tr>
      <td><input type="text" class="form-control" name="your-name"></td>
      <td><input type="email" class="form-control" name="your-email"></td>
      <td><input type="text" class="form-control" name="your-phone"></td>
    </tr>
  </table>

  <h5 class="text-center uppercase mb-4 mt-5">Choose among the most common prescriptions by Drug Type</h5>
  <div class="row">
    <div class="col-12 col-md-4 text-center">
      <span class="d-block mb-2 fWeight-500">Blood Pressure</span>
      <img src="./assets/img/common/icons/Asset 1xxxhdpi.png" alt="" class="max-width-100 mb-3">
    </div>
    <div class="col-12 col-md-4 text-center">
      <span class="d-block mb-2 fWeight-500">Cholesterol</span>
      <img src="./assets/img/common/icons/Asset 1xxxhdpi.png" alt="" class="max-width-100 mb-3">
    </div>
    <div class="col-12 col-md-4 text-center">
      <span class="d-block mb-2 fWeight-500">Diabetes</span>
      <img src="./assets/img/common/icons/Asset 1xxxhdpi.png" alt="" class="max-width-100 mb-3">
    </div>
    <div class="col-12 col-md-4 text-center">
      <span class="d-block mb-2 fWeight-500">Lung & Respiratory</span>
      <img src="./assets/img/common/icons/Asset 1xxxhdpi.png" alt="" class="max-width-100 mb-3">
    </div>
    <div class="col-12 col-md-4 text-center">
      <span class="d-block mb-2 fWeight-500">Mental Health</span>
      <img src="./assets/img/common/icons/Asset 1xxxhdpi.png" alt="" class="max-width-100 mb-3">
    </div>
    <div class="col-12 col-md-4 text-center">
      <span class="d-block mb-2 fWeight-500">Kidney Health</span>
      <img src="./assets/img/common/icons/Asset 1xxxhdpi.png" alt="" class="max-width-100 mb-3">
    </div>
  </div>