<div *ngIf="view_default">
  <h4 class="text-center text-dblue">Additional Plan Insights</h4>
  <div class="row">
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
      <a href="#" class="anchor-link" role="button" (click)="onClick_CarrierPlanDetails($event)">
        <div class="img-wrapper">
          <img src="./assets/img/additional-plan-insights/carrier-plan-details-icon.png" alt="">
        </div>
        <h6 class="fColor-blue mt-2">Carrier & Plan Details</h6>
      </a>
    </div>
  
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
      <a href="#" class="anchor-link" role="button" (click)="onClick_PlanRelatedDatesTimelines($event)">
        <div class="img-wrapper">
          <img src="./assets/img/additional-plan-insights/plan-related-dates-timelines-icon.png" alt="">
        </div>
        <h6 class="fColor-blue mt-2">Plan Related Dates & Timelines</h6>
      </a>
    </div>
  
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
      <a href="#" class="anchor-link" role="button" (click)="onClick_popularityEnrollment($event)">
        <div class="img-wrapper">
          <img src="./assets/img/additional-plan-insights/popularity-enrollment.png" alt="">
        </div>
        <h6 class="fColor-blue mt-2">Popularity & Enrollment</h6>
      </a>
    </div>
  
    <div class="col-12 col-md-6 text-center mt-0 mt-sm-5">
      <a href="#" class="anchor-link" role="button" (click)="onClick_TaptapPlanCarrierInsights($event)">
        <div class="img-wrapper">
          <img src="./assets/img/additional-plan-insights/taptap-plan-carrier-insights.png" alt="">
        </div>
        <h6 class="fColor-blue mt-2">TapTap Plan & Carrier Insights</h6>
      </a>
    </div>
    
  </div>
</div>

<div *ngIf="view_carrierPlanDetails">
  <app-carrier-plan-details></app-carrier-plan-details>
</div>

<div *ngIf="view_planRelatedDatesTimelines">
  <app-plan-related-dates-timeline></app-plan-related-dates-timeline>
</div>

<div *ngIf="view_popularityEnrollment">
  <app-popularity-enrollment></app-popularity-enrollment>
</div>

<div *ngIf="view_taptapPlanCarrierInsights">
  <app-taptap-plan-carrier-insights></app-taptap-plan-carrier-insights>
</div>

<div class="mt-5 d-flex justify-content-center">
  <button class="btn btn-third btn-small btn-back mr-2" *ngIf="!view_default" (click)="backToDefaultView()">Back</button>
  <a target="_blank" class="btn btn-first btn-small" routerLink="/insurance-marketplace/medicare-advantage/plan-details/H0624/001/2023/1/19019">TapTap Interactive Plan Page</a>
</div>