<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain banner-result">
  
  <app-header-b></app-header-b>
  <div class="container banner-wrapper pb-0">
    <div class="row">
      <div class="col-12">
        <h1 class="banner-main-title text-center">
          My RX Preferences
        </h1>
      </div>
    </div>
  </div>
</div>

<img class="d-none d-lg-block banner-image" src="/assets/img/beneficiary/my-providers/bg-providers.svg" alt="">

<div class="main-content-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-9 mapd-drugs-left">
                <app-common-step1-v2></app-common-step1-v2>
            </div>
            <div class="col-12 col-md-3 mapd-drugs-right">
                <!-- <app-common-your-drug-list></app-common-your-drug-list> -->
            </div>
        </div>
    </div>
</div>

<div class="footer-top-spacer"></div>
<app-ready-to-talk class="noprint"></app-ready-to-talk>
