<div class="overflow-hidden">
  <h4 class="text-center fw-semibold text-dblue">
    Medicare Advantage Plan <span class="text-orange">{{ planName }}</span> | Application Info
  </h4>
  <h5 class="text-center text-label fw-semibold">
    We Have Designed Your Application Info Experience For Your Clarity and Convenience. Begin By Reviewing and Inputting The Info Below:
  </h5>
  
  <div class="stepper" [class.full-line]="screenStep == 6" [class.full-line]="screenStep == 11">
    <div class="container steps-wrapper">
      <div *ngIf="screenStep <= 6" class="d-flex justify-content-around">
        <div class="step" [class.active]="screenStep >= 1">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(1)">
            <span>1</span>
          </div>
          <span class="step-label">Basic Info</span>
        </div>
        <div class="step" [class.active]="screenStep >= 2">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(2)">
            <span>2</span>
          </div>
          <span class="step-label">Contact Info</span>
        </div>
        <div class="step" [class.active]="screenStep >= 3">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(3)">
            <span>3</span>
          </div>
          <span class="step-label">Medicare Info</span>
        </div>
        <div class="step" [class.active]="screenStep >= 4">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(4)">
            <span>4</span>
          </div>
          <span class="step-label">Emergency Contact</span>
        </div>
        <div class="step" [class.active]="screenStep >= 5">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(5)">
            <span>5</span>
          </div>
          <span class="step-label">Primary Care</span>
        </div>
        <div class="step" [class.active]="screenStep >= 6">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(6)">
            <span>6</span>
          </div>
          <span class="step-label">Paying Your Plan Premium</span>
        </div>
      </div>
      <div *ngIf="screenStep > 6" class="d-flex justify-content-around">
        <div class="step" [class.active]="screenStep >= 7">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(7)">
            <span>7</span>
          </div>
          <span class="step-label">Eligible Enrollment Periods</span>
        </div>
        <div class="step" [class.active]="screenStep >= 8">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(8)">
            <span>8</span>
          </div>
          <span class="step-label">Final Questions</span>
        </div>
        <div class="step" [class.active]="screenStep >= 9">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(9)">
            <span>9</span>
          </div>
          <span class="step-label">Final Preferences</span>
        </div>
        <div class="step" [class.active]="screenStep >= 10">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(10)">
            <span>10</span>
          </div>
          <span class="step-label">Privacy Act Statement</span>
        </div>
        <div class="step" [class.active]="screenStep >= 11">
          <div class="line"></div>
          <div class="step-number" (click)="navigateToStep(11)">
            <span>11</span>
          </div>
          <span class="step-label">Enrollment Checklist</span>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container">
    <div [class.d-block]="screenStep == 1" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen1></app-online-application-mapd-version2-screen1>
    </div>
    <div [class.d-block]="screenStep == 2" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen2></app-online-application-mapd-version2-screen2>
    </div>
    <div [class.d-block]="screenStep == 3" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen3></app-online-application-mapd-version2-screen3>
    </div>
    <div [class.d-block]="screenStep == 4" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen4></app-online-application-mapd-version2-screen4>
    </div>
    <div [class.d-block]="screenStep == 5" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen5></app-online-application-mapd-version2-screen5>
    </div>
    <div [class.d-block]="screenStep == 6" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen6></app-online-application-mapd-version2-screen6>
    </div>
    <div [class.d-block]="screenStep == 7" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen7></app-online-application-mapd-version2-screen7>
    </div>
    <div [class.d-block]="screenStep == 8" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen8></app-online-application-mapd-version2-screen8>
    </div>
    <div [class.d-block]="screenStep == 9" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen9></app-online-application-mapd-version2-screen9>
    </div>
    <div [class.d-block]="screenStep == 10" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen10></app-online-application-mapd-version2-screen10>
    </div>
    <div [class.d-block]="screenStep == 11" class="d-none screen-slide animate__animated">
      <app-online-application-mapd-version2-screen11></app-online-application-mapd-version2-screen11>
    </div>
    
    <div class="my-5 d-flex justify-content-center">
      <button class="btn btn-third mx-3" *ngIf="screenStep > 1" (click)="previousScreen()">Back</button>
      <button class="btn btn-first mx-3" *ngIf="screenStep != 11" (click)="nextScreen()">Next</button>
    </div>
    
  </div>
</div>