import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-application-mapd-version2-screen5',
  templateUrl: './screen5.component.html',
  styleUrls: ['./screen5.component.scss']
})
export class Screen5Component implements OnInit {

  cantFindDoctor:any = false;

  constructor() { }

  ngOnInit() {
  }

}
