import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DrugsService} from '../../../services/drugs.service';
import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-common-step2',
  templateUrl: './common-step2.component.html',
  styleUrls: ['./common-step2.component.scss']
})
export class CommonStep2Component implements OnInit {

  /*controls: any = {
     times_taken: new FormControl('', [
      //Validators.required,
    ]),
    often_taken: new FormControl('', [
      //Validators.required,
    ]),

  };*/

  drugForm: FormGroup;
  isLoading: boolean = false;
  isShowHowOften: boolean = false;
  isMoreOptions:boolean = false;
  drugSupply: boolean = false;

  view_selectPackaging:boolean = false;
  view_selectAmount: boolean = false;
  rxcui: any;
  results: any[] = [];
  promise1: any;
  promise2: any;
  paramsObject: any;

  isOftenTaken_day:boolean = false;
  isOftenTaken_meal:boolean = false;
  isOftenTaken_week:boolean = false;
  isOftenTaken_month:boolean = false;

  times_taken:any;
  often_taken:any;
  drug_refill: any;

  formCheck:any = [];

  oftenSelection:any = false;

  isAllDrug_30_day_supply: boolean = false;

  isShowNo_day_supply: boolean = true;

  strip_drug_name:any;

  marketplaceType:any;
  paramMarketplaceType: any = 'mapd';

  constructor(private _router: Router, private route: ActivatedRoute, public drugService: DrugsService, private formBuilder: FormBuilder) { 
    
  }

  ngOnInit() {
    this.rxcui = this.route.snapshot.paramMap.get('rxcui');
    this.route.queryParamMap
      .subscribe((params) => {
          this.paramsObject = {...params };

          // check if param has marketplace_type
          if(this.paramsObject['params'].marketplace_type){
            this.paramMarketplaceType = this.paramsObject['params'].marketplace_type;

            if(this.paramMarketplaceType == 'pdp'){
              this.marketplaceType = 'medicare-drug-coverage';
            }else if(this.paramMarketplaceType == 'medigap'){
              this.marketplaceType = 'medigap';
            }else if(this.paramMarketplaceType == 'dental'){
              this.marketplaceType = 'dental';
            }else{
              this.marketplaceType = 'medicare-advantage';
            }
            
          }else{
            this.marketplaceType = 'medicare-advantage';
          }
          //end check if param has marketplace_type

        }
      );

    this.step3();
    this.drugForm = this.formBuilder.group({
      times_taken: ['', Validators.required],
      often_taken: ['', Validators.required],
      days_supply: ['', Validators.required],
      drug_refill: ['', Validators.required]
    });
    
  }

  async step3() {
    try {
      this.promise1 = await this.drugService.step3r1(this.rxcui);
      this.promise2 = await this.drugService.step3r2(this.rxcui);
      Promise.all([this.promise1, this.promise2]).then((values) => {
        this.results = values;
        this.strip_drug_name = this.drugService.getDrugName(this.results[1].idGroup.drug_info );
        this.checkDosageForm();
      });
      this.isLoading = false;
    } catch (e) {
      console.log('error', e);
      this.isLoading = false;
    } finally {
    }
  }

  checkDosageForm() {
    const dosageName = this.results[1].idGroup.name.toLowerCase();
    const dosageString = "capsule";
    const dosageString2 = "tablet";
    const dosageString3 = "injection";
    if(dosageName.includes(dosageString)) {
      this.formCheck = "capsule";
    }
    else if(dosageName.includes(dosageString2)) {
      this.formCheck = "tablet";
    }
    else if(dosageName.includes(dosageString3)) {
      this.formCheck = "injection";
    }
    else {
      this.formCheck = "meds";
    }
  }

  viewSelectAmount(result) {
    this.view_selectAmount = true;
  }

  async onSubmit() {
    // this.promise2 = await this.drugService.step3r2(this.rxcui);
    // const drugInfo = {
    //   ...this.drugForm.value,
    //   'drug_name' : this.paramsObject['params'].drug_name,
    //   'drug_id' : this.paramsObject['params'].drug_id,
    //   'rxcui' : this.rxcui,
    //   'dosage' : this.promise2
    // };
    // console.log('stifin drugInfo = ',drugInfo);

    // let existingEntries = JSON.parse(localStorage.getItem('rx_add_to_drug_list'));
    // if (existingEntries == null) {
    //   existingEntries = [];
    // }

    // existingEntries.push(drugInfo);

    // this.drugService.addList(drugInfo);

    // localStorage.setItem('rx_add_to_drug_list', JSON.stringify(existingEntries));

    this._router.navigate(['../../step3', this.rxcui], { queryParams : this.drugForm.value, queryParamsHandling: 'merge', relativeTo: this.route });

  }

  viewNextStep(rxcui) {
    this._router.navigate(['step2', rxcui], { queryParamsHandling: 'preserve', relativeTo: this.route });
  }

  click_often_taken(event, type) {
    $('.timesOftenBtn2').removeClass('error');
    $('.btn-packaging-list-item').removeClass('active');
    $('#often_taken_' + type).click();
    $(event.target).addClass('active');
    if(this.times_taken){      
      $('#oftenTakenWarningID2').removeClass('d-block').addClass('d-none');
    }
  }

  click_drug_refill(event, type) {
    $('.refillBtn').removeClass('error');
    $('.btn-refill').removeClass('active');
    $('#drug_refill_' + type).click();
    $(event.target).addClass('active');
    // if(this.times_taken){      
    //   $('#refillSelectionWarning').removeClass('d-block').addClass('d-none');
    // }
  }

  click_drug_supply(event, type, supply:boolean=false) {
    if(supply){
      this.isAllDrug_30_day_supply = true;
    }

    this.drugSupply = true;
    $('.btn-often').removeClass('active').removeClass('error');
    $('#drugSupplyWarningID').addClass('d-none').removeClass('d-block');
    $('#days_supply' + type).click();
    $(event.target).addClass('active');
    this.drugForm.controls['days_supply'].setValue(type);
  }

  onClick_ChangeDrug() {
    this._router.navigateByUrl('/insurance-marketplace/'+ this.marketplaceType +'/drugs?marketplace_type=' + this.paramMarketplaceType +'&plan_type='+ this.paramsObject['params'].plan_type +'&state=' + this.paramsObject['params'].state + '&county=' + this.paramsObject['params'].county + '&zipcode=' + this.paramsObject['params'].zipcode + '&contract_id=' + this.paramsObject['params'].contract_id + '&plan_id=' + this.paramsObject['params'].plan_id + '&segment_id=' + this.paramsObject['params'].segment_id + '&plan_year=' + this.paramsObject['params'].plan_year);
  }

  onClick_MoreOptions(){
    this.isMoreOptions = true;
  }

  onClick_shortcutOften(times,often){
    this.times_taken = times;
    this.often_taken = often;
    $('.timesOftenBtn').removeClass('active');
    document.querySelector('#'+often+'-'+times).classList.add('active');
    $('.timesOftenBtn').removeClass('error');
    $('#oftenTakenWarningID').addClass('d-none').removeClass('d-block');
  }

  onClick_MoreOptions_New(){      
    this.resetAllBtnStateAndValue();
    document.querySelector('#form_view_default').classList.add('d-none');
    document.querySelector('#form_view_default').classList.remove('d-block');
    document.querySelector('#form_view_more_option').classList.add('d-block');
    document.querySelector('#form_view_more_option').classList.remove('d-none');
    document.querySelector('#form_refill_option').classList.add('d-none');
    document.querySelector('#form_refill_option').classList.remove('d-block');
  }

  onClick_DefaultViewProceed_New(){
    if(this.times_taken && this.often_taken){
      $('.timesOftenBtn').removeClass('error');
      $('#oftenTakenWarningID').addClass('d-none').removeClass('d-block');
      document.querySelector('#form_view_default').classList.add('d-none');
      document.querySelector('#form_view_default').classList.remove('d-block');
      document.querySelector('#form_view_how_often').classList.add('d-block');
      document.querySelector('#form_view_how_often').classList.remove('d-none');
      // document.querySelector('#form_refill_option').classList.add('d-block');
      // document.querySelector('#form_refill_option').classList.remove('d-none');
    }else{      
      $('.timesOftenBtn').addClass('error');
      $('#oftenTakenWarningID').addClass('d-block').removeClass('d-none');
    }    
  }

  onClick_AsNeeded(){
    document.querySelector('#form_refill_option').classList.add('d-block');
    document.querySelector('#form_refill_option').classList.remove('d-none');
    document.querySelector('#form_view_more_option').classList.add('d-none');
    document.querySelector('#form_view_more_option').classList.remove('d-block');
  }



  onClick_DrugRefillProceed_New(){
    if(this.times_taken && this.often_taken){
      $('.btn-refill').removeClass('error');
      $('#refillSelectionWarning').addClass('d-none').removeClass('d-block');
      document.querySelector('#form_view_default').classList.add('d-none');
      document.querySelector('#form_view_default').classList.remove('d-block');
      document.querySelector('#form_view_how_often').classList.add('d-block');
      document.querySelector('#form_view_how_often').classList.remove('d-none');
      document.querySelector('#form_refill_option').classList.add('d-none');
      document.querySelector('#form_refill_option').classList.remove('d-block');

      let daySupply30 = localStorage.getItem('rx_30_day_supply');

      if(daySupply30){
        this.isShowNo_day_supply = false;
        //this.isAllDrug_30_day_supply = true;
        this.click_drug_supply(event, 30, true);
        //$('#days_supply_always_30').click();

      }else{
        //this.isAllDrug_30_day_supply = false;
        this.isShowNo_day_supply = true;
      }

    }else{      
      $('.btn-refill').addClass('error');
      $('#refillSelectionWarning').addClass('d-block').removeClass('d-none');
    }    
  }
  
  onClick_MoreOptionBack_New(){
    this.resetAllBtnStateAndValue();
    document.querySelector('#form_view_default').classList.remove('d-none');
    document.querySelector('#form_view_default').classList.add('d-block');
    document.querySelector('#form_view_more_option').classList.add('d-none');
    document.querySelector('#form_view_more_option').classList.remove('d-block');
    document.querySelector('#form_refill_option').classList.add('d-none');
    document.querySelector('#form_refill_option').classList.remove('d-block');
  }

  onClick_DrugRefillBack_New(){
    this.resetAllBtnStateAndValue();
    document.querySelector('#form_view_default').classList.remove('d-none');
    document.querySelector('#form_view_default').classList.add('d-block');
    document.querySelector('#form_view_more_option').classList.add('d-none');
    document.querySelector('#form_view_more_option').classList.remove('d-block');
    document.querySelector('#form_refill_option').classList.add('d-none');
    document.querySelector('#form_refill_option').classList.remove('d-block');
  }

  onClick_MoreOptionProceed_New(){
    if(this.times_taken && this.often_taken){
      $('.timesOftenBtn2').removeClass('error');
      $('#oftenTakenWarningID2').addClass('d-none').removeClass('d-block');
      document.querySelector('#form_view_more_option').classList.add('d-none');
      document.querySelector('#form_view_more_option').classList.remove('d-block');
      document.querySelector('#form_view_how_often').classList.add('d-block');
      document.querySelector('#form_view_how_often').classList.remove('d-none');
      
    }else{ 
      if(!this.often_taken){
        $('.timesOftenBtn2').addClass('error');
      }else{
        $('.timesOftenBtn2').removeClass('error');
      }
      $('#oftenTakenWarningID2').addClass('d-block').removeClass('d-none');
    }   
  }

  onClickHowOften_Back_New(){
    this.oftenSelection = false
    document.querySelector('#form_view_how_often').classList.add('d-none');
    document.querySelector('#form_view_how_often').classList.remove('d-block');    
    document.querySelector('#form_view_default').classList.add('d-block');
    document.querySelector('#form_view_default').classList.remove('d-none');
  }

  checkSupplyValue(){
    if (this.drugSupply) {
        if(this.isAllDrug_30_day_supply){
          localStorage.setItem('rx_30_day_supply', 'yes');
        }
        this.onSubmit();
    }else{
        $('.btn-often').addClass('error');
        $('#drugSupplyWarningID').addClass('d-block').removeClass('d-none');
    }
  }

  resetAllBtnStateAndValue(){
    this.drugForm.reset();
    $('.timesOftenBtn').removeClass('active');
    $('.timesOftenBtn2').removeClass('active');
    $('.timesOftenBtn2').removeClass('error');    
    $('.timesOftenBtn').removeClass('error');
    $('.btn-often').removeClass('error'); 
    $('.refillBtn').removeClass('active');  
    $('.refillBtn').removeClass('error');  
  
    $('#oftenTakenWarningID2').addClass('d-none').removeClass('d-block');
    $('#oftenTakenWarningID').addClass('d-none').removeClass('d-block');
    $('#drugSupplyWarningID').addClass('d-none').removeClass('d-block');
    $('#drugSupplyWarningID2').addClass('d-none').removeClass('d-block');
    $('#refillSelectionWarning').addClass('d-none').removeClass('d-block');
  }

  selectedTimesTaken(){
    if(this.often_taken){
      $('#oftenTakenWarningID2').addClass('d-none').removeClass('d-block');
    }    
  }

  onClick_RefillDone(){
    document.querySelector('#form_view_more_option').classList.add('d-block');
    document.querySelector('#form_view_more_option').classList.remove('d-none');
    document.querySelector('#form_refill_option').classList.add('d-none');
    document.querySelector('#form_refill_option').classList.remove('d-block');
  }
}
