import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-application-medigap-version1-screen4',
  templateUrl: './screen4.component.html',
  styleUrls: ['./screen4.component.scss']
})
export class Screen4Component implements OnInit {

  dateFieldType: string;
  dateFieldHeading: string;
  medicareCardName = ''
  medicareCardNumber = {
    firstSet: '',
    secondSet: '',
    thirdSet: ''
  }

  effectiveDatePartA = ""
  effectiveDatePartB = ""

  isShowMedCardPartA = true;
  isShowMedCardPartB = true;

  hasNoPartA = false
  hasNoPartB = false

  constructor() { }

  ngOnInit() {
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

  onReceiveDate(dob: any){
    const idate = new Date(dob.date);
    const fdate = this.formatDate(idate);

    if(dob.type == 'partAEffectiveDate'){
      this.effectiveDatePartA = fdate;
    }

    if(dob.type == 'partBEffectiveDate'){
      this.effectiveDatePartB = fdate;
    }

    Swal.close();
  }

  formatDate(date) {
    return (this.padTo2Digits(date.getMonth()+1)) + '-' + this.padTo2Digits(date.getDate()) + '-' + date.getFullYear();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  onCheckedDontHavePartA(event){
    if(event.target.checked){
      this.isShowMedCardPartA = true;
    }else{
      this.isShowMedCardPartA = false;
    }

    this.hasNoPartA = this.isShowMedCardPartA;
  }
  onCheckedDontHavePartB(event){
    if(event.target.checked){
      this.isShowMedCardPartB = true;
    }else{
      this.isShowMedCardPartB = false;
    }
    this.hasNoPartB = this.isShowMedCardPartB;
  }

}
