<div class="container-fluid header-banner-wrapper home-banner-wrapper force-height-auto">
	<app-header-b></app-header-b>
	
	<div class="container home-banner banner-wrapper unset-absolute-position">
		<div class="d-flex">
			<div class="home-banner-left">
				<div class="home-banner-text-wrapper">
					<h2 class="banner-main-title-subtext text-center text-sm-left">A Clear & Organized</h2>
					<h1 class="banner-main-title">
						<!-- <span>Give Yourself A</span><br>  -->
						<!-- A Clear and Organized <br> -->
						Medicare Experience
					</h1>
					<mat-list class="mt-4 mat-list-home-header">
						<mat-list-item>
							<mat-icon mat-list-icon color="primary">check</mat-icon>
							<p>Beautiful Consumer-Friendly Ways To Search Plans</p>
							<!-- <p>Every Medicare Plan & Carrier</p> -->
							<!-- <p>Access To All Medicare Plans & Carriers</p> -->
						</mat-list-item>
						<mat-list-item>
							<mat-icon mat-list-icon color="primary">check</mat-icon>
							<p>Clear Answers & Explanations</p>
							<!-- <p>Remarkably Simple Guidance & Explanations</p> -->
							<!-- <p>Easy-to-Understand Answers & Explanations</p> -->
						</mat-list-item>
						<mat-list-item>
							<mat-icon mat-list-icon color="primary">check</mat-icon>
							<p>Organization & Support All Year</p>
							<!-- <p>Be Supported, Be Organized - Throughout The Year</p> -->
							<!-- <p>Guidance & Support Throughout The Year</p> -->
						</mat-list-item>
						<!-- <mat-list-item>
							<mat-icon mat-list-icon color="primary">check</mat-icon>
							<p>Personal Cost Improvement Assistance</p>
						</mat-list-item> -->
					</mat-list>
					<!-- <ul>
						<li><i class="fa fa-check mr-2"></i>Simple and Clear Access to All Plans </li>
						<li><i class="fa fa-check mr-2"></i>Proactive Support </li>
						<li><i class="fa fa-check mr-2"></i>Automatic Discount Checks and Price Reductions </li>
					</ul> -->
					<div class="home-banner-btn d-flex justify-content-around justify-content-md-start flex-column flex-md-row align-items-center">
						<!-- <a href="javascript:void(0)" class="btn btn-third" (click)="watchMore(videoContent)"><i class="fa fa-play mr-3"></i>Watch Video</a> -->
						<a routerLink="/benny-dash" class="btn btn-third text-uppercase">Go To My Benny HQ</a>

						<button class="btn btn-first ml-2" routerLink="/insurance-marketplace">View Plans</button>
						<!-- <button class="btn btn-first ml-2" routerLink="/registration">Join Now </button> -->
					</div>
					<!-- <div class="banner-summary-wrapper mt-5">
						<div class="banner-summary-left d-flex align-items-center">
							<strong class="font-primary">3k <sup>+</sup></strong>
							<span>Number of Beneficiaries Helped</span>
						</div>
						<mat-divider [vertical]="true"></mat-divider>
						<div class="banner-summary-right d-flex align-items-center">
							<strong class="font-primary">92%</strong>
							<span>
								Improve Their Overall Medicare Experience
							</span>
						</div>
					</div>
					<div class="banner-budges">
						 TrustBox widget - Review Collector 
						<div class="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="5e70ef64fb50ff00015c7bbe" data-style-height="52px" data-style-width="100%">
							<a href="https://www.trustpilot.com/review/taptapmedicare.com" target="_blank" rel="noopener">Trustpilot</a>
						</div>
						 End TrustBox widget
						<img src="assets/img/home/american-association.jpg" alt="American Association Badge" class="american-association-badge">
					</div> -->
					
	
				</div>
			</div>
			<div class="home-banner-right banner-image-wrapper">
				<!-- <img src="assets/img/home/new/blonde-old-woman-holding-a-phone.png"> -->
				<img src="assets/img/common/old-woman-sitting-yoga-no-bg.png">
			</div> 
		</div>
	</div>
</div>

<app-insurance-logo></app-insurance-logo>
<mat-divider class="insuranceLogo-home-content-divider"></mat-divider>
<section class="container-fluid main-content-wrapper home-wrapper">
		<div class="row home-content">
			<div class="col-12 row home-content-first-box-wrapper">
				<div class="col-md-12 home-content-row-div">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-12 col-md-6 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
								<img src="assets/img/home/new/home-medicare-solves-medicare-problems.png">
							</div>
							<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5">
								<div class="home-text-box">
									<h2>TapTap Medicare Solves Medicare Problems</h2>
									<mat-list>
										<mat-list-item>
											<mat-icon mat-list-icon color="primary">check</mat-icon>
											<p>Easily Compare Plans and Prices</p>
										</mat-list-item>
										<mat-list-item>
											<mat-icon mat-list-icon color="primary">check</mat-icon>
											<p>Identify the Plan Benefits You Want Most</p>
										</mat-list-item>
										<mat-list-item>
											<mat-icon mat-list-icon color="primary">check</mat-icon>
											<p>Set Up Automatic Savings and Discount Checks</p>
										</mat-list-item>
									</mat-list>
									<button class="btn btn-first" routerLink="/insurance-marketplace">View All Medicare Plans <!--<i class="fas fa-angle-double-right ml-2"></i>--></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 home-content-row-div">
					<div class="container">
						<div class="row flex-column-reverse flex-md-row align-items-center">
							<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start text-center text-md-left pr-md-5">
								<div class="home-text-box">
									<h2>Learn and Understand Enjoyably</h2>
									<mat-list>
										<mat-list-item>
											<mat-icon mat-list-icon color="primary">check</mat-icon>
											<p>Watch Videos</p>
										</mat-list-item>
										<mat-list-item>
											<mat-icon mat-list-icon color="primary">check</mat-icon>
											<p>Access Our Courses, Guides, and Articles</p>
										</mat-list-item>
										<mat-list-item>
											<mat-icon mat-list-icon color="primary">check</mat-icon>
											<p>View The Comments, Tips, and Suggestions from Fellow Consumers</p>
										</mat-list-item>
									</mat-list>
									<!-- <ul>
										<li><i class="fa fa-check mr-2"></i>Watch Videos</li>
										<li><i class="fa fa-check mr-2"></i>Access Our E-Cources, Articles and Infographics</li>
										<li><i class="fa fa-check mr-2"></i>FInd Help with Our Reliable Guidebook</li>
									</ul> -->
									<button class="btn btn-first" routerLink="/education">Visit the Education Center <!--<i class="fas fa-angle-double-right ml-2"></i>--></button>
								</div>
							</div>
							<div class="col-12 col-md-6 img-tile-box d-flex justify-content-center align-items-center justify-content-md-end mb-4 mb-md-0">
								<img src="assets/img/home/new/home-learn-and-understand-enjoyably.png">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 row home-content-second-box-wrapper">
					<div class="col-md-12 home-content-row-div">
							<div class="container">
								<div class="row align-items-center">
									<div class="col-12 col-md-6 img-tile-box d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
										<img src="assets/img/home/new/home-be-organized.png">
									</div>
									<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end text-center text-md-left pl-md-5">
										<div class="home-text-box">
											<h2>Be Organized</h2>
											<mat-list>
												<mat-list-item>
													<mat-icon mat-list-icon color="primary">check</mat-icon>
													<p>All of your insurances organized and illustrated for you</p>
												</mat-list-item>
												<mat-list-item>
													<mat-icon mat-list-icon color="primary">check</mat-icon>
													<p>Your important coverage information saved, secured, and accessible at all times</p>
												</mat-list-item>
											</mat-list>
											<button class="btn btn-first" routerLink="/medicare-services">View All of Our Services <!--<i class="fas fa-angle-double-right ml-2"></i>--></button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12 home-content-row-div home-content-row-4">
							<div class="container">
								<div class="row flex-column-reverse flex-md-row align-items-center">
									<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start text-center text-md-left pr-md-3">
										<div class="home-text-box">
											<h2>Connect and Contribute</h2>
											<mat-list>
												<mat-list-item>
													<mat-icon mat-list-icon color="primary">check</mat-icon>
													<p>Learn from Fellow Medicare Beneficiaries and Their Experiences</p>
												</mat-list-item>
												<mat-list-item>
													<mat-icon mat-list-icon color="primary">check</mat-icon>
													<p>Ask the Community About Specific Questions or General Advice</p>
												</mat-list-item>
												<mat-list-item>
													<mat-icon mat-list-icon color="primary">check</mat-icon>
													<p>Use Your Experience to Help Others with Their Questions and Earn Rewards</p>
												</mat-list-item>
											</mat-list>
											<button class="btn btn-first" routerLink="/community">See Your Communities<!--<i class="fas fa-angle-double-right ml-2"></i>--></button>
										</div>
									</div>
									<div class="col-12 col-md-6 img-tile-box d-flex align-items-center justify-content-center justify-content-md-end mb-4 mb-md-0">
										<img src="assets/img/home/new/home-connect-and-contribute.png">
									</div>
								</div>
							</div>
						</div>
			</div>
			
		</div>
</section>

<div class="d-none">
	<div #videoContent>
		<!-- <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_9rgfxy7pqm videoFoam=true" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/9rgfxy7pqm/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div> -->
		<video width="100%" height="auto" controls>
			<source src="./assets/videos/Taptap Medicare About Us Full.mp4" type="video/mp4">
			Your browser does not support the video tag.
		</video>
	</div>
</div>
<!-- <span class="wistia_embed wistia_async_9rgfxy7pqm popover=true popoverContent=link" style="display:inline;position:relative"><a href="#">This is my popover!</a></span> -->
<app-new-to-medicare></app-new-to-medicare>
<app-testimonials></app-testimonials>
<div class="footer-top-spacer mt-5"></div>
<app-ready-to-talk></app-ready-to-talk>
