import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private _authService: AuthService, private localStorage: StorageService) {
      this._authService.logUserOut();

      // clear provider and facility cache
      this.localStorage.remove('mapdSelectedProviders');
      this.localStorage.remove('mapdSelectedFacility');
  }

  ngOnInit() {
  }

}
