import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-lottie-player',
  templateUrl: './custom-lottie-player.component.html',
  styleUrls: ['./custom-lottie-player.component.scss']
})
export class CustomLottiePlayerComponent implements OnInit {

  @Input() lottieUrl: string;

  constructor() { }

  ngOnInit() {
    console.log(this.lottieUrl);
    
  }

}
