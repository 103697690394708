<div class="status-box p-3">
  <h4 class="status-header mb-1">Provider Status</h4>
  <p class="status-desc">These indicators describe the plan's status above:</p>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center mr-3">
      <i class="fas fa-check-circle mr-2"></i>
      <h6 class="green">In-Network</h6>
    </div>
    <div class="d-flex align-items-center">
      <i class="fas fa-times-circle mr-2"></i>
      <h6 class="red">Out-of-Network</h6>
    </div>
  </div>
</div>
<div *ngIf="hasCarousel" id="carouselTableSlider" class="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <table class="table table-striped table-borderless status-table">
        <thead>
          <tr>
            <td>
              <h5 class="text-center">Providers</h5>
            </td>
            <td class="plan-td bg-lblue">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <!-- <td class="plan-td">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <td class="plan-td bg-lblue">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <td class="plan-td">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <!-- <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td> -->
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <!-- <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td> -->
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <!-- <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td> -->
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <!-- <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td> -->
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <!-- <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="carousel-item">
      <table class="table table-striped table-borderless status-table">
        <thead>
          <tr>
            <td>
              <h5 class="text-center">Providers</h5>
            </td>
            <td class="plan-td bg-lblue">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <!-- <td class="plan-td">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <td class="plan-td bg-lblue">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <td class="plan-td">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <!-- <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td> -->
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="carousel-item">
      <table class="table table-striped table-borderless status-table">
        <thead>
          <tr>
            <td>
              <h5 class="text-center">Providers</h5>
            </td>
            <td class="plan-td bg-lblue">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <td class="plan-td">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <td class="plan-td bg-lblue">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
            <td class="plan-td">
              <img class="plan-logo" src="./assets/img/home/company-logo-display/cigna-healthspring.png" alt="">
              <h5 class="text-center">Cigna</h5>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
          <tr>
            <td class="provider-col">
              <img class="mr-2" src="./assets/img/beneficiary/my-providers/docvatar.svg" alt="">
              <div>
                <p class="name mb-0">JOHN M PETRILLO D.O.</p>
                <p class="address mb-0">624 MCCLELLAN STREET, SCHENECTADY</p>
              </div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-times-circle"></i></div>
            </td>
            <td class="status-col bg-lblue">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
            <td class="status-col">
              <div><i class="fas fa-check-circle"></i></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center px-3 pb-3">
    <button class="btn btn-first btn-small" type="button" data-target="#carouselTableSlider" data-slide="prev">Back</button>
    <div class="paginate d-flex">
      <ol class="carousel-indicators carousel-indicators-numbers">
        <li data-target="#carouselTableSlider" data-slide-to="0" class="active">1</li>
        <li data-target="#carouselTableSlider" data-slide-to="1">2</li>
        <li data-target="#carouselTableSlider" data-slide-to="2">3</li>
      </ol>
    </div>
    <button class="btn btn-first btn-small" type="button" data-target="#carouselTableSlider" data-slide="next">Next</button>
  </div>
</div>