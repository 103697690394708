<ng-container *ngIf="rating > 0">
  <div class="star-rating size-{{size}}">
    <div class="star-rating-icons mr-2">
      <i class="fa {{rating > 0 && rating < 1  ? 'fa-star-half-o' : rating >= 1 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa {{rating > 1 && rating < 2  ? 'fa-star-half-o' : rating >= 2 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa {{rating > 2 && rating < 3  ? 'fa-star-half-o' : rating >= 3 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa {{rating > 3 && rating < 4  ? 'fa-star-half-o' : rating >= 4 ? 'fa-star' : 'fa-star-o'}}"></i>
      <i class="fa {{rating > 4 && rating < 5  ? 'fa-star-half-o' : rating >= 5 ? 'fa-star' : 'fa-star-o'}}"></i>
    </div>
    <span class="star-rating-count">{{ rating }}</span>
  </div>
</ng-container>

<ng-container *ngIf="!rating">  
  <div class="star-rating">
    <span class="star-rating-count">Plan is New</span>
  </div>
</ng-container>