import { Injectable, NgZone } from '@angular/core';
 import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/database'; 

@Injectable()
export class MetadataService {
  getMetaData(route, callback) {
    let ref = firebase.database().ref('/pages');
    let query = ref.orderByChild('route').equalTo(route);
    query.once('value').then((snapshot) => {
      let arr = [];
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        arr.push({
          key: childKey,
          data: childData
        });
      });

      callback(arr[0]);
    });
  }
}
