import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-application-medigap-version1-screen12',
  templateUrl: './screen12.component.html',
  styleUrls: ['./screen12.component.scss']
})
export class Screen12Component implements OnInit {

  dateFieldType: string;
  dateFieldHeading: string;

  preNoticeAgreedDate = null

  constructor() { }

  ngOnInit() {
  }

  onClickDob(calendar, type, heading){
    this.dateFieldType = type;
    this.dateFieldHeading = heading;
    Swal.fire({
        html: calendar,
        showCloseButton: true,
        showConfirmButton: false,
        buttonsStyling: false,
        //allowOutsideClick: false,
        width: 300,
        padding:'20px',
    })
  }

  onReceiveDate(dob: any){
    const idate = new Date(dob.date);
    const fdate = this.formatDate(idate);

    this.preNoticeAgreedDate = dob.date;

    Swal.close();
  }

  formatDate(date) {
    return (this.padTo2Digits(date.getMonth()+1)) + '-' + this.padTo2Digits(date.getDate()) + '-' + date.getFullYear();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

}
