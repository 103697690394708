import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2'; 
@Component({
  selector: 'app-medigap-version3',
  templateUrl: './version3.component.html',
  styleUrls: ['./version3.component.scss']
})
export class Version3Component implements OnInit {

  @Input() results: any;
  @Input() base_img_loc: any;
  @Input() isMassachusetts;
  @Input() planLetter;
  @Input() controls: any;
  @Input() logComparedKeys: Array<Object>;
  @Input() logCartKeys: Array<Object> = [];
  @Input() part_d_id;
  @Input() isBlueBgHeading = true;
  @Input() containerBgWhite = false;

  @Output() onClickCompanyProfile = new EventEmitter();
  @Output() onClickConsumerReviews = new EventEmitter();
  @Output() onClickMoreInsights = new EventEmitter();
  @Output() onClickApplyOnline = new EventEmitter();
  @Output() onClickApplyOnThePhone = new EventEmitter();

  @Output() onClickAddToCompare = new EventEmitter();
  @Output() emit_CostSelection = new EventEmitter();
  
  costRate:any;
  costRateType:any;
  costRateIndex:any;
  param_household = 0;
  param_plan: any = 'All';
  costToggleState: boolean = false;
  selectedPricingRate: string = 'month';
  allowedZipCodeToHaveDiscounts = ['73504', '31119', '79987', '65641', '67059', '71412', '61999'];
  addToCartLabel:string = 'Add to Cart';
  selectedMapId:any;
  selectedPricingId:any;
  currentUrlPathName:any;
  selected_cost:any;

  selectedCostLabel:any;
  selectedCostPrice:any;
  result_cost_label_array:any = [];
  result_cost_price_array:any = [];

  constructor(private route: ActivatedRoute) { 
    this.route.params.subscribe(params => {
      this.param_household = params["household"].replace("household-", "");
      this.param_plan = params["plan"].replace("plan-", "");
    });
    //this.getExistingCartPlans();
  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    console.log('rrrrrrrrrrrrrrrr', changes);
    let cost_name;
    let cost_price;
    changes['results'].currentValue.forEach(element => {
      element.payment_method_available_and_pricing.forEach(element2 => {
        if(element2.multiplier_name == 'monthly_eft_auto_draft_from_bank_account'){
          cost_name = element2.multiplier_display_name;
          cost_price = element2.calculated_rate;          ;
        }else if(element2.multiplier_name == 'monthly_direct_bill'){
          cost_name = element2.multiplier_display_name;
          cost_price = element2.calculated_rate;
        }else if(element2.multiplier_name == 'monthly_credit_card'){
          cost_name = element2.multiplier_display_name;
          cost_price = element2.calculated_rate;
        }else if(element2.multiplier_name == 'annual_direct_bill'){
          cost_name = element2.multiplier_display_name;
          cost_price = element2.calculated_rate;
        }else if(element2.multiplier_name.includes('monthly')){
          cost_name = element2.multiplier_display_name;
          cost_price = element2.calculated_rate;
        }
      });

      this.result_cost_label_array.push({'cost_name': cost_name, 'cost_price':cost_price});
    });

    console.log('wwwwwwwwwwwwwwwwwww', this.result_cost_label_array);


    // if(changes.logCartKeys){
    //   this.logCartKeys = changes.logCartKeys.currentValue;
    // }
  }
    
  on_costSelectorDropdown(rate, type, index){   
    this.costRate = rate.toFixed(2);
    this.costRateType = type;
    this.costRateIndex = index;
    let crt;
    //$('.costSelectorDropdown').toggleClass('active');
    
    $('#costSelectorDropdown_'+ index).toggleClass('active');
    $('#cost_rate_default_id_'+ index).removeClass('d-block').addClass('d-none');
    $('#cost_rate_selected_id_'+ index).html('$' + this.costRate).removeClass('d-none').addClass('d-block');

    $('#cost_label_default_id_'+ index).removeClass('d-block').addClass('d-none');

    if(this.costRateType.includes('Semi Annual')){
      crt = 'Semi Annual';
    }else if(this.costRateType.includes('Quarterly')){
      crt = 'Quarterly'; 
    }else if(this.costRateType.includes('Annual ')){
      crt = 'Annual'; 
    }else if(this.costRateType.includes('Monthly')){
      crt = 'Monthly';
    }else{
      crt = this.costRateType;
    }

    // let costLabelMarkup = `<span class="cost-type-label-default">` + crt + `</span>
    //                        <span class="cost-type-label-hovered">` + this.costRateType + `</span>`;

    let costLabelMarkup = this.costRateType;
    $('#cost_label_selected_id_'+ index).html(costLabelMarkup).removeClass('d-none').addClass('d-block');
    
    
    // this.selectedCostSelectorIndex = index;
    //this.selectedPricingRate = costType;
    this.costToggleState = false;
    // this.onChangeCostSelector.emit(costType);
  }

  onClickCostToggleState(index){
    $('#costSelectorDropdown_'+ index).toggleClass('active');
  }

  getConvertedRate2(rate, discountRate, naic) {

    rate = parseFloat((rate * .01).toFixed(2));

    if (this.param_household == 2) { //apply only selected naic;
        if (this.allowedZipCodeToHaveDiscounts.includes(naic)) {
            if (discountRate !== 0 || discountRate !== null) {
                rate = rate - (rate * discountRate);
            }
        }
    } else if (this.param_household == 1) { //;
        if (discountRate !== 0 || discountRate !== null) {
            rate = rate - (rate * discountRate);
        }
    }

    return rate;
  }

  companyProfile(result){
    result.plan_identifier = "medigap"
    this.onClickCompanyProfile.emit(result);
  }

  consumerReview(result){
    this.onClickConsumerReviews.emit(result);
  }

  additionalInsights(result){
    this.onClickMoreInsights.emit(result);
  }


  applyOnThePhone(result, img){
    this.onClickApplyOnThePhone.emit({result, img});
  }

  onclick_planDocsResources(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.toggle('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.remove('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.toggle('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.toggle('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.remove('d-md-flex');

  }

  onclick_doctorDrugAnalysis(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.toggle('d-flex');
    pab.classList.remove('d-flex');
    
    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.toggle('button-toggled');
    buttonColor3.classList.remove('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.toggle('d-md-flex');
    arrow3.classList.remove('d-md-flex');
    
  }
  

  onClick_DiscussThisPlanWithOthers(modalContent, result){
    console.log('papamama', result);
    this.selectedMapId = result.id;
    this.selectedPricingId = result.pricing.plan_pricing_id;
    Swal.fire({
      html: modalContent,
      showConfirmButton: false,
      showCloseButton: true,
      width: '500px'
    })
  }

  addToCompareFunc(result,event,index){
    if(this.costRate){ // get selected pricing cost
      this.selected_cost = { 'rate': this.costRate, 'type': this.costRateType };
    }else{ // get the default pricing cost if no cost selection from dropdown
      if(result.payment_method_available_and_pricing){
        for (let i = 0; i < result.payment_method_available_and_pricing.length; i++) {

          if(result.payment_method_available_and_pricing[i].multiplier_display_name.includes("Monthly EFT Auto Draft From Bank Account")){
            this.selected_cost = { 'rate': result.payment_method_available_and_pricing[i].calculated_rate, 'type': result.payment_method_available_and_pricing[i].multiplier_display_name };
            break;
          }else if(result.payment_method_available_and_pricing[i].multiplier_display_name.includes("Monthly")){
            this.selected_cost = { 'rate': result.payment_method_available_and_pricing[i].calculated_rate, 'type': result.payment_method_available_and_pricing[i].multiplier_display_name };
            break;
          }else{
            this.selected_cost = { 'rate': '$0.00', 'type': 'Monthly' };
          }
        }
      }
    }

    this.emit_CostSelection.emit(this.selected_cost);
        
    let vcb = document.querySelector('#view_compare_btn_'+index);
    vcb.classList.toggle('d-block');
    this.onClickAddToCompare.emit({result,event,index});
  }

  onClick_AddToCart(result, event){

    const addToCartBtn = document.querySelector('#ci_cart_btn__'+result.id);
    const viewCartBtn = document.querySelector('#view_cart_btn_'+result.id);
    
    if ( event.target.checked ) {

      if(this.costRate){ // get selected pricing cost
        this.selected_cost = { 'rate': this.costRate, 'type': this.costRateType };
      }else{ // get the default pricing cost if no cost selection from dropdown
        if(result.payment_method_available_and_pricing){
          for (let i = 0; i < result.payment_method_available_and_pricing.length; i++) {
  
            if(result.payment_method_available_and_pricing[i].multiplier_display_name.includes("Monthly EFT Auto Draft From Bank Account")){
              this.selected_cost = { 'rate': result.payment_method_available_and_pricing[i].calculated_rate, 'type': result.payment_method_available_and_pricing[i].multiplier_display_name };
              break;
            }else if(result.payment_method_available_and_pricing[i].multiplier_display_name.includes("Monthly")){
              this.selected_cost = { 'rate': result.payment_method_available_and_pricing[i].calculated_rate, 'type': result.payment_method_available_and_pricing[i].multiplier_display_name };
              break;
            }else{
              this.selected_cost = { 'rate': '$0.00', 'type': 'Monthlysssss' };
            }
          }
        }
      }

      this.addToCartLabel = 'Added to Cart';
      addToCartBtn.classList.add('active');

      var existingEntries = JSON.parse(localStorage.getItem("add_to_cart_medigap"));            

      if(existingEntries == null) {
          existingEntries = [];
      }
      result['selected_pricing']= this.selected_cost; //this.selected_pricing;
      result['selected_plan_input']= this.param_plan;
      existingEntries.push(result);
      localStorage.setItem("add_to_cart_medigap", JSON.stringify(existingEntries));

      viewCartBtn.classList.add("d-block");
      viewCartBtn.classList.remove("d-none");

    }else{
      this.addToCartLabel = 'Add to Cart';
      addToCartBtn.classList.remove('active');
      this.removeToCart(result);
      viewCartBtn.classList.remove("d-block");
      viewCartBtn.classList.add("d-none");
    }
  }

  removeToCart(result){

    const cartItem =  JSON.parse(window.localStorage.getItem('add_to_cart_medigap'));

    if( cartItem ) {
         for (let x = 0; x < cartItem.length; x++) {
             if (result['id'] !== 'undefined' && cartItem[x]['id'] !== 'undefined') {
                 if (result['id'] === cartItem[x]['id']) {
                  cartItem.splice(x, 1);
                 }
             }
         }
     }

    localStorage.setItem("add_to_cart_medigap", JSON.stringify(cartItem));

  }

  getExistingCartPlans(){
    let addToCartItem = localStorage.getItem("add_to_cart_medigap");

    if(addToCartItem){
        let existingCartEntries = JSON.parse(addToCartItem);

        if(existingCartEntries){
            existingCartEntries.forEach( (element) => {              
                this.logCartKeys.push(element.id);
            });
        }
    }
  }

  onclick_planActionButtons(key){
    let dri = document.querySelector('#docs_resources_id_'+key);
    let ddi = document.querySelector('#doctors_drugs_id_'+key);
    let pab = document.querySelector('#plan_actions_id_'+key);
    dri.classList.remove('d-flex');
    ddi.classList.remove('d-flex');
    pab.classList.toggle('d-flex');

    let buttonColor1 = document.getElementById('#button1_'+key);
    let buttonColor2 = document.getElementById('#button2_'+key);
    let buttonColor3 = document.getElementById('#button3_'+key);
    buttonColor1.classList.remove('button-toggled');
    buttonColor2.classList.remove('button-toggled');
    buttonColor3.classList.toggle('button-toggled');

    let arrow1 = document.querySelector('#arrow1_'+key);
    let arrow2 = document.querySelector('#arrow2_'+key);
    let arrow3 = document.querySelector('#arrow3_'+key);
    arrow1.classList.remove('d-md-flex');
    arrow2.classList.remove('d-md-flex');
    arrow3.classList.toggle('d-md-flex');
  }

  applyOnline(result, index, img, cost){
    this.onClickApplyOnline.emit({result, index, img, cost});
  }

  saveThisPlan(){    
    this.currentUrlPathName = window.location.pathname;
    console.log(this.currentUrlPathName);    
  }
}
