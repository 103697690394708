<h4 class="title">Primary Care Provider Selection (Optional)</h4>
<h5 class="fWeight-700 fColor-grey">Your Response To This Question Is Optional</h5>
<h4 class="fColor-black fWeight-600 mt-5">Insured Applicant:</h4>
<div class="row">
  <div class="col-12 mb-4">
    <p>Would You Like To Specify Your Preferred Primary Care Doctor With The Plan?</p>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" id="isSpecifyPreferredPrimaryCareDoctor_1" name="isSpecifyPreferredPrimaryCareDoctor" class="custom-control-input" value="yes" [(ngModel)]="isSpecifyPreferredPrimaryCareDoctor" (change)="onChange_isSpecifyPreferredPrimaryCareDoctor($event.target.value)">
      <label class="custom-control-label" for="isSpecifyPreferredPrimaryCareDoctor_1">Yes</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" id="isSpecifyPreferredPrimaryCareDoctor_2" name="isSpecifyPreferredPrimaryCareDoctor" class="custom-control-input" value="no" [(ngModel)]="isSpecifyPreferredPrimaryCareDoctor" (change)="onChange_isSpecifyPreferredPrimaryCareDoctor($event.target.value)" checked="true">
      <label class="custom-control-label" for="isSpecifyPreferredPrimaryCareDoctor_2">No</label>
    </div>
  </div>
  <div class="col-12 mb-4">
    <div class="row">
      <div class="form-group col-md-6">
        <label id="nameOfYourPrimaryDoctor">What Is The Name of Your Primary Care Doctor?</label>
        <input for="nameOfYourPrimaryDoctor" type="text" class="form-control blurred"/>
      </div>
    </div>
  </div>
  <div class="col-12 pl-5">
    <p class="fWeight-600 fColor-black mb-0">Are You a Current Patient?</p>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" id="areYourACurrentPatient_1" name="areYourACurrentPatient" class="custom-control-input" value="yes" [(ngModel)]="areYourACurrentPatient" (change)="onChange_areYourACurrentPatient($event.target.value)">
      <label class="custom-control-label" for="areYourACurrentPatient_1">Yes</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" id="areYourACurrentPatient_2" name="areYourACurrentPatient" class="custom-control-input" value="no" [(ngModel)]="areYourACurrentPatient" (change)="onChange_areYourACurrentPatient($event.target.value)" checked="true">
      <label class="custom-control-label" for="areYourACurrentPatient_2">No</label>
    </div>
  </div>
</div>