import { Component, OnInit } from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {DrugsService} from '../../../services/drugs.service';

@Component({
  selector: 'app-common-step1-v2',
  templateUrl: './common-step1.component.html',
  styleUrls: ['./common-step1.component.scss']
})
export class CommonStep1Component implements OnInit {

  viewDefault:boolean = true;
  isLoading: boolean = false;
  isEditDrug:boolean = false;
  results: any[] = [];
  view_drug_list:boolean = false;

  step1Form = new FormGroup({
    drugname: new FormControl('', [
      Validators.required,
    ]),
    fipscode: new FormControl('', [
      Validators.required,
    ]),
    allow_generics: new FormControl(true, [
      Validators.required,
    ]),
  });

  constructor(private drugService: DrugsService) { }

  ngOnInit() {
  }

  async onSubmit(event: any) {}

  fullnameValueChanged(event: any) {
    const text = event;

    if (text.length > 2) {
      this.getSuggestedList(text);
    }else{
      this.results = [];
    }
  }

  async getSuggestedList(keyword) {
    try {
      //this.isLoading = true;
      const results: any = await this.drugService.getAutoSuggest(keyword);

      if (results) {
        this.results = results;
      }
      //this.isLoading = false;
    } catch(e) {
      //this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
      //this.isLoading = false;
    }
  }

}
