import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-apply-online-modal',
  templateUrl: './apply-online-modal.component.html',
  styleUrls: ['./apply-online-modal.component.scss']
})

export class ApplyOnlineModalComponent implements OnInit {

  @Input() plan: any;
  @Input() logo: any;
  @Input() planPremium: any;
  @Input() planPremiumLabel: any;  
  @Input() oneTimeEnrollmentFee: any;
  @Input() householdDiscount: any;
  @Output() emit_ShowEnrollOnlineModal = new EventEmitter;
  
  @Input() controlPlan: any;
  @Input() controlAge: any;
  @Input() controlGender: any;
  @Input() controlZipcode: any;
  @Input() controlTobacco: any;
  @Input() controlHousehold: any;
  @Input() controlNaic: any;
  @Input() controlPremium: any;
  @Input() controlType: any;
  @Input() controlCarrierLogo: any;
  @Input() controlCarrierBrandName: any;
  @Input() controlPlanLetter: any;
  @Input() controlProductType: any;
  @Input() controlLinkToSinglePlanPage : any;
  @Input() controlMaxOutOfPocket : any;
  @Input() controlContractId: any;
  @Input() controlPlanDeductible: any;
  @Input() controlPlanId: any;
  @Input() controlAnnualMax: any;
  @Input() controlDeathBenefit: any;

  @Input() insuranceType: any;
  @Input() pdsCode: any;
  @Input() csgKey: any;
  @Input() subUrl: string = 'medigap';
  @Input() parentUrl: string = 'insurance-marketplace';
  @Input() overallStarRating: any;
  @Input() result: any;
  @Input() index: any;
  
  
  isShowDefaultView: boolean = true;
  isShowModalPage1:boolean = false;
  isShowModalPage2:boolean = false;
  isShowModalPage3:boolean = false;

  isShowImportantInformation1stView:boolean = true;
  isShowImportantInformation2ndView:boolean = false;

  firstName:string;
  middleName:string;
  lastName:string;

  isShowProceedToOnlineApplication:boolean = false;

  constructor() {
    
  }

  getLocalStorageFullName(){
    let localStorageFullName = JSON.parse(localStorage.getItem('online_application_mapd_full_name'));
    this.firstName = localStorageFullName.first_name;
    this.middleName = localStorageFullName.middle_name;
    this.lastName = localStorageFullName.last_name;
  }

  ngOnInit() {}

  showDefaultPage(){
    this.isShowDefaultView = true;
    this.isShowModalPage1 = false;
    this.isShowModalPage2 = false;
    this.isShowModalPage3 = false;
  }

  showFirstPage(){
    this.isShowDefaultView = false;
    this.isShowModalPage1 = true;
    this.isShowModalPage2 = false;
    this.isShowModalPage3 = false;
  }

  showSecondPage() {
    this.getLocalStorageFullName();
    this.isShowDefaultView = false;
    this.isShowModalPage1 = false;
    this.isShowModalPage2 = true;
    this.isShowModalPage3 = false;
  }

  onClick_ProceedToOnlineApplication(){
    Swal.close();
  }

  showEnrollOnlineModal(){
    const data = {
      result:this.result,
      index: this.index
    }
    this.emit_ShowEnrollOnlineModal.emit(data);
  }

  showImportantInformationLastPage(){
    this.showFirstPage();
    this.isShowImportantInformation1stView = false;
    this.isShowImportantInformation2ndView = true;
    this.isShowProceedToOnlineApplication = false;
  }

  onCheck_acknowledgementIAgree(event){
    if(event){
      this.isShowProceedToOnlineApplication = true;
    }else{
      this.isShowProceedToOnlineApplication = false;
    }
  }

}
