
  <!-- <div class="speakToCounselor-wrapper">
      <h5 class="heading">Speak With a Concierge</h5>
      <h5 class="mb-3 fColor-base" style="font-weight:500">Call Us Now</h5>
      <a href="tel:18002155108" class="btn btn-third mx-auto fluid btn-small" style="max-width:230px;letter-spacing: 1px;">1(800)215-5108 
       
      </a>
      <h5 class="mb-3 fColor-base" style="font-weight:500">Chat Through Our Website:</h5>
      <button (click)="onClickChat($event)" id="open-chat" class="btn btn-first mx-auto fluid btn-small"  style="max-width:230px;letter-spacing: 1px;">Click Here 
      
      </button>
      <h5 class="mb-3 fColor-base" style="font-weight:500">Schedule a Call for Later:</h5>
      <a href="https://brightprice.gigabook.com/book/all-team-members/loc#panel_step_choose" target="_blank" class="btn btn-first mx-auto fluid btn-small" style="max-width:230px;letter-spacing: 1px;">Click Here 
      
      </a>
  </div> -->

<ng-container *ngIf="scheduleStep == 1">
  <div class="counselor-wrapper">
    <div class="d-flex">
      <img class="counselor-asset ml-3" src="./assets/img/common/speak-counselor.png" alt="">
      <div class="p-4">
        <h5 class="modal-head text-center">Speak With a Concierge</h5>
        <p class="modal-desc text-center">Call Us Now at <span>1 (800) 215-5108</span></p>
        <button (click)="onClickChat($event)" class="btn btn-first btn-small w-100 d-flex chat-btn mb-2">
          <i class="fas fa-comment-dots mr-2"></i>
          <span class="flex-grow-1">Chat On Our Website</span>
        </button>
        <button (click)="scheduleStep = 2" class="btn btn-first btn-small w-100 d-flex call-btn">
          <i class="fas fa-phone mr-2"></i>
          <span class="flex-grow-1">Schedule A Call</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="scheduleStep == 2">
  <div class="p-1 p-md-5 max-w-700">
    <h5 class="text-center cat-head mb-3">Choose The Category Service To Start</h5>
    <div class="search-cat d-flex mx-auto">
      <input type="text" [(ngModel)]="searchCategoryInput" (input)="setCategoriesOnSearch()" placeholder="Search a category...">
      <i class="fas fa-search mx-1"></i>
    </div>

    <div class="accordion mt-4" id="accordionCategory">
      <div class="accordion-group">
        <ng-container *ngFor="let i = index; let category of displayedCategories">
          <div class="card cat-card mb-1">
            <button class="btn-category text-left collapsed" data-toggle="collapse" [attr.data-target]="'#category_'+i">
              <span>{{ category.name }}</span>
              <i class="fas fa-angle-down"></i>
            </button>
            <div [id]="'category_'+i" class="collapse collapseWrapper" data-parent="#accordionCategory">
              <div class="card-body p-2">
                <ng-container *ngFor="let subCategory of category.subCategories">
                  <button class="btn-subcat" (click)="setSelectTopic(subCategory)">
                    <i class="fas fa-arrow-alt-circle-right mr-2"></i>
                    <ng-container *ngIf="searchCategoryInput == null">
                      <span>{{ subCategory.name }}</span>
                    </ng-container>
                    <ng-container *ngIf="searchCategoryInput != null">
                      <span [innerHTML]="subCategory.name | highlight:searchCategoryInput "></span>
                    </ng-container>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <button *ngIf="selectedAppointmentIndex == null" (click)="(scheduleStep = 1) && (searchCategoryInput = null) && (displayedCategories = categories)" class="btn btn-third btn-small mx-auto mt-3">
      Back
    </button>
    <button *ngIf="selectedAppointmentIndex != null" (click)="backAndClear()" class="btn btn-third btn-small mx-auto mt-3">
      Back
    </button>
  </div>
</ng-container>

<ng-container *ngIf="scheduleStep == 3">
  <div class="d-flex p-4">
    <div class="topic-box p-1 pr-4">
      <h6 class="modal-head text-center mb-2">Selected topics</h6>
      <ng-container *ngFor="let topic of selectedTopics; let i = index">
        <div class="appointment-card card p-3 mb-2">
          <div>
            <i class="topic-icon fas fa-comments mb-2"></i><br>
            <p class="selected-cat">{{ topic.name }}</p>
            <p class="topic-info mb-0">{{ topic.desc }}</p>
          </div>
          <button *ngIf="selectedTopics.length > 1" (click)="setRemoveTopic(i)" class="delete-btn">
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
      </ng-container>
      <button *ngIf="selectedTopics.length < 3" (click)="scheduleStep = 2" class="btn btn-first btn-xs bg-blue w-100">
        <i class="fas fa-angle-left mr-2"></i>
        Add Another Appointment
      </button>
    </div>

    <div class="p-2">
      <h5 class="modal-head text-center mb-1">Select a Schedule</h5>
      <div class="d-flex">
        <div class="w-300px p-2">
          <div class="card">
            <mat-calendar [(selected)]="selected"></mat-calendar>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <i class="arrow-right-icon fas fa-arrow-right"></i>
          <div class="w-200px p-2" *ngIf="selected">
            <div class="card list-schedule-box-calendar">
              <p class="schedule-label">{{ getDayOfWeek() }} <span>{{ getMonthAndDay() }}</span></p>
              <div class="d-flex flex-column">
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '12:30 PM'" (click)="setSelectSchedule('12:30 PM')">12:30 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '1:00 PM'" (click)="setSelectSchedule('1:00 PM')">1:00 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '1:30 PM'" (click)="setSelectSchedule('1:30 PM')">1:30 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '2:00 PM'" (click)="setSelectSchedule('2:00 PM')">2:00 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '2:30 PM'" (click)="setSelectSchedule('2:30 PM')">2:30 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '3:00 PM'" (click)="setSelectSchedule('3:00 PM')">3:00 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '3:30 PM'" (click)="setSelectSchedule('3:30 PM')">3:30 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '4:00 PM'" (click)="setSelectSchedule('4:00 PM')">4:00 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '4:30 PM'" (click)="setSelectSchedule('4:30 PM')">4:30 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '5:00 PM'" (click)="setSelectSchedule('5:00 PM')">5:00 PM</button>
                <button class="btn btn-xs btn-first w-100 my-1" [class.highlight]="selectedAppointmentTime == '5:30 PM'" (click)="setSelectSchedule('5:30 PM')">5:30 PM</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="scheduleStep == 4">
  <div class="p-4">
    <h5 class="modal-head text-center mb-3">Your Preference Method</h5>
    <div class="row justify-content-center">
      <div class="col-12 col-md-4 p-2">
        <button class="btn-preference shadow-sm mx-auto" (click)="setSelectPreference('On The Phone')">
          <span>On The Phone</span>
          <img src="./assets/img/common/on-the-phone.png" alt="">
          <div class="icon">
            <i class="fas fa-phone"></i>
          </div>
        </button>
      </div>
      <div class="col-12 col-md-4 p-2">
        <button class="btn-preference shadow-sm mx-auto" (click)="setSelectPreference('Screen Share')">
          <span>Screen Share On Your Computer</span>
          <img src="./assets/img/common/screen-share.png" alt="">
          <div class="icon">
            <i class="fas fa-desktop"></i>
          </div>
        </button>
      </div>
      <div class="col-12 col-md-4 p-2">
        <button class="btn-preference shadow-sm mx-auto" (click)="setSelectPreference('Text Message')">
          <span>Text Message With Us</span>
          <img src="./assets/img/common/text-message.png" alt="">
          <div class="icon">
            <i class="fas fa-sms"></i>
          </div>
        </button>
      </div>
    </div>
    <button *ngIf="selectedAppointmentIndex == null" (click)="scheduleStep = 2" class="btn btn-third btn-xs mt-2 mx-auto">Back</button>
    <button *ngIf="selectedAppointmentIndex != null" (click)="backAndClear()" class="btn btn-third btn-small mx-auto mt-3">
      Back
    </button>
  </div>
</ng-container>

<ng-container *ngIf="scheduleStep == 5">
  <div class="counselor-wrapper max-w-600">
    <div class="d-flex">
      <!-- <img class="counselor-asset-2 ml-3 d-none d-lg-block" src="./assets/img/common/speak-counselor.png" alt=""> -->
      <div class="p-4">
        <h5 class="modal-head text-center">Enter Your Info To Finalize</h5>
        <p class="text-center modal-desc">We'll send an email confirmation upon completion.</p>
        <!-- <p class="modal-desc text-center">Call Us Now at <span>1 (800) 215-5108</span></p> -->
        <div>
          <div class="card confirmation-box w-100 p-3 mb-2">
            <ng-container *ngFor="let topic of selectedTopics; let i = index">
              <div class="d-flex align-items-center justify-content-between p-1">
                <div class="d-flex align-items-center">
                  <i class="fas fa-comments"></i>
                  <span>{{ topic.name }}</span>
                </div>
                <button *ngIf="selectedTopics.length > 1" class="btn btn-first remove-button btn-xs ml-2" (click)="setRemoveTopic(i)">Remove</button>
              </div>
            </ng-container>
          </div>

          <div class="card confirmation-box w-100 p-3 mb-2">
            <div class="d-flex align-items-center justify-content-between p-1">
              <div class="d-flex align-items-center">
                <i class="fas fa-calendar"></i>
                <span>{{ selectedTime + ' ' + selectedDay + ', ' + selectedMonthAndDay + ', Eastern Time' }}</span>
              </div>
              <button class="btn btn-first btn-xs ml-2" (click)="scheduleStep = 3">Change</button>
            </div>
            <div class="d-flex align-items-center justify-content-between p-1">
              <div class="d-flex align-items-center">
                <i *ngIf="selectedPreference == 'On The Phone'" class="fas fa-phone"></i>
                <i *ngIf="selectedPreference == 'Text Message'" class="fas fa-sms"></i>
                <i *ngIf="selectedPreference == 'Screen Share'" class="fas fa-desktop"></i>
                <span>{{ selectedPreference }}</span>
              </div>
              <button class="btn btn-first btn-xs ml-2" (click)="scheduleStep = 4">Change</button>
            </div>
          </div>

          <form class="finalize-form">
            <div class="row">
              <div class="col-12 col-md-6 p-2">
                <label for="fname">First Name</label>
                <input type="text" class="form-control" [(ngModel)]="fname" name="fname" required>
              </div>
              <div class="col-12 col-md-6 p-2">
                <label for="lname">Last Name</label>
                <input type="text" class="form-control" [(ngModel)]="lname" name="lname" required>
              </div>
              <div class="col-12 col-md-6 p-2">
                <label for="email">Email</label>
                <input type="text" class="form-control" [(ngModel)]="email" name="email" required>
              </div>
              <div class="col-12 col-md-6 p-2">
                <label for="phone">Phone</label>
                <input type="tel" class="form-control" [(ngModel)]="phone" autocomplete="tel" maxlength="14" (input)="telFormat($event, 'mobile_number')" name="phone" required>
              </div>
              <div class="col-12 p-2">
                <label for="message">Message</label>
                <textarea type="text" class="form-control" [(ngModel)]="message" name="message" rows="7" required></textarea>
              </div>
            </div>
            <div class="row justify-content-center">
              <div *ngIf="selectedTopics.length < 3" class="col-12 col-md-6 p-2">
                <button type="button" class="btn btn-first bg-blue btn-small w-100" (click)="scheduleStep = 2">
                  <i class="fas fa-angle-left mr-2"></i>
                  Add Another Appointment
                </button>
              </div>
              <div class="col-12 col-md-6 p-2">
                <button type="submit" (click)="setSubmitSchedule()" class="btn btn-first btn-small w-100">
                  Complete Booking
                  <i class="fas fa-angle-right ml-2"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="scheduleStep == 6">
  <div class="counselor-wrapper">
    <div class="d-flex">
      <img class="counselor-asset-3 ml-3 d-none d-lg-block" src="./assets/img/common/speak-counselor.png" alt="">
      <div class="p-4">
        <h4 class="modal-head text-center mb-1">Success!</h4>
        <p class="modal-desc text-center mb-1">Thank you from the TapTap and Brightprice Team!</p>
        <p class="modal-desc text-center">We've successfully scheduled your booking!</p>
        <ng-container *ngFor="let appointment of addedAppointment; let i = index">
          <div class="card confirmation-box w-100 p-3 mb-2">
            <div class="d-flex align-items-center justify-content-between p-1">
              <div class="d-flex align-items-center">
                <i class="fas fa-comments"></i>
                <span>{{ appointment.category }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between p-1">
              <div class="d-flex align-items-center">
                <i class="fas fa-calendar"></i>
                <span>{{ appointment.time + ' ' + appointment.day + ', ' + appointment.month + ', Eastern Time' }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between p-1">
              <div class="d-flex align-items-center">
                <i *ngIf="appointment.preference == 'On The Phone'" class="fas fa-phone"></i>
                <i *ngIf="appointment.preference == 'Text Message'" class="fas fa-sms"></i>
                <i *ngIf="appointment.preference == 'Screen Share'" class="fas fa-desktop"></i>
                <span>{{ appointment.preference }}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="or-separator px-3 my-4">
          <hr>
          <span>OR</span>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <button routerLink="/login" class="btn btn-first btn-small">
            Login
          </button>
          <span class="modal-desc mx-2">OR</span>
          <button routerLink="/registration" class="btn btn-first btn-small create-account-btn">
            Create an Account
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>




  <div class="d-none speakToCounselor-wrapper">  
    <ng-container *ngIf="!showCalendly">
      <img src="./assets/img/common/3-agent-standing.png" alt="speak to counselor agent">
      <h5 class="heading text-center">Speak With a Concierge</h5>  
      <h6 class="fColor-black mb-3 text-center">Call Us Now At 1(800) 215-5108</h6>      
      <!-- <h6 class="fColor-black mb-3 text-center">3 Options to Choose from:</h6> -->
      <!-- <a href="tel:18002155108" class="btn btn-first btn-small btn-speak-to-counselor fluid">Call Us Now</a> -->
      <button (click)="onClickChat($event)" id="open-chat" class="btn btn-first btn-small btn-speak-to-counselor fluid">Chat On Our Website</button>
      <!-- <a href="https://brightprice.gigabook.com/book/all-team-members/loc#panel_step_choose" target="_blank" class="btn btn-first btn-small btn-speak-to-counselor">Schedule A Call</a> -->
      <button (click)="showModal(gigaBookModal)" class="btn btn-first btn-small btn-speak-to-counselor fluid">Schedule A Call</button>
   </ng-container>
<!-- 
  <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
  <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
  <a href="" id="calendlyPopUp" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/taptap'});return false;"></a> -->
  </div>

  <div class="d-none">
    <div #gigaBookModal>
      <div id="gb_widget">
        <iframe id="gb_iframe" frameborder="0" sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation" 
          src="https://brightprice.gigabook.com/book/all" 
          style="width:100% !important; height:700px !important; border: 0px; margin: 0px;">
        </iframe>
      </div>
    </div>
  </div>