import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-view-all-ratings',
  templateUrl: './view-all-ratings.component.html',
  styleUrls: ['./view-all-ratings.component.scss']
})
export class ViewAllRatingsComponent implements OnInit {
  modalStep: any = 1;
  
    @Input() image:any;
    @Input() consumerRating:any;
    @Input() customerServiceRating:any;
    @Input() overallScore:any;
    @Input() consumerScore:any;
    @Input() overallRating:any;
    @Input() priceStabilityRating:any;
    @Input() providerScore:any = 2;
    @Input() fees: any[];
    @Input() discounts: any[];
    @Input() rateHistory: any[];
    @Input() silverSneakers: any;
    @Input() hasHeading:boolean = true;
    @Input() forResult: boolean = false;
    @Input() result;


    // for medigap v2
    @Input() marketplaceType = 'medigap';
    @Input() allRatings_overallConsumerScore: any;
    @Input() allRatings_serviceSupportScore: any;
    @Input() allRatings_ProviderFavorScore: any;
    @Input() allRatings_PriceStabilityScore: any;

    @Input() planProfile_wellnessFitnessProgramInclusion: any;
    @Input() planProfile_householdDiscountConditions: any;
    @Input() planProfile_enrollmentDiscountConditions: any;
    @Input() planProfile_onetimeCarrierEnrollmentFee: any;
    @Input() planProfile_onetimeTaptapEnrollmentFee: any;
    @Input() priceRecord_priceStabilityRating: any;
    
    @Input() priceRecord_2018RateIncrease: any;
    @Input() priceRecord_2019RateIncrease: any;
    @Input() priceRecord_2020RateIncrease: any;
    @Input() priceRecord_2021RateIncrease: any;
    @Input() priceRecord_2022RateIncrease: any;
    

  constructor() { 
  }

  ngOnInit() {
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if(changes.forResult){
  //     let result = changes.result.currentValue;
  //     this.additionalInsights(result)
  //   }
  // }

  additionalInsights(result) {
    // let consumerScore = JSON.parse(result.section14.data);
    let priceStabilityScore = JSON.parse(result.section13.data);
    
    // if(consumerScore){
    //   if(consumerScore.price_record){
    //     if(consumerScore.price_record.consumer_score){
    //       this.consumerRating = consumerScore.price_record.consumer_score;
    //     }else{
    //       this.consumerRating = 0;
    //     }
    //   }
    // }

    // if(consumerScore){
    //   if(consumerScore.price_record){
    //     if(consumerScore.price_record.overall_score){
    //       this.overallRating = consumerScore.price_record.overall_score;
    //     }else{
    //       this.overallRating = 0;
    //     }
    //   }
    // }

    // if(consumerScore){
    //   if(consumerScore.price_record){
    //     if(consumerScore.price_record.customer_service_score){
    //       this.customerServiceRating = consumerScore.price_record.customer_service_score;
    //     }else{
    //       this.customerServiceRating = 0;
    //     }
    //   }
    // }

    if(priceStabilityScore){
      if( priceStabilityScore.price_record){
        if( priceStabilityScore.price_record.price_stability_rating){
          this.priceStabilityRating =  priceStabilityScore.price_record.price_stability_rating;
        }else{
          this.priceStabilityRating = 0;
        }
      }
    }

    // this.consumerRating = (consumerScore) ? consumerScore.price_record.consumer_score : 0;
    // this.overallRating = (consumerScore) ? consumerScore.price_record.overall_score : 0;
    // this.customerServiceRating = (consumerScore) ? consumerScore.price_record.customer_service_score : 0;
    // this.priceStabilityRating = (priceStabilityScore) ? priceStabilityScore.price_record.price_stability_rating : 0;
  }


  // additionalInsights(result) {
  //     if(result.taptap_details.consumer_rating)
  //     this.consumerRating = ( result.taptap_details.consumer_rating == 'Information coming soon' || result.taptap_details.consumer_rating == '' || result.taptap_details.consumer_rating == null) ? result.taptap_details.consumer_rating : this.round5(parseFloat(result.taptap_details.consumer_rating));
  //     this.overallRating = ( result.taptap_details.overall_rating == 'Information coming soon' || result.taptap_details.overall_rating == '' || result.taptap_details.overall_rating == null) ? result.taptap_details.overall_rating : this.round5(parseFloat(result.taptap_details.overall_rating));
  //     this.customerServiceRating = ( result.taptap_details.customer_service_rating == 'Information coming soon' || result.taptap_details.customer_service_rating == '' || result.taptap_details.customer_service_rating == null) ? result.taptap_details.customer_service_rating : this.round5(parseFloat(result.taptap_details.customer_service_rating));
  //     this.priceStabilityRating = ( result.taptap_details.price_stability_rating == 'Information coming soon' || result.taptap_details.price_stability_rating == '' || result.taptap_details.price_stability_rating == null) ? result.taptap_details.price_stability_rating : this.round5(parseFloat(result.taptap_details.price_stability_rating));
  // }

  round5(x){
    return Math.round(x/.5)*.5;
  }

}
