import { Component, OnInit } from '@angular/core';
import { FooterService } from '../../services/footer.service';
import {HeaderService} from '../../services/header.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor( public footer: FooterService, public header: HeaderService ) { }

  dateNow:any = new Date().getFullYear();

  ngOnInit() {
  }

  showModal(content) {
    Swal.fire({
        html: content,
        showConfirmButton: false,
        showCloseButton: true,      
        width: 500,
        padding: '0px',
    });  
}

shareWithEmail(content) {
    Swal.fire({
        html: content,
        showConfirmButton: false,
        showCloseButton: true,      
        width: 600,
        padding: '0px',
    });  
}

  showSupportOptions():void {
    const widgetForm = $("#bennyDash-widget-form");
    const widgetOverlay = $(".bennyDash-widget-overlay");

    if (!widgetForm.attr('class')) {
      widgetForm.addClass('d-block')
      widgetOverlay.addClass('d-block')
    } else {
      if (widgetForm.hasClass('d-none')) {
        widgetForm.removeClass('d-none')
        widgetOverlay.removeClass('d-none')
  
        widgetForm.addClass('d-block')
        widgetOverlay.addClass('d-block')
      }
    }
  }
  

}
