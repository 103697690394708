<div *ngIf="loading" class="margin-top-100 margin-bottom-100" style="height:300px; width: 100%; text-align: center;">
    <div class="text-center">
        <div class="spinner-border" style="width: 4rem; height: 4rem; margin-top: 150px;" role="status">
            <span class="sr-only">Sending...</span>
        </div>
    </div>
</div>

<div class="d-flex align-items-end email-your-quote" *ngIf="!loading">
    <img src="./assets/img/common/old-woman-holding-paper-eyeglass-cut-half.png" alt="">
    <div class="email-your-quote-form">
        <h4 class="modal-title-heading mb-2 mx-auto text-center">Email This Page</h4><!--Email Your Quote-->
        <h6 class="color-primary uppercase text-center mb-3">Enter your details here:</h6>
        <form>
            <div class="row text-left">
                <div class="form-group col-12">
                    <label for="name_emailMyQuotes">Your Name:</label>
                    <input type="search" autocomplete="off" class="form-control" id="name_emailMyQuotes" [formControl]="quote.name">
                </div>
                <!-- <div class="form-group col-12">
                    <label for="email_emailMyQuotes">Your Email:</label>
                    <input type="search" autocomplete="off" class="form-control" id="email_emailMyQuotes" [formControl]="quote.your_email">
                </div> -->
                <div class="form-group col-12">
                    <label for="phone_emailMyQuotes">Your Phone:</label>
                    <input type="search" autocomplete="off" class="form-control" id="phone_emailMyQuotes" [formControl]="quote.phone">
                </div>
                <div class="form-group col-12">
                    <label for="emailto_emailMyQuotes">Email To:</label>
                    <input type="search" autocomplete="off" class="form-control" id="emailto_emailMyQuotes" [formControl]="quote.to_email">
                </div>
                <div class="form-group col-12 d-flex justify-content-center">
                    <button type="submit" class="btn btn-first fluid"
                    (click)="emailYourQuote()">
                        Email This Page
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>