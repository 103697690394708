<h4 class="title mb-5">Coverage Info</h4>
<h6>As an applicant for Medicare Advantage coverage, which Best Fits Your Recent Coverage Scenario (Check All That Apply)</h6>

<div class="row bordered p-4 mt-3">
  <div class="col-12 pl-0 mb-3">
      <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" (change)="isOnChangeTopOption_1($event)" class="custom-control-input" id="topOption1">
        <label class="custom-control-label fWeight-600 fColor-blue" for="topOption1">Do you have an existing MAPD:</label>
      </div>
  </div> 
  <div class="col-12">
    <div class="row px-0">
      <div class="col-12 col-sm-6 pl-2">
          <div class="form-group">
            <label for="mapdInsuranceCarrier">MAPD Insurance Carrier</label>
            <select class="form-control blurred" id="mapdInsuranceCarrier">  
              <option value="">Please Select</option>
            </select>         
          </div>
      </div>
      <div class="col-12 col-sm-6 pl-4">
          <div class="form-group">
            <label for="phoneNumber">Was This an HMO or PPO Plan</label>
            <div class="d-flex">
              <div class="custom-control custom-checkbox mt-2 mr-5">
                <input type="checkbox" class="custom-control-input" id="whatThisHmoPpoPlan_1">
                <label class="custom-control-label fWeight-600" for="whatThisHmoPpoPlan_1">HMO</label>
              </div>

              <div class="custom-control custom-checkbox mt-2">
                <input type="checkbox" class="custom-control-input" id="whatThisHmoPpoPlan_2">
                <label class="custom-control-label fWeight-600" for="whatThisHmoPpoPlan_2">PPO</label>
              </div>
            </div>
          </div>
      </div>
      <div class="col-12 col-sm-6 pl-4">
        <div class="form-group">
          <label for="planNameOfMapdPlan">Plan Name of MAPD Plan</label>
          <select class="form-control blurred" id="planNameOfMapdPlan">  
            <option value="">Please Select</option>
          </select>         
        </div>
      </div>
      <div class="col-12 col-sm-6 pl-2">
          <div class="form-group">
            <label for="whatWasYourStartDateOfThisPlan">What Was Your Start Date of This Plan</label>
            <input type="text" class="form-control blurred" id="whatWasYourStartDateOfThisPlan"/>           
          </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <p>If you are still covered under that Medicare insurance plan, do you intend to replace your current coverage with this new Medicare Supplement policy?</p>
    <div class="d-flex">
      <div class="mr-4">
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="stillCoveredUnderMedicareInsurancePlan_yes">
          <label class="custom-control-label fWeight-600" for="stillCoveredUnderMedicareInsurancePlan_yes">Yes</label>
        </div>
        <a href="#" role="button">Notice to Applicant Regarding Replacement</a>
      </div>
      <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" class="custom-control-input" id="stillCoveredUnderMedicareInsurancePlan_no">
        <label class="custom-control-label fWeight-600" for="stillCoveredUnderMedicareInsurancePlan_no">No</label>
      </div>
    </div>    
  </div>
</div>
<div class="row bordered p-4 mt-3">
  <div class="col-12 pl-0">
      <!-- <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" (change)="isOnChangeTopOption_2($event)" class="custom-control-input" id="topOption2">
        <label class="custom-control-label fWeight-600" for="topOption2">If Currently Has One Of The Following -> Select a Scenario That Applies</label>
      </div> -->
      <h6 class="fColor-blue fWeight-600"> If Currently Has One Of The Following -> Select a Scenario That Applies </h6>
  </div> 
  <div class="col-12 pl-0">
    <div class="row">
      <div class="col-12">
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="iHave63days_1">
          <label class="custom-control-label fWeight-600" for="iHave63days_1">I Have or Had Employer Coverage Within The Past 63 Days</label>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="iHave63days_2">
          <label class="custom-control-label fWeight-600" for="iHave63days_2">I Have or Had An ACA/Healthcare.gov Policy Within The Past 63 Days</label>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" class="custom-control-input" id="iHave63days_3">
          <label class="custom-control-label fWeight-600" for="iHave63days_3">I Have or Had Other Major-Medical Health Insurance Within The Past 63 Days</label>
        </div>
      </div>
      <div class="col-12">
          <p>MAPD App Your Coverage Status Outlook:</p>
          <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="mapdAppYourCoverage_1">
            <label class="custom-control-label fWeight-600" for="mapdAppYourCoverage_1">I Can Keep This Coverage If I’d Like</label>
          </div>
          <div class="custom-control custom-checkbox mt-2">
            <input type="checkbox" class="custom-control-input" id="mapdAppYourCoverage_2">
            <label class="custom-control-label fWeight-600" for="mapdAppYourCoverage_2">I Am Within 63 Days of Losing This Coverage Involuntarily</label>
          </div>
      </div>
    </div>
    
  </div>
</div>
<div class="row bordered p-4 mt-3">
  <div class="col-12 pl-0">
      <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" (change)="isOnChangeTopOption_3($event)" class="custom-control-input" id="topOption3">
        <label class="custom-control-label fWeight-600 fColor-blue" for="topOption3">If Currently Has Medicaid In Any Form,</label>
      </div>
  </div> 
  <div class="col-12 pl-5">
      <div class="custom-control custom-checkbox mt-2">
        <input type="checkbox" class="custom-control-input" id="doYourReceiveAnyBenefitsFromMedicaid">
        <label class="custom-control-label fWeight-600" for="doYourReceiveAnyBenefitsFromMedicaid">Do you receive any benefits from Medicaid other than payments toward your Medicare Part B premium?</label>
      </div>
  </div> 
</div>