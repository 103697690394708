import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-medicare-info',
  templateUrl: './medicare-info.component.html',
  styleUrls: ['./medicare-info.component.scss']
})
export class MedicareInfoComponent implements OnInit {

  @Output() emit_onClickCalendar = new EventEmitter;
  @Input() details: any;
  @Input() medCardNumber1: any;
  @Input() medCardNumber2: any;
  @Input() medCardNumber3: any;
  customRadio: any;

  constructor() { }

  ngOnInit() {
  }

  onClickDob(type, heading){
    this.emit_onClickCalendar.emit({type,heading});
    //this.details.customer_info.part_a_effective_date = 
    // this.dateFieldType = type;
    // this.dateFieldHeading = heading;

  }

  onCheckedDontHavePartA(type){

  }

  onCheckedDontHavePartB(type){}
}
