import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared.module';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { AepSidenavComponent } from './aep-sidenav/aep-sidenav.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [ ContentHeaderComponent, AepSidenavComponent ],
  exports: [ ContentHeaderComponent, AepSidenavComponent ]
})
export class AepModule { }

