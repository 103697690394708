import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MetadataService } from '../services/metadata.service';
import {Title} from "@angular/platform-browser";

declare var window: any;

@Component({
  selector: 'app-medicare-services',
  templateUrl: './medicare-services.component.html',
  styleUrls: ['./medicare-services.component.scss'],
  providers: [MetadataService]
})
export class MedicareServicesComponent implements OnInit {
  fullPrimaryHeader: boolean = true;
  isLoggedInUser: any;
  searchKeyword:any;

  private currentUrl = window.location.href;
  private baseUrl = window.location.origin;
  isShow: boolean = true;
  infoMessage: string;

  list:any = [
    {
      category:'I Just Started Medicare',
      item: ['Understand the Big Picture','Understand What Decisions I Have to Make','Understand What Questions I Should Ask']
    },
    {
      category:'Medicare Part A & B Enrollment',
      item: ['Fully Enroll in Medicare','Enroll in Medicare Part A','Enroll in Medicare Part B','With the Required Forms My Employer Needs to Complete']
    },
    {
      category:'Understanding Medigap vs. Medicare Advantage',
      item: ['Understand the Big Picture','Understand What Decisions I Have to Make','Understand What Questions I Should Ask']
    },
    {
      category:'Help Me Organize My Medicare',
      item: ['Understand My Coverage','Organize My Coverage','Identify and Understand My Costs']
    },
    {
      category:'Reduce Costs & Save Money',
      item: ['With My Drug Costs','Get a Medigap Price Comparison','improve My Out-of-Pocket Costs']
    },
    {
      category:'Custom Plan Suitability Comparison',
      item: ['Compare Medicare Advantage Plans','Compare Medigap Plans','See All Plans My Doctor Takes']
    },
    {
      category:'Apply for Government Assistance Programs',
      item: ['With Medicare Savings Programs','Drug Cost Assistance Programs','Reduce My Part B Premium']
    },
    {
      category:'Full Personalized Reviews',
      item: ['Receive Clear Answers and Explanations on Any Part of Medicare','Identify Your Exact Coverage Scenario and Its Competitiveness Relative to Other Plans and Prices','Receive a Full Custom Report That I Can Access And Share with Others']
    }
  ]

  constructor(
    private _auth: AuthService,
    private metadataService: MetadataService,
    private titleService:Title
  ) {
    this.titleService.setTitle("Medicare Services & Solutions For You - TapTap Medicare");
    console.log('cheese', this.list);
  }

  showForm(info) {
    this.infoMessage = info;
    this.isShow = false;
    window.scrollTo(0, 0);
  }

  back() {
    this.isShow = true;
    window.scrollTo(0, 0);
  }

  injectSEO(): void {
    this.metadataService.getMetaData('/medicare-services', (a) => {
      const { data } = a;
     
      const fbMetaTitle = document.getElementById('fb-meta-title');
      const fbMetaDescription = document.getElementById('fb-meta-description');
      const fbMetaUrl = document.getElementById('fb-meta-url');
      const fbMetaImage = document.getElementById('fb-meta-image');

      
      fbMetaTitle.setAttribute('content', data['meta-title']);
      fbMetaDescription.setAttribute('content', data['meta-desc']);
      fbMetaUrl.setAttribute('content', this.currentUrl);
      fbMetaImage.setAttribute(
        'content',
        `${this.baseUrl}/assets/company-logo.jpg`
      );
      window.prerenderReady = true;
    });
  }

  /**
   * Show contact header description
   * @type { string } 
   */
  getDisplayContactDescription():string {
    const message = "We Will Be Happy To Help You. Just Submit Your Info Below and Our Team Will Start Working To Assist You Right Away!";
    return message;
  }

  ngOnInit() {
    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if (this.isLoggedInUser) {
      this.fullPrimaryHeader = false;
    }
    this.injectSEO();
  }
}
