import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs/index';
import { AuthService } from '../services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2'; 

@Component({
  selector: 'app-retirement-living',
  templateUrl: './retirement-living.component.html',
  styleUrls: ['./retirement-living.component.scss']
})
export class RetirementLivingComponent implements OnInit {

  fullPrimaryHeader:boolean = true;
  isLoggedInUser:any;
  users: Observable<any>;
  constructor(private db: AngularFirestore,public _auth: AuthService) { }

  ngOnInit() {
    this.users = this.db.collection('users')
          .valueChanges();

    this.isLoggedInUser = this._auth.checkIfUserLoggedIn();
    if(this.isLoggedInUser){
        this.fullPrimaryHeader = false;
    }
  }

  speakToCounselorEvent(modalViews) {
    Swal.fire({
      html: modalViews,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
      padding: '3em'
    }); 
  }

}
