import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-more-insights',
  templateUrl: './more-insights.component.html',
  styleUrls: ['./more-insights.component.scss']
})
export class MoreInsightsComponent implements OnInit {
    @Input() image:any;
    @Input() consumerRating:any;
    @Input() overallRating:any;
    @Input() priceStabilityRating:any;
    @Input() fees: any[];
    @Input() discounts: any[];
    @Input() rateHistory: any[];
    @Input() silverSneakers: any;

    constructor() {
    }

    ngOnInit() {
    }

    formatDate(date) {
        return new Date(date).getFullYear();
    }

}
