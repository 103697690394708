import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bennydash-widget-form',
  templateUrl: './bennydash-widget-form.component.html',
  styleUrls: ['./bennydash-widget-form.component.scss']
})
export class BennydashWidgetFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $('.bennyDash-widget-form-close').click(function(){
      $('.bennyDash-widget-overlay').removeClass('d-block');
      $('#bennyDash-widget-form').removeClass('d-block');
      $('.bennyDash-widget-overlay').addClass('d-none');
      $('#bennyDash-widget-form').addClass('d-none');
    })

    $('.bennyDash-widget-connect-btn').click(function(){
      $('#custom-widget-chat-btn-id').click();
      $('.bennyDash-widget-form-close').click();
    })

    // $('.marketplace-widget-1-btn').click(function(){
    //   $('.marketplace-widget-form-close').click();
    //   const content = $('.help-me-understand-this-coverage').html();
    //   showModal(content);
    // })

    // $('.marketplace-widget-2-btn').click(function(){
    //   $('.marketplace-widget-form-close').click();
    //   const content = $('.is-this-the-right-coverage-for-me').html();
    //   showModal(content);
    // })

    // function showModal(content){
    //   Swal.fire({
    //     html: content,
    //     customClass: {
    //         popup: 'custom-modal-wrapper',
    //         content: 'text-left custom-modal-content'
    //     },
    //     showConfirmButton: false,
    //     showCloseButton:true
    //   })
    // }
  }

}
