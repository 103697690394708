<h1>QOL TDS</h1>
<br />
<p>{{ convertDateTime('1452508763895') }}</p>

<br />
<br />

<h1>QOL Boolean</h1>
<p>{{ convertBoolean(true) }}</p>

<div class="clr-row">
  <div class="clr-col-md-6 clr-col-xl-4 clr-col-12">
    <div class="card">
      <div class="card-img">
        <img src="https://source.unsplash.com/300x180/?nature,water" />
      </div>
      <div class="card-block">
        <div class="card-title">
          Sed ut perspiciatis unde omnis iste natus
        </div>
        <p class="card-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt
          excepturi labore explicabo temporibus, enim voluptate saepe corrupti
          illum earum eveniet ab veniam vel nisi fugit accusantium perferendis
          quas facilis quod.
        </p>
      </div>
      <div class="card-footer">
        <a class="btn btn-primary btn-sm">Read more</a>
      </div>
    </div>
  </div>

  <div class="clr-col-md-6 clr-col-xl-4 clr-col-12">
    <div class="card">
      <div class="card-img">
        <img src="https://source.unsplash.com/random/300x180" />
      </div>
      <div class="card-block">
        <div class="card-title">
          Sed ut perspiciatis unde omnis iste natus
        </div>
        <p class="card-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt
          excepturi labore explicabo temporibus, enim voluptate saepe corrupti
          illum earum eveniet ab veniam vel nisi fugit accusantium perferendis
          quas facilis quod.
        </p>
      </div>
      <div class="card-footer">
        <a class="btn btn-primary btn-sm">Read more</a>
      </div>
    </div>
  </div>
</div>
