import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {

  firstName_acknowledgement:any;
  middleName_acknowledgement:any;
  lastName_acknowledgement:any;
  importantInformationSelectedDate_acknowledgement:any;
  isIAgree:boolean = false;
  @Output() emit_isAgree = new EventEmitter;

  constructor() { }

  ngOnInit() {
  }

  onChange_IAgree_acknowledgement(event){
    if(event.target.checked){
      this.isIAgree = true;
    }else{
      this.isIAgree = false;
    }

    this.emit_isAgree.emit(this.isIAgree);
  }
}
