import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-application-mapd-version2-screen4',
  templateUrl: './screen4.component.html',
  styleUrls: ['./screen4.component.scss']
})
export class Screen4Component implements OnInit {

  addEmergencyContact: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
