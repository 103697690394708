<div id="header-b" [class.landing-page]="header.isNoHeader" [class.mb-4]="hasNoMarginBottom">
  <div class="container">
    <div class="row">
      <div [class.aep-wrapper]="isAepLandingPage" [class.mt-0]="header.isNoHeader || hasNoMarginTop" class="header-b-wrapper col-12 d-flex justify-content-center justify-content-lg-between align-items-center flex-wrap flex-column flex-lg-row">
        <a href="#" routerLink="/home"><img src="assets/img/common/logo-white.png" class="company-logo d-inline-block"
            alt=""></a>
        <!-- <a class="home-icon-wrapper"><i class="fa fa-home fColor-white" style="font-size: 1.5em;"></i></a> -->
        <p class="font-primary" *ngIf="showNumber || header.isNoHeader">
          <span class="fColor-white">Need Immediate Assistance? </span>
          <span class="fColor-white"> Call <strong class="font-primary fColor-white fWeight-700"
              style="font-size: 1.2em;">1(800) 215-5108</strong></span>
        </p>

        <div class="d-flex align-items-center">
          <button class="btn btn-third" (click)="speakToCounselorEvent(speakToCounselorModal)">Speak With a Concierge</button>
          
          <div class="retiree-notification d-flex desktopOnly" *ngIf="isLoggedIn">
              <!-- <mat-icon matBadge="15" [ngStyle]="{'color':'white','font-size': '30px','margin-left':'1em'}" matBadgeColor="warn">notifications</mat-icon> -->
              <!-- <mat-icon matBadge="3" [ngStyle]="{'color':'white','font-size': '28px','cursor':'pointer'}" matBadgeColor="warn">email</mat-icon>
              <mat-icon matBadge="8" [ngStyle]="{'color':'white','font-size': '30px','margin-left':'1em','cursor':'pointer'}" matBadgeColor="warn">notifications</mat-icon> -->
              <mat-icon class="d-none" matTooltip="Visit Beneficiary Dashboard" routerLink="/benny-dash" [ngStyle]="{'color':'white','font-size': '30px','margin-left':'1em','cursor':'pointer'}" matBadgeColor="warn">account_circle</mat-icon>
              <!-- <i class="fa fa-user header-beneficiary-icon" routerLink="/benny-dash" title="Visit Beneficiary Dashboard"></i> -->
              <!-- <a routerLink="/benny-dash" class="logout-group benny-hq-button"> 
                <img src="https://taptapmedicare.com/assets/img/taptap-house-icon-v2.png" class="header-logout-icon" title="Visit Beneficiary Dashboard" alt="">
                GO TO MY BENNY HQ
              </a> -->
              <button class="benny-hq-link-button" routerLink="/benny-dash">
                <img src="https://taptapmedicare.com/assets/img/taptap-house-icon-v2.png" class="header-logout-icon" title="Visit Beneficiary Dashboard" alt="">
                <span class="benny-hq-link-title">GO TO MY BENNY HQ</span>
              </button>
              
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #speakToCounselorModal>
    <app-speak-with-a-counselor></app-speak-with-a-counselor>
  </div>
</div>