import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { catchError, finalize, share } from 'rxjs/operators';
import { pipe } from 'rxjs';

const API_BASE_URL: string = environment.apiUrl;

@Injectable()
export class APIService {

    inFlightRequestsCount: number = 0;

    constructor(private _http: HttpClient) {
    }

    public callAPIEndpoint(endpoint: string, data: any, config: APIOptions = {
        showLoading: true,
        method: 'POST'
    }): Observable<any> {
        let method = config.method.toLowerCase(),
            showLoading = config.showLoading;
    
        data = (typeof data == 'string') ? JSON.parse(data) : data;
    
        let options = {};
        if (config.method.toUpperCase() == 'GET') {
            data = {
                params: data
            }
        } else {
            data = JSON.stringify(data);
        }
    
        if (showLoading) {
            this.inFlightRequestsCount++;
        }
    
        return this._http[method](this.formattedBaseURL + endpoint, data, options).pipe(
            share(),
            finalize(() => {
                if (showLoading) {
                    this.inFlightRequestsCount--;
                }
            })
        );
    }

    public downloadFromAPIEndpoint(endpoint: string, data: any): Observable<any> {
        return this._http.post(this.formattedBaseURL + endpoint,data,{
          responseType: 'blob',headers: new HttpHeaders().append('content-type','application/json')});
    }

    public uploadToEndpoint(endpoint: string, data: any): Observable<any>{
      return this._http.post(this.formattedBaseURL + endpoint,data);
    }

    get isLoading(): boolean {
        return this.inFlightRequestsCount > 0;
    }

    get formattedBaseURL(): string {
        console.log('1= ' + API_BASE_URL);
        return API_BASE_URL.replace(/\/$/, "");
    }

    public callAPIEndpointAuthenticated(endpoint, token, body): Observable<any> {
        return this._http.post(this.formattedBaseURL + endpoint, body,{headers: new HttpHeaders().append('content-type','application/json').append('Authorization', 'Bearer ' + token)});
    }

    public callAPIEndpointAuthenticatedPutRequest(endpoint, token, body): Observable<any> {
        return this._http.put(this.formattedBaseURL + endpoint, body,{headers: new HttpHeaders().append('content-type','application/json').append('Authorization', 'Bearer ' + token)});
    }

    public callAPIEndpointAuthenticatedGetRequest(endpoint, token): Observable<any> {
        return this._http.get(this.formattedBaseURL + endpoint, {headers: new HttpHeaders().append('content-type','application/json').append('Authorization', 'Bearer ' + token)});
    }


    public geUserInfo(endpoint, token, body): Observable<any> {

        return this._http.post(this.formattedBaseURL + endpoint, body,{headers: new HttpHeaders().append('content-type','application/json').append('Authorization', 'Bearer ' + token)});

        /*let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Authorization', 'Bearer ' + token);
        const body = new URLSearchParams();
        return this._http.post(this.formattedBaseURL + endpoint, body, { headers: headers}).map((data: Response) => data.json());*/
    }

}

interface APIOptions {
    showLoading?: boolean;
    method?: string;
}
