<!--START BANNER-->
<div class="container-fluid header-banner-wrapper-plain">
	<app-header-b></app-header-b>
	<div class="container banner-wrapper">
		<div class="row">
			<div class="col-12">
				<h1 class="medium banner-main-title text-center">
					Terms of Services
				</h1>
			</div>
		</div>
	</div>
</div>
<div class="banner-pointer-wrapper">
	<i class="fa fa-arrow-down"></i>
	<div class="banner-pointer-background"></div>
</div>
<!--END BANNER-->

<div class="container">
  <div class="row">
    <div class="col-12 text-center">
        <h4 class="form-input-heading">
            <strong class="color-primary"> Terms of Use:</strong> <br>
            Please read carefully this Terms of Use Agreement (the "Agreement") before accessing this website.
        </h4>
    </div>
      
  </div>
  <div class="row">
      <div class="px-0 col-sm-7 col-lg-8 form-wrapper">
        
          <p class="p-subheading">
              This Term of Use Agreement sets both forth the standards of use of TapTapMedicare.com (or the "Site"). 
              TapTapMedicare.com is owned and operated by BrightPrice Group. (Hereinafter, the "Company," or "BrightPrice"), 
              a licensed insurance agency. By using TapTapMedicare.com you (the "Member") agree to these terms and conditions. If you do not agree to the terms and conditions of this agreement, you should immediately cease all usage of this Site. We reserve the right, at any time, to modify, alter, or update the terms and conditions of this agreement without prior notice. Modifications shall become effective immediately upon being posted at TapTapMedicare.com. Your continued use of the Site after amendments are posted constitutes acknowledgement and acceptance of the Agreement and its modifications. Except as provided in this paragraph, this Agreement may not be amended.
          </p>
              <!-- expansion panel -->
              <mat-accordion class="example-headers-align">
                  <mat-expansion-panel [expanded]="true" hideToggle>
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                              1. Description of Service
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      TapTapMedicare.com provides users with a range of information and tools relating to Medicare coverage, health and wellness. There are articles, tools, images, and other content ("Content") written by us or commissioned from writers; and content we have licensed from well-respected sources and/or partners. The Content we write and commision is written and reviewed by reputable sources before it is posted. Content provided by our partners is also, where appropriate, subject to review by the TapTapMedicare.com team before it is posted. We make sure that all ads are clearly marked. Everything we offer on the Site is referred to in these Terms of Service collectively as "The Services". Review our Privacy Policy for additional information on how we do business.
                  </mat-expansion-panel>
              
                  <mat-expansion-panel hideToggle>
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                            2. This Site Does Not Provide Medical Advice
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      The contents of TapTapMedicare.com, such as text, graphics, images, information obtained from TapTapMedicare.com’s licensors, and other material contained on the Site (“Content”) are for informational purposes only. The Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on TapTapMedicare.com. TapTapMedicare.com does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the Site. Reliance on any information provided by TapTapMedicare.com, TapTapMedicare.com employees, others appearing on the Site at the invitation of TapTapMedicare.com, or other visitors to the Site is solely at your own risk.
                  </mat-expansion-panel>
              
                  <mat-expansion-panel hideToggle>
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                            3. We Are Not Associated With Any Government Nor Do We Claim To Be
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      This Site is a private website and is not, associated, endorsed or authorized by the Social Security Administration, the Health Care Financing Administration, the Department of Health and Human Services or the Centers for Medicare & Medicaid Services nor do we claim to be. This site contains basic information about Medicare, services related to Medicare and services for people with Medicare.
                  </mat-expansion-panel>

                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          4. Disclaimer of Warranties
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    The site is provided by BrightPrice Group on an “as is” and on an “as available” basis. To the fullest extent permitted by applicable law, BrightPrice Group makes no representations or warranties of any kind, express or implied, regarding the use or the results of this Site or the information, content, materials or products included in the Site in terms of its correctness, accuracy, reliability, or otherwise. All links to other websites are provided as a courtesy only and you expressly agree that the use of them is at your own risk. BrightPrice Group shall have no liability for any interruptions in the use of this Site. BrightPrice Group does not warrant that this Site, its servers, or email sent from BrightPrice Group are free of viruses or other harmful components. BrightPrice Group disclaims all warranties with regard to the information provided, including the implied warranties of merchantability and fitness for a particular purpose, and non-infringement. Some jurisdictions do not allow the exclusion of implied warranties, therefore the above-referenced exclusion is inapplicable.
                  </mat-expansion-panel>


                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          5. Limitation of Liability
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    Brightprice Group shall not be liable for any damages whatsoever, and in particular Brightprice Group shall not be liable for any special, indirect, consequential, or incidental damages, or damages for lost profits, loss of revenue, or loss of use, arising out of or related to this website or the information contained in it, whether such damages arise in contract, negligence, tort, under statute, in equity, at law, or otherwise, even if Brightprice Group has been advised of the possibility of such damages. Some jurisdictions do not allow for the limitation or exclusion of liability for incidental or consequential damages, therefore some of the above limitations are inapplicable.
                  </mat-expansion-panel>

                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          6. Indemnificationy
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    Member agrees to indemnify and hold BrightPrice Group, its parents, subsidiaries, affiliates, officers and employees, harmless from any claim or demand, including reasonable attorneys’ fees and costs, made by any third party due to or arising out of Member’s use of the Service, the violation of this Agreement, or infringement by Member, or other user of the Service using Member’s computer, of any intellectual property or any other rights of any person or entity.
                  </mat-expansion-panel>
                  
                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          7. Third-Party Sites
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    This Site may include links to other websites on the Internet that are owned and operated by other third parties. Although we may provide oversight of some content on certain third-party sites as required by our agreements with insurance companies or by applicable government regulations, you acknowledge that we are not responsible to you for the availability of, or the content located on or through, any third-party website. You should contact the website administrator or webmaster for those third-party sites if you have any concerns regarding such links or the content located on such sites. Your use of those third-party websites is subject to the terms of use and privacy policies of each website, and we are not responsible therein. We encourage all Members to review said privacy policies of third-party' sites.
                  </mat-expansion-panel>

                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          8. Governing Jurisdiction of the Courtss
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    Our website is operated and provided in the State of Pennsylvania. As such, we are subject to the laws of the State of Pennsylvania, and such laws will govern this Agreement, and any dispute of any sort that might arise between Member and BrightPrice Group, without giving effect to any choice of law rules
                  </mat-expansion-panel>

                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          9. Copyright and Trademark Information
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    All content included on this site, such as text, graphics, logos (including the TapTapMedicare.com logo), button icons, images, audio clips, digital downloads, data compilations, and software, is the property of BrightPrice Group or its content suppliers and protected by United States and international copyright laws. The compilation of all content on this site is the exclusive property of BrightPrice Group and protected by United States and international copyright laws. All software used on this site is the property of BrightPrice Group or its software suppliers and protected by United States and international copyright laws. BrightPrice Group and TapTapMedicare.com are registered trademarks of the Company TapTapMedicare.com is a registered domain owned by BrightPrice Group. The Company’s trademarks may not be used in connection with any product or service that is not the Company’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits the Company. All trademarks not owned by the Company that appear on this website are the property of their respective owners.
                  </mat-expansion-panel>

                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          10. Notification of Claimed Copyright Infringement
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    Please email us at info@TapTapMedicare.com  to report a claim of copyright infringement.
                  </mat-expansion-panel>

              </mat-accordion>
              
              <!-- end expansion panel -->
      </div>
      <div class="col-sm-5 col-lg-4 form-left-img">
          <img src="./assets/img/common/old-woman-with-basket.png" alt="">
      </div>
  </div>
</div>



<div class="footer-top-spacer"></div>
<app-ready-to-talk></app-ready-to-talk>