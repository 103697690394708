import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-result-not-found',
  templateUrl: './result-not-found.component.html',
  styleUrls: ['./result-not-found.component.scss']
})
export class ResultNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  resultNotFoundGuidance():void {
    $('.marketplace-widget-overlay').removeClass('d-none');
    $('#marketplace-widget-form').removeClass('d-none');
    $('.marketplace-widget-overlay').addClass('d-block');
    $('#marketplace-widget-form').addClass('d-block');
  }

}
