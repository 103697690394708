<button class="marketplace-widget-form-close"><i class="fa fa-times"></i></button>

<div class="d-flex">
  <div class="flex-grow-1 pr-0 p-md-4">
    <h5 class="text-center fWeight-600 dblue">
      Speak With A Counselor
    </h5>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <button class="btn-widget-choice shadow-sm w-100 marketplace-widget-1-btn">
          <img src="./assets/img/common/widget/1.png" alt="">
          <p>Help Me Understand This Coverage</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="btn-widget-choice shadow-sm w-100 marketplace-widget-2-btn">
          <img src="./assets/img/common/widget/2.png" alt="">
          <p>Is This The Right Coverage For Me?</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="btn-widget-choice shadow-sm w-100 marketplace-widget-3-btn">
          <img src="./assets/img/common/widget/3.png" alt="">
          <p>How Should I Choose A Carrier?</p>
        </button>
      </div>
      <div class="col-12 col-md-6 p-2">
        <button class="btn-widget-choice shadow-sm w-100 marketplace-widget-4-btn">
          <img src="./assets/img/common/widget/4.png" alt="">
          <p>Will I Be Approved For This Coverage?</p>
        </button>
      </div>
    </div>
    <button class="btn btn-first btn-small mx-auto mt-2 marketplace-widget-connect-btn">
      <i class="fas fa-comment-dots mr-2"></i>Connect With A Counselor
    </button>
  </div>
  <img class="woman-team-asset pr-3 d-none d-md-block" src="./assets/img/common/woman-team-cut.png" alt="">
</div>


<!-- <div class="row marketplace-support-widget">
    <div class="col-8">
        <h5>Marketplace Support</h5>
        <div class="row">
            <div class="col-12 button-wrapper">
                <button class="btn btn-first btn-small marketplace-widget-1-btn">Help Me Understand this Coverage</button>
                <i class="fa fa-question global-info-icon-first"></i>
            </div>
            <div class="col-12 button-wrapper">
                <button class="btn btn-first btn-small marketplace-widget-2-btn">Is this the right Coverage for Me?</button>
                <i class="fa fa-question global-info-icon-first"></i>
            </div>
            <div class="col-12 button-wrapper">
                <button class="btn btn-first btn-small marketplace-widget-3-btn">How Should I Choose A Carrier?</button>
                <i class="fa fa-question global-info-icon-first"></i>
            </div>
            <div class="col-12 button-wrapper">
                <button class="btn btn-first btn-small marketplace-widget-4-btn">Will I Be Approved for This Coverage?</button>
                <i class="fa fa-question global-info-icon-first"></i>
            </div>
            <div class="col-12 button-wrapper">
                <button class="btn btn-third btn-small mb-3 marketplace-widget-connect-btn">Connect With A Counselor</button>
            </div>
        </div>
    </div>
    <div class="col-4 d-flex justify-content-start align-items-end">
        <img src="./assets/img/common/contact-us-woman-pink-dress.png" class="main-img" alt="Marketplace Widget Counselor">
    </div>
</div> -->

<div class="d-none">
    <div class="help-me-understand-this-coverage">
        <h4 class="color-primary text-center uppercase">What People Don't Like about it</h4>
        <div class="container what-is-the-big-picture d-flex align-items-center max-width-700">
            <div class="what-is-the-big-picture-desc">                
                <h5 class="fColor-black">What is The Big Picture?</h5>
                <p>
                    <b class="color-primary">As a result of your Medicare Beneficiary Profiles</b>
                    you get the opportunity to choose between 2 different ways of setting up your Medicare healthcare
                </p>
                <button class="btn border-radius bg-primary text-center p-4 full-width mb-3">
                    <span class="fColor-white d-block fWeight-600" style="font-size: 1.5em;">Coverage Option 1</span>
                    <span class="fColor-white d-block">is using a Medicare Supplement Plan</span>
                </button>
                <button class="btn border-radius bg-primary text-center p-4 full-width mb-2">
                    <span class="fColor-white d-block fWeight-600" style="font-size: 1.5em;">Coverage Option 2</span>
                    <span class="fColor-white d-block">is using a Medicare Advantage Plan</span>
                </button>
                <p>
                    <b class="color-primary">Both Medicare Coverage Options</b> are
                    particularly comprehensive relative to the 
                    majority of Health Insurance coverages.
                </p>
                <p>
                    <b class="color-primary">Additionally,</b> both coverage options are established and popular among current
                    Medicare Beneficiaries
                </p>
                <p>
                    <b class="color-primary">However,</b> each coverage type works very differently from the other, and provides
                    a different healthcare experience.
                </p>
            </div>
            <div class="what-is-the-big-picture-img" style="max-width: 400px;width: 68%;" class="desktop-only">
                <img src="./assets/img/common/old-man-blue-shirt.png" alt="">
            </div>
        </div>

        <mat-divider></mat-divider>

        <h5 class="fColor-black">What Do Most Beneficiaries Prefer?</h5>
        <p>
            <b class="color-primary">Among Current Experienced and Savvy Medicare Beneficiaries,</b> some swear by
                Coverage Option 1, while others swear by Coverage Option 2
        </p>
        <p>
            <b class="color-primary">The Right Coverage Type For You</b> should be based on your particular 
            preferences, goals, and health outlook.
        </p>

        <mat-divider></mat-divider>

        <p>
            <b class="color-primary">For Reference</b> in 2020 about 41% of eligible Medicare beneficiaries have Medicare Supplement plan
            (Coverage Option 1) and <strong>59%</strong> have a Medicare Advantage plan (Coverage Option 2)
        </p>

        <mat-divider></mat-divider>
        
        <h4 class="fColor-primary">Medigap Plan G Profile</h4>
        <p><b class="fColor-primary">Overview:</b> When you have Medicare Supplemnt coverage this Means You are keeping MEdicare as your
        primary and enrolling in a Medicare Supplement (aka Medigap Plan) to eliminate your out-of-pocket costs for "Major Medical" services.</p>
            
        <p><b class="fColor-primary">Your prescription drug coverage</b> is through a "Standalone" Medicare Part D Drug Plan, and your dental, vision, and hearing coverage
        is through a separate additional policy. These are not covered by your Medigap plan</p>
            
        <div class="d-flex align-items-center bgColor-orange">
            <span class="text-center fWeight-600 px-5 py-2 fColor-black">OPTION 1</span>
            <span class="text-center px-5 py-2 fColor-black"><strong class="fColor-black">MEDICARE SUPPLEMENT</strong> COVERAGE</span>
        </div>
        <div class="box-12 row margin-top-20">
            <div class="box-12 row margin-top-20">
              <div class="overview-coverage-4-boxes-wrapper">
                <div class="overview-coverage-4-inner">
                  <h6>Primary Coverage</h6>
                  <p>Original Medicare</p>
                  <img src="./assets/img/retiree-dashboard/original-medicare-card.png" alt="">
                </div>
                <div class="rd-oc-box-v1-plus">
                  <i class="fa fa-plus"></i>
                </div>
                <div class="overview-coverage-4-inner rd-highlighted">
                  <h6>Secondary Coverage</h6>
                  <p>Medicare Supplement</p>
                  <img src="./assets/img/retiree-dashboard/overview-coverage_box-secondary.png" alt="">
                  <b>Medigap</b>
                </div>
                <div class="rd-oc-box-v1-plus">
                  <i class="fa fa-plus"></i>
                </div>
                <div class="overview-coverage-4-inner">
                  <h6>Drug Coverage</h6>
                  <p>Standalone Part D Plans</p>
                  <img src="./assets/img/retiree-dashboard/overview-coverage_box-drug.png" alt="">
                  <b>Medicare part D Drug Plan</b>
                </div>
                <div class="rd-oc-box-v1-plus">
                  <i class="fa fa-plus"></i>
                </div>
                <div class="overview-coverage-4-inner">
                  <h6>Dental Coverage</h6>
                  <p>Individual Dental Policy</p>
                  <img src="./assets/img/retiree-dashboard/overview-coverage_box-dental.png" alt="">
                  <b>Dental Insurance</b>
                </div>
              </div>
            </div>
        </div>

        <p><b class="color-primary">Recap:</b> Original Medicare (Part A & B) will stay as your primary
        coverage and cover about 70%-80% of your major-medication healthcare costs.</p>

        <p>Your Medigap Plan is your secondary coverage, and covers the 
            remaining 20%-30%, so you have no networks and no out-of-pocket risk for any major
            medical services.
        </p>

    </div>

    <div class="is-this-the-right-coverage-for-me">
      <h4 class="color-primary text-center uppercase">What People Don't Like about it</h4>
      <h5 class="fColor-base">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">Like</span> About This Coverage?</h5>
      <ul class="checklist">
        <li>Low or No Monthly Premiums, & Includes Your Drug Coverage</li>
        <li>Streamlined Approach With All Healthcare Services Covered Under One Plan</li>
        <li>Most Plans Include Dental & Vision & Additional Health & Wellenss Benefits</li>
        <li>Can Include Extra Benefits LIke Gym Memberships, Transportation, & OTC Items</li>
      </ul>

      <h5 class="fColor-base mt-5">What Do Medicare Beneficiaries <span class="color-primary fWeight-inherit">NOT Like</span> About This Coverage?</h5>
      <ul class="xlist">
        <li>More Potential Out-of-Pocket Costs</li>
        <li>Plans Involve a Network, & Potentially Referrals & Pre-Certifications</li>
        <li>Plans Change Annually So There is More Oversight Needed</li>
        <li>Possibly Less Convenience Due To Networks & OUt-of-Pocket Bills</li>
      </ul>
      <h5 class="fColor-black">What Do Most Beneficiaries Prefer?</h5>
        <p>
            <b class="color-primary">Among Current Experienced and Savvy Medicare Beneficiaries,</b> some swear by
                Coverage Option 1, while others swear by Coverage Option 2
        </p>
        <p>
            <b class="color-primary">The Right Coverage Type For You</b> should be based on your particular 
            preferences, goals, and health outlook.
        </p>

        <mat-divider></mat-divider>

        <p>
            <b class="color-primary">For Reference</b> in 2020 about 41% of eligible Medicare beneficiaries have Medicare Supplement plan
            (Coverage Option 1) and <strong>59%</strong> have a Medicare Advantage plan (Coverage Option 2)
        </p>
    </div>

    <div class="how-should-i-choose-a-carrier">
        <h4 class="color-primary text-center uppercase">How Should I Choose A Carrier?</h4>
        <p>The following characteristics serve as a guide to the criteria you should use to
            help you choose your Medigap Insurance Company.
        </p>
        <mat-divider></mat-divider>
        <div class="row col-12 d-flex justify-content-center justify-content-md-end text-center text-md-left">
              <h5 class="col-12 fColor-black mb-4">
                5 Key Criteria For Choosing a Medigap Carrier:
              </h5>
              <table class="col-12 table-criteria mt-20 table-bordered">
                <tr>
                  <td class="first-col">Criteria 1</td>
                  <td class="second-col">Current Price Competitiveness</td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 2</td>
                  <td class="second-col">Track Record of Price Stability</td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 3</td>
                  <td class="second-col">
                    Financial Ratings and Overall Reputation
                  </td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 4</td>
                  <td class="second-col">
                    Quality and Convenience of Customer Service
                  </td>
                </tr>
                <tr>
                  <td class="first-col">Criteria 5</td>
                  <td class="second-col">
                    Inclusion of Wellness and Fitness Membership
                  </td>
                </tr>
              </table>
        </div>

        <mat-divider></mat-divider>

        <p><strong class="capitalize">Criteria 1:</strong> This is simply which carrier has the best pricing right now for the 
        particular plan letter you want.</p>

        <p><strong class="capitalize">Criteria 2:</strong> This refers to how well the company has kept prices low and stable
        for their current policy holders over the years. This is important because some beneficiaries may not be able to switch their Medigap
        Plan to another carrier due to health reasons. </p>

        <p><strong class="capitalize">Criteria 3:</strong> This is a reflection of the companies financial strength, measures by its level
             of assets (savings) relative to its costs & risks</p>

        <p><strong class="capitalize">Criteria 4:</strong> This is how easy and consumer friendly it is to deal with the Insurancecompany if you ever need to make
        requests or changes.</p>

        <p><strong class="capitalize">Criteria 5:</strong> This is whether the insurance company includes a program like SilverSneakers or 
            Silver&Fit with their Medigap Coverage</p>
    </div>

    <div class="will-i-be-approved-for-this-coverage">
        <h4 class="color-primary text-center uppercase">Will I Be Approved for this coverage?</h4>
        <div class="container what-is-the-big-picture d-flex align-items-center max-width-700">            
            <div class="what-is-the-big-picture-desc">
                <h5 class="fColor-black">What is The Big Picture?</h5>
                <p>
                    <b class="color-primary">As a result of your Medicare Beneficiary Profiles</b>
                    you get the opportunity to choose between 2 different ways of setting up your Medicare healthcare
                </p>
                <button class="btn border-radius bg-primary text-center p-4 full-width mb-3">
                    <span class="fColor-white d-block fWeight-600" style="font-size: 1.5em;">Coverage Option 1</span>
                    <span class="fColor-white d-block">is using a Medicare Supplement Plan</span>
                </button>
                <button class="btn border-radius bg-primary text-center p-4 full-width mb-2">
                    <span class="fColor-white d-block fWeight-600" style="font-size: 1.5em;">Coverage Option 2</span>
                    <span class="fColor-white d-block">is using a Medicare Advantage Plan</span>
                </button>
                <p>
                    <b class="color-primary">Both Medicare Coverage Options</b> are
                    particularly comprehensive relative to the 
                    majority of Health Insurance coverages.
                </p>
                <p>
                    <b class="color-primary">Additionally,</b> both coverage options are established and popular among current
                    Medicare Beneficiaries
                </p>
                <p>
                    <b class="color-primary">However,</b> each coverage type works very differently from the other, and provides
                    a different healthcare experience.
                </p>
            </div>
            <div class="what-is-the-big-picture-img" style="max-width: 400px;width: 68%;" class="desktop-only">
                <img src="./assets/img/common/old-man-blue-shirt.png" alt="">
            </div>
        </div>

        <mat-divider></mat-divider>

        <h5 class="fColor-black">What Do Most Beneficiaries Prefer?</h5>
        <p>
            <b class="color-primary">Among Current Experienced and Savvy Medicare Beneficiaries,</b> some swear by
                Coverage Option 1, while others swear by Coverage Option 2
        </p>
        <p>
            <b class="color-primary">The Right Coverage Type For You</b> should be based on your particular 
            preferences, goals, and health outlook.
        </p>

        <mat-divider></mat-divider>

        <p>
            <b class="color-primary">For Reference</b> in 2020 about 41% of eligible Medicare beneficiaries have Medicare Supplement plan
            (Coverage Option 1) and <strong>59%</strong> have a Medicare Advantage plan (Coverage Option 2)
        </p>

        <mat-divider></mat-divider>
        
        <h4 class="fColor-primary">Medigap Plan G Profile</h4>
        <p><b class="fColor-primary">Overview:</b> When you have Medicare Supplemnt coverage this Means You are keeping MEdicare as your
        primary and enrolling in a Medicare Supplement (aka Medigap Plan) to eliminate your out-of-pocket costs for "Major Medical" services.</p>
            
        <p><b class="fColor-primary">Your prescription drug coverage</b> is through a "Standalone" Medicare Part D Drug Plan, and your dental, vision, and hearing coverage
        is through a separate additional policy. These are not covered by your Medigap plan</p>
            
        <div class="d-flex align-items-center bgColor-orange">
            <span class="text-center fWeight-600 px-5 py-2">OPTION 1</span>
            <span class="text-center px-5 py-2"><strong>MEDICARE SUPPLEMENT</strong> COVERAGE</span>
        </div>
        <div class="box-12 row margin-top-20">
            <div class="box-12 row margin-top-20">
              <div class="overview-coverage-4-boxes-wrapper">
                <div class="overview-coverage-4-inner">
                  <h6>Primary Coverage</h6>
                  <p>Original Medicare</p>
                  <img src="./assets/img/retiree-dashboard/original-medicare-card.png" alt="">
                </div>
                <div class="rd-oc-box-v1-plus">
                  <i class="fa fa-plus"></i>
                </div>
                <div class="overview-coverage-4-inner rd-highlighted">
                  <h6>Secondary Coverage</h6>
                  <p>Medicare Supplement</p>
                  <img src="./assets/img/retiree-dashboard/overview-coverage_box-secondary.png" alt="">
                  <b>Medigap</b>
                </div>
                <div class="rd-oc-box-v1-plus">
                  <i class="fa fa-plus"></i>
                </div>
                <div class="overview-coverage-4-inner">
                  <h6>Drug Coverage</h6>
                  <p>Standalone Part D Plans</p>
                  <img src="./assets/img/retiree-dashboard/overview-coverage_box-drug.png" alt="">
                  <b>Medicare part D Drug Plan</b>
                </div>
                <div class="rd-oc-box-v1-plus">
                  <i class="fa fa-plus"></i>
                </div>
                <div class="overview-coverage-4-inner">
                  <h6>Dental Coverage</h6>
                  <p>Individual Dental Policy</p>
                  <img src="./assets/img/retiree-dashboard/overview-coverage_box-dental.png" alt="">
                  <b>Dental Insurance</b>
                </div>
              </div>
            </div>
        </div>

        <p><b class="color-primary">Recap:</b> Original Medicare (Part A & B) will stay as your primary
        coverage and cover about 70%-80% of your major-medication healthcare costs.</p>

        <p>Your Medigap Plan is your secondary coverage, and covers the 
            remaining 20%-30%, so you have no networks and no out-of-pocket risk for any major
            medical services.
        </p>

    </div>
</div>