import { Component, OnInit } from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {APIService} from '../services/api.service';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss']
})
export class WebinarComponent implements OnInit {

  constructor(private _api: APIService) {
      this.onSearch();
  }

    ngOnInit() {
    }

    loading: boolean = false;
    isFormSubmit: boolean = false;
    results = [];

    webinarForm = new FormGroup({
        first_name: new FormControl('', [
            Validators.required,
        ]),
        last_name: new FormControl('', [
            Validators.required,
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]),
    });

    get first_name() {
        return this.webinarForm.get('first_name');
    }

    get last_name() {
        return this.webinarForm.get('last_name');
    }

    get email() {
        return this.webinarForm.get('email');
    }

    saveMySeat() {

        this.isFormSubmit = true;
        if (this.webinarForm.valid) {
            this.loading = true;
        }
    }

    emptyFields() {
        this.webinarForm.setValue(
            {
                first_name: '',
                last_name: '',
                email: ''
            }
        )
    }

    onSearch() {
        try {
            this._api.callAPIEndpointAuthenticatedGetRequest('/api/v1/webinar', {
            })
            .subscribe({
                next: (data:any) => {
                    this.results = data;
                },
                error: (e) => {
                    this.loading = false;
                    console.error(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

}
