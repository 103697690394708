<div class="main-content-wrapper">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-9 mapd-drugs-left">
            <app-search-drugs></app-search-drugs>
          </div>
          <div class="col-12 col-md-3 mapd-drugs-right">
            <app-your-drugs></app-your-drugs>
          </div>
      </div>
  </div>
</div>