import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import confetti from 'canvas-confetti';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss']
})
export class ShareFormComponent implements OnInit {

  shareStep:any = 'main';

  constructor() { }

  ngOnInit() {
  }

  onShare(content) {
    this.deployConfetti();
    Swal.fire({
      html: content,
      showCloseButton: true,
      width: 600,
      padding: '30px'
    });
  }

  deployConfetti() {
    var end = Date.now() + (1 * 1000);
    (function frame() {
      confetti({
        particleCount: 4,
        angle: 60,
        spread: 80,
        origin: { x: 0 },
        zIndex: 2000
      });
      confetti({
        particleCount: 4,
        angle: 120,
        spread: 80,
        origin: { x: 1 },
        zIndex: 2000
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
  }

}
