import { Component, OnInit } from '@angular/core';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { DrugsService } from 'src/app/services/drugs.service';
import Swal from 'sweetalert2';
import confetti from 'canvas-confetti'
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-search-drugs',
  templateUrl: './search-drugs.component.html',
  styleUrls: ['./search-drugs.component.scss']
})
export class SearchDrugsComponent implements OnInit {
  viewDefault:boolean = true;
  viewCommonListDrugs:boolean = false;
  viewTypesOfMedications:boolean = false;
  view_drug_list:boolean = false;
  view_selectDosage:boolean = false;
  isLoading: boolean = false;
  results: any[] = [];
  fips: any[] = [];
  fips_code: any = '';
  searchFieldDrugname: any = '';
  step1Results: any[] = [];
  branded: any[] = [];
  generic: any[] = [];
  selectedDrug: any;
  drugTableName:string = 'Most Commonly Taken Drugs';
  drugTable_prev_view = 'common';
  tagResults: any[] = [];
  generic_grouped: any = [];
  branded_grouped: any = [];
  paramsObject: any;
  isEditDrug:boolean = false;
  editDrugName: any;
  editDrugId: any;
  searchDrugName: any;

  showStep1 = true;
  showStep2 = false;
  showStep3 = false;

  // STEP 2 Data
  rxcui = '';
  drug_id = '';
  drug_name = '';
  selected_drug_name = '';
  is_branded = null
  strip_drug_name:any;
  promise1: any;
  promise2: any;
  results_step_two:any[] = [];
  formCheck:any = [];
  times_taken:any;
  often_taken:any;
  drugForm: FormGroup;
  drug_refill: any;
  days_supply = null
  oftenSelection:any = false;
  isAllDrug_30_day_supply: boolean = false;
  isShowNo_day_supply: boolean = true;
  drugSupply: boolean = false;

  // Step 3
  showEditFields:boolean = false;
  edit_dosageValue:any;
  edit_amountTakenValue:any;
  edit_daysSupplyValue:any;
  edit_timesTakenValue:any;
  edit_oftenTakenValue:any;
  edit_refillValue:any;
  showMoreDrugDetails:boolean = false;
  moreDrugDetails_text:string = 'More Drug Details';
  step1ResultsPlain: any;
  single_notes: any = "";
  single_tags:any = [];
  drugListID: any;
  existingRxDrugListNotes:any;
  isUserLoggedIn:boolean;

  step1Form = new FormGroup({
    drugname: new FormControl('', [
      Validators.required,
    ]),
    fipscode: new FormControl('', [
      Validators.required,
    ]),
    allow_generics: new FormControl(true, [
      Validators.required,
    ]),
  });

  selectYourDrugsFromList:boolean = false;
  isShowCommonMedication:boolean = false;

  constructor(private _router: Router, private route: ActivatedRoute, private drugService: DrugsService, private formBuilder: FormBuilder, private _auth: AuthService) {
    this.isUserLoggedIn = _auth.isAuthenticated();
    this.route.queryParamMap
      .subscribe((params) => {
          this.paramsObject = {...params };
          this.isEditDrug = this.paramsObject['params'].edit_drug;
          this.editDrugName = this.paramsObject['params'].edit_drug_name;
          this.searchDrugName = this.paramsObject['params'].search_drug_name;          
          this.editDrugId = this.paramsObject['params'].edit_drug_id;

          if(this.isEditDrug){            
            this.onSubmit(event);
          }else{
            this.view_drug_list = false;
            this.branded = [];
            this.generic = [];
          }
        }
      );
  }

  ngOnInit() {
    this.step2()
    this.drugForm = this.formBuilder.group({
      times_taken: ['', Validators.required],
      often_taken: ['', Validators.required],
      days_supply: ['', Validators.required],
      drug_refill: ['', Validators.required]
    });
  }

  async getSuggestedList(keyword) {
    try {
      //this.isLoading = true;
      const results: any = await this.drugService.getAutoSuggest(keyword);

      if (results) {
        this.results = results;
      }
      //this.isLoading = false;
    } catch(e) {
      //this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
      //this.isLoading = false;
    }
  }

  async getFipsSuggestedList(keyword) {
    try {
      //this.isLoading = true;
      const fips: any = await this.drugService.getFipsAutoSuggest(keyword);

      if (fips) {
        this.fips = fips;
      }
      //this.isLoading = false;
    } catch(e) {
      // console.log('error', e);
      //this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
      //this.isLoading = false;
    }
  }

  updateMySelection(event: any) {
    this.selectedDrug = event;
  }

  fullnameValueChanged(event: any) {
    const text = event;

    if (text.length > 2) {
      this.getSuggestedList(text);
    }else{
      this.results = [];
    }
  }

  fipsValueChanged(event: any) {
    const text = event;

    if (text.length > 2) {
      this.getFipsSuggestedList(text);
    }
  }

  fipsChange(data) {
    this.fips_code = data.code;
  }


  async onSubmit(event: any) {
    this.view_drug_list = true;
    this.isLoading = true;
    this.branded = [];
    this.generic = [];
    this.branded_grouped = [];
    this.generic_grouped = [];
    
    try {
      let step1Results;
      //this.isLoading[type] = true;
      if(this.isEditDrug){
        step1Results = await this.drugService.step1(this.editDrugName, this.step1Form.controls['allow_generics'].value);
      }else{
        step1Results = await this.drugService.step1(this.step1Form.controls['drugname'].value, this.step1Form.controls['allow_generics'].value);
      }
      
      this.step1Results = step1Results;
      this.branded = step1Results.branded;
      this.generic = step1Results.generic;

      if(this.isEditDrug){
        this.branded = this.branded.filter(item => item.drug_info.brand_name.toLowerCase() == this.editDrugName.toLowerCase());
        this.generic = this.generic.filter(item => item.drug_info.drug_name.toLowerCase() == this.editDrugName.toLowerCase());
      }
      
      // group generic drug by package
      const genGroup = this.generic.reduce((r, a) => {
        r[a.drug_info.package] = [...r[a.drug_info.package] || [], a];
        return r;
      }, {});      
      for (var key of Object.keys(genGroup)) {
          this.generic_grouped.push({'type': key, 'drugs':genGroup[key]});
      }

      // group branded drug by package
      const brandGroup = this.branded.reduce((r, a) => {
        r[a.drug_info.package] = [...r[a.drug_info.package] || [], a];
        return r;
      }, {});

      for (var key of Object.keys(brandGroup)) {
        this.branded_grouped.push({'type': key, 'drugs':brandGroup[key]});
      }

      /*if (step1Results) {
        this.step1Results = step1Results;
      }*/
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
    }

  }

  viewNextStep(drug:any, isBranded:any):void {
    const selectedDname = (drug.drug_info.brand_name) ? drug.drug_info.brand_name : drug.drug_info.drug_name;
    const selectedDrugId = (this.isEditDrug) ? this.editDrugId : this.selectedDrug.id;
    const selectedDrugName = (this.isEditDrug) ? this.editDrugName : this.selectedDrug.name;

    this.rxcui = drug.rxcui
    this.drug_id = selectedDrugId
    this.drug_name = selectedDrugName
    this.selected_drug_name = selectedDname.toLowerCase()
    this.is_branded = isBranded

    this.toggleSteps("step2");
  }

  /**
   * 
   * @param stepType - Accepts string that needs to be displayed. Format e.g - step1,step2 and more...
   */
  toggleSteps(stepType:string):void {
    switch (stepType){
      case "step1":
        this.showStep1 = true
        this.showStep2 = false
        this.showStep3 = false
        break;
      case "step2":
        this.step2();
        this.showStep1 = false
        this.showStep2 = true
        this.showStep3 = false
        break;
      case "step3":
        this.step2();
        this.getMarketplaceDrugListID();
        this.showStep1 = false
        this.showStep2 = false
        this.showStep3 = true
        break;
    }
  }

  async step2() {
    try {
      this.promise1 = await this.drugService.step3r1(this.rxcui);
      this.promise2 = await this.drugService.step3r2(this.rxcui);
      Promise.all([this.promise1, this.promise2]).then((values) => {
        this.results_step_two = values;
        this.strip_drug_name = this.drugService.getDrugName(this.results_step_two[1].idGroup.drug_info );
        this.checkDosageForm();
      });
      this.isLoading = false;
    } catch (e) {
      console.log('error', e);
      this.isLoading = false;
    } finally {
    }
  }

  checkDosageForm() {
    const dosageName = this.results_step_two[1].idGroup.name.toLowerCase();
    const dosageString = "capsule";
    const dosageString2 = "tablet";
    const dosageString3 = "injection";
    if(dosageName.includes(dosageString)) {
      this.formCheck = "capsule";
    }
    else if(dosageName.includes(dosageString2)) {
      this.formCheck = "tablet";
    }
    else if(dosageName.includes(dosageString3)) {
      this.formCheck = "injection";
    }
    else {
      this.formCheck = "meds";
    }
  }

  onClick_ChangeDrug():void {
    this.toggleSteps('step1')
  }

  onClick_shortcutOften(times,often){
    this.times_taken = times;
    this.often_taken = often;
    $('.timesOftenBtn').removeClass('active');
    document.querySelector('#'+often+'-'+times).classList.add('active');
    $('.timesOftenBtn').removeClass('error');
    $('#oftenTakenWarningID').addClass('d-none').removeClass('d-block');
  }

  click_often_taken(event, type) {
    $('.timesOftenBtn2').removeClass('error');
    $('.btn-packaging-list-item').removeClass('active');
    $('#often_taken_' + type).click();
    $(event.target).addClass('active');
    if(this.times_taken){      
      $('#oftenTakenWarningID2').removeClass('d-block').addClass('d-none');
    }
  }

  selectedTimesTaken(){
    if(this.often_taken){
      $('#oftenTakenWarningID2').addClass('d-none').removeClass('d-block');
    }    
  }

  click_drug_supply(event, type, supply:boolean=false) {
    if(supply){
      this.isAllDrug_30_day_supply = true;
    }

    this.drugSupply = true;
    $('.btn-often').removeClass('active').removeClass('error');
    $('#drugSupplyWarningID').addClass('d-none').removeClass('d-block');
    $('#days_supply' + type).click();
    $(event.target).addClass('active');
    this.drugForm.controls['days_supply'].setValue(type);
    this.days_supply = type
  }

  onClick_DefaultViewProceed_New(){
    if(this.times_taken && this.often_taken){
      $('.timesOftenBtn').removeClass('error');
      $('#oftenTakenWarningID').addClass('d-none').removeClass('d-block');
      document.querySelector('#form_view_default').classList.add('d-none');
      document.querySelector('#form_view_default').classList.remove('d-block');
      document.querySelector('#form_view_how_often').classList.add('d-block');
      document.querySelector('#form_view_how_often').classList.remove('d-none');
      // document.querySelector('#form_refill_option').classList.add('d-block');
      // document.querySelector('#form_refill_option').classList.remove('d-none');
    }else{      
      $('.timesOftenBtn').addClass('error');
      $('#oftenTakenWarningID').addClass('d-block').removeClass('d-none');
    }    
  }

  onClickHowOften_Back_New(){
    this.oftenSelection = false
    document.querySelector('#form_view_how_often').classList.add('d-none');
    document.querySelector('#form_view_how_often').classList.remove('d-block');    
    document.querySelector('#form_view_default').classList.add('d-block');
    document.querySelector('#form_view_default').classList.remove('d-none');
  }

  onClick_MoreOptionProceed_New(){
    if(this.times_taken && this.often_taken){
      $('.timesOftenBtn2').removeClass('error');
      $('#oftenTakenWarningID2').addClass('d-none').removeClass('d-block');
      document.querySelector('#form_view_more_option').classList.add('d-none');
      document.querySelector('#form_view_more_option').classList.remove('d-block');
      document.querySelector('#form_view_how_often').classList.add('d-block');
      document.querySelector('#form_view_how_often').classList.remove('d-none');
      
    }else{ 
      if(!this.often_taken){
        $('.timesOftenBtn2').addClass('error');
      }else{
        $('.timesOftenBtn2').removeClass('error');
      }
      $('#oftenTakenWarningID2').addClass('d-block').removeClass('d-none');
    }   
  }

  onClick_AsNeeded(){
    document.querySelector('#form_refill_option').classList.add('d-block');
    document.querySelector('#form_refill_option').classList.remove('d-none');
    document.querySelector('#form_view_more_option').classList.add('d-none');
    document.querySelector('#form_view_more_option').classList.remove('d-block');
  }

  onClick_MoreOptionBack_New(){
    this.resetAllBtnStateAndValue();
    document.querySelector('#form_view_default').classList.remove('d-none');
    document.querySelector('#form_view_default').classList.add('d-block');
    document.querySelector('#form_view_more_option').classList.add('d-none');
    document.querySelector('#form_view_more_option').classList.remove('d-block');
    document.querySelector('#form_refill_option').classList.add('d-none');
    document.querySelector('#form_refill_option').classList.remove('d-block');
  }

  viewNextStep_TypesMedication() {
    this._router.navigate(['step2', '262095'], { queryParams : {'drug_id': '2820', 'drug_name': 'lipitor'}, queryParamsHandling: 'merge', relativeTo: this.route });
  }

  onClick_MoreOptions_New(){      
    this.resetAllBtnStateAndValue();
    document.querySelector('#form_view_default').classList.add('d-none');
    document.querySelector('#form_view_default').classList.remove('d-block');
    document.querySelector('#form_view_more_option').classList.add('d-block');
    document.querySelector('#form_view_more_option').classList.remove('d-none');
    document.querySelector('#form_refill_option').classList.add('d-none');
    document.querySelector('#form_refill_option').classList.remove('d-block');
  }

  resetAllBtnStateAndValue(){
    this.drugForm.reset();
    $('.timesOftenBtn').removeClass('active');
    $('.timesOftenBtn2').removeClass('active');
    $('.timesOftenBtn2').removeClass('error');    
    $('.timesOftenBtn').removeClass('error');
    $('.btn-often').removeClass('error'); 
    $('.refillBtn').removeClass('active');  
    $('.refillBtn').removeClass('error');  
  
    $('#oftenTakenWarningID2').addClass('d-none').removeClass('d-block');
    $('#oftenTakenWarningID').addClass('d-none').removeClass('d-block');
    $('#drugSupplyWarningID').addClass('d-none').removeClass('d-block');
    $('#drugSupplyWarningID2').addClass('d-none').removeClass('d-block');
    $('#refillSelectionWarning').addClass('d-none').removeClass('d-block');
  }


  onClick_ViewDefault(){
    this.viewDefault = true;
    this.viewCommonListDrugs = false;
    this.viewTypesOfMedications = false;
  }

  onClick_ViewTheMostCommonDrugs(tag){
    this.viewDefault = false;
    this.viewCommonListDrugs = true;
    this.viewTypesOfMedications = false;
    this.drugTable_prev_view = 'common';
    this.getTaggedList(tag);

  }

  onClick_ViewTypesOfMedications(){
    this.viewDefault = false;
    this.viewCommonListDrugs = false;
    this.viewTypesOfMedications = true;
    this.drugTable_prev_view = 'types';
  }

  onClick_types_of_medications(tableTitle,tag){
    this.viewDefault = false;
    this.viewCommonListDrugs = true;
    this.viewTypesOfMedications = false;
    this.drugTableName = tableTitle;
    this.getTaggedList(tag);
  }

  onClick_backToPrevView(type){
    if(type == 'common'){
      this.onClick_ViewDefault();
    }else{
      this.onClick_ViewTypesOfMedications();
    }
  }

  click_drug_refill(event, type) {
    $('.refillBtn').removeClass('error');
    $('.btn-refill').removeClass('active');
    $('#drug_refill_' + type).click();
    $(event.target).addClass('active');
    // if(this.times_taken){      
    //   $('#refillSelectionWarning').removeClass('d-block').addClass('d-none');
    // }
  }

  onClick_RefillDone(){
    document.querySelector('#form_view_more_option').classList.add('d-block');
    document.querySelector('#form_view_more_option').classList.remove('d-none');
    document.querySelector('#form_refill_option').classList.add('d-none');
    document.querySelector('#form_refill_option').classList.remove('d-block');
  }

  checkSupplyValue(){
    if (this.drugSupply) {
        if(this.isAllDrug_30_day_supply){
          localStorage.setItem('rx_30_day_supply', 'yes');
        }
        this.toggleSteps("step3");
    }else{
        $('.btn-often').addClass('error');
        $('#drugSupplyWarningID').addClass('d-block').removeClass('d-none');
    }
  }

  async getTaggedList(tag) {
    try {
      this.isLoading = true;
      const results: any = await this.drugService.getTaggedList(tag);

      if (results) {
        this.isLoading = false;
        this.tagResults = results;
      }
      //this.isLoading = false;
    } catch(e) {      
      //this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
      //this.isLoading = false;
    }
  }

  otherWaysToAddModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true
    })
  }


  onChange_Dosage(event){
    console.log('onChange_Dosage',event.value);
    this.edit_dosageValue = event.value;
  }

  onChange_DaysSupply(event){
    console.log('onChange_daysSupply',event.value);
    this.edit_daysSupplyValue = event.value;
  }

  onChange_TimesTaken(event){
    console.log('onChangeDosage_timesTaken',event.value);
    this.edit_timesTakenValue = event.value;
  }

  onChange_OftenTaken(event){
    console.log('onChangeDosage_oftenTaken',event.value);
    this.edit_oftenTakenValue = event.value;
  }

  onChange_Refill(event){
    console.log('onChange_Refill',event.value);
    this.edit_refillValue = event.value;
  }

  onClick_editBtn(){
    this.showEditFields = true;
    this.onEditSubmit();
  }

  async onEditSubmit() {
    this.isLoading = true;
    this.branded = [];
    this.generic = [];
    this.branded_grouped = [];
    this.generic_grouped = [];
    
    try {    
      //let step1Results;

      this.step1ResultsPlain = await this.drugService.step1(this.strip_drug_name, 1);

      if(this.step1ResultsPlain){
        this.branded = this.step1ResultsPlain.branded;
        this.generic = this.step1ResultsPlain.generic;
      }

      this.branded = this.branded.filter(item => item.drug_info.brand_name.toLowerCase() == this.strip_drug_name.toLowerCase());
      this.generic = this.generic.filter(item => item.drug_info.drug_name.toLowerCase() == this.strip_drug_name.toLowerCase());
      
      //set edit field default value to current selected drug
      this.edit_dosageValue = this.rxcui;
      this.edit_daysSupplyValue = this.days_supply;
      this.edit_timesTakenValue = this.times_taken;
      this.edit_oftenTakenValue = this.often_taken;
      this.edit_refillValue = this.drug_refill;

      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
    } finally {
      //this.isLoading[type] = false;
    }

  }

  onClick_edit_SaveBtn(){
    const editedDrugInfo = {
      times_taken: this.edit_timesTakenValue,
      often_taken: this.edit_oftenTakenValue ,
      days_supply: this.edit_daysSupplyValue,
      drug_refill: this.edit_refillValue
    }
    this.showEditFields = false
    // this._router.navigate(['../../step3', this.edit_dosageValue], { queryParams : editedDrugInfo, queryParamsHandling: 'merge', relativeTo: this.route });
  }

  onClick_MoreDrugDetails(state){
    this.showMoreDrugDetails = !state;
    if(this.showMoreDrugDetails){
      this.moreDrugDetails_text = "Hide Additional Details";
    }else{     
      this.moreDrugDetails_text = "More Drug Details";
    }
  }

  async getMarketplaceDrugListID(){
    const list = await this.drugService.getDrugLists();    
    const listInfo_filter = list.data.filter(list => list.name.toLowerCase() == this.drugService.forbiddenDrugListName);
    this.drugListID = listInfo_filter[0].id;
    
  }

  async onClick_AddDrugToList(content) {
    this.isLoading = true;

    this.existingRxDrugListNotes = localStorage.getItem('rx_drug_list_notes');
    if (this.existingRxDrugListNotes == null || this.existingRxDrugListNotes == "") {
      localStorage.setItem('rx_drug_list_notes', "");
    }

    this.promise1 = await this.drugService.step3r1(this.rxcui);
    this.promise2 = await this.drugService.step3r2(this.rxcui);
      Promise.all([this.promise1, this.promise2]).then((values) => {
        this.results_step_two = values;
        this.checkDosageForm();
      });
     
    this.results_step_two[1].idGroup.drug_info['isbranded'] = this.is_branded;

    const drugInfo = { // payload for saving invidual drug to local storage
      'drug_id' : this.drug_id,
      //'drug_name' : this.results[0][0].proprietary_name.toLowerCase(),
      'drug_name': this.selected_drug_name, 
      'rxcui' : this.rxcui,
      'often_taken': this.often_taken,
      'times_taken': this.times_taken,      
      'quantity': this.days_supply,
      'tags' : this.single_tags.toString(),
      'notes' : this.single_notes,
      'details' : this.results_step_two[1].idGroup.drug_info,
      'search_drug_name' : this.drug_name 
    };


    const drugInfoDB = { // payload for saving invidual drug to DB
      "rx_list_id": this.drugListID,
      "drug_id": this.drug_id,
      "drug_name": this.selected_drug_name,
      "notes": this.single_notes,
      "often_taken": this.often_taken,
      "times_taken": this.times_taken,
      "quantity": this.days_supply,
      "rxcui": this.rxcui,
      "tags": this.single_tags.toString(),
      "details": this.results_step_two[1].idGroup.drug_info
    }

    if(this.isUserLoggedIn){ // check if user is logged in
      this.saveToDbHandler(drugInfoDB);
    }else{
      this.saveToLocalStorageHandler(drugInfo); // save to local storage only
    }
    
    this.isLoading = false;
    
    this.onShow_DrugAddedToListModal(content);
    this.deployConfetti();

  }

  onShow_DrugAddedToListModal(content){
    Swal.fire({
      html:content,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true
    })
  }

  deployConfetti() {
    confetti({
      particleCount: 200,
      spread: 120,
      origin: { y: 0.5 },
      zIndex: 1000
    });
  }

  async saveToDbHandler(drugInfoDB){
    const status = await this.drugService.saveIndividualDrug(drugInfoDB);
    if(status.code == 1){
      this.drugService.fetch_drugList_data(this.drugListID); 
      this.isLoading = false;  
    }
  }

  // function to save drug detail to Browser Local Storage
  saveToLocalStorageHandler(drugInfo){
    let existingEntries = JSON.parse(localStorage.getItem('rx_add_to_drug_list'));
    if (existingEntries == null) {
      existingEntries = [];
    }

    if (existingEntries.filter(e => e.rxcui === drugInfo.rxcui).length > 0) {
      this.drugService.removeDrugListByRxcui(drugInfo);
    }
  
    existingEntries = JSON.parse(localStorage.getItem('rx_add_to_drug_list'));
    if (existingEntries == null) {
      existingEntries = [];
    }
  
    existingEntries.push(drugInfo);
    this.drugService.addList(drugInfo);
    localStorage.setItem('rx_add_to_drug_list', JSON.stringify(existingEntries));
    this.drugService.fetchAddedDrugList(); // pull drugs list from local storage
  }

  onClick_addNote(modalContent){
    Swal.fire({
      html:modalContent,
      padding:'20px',
      showConfirmButton: false,
      showCloseButton: true,
      width: 500
    }) 
  }

  onClick_setNotes(event){ 
    this.single_notes = event.notes;
    this.single_tags = event.tags;
    Swal.close();
  }

  addAnotherDrug() {
    this.onClick_ChangeDrug();
    Swal.close();
  }
}
