import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-application-medigap-version1-screen10',
  templateUrl: './screen10.component.html',
  styleUrls: ['./screen10.component.scss']
})
export class Screen10Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
