<div class="modal-popupWrapper modalAdditionalInsights">
  <img onerror="this.onerror=null;this.src='/assets/img/no-image-available.jpg';"
      src="{{ image }}" class="modal-companyLogo">

  <div class="table-wrapper">
      <table class="enrollmentFee-table">
          <tr>
              <td>
                  <p class="heading">Enrollment Fee:</p>
                  <div *ngFor="let fee of fees; let i = index">
                      <p class="result"><span class="font-secondary color-primary"
                              style="font-size: 2em;font-weight:bold;">{{ fee.value | currency }}</span></p>
                  </div>
                  <div *ngIf="fees.length === 0">
                      <p class="result">No Enrollment Fee</p>
                  </div>
              </td>
              <td>
                  <p class="heading">Household Discount:</p>
                  <div *ngFor="let discount of discounts; let i = index">
                      <p class="result"><span class="font-secondary color-primary"
                              style="font-size: 2em;font-weight:bold;">{{ discount.value | percent }}</span></p>
                  </div>
                  <div *ngIf="discounts.length === 0">
                      <p class="result">No Household Discount</p>
                  </div>
              </td>
          </tr>
      </table>
  </div>

  <div class="table-wrapper">
    <tbody style="display: block;width: 100%;">
        <tr style="display: block;">
            <td style="display:block;margin:auto">
                <p class="heading" style="font-weight: 700;font-size: 18px;text-transform: uppercase;">Price Stability Score:</p>
                <span class="result color-primary" style="font-size: 2em;font-weight: bold;">4.1 Out of 5</span>
            </td>
        </tr>
    </tbody>
 </div>

  <div class="table-wrapper no-border-radius">

      <table class="rateIncrease-table">
          <tr>
              <td colspan="2"><span class="heading">Rate Increase History</span></td>
          </tr>
          <tr>
              <table class="table-bordered rate-increase-history-table">
                  <thead>
                    <tr>
                        <th class="bg-primary fColor-white">Year</th>
                        <th class="bg-primary fColor-white">Increase</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let history of rateHistory; let i = index">
                          <td>{{ formatDate(history.date) }}</td>
                          <td>{{ history.rate_increase | percent:'1.0-1' }}</td>
                      </tr>
                      <!--<tr>
                          <td>2018</td>
                          <td>2.8%</td>
                      </tr>
                      <tr>
                        <td>2019</td>
                        <td>3.1%</td>
                    </tr>
                    <tr>
                        <td>2020</td>
                        <td>4.3%</td>
                    </tr>-->
                  </tbody>
              </table>
          </tr>
      </table>
  </div>


  <div class="table-wrapper text-center">
      <h4 class="fWeight-bold fColor-base">Wellness & Fitness Programs</h4>
      <h4 class="fWeight-400 fColor-base">{{ silverSneakers }}</h4>
  </div>
    <!-- <div class="table-wrapper">
        <table class="wellness-table">
            <tr>
                <td colspan="2"><span class="heading">Wellness & Fitness Programs</span></td>
            </tr>
            <tr>
                <td><span class="result">Yes: SilverSneakers</span></td>
            </tr>
        </table>
    </div> -->
</div>